'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"charges_receivable_paidAt":{"n":""},"chargesFromSubscriptions_receivable_paidAt":{"n":""},"chargesFromSubscriptions_receivable_status":{"n":""},"charges_receivable":{"n":""},"chargesFromSubscriptions_receivable_expectedOn":{"n":""},"chargesFromSubscriptions_receivable":{"n":""},"charges_receivable_status":{"n":""},"charges_receivable_expectedOn":{"n":""},"recurrentCharges_transactionCost":{"n":""}}};

function convertFragment(v) {
  return ReasonRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "year",
  "variableName": "currentYear"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionCost",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ChargeReceivable",
  "kind": "LinkedField",
  "name": "receivable",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expectedOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidAt",
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "currentYear"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChargesProjectionsChartFragment_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "source",
          "value": "MANUAL"
        },
        (v1/*: any*/)
      ],
      "concreteType": "Charge",
      "kind": "LinkedField",
      "name": "charges",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "chargesFromSubscriptions",
      "args": [
        {
          "kind": "Literal",
          "name": "source",
          "value": "RECURRENT_CHARGE"
        },
        (v1/*: any*/)
      ],
      "concreteType": "Charge",
      "kind": "LinkedField",
      "name": "charges",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RecurrentCharge",
      "kind": "LinkedField",
      "name": "recurrentCharges",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v5/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
