'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Hr$VannaFrontend = require("./Hr.bs.js");
var Text$VannaFrontend = require("./Text.bs.js");

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceAround"),
          tl: /* [] */0
        }
      }
    });

var headerInfo = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: /* [] */0
      }
    });

var brandImage = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "px",
            VAL: 50
          }),
      tl: {
        hd: Css.marginRight({
              NAME: "px",
              VAL: 10
            }),
        tl: /* [] */0
      }
    });

var amountWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: /* [] */0
        }
      }
    });

var sectionTitleRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginBottom({
                  NAME: "px",
                  VAL: 10
                }),
            tl: {
              hd: Css.marginTop({
                    NAME: "px",
                    VAL: 10
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var sectionTitle = Curry._1(Css.style, {
      hd: Css.marginBottom({
            NAME: "px",
            VAL: 5
          }),
      tl: /* [] */0
    });

var text = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var centeredWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.padding({
                  NAME: "px",
                  VAL: 7
                }),
            tl: /* [] */0
          }
        }
      }
    });

var row = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  header: header,
  headerInfo: headerInfo,
  brandImage: brandImage,
  amountWrapper: amountWrapper,
  sectionTitleRow: sectionTitleRow,
  sectionTitle: sectionTitle,
  text: text,
  centeredWrapper: centeredWrapper,
  row: row
};

function Receipt$Wrapper(Props) {
  var content = Props.content;
  var forwardRef = Props.forwardRef;
  return React.createElement("div", {
              ref: forwardRef
            }, React.createElement("div", {
                  className: header
                }, React.createElement("img", {
                      className: brandImage,
                      src: "/favicon.png"
                    }), React.createElement("div", {
                      className: headerInfo
                    }, React.createElement(Text$VannaFrontend.make, {
                          className: text,
                          children: "G3 Informática LTDA"
                        }), React.createElement(Text$VannaFrontend.make, {
                          className: text,
                          children: "34.405.074/0001-20"
                        }))), React.createElement(Hr$VannaFrontend.make, {}), Curry._1(content, undefined));
}

var Wrapper = {
  make: Receipt$Wrapper
};

exports.Styles = Styles;
exports.Wrapper = Wrapper;
/* header Not a pure module */
