'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Hr$VannaFrontend = require("../components/Hr.bs.js");
var Button$VannaFrontend = require("../components/Button.bs.js");
var BrandPanel$VannaFrontend = require("../components/BrandPanel.bs.js");

function NotFound(Props) {
  var $$navigator = Props.navigator;
  return React.createElement(BrandPanel$VannaFrontend.make, {
              title: "Página não encontrada",
              children: null
            }, React.createElement(Hr$VannaFrontend.make, {}), React.createElement(Button$VannaFrontend.make, {
                  marginTop: true,
                  mode: /* Secondary */1,
                  onClick: (function (param) {
                      return Curry._1($$navigator.push, /* Login */0);
                    }),
                  children: "Voltar"
                }));
}

var make = NotFound;

exports.make = make;
/* react Not a pure module */
