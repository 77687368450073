'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Fi = require("react-icons/fi");
var Tooltip$VannaFrontend = require("./Tooltip.bs.js");
var IconButton$VannaFrontend = require("./IconButton.bs.js");

var wrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("flexStart"),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  wrapper: wrapper
};

function CopyableContent(Props) {
  var content = Props.content;
  var children = Props.children;
  return React.createElement("div", {
              className: wrapper
            }, children, React.createElement(IconButton$VannaFrontend.make, {
                  onClick: (function (_evt, _setLoading, setConfirming) {
                      Curry._1(setConfirming, (function (param) {
                              return true;
                            }));
                      window.navigator.clipboard.writeText(content).then(function (param) {
                            setTimeout((function (param) {
                                    return Curry._1(setConfirming, (function (param) {
                                                  return false;
                                                }));
                                  }), 3000);
                            return Promise.resolve(undefined);
                          });
                      
                    }),
                  children: React.createElement(Tooltip$VannaFrontend.make, {
                        message: "Copiar",
                        children: React.createElement(Fi.FiCopy, {})
                      })
                }));
}

var make = CopyableContent;

exports.Styles = Styles;
exports.make = make;
/* wrapper Not a pure module */
