'use strict';

var React = require("react");
var ReactTooltip = require("react-tooltip").default;

function Tooltip$Spread(Props) {
  return React.cloneElement(Props.children, Props.props);
}

var Spread = {
  make: Tooltip$Spread
};

function Tooltip(Props) {
  var message = Props.message;
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement(Tooltip$Spread, {
                  props: {
                    "data-tip": message
                  },
                  children: children
                }), React.createElement(ReactTooltip, {}));
}

var make = Tooltip;

exports.Spread = Spread;
exports.make = make;
/* react Not a pure module */
