'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var UseTimer = require("use-timer");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Button$VannaFrontend = require("./Button.bs.js");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var FieldSection$VannaFrontend = require("./FieldSection.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend = require("../__generated__/TwoFactorForm_UserRequestSmsAuthMutation_graphql.bs.js");

var smsField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 75
          }),
      tl: /* [] */0
    });

var Styles = {
  smsField: smsField
};

function get(state, field) {
  if (field) {
    return state.smsAuthCode;
  } else {
    return state.googleAuthCode;
  }
}

function set(state, field, value) {
  if (field) {
    return {
            googleAuthCode: state.googleAuthCode,
            smsAuthCode: value
          };
  } else {
    return {
            googleAuthCode: value,
            smsAuthCode: state.smsAuthCode
          };
  }
}

var StateLenses = {
  get: get,
  set: set
};

var TwoFactorForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.node,
              variables: TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var UserRequestSmsAuthMutation_make_response_userRequestSmsAuth_error = TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Utils.make_response_userRequestSmsAuth_error;

var UserRequestSmsAuthMutation_make_response_userRequestSmsAuth = TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Utils.make_response_userRequestSmsAuth;

var UserRequestSmsAuthMutation_makeOptimisticResponse = TwoFactorForm_UserRequestSmsAuthMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var UserRequestSmsAuthMutation = {
  make_response_userRequestSmsAuth_error: UserRequestSmsAuthMutation_make_response_userRequestSmsAuth_error,
  make_response_userRequestSmsAuth: UserRequestSmsAuthMutation_make_response_userRequestSmsAuth,
  makeOptimisticResponse: UserRequestSmsAuthMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

function TwoFactorForm$1(Props) {
  var isGoogleAuthEnabled = Props.isGoogleAuthEnabled;
  var isSmsAuthEnabled = Props.isSmsAuthEnabled;
  var initialCanRequestCodeOpt = Props.initialCanRequestCode;
  var withSectionOpt = Props.withSection;
  var onChange = Props.onChange;
  var initialCanRequestCode = initialCanRequestCodeOpt !== undefined ? initialCanRequestCodeOpt : true;
  var withSection = withSectionOpt !== undefined ? withSectionOpt : false;
  var match = use(undefined);
  var isRequestingSms = match[1];
  var requestSmsCode = match[0];
  var match$1 = React.useState(function () {
        return true;
      });
  var setCanRequestCode = match$1[1];
  var canRequestCode = match$1[0];
  var match$2 = UseTimer.useTimer({
        initialTime: 60,
        endTime: 0,
        timerType: "DECREMENTAL"
      });
  var status = match$2.status;
  var reset = match$2.reset;
  var start = match$2.start;
  React.useEffect((function () {
          if (initialCanRequestCode === false) {
            Curry._1(setCanRequestCode, (function (param) {
                    return initialCanRequestCode;
                  }));
            Curry._1(reset, undefined);
            Curry._1(start, undefined);
          }
          
        }), []);
  React.useEffect((function () {
          switch (status) {
            case "RUNNING" :
                Curry._1(setCanRequestCode, (function (param) {
                        return false;
                      }));
                break;
            case "STOPPED" :
                Curry._1(setCanRequestCode, (function (param) {
                        return true;
                      }));
                break;
            default:
              
          }
          
        }), [status]);
  var reform = Curry._7(TwoFactorForm.use, {
        googleAuthCode: "",
        smsAuthCode: ""
      }, /* Schema */{
        _0: Curry._2(TwoFactorForm.ReSchema.Validation.$plus, Curry._3(TwoFactorForm.ReSchema.Validation.custom, (function (state) {
                    if (isGoogleAuthEnabled && state.googleAuthCode.length !== 6) {
                      return {
                              TAG: /* Error */1,
                              _0: "O código de autenticação precisa ter exatamente 6 dígitos"
                            };
                    } else {
                      return /* Valid */0;
                    }
                  }), undefined, /* GoogleAuthCode */0), Curry._3(TwoFactorForm.ReSchema.Validation.custom, (function (state) {
                    if (isSmsAuthEnabled && state.smsAuthCode.length !== 6) {
                      return {
                              TAG: /* Error */1,
                              _0: "O código de autenticação precisa ter exatamente 6 dígitos"
                            };
                    } else {
                      return /* Valid */0;
                    }
                  }), undefined, /* SmsAuthCode */1))
      }, (function (param) {
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  React.useEffect((function () {
          
        }), [canRequestCode]);
  return React.createElement(TwoFactorForm.Provider.make, Curry._3(TwoFactorForm.Provider.makeProps, reform, null, undefined), withSection ? React.createElement(FieldSection$VannaFrontend.make, {
                    children: "Autenticação de dois fatores"
                  }) : null, isGoogleAuthEnabled ? React.createElement(TwoFactorForm.Field.make, {
                    field: /* GoogleAuthCode */0,
                    render: (function (param) {
                        var handleChange = param.handleChange;
                        return React.createElement(TextField$VannaFrontend.make, {
                                    label: "Código de Autenticação Google",
                                    error: param.error,
                                    mask: "999999",
                                    value: param.value,
                                    onChange: (function (value) {
                                        Curry._1(handleChange, value);
                                        return Curry._2(onChange, /* Google */0, value);
                                      }),
                                    placeholder: "000000"
                                  });
                      })
                  }) : null, isSmsAuthEnabled ? React.createElement(FieldGrid$VannaFrontend.make, {
                    children: null
                  }, React.createElement(TwoFactorForm.Field.make, {
                        field: /* SmsAuthCode */1,
                        render: (function (param) {
                            var handleChange = param.handleChange;
                            return React.createElement(TextField$VannaFrontend.make, {
                                        label: "Código de Autenticação SMS",
                                        error: param.error,
                                        mask: "999999",
                                        value: param.value,
                                        onChange: (function (value) {
                                            Curry._1(handleChange, value);
                                            return Curry._2(onChange, /* SMS */1, value);
                                          }),
                                        placeholder: "000000"
                                      });
                          })
                      }), React.createElement(Button$VannaFrontend.make, {
                        fullWidth: false,
                        isLoading: isRequestingSms,
                        onClick: (function (param) {
                            Curry.app(requestSmsCode, [
                                  (function (param) {
                                      
                                    }),
                                  (function (response, errors) {
                                      if (errors !== undefined && errors.length !== 0) {
                                        return ;
                                      }
                                      var match = response.userRequestSmsAuth;
                                      if (match !== undefined && match.error === undefined) {
                                        Curry._1(setCanRequestCode, (function (param) {
                                                return false;
                                              }));
                                        Curry._1(reset, undefined);
                                        return Curry._1(start, undefined);
                                      }
                                      
                                    }),
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined
                                ]);
                            
                          }),
                        disabled: !canRequestCode || isRequestingSms,
                        children: status === "RUNNING" ? "Solicitar código" + "(" + String(match$2.time) + "s)" : "Solicitar código"
                      })) : null);
}

var smsRequestInterval = 60;

var make = TwoFactorForm$1;

exports.Styles = Styles;
exports.StateLenses = StateLenses;
exports.TwoFactorForm = TwoFactorForm;
exports.UserRequestSmsAuthMutation = UserRequestSmsAuthMutation;
exports.smsRequestInterval = smsRequestInterval;
exports.make = make;
/* smsField Not a pure module */
