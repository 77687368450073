'use strict';

var Js_math = require("bs-platform/lib/js/js_math.js");
var BignumberJs = require("bignumber.js").default;
var GetDay = require("date-fns/getDay");
var GetDate = require("date-fns/getDate");
var GetTime = require("date-fns/getTime");
var SetDate = require("date-fns/setDate");
var GetMonth = require("date-fns/getMonth");
var SetHours = require("date-fns/setHours");
var SetMonth = require("date-fns/setMonth");
var IsSameDay = require("date-fns/isSameDay");
var SetMinutes = require("date-fns/setMinutes");
var SetSeconds = require("date-fns/setSeconds");
var DifferenceInCalendarDays = require("date-fns/differenceInCalendarDays");

function resetDate(date) {
  return SetSeconds(SetMinutes(date, 0), 0);
}

function getNextChargingDate(recurrencePeriod, chargingMonthDay, chargingWeekDay, customRecurrencePeriod, customRecurrencePeriodStartingDate) {
  switch (recurrencePeriod) {
    case /* Monthly */0 :
        if (chargingMonthDay === undefined) {
          return new Date();
        }
        var nextMonthlyDate = resetDate(SetHours(SetDate(new Date(), chargingMonthDay), 10));
        if (GetTime(nextMonthlyDate) > Date.now()) {
          return nextMonthlyDate;
        } else {
          return SetMonth(nextMonthlyDate, GetMonth(nextMonthlyDate) + 1 | 0);
        }
    case /* Weekly */1 :
        if (chargingWeekDay === undefined) {
          return new Date();
        }
        var nextWeeklyDate = resetDate(SetHours(new Date(), 10));
        var daysOffset = ((chargingWeekDay + 7 | 0) - GetDay(nextWeeklyDate) | 0) % 7 | 0;
        return SetDate(nextWeeklyDate, GetDate(nextWeeklyDate) + (
                    daysOffset === 0 ? 7 : daysOffset
                  ) | 0);
    case /* Custom */2 :
        if (customRecurrencePeriod === undefined) {
          return new Date();
        }
        if (customRecurrencePeriodStartingDate === undefined) {
          return new Date();
        }
        var nextCustomDate = resetDate(SetHours(new Date(customRecurrencePeriodStartingDate), 10));
        if (GetTime(nextCustomDate) > Date.now()) {
          return nextCustomDate;
        }
        var daysDifference = DifferenceInCalendarDays(nextCustomDate, new Date());
        var chargeIndex = Js_math.ceil(daysDifference / customRecurrencePeriod);
        return SetDate(nextCustomDate, GetDate(nextCustomDate) + Math.imul(customRecurrencePeriod, chargeIndex) | 0);
    
  }
}

function getProportionalChargeAmount(recurrencePeriod, chargingMonthDay, chargingWeekDay, customRecurrencePeriod, customRecurrencePeriodStartingDate, wholeAmount) {
  var getPeriodSize = function (nextChargingDate) {
    switch (recurrencePeriod) {
      case /* Monthly */0 :
          var previousDate = SetMonth(new Date(GetTime(nextChargingDate)), GetMonth(nextChargingDate) - 1 | 0);
          return (GetTime(nextChargingDate) - GetTime(previousDate)) / 86400000.0 | 0;
      case /* Weekly */1 :
          return 7;
      case /* Custom */2 :
          if (customRecurrencePeriod !== undefined) {
            return customRecurrencePeriod;
          } else {
            return 0;
          }
      
    }
  };
  var nextChargingDate = getNextChargingDate(recurrencePeriod, chargingMonthDay, chargingWeekDay, customRecurrencePeriod, customRecurrencePeriodStartingDate);
  var periodSize = getPeriodSize(nextChargingDate);
  var daysDifference = DifferenceInCalendarDays(nextChargingDate, new Date());
  if (recurrencePeriod === /* Custom */2 && IsSameDay(nextChargingDate, new Date())) {
    return wholeAmount;
  }
  var wholeAmountBn = new BignumberJs(wholeAmount);
  return wholeAmountBn.dividedBy(periodSize).times(daysDifference).toNumber();
}

var millisecondsInDay = 86400000.0;

exports.resetDate = resetDate;
exports.millisecondsInDay = millisecondsInDay;
exports.getNextChargingDate = getNextChargingDate;
exports.getProportionalChargeAmount = getProportionalChargeAmount;
/* bignumber.js Not a pure module */
