'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Md = require("react-icons/md");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Tooltip$VannaFrontend = require("./Tooltip.bs.js");

function fieldLabelWrapper(hasError) {
  return Curry._1(Css.style, {
              hd: Css.padding({
                    NAME: "px",
                    VAL: 0
                  }),
              tl: {
                hd: Css.fontSize({
                      NAME: "rem",
                      VAL: 0.85
                    }),
                tl: {
                  hd: Css.marginBottom({
                        NAME: "rem",
                        VAL: 0.5
                      }),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.color(hasError ? Theme$VannaFrontend.Colors.error : Theme$VannaFrontend.Colors.textSecondary),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var asterisk = Curry._1(Css.style, {
      hd: Css.color(Theme$VannaFrontend.Colors.error),
      tl: {
        hd: Css.fontSize({
              NAME: "rem",
              VAL: 1.0
            }),
        tl: {
          hd: Css.marginLeft({
                NAME: "rem",
                VAL: 0.1
              }),
          tl: /* [] */0
        }
      }
    });

var wrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("flexStart"),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  fieldLabelWrapper: fieldLabelWrapper,
  asterisk: asterisk,
  wrapper: wrapper
};

function FieldLabel(Props) {
  var hasError = Props.hasError;
  var requiredOpt = Props.required;
  var tip = Props.tip;
  var floatRight = Props.floatRight;
  var children = Props.children;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  return React.createElement("label", {
              className: fieldLabelWrapper(hasError)
            }, React.createElement("div", {
                  className: wrapper
                }, children, required ? React.createElement("span", {
                        className: asterisk
                      }, "*") : null, tip !== undefined ? React.createElement(Tooltip$VannaFrontend.make, {
                        message: Caml_option.valFromOption(tip),
                        children: React.createElement(Md.MdHelp, {})
                      }) : null), floatRight !== undefined ? Caml_option.valFromOption(floatRight) : null);
}

var make = FieldLabel;

exports.Styles = Styles;
exports.make = make;
/* asterisk Not a pure module */
