'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"recurrentChargeCreate_recurrentCharge_chargeImmediately":{"n":""},"recurrentChargeCreate_recurrentCharge_customRecurrencePeriodStartingDate":{"n":""},"recurrentChargeCreate_recurrentCharge_chargeMonthDay":{"n":""},"recurrentChargeCreate_recurrentCharge_transactionCost":{"n":""},"recurrentChargeCreate_recurrentCharge_customer_name":{"n":""},"recurrentChargeCreate_recurrentCharge_askForConfirmation":{"n":""},"recurrentChargeCreate_recurrentCharge_customer_tradeName":{"n":""},"recurrentChargeCreate_error":{"n":""},"recurrentChargeCreate_recurrentCharge":{"n":""},"recurrentChargeCreate_recurrentCharge_firstChargeAmount":{"n":""},"recurrentChargeCreate":{"n":""},"recurrentChargeCreate_recurrentCharge_customRecurrencePeriod":{"n":""},"recurrentChargeCreate_recurrentCharge_description":{"n":""},"recurrentChargeCreate_recurrentCharge_chargeWeekDay":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"recurrentChargeCreate_recurrentCharge_chargeImmediately":{"n":""},"recurrentChargeCreate_recurrentCharge_customRecurrencePeriodStartingDate":{"n":""},"recurrentChargeCreate_recurrentCharge_chargeMonthDay":{"n":""},"recurrentChargeCreate_recurrentCharge_transactionCost":{"n":""},"recurrentChargeCreate_recurrentCharge_customer_name":{"n":""},"recurrentChargeCreate_recurrentCharge_askForConfirmation":{"n":""},"recurrentChargeCreate_recurrentCharge_customer_tradeName":{"n":""},"recurrentChargeCreate_error":{"n":""},"recurrentChargeCreate_recurrentCharge":{"n":""},"recurrentChargeCreate_recurrentCharge_firstChargeAmount":{"n":""},"recurrentChargeCreate":{"n":""},"recurrentChargeCreate_recurrentCharge_customRecurrencePeriod":{"n":""},"recurrentChargeCreate_recurrentCharge_description":{"n":""},"recurrentChargeCreate_recurrentCharge_chargeWeekDay":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"RecurrentChargeCreateInput":{"chargeWeekDay":{"n":""},"items":{"r":"ChargeItem"},"chargeMonthDay":{"n":""},"customRecurrencePeriodStartingDate":{"n":""},"customRecurrencePeriod":{"n":""},"affiliateId":{"n":""},"firstChargeAmount":{"n":""},"affiliatePercentage":{"n":""}},"__root":{"input":{"r":"RecurrentChargeCreateInput"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_chargeItem(discountAmount, freightAmount, insuranceAmount, itemId, othersAmount, quantity) {
  return {
          discountAmount: discountAmount,
          freightAmount: freightAmount,
          insuranceAmount: insuranceAmount,
          itemId: itemId,
          othersAmount: othersAmount,
          quantity: quantity
        };
}

function make_recurrentChargeCreateInput(affiliateId, affiliatePercentage, askForConfirmation, chargeImmediately, chargeMonthDay, chargeWeekDay, customRecurrencePeriod, customRecurrencePeriodStartingDate, customerId, description, firstChargeAmount, items, paymentMethod, recurrencePeriod, param) {
  return {
          affiliateId: affiliateId,
          affiliatePercentage: affiliatePercentage,
          askForConfirmation: askForConfirmation,
          chargeImmediately: chargeImmediately,
          chargeMonthDay: chargeMonthDay,
          chargeWeekDay: chargeWeekDay,
          customRecurrencePeriod: customRecurrencePeriod,
          customRecurrencePeriodStartingDate: customRecurrencePeriodStartingDate,
          customerId: customerId,
          description: description,
          firstChargeAmount: firstChargeAmount,
          items: items,
          paymentMethod: paymentMethod,
          recurrencePeriod: recurrencePeriod
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_recurrentChargeCreate_recurrentCharge_items(id, name, price, quantity, type_, discountAmount, freightAmount, insuranceAmount, othersAmount) {
  return {
          id: id,
          name: name,
          price: price,
          quantity: quantity,
          type_: type_,
          discountAmount: discountAmount,
          freightAmount: freightAmount,
          insuranceAmount: insuranceAmount,
          othersAmount: othersAmount
        };
}

function make_response_recurrentChargeCreate_recurrentCharge_customer(id, name, tradeName, param) {
  return {
          id: id,
          name: name,
          tradeName: tradeName
        };
}

function make_response_recurrentChargeCreate_recurrentCharge(id, amount, description, friendlyId, paymentMethod, status, transactionCost, askForConfirmation, chargeMonthDay, chargeWeekDay, customRecurrencePeriod, customRecurrencePeriodStartingDate, chargeImmediately, firstChargeAmount, customer, items, param) {
  return {
          id: id,
          amount: amount,
          description: description,
          friendlyId: friendlyId,
          paymentMethod: paymentMethod,
          status: status,
          transactionCost: transactionCost,
          askForConfirmation: askForConfirmation,
          chargeMonthDay: chargeMonthDay,
          chargeWeekDay: chargeWeekDay,
          customRecurrencePeriod: customRecurrencePeriod,
          customRecurrencePeriodStartingDate: customRecurrencePeriodStartingDate,
          chargeImmediately: chargeImmediately,
          firstChargeAmount: firstChargeAmount,
          customer: customer,
          items: items
        };
}

function make_response_recurrentChargeCreate_error(message) {
  return {
          message: message
        };
}

function make_response_recurrentChargeCreate(error, recurrentCharge, param) {
  return {
          error: error,
          recurrentCharge: recurrentCharge
        };
}

function makeOptimisticResponse(recurrentChargeCreate, param) {
  return {
          recurrentChargeCreate: recurrentChargeCreate
        };
}

var Utils = {
  make_chargeItem: make_chargeItem,
  make_recurrentChargeCreateInput: make_recurrentChargeCreateInput,
  makeVariables: makeVariables,
  make_response_recurrentChargeCreate_recurrentCharge_items: make_response_recurrentChargeCreate_recurrentCharge_items,
  make_response_recurrentChargeCreate_recurrentCharge_customer: make_response_recurrentChargeCreate_recurrentCharge_customer,
  make_response_recurrentChargeCreate_recurrentCharge: make_response_recurrentChargeCreate_recurrentCharge,
  make_response_recurrentChargeCreate_error: make_response_recurrentChargeCreate_error,
  make_response_recurrentChargeCreate: make_response_recurrentChargeCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RecurrentChargeCreatePayload",
    "kind": "LinkedField",
    "name": "recurrentChargeCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RecurrentCharge",
        "kind": "LinkedField",
        "name": "recurrentCharge",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "friendlyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentMethod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transactionCost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "askForConfirmation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chargeMonthDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chargeWeekDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customRecurrencePeriod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customRecurrencePeriodStartingDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chargeImmediately",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstChargeAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tradeName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChargeItemType",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": "type_",
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "discountAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freightAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insuranceAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "othersAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecurrentChargeUpsertForm_RecurrentChargeCreateMutation",
    "selections": (v3/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecurrentChargeUpsertForm_RecurrentChargeCreateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f19ad00cd4ca1c8b5dfab9e9c4e05a16",
    "id": null,
    "metadata": {},
    "name": "RecurrentChargeUpsertForm_RecurrentChargeCreateMutation",
    "operationKind": "mutation",
    "text": "mutation RecurrentChargeUpsertForm_RecurrentChargeCreateMutation(\n  $input: RecurrentChargeCreateInput!\n) {\n  recurrentChargeCreate(input: $input) {\n    error {\n      message\n    }\n    recurrentCharge {\n      id\n      amount\n      description\n      friendlyId\n      paymentMethod\n      status\n      transactionCost\n      askForConfirmation\n      chargeMonthDay\n      chargeWeekDay\n      customRecurrencePeriod\n      customRecurrencePeriodStartingDate\n      chargeImmediately\n      firstChargeAmount\n      customer {\n        id\n        name\n        tradeName\n      }\n      items {\n        id\n        name\n        price\n        quantity\n        type_: type\n        discountAmount\n        freightAmount\n        insuranceAmount\n        othersAmount\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
