'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"userResetPassword_error":{"n":""},"userResetPassword":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"userResetPassword_error":{"n":""},"userResetPassword":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"UserResetPasswordInput"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_userResetPasswordInput(email, newPassword, token) {
  return {
          email: email,
          newPassword: newPassword,
          token: token
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_userResetPassword_error(message) {
  return {
          message: message
        };
}

function make_response_userResetPassword(error, param) {
  return {
          error: error
        };
}

function makeOptimisticResponse(userResetPassword, param) {
  return {
          userResetPassword: userResetPassword
        };
}

var Utils = {
  make_userResetPasswordInput: make_userResetPasswordInput,
  makeVariables: makeVariables,
  make_response_userResetPassword_error: make_response_userResetPassword_error,
  make_response_userResetPassword: make_response_userResetPassword,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserResetPasswordPayload",
    "kind": "LinkedField",
    "name": "userResetPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPassword_UserResetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPassword_UserResetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f6a263e5b2171caf20552505ac8e54fa",
    "id": null,
    "metadata": {},
    "name": "ResetPassword_UserResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ResetPassword_UserResetPasswordMutation(\n  $input: UserResetPasswordInput!\n) {\n  userResetPassword(input: $input) {\n    error {\n      message\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
