'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Uuid = require("uuid");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Format = require("date-fns/format");
var Box$VannaFrontend = require("../components/Box.bs.js");
var Hooks = require("react-relay/hooks");
var Text$VannaFrontend = require("../components/Text.bs.js");
var Button$VannaFrontend = require("../components/Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var BrandPanel$VannaFrontend = require("../components/BrandPanel.bs.js");
var ItemsTable$VannaFrontend = require("../components/ItemsTable.bs.js");
var FieldSection$VannaFrontend = require("../components/FieldSection.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var ChargePaymentConfirmQuery_graphql$VannaFrontend = require("../__generated__/ChargePaymentConfirmQuery_graphql.bs.js");
var ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend = require("../__generated__/ChargePaymentConfirm_ChargePaymentRejectMutation_graphql.bs.js");
var ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend = require("../__generated__/ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql.bs.js");

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(ChargePaymentConfirmQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ChargePaymentConfirmQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(ChargePaymentConfirmQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(ChargePaymentConfirmQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, ChargePaymentConfirmQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, ChargePaymentConfirmQuery_graphql$VannaFrontend.node, ChargePaymentConfirmQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: ChargePaymentConfirmQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, ChargePaymentConfirmQuery_graphql$VannaFrontend.node, ChargePaymentConfirmQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return ChargePaymentConfirmQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(ChargePaymentConfirmQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(ChargePaymentConfirmQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var ChargePaymentConfirmQuery_makeVariables = ChargePaymentConfirmQuery_graphql$VannaFrontend.Utils.makeVariables;

var ChargePaymentConfirmQuery = {
  makeVariables: ChargePaymentConfirmQuery_makeVariables,
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.node,
              variables: ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ChargePaymentConfirmMutation_make_chargeConfirmInput = ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Utils.make_chargeConfirmInput;

var ChargePaymentConfirmMutation_makeVariables = ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Utils.makeVariables;

var ChargePaymentConfirmMutation_make_response_chargeConfirm_charge = ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Utils.make_response_chargeConfirm_charge;

var ChargePaymentConfirmMutation_make_response_chargeConfirm_error = ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Utils.make_response_chargeConfirm_error;

var ChargePaymentConfirmMutation_make_response_chargeConfirm = ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Utils.make_response_chargeConfirm;

var ChargePaymentConfirmMutation_makeOptimisticResponse = ChargePaymentConfirm_ChargePaymentConfirmMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ChargePaymentConfirmMutation = {
  make_chargeConfirmInput: ChargePaymentConfirmMutation_make_chargeConfirmInput,
  makeVariables: ChargePaymentConfirmMutation_makeVariables,
  make_response_chargeConfirm_charge: ChargePaymentConfirmMutation_make_response_chargeConfirm_charge,
  make_response_chargeConfirm_error: ChargePaymentConfirmMutation_make_response_chargeConfirm_error,
  make_response_chargeConfirm: ChargePaymentConfirmMutation_make_response_chargeConfirm,
  makeOptimisticResponse: ChargePaymentConfirmMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.node,
              variables: ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ChargePaymentRejectMutation_make_chargeRejectInput = ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Utils.make_chargeRejectInput;

var ChargePaymentRejectMutation_makeVariables = ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Utils.makeVariables;

var ChargePaymentRejectMutation_make_response_chargeReject_charge = ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Utils.make_response_chargeReject_charge;

var ChargePaymentRejectMutation_make_response_chargeReject_error = ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Utils.make_response_chargeReject_error;

var ChargePaymentRejectMutation_make_response_chargeReject = ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Utils.make_response_chargeReject;

var ChargePaymentRejectMutation_makeOptimisticResponse = ChargePaymentConfirm_ChargePaymentRejectMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ChargePaymentRejectMutation = {
  make_chargeRejectInput: ChargePaymentRejectMutation_make_chargeRejectInput,
  makeVariables: ChargePaymentRejectMutation_makeVariables,
  make_response_chargeReject_charge: ChargePaymentRejectMutation_make_response_chargeReject_charge,
  make_response_chargeReject_error: ChargePaymentRejectMutation_make_response_chargeReject_error,
  make_response_chargeReject: ChargePaymentRejectMutation_make_response_chargeReject,
  makeOptimisticResponse: ChargePaymentRejectMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

var textSection = Curry._1(Css.style, {
      hd: Css.marginBottom({
            NAME: "px",
            VAL: 20
          }),
      tl: /* [] */0
    });

var text = Curry._1(Css.style, {
      hd: Css.lineHeight({
            NAME: "px",
            VAL: 6
          }),
      tl: /* [] */0
    });

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexStart"),
        tl: {
          hd: Css.marginTop({
                NAME: "px",
                VAL: 15
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  textSection: textSection,
  text: text,
  buttons: buttons
};

function ChargePaymentConfirm(Props) {
  var chargeId = Props.chargeId;
  var queryData = use({
        id: chargeId
      }, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var error = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setHasSuccess = match$1[1];
  var hasSuccess = match$1[0];
  var match$2 = use$1(undefined);
  var confirmCharge = match$2[0];
  var match$3 = use$2(undefined);
  var rejectCharge = match$3[0];
  var charge = queryData.node;
  if (charge === undefined) {
    return React.createElement(BrandPanel$VannaFrontend.make, {
                children: React.createElement(Text$VannaFrontend.make, {
                      children: "Cobrança não encontrada"
                    })
              });
  }
  var description = charge.description;
  var date = charge.date;
  var match$4 = charge.status;
  var match$5 = charge.paymentMethod;
  var match$6 = charge.status;
  var match$7 = charge.paidAt;
  var match$8 = charge.status;
  var match$9 = charge.canceledAt;
  var match$10 = charge.status;
  var match$11 = charge.refundedAt;
  var match$12 = charge.status;
  var match$13 = charge.rejectedAt;
  var match$14 = charge.status;
  return React.createElement(BrandPanel$VannaFrontend.make, {
              title: "Confirmação de pagamento de " + Belt_Option.getWithDefault(charge.createdByName, "Vanna"),
              children: null
            }, React.createElement("section", {
                  className: textSection
                }, React.createElement(Text$VannaFrontend.make, {
                      className: text,
                      children: null
                    }, React.createElement("strong", undefined, "Identificador: "), Belt_Option.getWithDefault(charge.friendlyId, "-")), description !== undefined ? React.createElement(Text$VannaFrontend.make, {
                        className: text,
                        children: null
                      }, React.createElement("strong", undefined, "Descrição: "), description) : null, date !== undefined ? React.createElement(Text$VannaFrontend.make, {
                        className: text,
                        children: null
                      }, React.createElement("strong", undefined, "Data: "), Format(new Date(date), "dd/MM/yyyy kk:mm")) : null, React.createElement(Text$VannaFrontend.make, {
                      className: text,
                      children: null
                    }, React.createElement("strong", undefined, "Situação: "), match$4 !== undefined ? (
                        match$4 === "CANCELED" ? "Cancelado" : (
                            match$4 === "REJECTED_BY_CUSTOMER" ? "Rejeitado" : (
                                match$4 === "AWAITING_PAYMENT" ? "Aguardando pagamento" : (
                                    match$4 === "REFUNDED" ? "Estornado" : (
                                        match$4 === "PAID" ? "Pago" : ""
                                      )
                                  )
                              )
                          )
                      ) : ""), React.createElement(Text$VannaFrontend.make, {
                      className: text,
                      children: null
                    }, React.createElement("strong", undefined, "Método de pagamento: "), match$5 !== undefined ? (
                        match$5 === "PIX" ? "PIX" : (
                            match$5 === "CREDIT_CARD" ? "Cartão de crédito" : "-"
                          )
                      ) : "-"), match$6 !== undefined && match$6 === "PAID" && match$7 !== undefined ? React.createElement(Text$VannaFrontend.make, {
                        className: text,
                        children: null
                      }, React.createElement("strong", undefined, "Paga em "), Format(new Date(match$7), "dd/MM/yyyy kk:mm")) : null, match$8 !== undefined && match$8 === "CANCELED" && match$9 !== undefined ? React.createElement(Text$VannaFrontend.make, {
                        className: text,
                        children: React.createElement("strong", undefined, "Cancelada em ", Format(new Date(match$9), "dd/MM/yyyy kk:mm"))
                      }) : null, match$10 !== undefined && match$10 === "REFUNDED" && match$11 !== undefined ? React.createElement(Text$VannaFrontend.make, {
                        className: text,
                        children: null
                      }, React.createElement("strong", undefined, "Estornada em "), Format(new Date(match$11), "dd/MM/yyyy kk:mm")) : null, match$12 !== undefined && match$12 === "REJECTED_BY_CUSTOMER" && match$13 !== undefined ? React.createElement(Text$VannaFrontend.make, {
                        className: text,
                        children: null
                      }, React.createElement("strong", undefined, "Rejeitada em "), Format(new Date(match$13), "dd/MM/yyyy kk:mm")) : null), React.createElement(ItemsTable$VannaFrontend.make, {
                  title: "",
                  data: Belt_Array.map(Belt_Option.getWithDefault(charge.items, []), (function (item) {
                          return {
                                  name: item.name,
                                  price: item.price,
                                  quantity: item.quantity,
                                  freightAmount: item.freightAmount,
                                  insuranceAmount: item.insuranceAmount,
                                  othersAmount: item.othersAmount,
                                  discountAmount: item.discountAmount,
                                  itemId: item.id,
                                  temporaryId: Uuid.v4(),
                                  maxQuantity: 0,
                                  hasMaxQuantity: false,
                                  chargeType: /* Single */0
                                };
                        })),
                  mode: /* Small */1,
                  readOnly: true,
                  domain: /* Charge */0
                }), match$14 !== undefined ? (
                match$14 === "REJECTED_BY_CUSTOMER" ? React.createElement(Box$VannaFrontend.make, {
                        type_: /* Warn */1,
                        title: "Rejeitada",
                        children: "Confirmação de pagamento rejeitada"
                      }) : (
                    match$14 === "AWAITING_PAYMENT" ? React.createElement(React.Fragment, undefined, React.createElement(FieldSection$VannaFrontend.make, {
                                children: "Você deseja confirmar o pagamento?"
                              }), React.createElement("div", {
                                className: buttons
                              }, React.createElement(Button$VannaFrontend.make, {
                                    mode: /* Danger */2,
                                    isLoading: match$3[1],
                                    onClick: (function (param) {
                                        Curry.app(rejectCharge, [
                                              (function (param) {
                                                  return Curry._1(setError, (function (param) {
                                                                return "Algo deu errado, tente novamente";
                                                              }));
                                                }),
                                              (function (response, errors) {
                                                  if (errors !== undefined && errors.length !== 0) {
                                                    return Curry._1(setError, (function (param) {
                                                                  return "Algo deu errado, tente novamente";
                                                                }));
                                                  }
                                                  var match = response.chargeReject;
                                                  if (match === undefined) {
                                                    return Curry._1(setError, (function (param) {
                                                                  return "Algo deu errado, tente novamente";
                                                                }));
                                                  }
                                                  var match$1 = match.error;
                                                  if (match$1 === undefined) {
                                                    return ;
                                                  }
                                                  var message = match$1.message;
                                                  return Curry._1(setError, (function (param) {
                                                                return message;
                                                              }));
                                                }),
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              {
                                                input: {
                                                  chargeId: chargeId
                                                }
                                              },
                                              undefined,
                                              undefined
                                            ]);
                                        
                                      }),
                                    children: "Não"
                                  }), React.createElement(Button$VannaFrontend.make, {
                                    isLoading: match$2[1],
                                    onClick: (function (param) {
                                        Curry._1(setHasSuccess, (function (param) {
                                                return false;
                                              }));
                                        Curry.app(confirmCharge, [
                                              (function (param) {
                                                  return Curry._1(setError, (function (param) {
                                                                return "Algo deu errado, tente novamente";
                                                              }));
                                                }),
                                              (function (response, errors) {
                                                  if (errors !== undefined && errors.length !== 0) {
                                                    return Curry._1(setError, (function (param) {
                                                                  return "Algo deu errado, tente novamente";
                                                                }));
                                                  }
                                                  var match = response.chargeConfirm;
                                                  if (match === undefined) {
                                                    return Curry._1(setError, (function (param) {
                                                                  return "Algo deu errado, tente novamente";
                                                                }));
                                                  }
                                                  var match$1 = match.error;
                                                  if (match$1 === undefined) {
                                                    return Curry._1(setHasSuccess, (function (param) {
                                                                  return true;
                                                                }));
                                                  }
                                                  var message = match$1.message;
                                                  return Curry._1(setError, (function (param) {
                                                                return message;
                                                              }));
                                                }),
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              {
                                                input: {
                                                  chargeId: chargeId
                                                }
                                              },
                                              undefined,
                                              undefined
                                            ]);
                                        
                                      }),
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginLeft({
                                                NAME: "px",
                                                VAL: 15
                                              }),
                                          tl: /* [] */0
                                        }),
                                    children: "Sim"
                                  })), error !== undefined && !hasSuccess ? React.createElement(Box$VannaFrontend.make, {
                                  type_: /* Error */2,
                                  title: "Ops!",
                                  children: error
                                }) : null, hasSuccess ? React.createElement(Box$VannaFrontend.make, {
                                  type_: /* Success */0,
                                  title: "Sucesso",
                                  children: "Confirmação de pagamento realizada com sucesso"
                                }) : null) : (
                        match$14 === "PAID" ? React.createElement(Box$VannaFrontend.make, {
                                type_: /* Success */0,
                                title: "Sucesso",
                                children: "Confirmação de pagamento realizada com sucesso"
                              }) : null
                      )
                  )
              ) : null);
}

var make = ChargePaymentConfirm;

exports.ChargePaymentConfirmQuery = ChargePaymentConfirmQuery;
exports.ChargePaymentConfirmMutation = ChargePaymentConfirmMutation;
exports.ChargePaymentRejectMutation = ChargePaymentRejectMutation;
exports.Styles = Styles;
exports.make = make;
/* textSection Not a pure module */
