import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import color from "color";

import { Colors } from "../Theme.bs";

export default ({
  value,
  loadOptions,
  options,
  placeholder,
  onChange,
  onInputChange,
  disabled,
}) => {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <AsyncPaginate
      defaultOptions
      value={selectedOption}
      options={options}
      loadOptions={loadOptions}
      loadingMessage={() => "Carregando..."}
      noOptionsMessage={() => "Nenhum resultado"}
      placeholder={placeholder}
      cacheUniqs={[(options || []).length]}
      styles={{
        control: (provided) => ({
          ...provided,
          border: `1px solid ${Colors.toString(Colors.border)}`,
          boxShadow: "none",
          maxWidth: 700,
          boxSizing: "border-box",
        }),
        valueContainer: (provided) => ({
          ...provided,
          border: "none",
          fontSize: "0.85rem",
          lineHeight: "0.85em",
          padding: "calc(0.75rem - 2.5px) 0.9rem",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: Colors.toString(Colors.background),
        }),
        option: (provided) => ({
          ...provided,
          fontSize: "0.85rem",
          color: Colors.toString(Colors.text),
        }),
        noOptionsMessage: (provided) => ({
          ...provided,
          fontSize: "0.85rem",
        }),
        loadingMessage: (provided) => ({
          ...provided,
          fontSize: "0.85rem",
        }),
      }}
      theme={(defaultTheme) => ({
        ...defaultTheme,
        borderRadius: 4,
        colors: {
          ...defaultTheme.colors,
          neutral0: Colors.toString(Colors.secondary),
          primary25: Colors.toString(Colors.background),
          primary50: color(Colors.toString(Colors.background))
            .darken(0.125)
            .string(),
          primary: color(Colors.toString(Colors.background))
            .darken(0.175)
            .string(),
          neutral50: Colors.toString(Colors.textSecondary),
          neutral80: Colors.toString(Colors.text),
        },
      })}
      onChange={onChange}
      onInputChange={onInputChange}
      isDisabled={disabled}
    />
  );
};
