'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"apiKeyCreate_secretKey":{"n":""},"apiKeyCreate_apiKey":{"n":""},"apiKeyCreate_error":{"n":""},"apiKeyCreate":{"n":""},"apiKeyCreate_apiKey_description":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"apiKeyCreate_secretKey":{"n":""},"apiKeyCreate_apiKey":{"n":""},"apiKeyCreate_error":{"n":""},"apiKeyCreate":{"n":""},"apiKeyCreate_apiKey_description":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"ApiKeyCreateInput"}},"ApiKeyCreateInput":{"description":{"n":""}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_apiKeyCreateInput(description, param) {
  return {
          description: description
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_apiKeyCreate_apiKey(id, keyId, description, param) {
  return {
          id: id,
          keyId: keyId,
          description: description
        };
}

function make_response_apiKeyCreate_error(message) {
  return {
          message: message
        };
}

function make_response_apiKeyCreate(error, secretKey, apiKey, param) {
  return {
          error: error,
          secretKey: secretKey,
          apiKey: apiKey
        };
}

function makeOptimisticResponse(apiKeyCreate, param) {
  return {
          apiKeyCreate: apiKeyCreate
        };
}

var Utils = {
  make_apiKeyCreateInput: make_apiKeyCreateInput,
  makeVariables: makeVariables,
  make_response_apiKeyCreate_apiKey: make_response_apiKeyCreate_apiKey,
  make_response_apiKeyCreate_error: make_response_apiKeyCreate_error,
  make_response_apiKeyCreate: make_response_apiKeyCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ApiKeyCreatePayload",
    "kind": "LinkedField",
    "name": "apiKeyCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "secretKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApiKey",
        "kind": "LinkedField",
        "name": "apiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "keyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApiKeyUpsertForm_ApiKeyCreateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApiKeyUpsertForm_ApiKeyCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c917dcec7680645a2d030066a3cda101",
    "id": null,
    "metadata": {},
    "name": "ApiKeyUpsertForm_ApiKeyCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ApiKeyUpsertForm_ApiKeyCreateMutation(\n  $input: ApiKeyCreateInput!\n) {\n  apiKeyCreate(input: $input) {\n    error {\n      message\n    }\n    secretKey\n    apiKey {\n      id\n      keyId\n      description\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
