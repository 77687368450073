'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$VannaFrontend = require("../Theme.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

function header(withMarginBottom, withMarginTop) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.marginBottom(withMarginBottom ? Theme$VannaFrontend.Margin.content : ({
                            NAME: "rem",
                            VAL: 1.2
                          })),
                  tl: {
                    hd: Css.marginTop(withMarginTop ? ({
                              NAME: "rem",
                              VAL: 1.5
                            }) : "initial"),
                    tl: {
                      hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                            hd: Css.flexDirection("column"),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  header: header
};

function Header(Props) {
  var withMarginBottomOpt = Props.withMarginBottom;
  var withMarginTopOpt = Props.withMarginTop;
  var classNameOpt = Props.className;
  var children = Props.children;
  var withMarginBottom = withMarginBottomOpt !== undefined ? withMarginBottomOpt : false;
  var withMarginTop = withMarginTopOpt !== undefined ? withMarginTopOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: header(withMarginBottom, withMarginTop),
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  })
            }, children);
}

var make = Header;

exports.Styles = Styles;
exports.make = make;
/* Css Not a pure module */
