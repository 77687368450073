'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function wrapper(hasPadding) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.fontSize({
                            NAME: "rem",
                            VAL: 0.85
                          }),
                      tl: {
                        hd: Css.padding(hasPadding ? ({
                                  NAME: "px",
                                  VAL: 20
                                }) : ({
                                  NAME: "px",
                                  VAL: 0
                                })),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var Styles = {
  wrapper: wrapper
};

function Loading(Props) {
  var hasPaddingOpt = Props.hasPadding;
  var hasIconOpt = Props.hasIcon;
  var hasPadding = hasPaddingOpt !== undefined ? hasPaddingOpt : false;
  var hasIcon = hasIconOpt !== undefined ? hasIconOpt : false;
  return React.createElement("div", {
              className: wrapper(hasPadding)
            }, hasIcon ? React.createElement("img", {
                    alt: "Carregando...",
                    src: "/loading.svg"
                  }) : null, React.createElement("span", undefined, "Carregando..."));
}

var make = Loading;

exports.Styles = Styles;
exports.make = make;
/* Css Not a pure module */
