'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Hooks = require("react-relay/hooks");
var Tabs$VannaFrontend = require("../components/Tabs.bs.js");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var RequireKYC$VannaFrontend = require("../components/RequireKYC.bs.js");
var ChargesTable$VannaFrontend = require("../components/ChargesTable.bs.js");
var DateSelectors$VannaFrontend = require("../components/DateSelectors.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var ChargeUpsertForm$VannaFrontend = require("../components/ChargeUpsertForm.bs.js");
var PageQueryRenderer$VannaFrontend = require("../components/PageQueryRenderer.bs.js");
var ChargesQuery_graphql$VannaFrontend = require("../__generated__/ChargesQuery_graphql.bs.js");
var ChargesProjectionsChart$VannaFrontend = require("../components/ChargesProjectionsChart.bs.js");

var chartWrapper = Curry._1(Css.style, {
      hd: Css.padding(Theme$VannaFrontend.Padding.content),
      tl: /* [] */0
    });

var Styles = {
  chartWrapper: chartWrapper
};

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(ChargesQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ChargesQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(ChargesQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(ChargesQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, ChargesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, ChargesQuery_graphql$VannaFrontend.node, ChargesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: ChargesQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, ChargesQuery_graphql$VannaFrontend.node, ChargesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return ChargesQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(ChargesQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(ChargesQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var ChargesQuery_makeVariables = ChargesQuery_graphql$VannaFrontend.Utils.makeVariables;

var ChargesQuery = {
  makeVariables: ChargesQuery_makeVariables,
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

function Charges$Inner(Props) {
  var $$navigator = Props.navigator;
  var match = React.useState(function () {
        return String(new Date().getFullYear() | 0);
      });
  var setYear = match[1];
  var year = match[0];
  var match$1 = React.useState(function () {
        return String(new Date().getMonth() | 0);
      });
  var setMonth = match$1[1];
  var month = match$1[0];
  var queryData = use({
        currentYear: Caml_format.caml_int_of_string(year),
        status: undefined,
        month: undefined,
        year: Caml_format.caml_int_of_string(year)
      }, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var match$2 = queryData.me;
  var charges = match$2 !== undefined ? match$2.allCharges : [];
  var chargesByType = React.useMemo((function () {
          return Belt_Array.reduce(charges, {
                      paid: 0,
                      awaitingPayment: 0,
                      canceled: 0,
                      refunded: 0,
                      rejectedByCustomer: 0
                    }, (function (acc, cur) {
                        var match = cur.status;
                        if (match === "CANCELED") {
                          return {
                                  paid: acc.paid,
                                  awaitingPayment: acc.awaitingPayment,
                                  canceled: acc.canceled + 1 | 0,
                                  refunded: acc.refunded,
                                  rejectedByCustomer: acc.rejectedByCustomer
                                };
                        } else if (match === "REJECTED_BY_CUSTOMER") {
                          return {
                                  paid: acc.paid,
                                  awaitingPayment: acc.awaitingPayment,
                                  canceled: acc.canceled,
                                  refunded: acc.refunded,
                                  rejectedByCustomer: acc.rejectedByCustomer + 1 | 0
                                };
                        } else if (match === "AWAITING_PAYMENT") {
                          return {
                                  paid: acc.paid,
                                  awaitingPayment: acc.awaitingPayment + 1 | 0,
                                  canceled: acc.canceled,
                                  refunded: acc.refunded,
                                  rejectedByCustomer: acc.rejectedByCustomer
                                };
                        } else if (match === "REFUNDED") {
                          return {
                                  paid: acc.paid,
                                  awaitingPayment: acc.awaitingPayment,
                                  canceled: acc.canceled,
                                  refunded: acc.refunded + 1 | 0,
                                  rejectedByCustomer: acc.rejectedByCustomer
                                };
                        } else if (match === "PAID") {
                          return {
                                  paid: acc.paid + 1 | 0,
                                  awaitingPayment: acc.awaitingPayment,
                                  canceled: acc.canceled,
                                  refunded: acc.refunded,
                                  rejectedByCustomer: acc.rejectedByCustomer
                                };
                        } else {
                          return acc;
                        }
                      }));
        }), [charges]);
  var me = queryData.me;
  if (me !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement(RequireKYC$VannaFrontend.make, {
                    navigator: $$navigator,
                    fragment: me.fragmentRefs,
                    children: (function (param) {
                        var me$1 = queryData.me;
                        return React.createElement(React.Fragment, undefined, React.createElement(PageHeader$VannaFrontend.make, {
                                        title: "Vendas",
                                        upsertModal: {
                                          renderModal: (function (dispatch) {
                                              return React.createElement(ChargeUpsertForm$VannaFrontend.make, {
                                                          charge: undefined,
                                                          dispatchModal: dispatch,
                                                          query: me.fragmentRefs
                                                        });
                                            }),
                                          displayName: "venda manualmente"
                                        }
                                      }), React.createElement(DateSelectors$VannaFrontend.YearSelector.make, {
                                        selected: year,
                                        setSelected: (function (value) {
                                            return Curry._1(setYear, (function (param) {
                                                          return value;
                                                        }));
                                          })
                                      }), me$1 !== undefined ? React.createElement(ChargesProjectionsChart$VannaFrontend.make, {
                                          data: me$1.fragmentRefs,
                                          year: year,
                                          month: month
                                        }) : null, React.createElement(DateSelectors$VannaFrontend.MonthSelector.make, {
                                        selected: month,
                                        setSelected: (function (value) {
                                            return Curry._1(setMonth, (function (param) {
                                                          return value;
                                                        }));
                                          })
                                      }), React.createElement(Panel$VannaFrontend.make, {
                                        children: React.createElement(Tabs$VannaFrontend.make, {
                                              list_: [
                                                {
                                                  name: "recebidos",
                                                  title: "Recebidos (" + (String(chargesByType.paid) + ")"),
                                                  disabled: false,
                                                  badge: null,
                                                  body: React.createElement(ChargesTable$VannaFrontend.make, {
                                                        navigator: $$navigator,
                                                        kind: /* Paid */0,
                                                        charges: Belt_Array.keep(charges, (function (charge) {
                                                                return charge.status === "PAID";
                                                              })),
                                                        query: me.fragmentRefs
                                                      })
                                                },
                                                {
                                                  name: "aguardando-pagamento",
                                                  title: "Aguardando pagamento (" + (String(chargesByType.awaitingPayment) + ")"),
                                                  disabled: false,
                                                  badge: null,
                                                  body: React.createElement(ChargesTable$VannaFrontend.make, {
                                                        navigator: $$navigator,
                                                        kind: /* AwaitingPayment */1,
                                                        charges: Belt_Array.keep(charges, (function (charge) {
                                                                return charge.status === "AWAITING_PAYMENT";
                                                              })),
                                                        query: me.fragmentRefs
                                                      })
                                                },
                                                {
                                                  name: "cancelados",
                                                  title: "Cancelados (" + (String(chargesByType.canceled) + ")"),
                                                  disabled: false,
                                                  badge: null,
                                                  body: React.createElement(ChargesTable$VannaFrontend.make, {
                                                        navigator: $$navigator,
                                                        kind: /* Canceled */2,
                                                        charges: Belt_Array.keep(charges, (function (charge) {
                                                                return charge.status === "CANCELED";
                                                              })),
                                                        query: me.fragmentRefs
                                                      })
                                                },
                                                {
                                                  name: "devolvidos",
                                                  title: "Devolvidos (" + (String(chargesByType.refunded) + ")"),
                                                  disabled: false,
                                                  badge: null,
                                                  body: React.createElement(ChargesTable$VannaFrontend.make, {
                                                        navigator: $$navigator,
                                                        kind: /* Refunded */3,
                                                        charges: Belt_Array.keep(charges, (function (charge) {
                                                                return charge.status === "REFUNDED";
                                                              })),
                                                        query: me.fragmentRefs
                                                      })
                                                },
                                                {
                                                  name: "rejeitados",
                                                  title: "Rejeitados pelo cliente (" + (String(chargesByType.rejectedByCustomer) + ")"),
                                                  disabled: false,
                                                  badge: null,
                                                  body: React.createElement(ChargesTable$VannaFrontend.make, {
                                                        navigator: $$navigator,
                                                        kind: /* RejectedByCustomer */4,
                                                        charges: Belt_Array.keep(charges, (function (charge) {
                                                                return charge.status === "REJECTED_BY_CUSTOMER";
                                                              })),
                                                        query: me.fragmentRefs
                                                      })
                                                }
                                              ],
                                              withPadding: true
                                            })
                                      }));
                      })
                  }));
  } else {
    return null;
  }
}

var Inner = {
  make: Charges$Inner
};

function Charges(Props) {
  var $$navigator = Props.navigator;
  return React.createElement(PageQueryRenderer$VannaFrontend.make, {
              children: React.createElement(PrivateLayout$VannaFrontend.make, {
                    navigator: $$navigator,
                    children: React.createElement(Charges$Inner, {
                          navigator: $$navigator
                        })
                  })
            });
}

var make = Charges;

exports.Styles = Styles;
exports.ChargesQuery = ChargesQuery;
exports.Inner = Inner;
exports.make = make;
/* chartWrapper Not a pure module */
