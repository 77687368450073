'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function Redirect(Props) {
  var $$navigator = Props.navigator;
  var route = Props.route;
  React.useEffect((function () {
          Curry._1($$navigator.replace, route);
          
        }), []);
  return null;
}

var make = Redirect;

exports.make = make;
/* react Not a pure module */
