'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function sectionLabel(noMarginTop) {
  return Curry._1(Css.style, {
              hd: Css.fontWeight("medium"),
              tl: {
                hd: Css.fontSize({
                      NAME: "rem",
                      VAL: 0.95
                    }),
                tl: {
                  hd: Css.marginTop(noMarginTop ? ({
                            NAME: "px",
                            VAL: 0
                          }) : ({
                            NAME: "px",
                            VAL: 15
                          })),
                  tl: /* [] */0
                }
              }
            });
}

var Styles = {
  sectionLabel: sectionLabel
};

function FieldSection(Props) {
  var children = Props.children;
  var noMarginTopOpt = Props.noMarginTop;
  var noMarginTop = noMarginTopOpt !== undefined ? noMarginTopOpt : false;
  return React.createElement("h5", {
              className: sectionLabel(noMarginTop)
            }, children);
}

var make = FieldSection;

exports.Styles = Styles;
exports.make = make;
/* Css Not a pure module */
