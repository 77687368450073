'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"bankAccountCreate":{"n":""},"bankAccountCreate_error":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"bankAccountCreate":{"n":""},"bankAccountCreate_error":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"BankAccountCreateInput"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_bankAccountCreateInput(accountNumber, accountType, bankCode, bankName, holderName, routingNumber, taxId) {
  return {
          accountNumber: accountNumber,
          accountType: accountType,
          bankCode: bankCode,
          bankName: bankName,
          holderName: holderName,
          routingNumber: routingNumber,
          taxId: taxId
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_bankAccountCreate_error(message) {
  return {
          message: message
        };
}

function make_response_bankAccountCreate(error, param) {
  return {
          error: error
        };
}

function makeOptimisticResponse(bankAccountCreate, param) {
  return {
          bankAccountCreate: bankAccountCreate
        };
}

var Utils = {
  make_bankAccountCreateInput: make_bankAccountCreateInput,
  makeVariables: makeVariables,
  make_response_bankAccountCreate_error: make_response_bankAccountCreate_error,
  make_response_bankAccountCreate: make_response_bankAccountCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BankAccountCreatePayload",
    "kind": "LinkedField",
    "name": "bankAccountCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BankAccountInfoForm_BankAccountInfoUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BankAccountInfoForm_BankAccountInfoUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e2182d245104cf0e25ee7fff8342751",
    "id": null,
    "metadata": {},
    "name": "BankAccountInfoForm_BankAccountInfoUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation BankAccountInfoForm_BankAccountInfoUpdateMutation(\n  $input: BankAccountCreateInput!\n) {\n  bankAccountCreate(input: $input) {\n    error {\n      message\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
