'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

function unwrap_response_pixWithdrawal_transaction_metadata(u) {
  var v = u.__typename;
  switch (v) {
    case "TransactionMetaEvent" :
        return {
                NAME: "TransactionMetaEvent",
                VAL: u
              };
    case "TransactionMetaPixReceived" :
        return {
                NAME: "TransactionMetaPixReceived",
                VAL: u
              };
    case "TransactionMetaPixSent" :
        return {
                NAME: "TransactionMetaPixSent",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_response_pixWithdrawal_transaction_metadata(param) {
  if (param.NAME === "UnselectedUnionMember") {
    return {
            __typename: param.VAL
          };
  } else {
    return param.VAL;
  }
}

var wrapResponseConverter = {"__root":{"pixWithdrawal_transaction_metadata_transactionmetapixreceived_status":{"n":""},"pixWithdrawal_error":{"n":""},"pixWithdrawal_transaction_metadata_transactionmetapixsent_status":{"n":""},"pixWithdrawal_transaction_metadata":{"u":"response_pixWithdrawal_transaction_metadata"},"pixWithdrawal_transaction_metadata_transactionmetaevent_status":{"n":""},"pixWithdrawal":{"n":""},"pixWithdrawal_transaction_metadata_transactionmetapixsent_description":{"n":""},"pixWithdrawal_transaction_metadata_transactionmetaevent_description":{"n":""},"pixWithdrawal_transaction_metadata_transactionmetapixreceived_description":{"n":""},"pixWithdrawal_transaction":{"n":""}}};

var wrapResponseConverterMap = {
  response_pixWithdrawal_transaction_metadata: wrap_response_pixWithdrawal_transaction_metadata
};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"pixWithdrawal_transaction_metadata_transactionmetapixreceived_status":{"n":""},"pixWithdrawal_error":{"n":""},"pixWithdrawal_transaction_metadata_transactionmetapixsent_status":{"n":""},"pixWithdrawal_transaction_metadata":{"u":"response_pixWithdrawal_transaction_metadata"},"pixWithdrawal_transaction_metadata_transactionmetaevent_status":{"n":""},"pixWithdrawal":{"n":""},"pixWithdrawal_transaction_metadata_transactionmetapixsent_description":{"n":""},"pixWithdrawal_transaction_metadata_transactionmetaevent_description":{"n":""},"pixWithdrawal_transaction_metadata_transactionmetapixreceived_description":{"n":""},"pixWithdrawal_transaction":{"n":""}}};

var responseConverterMap = {
  response_pixWithdrawal_transaction_metadata: unwrap_response_pixWithdrawal_transaction_metadata
};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"PixWithdrawalInput"}},"TwoFactor":{"googleAuthCode":{"n":""},"smsAuthCode":{"n":""}},"PixWithdrawalInput":{"twoFactor":{"r":"TwoFactor"},"beneficiaryAccount":{"n":"","r":"BeneficiaryAccount"},"beneficiaryPixKey":{"n":""}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_twoFactor(googleAuthCode, smsAuthCode, param) {
  return {
          googleAuthCode: googleAuthCode,
          smsAuthCode: smsAuthCode
        };
}

function make_beneficiaryAccount(accountNumber, accountType, agency, bankCode, name, taxId) {
  return {
          accountNumber: accountNumber,
          accountType: accountType,
          agency: agency,
          bankCode: bankCode,
          name: name,
          taxId: taxId
        };
}

function make_pixWithdrawalInput(amount, beneficiaryAccount, beneficiaryPixKey, twoFactor, param) {
  return {
          amount: amount,
          beneficiaryAccount: beneficiaryAccount,
          beneficiaryPixKey: beneficiaryPixKey,
          twoFactor: twoFactor
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_pixWithdrawal_transaction(id, amount, metadata) {
  return {
          id: id,
          amount: amount,
          metadata: metadata
        };
}

function make_response_pixWithdrawal_error(message) {
  return {
          message: message
        };
}

function make_response_pixWithdrawal(error, transaction, param) {
  return {
          error: error,
          transaction: transaction
        };
}

function makeOptimisticResponse(pixWithdrawal, param) {
  return {
          pixWithdrawal: pixWithdrawal
        };
}

var Utils = {
  make_twoFactor: make_twoFactor,
  make_beneficiaryAccount: make_beneficiaryAccount,
  make_pixWithdrawalInput: make_pixWithdrawalInput,
  makeVariables: makeVariables,
  make_response_pixWithdrawal_transaction: make_response_pixWithdrawal_transaction,
  make_response_pixWithdrawal_error: make_response_pixWithdrawal_error,
  make_response_pixWithdrawal: make_response_pixWithdrawal,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PixWithdrawalPayload",
    "kind": "LinkedField",
    "name": "pixWithdrawal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Transaction",
        "kind": "LinkedField",
        "name": "transaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "metadata",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v1/*: any*/),
                "type": "TransactionMetaEvent",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v1/*: any*/),
                "type": "TransactionMetaPixSent",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v1/*: any*/),
                "type": "TransactionMetaPixReceived",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PixWithdrawalModal_PixWithdrawalMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PixWithdrawalModal_PixWithdrawalMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ab01cd3e911a09f6c7a8d54806c549aa",
    "id": null,
    "metadata": {},
    "name": "PixWithdrawalModal_PixWithdrawalMutation",
    "operationKind": "mutation",
    "text": "mutation PixWithdrawalModal_PixWithdrawalMutation(\n  $input: PixWithdrawalInput!\n) {\n  pixWithdrawal(input: $input) {\n    error {\n      message\n    }\n    transaction {\n      id\n      amount\n      metadata {\n        __typename\n        ... on TransactionMetaEvent {\n          kind\n          status\n          description\n        }\n        ... on TransactionMetaPixSent {\n          kind\n          status\n          description\n        }\n        ... on TransactionMetaPixReceived {\n          kind\n          status\n          description\n        }\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.unwrap_response_pixWithdrawal_transaction_metadata = unwrap_response_pixWithdrawal_transaction_metadata;
exports.wrap_response_pixWithdrawal_transaction_metadata = wrap_response_pixWithdrawal_transaction_metadata;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
