'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var ApexChart$VannaFrontend = require("../types/ApexChart.bs.js");
var CollectionHelpers$VannaFrontend = require("../types/CollectionHelpers.bs.js");
var ChargesProjectionsChartFragment_user_graphql$VannaFrontend = require("../__generated__/ChargesProjectionsChartFragment_user_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.padding(Theme$VannaFrontend.Padding.content),
      tl: /* [] */0
    });

var Styles = {
  panel: panel
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(ChargesProjectionsChartFragment_user_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ChargesProjectionsChartFragment_user_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ChargesProjectionsChartFragment_user_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ChargesProjectionsChartFragment_user_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var ChargesProjectionsChartFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function ChargesProjectionsChart(Props) {
  var data = Props.data;
  var year = Props.year;
  var currentMonth = React.useMemo((function () {
          return new Date().getMonth() | 0;
        }), []);
  var currentYear = React.useMemo((function () {
          return new Date().getFullYear() | 0;
        }), []);
  var queryData = use(data);
  var chargesPerMonth = React.useMemo((function () {
          return Belt_Array.reduce(Js_dict.entries(CollectionHelpers$VannaFrontend.groupBy(queryData.charges, (function (x) {
                                return new Date(x.date).getMonth().toString();
                              }))), Belt_Array.makeBy(12, (function (param) {
                            return 0;
                          })), (function (acc, param) {
                        var month = Caml_format.caml_int_of_string(param[0]);
                        var totalAmountOnMonth = Belt_Array.reduce(param[1], Money$VannaFrontend.make({
                                  TAG: /* Cents */1,
                                  _0: 0
                                }), (function (acc, cur) {
                                return Money$VannaFrontend.$plus(acc, Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                                    TAG: /* Cents */1,
                                                    _0: cur.amount
                                                  }), Money$VannaFrontend.make({
                                                    TAG: /* Cents */1,
                                                    _0: cur.transactionCost
                                                  })));
                              }));
                        var head = Belt_Array.slice(acc, 0, month);
                        var middle = [Money$VannaFrontend.toDecimal(totalAmountOnMonth)];
                        var tail = Belt_Array.sliceToEnd(acc, month + 1 | 0);
                        return Belt_Array.concatMany([
                                    head,
                                    middle,
                                    tail
                                  ]);
                      }));
        }), [queryData]);
  var recurrentChargesPerMonth = React.useMemo((function () {
          return Belt_Array.reduce(Js_dict.entries(CollectionHelpers$VannaFrontend.groupBy(queryData.chargesFromSubscriptions, (function (x) {
                                return new Date(x.date).getMonth().toString();
                              }))), Belt_Array.makeBy(12, (function (param) {
                            return 0;
                          })), (function (acc, param) {
                        var month = Caml_format.caml_int_of_string(param[0]);
                        var totalAmountOnMonth = Belt_Array.reduce(param[1], Money$VannaFrontend.make({
                                  TAG: /* Cents */1,
                                  _0: 0
                                }), (function (acc, cur) {
                                return Money$VannaFrontend.$plus(acc, Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                                    TAG: /* Cents */1,
                                                    _0: cur.amount
                                                  }), Money$VannaFrontend.make({
                                                    TAG: /* Cents */1,
                                                    _0: cur.transactionCost
                                                  })));
                              }));
                        var head = Belt_Array.slice(acc, 0, month);
                        var middle = [Money$VannaFrontend.toDecimal(totalAmountOnMonth)];
                        var tail = Belt_Array.sliceToEnd(acc, month + 1 | 0);
                        return Belt_Array.concatMany([
                                    head,
                                    middle,
                                    tail
                                  ]);
                      }));
        }), [queryData]);
  var transactionCostsPerMonth = React.useMemo((function () {
          return Belt_Array.reduce(Js_dict.entries(CollectionHelpers$VannaFrontend.groupBy(Belt_Array.concat(Belt_Array.map(queryData.charges, (function (charge) {
                                        return {
                                                date: charge.date,
                                                transactionCost: charge.transactionCost
                                              };
                                      })), Belt_Array.map(queryData.chargesFromSubscriptions, (function (charge) {
                                        return {
                                                date: charge.date,
                                                transactionCost: charge.transactionCost
                                              };
                                      }))), (function (x) {
                                return new Date(x.date).getMonth().toString();
                              }))), Belt_Array.makeBy(12, (function (param) {
                            return 0;
                          })), (function (acc, param) {
                        var month = Caml_format.caml_int_of_string(param[0]);
                        var totalAmountOnMonth = Belt_Array.reduce(param[1], Money$VannaFrontend.make({
                                  TAG: /* Cents */1,
                                  _0: 0
                                }), (function (acc, cur) {
                                return Money$VannaFrontend.$plus(acc, Money$VannaFrontend.make({
                                                TAG: /* Cents */1,
                                                _0: cur.transactionCost
                                              }));
                              }));
                        var head = Belt_Array.slice(acc, 0, month);
                        var middle = [Money$VannaFrontend.toDecimal(totalAmountOnMonth)];
                        var tail = Belt_Array.sliceToEnd(acc, month + 1 | 0);
                        return Belt_Array.concatMany([
                                    head,
                                    middle,
                                    tail
                                  ]);
                      }));
        }), [queryData]);
  var match = React.useMemo((function () {
          var param = Belt_Array.reduce(queryData.recurrentCharges, [
                Money$VannaFrontend.make({
                      TAG: /* Cents */1,
                      _0: 0
                    }),
                Money$VannaFrontend.make({
                      TAG: /* Cents */1,
                      _0: 0
                    })
              ], (function (param, recurrentCharge) {
                  var activeRecurrentChargesTransactionsCosts = param[1];
                  var activeRecurrentChargesTotal = param[0];
                  var match = recurrentCharge.status;
                  if (match === "ONGOING") {
                    return [
                            Money$VannaFrontend.$plus(activeRecurrentChargesTotal, Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                          TAG: /* Cents */1,
                                          _0: recurrentCharge.amount
                                        }), Money$VannaFrontend.make({
                                          TAG: /* Cents */1,
                                          _0: Belt_Option.getWithDefault(recurrentCharge.transactionCost, 0)
                                        }))),
                            Money$VannaFrontend.$plus(activeRecurrentChargesTransactionsCosts, Money$VannaFrontend.make({
                                      TAG: /* Cents */1,
                                      _0: Belt_Option.getWithDefault(recurrentCharge.transactionCost, 0)
                                    }))
                          ];
                  } else {
                    return [
                            activeRecurrentChargesTotal,
                            activeRecurrentChargesTransactionsCosts
                          ];
                  }
                }));
          return [
                  Money$VannaFrontend.toDecimal(param[0]),
                  Money$VannaFrontend.toDecimal(param[1])
                ];
        }), [queryData]);
  var activeRecurrentChargesTransactionsCosts = match[1];
  var activeRecurrentChargesTotal = match[0];
  var recurrentChargesProjection = React.useMemo((function () {
          if (Caml_format.caml_int_of_string(year) >= currentYear) {
            return Belt_Array.mapWithIndex(recurrentChargesPerMonth, (function (index, amount) {
                          if (index > currentMonth || Caml_format.caml_int_of_string(year) > currentYear) {
                            return activeRecurrentChargesTotal;
                          } else {
                            return amount;
                          }
                        }));
          } else {
            return recurrentChargesPerMonth;
          }
        }), [recurrentChargesPerMonth]);
  var transactionCostsProjection = React.useMemo((function () {
          if (Caml_format.caml_int_of_string(year) >= currentYear) {
            return Belt_Array.mapWithIndex(transactionCostsPerMonth, (function (index, amount) {
                          if (index > currentMonth || Caml_format.caml_int_of_string(year) > currentYear) {
                            return activeRecurrentChargesTransactionsCosts;
                          } else {
                            return amount;
                          }
                        }));
          } else {
            return transactionCostsPerMonth;
          }
        }), [transactionCostsPerMonth]);
  return React.createElement(Panel$VannaFrontend.make, {
              className: panel,
              children: null
            }, React.createElement(Panel$VannaFrontend.PanelTitle.make, {
                  children: "Balanço do ano"
                }), React.createElement(ApexChart$VannaFrontend.make, {
                  options: {
                    colors: [
                      Theme$VannaFrontend.Colors.toString(Theme$VannaFrontend.Colors.domainCharges),
                      Theme$VannaFrontend.Colors.toString(Theme$VannaFrontend.Colors.domainRecurrentCharges),
                      Theme$VannaFrontend.Colors.toString(Theme$VannaFrontend.Colors.domainTransactionCosts)
                    ],
                    tooltip: {
                      x: {
                        formatter: (function (val_) {
                            return val_;
                          })
                      }
                    }
                  },
                  series: [
                    {
                      name: "Vendas avulsas",
                      data: chargesPerMonth
                    },
                    {
                      name: "Assinaturas",
                      data: recurrentChargesProjection
                    },
                    {
                      name: "Taxas de transação",
                      data: transactionCostsProjection
                    }
                  ],
                  categories: [
                    "Jan",
                    "Fev",
                    "Mar",
                    "Abr",
                    "Maio",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Set",
                    "Out",
                    "Nov",
                    "Dez"
                  ],
                  width: "100%",
                  height: "320",
                  type_: "bar"
                }));
}

var make = ChargesProjectionsChart;

exports.Styles = Styles;
exports.ChargesProjectionsChartFragment = ChargesProjectionsChartFragment;
exports.make = make;
/* panel Not a pure module */
