'use strict';

var React = require("react");
var ReasonReactErrorBoundary = require("reason-react/src/ReasonReactErrorBoundary.bs.js");
var SuspenseLoader$VannaFrontend = require("./SuspenseLoader.bs.js");

function PageQueryRenderer(Props) {
  var children = Props.children;
  return React.createElement(ReasonReactErrorBoundary.make, {
              children: React.createElement(SuspenseLoader$VannaFrontend.make, {
                    children: children
                  }),
              fallback: (function (param) {
                  return "Infelizmente, algo deu errado";
                })
            });
}

var make = PageQueryRenderer;

exports.make = make;
/* react Not a pure module */
