'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"productCreate_product_cfop":{"n":""},"productCreate_product_pis":{"n":""},"productCreate_product_icms":{"n":""},"productCreate_product_cest":{"n":""},"productCreate_product_cofins":{"n":""},"productCreate_product_icms_csosn":{"n":""},"productCreate_product_description":{"n":""},"productCreate_product":{"n":""},"productCreate":{"n":""},"productCreate_product_ncm":{"n":""},"productCreate_error":{"n":""},"productCreate_product_icms_cst":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"productCreate_product_cfop":{"n":""},"productCreate_product_pis":{"n":""},"productCreate_product_icms":{"n":""},"productCreate_product_cest":{"n":""},"productCreate_product_cofins":{"n":""},"productCreate_product_icms_csosn":{"n":""},"productCreate_product_description":{"n":""},"productCreate_product":{"n":""},"productCreate":{"n":""},"productCreate_product_ncm":{"n":""},"productCreate_error":{"n":""},"productCreate_product_icms_cst":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"ProductCreateInput":{"pis":{"n":"","r":"Pis"},"cofins":{"n":"","r":"Cofins"},"description":{"n":""},"ncm":{"n":"","r":"Ncm"},"cest":{"n":"","r":"Cest"},"cfop":{"n":"","r":"Cfop"},"icms":{"n":"","r":"Icms"}},"Icms":{"cst":{"n":""},"csosn":{"n":""}},"__root":{"input":{"r":"ProductCreateInput"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_pis(cst) {
  return {
          cst: cst
        };
}

function make_ncm(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_icms(csosn, cst, origin, param) {
  return {
          csosn: csosn,
          cst: cst,
          origin: origin
        };
}

function make_cofins(cst) {
  return {
          cst: cst
        };
}

function make_cfop(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_cest(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_productCreateInput(cest, cfop, cofins, description, icms, name, ncm, pis, price, param) {
  return {
          cest: cest,
          cfop: cfop,
          cofins: cofins,
          description: description,
          icms: icms,
          name: name,
          ncm: ncm,
          pis: pis,
          price: price
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_productCreate_product_pis(cst) {
  return {
          cst: cst
        };
}

function make_response_productCreate_product_ncm(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_response_productCreate_product_icms(csosn, cst, origin, param) {
  return {
          csosn: csosn,
          cst: cst,
          origin: origin
        };
}

function make_response_productCreate_product_cofins(cst) {
  return {
          cst: cst
        };
}

function make_response_productCreate_product_cfop(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_response_productCreate_product_cest(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_response_productCreate_product(id, name, description, price, cest, cfop, cofins, icms, ncm, pis, param) {
  return {
          id: id,
          name: name,
          description: description,
          price: price,
          cest: cest,
          cfop: cfop,
          cofins: cofins,
          icms: icms,
          ncm: ncm,
          pis: pis
        };
}

function make_response_productCreate_error(message) {
  return {
          message: message
        };
}

function make_response_productCreate(error, product, param) {
  return {
          error: error,
          product: product
        };
}

function makeOptimisticResponse(productCreate, param) {
  return {
          productCreate: productCreate
        };
}

var Utils = {
  make_pis: make_pis,
  make_ncm: make_ncm,
  make_icms: make_icms,
  make_cofins: make_cofins,
  make_cfop: make_cfop,
  make_cest: make_cest,
  make_productCreateInput: make_productCreateInput,
  makeVariables: makeVariables,
  make_response_productCreate_product_pis: make_response_productCreate_product_pis,
  make_response_productCreate_product_ncm: make_response_productCreate_product_ncm,
  make_response_productCreate_product_icms: make_response_productCreate_product_icms,
  make_response_productCreate_product_cofins: make_response_productCreate_product_cofins,
  make_response_productCreate_product_cfop: make_response_productCreate_product_cfop,
  make_response_productCreate_product_cest: make_response_productCreate_product_cest,
  make_response_productCreate_product: make_response_productCreate_product,
  make_response_productCreate_error: make_response_productCreate_error,
  make_response_productCreate: make_response_productCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cst",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductCreatePayload",
    "kind": "LinkedField",
    "name": "productCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CestType",
            "kind": "LinkedField",
            "name": "cest",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CfopType",
            "kind": "LinkedField",
            "name": "cfop",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PisAndCofinsType",
            "kind": "LinkedField",
            "name": "cofins",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "IcmsType",
            "kind": "LinkedField",
            "name": "icms",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "csosn",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "origin",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NcmType",
            "kind": "LinkedField",
            "name": "ncm",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PisAndCofinsType",
            "kind": "LinkedField",
            "name": "pis",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductUpsertForm_ProductCreateMutation",
    "selections": (v5/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductUpsertForm_ProductCreateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "546c394bbd40cba2694ba45165ad9023",
    "id": null,
    "metadata": {},
    "name": "ProductUpsertForm_ProductCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ProductUpsertForm_ProductCreateMutation(\n  $input: ProductCreateInput!\n) {\n  productCreate(input: $input) {\n    error {\n      message\n    }\n    product {\n      id\n      name\n      description\n      price\n      cest {\n        code\n        name\n      }\n      cfop {\n        code\n        name\n      }\n      cofins {\n        cst\n      }\n      icms {\n        csosn\n        cst\n        origin\n      }\n      ncm {\n        code\n        name\n      }\n      pis {\n        cst\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
