'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var Commonjs = require("@fnando/cnpj/commonjs");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var FieldSection$VannaFrontend = require("./FieldSection.bs.js");
var ZipcodeField$VannaFrontend = require("./ZipcodeField.bs.js");
var TwoFactorForm$VannaFrontend = require("./TwoFactorForm.bs.js");
var StateSelectField$VannaFrontend = require("./StateSelectField.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var CompanyInfoFormFragment_graphql$VannaFrontend = require("../__generated__/CompanyInfoFormFragment_graphql.bs.js");
var CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend = require("../__generated__/CompanyInfoForm_CompanyInfoUpdateMutation_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 50
          }),
      tl: {
        hd: Css.padding(Theme$VannaFrontend.Padding.content),
        tl: /* [] */0
      }
    });

var twentyPercentField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 20
          }),
      tl: /* [] */0
    });

var thirtyPercent = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 33.33
          }),
      tl: /* [] */0
    });

var Styles = {
  panel: panel,
  twentyPercentField: twentyPercentField,
  thirtyPercent: thirtyPercent
};

function get(state, field) {
  switch (field) {
    case /* Name */0 :
        return state.name;
    case /* TradeName */1 :
        return state.tradeName;
    case /* TaxId */2 :
        return state.taxId;
    case /* PhoneAreaCode */3 :
        return state.phoneAreaCode;
    case /* PhoneNumber */4 :
        return state.phoneNumber;
    case /* AddressPostalCode */5 :
        return state.addressPostalCode;
    case /* AddressStreet */6 :
        return state.addressStreet;
    case /* AddressNumber */7 :
        return state.addressNumber;
    case /* AddressAdditionalInformation */8 :
        return state.addressAdditionalInformation;
    case /* AddressDistrict */9 :
        return state.addressDistrict;
    case /* AddressCityName */10 :
        return state.addressCityName;
    case /* AddressCityCode */11 :
        return state.addressCityCode;
    case /* AddressState */12 :
        return state.addressState;
    case /* GoogleAuthCode */13 :
        return state.googleAuthCode;
    case /* SmsAuthCode */14 :
        return state.smsAuthCode;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Name */0 :
        return {
                name: value,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* TradeName */1 :
        return {
                name: state.name,
                tradeName: value,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* TaxId */2 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: value,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* PhoneAreaCode */3 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: value,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* PhoneNumber */4 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: value,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* AddressPostalCode */5 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: value,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* AddressStreet */6 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: value,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* AddressNumber */7 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: value,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* AddressAdditionalInformation */8 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: value,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* AddressDistrict */9 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: value,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* AddressCityName */10 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: value,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* AddressCityCode */11 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: value,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* AddressState */12 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: value,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* GoogleAuthCode */13 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: value,
                smsAuthCode: state.smsAuthCode
              };
    case /* SmsAuthCode */14 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                addressPostalCode: state.addressPostalCode,
                addressStreet: state.addressStreet,
                addressNumber: state.addressNumber,
                addressAdditionalInformation: state.addressAdditionalInformation,
                addressDistrict: state.addressDistrict,
                addressCityName: state.addressCityName,
                addressCityCode: state.addressCityCode,
                addressState: state.addressState,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var CompanyInfoUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(CompanyInfoFormFragment_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(CompanyInfoFormFragment_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(CompanyInfoFormFragment_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return CompanyInfoFormFragment_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var CompanyInfoFormFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.node,
              variables: CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var CompanyInfoUpdateMutation_make_twoFactor = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.make_twoFactor;

var CompanyInfoUpdateMutation_make_city = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.make_city;

var CompanyInfoUpdateMutation_make_address = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.make_address;

var CompanyInfoUpdateMutation_make_companyInfoUpdateInput = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.make_companyInfoUpdateInput;

var CompanyInfoUpdateMutation_makeVariables = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var CompanyInfoUpdateMutation_make_response_companyInfoUpdate_settingsCompanyInfo_address_city = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.make_response_companyInfoUpdate_settingsCompanyInfo_address_city;

var CompanyInfoUpdateMutation_make_response_companyInfoUpdate_settingsCompanyInfo_address = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.make_response_companyInfoUpdate_settingsCompanyInfo_address;

var CompanyInfoUpdateMutation_make_response_companyInfoUpdate_settingsCompanyInfo = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.make_response_companyInfoUpdate_settingsCompanyInfo;

var CompanyInfoUpdateMutation_make_response_companyInfoUpdate_error = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.make_response_companyInfoUpdate_error;

var CompanyInfoUpdateMutation_make_response_companyInfoUpdate = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.make_response_companyInfoUpdate;

var CompanyInfoUpdateMutation_makeOptimisticResponse = CompanyInfoForm_CompanyInfoUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var CompanyInfoUpdateMutation = {
  make_twoFactor: CompanyInfoUpdateMutation_make_twoFactor,
  make_city: CompanyInfoUpdateMutation_make_city,
  make_address: CompanyInfoUpdateMutation_make_address,
  make_companyInfoUpdateInput: CompanyInfoUpdateMutation_make_companyInfoUpdateInput,
  makeVariables: CompanyInfoUpdateMutation_makeVariables,
  make_response_companyInfoUpdate_settingsCompanyInfo_address_city: CompanyInfoUpdateMutation_make_response_companyInfoUpdate_settingsCompanyInfo_address_city,
  make_response_companyInfoUpdate_settingsCompanyInfo_address: CompanyInfoUpdateMutation_make_response_companyInfoUpdate_settingsCompanyInfo_address,
  make_response_companyInfoUpdate_settingsCompanyInfo: CompanyInfoUpdateMutation_make_response_companyInfoUpdate_settingsCompanyInfo,
  make_response_companyInfoUpdate_error: CompanyInfoUpdateMutation_make_response_companyInfoUpdate_error,
  make_response_companyInfoUpdate: CompanyInfoUpdateMutation_make_response_companyInfoUpdate,
  makeOptimisticResponse: CompanyInfoUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

function CompanyInfoForm(Props) {
  var fragment = Props.fragment;
  var queryData = use(fragment);
  var match = use$1(undefined);
  var companyInfoUpdate = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setAddressLoaded = match$1[1];
  var isAddressLoaded = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSuccess = match$2[1];
  var isGoogleAuthEnabled = Belt_Option.mapWithDefault(queryData.googleAuth, false, (function (auth) {
          return auth.isEnabled;
        }));
  var isSmsAuthEnabled = Belt_Option.mapWithDefault(queryData.smsAuth, false, (function (auth) {
          return auth.isEnabled;
        }));
  var reform = Curry._7(CompanyInfoUpsertForm.use, {
        name: Belt_Option.getWithDefault(queryData.settings.companyInfo.name, ""),
        tradeName: Belt_Option.getWithDefault(queryData.settings.companyInfo.tradeName, ""),
        taxId: Belt_Option.getWithDefault(queryData.settings.companyInfo.taxId, ""),
        phoneAreaCode: Belt_Option.getWithDefault(queryData.settings.companyInfo.phoneAreaCode, ""),
        phoneNumber: Belt_Option.getWithDefault(queryData.settings.companyInfo.phoneNumber, ""),
        addressPostalCode: Belt_Option.mapWithDefault(queryData.settings.companyInfo.address, "", (function (address) {
                return address.postalCode;
              })),
        addressStreet: Belt_Option.mapWithDefault(queryData.settings.companyInfo.address, "", (function (address) {
                return Belt_Option.getWithDefault(address.street, "");
              })),
        addressNumber: Belt_Option.mapWithDefault(queryData.settings.companyInfo.address, "", (function (address) {
                return Belt_Option.getWithDefault(address.number, "");
              })),
        addressAdditionalInformation: Belt_Option.mapWithDefault(queryData.settings.companyInfo.address, "", (function (address) {
                return Belt_Option.getWithDefault(address.additionalInformation, "");
              })),
        addressDistrict: Belt_Option.mapWithDefault(queryData.settings.companyInfo.address, "", (function (address) {
                return address.district;
              })),
        addressCityName: Belt_Option.mapWithDefault(queryData.settings.companyInfo.address, "", (function (address) {
                return Belt_Option.mapWithDefault(address.city, "", (function (city) {
                              return city.name;
                            }));
              })),
        addressCityCode: Belt_Option.mapWithDefault(queryData.settings.companyInfo.address, "", (function (address) {
                return Belt_Option.mapWithDefault(address.city, "", (function (city) {
                              return city.code;
                            }));
              })),
        addressState: Belt_Option.mapWithDefault(queryData.settings.companyInfo.address, "", (function (address) {
                return address.state;
              })),
        googleAuthCode: "",
        smsAuthCode: ""
      }, /* Schema */{
        _0: Curry._2(CompanyInfoUpsertForm.ReSchema.Validation.$plus, Curry._2(CompanyInfoUpsertForm.ReSchema.Validation.$plus, Curry._2(CompanyInfoUpsertForm.ReSchema.Validation.$plus, Curry._2(CompanyInfoUpsertForm.ReSchema.Validation.$plus, Curry._2(CompanyInfoUpsertForm.ReSchema.Validation.$plus, Curry._2(CompanyInfoUpsertForm.ReSchema.Validation.$plus, Curry._2(CompanyInfoUpsertForm.ReSchema.Validation.$plus, Curry._2(CompanyInfoUpsertForm.ReSchema.Validation.$plus, Curry._3(CompanyInfoUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Name */0), Curry._3(CompanyInfoUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* TradeName */1)), Curry._3(CompanyInfoUpsertForm.ReSchema.Validation.custom, (function (param) {
                                            if (Commonjs.isValid(param.taxId)) {
                                              return /* Valid */0;
                                            } else {
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: "O CPF informado é inválido"
                                                    };
                                            }
                                          }), undefined, /* TaxId */2)), Curry._3(CompanyInfoUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* AddressPostalCode */5)), Curry._3(CompanyInfoUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* AddressStreet */6)), Curry._3(CompanyInfoUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* AddressNumber */7)), Curry._3(CompanyInfoUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* AddressDistrict */9)), Curry._3(CompanyInfoUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* AddressCityName */10)), Curry._3(CompanyInfoUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* AddressPostalCode */5))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          Curry._1(setSuccess, (function (param) {
                  return false;
                }));
          Curry.app(companyInfoUpdate, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, errors) {
                    if (errors !== undefined && errors.length !== 0) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match = response.companyInfoUpdate;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    } else {
                      Curry._1(send, {
                            TAG: /* SetFormState */10,
                            _0: /* Dirty */0
                          });
                      return Curry._1(setSuccess, (function (param) {
                                    return true;
                                  }));
                    }
                  }),
                undefined,
                undefined,
                undefined,
                undefined,
                {
                  input: {
                    address: {
                      additionalInformation: state.values.addressAdditionalInformation,
                      city: {
                        code: state.values.addressCityCode,
                        name: state.values.addressCityName
                      },
                      district: state.values.addressDistrict,
                      number: state.values.addressNumber,
                      postalCode: state.values.addressPostalCode,
                      state: state.values.addressState,
                      street: state.values.addressStreet
                    },
                    municipalTaxId: "",
                    name: state.values.name,
                    openningDate: "",
                    phoneAreaCode: state.values.phoneAreaCode,
                    phoneNumber: state.values.phoneNumber,
                    taxId: state.values.taxId,
                    tradeName: state.values.tradeName,
                    twoFactor: {
                      googleAuthCode: state.values.googleAuthCode,
                      smsAuthCode: state.values.smsAuthCode
                    }
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$3 = reform.formState;
  var tmp;
  if (match$2[0]) {
    tmp = React.createElement(Box$VannaFrontend.make, {
          type_: /* Success */0,
          title: "Sucesso",
          children: "Informações atualizadas com sucesso"
        });
  } else if (typeof match$3 === "number") {
    tmp = null;
  } else {
    var error = match$3._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(Panel$VannaFrontend.make, {
              className: panel,
              children: null
            }, React.createElement(Panel$VannaFrontend.PanelTitle.make, {
                  children: "Dados básicos da sua empresa"
                }), React.createElement(CompanyInfoUpsertForm.Provider.make, Curry._3(CompanyInfoUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              return Curry._1(reform.submit, undefined);
                            })
                        }, React.createElement(CompanyInfoUpsertForm.Field.make, {
                              field: /* Name */0,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Razão social",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), React.createElement(CompanyInfoUpsertForm.Field.make, {
                              field: /* TradeName */1,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Nome fantasia",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), React.createElement(CompanyInfoUpsertForm.Field.make, {
                              field: /* TaxId */2,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "CNPJ",
                                              error: param.error,
                                              required: true,
                                              mask: "99.999.999/9999-99",
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), React.createElement(FieldSection$VannaFrontend.make, {
                              children: "Endereço"
                            }), React.createElement(CompanyInfoUpsertForm.Field.make, {
                              field: /* AddressPostalCode */5,
                              render: (function (param) {
                                  return React.createElement(ZipcodeField$VannaFrontend.make, {
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              onAddressInfoLoaded: (function (param) {
                                                  Curry._1(setAddressLoaded, (function (param) {
                                                          return true;
                                                        }));
                                                  var init = reform.values;
                                                  return Curry._1(reform.setValues, {
                                                              name: init.name,
                                                              tradeName: init.tradeName,
                                                              taxId: init.taxId,
                                                              phoneAreaCode: init.phoneAreaCode,
                                                              phoneNumber: init.phoneNumber,
                                                              addressPostalCode: param.zipcode,
                                                              addressStreet: param.address,
                                                              addressNumber: init.addressNumber,
                                                              addressAdditionalInformation: param.complement,
                                                              addressDistrict: param.neighborhood,
                                                              addressCityName: param.cityName,
                                                              addressCityCode: param.cityCode,
                                                              addressState: param.state,
                                                              googleAuthCode: init.googleAuthCode,
                                                              smsAuthCode: init.smsAuthCode
                                                            });
                                                })
                                            });
                                })
                            }), React.createElement(FieldGrid$VannaFrontend.make, {
                              children: null
                            }, React.createElement(CompanyInfoUpsertForm.Field.make, {
                                  field: /* AddressStreet */6,
                                  render: (function (param) {
                                      return React.createElement(TextField$VannaFrontend.make, {
                                                  label: "Rua",
                                                  error: param.error,
                                                  required: true,
                                                  value: param.value,
                                                  onChange: param.handleChange
                                                });
                                    })
                                }), React.createElement(CompanyInfoUpsertForm.Field.make, {
                                  field: /* AddressNumber */7,
                                  render: (function (param) {
                                      return React.createElement(TextField$VannaFrontend.make, {
                                                  label: "Número",
                                                  error: param.error,
                                                  type_: /* Tel */5,
                                                  required: true,
                                                  value: param.value,
                                                  onChange: param.handleChange,
                                                  wrapperClassName: twentyPercentField
                                                });
                                    })
                                })), React.createElement(FieldGrid$VannaFrontend.make, {
                              children: null
                            }, React.createElement(CompanyInfoUpsertForm.Field.make, {
                                  field: /* AddressDistrict */9,
                                  render: (function (param) {
                                      return React.createElement(TextField$VannaFrontend.make, {
                                                  label: "Bairro",
                                                  error: param.error,
                                                  required: true,
                                                  value: param.value,
                                                  onChange: param.handleChange,
                                                  wrapperClassName: thirtyPercent
                                                });
                                    })
                                }), React.createElement(CompanyInfoUpsertForm.Field.make, {
                                  field: /* AddressCityName */10,
                                  render: (function (param) {
                                      return React.createElement(TextField$VannaFrontend.make, {
                                                  label: "Cidade",
                                                  error: param.error,
                                                  required: true,
                                                  value: param.value,
                                                  onChange: param.handleChange,
                                                  wrapperClassName: thirtyPercent,
                                                  disabled: isAddressLoaded
                                                });
                                    })
                                }), React.createElement(CompanyInfoUpsertForm.Field.make, {
                                  field: /* AddressState */12,
                                  render: (function (param) {
                                      return React.createElement(StateSelectField$VannaFrontend.make, {
                                                  error: param.error,
                                                  onChange: param.handleChange,
                                                  value: param.value,
                                                  disabled: isAddressLoaded,
                                                  className: thirtyPercent
                                                });
                                    })
                                })), React.createElement(CompanyInfoUpsertForm.Field.make, {
                              field: /* AddressAdditionalInformation */8,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Informações adicionais",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), React.createElement(FieldSection$VannaFrontend.make, {
                              children: "Telefone"
                            }), React.createElement(FieldGrid$VannaFrontend.make, {
                              children: null
                            }, React.createElement(CompanyInfoUpsertForm.Field.make, {
                                  field: /* PhoneAreaCode */3,
                                  render: (function (param) {
                                      return React.createElement(TextField$VannaFrontend.make, {
                                                  label: "Código de área",
                                                  error: param.error,
                                                  required: true,
                                                  mask: "99",
                                                  value: param.value,
                                                  onChange: param.handleChange,
                                                  wrapperClassName: thirtyPercent
                                                });
                                    })
                                }), React.createElement(CompanyInfoUpsertForm.Field.make, {
                                  field: /* PhoneNumber */4,
                                  render: (function (param) {
                                      return React.createElement(TextField$VannaFrontend.make, {
                                                  label: "Número",
                                                  error: param.error,
                                                  required: true,
                                                  mask: "9.9999-9999",
                                                  value: param.value,
                                                  onChange: param.handleChange
                                                });
                                    })
                                })), React.createElement(TwoFactorForm$VannaFrontend.make, {
                              isGoogleAuthEnabled: isGoogleAuthEnabled,
                              isSmsAuthEnabled: isSmsAuthEnabled,
                              withSection: true,
                              onChange: (function (authMehod, value) {
                                  if (authMehod) {
                                    return Curry._4(reform.setFieldValue, /* SmsAuthCode */14, value, undefined, undefined);
                                  } else {
                                    return Curry._4(reform.setFieldValue, /* GoogleAuthCode */13, value, undefined, undefined);
                                  }
                                })
                            }), tmp, React.createElement(Button$VannaFrontend.make, {
                              fullWidth: false,
                              isLoading: reform.isSubmitting,
                              type_: /* Submit */1,
                              children: "Atualizar"
                            })), undefined)));
}

var make = CompanyInfoForm;

exports.Styles = Styles;
exports.StateLenses = StateLenses;
exports.CompanyInfoUpsertForm = CompanyInfoUpsertForm;
exports.CompanyInfoFormFragment = CompanyInfoFormFragment;
exports.CompanyInfoUpdateMutation = CompanyInfoUpdateMutation;
exports.make = make;
/* panel Not a pure module */
