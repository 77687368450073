'use strict';

var Uuid = require("uuid");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Fi = require("react-icons/fi");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var ItemsTable$VannaFrontend = require("./ItemsTable.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var NumberField$VannaFrontend = require("./NumberField.bs.js");
var TextAreaField$VannaFrontend = require("./TextAreaField.bs.js");
var SuspenseLoader$VannaFrontend = require("./SuspenseLoader.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var SearchableSelectField$VannaFrontend = require("./SearchableSelectField.bs.js");
var ItemTableUpsertProductForm$VannaFrontend = require("./ItemTableUpsertProductForm.bs.js");
var ItemTableUpsertServiceForm$VannaFrontend = require("./ItemTableUpsertServiceForm.bs.js");
var SelfCheckoutUpsertForm_affiliates_graphql$VannaFrontend = require("../__generated__/SelfCheckoutUpsertForm_affiliates_graphql.bs.js");
var SelfCheckoutUpsertForm_selfCheckout_graphql$VannaFrontend = require("../__generated__/SelfCheckoutUpsertForm_selfCheckout_graphql.bs.js");
var SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend = require("../__generated__/SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql.bs.js");
var SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend = require("../__generated__/SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql.bs.js");

function get(state, field) {
  switch (field) {
    case /* Description */0 :
        return state.description;
    case /* BannerLink */1 :
        return state.bannerLink;
    case /* HasMemberkitIntegration */2 :
        return state.hasMemberkitIntegration;
    case /* MemberkitWebhookUrl */3 :
        return state.memberkitWebhookUrl;
    case /* MemberkitIntegrationToken */4 :
        return state.memberkitIntegrationToken;
    case /* HasAffiliate */5 :
        return state.hasAffiliate;
    case /* AffiliateId */6 :
        return state.affiliateId;
    case /* AffiliatePercentage */7 :
        return state.affiliatePercentage;
    case /* Snippets */8 :
        return state.snippets;
    case /* Items */9 :
        return state.items;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Description */0 :
        return {
                description: value,
                bannerLink: state.bannerLink,
                hasMemberkitIntegration: state.hasMemberkitIntegration,
                memberkitWebhookUrl: state.memberkitWebhookUrl,
                memberkitIntegrationToken: state.memberkitIntegrationToken,
                hasAffiliate: state.hasAffiliate,
                affiliateId: state.affiliateId,
                affiliatePercentage: state.affiliatePercentage,
                snippets: state.snippets,
                items: state.items
              };
    case /* BannerLink */1 :
        return {
                description: state.description,
                bannerLink: value,
                hasMemberkitIntegration: state.hasMemberkitIntegration,
                memberkitWebhookUrl: state.memberkitWebhookUrl,
                memberkitIntegrationToken: state.memberkitIntegrationToken,
                hasAffiliate: state.hasAffiliate,
                affiliateId: state.affiliateId,
                affiliatePercentage: state.affiliatePercentage,
                snippets: state.snippets,
                items: state.items
              };
    case /* HasMemberkitIntegration */2 :
        return {
                description: state.description,
                bannerLink: state.bannerLink,
                hasMemberkitIntegration: value,
                memberkitWebhookUrl: state.memberkitWebhookUrl,
                memberkitIntegrationToken: state.memberkitIntegrationToken,
                hasAffiliate: state.hasAffiliate,
                affiliateId: state.affiliateId,
                affiliatePercentage: state.affiliatePercentage,
                snippets: state.snippets,
                items: state.items
              };
    case /* MemberkitWebhookUrl */3 :
        return {
                description: state.description,
                bannerLink: state.bannerLink,
                hasMemberkitIntegration: state.hasMemberkitIntegration,
                memberkitWebhookUrl: value,
                memberkitIntegrationToken: state.memberkitIntegrationToken,
                hasAffiliate: state.hasAffiliate,
                affiliateId: state.affiliateId,
                affiliatePercentage: state.affiliatePercentage,
                snippets: state.snippets,
                items: state.items
              };
    case /* MemberkitIntegrationToken */4 :
        return {
                description: state.description,
                bannerLink: state.bannerLink,
                hasMemberkitIntegration: state.hasMemberkitIntegration,
                memberkitWebhookUrl: state.memberkitWebhookUrl,
                memberkitIntegrationToken: value,
                hasAffiliate: state.hasAffiliate,
                affiliateId: state.affiliateId,
                affiliatePercentage: state.affiliatePercentage,
                snippets: state.snippets,
                items: state.items
              };
    case /* HasAffiliate */5 :
        return {
                description: state.description,
                bannerLink: state.bannerLink,
                hasMemberkitIntegration: state.hasMemberkitIntegration,
                memberkitWebhookUrl: state.memberkitWebhookUrl,
                memberkitIntegrationToken: state.memberkitIntegrationToken,
                hasAffiliate: value,
                affiliateId: state.affiliateId,
                affiliatePercentage: state.affiliatePercentage,
                snippets: state.snippets,
                items: state.items
              };
    case /* AffiliateId */6 :
        return {
                description: state.description,
                bannerLink: state.bannerLink,
                hasMemberkitIntegration: state.hasMemberkitIntegration,
                memberkitWebhookUrl: state.memberkitWebhookUrl,
                memberkitIntegrationToken: state.memberkitIntegrationToken,
                hasAffiliate: state.hasAffiliate,
                affiliateId: value,
                affiliatePercentage: state.affiliatePercentage,
                snippets: state.snippets,
                items: state.items
              };
    case /* AffiliatePercentage */7 :
        return {
                description: state.description,
                bannerLink: state.bannerLink,
                hasMemberkitIntegration: state.hasMemberkitIntegration,
                memberkitWebhookUrl: state.memberkitWebhookUrl,
                memberkitIntegrationToken: state.memberkitIntegrationToken,
                hasAffiliate: state.hasAffiliate,
                affiliateId: state.affiliateId,
                affiliatePercentage: value,
                snippets: state.snippets,
                items: state.items
              };
    case /* Snippets */8 :
        return {
                description: state.description,
                bannerLink: state.bannerLink,
                hasMemberkitIntegration: state.hasMemberkitIntegration,
                memberkitWebhookUrl: state.memberkitWebhookUrl,
                memberkitIntegrationToken: state.memberkitIntegrationToken,
                hasAffiliate: state.hasAffiliate,
                affiliateId: state.affiliateId,
                affiliatePercentage: state.affiliatePercentage,
                snippets: value,
                items: state.items
              };
    case /* Items */9 :
        return {
                description: state.description,
                bannerLink: state.bannerLink,
                hasMemberkitIntegration: state.hasMemberkitIntegration,
                memberkitWebhookUrl: state.memberkitWebhookUrl,
                memberkitIntegrationToken: state.memberkitIntegrationToken,
                hasAffiliate: state.hasAffiliate,
                affiliateId: state.affiliateId,
                affiliatePercentage: state.affiliatePercentage,
                snippets: state.snippets,
                items: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var SelfCheckoutUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var BooleanSelectField = SearchableSelectField$VannaFrontend.Make({});

var AffiliateSelectField = SearchableSelectField$VannaFrontend.Make({});

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(SelfCheckoutUpsertForm_selfCheckout_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(SelfCheckoutUpsertForm_selfCheckout_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(SelfCheckoutUpsertForm_selfCheckout_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return SelfCheckoutUpsertForm_selfCheckout_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var SelfCheckoutFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

var Internal$1 = {};

function use$1(fRef) {
  var data = Hooks.useFragment(SelfCheckoutUpsertForm_affiliates_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(SelfCheckoutUpsertForm_affiliates_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt$1(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(SelfCheckoutUpsertForm_affiliates_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return SelfCheckoutUpsertForm_affiliates_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var SelfCheckoutFragmentAffiliates = {
  Types: undefined,
  Internal: Internal$1,
  use: use$1,
  useOpt: useOpt$1
};

var Internal$2 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.node,
              variables: SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var SelfCheckoutCreateMutation_make_selfCheckoutItem = SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Utils.make_selfCheckoutItem;

var SelfCheckoutCreateMutation_make_selfCheckoutCreateInput = SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Utils.make_selfCheckoutCreateInput;

var SelfCheckoutCreateMutation_makeVariables = SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var SelfCheckoutCreateMutation_make_response_selfCheckoutCreate_selfCheckout_items = SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutCreate_selfCheckout_items;

var SelfCheckoutCreateMutation_make_response_selfCheckoutCreate_selfCheckout = SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutCreate_selfCheckout;

var SelfCheckoutCreateMutation_make_response_selfCheckoutCreate_error = SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutCreate_error;

var SelfCheckoutCreateMutation_make_response_selfCheckoutCreate = SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutCreate;

var SelfCheckoutCreateMutation_makeOptimisticResponse = SelfCheckoutUpsertForm_SelfCheckoutCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var SelfCheckoutCreateMutation = {
  make_selfCheckoutItem: SelfCheckoutCreateMutation_make_selfCheckoutItem,
  make_selfCheckoutCreateInput: SelfCheckoutCreateMutation_make_selfCheckoutCreateInput,
  makeVariables: SelfCheckoutCreateMutation_makeVariables,
  make_response_selfCheckoutCreate_selfCheckout_items: SelfCheckoutCreateMutation_make_response_selfCheckoutCreate_selfCheckout_items,
  make_response_selfCheckoutCreate_selfCheckout: SelfCheckoutCreateMutation_make_response_selfCheckoutCreate_selfCheckout,
  make_response_selfCheckoutCreate_error: SelfCheckoutCreateMutation_make_response_selfCheckoutCreate_error,
  make_response_selfCheckoutCreate: SelfCheckoutCreateMutation_make_response_selfCheckoutCreate,
  makeOptimisticResponse: SelfCheckoutCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation,
  use: use$2
};

var Internal$3 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.node,
              variables: SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$3(param) {
  var match = RelayExperimental.useMutation(SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var SelfCheckoutUpdateMutation_make_selfCheckoutItem = SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Utils.make_selfCheckoutItem;

var SelfCheckoutUpdateMutation_make_selfCheckoutUpdateInput = SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Utils.make_selfCheckoutUpdateInput;

var SelfCheckoutUpdateMutation_makeVariables = SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var SelfCheckoutUpdateMutation_make_response_selfCheckoutUpdate_selfCheckout_items = SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutUpdate_selfCheckout_items;

var SelfCheckoutUpdateMutation_make_response_selfCheckoutUpdate_selfCheckout = SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutUpdate_selfCheckout;

var SelfCheckoutUpdateMutation_make_response_selfCheckoutUpdate_error = SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutUpdate_error;

var SelfCheckoutUpdateMutation_make_response_selfCheckoutUpdate = SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutUpdate;

var SelfCheckoutUpdateMutation_makeOptimisticResponse = SelfCheckoutUpsertForm_SelfCheckoutUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var SelfCheckoutUpdateMutation = {
  make_selfCheckoutItem: SelfCheckoutUpdateMutation_make_selfCheckoutItem,
  make_selfCheckoutUpdateInput: SelfCheckoutUpdateMutation_make_selfCheckoutUpdateInput,
  makeVariables: SelfCheckoutUpdateMutation_makeVariables,
  make_response_selfCheckoutUpdate_selfCheckout_items: SelfCheckoutUpdateMutation_make_response_selfCheckoutUpdate_selfCheckout_items,
  make_response_selfCheckoutUpdate_selfCheckout: SelfCheckoutUpdateMutation_make_response_selfCheckoutUpdate_selfCheckout,
  make_response_selfCheckoutUpdate_error: SelfCheckoutUpdateMutation_make_response_selfCheckoutUpdate_error,
  make_response_selfCheckoutUpdate: SelfCheckoutUpdateMutation_make_response_selfCheckoutUpdate,
  makeOptimisticResponse: SelfCheckoutUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$3,
  commitMutation: commitMutation$1,
  use: use$3
};

function SelfCheckoutUpsert(Props) {
  var selfCheckout = Props.selfCheckout;
  var me = Props.me;
  var dispatchModal = Props.dispatchModal;
  var selfCheckoutFragment = useOpt(selfCheckout);
  var meAffiliatesFragment = use$1(me);
  var match = use$2(undefined);
  var selfCheckoutCreate = match[0];
  var match$1 = use$3(undefined);
  var selfCheckoutUpdate = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setSelectedItem = match$2[1];
  var selectedItem = match$2[0];
  var reform = Curry._7(SelfCheckoutUpsertForm.use, selfCheckoutFragment !== undefined ? ({
            description: Belt_Option.getWithDefault(selfCheckoutFragment.description, ""),
            bannerLink: Belt_Option.getWithDefault(selfCheckoutFragment.bannerLink, ""),
            hasMemberkitIntegration: selfCheckoutFragment.hasMemberkitIntegration,
            memberkitWebhookUrl: Belt_Option.getWithDefault(selfCheckoutFragment.memberkitWebhook, ""),
            memberkitIntegrationToken: Belt_Option.getWithDefault(selfCheckoutFragment.memberkitHottoken, ""),
            hasAffiliate: false,
            affiliateId: "",
            affiliatePercentage: 0,
            snippets: Belt_Option.getWithDefault(selfCheckoutFragment.snippets, ""),
            items: Belt_Array.map(selfCheckoutFragment.items, (function (param) {
                    var item = param.item;
                    var maxQuantity = param.maxQuantity;
                    var hasMaxQuantity = param.hasMaxQuantity;
                    var chargeType = param.chargeType;
                    if (typeof item === "string") {
                      throw {
                            RE_EXN_ID: "Match_failure",
                            _1: [
                              "SelfCheckoutUpsert.res",
                              188,
                              10
                            ],
                            Error: new Error()
                          };
                    }
                    var variant = item.NAME;
                    if (variant === "Service") {
                      var item$1 = item.VAL;
                      return {
                              TAG: /* Service */1,
                              _0: {
                                name: item$1.name,
                                price: item$1.price,
                                quantity: 0,
                                freightAmount: 0,
                                insuranceAmount: 0,
                                othersAmount: 0,
                                discountAmount: 0,
                                itemId: item$1.id,
                                temporaryId: Uuid.v4(),
                                maxQuantity: maxQuantity !== undefined ? maxQuantity : 0,
                                hasMaxQuantity: hasMaxQuantity,
                                chargeType: chargeType === "SUBSCRIPTION" ? /* Recurrent */1 : /* Single */0
                              }
                            };
                    }
                    if (variant === "Product") {
                      var item$2 = item.VAL;
                      return {
                              TAG: /* Product */0,
                              _0: {
                                name: item$2.name,
                                price: item$2.price,
                                quantity: 0,
                                freightAmount: 0,
                                insuranceAmount: 0,
                                othersAmount: 0,
                                discountAmount: 0,
                                itemId: item$2.id,
                                temporaryId: Uuid.v4(),
                                maxQuantity: maxQuantity !== undefined ? maxQuantity : 0,
                                hasMaxQuantity: hasMaxQuantity,
                                chargeType: chargeType === "SUBSCRIPTION" ? /* Recurrent */1 : /* Single */0
                              }
                            };
                    }
                    throw {
                          RE_EXN_ID: "Match_failure",
                          _1: [
                            "SelfCheckoutUpsert.res",
                            188,
                            10
                          ],
                          Error: new Error()
                        };
                  }))
          }) : ({
            description: "",
            bannerLink: "",
            hasMemberkitIntegration: false,
            memberkitWebhookUrl: "",
            memberkitIntegrationToken: Uuid.v4(),
            hasAffiliate: false,
            affiliateId: "",
            affiliatePercentage: 0,
            snippets: "",
            items: []
          }), /* Schema */{
        _0: Curry._2(SelfCheckoutUpsertForm.ReSchema.Validation.$plus, Curry._2(SelfCheckoutUpsertForm.ReSchema.Validation.$plus, Curry._2(SelfCheckoutUpsertForm.ReSchema.Validation.$plus, Curry._3(SelfCheckoutUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Description */0), Curry._3(SelfCheckoutUpsertForm.ReSchema.Validation.custom, (function (state) {
                            var match = state.hasMemberkitIntegration;
                            var match$1 = state.memberkitWebhookUrl;
                            if (match && match$1 === "") {
                              return {
                                      TAG: /* Error */1,
                                      _0: "O webhook de integração com o memberkit é obrigatório"
                                    };
                            } else {
                              return /* Valid */0;
                            }
                          }), undefined, /* MemberkitWebhookUrl */3)), Curry._3(SelfCheckoutUpsertForm.ReSchema.Validation.custom, (function (state) {
                        if (state.items.length === 0) {
                          return {
                                  TAG: /* Error */1,
                                  _0: "Você deve adicionar ao menos um produto ou serviço"
                                };
                        } else {
                          return /* Valid */0;
                        }
                      }), undefined, /* Items */9)), Curry._6(SelfCheckoutUpsertForm.ReSchema.Validation.$$int, 0, undefined, 100, undefined, undefined, /* AffiliatePercentage */7))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          if (selfCheckoutFragment !== undefined) {
            Curry.app(selfCheckoutUpdate, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.selfCheckoutUpdate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      } else if (match.selfCheckout !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        return Curry._1(send, {
                                    TAG: /* SetFormState */10,
                                    _0: /* Dirty */0
                                  });
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    input: {
                      affiliateId: state.values.hasAffiliate ? state.values.affiliateId : undefined,
                      affiliatePercentage: state.values.hasAffiliate ? state.values.affiliatePercentage : undefined,
                      bannerLink: state.values.bannerLink,
                      description: state.values.description,
                      hasMemberkitIntegration: state.values.hasMemberkitIntegration,
                      items: Belt_Array.map(state.values.items, (function (item) {
                              if (item.TAG === /* Product */0) {
                                var item$1 = item._0;
                                var match = item$1.chargeType;
                                return {
                                        chargeType: match ? "SUBSCRIPTION" : "SINGLE",
                                        hasMaxQuantity: item$1.hasMaxQuantity,
                                        itemId: item$1.itemId,
                                        maxQuantity: item$1.maxQuantity,
                                        type: "PRODUCT"
                                      };
                              }
                              var item$2 = item._0;
                              var match$1 = item$2.chargeType;
                              return {
                                      chargeType: match$1 ? "SUBSCRIPTION" : "SINGLE",
                                      hasMaxQuantity: item$2.hasMaxQuantity,
                                      itemId: item$2.itemId,
                                      maxQuantity: item$2.maxQuantity,
                                      type: "SERVICE"
                                    };
                            })),
                      memberkitHottoken: state.values.memberkitIntegrationToken,
                      memberkitWebhook: state.values.memberkitWebhookUrl,
                      selfCheckoutId: selfCheckoutFragment.id,
                      snippets: state.values.snippets
                    }
                  },
                  undefined,
                  undefined
                ]);
          } else {
            Curry.app(selfCheckoutCreate, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.selfCheckoutCreate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      } else if (match.selfCheckout !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        return Curry._1(send, {
                                    TAG: /* SetFormState */10,
                                    _0: /* Dirty */0
                                  });
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  (function (store, response) {
                      var match = response.selfCheckoutCreate;
                      if (match === undefined) {
                        return ;
                      }
                      var selfCheckout = match.selfCheckout;
                      if (selfCheckout === undefined) {
                        return ;
                      }
                      var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                      var selfCheckoutProxy = store.get(selfCheckout.id);
                      if (meProxy == null) {
                        return ;
                      }
                      if (selfCheckoutProxy == null) {
                        return ;
                      }
                      var selfCheckoutsConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "SelfCheckouts_selfCheckouts", undefined);
                      if (selfCheckoutsConnection == null) {
                        return ;
                      }
                      var edge = RelayRuntime.ConnectionHandler.createEdge(store, selfCheckoutsConnection, selfCheckoutProxy, "SelfCheckout");
                      RelayRuntime.ConnectionHandler.insertEdgeAfter(selfCheckoutsConnection, edge, undefined);
                      
                    }),
                  {
                    input: {
                      affiliateId: state.values.hasAffiliate ? state.values.affiliateId : undefined,
                      affiliatePercentage: state.values.hasAffiliate ? state.values.affiliatePercentage : undefined,
                      bannerLink: state.values.bannerLink,
                      description: state.values.description,
                      hasMemberkitIntegration: state.values.hasMemberkitIntegration,
                      items: Belt_Array.map(state.values.items, (function (item) {
                              if (item.TAG === /* Product */0) {
                                var item$1 = item._0;
                                var match = item$1.chargeType;
                                return {
                                        chargeType: match ? "SUBSCRIPTION" : "SINGLE",
                                        hasMaxQuantity: item$1.hasMaxQuantity,
                                        itemId: item$1.itemId,
                                        maxQuantity: item$1.maxQuantity,
                                        type: "PRODUCT"
                                      };
                              }
                              var item$2 = item._0;
                              var match$1 = item$2.chargeType;
                              return {
                                      chargeType: match$1 ? "SUBSCRIPTION" : "SINGLE",
                                      hasMaxQuantity: item$2.hasMaxQuantity,
                                      itemId: item$2.itemId,
                                      maxQuantity: item$2.maxQuantity,
                                      type: "SERVICE"
                                    };
                            })),
                      memberkitHottoken: state.values.memberkitIntegrationToken,
                      memberkitWebhook: state.values.memberkitWebhookUrl,
                      snippets: state.values.snippets
                    }
                  },
                  undefined,
                  undefined
                ]);
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$3 = reform.formState;
  var tmp;
  if (typeof match$3 === "number") {
    tmp = null;
  } else {
    var error = match$3._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(SelfCheckoutUpsertForm.Provider.make, Curry._3(SelfCheckoutUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(SelfCheckoutUpsertForm.Field.make, {
                          field: /* Description */0,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Descrição",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(SelfCheckoutUpsertForm.Field.make, {
                          field: /* BannerLink */1,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Link para banner",
                                          error: param.error,
                                          required: false,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(SelfCheckoutUpsertForm.Field.make, {
                          field: /* HasMemberkitIntegration */2,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(BooleanSelectField.make, {
                                          label: "Tem integração com Memberkit?",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          options: [
                                            {
                                              label: "Sim",
                                              value: true
                                            },
                                            {
                                              label: "Não",
                                              value: false
                                            }
                                          ]
                                        });
                            })
                        }), reform.state.values.hasMemberkitIntegration ? React.createElement(React.Fragment, undefined, React.createElement(SelfCheckoutUpsertForm.Field.make, {
                                field: /* MemberkitWebhookUrl */3,
                                render: (function (param) {
                                    return React.createElement(TextField$VannaFrontend.make, {
                                                label: "Memberkit webhook URL",
                                                error: param.error,
                                                required: false,
                                                value: param.value,
                                                onChange: param.handleChange
                                              });
                                  })
                              }), React.createElement(SelfCheckoutUpsertForm.Field.make, {
                                field: /* MemberkitIntegrationToken */4,
                                render: (function (param) {
                                    return React.createElement(TextField$VannaFrontend.make, {
                                                label: "Token de integração (copie e cole na área de integração do Memberkit)",
                                                error: param.error,
                                                required: false,
                                                value: param.value,
                                                onChange: param.handleChange,
                                                disabled: true
                                              });
                                  })
                              })) : null, React.createElement(SelfCheckoutUpsertForm.Field.make, {
                          field: /* HasAffiliate */5,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(BooleanSelectField.make, {
                                          label: "Habilitar afiliado?",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          options: [
                                            {
                                              label: "Sim",
                                              value: true
                                            },
                                            {
                                              label: "Não",
                                              value: false
                                            }
                                          ]
                                        });
                            })
                        }), reform.state.values.hasAffiliate ? React.createElement(React.Fragment, undefined, React.createElement(SelfCheckoutUpsertForm.Field.make, {
                                field: /* AffiliateId */6,
                                render: (function (param) {
                                    var handleChange = param.handleChange;
                                    return React.createElement(AffiliateSelectField.make, {
                                                label: "Afiliado",
                                                error: param.error,
                                                required: true,
                                                value: param.value,
                                                onChange: (function (param) {
                                                    Curry._1(handleChange, param.value);
                                                    return $$Promise.resolved(undefined);
                                                  }),
                                                options: Belt_Array.map(Belt_Array.keep(Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(meAffiliatesFragment.affiliates, (function (affiliates) {
                                                                        return affiliates.edges;
                                                                      })), []), (function (affiliateEdge) {
                                                                return Belt_Option.flatMap(affiliateEdge, (function (affiliateEdge) {
                                                                              return affiliateEdge.node;
                                                                            }));
                                                              })), Belt_Option.isSome), (function (affiliateNode) {
                                                        return Belt_Option.mapWithDefault(affiliateNode, {
                                                                    label: "",
                                                                    value: ""
                                                                  }, (function (affiliateNode) {
                                                                      return {
                                                                              label: affiliateNode.firstName + (" " + (affiliateNode.lastName + (" - " + affiliateNode.taxId))),
                                                                              value: affiliateNode.id
                                                                            };
                                                                    }));
                                                      }))
                                              });
                                  })
                              }), React.createElement(SelfCheckoutUpsertForm.Field.make, {
                                field: /* AffiliatePercentage */7,
                                render: (function (param) {
                                    return React.createElement(NumberField$VannaFrontend.make, {
                                                label: "Porcentagem do afiliado",
                                                error: param.error,
                                                required: true,
                                                value: param.value,
                                                onChange: param.handleChange
                                              });
                                  })
                              })) : null, React.createElement(SelfCheckoutUpsertForm.Field.make, {
                          field: /* Snippets */8,
                          render: (function (param) {
                              return React.createElement(TextAreaField$VannaFrontend.make, {
                                          label: "Snippets para carregar na página de pagamento",
                                          error: param.error,
                                          required: false,
                                          value: param.value,
                                          onChange: param.handleChange,
                                          rows: 8
                                        });
                            })
                        }), React.createElement(SelfCheckoutUpsertForm.Field.make, {
                          field: /* Items */9,
                          render: (function (param) {
                              var tmp;
                              tmp = selectedItem !== undefined && selectedItem.TAG === /* Product */0 ? true : false;
                              var tmp$1;
                              tmp$1 = selectedItem !== undefined && selectedItem.TAG !== /* Product */0 ? true : false;
                              return React.createElement(React.Fragment, undefined, React.createElement(ItemsTable$VannaFrontend.make, {
                                              title: "Produtos",
                                              data: Belt_Array.reduce(reform.state.values.items, [], (function (acc, item) {
                                                      if (item.TAG === /* Product */0) {
                                                        return Belt_Array.concat(acc, [item._0]);
                                                      } else {
                                                        return acc;
                                                      }
                                                    })),
                                              onRemoveItem: (function (itemTemporaryId) {
                                                  var rowIndex = Belt_Array.getIndexBy(reform.state.values.items, (function (item) {
                                                          if (item.TAG === /* Product */0) {
                                                            return item._0.temporaryId === itemTemporaryId;
                                                          } else {
                                                            return false;
                                                          }
                                                        }));
                                                  if (rowIndex !== undefined) {
                                                    return Curry._2(reform.arrayRemoveByIndex, /* Items */9, rowIndex);
                                                  }
                                                  
                                                }),
                                              onEditItem: (function (item) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                return {
                                                                        TAG: /* Product */0,
                                                                        _0: item
                                                                      };
                                                              }));
                                                }),
                                              domain: /* SelfCheckout */2
                                            }), React.createElement(Modal$VannaFrontend.make, {
                                              title: "Atualizar produto",
                                              trigger: (function (param) {
                                                  return null;
                                                }),
                                              controlledOpen: tmp,
                                              content: (function (dispatch) {
                                                  return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                              children: React.createElement(ItemTableUpsertProductForm$VannaFrontend.make, {
                                                                    domain: /* SelfCheckout */2,
                                                                    item: selectedItem !== undefined ? selectedItem : undefined,
                                                                    dispatchModal: dispatch,
                                                                    onUpsert: (function (item) {
                                                                        Curry._1(setSelectedItem, (function (param) {
                                                                                
                                                                              }));
                                                                        if (item.TAG === /* Insert */0) {
                                                                          return ;
                                                                        }
                                                                        var item$1 = item._0;
                                                                        var index = Belt_Array.getIndexBy(reform.state.values.items, (function (cur) {
                                                                                if (cur.TAG === /* Product */0) {
                                                                                  return item$1.temporaryId === cur._0.temporaryId;
                                                                                } else {
                                                                                  return false;
                                                                                }
                                                                              }));
                                                                        if (index !== undefined) {
                                                                          return Curry._3(reform.arrayUpdateByIndex, /* Items */9, index, {
                                                                                      TAG: /* Product */0,
                                                                                      _0: item$1
                                                                                    });
                                                                        }
                                                                        
                                                                      }),
                                                                    query: me
                                                                  })
                                                            });
                                                }),
                                              onClose: (function (param) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                
                                                              }));
                                                })
                                            }), React.createElement(Modal$VannaFrontend.make, {
                                              title: "Adicionar produto",
                                              trigger: (function (dispatch) {
                                                  return React.createElement(Button$VannaFrontend.make, {
                                                              mode: /* Secondary */1,
                                                              onClick: (function (param) {
                                                                  return Curry._1(dispatch, /* Open */2);
                                                                }),
                                                              children: null
                                                            }, React.createElement(Fi.FiPlus, {}), "Adicionar produto");
                                                }),
                                              content: (function (dispatch) {
                                                  return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                              children: React.createElement(ItemTableUpsertProductForm$VannaFrontend.make, {
                                                                    domain: /* SelfCheckout */2,
                                                                    item: undefined,
                                                                    dispatchModal: dispatch,
                                                                    onUpsert: (function (item) {
                                                                        if (item.TAG === /* Insert */0) {
                                                                          return Curry._2(reform.arrayPush, /* Items */9, {
                                                                                      TAG: /* Product */0,
                                                                                      _0: item._0
                                                                                    });
                                                                        }
                                                                        
                                                                      }),
                                                                    query: me
                                                                  })
                                                            });
                                                })
                                            }), React.createElement(Modal$VannaFrontend.make, {
                                              title: "Atualizar serviço",
                                              trigger: (function (param) {
                                                  return null;
                                                }),
                                              controlledOpen: tmp$1,
                                              content: (function (dispatch) {
                                                  return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                              children: React.createElement(ItemTableUpsertServiceForm$VannaFrontend.make, {
                                                                    domain: /* SelfCheckout */2,
                                                                    item: selectedItem !== undefined ? selectedItem : undefined,
                                                                    dispatchModal: dispatch,
                                                                    onUpsert: (function (item) {
                                                                        Curry._1(setSelectedItem, (function (param) {
                                                                                
                                                                              }));
                                                                        if (item.TAG === /* Insert */0) {
                                                                          return ;
                                                                        }
                                                                        var item$1 = item._0;
                                                                        var index = Belt_Array.getIndexBy(reform.state.values.items, (function (cur) {
                                                                                if (cur.TAG === /* Product */0) {
                                                                                  return false;
                                                                                } else {
                                                                                  return item$1.temporaryId === cur._0.temporaryId;
                                                                                }
                                                                              }));
                                                                        if (index !== undefined) {
                                                                          return Curry._3(reform.arrayUpdateByIndex, /* Items */9, index, {
                                                                                      TAG: /* Service */1,
                                                                                      _0: item$1
                                                                                    });
                                                                        }
                                                                        
                                                                      }),
                                                                    query: me
                                                                  })
                                                            });
                                                }),
                                              onClose: (function (param) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                
                                                              }));
                                                })
                                            }), React.createElement(ItemsTable$VannaFrontend.make, {
                                              title: "Serviços",
                                              data: Belt_Array.reduce(reform.state.values.items, [], (function (acc, item) {
                                                      if (item.TAG === /* Product */0) {
                                                        return acc;
                                                      } else {
                                                        return Belt_Array.concat(acc, [item._0]);
                                                      }
                                                    })),
                                              onRemoveItem: (function (itemTemporaryId) {
                                                  var rowIndex = Belt_Array.getIndexBy(reform.state.values.items, (function (item) {
                                                          if (item.TAG === /* Product */0) {
                                                            return false;
                                                          } else {
                                                            return item._0.temporaryId === itemTemporaryId;
                                                          }
                                                        }));
                                                  if (rowIndex !== undefined) {
                                                    return Curry._2(reform.arrayRemoveByIndex, /* Items */9, rowIndex);
                                                  }
                                                  
                                                }),
                                              onEditItem: (function (item) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                return {
                                                                        TAG: /* Service */1,
                                                                        _0: item
                                                                      };
                                                              }));
                                                }),
                                              domain: /* SelfCheckout */2
                                            }), React.createElement(Modal$VannaFrontend.make, {
                                              title: "Adicionar serviço",
                                              trigger: (function (dispatch) {
                                                  return React.createElement(Button$VannaFrontend.make, {
                                                              marginTop: false,
                                                              mode: /* Secondary */1,
                                                              onClick: (function (param) {
                                                                  return Curry._1(dispatch, /* Open */2);
                                                                }),
                                                              children: null
                                                            }, React.createElement(Fi.FiPlus, {}), "Adicionar serviço");
                                                }),
                                              content: (function (dispatch) {
                                                  return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                              children: React.createElement(ItemTableUpsertServiceForm$VannaFrontend.make, {
                                                                    domain: /* SelfCheckout */2,
                                                                    item: undefined,
                                                                    dispatchModal: dispatch,
                                                                    onUpsert: (function (item) {
                                                                        if (item.TAG === /* Insert */0) {
                                                                          return Curry._2(reform.arrayPush, /* Items */9, {
                                                                                      TAG: /* Service */1,
                                                                                      _0: item._0
                                                                                    });
                                                                        }
                                                                        
                                                                      }),
                                                                    query: me
                                                                  })
                                                            });
                                                })
                                            }));
                            })
                        }), tmp, React.createElement(Button$VannaFrontend.make, {
                          isLoading: reform.isSubmitting,
                          type_: /* Submit */1,
                          children: Belt_Option.isSome(selfCheckoutFragment) ? "Atualizar" : "Adicionar"
                        }), React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, /* CloseWithConfirmation */0);
                            }),
                          children: "Cancelar"
                        })), undefined));
}

var make = SelfCheckoutUpsert;

exports.StateLenses = StateLenses;
exports.SelfCheckoutUpsertForm = SelfCheckoutUpsertForm;
exports.BooleanSelectField = BooleanSelectField;
exports.AffiliateSelectField = AffiliateSelectField;
exports.SelfCheckoutFragment = SelfCheckoutFragment;
exports.SelfCheckoutFragmentAffiliates = SelfCheckoutFragmentAffiliates;
exports.SelfCheckoutCreateMutation = SelfCheckoutCreateMutation;
exports.SelfCheckoutUpdateMutation = SelfCheckoutUpdateMutation;
exports.make = make;
/* SelfCheckoutUpsertForm Not a pure module */
