'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var InputBase$VannaFrontend = require("./InputBase.bs.js");
var FieldError$VannaFrontend = require("./FieldError.bs.js");
var FieldLabel$VannaFrontend = require("./FieldLabel.bs.js");
var FieldWrapper$VannaFrontend = require("./FieldWrapper.bs.js");

function NumberField(Props) {
  var label = Props.label;
  var errorOpt = Props.error;
  var requiredOpt = Props.required;
  var tip = Props.tip;
  var floatRight = Props.floatRight;
  var value = Props.value;
  var onChange = Props.onChange;
  var wrapperClassNameOpt = Props.wrapperClassName;
  var disabledOpt = Props.disabled;
  var min = Props.min;
  var max = Props.max;
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var wrapperClassName = wrapperClassNameOpt !== undefined ? wrapperClassNameOpt : Curry._1(Css.style, /* [] */0);
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var hasError = error !== undefined ? error !== "" : false;
  var tmp;
  if (label !== undefined) {
    var tmp$1 = {
      hasError: hasError,
      required: required,
      children: label
    };
    if (tip !== undefined) {
      tmp$1.tip = Caml_option.valFromOption(tip);
    }
    if (floatRight !== undefined) {
      tmp$1.floatRight = Caml_option.valFromOption(floatRight);
    }
    tmp = React.createElement(FieldLabel$VannaFrontend.make, tmp$1);
  } else {
    tmp = null;
  }
  var tmp$2 = {
    type_: /* Number */2,
    disabled: disabled,
    value: String(value),
    onChange: (function (evt) {
        var value = evt.target.value;
        if (value === "") {
          return Curry._1(onChange, 0);
        } else {
          return Curry._1(onChange, Caml_format.caml_int_of_string(value));
        }
      })
  };
  if (min !== undefined) {
    tmp$2.min = Caml_option.valFromOption(min);
  }
  if (max !== undefined) {
    tmp$2.max = Caml_option.valFromOption(max);
  }
  return React.createElement(FieldWrapper$VannaFrontend.make, {
              className: wrapperClassName,
              children: null
            }, tmp, React.createElement(InputBase$VannaFrontend.make, tmp$2), hasError ? React.createElement(FieldError$VannaFrontend.make, {
                    message: Belt_Option.getWithDefault(error, "")
                  }) : null);
}

var make = NumberField;

exports.make = make;
/* Css Not a pure module */
