'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fuse$VannaFrontend = require("../types/Fuse.bs.js");
var FieldError$VannaFrontend = require("./FieldError.bs.js");
var FieldLabel$VannaFrontend = require("./FieldLabel.bs.js");
var FieldWrapper$VannaFrontend = require("./FieldWrapper.bs.js");
var ReactSelectAsyncPaginate$VannaFrontend = require("../types/ReactSelectAsyncPaginate.bs.js");

function Make(Config) {
  var SelectAsyncPaginate = ReactSelectAsyncPaginate$VannaFrontend.Make({});
  var SearchableSelectField$Make = function (Props) {
    var label = Props.label;
    var errorOpt = Props.error;
    var requiredOpt = Props.required;
    var tip = Props.tip;
    var floatRight = Props.floatRight;
    var value = Props.value;
    var onChange = Props.onChange;
    var placeholderOpt = Props.placeholder;
    var disabledOpt = Props.disabled;
    var options = Props.options;
    var loadMore = Props.loadMore;
    var onInputChangeOpt = Props.onInputChange;
    var wrapperClassNameOpt = Props.wrapperClassName;
    var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
    var required = requiredOpt !== undefined ? requiredOpt : false;
    var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var onInputChange = onInputChangeOpt !== undefined ? onInputChangeOpt : (function (param) {
          
        });
    var wrapperClassName = wrapperClassNameOpt !== undefined ? wrapperClassNameOpt : Curry._1(Css.style, /* [] */0);
    var hasError = error !== undefined ? error !== "" : false;
    var fuse = React.useMemo((function () {
            return Fuse$VannaFrontend.fuse(options, {
                        keys: [{
                            name: "label",
                            weight: 0.9
                          }]
                      });
          }), []);
    var tmp;
    if (label !== undefined) {
      var tmp$1 = {
        hasError: hasError,
        required: required,
        children: label
      };
      if (tip !== undefined) {
        tmp$1.tip = Caml_option.valFromOption(tip);
      }
      if (floatRight !== undefined) {
        tmp$1.floatRight = Caml_option.valFromOption(floatRight);
      }
      tmp = React.createElement(FieldLabel$VannaFrontend.make, tmp$1);
    } else {
      tmp = null;
    }
    return React.createElement(FieldWrapper$VannaFrontend.make, {
                className: wrapperClassName,
                children: null
              }, tmp, React.createElement(SelectAsyncPaginate.make, {
                    value: value,
                    loadOptions: (function (search, loadedOptions) {
                        if (loadMore !== undefined) {
                          return Curry._1(loadMore, search);
                        }
                        var hasSearch = search !== "";
                        var finalOptions = hasSearch ? Belt_Array.map(fuse.search(search), (function (result) {
                                  return result.item;
                                })) : options;
                        var loadedLength = loadedOptions.length;
                        var result = Belt_Array.slice(finalOptions, loadedLength, loadedLength + 20 | 0);
                        var hasMore = (loadedLength + 20 | 0) <= finalOptions.length;
                        return Promise.resolve({
                                    options: result,
                                    hasMore: hasMore
                                  });
                      }),
                    options: options,
                    placeholder: placeholder,
                    onChange: onChange,
                    onInputChange: onInputChange,
                    disabled: disabled
                  }), hasError ? React.createElement(FieldError$VannaFrontend.make, {
                      message: Belt_Option.getWithDefault(error, "")
                    }) : null);
  };
  return {
          SelectAsyncPaginate: SelectAsyncPaginate,
          make: SearchableSelectField$Make
        };
}

var loadMoreSize = 20;

exports.loadMoreSize = loadMoreSize;
exports.Make = Make;
/* Css Not a pure module */
