'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Text$VannaFrontend = require("./Text.bs.js");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");

var cardNumber = Curry._1(Css.style, {
      hd: Css.flexShrink(0),
      tl: {
        hd: Css.flexBasis({
              NAME: "px",
              VAL: 0
            }),
        tl: {
          hd: Css.flexGrow(4),
          tl: /* [] */0
        }
      }
    });

var cardPoints = Curry._1(Css.style, {
      hd: Css.flexShrink(0),
      tl: {
        hd: Css.flexBasis({
              NAME: "px",
              VAL: 0
            }),
        tl: {
          hd: Css.fontSize({
                NAME: "rem",
                VAL: 1.8
              }),
          tl: {
            hd: Css.flexGrow(1.5),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.justifyContent("flexEnd"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Styles = {
  cardNumber: cardNumber,
  cardPoints: cardPoints
};

function CreditCardReadOnly(Props) {
  var brand = Props.brand;
  var last4 = Props.last4;
  return React.createElement(FieldGrid$VannaFrontend.make, {
              children: null
            }, React.createElement(Text$VannaFrontend.make, {
                  className: cardNumber,
                  children: brand + (" terminado em " + last4)
                }), React.createElement(Text$VannaFrontend.make, {
                  className: cardPoints,
                  children: "..."
                }));
}

var make = CreditCardReadOnly;

exports.Styles = Styles;
exports.make = make;
/* cardNumber Not a pure module */
