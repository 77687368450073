'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Color = require("color");
var React = require("react");
var Css_AtomicTypes = require("bs-css/src/Css_AtomicTypes.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");

function getMargin(flag) {
  if (flag) {
    return {
            NAME: "px",
            VAL: 10
          };
  } else {
    return {
            NAME: "px",
            VAL: 0
          };
  }
}

function getColor(mode, lighter) {
  switch (mode) {
    case /* Primary */0 :
        return [
                Theme$VannaFrontend.Colors.primary,
                Theme$VannaFrontend.Colors.primary,
                Theme$VannaFrontend.Colors.buttonText
              ];
    case /* Secondary */1 :
        if (lighter) {
          return [
                  Theme$VannaFrontend.Colors.secondary,
                  Theme$VannaFrontend.Colors.secondary,
                  Theme$VannaFrontend.Colors.text
                ];
        } else {
          return [
                  Theme$VannaFrontend.Colors.background,
                  Theme$VannaFrontend.Colors.background,
                  Theme$VannaFrontend.Colors.text
                ];
        }
    case /* Danger */2 :
        return [
                Theme$VannaFrontend.Colors.error,
                Theme$VannaFrontend.Colors.error,
                Theme$VannaFrontend.Colors.secondary
              ];
    
  }
}

function button(marginTop, marginBottom, marginLeft, marginRight, small, fullWidth, mode, lighter) {
  var match = getColor(mode, lighter);
  var border = match[1];
  var background = match[0];
  return Curry._1(Css.style, {
              hd: Css.fontSize({
                    NAME: "rem",
                    VAL: 1.0
                  }),
              tl: {
                hd: Css.lineHeight({
                      NAME: "rem",
                      VAL: 0.9
                    }),
                tl: {
                  hd: Css.cursor("pointer"),
                  tl: {
                    hd: Css.fontWeight("bold"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.textDecoration("none"),
                            tl: {
                              hd: Css.whiteSpace("nowrap"),
                              tl: {
                                hd: Css.borderRadius(Theme$VannaFrontend.Border.radius),
                                tl: {
                                  hd: Css.margin4(getMargin(marginTop), getMargin(marginRight), getMargin(marginBottom), getMargin(marginLeft)),
                                  tl: {
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.backgroundColor(background),
                                      tl: {
                                        hd: Css.borderWidth({
                                              NAME: "px",
                                              VAL: 1
                                            }),
                                        tl: {
                                          hd: Css.borderStyle("solid"),
                                          tl: {
                                            hd: Css.borderColor(border),
                                            tl: {
                                              hd: Css.color(match[2]),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.unsafe("backgroundColor", new Color(Css_AtomicTypes.Color.toString(background)).darken(0.125).string()),
                                                      tl: {
                                                        hd: Css.unsafe("borderColor", new Color(Css_AtomicTypes.Color.toString(border)).darken(0.125).string()),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: {
                                                  hd: Css.active({
                                                        hd: Css.unsafe("backgroundColor", new Color(Css_AtomicTypes.Color.toString(background)).darken(0.25).string()),
                                                        tl: {
                                                          hd: Css.unsafe("borderColor", new Color(Css_AtomicTypes.Color.toString(border)).darken(0.25).string()),
                                                          tl: /* [] */0
                                                        }
                                                      }),
                                                  tl: {
                                                    hd: Css.disabled({
                                                          hd: Css.backgroundColor(Theme$VannaFrontend.Colors.buttonDisabled),
                                                          tl: {
                                                            hd: Css.borderColor(Theme$VannaFrontend.Colors.buttonDisabled),
                                                            tl: {
                                                              hd: Css.cursor("notAllowed"),
                                                              tl: {
                                                                hd: Css.color(Theme$VannaFrontend.Colors.buttonDisabledText),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }),
                                                    tl: {
                                                      hd: small ? Css.padding2({
                                                              NAME: "rem",
                                                              VAL: 0.5
                                                            }, {
                                                              NAME: "rem",
                                                              VAL: 0.65
                                                            }) : Css.padding2({
                                                              NAME: "rem",
                                                              VAL: 0.75
                                                            }, {
                                                              NAME: "rem",
                                                              VAL: 0.9
                                                            }),
                                                      tl: fullWidth ? ({
                                                            hd: Css.textAlign("center"),
                                                            tl: {
                                                              hd: Css.width({
                                                                    NAME: "percent",
                                                                    VAL: 100.0
                                                                  }),
                                                              tl: {
                                                                hd: Css.boxSizing("borderBox"),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }) : /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Styles = {
  getMargin: getMargin,
  getColor: getColor,
  button: button
};

function Button(Props) {
  var marginTopOpt = Props.marginTop;
  var marginBottomOpt = Props.marginBottom;
  var marginLeftOpt = Props.marginLeft;
  var marginRightOpt = Props.marginRight;
  var smallOpt = Props.small;
  var fullWidthOpt = Props.fullWidth;
  var modeOpt = Props.mode;
  var lighterOpt = Props.lighter;
  var isLoadingOpt = Props.isLoading;
  var onClick = Props.onClick;
  var type_Opt = Props.type_;
  var disabledOpt = Props.disabled;
  var classNameOpt = Props.className;
  var children = Props.children;
  var marginTop = marginTopOpt !== undefined ? marginTopOpt : true;
  var marginBottom = marginBottomOpt !== undefined ? marginBottomOpt : false;
  var marginLeft = marginLeftOpt !== undefined ? marginLeftOpt : false;
  var marginRight = marginRightOpt !== undefined ? marginRightOpt : false;
  var small = smallOpt !== undefined ? smallOpt : false;
  var fullWidth = fullWidthOpt !== undefined ? fullWidthOpt : true;
  var mode = modeOpt !== undefined ? modeOpt : /* Primary */0;
  var lighter = lighterOpt !== undefined ? lighterOpt : false;
  var isLoading = isLoadingOpt !== undefined ? isLoadingOpt : false;
  var type_ = type_Opt !== undefined ? type_Opt : /* Button */0;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  var buttonType = type_ ? "submit" : "button";
  return React.createElement("button", {
              className: Curry._1(Css.merge, {
                    hd: button(marginTop, marginBottom, marginLeft, marginRight, small, fullWidth, mode, lighter),
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  }),
              disabled: disabled || isLoading,
              type: buttonType,
              onClick: (function (evt) {
                  if (onClick !== undefined) {
                    evt.preventDefault();
                    return Curry._1(onClick, evt);
                  }
                  
                })
            }, isLoading ? "Carregando..." : children);
}

var make = Button;

exports.Styles = Styles;
exports.make = make;
/* Css Not a pure module */
