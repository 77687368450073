'use strict';

var FuseJs = require("fuse.js").default;

function fuse(prim, prim$1) {
  return new FuseJs(prim, prim$1);
}

exports.fuse = fuse;
/* fuse.js Not a pure module */
