'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Hr$VannaFrontend = require("../components/Hr.bs.js");
var Box$VannaFrontend = require("../components/Box.bs.js");
var Link$VannaFrontend = require("../components/Link.bs.js");
var Button$VannaFrontend = require("../components/Button.bs.js");
var TextField$VannaFrontend = require("../components/TextField.bs.js");
var BrandPanel$VannaFrontend = require("../components/BrandPanel.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var Authorization$VannaFrontend = require("../types/Authorization.bs.js");
var TwoFactorForm$VannaFrontend = require("../components/TwoFactorForm.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var LoginMutation_graphql$VannaFrontend = require("../__generated__/LoginMutation_graphql.bs.js");
var Login_RequestLoginVerificationsMutation_graphql$VannaFrontend = require("../__generated__/Login_RequestLoginVerificationsMutation_graphql.bs.js");

function get(state, field) {
  switch (field) {
    case /* Email */0 :
        return state.email;
    case /* Password */1 :
        return state.password;
    case /* GoogleAuthCode */2 :
        return state.googleAuthCode;
    case /* SmsAuthCode */3 :
        return state.smsAuthCode;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Email */0 :
        return {
                email: value,
                password: state.password,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* Password */1 :
        return {
                email: state.email,
                password: value,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* GoogleAuthCode */2 :
        return {
                email: state.email,
                password: state.password,
                googleAuthCode: value,
                smsAuthCode: state.smsAuthCode
              };
    case /* SmsAuthCode */3 :
        return {
                email: state.email,
                password: state.password,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var LoginForm = ReForm.Make({
      set: set,
      get: get
    });

var forgotPasswordWrapper = Curry._1(Css.style, {
      hd: Css.textAlign("right"),
      tl: /* [] */0
    });

var versionWrapper = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.9
          }),
      tl: {
        hd: Css.marginTop({
              NAME: "rem",
              VAL: 1.3
            }),
        tl: {
          hd: Css.textAlign("center"),
          tl: /* [] */0
        }
      }
    });

var linkWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexEnd"),
        tl: /* [] */0
      }
    });

var link = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.8
          }),
      tl: {
        hd: Css.marginBottom({
              NAME: "px",
              VAL: 10
            }),
        tl: /* [] */0
      }
    });

var smsField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 75
          }),
      tl: /* [] */0
    });

var Styles = {
  forgotPasswordWrapper: forgotPasswordWrapper,
  versionWrapper: versionWrapper,
  linkWrapper: linkWrapper,
  link: link,
  smsField: smsField
};

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.node,
              variables: Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var RequestLoginVerificationsMutation_make_userRequestLoginVerificationsInput = Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Utils.make_userRequestLoginVerificationsInput;

var RequestLoginVerificationsMutation_makeVariables = Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Utils.makeVariables;

var RequestLoginVerificationsMutation_make_response_userRequestLoginVerifications_error = Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Utils.make_response_userRequestLoginVerifications_error;

var RequestLoginVerificationsMutation_make_response_userRequestLoginVerifications = Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Utils.make_response_userRequestLoginVerifications;

var RequestLoginVerificationsMutation_makeOptimisticResponse = Login_RequestLoginVerificationsMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var RequestLoginVerificationsMutation = {
  make_userRequestLoginVerificationsInput: RequestLoginVerificationsMutation_make_userRequestLoginVerificationsInput,
  makeVariables: RequestLoginVerificationsMutation_makeVariables,
  make_response_userRequestLoginVerifications_error: RequestLoginVerificationsMutation_make_response_userRequestLoginVerifications_error,
  make_response_userRequestLoginVerifications: RequestLoginVerificationsMutation_make_response_userRequestLoginVerifications,
  makeOptimisticResponse: RequestLoginVerificationsMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

var Internal$1 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: LoginMutation_graphql$VannaFrontend.node,
              variables: LoginMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, LoginMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? LoginMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, LoginMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(LoginMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, LoginMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? LoginMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, LoginMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: LoginMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var LoginMutation_make_twoFactor = LoginMutation_graphql$VannaFrontend.Utils.make_twoFactor;

var LoginMutation_make_signInInput = LoginMutation_graphql$VannaFrontend.Utils.make_signInInput;

var LoginMutation_makeVariables = LoginMutation_graphql$VannaFrontend.Utils.makeVariables;

var LoginMutation_make_response_signIn_error = LoginMutation_graphql$VannaFrontend.Utils.make_response_signIn_error;

var LoginMutation_make_response_signIn = LoginMutation_graphql$VannaFrontend.Utils.make_response_signIn;

var LoginMutation_makeOptimisticResponse = LoginMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var LoginMutation = {
  make_twoFactor: LoginMutation_make_twoFactor,
  make_signInInput: LoginMutation_make_signInInput,
  makeVariables: LoginMutation_makeVariables,
  make_response_signIn_error: LoginMutation_make_response_signIn_error,
  make_response_signIn: LoginMutation_make_response_signIn,
  makeOptimisticResponse: LoginMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation$1,
  use: use$1
};

function Login(Props) {
  var $$navigator = Props.navigator;
  var match = use(undefined);
  var requestLoginVerifications = match[0];
  var match$1 = use$1(undefined);
  var signIn = match$1[0];
  var match$2 = React.useState(function () {
        return /* EmailAndPassword */0;
      });
  var setCurrentStep = match$2[1];
  var currentStep = match$2[0];
  var match$3 = React.useState(function () {
        return [];
      });
  var setEnabledAuthVerifications = match$3[1];
  var enabledAuthVerifications = match$3[0];
  var callSignIn = function (email, password, googleAuthCode, smsAuthCode, send, raiseSubmitFailed) {
    Curry.app(signIn, [
          (function (param) {
              return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
            }),
          (function (response, _errors) {
              var match = response.signIn;
              if (match === undefined) {
                return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
              }
              var token = match.token;
              if (token !== undefined) {
                Authorization$VannaFrontend.signIn(token);
                Curry._1($$navigator.push, /* Dashboard */3);
                return Curry._1(send, {
                            TAG: /* SetFormState */10,
                            _0: /* Dirty */0
                          });
              }
              var match$1 = match.error;
              if (match$1 !== undefined) {
                return Curry._1(raiseSubmitFailed, match$1.message);
              } else {
                return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
              }
            }),
          undefined,
          undefined,
          undefined,
          undefined,
          {
            input: {
              email: email,
              password: password,
              twoFactor: {
                googleAuthCode: googleAuthCode,
                smsAuthCode: smsAuthCode
              }
            }
          },
          undefined,
          undefined
        ]);
    
  };
  var reform = Curry._7(LoginForm.use, {
        email: "",
        password: "",
        googleAuthCode: "",
        smsAuthCode: ""
      }, /* Schema */{
        _0: Curry._2(LoginForm.ReSchema.Validation.$plus, Curry._3(LoginForm.ReSchema.Validation.email, "Email inválido", undefined, /* Email */0), Curry._6(LoginForm.ReSchema.Validation.string, 8, "A senha precisa ter pelo menos 8 caracteres", undefined, undefined, undefined, /* Password */1))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          if (currentStep) {
            callSignIn(state.values.email, state.values.password, state.values.googleAuthCode, state.values.smsAuthCode, send, raiseSubmitFailed);
          } else {
            Curry.app(requestLoginVerifications, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, _errors) {
                      var match = response.userRequestLoginVerifications;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var verifications = match.verifications;
                      if (verifications.length !== 0) {
                        Curry._1(setEnabledAuthVerifications, (function (param) {
                                return verifications;
                              }));
                        Curry._1(send, {
                              TAG: /* SetFormState */10,
                              _0: /* Dirty */0
                            });
                        return Curry._1(setCurrentStep, (function (param) {
                                      return /* TwoFactor */1;
                                    }));
                      } else {
                        return callSignIn(state.values.email, state.values.password, state.values.googleAuthCode, state.values.smsAuthCode, send, raiseSubmitFailed);
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    input: {
                      email: state.values.email,
                      password: state.values.password
                    }
                  },
                  undefined,
                  undefined
                ]);
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$4 = reform.formState;
  var tmp;
  if (typeof match$4 === "number") {
    tmp = null;
  } else {
    var error = match$4._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(BrandPanel$VannaFrontend.make, {
              title: "Login",
              children: null
            }, React.createElement(LoginForm.Provider.make, Curry._3(LoginForm.Provider.makeProps, reform, React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              return Curry._1(reform.submit, undefined);
                            })
                        }, currentStep ? React.createElement(TwoFactorForm$VannaFrontend.make, {
                                isGoogleAuthEnabled: Belt_Array.some(enabledAuthVerifications, (function (auth) {
                                        return auth === "GOOGLE";
                                      })),
                                isSmsAuthEnabled: Belt_Array.some(enabledAuthVerifications, (function (auth) {
                                        return auth === "SMS";
                                      })),
                                initialCanRequestCode: false,
                                onChange: (function (method, value) {
                                    if (method) {
                                      return Curry._4(reform.setFieldValue, /* SmsAuthCode */3, value, undefined, undefined);
                                    } else {
                                      return Curry._4(reform.setFieldValue, /* GoogleAuthCode */2, value, undefined, undefined);
                                    }
                                  })
                              }) : React.createElement(React.Fragment, undefined, React.createElement(LoginForm.Field.make, {
                                    field: /* Email */0,
                                    render: (function (param) {
                                        return React.createElement(TextField$VannaFrontend.make, {
                                                    label: "Email",
                                                    error: param.error,
                                                    type_: /* Email */0,
                                                    value: param.value,
                                                    onChange: param.handleChange
                                                  });
                                      })
                                  }), React.createElement(LoginForm.Field.make, {
                                    field: /* Password */1,
                                    render: (function (param) {
                                        return React.createElement(TextField$VannaFrontend.make, {
                                                    label: "Senha",
                                                    error: param.error,
                                                    type_: /* Password */4,
                                                    value: param.value,
                                                    onChange: param.handleChange
                                                  });
                                      })
                                  })), tmp, React.createElement(Hr$VannaFrontend.make, {}), React.createElement(Button$VannaFrontend.make, {
                              isLoading: reform.isSubmitting,
                              type_: /* Submit */1,
                              children: "Entrar"
                            })), undefined)), React.createElement(Hr$VannaFrontend.make, {}), React.createElement("div", {
                  className: forgotPasswordWrapper
                }, React.createElement(Link$VannaFrontend.make, {
                      content: "Esqueci minha senha",
                      onClick: (function (param) {
                          return Curry._1($$navigator.push, /* ForgotPassword */2);
                        })
                    })), React.createElement(Button$VannaFrontend.make, {
                  marginTop: true,
                  mode: /* Secondary */1,
                  onClick: (function (param) {
                      return Curry._1($$navigator.push, /* SignUp */1);
                    }),
                  children: "Criar conta"
                }), React.createElement("div", {
                  className: versionWrapper
                }, React.createElement("span", undefined, "v1.0.0")));
}

var make = Login;

exports.StateLenses = StateLenses;
exports.LoginForm = LoginForm;
exports.Styles = Styles;
exports.RequestLoginVerificationsMutation = RequestLoginVerificationsMutation;
exports.LoginMutation = LoginMutation;
exports.make = make;
/* LoginForm Not a pure module */
