'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Hr$VannaFrontend = require("../components/Hr.bs.js");
var Box$VannaFrontend = require("../components/Box.bs.js");
var Button$VannaFrontend = require("../components/Button.bs.js");
var TextField$VannaFrontend = require("../components/TextField.bs.js");
var BrandPanel$VannaFrontend = require("../components/BrandPanel.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var Authorization$VannaFrontend = require("../types/Authorization.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var SignUpMutation_graphql$VannaFrontend = require("../__generated__/SignUpMutation_graphql.bs.js");

function get(state, field) {
  switch (field) {
    case /* Name */0 :
        return state.name;
    case /* Email */1 :
        return state.email;
    case /* Password */2 :
        return state.password;
    case /* ConfirmPassword */3 :
        return state.confirmPassword;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Name */0 :
        return {
                name: value,
                email: state.email,
                password: state.password,
                confirmPassword: state.confirmPassword
              };
    case /* Email */1 :
        return {
                name: state.name,
                email: value,
                password: state.password,
                confirmPassword: state.confirmPassword
              };
    case /* Password */2 :
        return {
                name: state.name,
                email: state.email,
                password: value,
                confirmPassword: state.confirmPassword
              };
    case /* ConfirmPassword */3 :
        return {
                name: state.name,
                email: state.email,
                password: state.password,
                confirmPassword: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var SignUpForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: SignUpMutation_graphql$VannaFrontend.node,
              variables: SignUpMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, SignUpMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? SignUpMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, SignUpMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(SignUpMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, SignUpMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? SignUpMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, SignUpMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: SignUpMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var SignUpMutation_make_signUpInput = SignUpMutation_graphql$VannaFrontend.Utils.make_signUpInput;

var SignUpMutation_makeVariables = SignUpMutation_graphql$VannaFrontend.Utils.makeVariables;

var SignUpMutation_make_response_signUp_error = SignUpMutation_graphql$VannaFrontend.Utils.make_response_signUp_error;

var SignUpMutation_make_response_signUp = SignUpMutation_graphql$VannaFrontend.Utils.make_response_signUp;

var SignUpMutation_makeOptimisticResponse = SignUpMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var SignUpMutation = {
  make_signUpInput: SignUpMutation_make_signUpInput,
  makeVariables: SignUpMutation_makeVariables,
  make_response_signUp_error: SignUpMutation_make_response_signUp_error,
  make_response_signUp: SignUpMutation_make_response_signUp,
  makeOptimisticResponse: SignUpMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

function SignUp(Props) {
  var $$navigator = Props.navigator;
  var codeOpt = Props.code;
  var code = codeOpt !== undefined ? Caml_option.valFromOption(codeOpt) : undefined;
  var match = use(undefined);
  var signUp = match[0];
  var reform = Curry._7(SignUpForm.use, {
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
      }, /* Schema */{
        _0: Curry._2(SignUpForm.ReSchema.Validation.$plus, Curry._2(SignUpForm.ReSchema.Validation.$plus, Curry._2(SignUpForm.ReSchema.Validation.$plus, Curry._3(SignUpForm.ReSchema.Validation.nonEmpty, "O nome é obrigatório", undefined, /* Name */0), Curry._3(SignUpForm.ReSchema.Validation.email, "Email inválido", undefined, /* Email */1)), Curry._6(SignUpForm.ReSchema.Validation.string, 8, "A senha precisa ter pelo menos 8 caracteres", undefined, undefined, undefined, /* Password */2)), Curry._3(SignUpForm.ReSchema.Validation.custom, (function (param) {
                    if (param.password !== param.confirmPassword) {
                      return {
                              TAG: /* Error */1,
                              _0: "Suas senhas não conferem"
                            };
                    } else {
                      return /* Valid */0;
                    }
                  }), undefined, /* ConfirmPassword */3))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          Curry.app(signUp, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, _errors) {
                    var match = response.signUp;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var token = match.token;
                    if (token !== undefined) {
                      Authorization$VannaFrontend.signIn(token);
                      Curry._1($$navigator.push, /* Dashboard */3);
                      return Curry._1(send, {
                                  TAG: /* SetFormState */10,
                                  _0: /* Dirty */0
                                });
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    } else {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                  }),
                undefined,
                undefined,
                undefined,
                undefined,
                {
                  input: {
                    code: code,
                    email: state.values.email,
                    name: state.values.name,
                    password: state.values.password
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$1 = reform.formState;
  var tmp;
  if (typeof match$1 === "number") {
    tmp = null;
  } else {
    var error = match$1._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(BrandPanel$VannaFrontend.make, {
              title: "Abra sua conta",
              children: null
            }, React.createElement(SignUpForm.Provider.make, Curry._3(SignUpForm.Provider.makeProps, reform, React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              return Curry._1(reform.submit, undefined);
                            })
                        }, React.createElement(SignUpForm.Field.make, {
                              field: /* Name */0,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Seu nome",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), React.createElement(SignUpForm.Field.make, {
                              field: /* Email */1,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Seu email",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), React.createElement(SignUpForm.Field.make, {
                              field: /* Password */2,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Sua senha",
                                              error: param.error,
                                              type_: /* Password */4,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), React.createElement(SignUpForm.Field.make, {
                              field: /* ConfirmPassword */3,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Confirme sua senha",
                                              error: param.error,
                                              type_: /* Password */4,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), tmp, React.createElement(Button$VannaFrontend.make, {
                              isLoading: reform.isSubmitting,
                              type_: /* Submit */1,
                              children: "Criar conta"
                            })), undefined)), React.createElement(Hr$VannaFrontend.make, {}), React.createElement(Button$VannaFrontend.make, {
                  marginTop: true,
                  mode: /* Secondary */1,
                  onClick: (function (param) {
                      return Curry._1($$navigator.push, /* Login */0);
                    }),
                  children: "Entrar"
                }));
}

var make = SignUp;

exports.StateLenses = StateLenses;
exports.SignUpForm = SignUpForm;
exports.SignUpMutation = SignUpMutation;
exports.make = make;
/* SignUpForm Not a pure module */
