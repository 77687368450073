'use strict';

var Uuid = require("uuid");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Fi = require("react-icons/fi");
var Format = require("date-fns/format");
var GetDay = require("date-fns/getDay");
var GetDate = require("date-fns/getDate");
var IsValid = require("date-fns/isValid");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var IsBefore = require("date-fns/isBefore");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var RelayEnv$VannaFrontend = require("../RelayEnv.bs.js");
var ChargeFns$VannaFrontend = require("../fns/ChargeFns.bs.js");
var DateField$VannaFrontend = require("./DateField.bs.js");
var FieldInfo$VannaFrontend = require("./FieldInfo.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var ItemsTable$VannaFrontend = require("./ItemsTable.bs.js");
var MoneyField$VannaFrontend = require("./MoneyField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var NumberField$VannaFrontend = require("./NumberField.bs.js");
var ChargeHelpers$VannaFrontend = require("../types/ChargeHelpers.bs.js");
var SuspenseLoader$VannaFrontend = require("./SuspenseLoader.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var SearchableSelectField$VannaFrontend = require("./SearchableSelectField.bs.js");
var ItemTableUpsertProductForm$VannaFrontend = require("./ItemTableUpsertProductForm.bs.js");
var ItemTableUpsertServiceForm$VannaFrontend = require("./ItemTableUpsertServiceForm.bs.js");
var RecurrentChargeUpsertFormQuery_graphql$VannaFrontend = require("../__generated__/RecurrentChargeUpsertFormQuery_graphql.bs.js");
var RecurrentChargeUpsertForm_recurrentCharge_graphql$VannaFrontend = require("../__generated__/RecurrentChargeUpsertForm_recurrentCharge_graphql.bs.js");
var RecurrentChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend = require("../__generated__/RecurrentChargeUpsertCustomersRefetchQuery_graphql.bs.js");
var RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend = require("../__generated__/RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql.bs.js");
var RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend = require("../__generated__/RecurrentChargeUpsertFormCustomersConnection_query_graphql.bs.js");
var RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend = require("../__generated__/RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql.bs.js");

function get(state, field) {
  switch (field) {
    case /* CustomerId */0 :
        return state.customerId;
    case /* Description */1 :
        return state.description;
    case /* PaymentMethod */2 :
        return state.paymentMethod;
    case /* Items */3 :
        return state.items;
    case /* AskForConfirmation */4 :
        return state.askForConfirmation;
    case /* ChargingMonthDay */5 :
        return state.chargingMonthDay;
    case /* ChargingWeekDay */6 :
        return state.chargingWeekDay;
    case /* RecurrencePeriod */7 :
        return state.recurrencePeriod;
    case /* CustomRecurrencePeriod */8 :
        return state.customRecurrencePeriod;
    case /* CustomRecurrencePeriodStartingDate */9 :
        return state.customRecurrencePeriodStartingDate;
    case /* ChargeImmediately */10 :
        return state.chargeImmediately;
    case /* FirstChargeAmount */11 :
        return state.firstChargeAmount;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* CustomerId */0 :
        return {
                customerId: value,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* Description */1 :
        return {
                customerId: state.customerId,
                description: value,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* PaymentMethod */2 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: value,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* Items */3 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: value,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* AskForConfirmation */4 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: value,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* ChargingMonthDay */5 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: value,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* ChargingWeekDay */6 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: value,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* RecurrencePeriod */7 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: value,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* CustomRecurrencePeriod */8 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: value,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* CustomRecurrencePeriodStartingDate */9 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: value,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* ChargeImmediately */10 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: value,
                firstChargeAmount: state.firstChargeAmount
              };
    case /* FirstChargeAmount */11 :
        return {
                customerId: state.customerId,
                description: state.description,
                paymentMethod: state.paymentMethod,
                items: state.items,
                askForConfirmation: state.askForConfirmation,
                chargingMonthDay: state.chargingMonthDay,
                chargingWeekDay: state.chargingWeekDay,
                recurrencePeriod: state.recurrencePeriod,
                customRecurrencePeriod: state.customRecurrencePeriod,
                customRecurrencePeriodStartingDate: state.customRecurrencePeriodStartingDate,
                chargeImmediately: state.chargeImmediately,
                firstChargeAmount: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var RecurrentChargeUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var CustomersSelect = SearchableSelectField$VannaFrontend.Make({});

var PaymentMethodSelect = SearchableSelectField$VannaFrontend.Make({});

var BooleanSelectField = SearchableSelectField$VannaFrontend.Make({});

var RecurrencePeriodSelectField = SearchableSelectField$VannaFrontend.Make({});

var IntegerSelectField = SearchableSelectField$VannaFrontend.Make({});

var getConnectionNodes = RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Utils.getConnectionNodes;

var Internal = {};

function internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = ReasonRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = ReasonRelay.mapRenderPolicy(renderPolicy);
  if (tmp$2 !== undefined) {
    tmp.UNSTABLE_renderPolicy = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$3 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var InternalRefetch = {
  internal_makeRefetchableFnOpts: internal_makeRefetchableFnOpts
};

function useRefetchable(fRef) {
  var match = Hooks.useRefetchableFragment(RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fRef);
  var refetchFn = match[1];
  var data = ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, match[0]);
  return [
          data,
          (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return Curry._2(refetchFn, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(RecurrentChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        ];
}

function use(fRef) {
  var data = Hooks.useFragment(RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function usePagination(fr) {
  var p = Hooks.usePaginationFragment(RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fr);
  var data = ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(RecurrentChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

function useBlockingPagination(fRef) {
  var p = Hooks.useBlockingPaginationFragment(RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fRef);
  var data = ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(RecurrentChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

var makeRefetchVariables = RecurrentChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend.Types.makeRefetchVariables;

var CustomersConnectionFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Internal: Internal,
  InternalRefetch: InternalRefetch,
  useRefetchable: useRefetchable,
  use: use,
  useOpt: useOpt,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination,
  makeRefetchVariables: makeRefetchVariables
};

var Internal$1 = {};

function use$1(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.node, RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.node, RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var RecurrentChargeUpsertFormQuery = {
  Types: undefined,
  Internal: Internal$1,
  use: use$1,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var Internal$2 = {};

function use$2(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader$1(param) {
  var match = Hooks.useQueryLoader(RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch$1(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node, RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised$1(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node, RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded$1(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var GetTransactionCostQuery_make_chargeGetTransactionCostQueryInput = RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Utils.make_chargeGetTransactionCostQueryInput;

var GetTransactionCostQuery_makeVariables = RecurrentChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Utils.makeVariables;

var GetTransactionCostQuery = {
  make_chargeGetTransactionCostQueryInput: GetTransactionCostQuery_make_chargeGetTransactionCostQueryInput,
  makeVariables: GetTransactionCostQuery_makeVariables,
  Types: undefined,
  Internal: Internal$2,
  use: use$2,
  useLoader: useLoader$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  usePreloaded: usePreloaded$1
};

var Internal$3 = {};

function use$3(fRef) {
  var data = Hooks.useFragment(RecurrentChargeUpsertForm_recurrentCharge_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargeUpsertForm_recurrentCharge_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt$1(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(RecurrentChargeUpsertForm_recurrentCharge_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return RecurrentChargeUpsertForm_recurrentCharge_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var RecurrentChargeFragment = {
  Types: undefined,
  Internal: Internal$3,
  use: use$3,
  useOpt: useOpt$1
};

var Internal$4 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.node,
              variables: RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$4(param) {
  var match = RelayExperimental.useMutation(RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var RecurrentChargeCreateMutation_make_chargeItem = RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Utils.make_chargeItem;

var RecurrentChargeCreateMutation_make_recurrentChargeCreateInput = RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Utils.make_recurrentChargeCreateInput;

var RecurrentChargeCreateMutation_makeVariables = RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var RecurrentChargeCreateMutation_make_response_recurrentChargeCreate_recurrentCharge_items = RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeCreate_recurrentCharge_items;

var RecurrentChargeCreateMutation_make_response_recurrentChargeCreate_recurrentCharge_customer = RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeCreate_recurrentCharge_customer;

var RecurrentChargeCreateMutation_make_response_recurrentChargeCreate_recurrentCharge = RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeCreate_recurrentCharge;

var RecurrentChargeCreateMutation_make_response_recurrentChargeCreate_error = RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeCreate_error;

var RecurrentChargeCreateMutation_make_response_recurrentChargeCreate = RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeCreate;

var RecurrentChargeCreateMutation_makeOptimisticResponse = RecurrentChargeUpsertForm_RecurrentChargeCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var RecurrentChargeCreateMutation = {
  make_chargeItem: RecurrentChargeCreateMutation_make_chargeItem,
  make_recurrentChargeCreateInput: RecurrentChargeCreateMutation_make_recurrentChargeCreateInput,
  makeVariables: RecurrentChargeCreateMutation_makeVariables,
  make_response_recurrentChargeCreate_recurrentCharge_items: RecurrentChargeCreateMutation_make_response_recurrentChargeCreate_recurrentCharge_items,
  make_response_recurrentChargeCreate_recurrentCharge_customer: RecurrentChargeCreateMutation_make_response_recurrentChargeCreate_recurrentCharge_customer,
  make_response_recurrentChargeCreate_recurrentCharge: RecurrentChargeCreateMutation_make_response_recurrentChargeCreate_recurrentCharge,
  make_response_recurrentChargeCreate_error: RecurrentChargeCreateMutation_make_response_recurrentChargeCreate_error,
  make_response_recurrentChargeCreate: RecurrentChargeCreateMutation_make_response_recurrentChargeCreate,
  makeOptimisticResponse: RecurrentChargeCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$4,
  commitMutation: commitMutation,
  use: use$4
};

function RecurrentChargeUpsertForm$1(Props) {
  var recurrentCharge = Props.recurrentCharge;
  var dispatchModal = Props.dispatchModal;
  var queryRef = Props.query;
  var recurrentChargeFragment = useOpt$1(recurrentCharge);
  var queryData = use$1(undefined, undefined, undefined, undefined, undefined, undefined);
  var match = React.useState(function () {
        
      });
  var setSelectedItem = match[1];
  var selectedItem = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoadingTransactionCost = match$1[1];
  var match$2 = React.useState(function () {
        return {
                pix: 0,
                ccDefault: 0,
                ccVisaOrMastercard: 0,
                boleto: 0
              };
      });
  var setTransactionCosts = match$2[1];
  var transactionCosts = match$2[0];
  var match$3 = use$4(undefined);
  var createRecurrentCharge = match$3[0];
  var match$4 = usePagination(queryRef);
  var hasNext = match$4.hasNext;
  var loadNext = match$4.loadNext;
  var customers = Curry._1(getConnectionNodes, match$4.data.customers);
  var tmp;
  if (recurrentChargeFragment !== undefined) {
    var match$5 = recurrentChargeFragment.paymentMethod;
    var match$6 = recurrentChargeFragment.recurrencePeriod;
    var firstChargeAmount = recurrentChargeFragment.firstChargeAmount;
    tmp = {
      customerId: recurrentChargeFragment.customer.id,
      description: Belt_Option.getWithDefault(recurrentChargeFragment.description, ""),
      paymentMethod: match$5 === "PIX" ? /* Pix */1 : (
          match$5 === "BOLETO" ? /* Boleto */2 : (
              match$5 === "CREDIT_CARD" ? /* CreditCard */0 : /* Pix */1
            )
        ),
      items: Belt_Array.reduce(recurrentChargeFragment.items, [], (function (acc, item) {
              var match = item.type_;
              if (match === "PRODUCT") {
                return Belt_Array.concat(acc, [{
                              TAG: /* Product */0,
                              _0: {
                                name: item.name,
                                price: item.price,
                                quantity: item.quantity,
                                freightAmount: item.freightAmount,
                                insuranceAmount: item.insuranceAmount,
                                othersAmount: item.othersAmount,
                                discountAmount: item.discountAmount,
                                itemId: item.id,
                                temporaryId: Uuid.v4(),
                                maxQuantity: 0,
                                hasMaxQuantity: false,
                                chargeType: /* Single */0
                              }
                            }]);
              } else if (match === "SERVICE") {
                return Belt_Array.concat(acc, [{
                              TAG: /* Service */1,
                              _0: {
                                name: item.name,
                                price: item.price,
                                quantity: item.quantity,
                                freightAmount: item.freightAmount,
                                insuranceAmount: item.insuranceAmount,
                                othersAmount: item.othersAmount,
                                discountAmount: item.discountAmount,
                                itemId: item.id,
                                temporaryId: Uuid.v4(),
                                maxQuantity: 0,
                                hasMaxQuantity: false,
                                chargeType: /* Single */0
                              }
                            }]);
              } else {
                return acc;
              }
            })),
      askForConfirmation: Belt_Option.getWithDefault(recurrentChargeFragment.askForConfirmation, false),
      chargingMonthDay: Belt_Option.getWithDefault(recurrentChargeFragment.chargeMonthDay, GetDate(new Date())),
      chargingWeekDay: Belt_Option.getWithDefault(recurrentChargeFragment.chargeWeekDay, GetDay(new Date())),
      recurrencePeriod: match$6 === "CUSTOM" ? /* Custom */2 : (
          match$6 === "WEEKLY" ? /* Weekly */1 : /* Monthly */0
        ),
      customRecurrencePeriod: Belt_Option.getWithDefault(recurrentChargeFragment.customRecurrencePeriod, 0),
      customRecurrencePeriodStartingDate: Belt_Option.getWithDefault(recurrentChargeFragment.customRecurrencePeriodStartingDate, ""),
      chargeImmediately: Belt_Option.getWithDefault(recurrentChargeFragment.chargeImmediately, false),
      firstChargeAmount: firstChargeAmount !== undefined ? Money$VannaFrontend.toDecimal(Money$VannaFrontend.make({
                  TAG: /* Cents */1,
                  _0: firstChargeAmount
                })) : 0.0
    };
  } else {
    tmp = {
      customerId: "",
      description: "",
      paymentMethod: /* Pix */1,
      items: [],
      askForConfirmation: false,
      chargingMonthDay: GetDate(new Date()),
      chargingWeekDay: GetDay(new Date()),
      recurrencePeriod: /* Monthly */0,
      customRecurrencePeriod: 0,
      customRecurrencePeriodStartingDate: "",
      chargeImmediately: false,
      firstChargeAmount: 0.0
    };
  }
  var reform = Curry._7(RecurrentChargeUpsertForm.use, tmp, /* Schema */{
        _0: Curry._2(RecurrentChargeUpsertForm.ReSchema.Validation.$plus, Curry._2(RecurrentChargeUpsertForm.ReSchema.Validation.$plus, Curry._2(RecurrentChargeUpsertForm.ReSchema.Validation.$plus, Curry._3(RecurrentChargeUpsertForm.ReSchema.Validation.nonEmpty, "Por favor, informe o cliente", undefined, /* CustomerId */0), Curry._3(RecurrentChargeUpsertForm.ReSchema.Validation.nonEmpty, "Por favor, informe uma descrição", undefined, /* Description */1)), Curry._3(RecurrentChargeUpsertForm.ReSchema.Validation.custom, (function (state) {
                        if (state.items.length === 0) {
                          return {
                                  TAG: /* Error */1,
                                  _0: "Voc\xc3\xaa deve adicionar ao menos um produto ou servi\xc3\xa7o"
                                };
                        } else {
                          return /* Valid */0;
                        }
                      }), undefined, /* Items */3)), Curry._3(RecurrentChargeUpsertForm.ReSchema.Validation.custom, (function (state) {
                    if (state.recurrencePeriod !== /* Custom */2) {
                      return /* Valid */0;
                    }
                    var customRecurrencePeriodStartingDate = new Date(state.customRecurrencePeriodStartingDate);
                    if (IsValid(customRecurrencePeriodStartingDate)) {
                      if (IsBefore(customRecurrencePeriodStartingDate, new Date())) {
                        return {
                                TAG: /* Error */1,
                                _0: "Por favor, informe uma data no futuro"
                              };
                      } else {
                        return /* Valid */0;
                      }
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: "Por favor, informe uma data válida."
                            };
                    }
                  }), undefined, /* CustomRecurrencePeriodStartingDate */9))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          var match = state.values.recurrencePeriod;
          var match$1 = state.values.recurrencePeriod;
          var match$2 = state.values.recurrencePeriod;
          var match$3 = state.values.recurrencePeriod;
          var match$4 = state.values.paymentMethod;
          var tmp;
          if (match$4 !== 1) {
            throw {
                  RE_EXN_ID: "Match_failure",
                  _1: [
                    "RecurrentChargeUpsertForm.res",
                    346,
                    27
                  ],
                  Error: new Error()
                };
          }
          tmp = "PIX";
          var match$5 = state.values.recurrencePeriod;
          var tmp$1;
          switch (match$5) {
            case /* Monthly */0 :
                tmp$1 = "MONTHLY";
                break;
            case /* Weekly */1 :
                tmp$1 = "WEEKLY";
                break;
            case /* Custom */2 :
                tmp$1 = "CUSTOM";
                break;
            
          }
          Curry.app(createRecurrentCharge, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, errors) {
                    if (errors !== undefined && errors.length !== 0) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match = response.recurrentChargeCreate;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    } else if (match.recurrentCharge !== undefined) {
                      Curry._1(dispatchModal, /* Close */1);
                      return Curry._1(send, {
                                  TAG: /* SetFormState */10,
                                  _0: /* Dirty */0
                                });
                    } else {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                  }),
                undefined,
                undefined,
                undefined,
                (function (store, response) {
                    var match = response.recurrentChargeCreate;
                    if (match === undefined) {
                      return ;
                    }
                    var charge = match.recurrentCharge;
                    if (charge === undefined) {
                      return ;
                    }
                    var me = store.getRoot().getLinkedRecord("me", undefined);
                    if (me == null) {
                      return ;
                    }
                    var products = ReasonRelay.RecordProxy.getLinkedRecords(me, "recurrentCharges", undefined, undefined);
                    if (products === undefined) {
                      return ;
                    }
                    var productProxy = store.get(charge.id);
                    me.setLinkedRecords(Belt_Array.concat(products, [(productProxy == null) ? undefined : Caml_option.some(productProxy)]), "recurrentCharges", undefined);
                    
                  }),
                {
                  input: {
                    affiliateId: undefined,
                    affiliatePercentage: undefined,
                    askForConfirmation: state.values.askForConfirmation,
                    chargeImmediately: state.values.chargeImmediately,
                    chargeMonthDay: match !== 0 ? undefined : state.values.chargingMonthDay,
                    chargeWeekDay: match$1 !== 1 ? undefined : state.values.chargingWeekDay,
                    customRecurrencePeriod: match$2 >= 2 ? state.values.customRecurrencePeriod : undefined,
                    customRecurrencePeriodStartingDate: match$3 >= 2 ? state.values.customRecurrencePeriodStartingDate : undefined,
                    customerId: state.values.customerId,
                    description: state.values.description,
                    firstChargeAmount: state.values.chargeImmediately ? Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                                TAG: /* Decimal */0,
                                _0: state.values.firstChargeAmount
                              })) : undefined,
                    items: Belt_Array.map(state.values.items, (function (item) {
                            var item$1 = item._0;
                            return {
                                    discountAmount: item$1.discountAmount,
                                    freightAmount: item$1.freightAmount,
                                    insuranceAmount: item$1.insuranceAmount,
                                    itemId: item$1.itemId,
                                    othersAmount: item$1.othersAmount,
                                    quantity: item$1.quantity
                                  };
                          })),
                    paymentMethod: tmp,
                    recurrencePeriod: tmp$1
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var chargeTotalAmount = ChargeHelpers$VannaFrontend.getTotalFromArray(Belt_Array.map(reform.state.values.items, (function (item) {
              if (item.TAG === /* Product */0) {
                var item$1 = item._0;
                return {
                        name: item$1.name,
                        price: item$1.price,
                        quantity: item$1.quantity,
                        freightAmount: item$1.freightAmount,
                        insuranceAmount: item$1.insuranceAmount,
                        othersAmount: item$1.othersAmount,
                        discountAmount: item$1.discountAmount,
                        itemId: "",
                        temporaryId: "",
                        maxQuantity: item$1.maxQuantity,
                        hasMaxQuantity: item$1.hasMaxQuantity,
                        chargeType: item$1.chargeType
                      };
              }
              var item$2 = item._0;
              return {
                      name: item$2.name,
                      price: item$2.price,
                      quantity: item$2.quantity,
                      freightAmount: item$2.freightAmount,
                      insuranceAmount: item$2.insuranceAmount,
                      othersAmount: item$2.othersAmount,
                      discountAmount: item$2.discountAmount,
                      itemId: "",
                      temporaryId: "",
                      maxQuantity: item$2.maxQuantity,
                      hasMaxQuantity: item$2.hasMaxQuantity,
                      chargeType: item$2.chargeType
                    };
            })));
  React.useEffect((function () {
          Curry._1(setLoadingTransactionCost, (function (param) {
                  return true;
                }));
          $$fetch$1(RelayEnv$VannaFrontend.environment, {
                input: {
                  amount: chargeTotalAmount
                }
              }, (function (result) {
                  if (result.TAG === /* Ok */0) {
                    var res = result._0.chargeGetTransactionCostQuery;
                    if (res !== undefined) {
                      Curry._1(setTransactionCosts, (function (param) {
                              return {
                                      pix: res.pix,
                                      ccDefault: res.ccDefault,
                                      ccVisaOrMastercard: res.ccVisaOrMastercard,
                                      boleto: res.boleto
                                    };
                            }));
                      return Curry._1(setLoadingTransactionCost, (function (param) {
                                    return false;
                                  }));
                    }
                    
                  }
                  return Curry._1(setLoadingTransactionCost, (function (param) {
                                return false;
                              }));
                }), undefined, undefined, undefined);
          
        }), [chargeTotalAmount]);
  React.useEffect((function () {
          if (reform.state.values.chargeImmediately) {
            var proportionalChargeAmount = ChargeFns$VannaFrontend.getProportionalChargeAmount(reform.state.values.recurrencePeriod, reform.state.values.chargingMonthDay, reform.state.values.chargingWeekDay, reform.state.values.customRecurrencePeriod, reform.state.values.customRecurrencePeriodStartingDate, chargeTotalAmount);
            Curry._4(reform.setFieldValue, /* FirstChargeAmount */11, Money$VannaFrontend.toDecimal(Money$VannaFrontend.make({
                          TAG: /* Cents */1,
                          _0: proportionalChargeAmount
                        })), undefined, undefined);
          }
          
        }), [
        chargeTotalAmount,
        reform.state.values.chargeImmediately,
        reform.state.values.chargingMonthDay,
        reform.state.values.chargingWeekDay,
        reform.state.values.recurrencePeriod,
        reform.state.values.customRecurrencePeriod,
        reform.state.values.customRecurrencePeriodStartingDate
      ]);
  var match$7 = queryData.me;
  if (match$7 === undefined) {
    return null;
  }
  var fragmentRefs = match$7.fragmentRefs;
  var match$8 = Belt_Array.getBy(customers, (function (customer) {
          return customer.id === reform.state.values.customerId;
        }));
  var match$9 = reform.state.values.paymentMethod;
  var match$10 = reform.state.values.recurrencePeriod;
  var tmp$1;
  switch (match$10) {
    case /* Monthly */0 :
        tmp$1 = React.createElement(RecurrentChargeUpsertForm.Field.make, {
              field: /* ChargingMonthDay */5,
              render: (function (param) {
                  var handleChange = param.handleChange;
                  return React.createElement(IntegerSelectField.make, {
                              label: "Dia do mês em que o cliente será cobrado",
                              error: param.error,
                              required: true,
                              value: param.value,
                              onChange: (function (param) {
                                  Curry._1(handleChange, param.value);
                                  return $$Promise.resolved(undefined);
                                }),
                              disabled: Belt_Option.isSome(recurrentChargeFragment),
                              options: Belt_Array.makeBy(28, (function (num) {
                                      return {
                                              label: String(num + 1 | 0),
                                              value: num + 1 | 0
                                            };
                                    }))
                            });
                })
            });
        break;
    case /* Weekly */1 :
        tmp$1 = React.createElement(RecurrentChargeUpsertForm.Field.make, {
              field: /* ChargingWeekDay */6,
              render: (function (param) {
                  var handleChange = param.handleChange;
                  return React.createElement(IntegerSelectField.make, {
                              label: "Dia da semana em que o cliente será cobrado",
                              error: param.error,
                              required: true,
                              value: param.value,
                              onChange: (function (param) {
                                  Curry._1(handleChange, param.value);
                                  return $$Promise.resolved(undefined);
                                }),
                              disabled: Belt_Option.isSome(recurrentChargeFragment),
                              options: [
                                {
                                  label: "Domingo",
                                  value: 0
                                },
                                {
                                  label: "Segunda-feira",
                                  value: 1
                                },
                                {
                                  label: "Terça-feira",
                                  value: 2
                                },
                                {
                                  label: "Quarta-feira",
                                  value: 3
                                },
                                {
                                  label: "Quinta-feira",
                                  value: 4
                                },
                                {
                                  label: "Sexta-feira",
                                  value: 5
                                },
                                {
                                  label: "Sábado",
                                  value: 6
                                }
                              ]
                            });
                })
            });
        break;
    case /* Custom */2 :
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(RecurrentChargeUpsertForm.Field.make, {
                  field: /* CustomRecurrencePeriod */8,
                  render: (function (param) {
                      return React.createElement(NumberField$VannaFrontend.make, {
                                  label: "Intervalo de dias entre cada cobrança",
                                  error: param.error,
                                  required: true,
                                  value: param.value,
                                  onChange: param.handleChange
                                });
                    })
                }), React.createElement(RecurrentChargeUpsertForm.Field.make, {
                  field: /* CustomRecurrencePeriodStartingDate */9,
                  render: (function (param) {
                      var value = param.value;
                      var date = IsValid(new Date(value)) ? new Date(value) : new Date();
                      return React.createElement(DateField$VannaFrontend.make, {
                                  label: "Data de início da assinatura",
                                  value: Format(date, "yyyy-MM-dd"),
                                  onChange: param.handleChange,
                                  error: param.error,
                                  required: true
                                });
                    })
                }));
        break;
    
  }
  var tmp$2;
  if (match$1[0]) {
    tmp$2 = "Calculando custo da transação...";
  } else {
    var match$11 = reform.state.values.paymentMethod;
    switch (match$11) {
      case /* CreditCard */0 :
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement("h4", undefined, "Taxa com a bandeira Visa ou MasterCard"), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Taxa de transação: "), Money$VannaFrontend.formatInt(transactionCosts.ccVisaOrMastercard)), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Valor líquido: "), Money$VannaFrontend.format(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: chargeTotalAmount
                              }), Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: transactionCosts.ccVisaOrMastercard
                              })))), React.createElement("h4", undefined, "Taxa com a bandeira Elo ou outras"), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Taxa de transação: "), Money$VannaFrontend.formatInt(transactionCosts.ccDefault)), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Valor líquido: "), Money$VannaFrontend.format(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: chargeTotalAmount
                              }), Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: transactionCosts.ccDefault
                              })))));
          break;
      case /* Pix */1 :
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Taxa de transação: "), Money$VannaFrontend.format(Money$VannaFrontend.make({
                            TAG: /* Cents */1,
                            _0: transactionCosts.pix
                          }))), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Valor líquido: "), Money$VannaFrontend.formatInt(chargeTotalAmount)));
          break;
      case /* Boleto */2 :
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Taxa de transação: "), Money$VannaFrontend.format(Money$VannaFrontend.make({
                            TAG: /* Cents */1,
                            _0: transactionCosts.boleto
                          }))), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Valor líquido: "), Money$VannaFrontend.format(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: chargeTotalAmount
                              }), Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: transactionCosts.boleto
                              })))));
          break;
      
    }
  }
  var match$12 = reform.formState;
  var tmp$3;
  if (typeof match$12 === "number") {
    tmp$3 = null;
  } else {
    var error = match$12._0;
    tmp$3 = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(RecurrentChargeUpsertForm.Provider.make, Curry._3(RecurrentChargeUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(RecurrentChargeUpsertForm.Field.make, {
                          field: /* CustomerId */0,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(CustomersSelect.make, {
                                          label: "Cliente",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          disabled: Belt_Option.isSome(recurrentChargeFragment),
                                          options: Belt_Array.map(customers, (function (customer) {
                                                  return {
                                                          label: Belt_Option.getWithDefault(customer.name, ""),
                                                          value: customer.id
                                                        };
                                                })),
                                          loadMore: (function (_search) {
                                              return new Promise((function (resolve, param) {
                                                            Curry._3(loadNext, 20, (function (error) {
                                                                    if (error !== undefined) {
                                                                      return resolve({
                                                                                  options: [],
                                                                                  hasMore: false
                                                                                });
                                                                    } else {
                                                                      return resolve({
                                                                                  options: Belt_Array.map(customers, (function (cur) {
                                                                                          var name = cur.name;
                                                                                          var tmp;
                                                                                          if (name !== undefined) {
                                                                                            tmp = name;
                                                                                          } else {
                                                                                            var tradeName = cur.tradeName;
                                                                                            tmp = tradeName !== undefined ? tradeName : "-";
                                                                                          }
                                                                                          return {
                                                                                                  label: tmp,
                                                                                                  value: cur.id
                                                                                                };
                                                                                        })),
                                                                                  hasMore: hasNext
                                                                                });
                                                                    }
                                                                  }), undefined);
                                                            
                                                          }));
                                            })
                                        });
                            })
                        }), React.createElement(RecurrentChargeUpsertForm.Field.make, {
                          field: /* Description */1,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Descrição",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange,
                                          disabled: Belt_Option.isSome(recurrentChargeFragment)
                                        });
                            })
                        }), React.createElement(RecurrentChargeUpsertForm.Field.make, {
                          field: /* PaymentMethod */2,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(PaymentMethodSelect.make, {
                                          label: "Método de pagamento",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          disabled: Belt_Option.isSome(recurrentChargeFragment),
                                          options: [{
                                              label: "PIX",
                                              value: /* Pix */1
                                            }]
                                        });
                            })
                        }), match$8 !== undefined && !(match$9 !== 0 || match$8.cards.length === 0) ? React.createElement(RecurrentChargeUpsertForm.Field.make, {
                            field: /* AskForConfirmation */4,
                            render: (function (param) {
                                var handleChange = param.handleChange;
                                return React.createElement(BooleanSelectField.make, {
                                            label: "Este cliente já possui um cartão cadastrado. Você deseja enviar uma solicitação de confirmação de pagamento?",
                                            error: param.error,
                                            required: true,
                                            value: param.value,
                                            onChange: (function (param) {
                                                Curry._1(handleChange, param.value);
                                                return $$Promise.resolved(undefined);
                                              }),
                                            disabled: Belt_Option.isSome(recurrentChargeFragment),
                                            options: [
                                              {
                                                label: "Sim",
                                                value: true
                                              },
                                              {
                                                label: "Não",
                                                value: false
                                              }
                                            ]
                                          });
                              })
                          }) : null, React.createElement(RecurrentChargeUpsertForm.Field.make, {
                          field: /* RecurrencePeriod */7,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(RecurrencePeriodSelectField.make, {
                                          label: "Período de recorrência da assinatura",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          disabled: Belt_Option.isSome(recurrentChargeFragment),
                                          options: [
                                            {
                                              label: "Mensal",
                                              value: /* Monthly */0
                                            },
                                            {
                                              label: "Semanal",
                                              value: /* Weekly */1
                                            },
                                            {
                                              label: "Customizado",
                                              value: /* Custom */2
                                            }
                                          ]
                                        });
                            })
                        }), tmp$1, React.createElement(RecurrentChargeUpsertForm.Field.make, {
                          field: /* Items */3,
                          render: (function (param) {
                              var tmp;
                              tmp = selectedItem !== undefined && selectedItem.TAG === /* Product */0 ? true : false;
                              var tmp$1;
                              tmp$1 = selectedItem !== undefined && selectedItem.TAG !== /* Product */0 ? true : false;
                              return React.createElement(React.Fragment, undefined, React.createElement(ItemsTable$VannaFrontend.make, {
                                              title: "Produtos",
                                              data: Belt_Array.reduce(reform.state.values.items, [], (function (acc, item) {
                                                      if (item.TAG === /* Product */0) {
                                                        return Belt_Array.concat(acc, [item._0]);
                                                      } else {
                                                        return acc;
                                                      }
                                                    })),
                                              onRemoveItem: (function (itemTemporaryId) {
                                                  var rowIndex = Belt_Array.getIndexBy(reform.state.values.items, (function (item) {
                                                          if (item.TAG === /* Product */0) {
                                                            return item._0.temporaryId === itemTemporaryId;
                                                          } else {
                                                            return false;
                                                          }
                                                        }));
                                                  if (rowIndex !== undefined) {
                                                    return Curry._2(reform.arrayRemoveByIndex, /* Items */3, rowIndex);
                                                  }
                                                  
                                                }),
                                              onEditItem: (function (item) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                return {
                                                                        TAG: /* Product */0,
                                                                        _0: item
                                                                      };
                                                              }));
                                                }),
                                              readOnly: Belt_Option.isSome(recurrentChargeFragment),
                                              domain: /* RecurrentCharge */1
                                            }), React.createElement(Modal$VannaFrontend.make, {
                                              title: "Atualizar produto",
                                              trigger: (function (param) {
                                                  return null;
                                                }),
                                              controlledOpen: tmp,
                                              content: (function (dispatch) {
                                                  return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                              children: React.createElement(ItemTableUpsertProductForm$VannaFrontend.make, {
                                                                    domain: /* RecurrentCharge */1,
                                                                    item: selectedItem !== undefined ? selectedItem : undefined,
                                                                    dispatchModal: dispatch,
                                                                    onUpsert: (function (item) {
                                                                        Curry._1(setSelectedItem, (function (param) {
                                                                                
                                                                              }));
                                                                        if (item.TAG === /* Insert */0) {
                                                                          return ;
                                                                        }
                                                                        var item$1 = item._0;
                                                                        var index = Belt_Array.getIndexBy(reform.state.values.items, (function (cur) {
                                                                                if (cur.TAG === /* Product */0) {
                                                                                  return item$1.temporaryId === cur._0.temporaryId;
                                                                                } else {
                                                                                  return false;
                                                                                }
                                                                              }));
                                                                        if (index !== undefined) {
                                                                          return Curry._3(reform.arrayUpdateByIndex, /* Items */3, index, {
                                                                                      TAG: /* Product */0,
                                                                                      _0: item$1
                                                                                    });
                                                                        }
                                                                        
                                                                      }),
                                                                    query: fragmentRefs
                                                                  })
                                                            });
                                                }),
                                              onClose: (function (param) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                
                                                              }));
                                                })
                                            }), Belt_Option.isNone(recurrentChargeFragment) ? React.createElement(Modal$VannaFrontend.make, {
                                                title: "Adicionar produto",
                                                trigger: (function (dispatch) {
                                                    return React.createElement(Button$VannaFrontend.make, {
                                                                mode: /* Secondary */1,
                                                                onClick: (function (param) {
                                                                    return Curry._1(dispatch, /* Open */2);
                                                                  }),
                                                                children: null
                                                              }, React.createElement(Fi.FiPlus, {}), "Adicionar produto");
                                                  }),
                                                content: (function (dispatch) {
                                                    return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                                children: React.createElement(ItemTableUpsertProductForm$VannaFrontend.make, {
                                                                      domain: /* RecurrentCharge */1,
                                                                      item: undefined,
                                                                      dispatchModal: dispatch,
                                                                      onUpsert: (function (item) {
                                                                          if (item.TAG === /* Insert */0) {
                                                                            return Curry._2(reform.arrayPush, /* Items */3, {
                                                                                        TAG: /* Product */0,
                                                                                        _0: item._0
                                                                                      });
                                                                          }
                                                                          
                                                                        }),
                                                                      query: fragmentRefs
                                                                    })
                                                              });
                                                  })
                                              }) : null, React.createElement(Modal$VannaFrontend.make, {
                                              title: "Atualizar serviço",
                                              trigger: (function (param) {
                                                  return null;
                                                }),
                                              controlledOpen: tmp$1,
                                              content: (function (dispatch) {
                                                  return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                              children: React.createElement(ItemTableUpsertServiceForm$VannaFrontend.make, {
                                                                    domain: /* RecurrentCharge */1,
                                                                    item: selectedItem !== undefined ? selectedItem : undefined,
                                                                    dispatchModal: dispatch,
                                                                    onUpsert: (function (item) {
                                                                        Curry._1(setSelectedItem, (function (param) {
                                                                                
                                                                              }));
                                                                        if (item.TAG === /* Insert */0) {
                                                                          return ;
                                                                        }
                                                                        var item$1 = item._0;
                                                                        var index = Belt_Array.getIndexBy(reform.state.values.items, (function (cur) {
                                                                                if (cur.TAG === /* Product */0) {
                                                                                  return false;
                                                                                } else {
                                                                                  return item$1.temporaryId === cur._0.temporaryId;
                                                                                }
                                                                              }));
                                                                        if (index !== undefined) {
                                                                          return Curry._3(reform.arrayUpdateByIndex, /* Items */3, index, {
                                                                                      TAG: /* Service */1,
                                                                                      _0: item$1
                                                                                    });
                                                                        }
                                                                        
                                                                      }),
                                                                    query: fragmentRefs
                                                                  })
                                                            });
                                                }),
                                              onClose: (function (param) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                
                                                              }));
                                                })
                                            }), React.createElement(ItemsTable$VannaFrontend.make, {
                                              title: "Serviços",
                                              data: Belt_Array.reduce(reform.state.values.items, [], (function (acc, item) {
                                                      if (item.TAG === /* Product */0) {
                                                        return acc;
                                                      } else {
                                                        return Belt_Array.concat(acc, [item._0]);
                                                      }
                                                    })),
                                              onRemoveItem: (function (itemTemporaryId) {
                                                  var rowIndex = Belt_Array.getIndexBy(reform.state.values.items, (function (item) {
                                                          if (item.TAG === /* Product */0) {
                                                            return false;
                                                          } else {
                                                            return item._0.temporaryId === itemTemporaryId;
                                                          }
                                                        }));
                                                  if (rowIndex !== undefined) {
                                                    return Curry._2(reform.arrayRemoveByIndex, /* Items */3, rowIndex);
                                                  }
                                                  
                                                }),
                                              onEditItem: (function (item) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                return {
                                                                        TAG: /* Service */1,
                                                                        _0: item
                                                                      };
                                                              }));
                                                }),
                                              readOnly: Belt_Option.isSome(recurrentChargeFragment),
                                              domain: /* RecurrentCharge */1
                                            }), Belt_Option.isNone(recurrentChargeFragment) ? React.createElement(Modal$VannaFrontend.make, {
                                                title: "Adicionar serviço",
                                                trigger: (function (dispatch) {
                                                    return React.createElement(Button$VannaFrontend.make, {
                                                                mode: /* Secondary */1,
                                                                onClick: (function (param) {
                                                                    return Curry._1(dispatch, /* Open */2);
                                                                  }),
                                                                children: null
                                                              }, React.createElement(Fi.FiPlus, {}), "Adicionar serviço");
                                                  }),
                                                content: (function (dispatch) {
                                                    return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                                children: React.createElement(ItemTableUpsertServiceForm$VannaFrontend.make, {
                                                                      domain: /* RecurrentCharge */1,
                                                                      item: undefined,
                                                                      dispatchModal: dispatch,
                                                                      onUpsert: (function (item) {
                                                                          if (item.TAG === /* Insert */0) {
                                                                            return Curry._2(reform.arrayPush, /* Items */3, {
                                                                                        TAG: /* Service */1,
                                                                                        _0: item._0
                                                                                      });
                                                                          }
                                                                          
                                                                        }),
                                                                      query: fragmentRefs
                                                                    })
                                                              });
                                                  })
                                              }) : null);
                            })
                        }), React.createElement(RecurrentChargeUpsertForm.Field.make, {
                          field: /* ChargeImmediately */10,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(BooleanSelectField.make, {
                                          label: "Criar a primeira venda assim que o cliente assinar",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          disabled: Belt_Option.isSome(recurrentChargeFragment),
                                          options: [
                                            {
                                              label: "Sim",
                                              value: true
                                            },
                                            {
                                              label: "Não",
                                              value: false
                                            }
                                          ]
                                        });
                            })
                        }), reform.state.values.chargeImmediately ? React.createElement(RecurrentChargeUpsertForm.Field.make, {
                            field: /* FirstChargeAmount */11,
                            render: (function (param) {
                                return React.createElement(MoneyField$VannaFrontend.make, {
                                            label: "Valor da primeira cobrança",
                                            error: param.error,
                                            required: false,
                                            tip: "Valor proporcional ao número de dias restantes para chegar ao dia de pagamento da segunda venda. Você pode modificá-lo como preferir.",
                                            value: param.value,
                                            onChange: param.handleChange
                                          });
                              })
                          }) : null, React.createElement(FieldInfo$VannaFrontend.List.make, {
                          children: React.createElement(React.Fragment, undefined, React.createElement("h4", undefined, "Simulação da cobrança de acordo com a bandeira do cartão do cliente"), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                                    children: null
                                  }, React.createElement("span", undefined, "Valor bruto: "), Money$VannaFrontend.formatInt(chargeTotalAmount)), tmp$2)
                        }), tmp$3, Belt_Option.isNone(recurrentChargeFragment) ? React.createElement(Button$VannaFrontend.make, {
                            isLoading: reform.isSubmitting,
                            type_: /* Submit */1,
                            children: Belt_Option.isSome(recurrentChargeFragment) ? "Atualizar" : "Adicionar"
                          }) : null, React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, Belt_Option.isNone(recurrentChargeFragment) ? /* CloseWithConfirmation */0 : /* Close */1);
                            }),
                          children: "Fechar"
                        })), undefined));
}

var pageSize = 20;

var make = RecurrentChargeUpsertForm$1;

exports.StateLenses = StateLenses;
exports.RecurrentChargeUpsertForm = RecurrentChargeUpsertForm;
exports.CustomersSelect = CustomersSelect;
exports.PaymentMethodSelect = PaymentMethodSelect;
exports.BooleanSelectField = BooleanSelectField;
exports.RecurrencePeriodSelectField = RecurrencePeriodSelectField;
exports.IntegerSelectField = IntegerSelectField;
exports.pageSize = pageSize;
exports.CustomersConnectionFragment = CustomersConnectionFragment;
exports.RecurrentChargeUpsertFormQuery = RecurrentChargeUpsertFormQuery;
exports.GetTransactionCostQuery = GetTransactionCostQuery;
exports.RecurrentChargeFragment = RecurrentChargeFragment;
exports.RecurrentChargeCreateMutation = RecurrentChargeCreateMutation;
exports.make = make;
/* RecurrentChargeUpsertForm Not a pure module */
