'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Uuid = require("uuid");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var $$String = require("bs-platform/lib/js/string.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("../components/Box.bs.js");
var Hooks = require("react-relay/hooks");
var Text$VannaFrontend = require("../components/Text.bs.js");
var Badge$VannaFrontend = require("../components/Badge.bs.js");
var Commonjs = require("@fnando/cpf/commonjs");
var Button$VannaFrontend = require("../components/Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var Commonjs$1 = require("@fnando/cnpj/commonjs");
var TextField$VannaFrontend = require("../components/TextField.bs.js");
var BrandPanel$VannaFrontend = require("../components/BrandPanel.bs.js");
var ItemsTable$VannaFrontend = require("../components/ItemsTable.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var FieldSection$VannaFrontend = require("../components/FieldSection.bs.js");
var CreditCardReadOnly$VannaFrontend = require("../components/CreditCardReadOnly.bs.js");
var ReactPaymentInputs$VannaFrontend = require("../types/ReactPaymentInputs.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var RecurrentChargePaymentQuery_graphql$VannaFrontend = require("../__generated__/RecurrentChargePaymentQuery_graphql.bs.js");
var RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend = require("../__generated__/RecurrentChargePayment_AddSourceMutation_graphql.bs.js");
var RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend = require("../__generated__/RecurrentChargePayment_UpdateSourceMutation_graphql.bs.js");

function get(state, field) {
  switch (field) {
    case /* HasTaxId */0 :
        return state.hasTaxId;
    case /* TypeOfPeople */1 :
        return state.typeOfPeople;
    case /* TaxId */2 :
        return state.taxId;
    case /* CreditCardHolderName */3 :
        return state.creditCardHolderName;
    case /* CreditCardNumber */4 :
        return state.creditCardNumber;
    case /* CreditCardExpiryDate */5 :
        return state.creditCardExpiryDate;
    case /* CreditCardCvc */6 :
        return state.creditCardCvc;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* HasTaxId */0 :
        return {
                hasTaxId: value,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* TypeOfPeople */1 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: value,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* TaxId */2 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: value,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* CreditCardHolderName */3 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: value,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* CreditCardNumber */4 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: value,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* CreditCardExpiryDate */5 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: value,
                creditCardCvc: state.creditCardCvc
              };
    case /* CreditCardCvc */6 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var CreditCardForm = ReForm.Make({
      set: set,
      get: get
    });

var textSection = Curry._1(Css.style, {
      hd: Css.marginBottom({
            NAME: "px",
            VAL: 20
          }),
      tl: /* [] */0
    });

var text = Curry._1(Css.style, {
      hd: Css.lineHeight({
            NAME: "px",
            VAL: 6
          }),
      tl: /* [] */0
    });

var Styles = {
  textSection: textSection,
  text: text
};

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(RecurrentChargePaymentQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(RecurrentChargePaymentQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargePaymentQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(RecurrentChargePaymentQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, RecurrentChargePaymentQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, RecurrentChargePaymentQuery_graphql$VannaFrontend.node, RecurrentChargePaymentQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: RecurrentChargePaymentQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, RecurrentChargePaymentQuery_graphql$VannaFrontend.node, RecurrentChargePaymentQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return RecurrentChargePaymentQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(RecurrentChargePaymentQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargePaymentQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var RecurrentChargePaymentQuery_makeVariables = RecurrentChargePaymentQuery_graphql$VannaFrontend.Utils.makeVariables;

var RecurrentChargePaymentQuery = {
  makeVariables: RecurrentChargePaymentQuery_makeVariables,
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.node,
              variables: RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var AddSourceMutation_make_creditCard = RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_creditCard;

var AddSourceMutation_make_recurrentChargeAddSourceInput = RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_recurrentChargeAddSourceInput;

var AddSourceMutation_makeVariables = RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.makeVariables;

var AddSourceMutation_make_response_recurrentChargeAddSource_recurrentCharge_card = RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeAddSource_recurrentCharge_card;

var AddSourceMutation_make_response_recurrentChargeAddSource_recurrentCharge = RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeAddSource_recurrentCharge;

var AddSourceMutation_make_response_recurrentChargeAddSource_error = RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeAddSource_error;

var AddSourceMutation_make_response_recurrentChargeAddSource = RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeAddSource;

var AddSourceMutation_makeOptimisticResponse = RecurrentChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var AddSourceMutation = {
  make_creditCard: AddSourceMutation_make_creditCard,
  make_recurrentChargeAddSourceInput: AddSourceMutation_make_recurrentChargeAddSourceInput,
  makeVariables: AddSourceMutation_makeVariables,
  make_response_recurrentChargeAddSource_recurrentCharge_card: AddSourceMutation_make_response_recurrentChargeAddSource_recurrentCharge_card,
  make_response_recurrentChargeAddSource_recurrentCharge: AddSourceMutation_make_response_recurrentChargeAddSource_recurrentCharge,
  make_response_recurrentChargeAddSource_error: AddSourceMutation_make_response_recurrentChargeAddSource_error,
  make_response_recurrentChargeAddSource: AddSourceMutation_make_response_recurrentChargeAddSource,
  makeOptimisticResponse: AddSourceMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.node,
              variables: RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var UpdateSourceMutation_make_creditCard = RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Utils.make_creditCard;

var UpdateSourceMutation_make_recurrentChargeUpdateSourceInput = RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Utils.make_recurrentChargeUpdateSourceInput;

var UpdateSourceMutation_makeVariables = RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Utils.makeVariables;

var UpdateSourceMutation_make_response_recurrentChargeUpdateSource_recurrentCharge_card = RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeUpdateSource_recurrentCharge_card;

var UpdateSourceMutation_make_response_recurrentChargeUpdateSource_recurrentCharge = RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeUpdateSource_recurrentCharge;

var UpdateSourceMutation_make_response_recurrentChargeUpdateSource_error = RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeUpdateSource_error;

var UpdateSourceMutation_make_response_recurrentChargeUpdateSource = RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeUpdateSource;

var UpdateSourceMutation_makeOptimisticResponse = RecurrentChargePayment_UpdateSourceMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var UpdateSourceMutation = {
  make_creditCard: UpdateSourceMutation_make_creditCard,
  make_recurrentChargeUpdateSourceInput: UpdateSourceMutation_make_recurrentChargeUpdateSourceInput,
  makeVariables: UpdateSourceMutation_makeVariables,
  make_response_recurrentChargeUpdateSource_recurrentCharge_card: UpdateSourceMutation_make_response_recurrentChargeUpdateSource_recurrentCharge_card,
  make_response_recurrentChargeUpdateSource_recurrentCharge: UpdateSourceMutation_make_response_recurrentChargeUpdateSource_recurrentCharge,
  make_response_recurrentChargeUpdateSource_error: UpdateSourceMutation_make_response_recurrentChargeUpdateSource_error,
  make_response_recurrentChargeUpdateSource: UpdateSourceMutation_make_response_recurrentChargeUpdateSource,
  makeOptimisticResponse: UpdateSourceMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

function RecurrentChargePayment(Props) {
  var recurrentChargeId = Props.recurrentChargeId;
  var match = use$1(undefined);
  var addSource = match[0];
  var match$1 = use$2(undefined);
  var updateSource = match$1[0];
  var queryData = use({
        id: recurrentChargeId
      }, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var match$2 = React.useState(function () {
        
      });
  var setCardError = match$2[1];
  var cardError = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setHasSuccess = match$3[1];
  var reform = Curry._7(CreditCardForm.use, {
        hasTaxId: false,
        typeOfPeople: /* Natural */0,
        taxId: "",
        creditCardHolderName: "",
        creditCardNumber: "",
        creditCardExpiryDate: "",
        creditCardCvc: ""
      }, /* Schema */{
        _0: Curry._2(CreditCardForm.ReSchema.Validation.$plus, Curry._3(CreditCardForm.ReSchema.Validation.nonEmpty, "Por favor, informe o nome no cartão", undefined, /* CreditCardHolderName */3), Curry._3(CreditCardForm.ReSchema.Validation.custom, (function (param) {
                    var taxId = param.taxId;
                    if (param.typeOfPeople) {
                      if (Commonjs$1.isValid(taxId)) {
                        return /* Valid */0;
                      } else {
                        return {
                                TAG: /* Error */1,
                                _0: "O CPF informado é inválido"
                              };
                      }
                    } else if (Commonjs.isValid(taxId)) {
                      return /* Valid */0;
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: "O CPF informado é inválido"
                            };
                    }
                  }), undefined, /* TaxId */2))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          var match = $$Array.map($$String.trim, state.values.creditCardExpiryDate.split("/"));
          var exit = 0;
          if (match.length !== 2) {
            exit = 1;
          } else {
            var expiryMonth = match[0];
            var expiryYear = match[1];
            if (cardError !== undefined) {
              exit = 1;
            } else {
              var match$1 = queryData.node;
              var exit$1 = 0;
              if (match$1 !== undefined && match$1.card !== undefined) {
                Curry.app(updateSource, [
                      (function (param) {
                          return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                        }),
                      (function (response, errors) {
                          if (errors !== undefined && errors.length !== 0) {
                            return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                          }
                          var match = response.recurrentChargeUpdateSource;
                          if (match === undefined) {
                            return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                          }
                          var match$1 = match.error;
                          if (match$1 !== undefined) {
                            return Curry._1(raiseSubmitFailed, match$1.message);
                          } else {
                            Curry._1(setHasSuccess, (function (param) {
                                    return true;
                                  }));
                            return Curry._1(send, {
                                        TAG: /* SetFormState */10,
                                        _0: /* Dirty */0
                                      });
                          }
                        }),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      {
                        input: {
                          creditCard: {
                            cardNumber: state.values.creditCardNumber,
                            cvc: state.values.creditCardCvc,
                            expirationMonth: expiryMonth,
                            expirationYear: expiryYear,
                            holderName: state.values.creditCardHolderName
                          },
                          recurrentChargeId: recurrentChargeId,
                          taxId: state.values.taxId
                        }
                      },
                      undefined,
                      undefined
                    ]);
              } else {
                exit$1 = 2;
              }
              if (exit$1 === 2) {
                Curry.app(addSource, [
                      (function (param) {
                          return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                        }),
                      (function (response, errors) {
                          if (errors !== undefined && errors.length !== 0) {
                            return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                          }
                          var match = response.recurrentChargeAddSource;
                          if (match === undefined) {
                            return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                          }
                          var match$1 = match.error;
                          if (match$1 !== undefined) {
                            return Curry._1(raiseSubmitFailed, match$1.message);
                          } else {
                            Curry._1(setHasSuccess, (function (param) {
                                    return true;
                                  }));
                            return Curry._1(send, {
                                        TAG: /* SetFormState */10,
                                        _0: /* Dirty */0
                                      });
                          }
                        }),
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      {
                        input: {
                          creditCard: {
                            cardNumber: state.values.creditCardNumber,
                            cvc: state.values.creditCardCvc,
                            expirationMonth: expiryMonth,
                            expirationYear: expiryYear,
                            holderName: state.values.creditCardHolderName
                          },
                          recurrentChargeId: recurrentChargeId,
                          taxId: state.values.taxId
                        }
                      },
                      undefined,
                      undefined
                    ]);
              }
              
            }
          }
          if (exit === 1) {
            if (cardError !== undefined) {
              Curry._1(raiseSubmitFailed, cardError);
            } else {
              Curry._1(send, {
                    TAG: /* SetFormState */10,
                    _0: /* Dirty */0
                  });
            }
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var recurrentCharge = queryData.node;
  if (recurrentCharge === undefined) {
    return React.createElement(BrandPanel$VannaFrontend.make, {
                children: React.createElement(Text$VannaFrontend.make, {
                      children: "Cobrança não encontrada"
                    })
              });
  }
  var description = recurrentCharge.description;
  var match$4 = recurrentCharge.paymentMethod;
  var match$5 = recurrentCharge.status;
  var match$6 = recurrentCharge.status;
  var match$7 = recurrentCharge.status;
  var match$8 = recurrentCharge.paymentMethod;
  var tmp;
  if (match$7 !== undefined) {
    if (match$7 === "PAYMENT_FAILURE" || match$7 === "AWAITING_SUBSCRIPTION") {
      if (match$8 === "CREDIT_CARD") {
        var match$9 = recurrentCharge.status;
        var match$10 = reform.formState;
        var tmp$1;
        if (typeof match$10 === "number") {
          tmp$1 = null;
        } else {
          var error = match$10._0;
          tmp$1 = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
                  type_: /* Error */2,
                  title: "Ops!",
                  children: error
                }) : null;
        }
        tmp = React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(reform.submit, undefined);
                })
            }, React.createElement(FieldSection$VannaFrontend.make, {
                  children: React.createElement("strong", undefined, match$9 === "PAYMENT_FAILURE" ? "Atualizar informações de pagamento" : "Pagamento")
                }), reform.state.values.hasTaxId ? null : React.createElement(CreditCardForm.Field.make, {
                    field: /* TaxId */2,
                    render: (function (param) {
                        var match = reform.state.values.typeOfPeople;
                        var match$1 = reform.state.values.typeOfPeople;
                        return React.createElement(TextField$VannaFrontend.make, {
                                    label: match ? "CNPJ" : "CPF",
                                    error: param.error,
                                    required: true,
                                    mask: match$1 ? "99.999.999/9999-99" : "999.999.999-99",
                                    value: param.value,
                                    onChange: param.handleChange
                                  });
                      })
                  }), React.createElement(CreditCardForm.Field.make, {
                  field: /* CreditCardHolderName */3,
                  render: (function (param) {
                      return React.createElement(TextField$VannaFrontend.make, {
                                  label: "Cartão de crédito",
                                  error: param.error,
                                  required: true,
                                  value: param.value,
                                  onChange: param.handleChange,
                                  placeholder: "Nome do titular do cartão"
                                });
                    })
                }), React.createElement(ReactPaymentInputs$VannaFrontend.make, {
                  handleChangeCardNumber: (function (value) {
                      return Curry._4(reform.setFieldValue, /* CreditCardNumber */4, value, undefined, undefined);
                    }),
                  handleChangeExpiryDate: (function (value) {
                      return Curry._4(reform.setFieldValue, /* CreditCardExpiryDate */5, value, undefined, undefined);
                    }),
                  handleChangeCvc: (function (value) {
                      return Curry._4(reform.setFieldValue, /* CreditCardCvc */6, value, undefined, undefined);
                    }),
                  setCardIsInvalid: (function (error) {
                      return Curry._1(setCardError, (function (param) {
                                    return error;
                                  }));
                    }),
                  values: {
                    cardNumber: reform.values.creditCardNumber,
                    cardExpirationDate: reform.values.creditCardExpiryDate,
                    cardCvc: reform.values.creditCardCvc
                  }
                }), tmp$1, React.createElement(Button$VannaFrontend.make, {
                  isLoading: reform.isSubmitting,
                  type_: /* Submit */1,
                  children: "Pagar"
                }));
      } else {
        tmp = null;
      }
    } else if (match$7 === "ONGOING" && match$8 === "CREDIT_CARD") {
      var match$11 = recurrentCharge.card;
      tmp = React.createElement(React.Fragment, undefined, React.createElement(Text$VannaFrontend.make, {
                children: React.createElement("strong", undefined, "Cartão de crédito")
              }), match$11 !== undefined ? React.createElement(CreditCardReadOnly$VannaFrontend.make, {
                  brand: match$11.brand,
                  last4: match$11.last4
                }) : null, React.createElement(Box$VannaFrontend.make, {
                type_: /* Success */0,
                title: "Sucesso",
                children: "Assinatura realizada com sucesso"
              }));
    } else {
      tmp = null;
    }
  } else {
    tmp = null;
  }
  return React.createElement(BrandPanel$VannaFrontend.make, {
              title: "Cobrança de " + Belt_Option.getWithDefault(recurrentCharge.createdByName, "Vanna"),
              children: null
            }, React.createElement("section", {
                  className: textSection
                }, React.createElement(Text$VannaFrontend.make, {
                      className: text,
                      children: null
                    }, React.createElement("strong", undefined, "Identificador: "), Belt_Option.getWithDefault(recurrentCharge.friendlyId, "-")), description !== undefined ? React.createElement(Text$VannaFrontend.make, {
                        className: text,
                        children: null
                      }, React.createElement("strong", undefined, "Descrição: "), description) : null, React.createElement(Text$VannaFrontend.make, {
                      className: text,
                      children: null
                    }, React.createElement("strong", undefined, "Método de pagamento: "), match$4 !== undefined ? (
                        match$4 === "PIX" ? "PIX" : (
                            match$4 === "CREDIT_CARD" ? "Cartão de crédito" : "-"
                          )
                      ) : "-"), React.createElement(Text$VannaFrontend.make, {
                      className: text,
                      children: React.createElement("strong", {
                            className: Curry._1(Css.style, {
                                  hd: Css.marginRight({
                                        NAME: "px",
                                        VAL: 10
                                      }),
                                  tl: /* [] */0
                                })
                          }, "Situação: ")
                    }), match$5 !== undefined ? (
                    match$5 === "STOPPED" ? React.createElement(Badge$VannaFrontend.make, {
                            mode: /* Info */3,
                            text: "Pausada"
                          }) : (
                        match$5 === "AWAITING_SUBSCRIPTION" ? React.createElement(Badge$VannaFrontend.make, {
                                mode: /* Default */0,
                                text: "Aguardando assinatura"
                              }) : (
                            match$5 === "PAYMENT_FAILURE" ? React.createElement(Badge$VannaFrontend.make, {
                                    mode: /* Error */2,
                                    text: "Falha no pagamento"
                                  }) : (
                                match$5 === "REJECTED_BY_CUSTOMER" ? React.createElement(Badge$VannaFrontend.make, {
                                        mode: /* Warn */4,
                                        text: "Rejeitada pelo cliente"
                                      }) : (
                                    match$5 === "ONGOING" ? React.createElement(Badge$VannaFrontend.make, {
                                            mode: /* Success */1,
                                            text: "Ativa"
                                          }) : "-"
                                  )
                              )
                          )
                      )
                  ) : "-", match$6 === "PAYMENT_FAILURE" ? React.createElement(Text$VannaFrontend.make, {
                        className: text,
                        children: null
                      }, React.createElement("strong", undefined, "Motivo da falha: "), Belt_Option.getWithDefault(recurrentCharge.failureReason, "Houve um problema com sua cobrança")) : null), React.createElement(ItemsTable$VannaFrontend.make, {
                  title: "",
                  data: Belt_Array.map(Belt_Option.getWithDefault(recurrentCharge.items, []), (function (item) {
                          return {
                                  name: item.name,
                                  price: item.price,
                                  quantity: item.quantity,
                                  freightAmount: item.freightAmount,
                                  insuranceAmount: item.insuranceAmount,
                                  othersAmount: item.othersAmount,
                                  discountAmount: item.discountAmount,
                                  itemId: item.id,
                                  temporaryId: Uuid.v4(),
                                  maxQuantity: 0,
                                  hasMaxQuantity: false,
                                  chargeType: /* Single */0
                                };
                        })),
                  mode: /* Small */1,
                  readOnly: true,
                  domain: /* RecurrentCharge */1
                }), React.createElement(CreditCardForm.Provider.make, Curry._3(CreditCardForm.Provider.makeProps, reform, tmp, undefined)));
}

var make = RecurrentChargePayment;

exports.StateLenses = StateLenses;
exports.CreditCardForm = CreditCardForm;
exports.Styles = Styles;
exports.RecurrentChargePaymentQuery = RecurrentChargePaymentQuery;
exports.AddSourceMutation = AddSourceMutation;
exports.UpdateSourceMutation = UpdateSourceMutation;
exports.make = make;
/* CreditCardForm Not a pure module */
