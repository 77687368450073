'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"chargeAddSource_charge_boleto_barcode":{"n":""},"chargeAddSource_charge_boleto_url":{"n":""},"chargeAddSource_charge":{"n":""},"chargeAddSource_charge_rejectedAt":{"n":""},"chargeAddSource_charge_refundedAt":{"n":""},"chargeAddSource_charge_card":{"n":""},"chargeAddSource":{"n":""},"chargeAddSource_charge_boleto_id":{"n":""},"chargeAddSource_charge_canceledAt":{"n":""},"chargeAddSource_charge_boleto":{"n":""},"chargeAddSource_error":{"n":""},"chargeAddSource_charge_paidAt":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"chargeAddSource_charge_boleto_barcode":{"n":""},"chargeAddSource_charge_boleto_url":{"n":""},"chargeAddSource_charge":{"n":""},"chargeAddSource_charge_rejectedAt":{"n":""},"chargeAddSource_charge_refundedAt":{"n":""},"chargeAddSource_charge_card":{"n":""},"chargeAddSource":{"n":""},"chargeAddSource_charge_boleto_id":{"n":""},"chargeAddSource_charge_canceledAt":{"n":""},"chargeAddSource_charge_boleto":{"n":""},"chargeAddSource_error":{"n":""},"chargeAddSource_charge_paidAt":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"ChargeAddSourceInput"}},"ChargeAddSourceInput":{"creditCard":{"r":"CreditCard"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_creditCard(cardNumber, cvc, expirationMonth, expirationYear, holderName) {
  return {
          cardNumber: cardNumber,
          cvc: cvc,
          expirationMonth: expirationMonth,
          expirationYear: expirationYear,
          holderName: holderName
        };
}

function make_chargeAddSourceInput(chargeId, creditCard, taxId) {
  return {
          chargeId: chargeId,
          creditCard: creditCard,
          taxId: taxId
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_chargeAddSource_charge_boleto(id, barcode, url, amount, param) {
  return {
          id: id,
          barcode: barcode,
          url: url,
          amount: amount
        };
}

function make_response_chargeAddSource_charge_card(id, last4, brand) {
  return {
          id: id,
          last4: last4,
          brand: brand
        };
}

function make_response_chargeAddSource_charge(id, status, card, boleto, paidAt, canceledAt, rejectedAt, refundedAt, param) {
  return {
          id: id,
          status: status,
          card: card,
          boleto: boleto,
          paidAt: paidAt,
          canceledAt: canceledAt,
          rejectedAt: rejectedAt,
          refundedAt: refundedAt
        };
}

function make_response_chargeAddSource_error(message) {
  return {
          message: message
        };
}

function make_response_chargeAddSource(error, charge, param) {
  return {
          error: error,
          charge: charge
        };
}

function makeOptimisticResponse(chargeAddSource, param) {
  return {
          chargeAddSource: chargeAddSource
        };
}

var Utils = {
  make_creditCard: make_creditCard,
  make_chargeAddSourceInput: make_chargeAddSourceInput,
  makeVariables: makeVariables,
  make_response_chargeAddSource_charge_boleto: make_response_chargeAddSource_charge_boleto,
  make_response_chargeAddSource_charge_card: make_response_chargeAddSource_charge_card,
  make_response_chargeAddSource_charge: make_response_chargeAddSource_charge,
  make_response_chargeAddSource_error: make_response_chargeAddSource_error,
  make_response_chargeAddSource: make_response_chargeAddSource,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChargeAddSourcePayload",
    "kind": "LinkedField",
    "name": "chargeAddSource",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Charge",
        "kind": "LinkedField",
        "name": "charge",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerCard",
            "kind": "LinkedField",
            "name": "card",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last4",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brand",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoletoType",
            "kind": "LinkedField",
            "name": "boleto",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "barcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canceledAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rejectedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refundedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChargePayment_AddSourceMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChargePayment_AddSourceMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9de2247307d59e6278a2e50b9eb6e563",
    "id": null,
    "metadata": {},
    "name": "ChargePayment_AddSourceMutation",
    "operationKind": "mutation",
    "text": "mutation ChargePayment_AddSourceMutation(\n  $input: ChargeAddSourceInput!\n) {\n  chargeAddSource(input: $input) {\n    error {\n      message\n    }\n    charge {\n      id\n      status\n      card {\n        id\n        last4\n        brand\n      }\n      boleto {\n        id\n        barcode\n        url\n        amount\n      }\n      paidAt\n      canceledAt\n      rejectedAt\n      refundedAt\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
