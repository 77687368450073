'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Uuid = require("uuid");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Fi = require("react-icons/fi");
var Hooks = require("react-relay/hooks");
var Text$VannaFrontend = require("../components/Text.bs.js");
var Badge$VannaFrontend = require("../components/Badge.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Button$VannaFrontend = require("../components/Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var ItemsTable$VannaFrontend = require("../components/ItemsTable.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var PageQueryRenderer$VannaFrontend = require("../components/PageQueryRenderer.bs.js");
var ChargeDetailsQuery_graphql$VannaFrontend = require("../__generated__/ChargeDetailsQuery_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.padding(Theme$VannaFrontend.Padding.content),
      tl: /* [] */0
    });

var icon = Curry._1(Css.style, {
      hd: Css.marginRight({
            NAME: "px",
            VAL: 5
          }),
      tl: /* [] */0
    });

var table = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.9
          }),
      tl: /* [] */0
    });

var tableColumn = Curry._1(Css.style, {
      hd: Css.fontWeight("initial"),
      tl: {
        hd: Css.paddingBottom({
              NAME: "px",
              VAL: 4
            }),
        tl: {
          hd: Css.fontSize({
                NAME: "rem",
                VAL: 0.95
              }),
          tl: {
            hd: Css.not__(":last-child", {
                  hd: Css.paddingRight({
                        NAME: "rem",
                        VAL: 4.0
                      }),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var total = Curry._1(Css.style, {
      hd: Css.textAlign("right"),
      tl: {
        hd: Css.maxWidth({
              NAME: "px",
              VAL: 700
            }),
        tl: {
          hd: Css.fontSize({
                NAME: "rem",
                VAL: 1.0
              }),
          tl: {
            hd: Css.marginTop({
                  NAME: "rem",
                  VAL: 2.0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  panel: panel,
  icon: icon,
  table: table,
  tableColumn: tableColumn,
  total: total
};

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(ChargeDetailsQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ChargeDetailsQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(ChargeDetailsQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(ChargeDetailsQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, ChargeDetailsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, ChargeDetailsQuery_graphql$VannaFrontend.node, ChargeDetailsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: ChargeDetailsQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, ChargeDetailsQuery_graphql$VannaFrontend.node, ChargeDetailsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return ChargeDetailsQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(ChargeDetailsQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(ChargeDetailsQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var ChargeDetailsQuery_makeVariables = ChargeDetailsQuery_graphql$VannaFrontend.Utils.makeVariables;

var ChargeDetailsQuery = {
  makeVariables: ChargeDetailsQuery_makeVariables,
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

function ChargeDetails$Inner(Props) {
  var $$navigator = Props.navigator;
  var chargeId = Props.chargeId;
  var queryData = use({
        chargeId: chargeId
      }, undefined, undefined, undefined, undefined, undefined);
  var charge = queryData.node;
  var tmp;
  if (charge !== undefined) {
    var match = charge.amount;
    var match$1 = charge.transactionCost;
    var match$2 = charge.status;
    var match$3 = charge.paymentMethod;
    var match$4 = charge.pixDynamicQrcode;
    var tmp$1;
    if (match$4 !== undefined) {
      var payerMessage = match$4.payerMessage;
      tmp$1 = payerMessage !== undefined ? React.createElement("tr", undefined, React.createElement("td", {
                  className: tableColumn
                }, "Mensagem do pagador:"), React.createElement("td", {
                  className: tableColumn
                }, React.createElement(Text$VannaFrontend.make, {
                      children: payerMessage
                    }))) : null;
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(PageHeader$VannaFrontend.make, {
              title: "Venda"
            }), React.createElement(Panel$VannaFrontend.make, {
              className: panel,
              children: null
            }, React.createElement(Button$VannaFrontend.make, {
                  marginBottom: true,
                  fullWidth: false,
                  mode: /* Secondary */1,
                  onClick: (function (param) {
                      return Curry._1($$navigator.push, /* Charges */6);
                    }),
                  children: null
                }, React.createElement(Fi.FiCornerUpLeft, {
                      className: icon
                    }), "Voltar"), React.createElement("table", {
                  className: table
                }, React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", {
                              className: tableColumn
                            }, "ID:"), React.createElement("td", {
                              className: tableColumn
                            }, Belt_Option.getWithDefault(charge.friendlyId, "-"))), React.createElement("tr", undefined, React.createElement("td", {
                              className: tableColumn
                            }, "Valor bruto:"), React.createElement("td", {
                              className: tableColumn
                            }, Belt_Option.mapWithDefault(charge.amount, "-", Money$VannaFrontend.formatInt))), React.createElement("tr", undefined, React.createElement("td", {
                              className: tableColumn
                            }, "Taxa de transação:"), React.createElement("td", {
                              className: tableColumn
                            }, Belt_Option.mapWithDefault(charge.transactionCost, "-", Money$VannaFrontend.formatInt))), React.createElement("tr", undefined, React.createElement("td", {
                              className: tableColumn
                            }, "Valor líquido:"), React.createElement("td", {
                              className: tableColumn
                            }, match !== undefined && match$1 !== undefined ? Money$VannaFrontend.format(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                            TAG: /* Cents */1,
                                            _0: match
                                          }), Money$VannaFrontend.make({
                                            TAG: /* Cents */1,
                                            _0: match$1
                                          }))) : "-")), React.createElement("tr", undefined, React.createElement("td", {
                              className: tableColumn
                            }, "Cliente:"), React.createElement("td", {
                              className: tableColumn
                            }, Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.map(charge.customer, (function (customer) {
                                            return customer;
                                          })), (function (customer) {
                                        return customer.name;
                                      })), "-"))), React.createElement("tr", undefined, React.createElement("td", {
                              className: tableColumn
                            }, "Situação"), React.createElement("td", {
                              className: tableColumn
                            }, match$2 !== undefined ? (
                                match$2 === "CANCELED" ? React.createElement(Badge$VannaFrontend.make, {
                                        mode: /* Warn */4,
                                        text: "Cancelado"
                                      }) : (
                                    match$2 === "REJECTED_BY_CUSTOMER" ? React.createElement(Badge$VannaFrontend.make, {
                                            mode: /* Error */2,
                                            text: "Rejeitado"
                                          }) : (
                                        match$2 === "AWAITING_PAYMENT" ? React.createElement(Badge$VannaFrontend.make, {
                                                mode: /* Default */0,
                                                text: "Aguardando pagamento"
                                              }) : (
                                            match$2 === "REFUNDED" ? React.createElement(Badge$VannaFrontend.make, {
                                                    mode: /* Info */3,
                                                    text: "Devolvido"
                                                  }) : (
                                                match$2 === "PAID" ? React.createElement(Badge$VannaFrontend.make, {
                                                        mode: /* Success */1,
                                                        text: "Pago"
                                                      }) : null
                                              )
                                          )
                                      )
                                  )
                              ) : null)), React.createElement("tr", undefined, React.createElement("td", {
                              className: tableColumn
                            }, "Método de pagamento"), React.createElement("td", {
                              className: tableColumn
                            }, match$3 !== undefined ? (
                                match$3 === "PIX" ? "PIX" : (
                                    match$3 === "CREDIT_CARD" ? "Cartão de crédito" : "-"
                                  )
                              ) : "-")), tmp$1)), React.createElement(Text$VannaFrontend.make, {
                  children: React.createElement("strong", undefined, "Produtos")
                }), React.createElement(ItemsTable$VannaFrontend.make, {
                  title: "",
                  data: Belt_Array.map(Belt_Array.keep(Belt_Option.getWithDefault(charge.items, []), (function (item) {
                              var match = item.type_;
                              return match === "PRODUCT";
                            })), (function (item) {
                          return {
                                  name: item.name,
                                  price: item.price,
                                  quantity: item.quantity,
                                  freightAmount: item.freightAmount,
                                  insuranceAmount: item.insuranceAmount,
                                  othersAmount: item.othersAmount,
                                  discountAmount: item.discountAmount,
                                  itemId: item.id,
                                  temporaryId: Uuid.v4(),
                                  maxQuantity: 0,
                                  hasMaxQuantity: false,
                                  chargeType: /* Single */0
                                };
                        })),
                  readOnly: true,
                  domain: /* Charge */0
                }), React.createElement(Text$VannaFrontend.make, {
                  children: React.createElement("strong", undefined, "Serviços")
                }), React.createElement(ItemsTable$VannaFrontend.make, {
                  title: "",
                  data: Belt_Array.map(Belt_Array.keep(Belt_Option.getWithDefault(charge.items, []), (function (item) {
                              var match = item.type_;
                              return match === "SERVICE";
                            })), (function (item) {
                          return {
                                  name: item.name,
                                  price: item.price,
                                  quantity: item.quantity,
                                  freightAmount: item.freightAmount,
                                  insuranceAmount: item.insuranceAmount,
                                  othersAmount: item.othersAmount,
                                  discountAmount: item.discountAmount,
                                  itemId: item.id,
                                  temporaryId: Uuid.v4(),
                                  maxQuantity: 0,
                                  hasMaxQuantity: false,
                                  chargeType: /* Single */0
                                };
                        })),
                  readOnly: true,
                  domain: /* Charge */0
                })));
  } else {
    tmp = React.createElement(Panel$VannaFrontend.make, {
          className: panel,
          children: React.createElement(Text$VannaFrontend.make, {
                children: "Faturamento não encontrado"
              })
        });
  }
  return React.createElement(PrivateLayout$VannaFrontend.make, {
              navigator: $$navigator,
              children: tmp
            });
}

var Inner = {
  make: ChargeDetails$Inner
};

function ChargeDetails(Props) {
  var chargeId = Props.chargeId;
  var $$navigator = Props.navigator;
  return React.createElement(PageQueryRenderer$VannaFrontend.make, {
              children: React.createElement(PrivateLayout$VannaFrontend.make, {
                    navigator: $$navigator,
                    children: React.createElement(ChargeDetails$Inner, {
                          navigator: $$navigator,
                          chargeId: chargeId
                        })
                  })
            });
}

var make = ChargeDetails;

exports.Styles = Styles;
exports.ChargeDetailsQuery = ChargeDetailsQuery;
exports.Inner = Inner;
exports.make = make;
/* panel Not a pure module */
