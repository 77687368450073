'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Uuid = require("uuid");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Fi = require("react-icons/fi");
var Format = require("date-fns/format");
var AddDays = require("date-fns/addDays");
var IsValid = require("date-fns/isValid");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var RelayEnv$VannaFrontend = require("../RelayEnv.bs.js");
var DateField$VannaFrontend = require("./DateField.bs.js");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");
var FieldInfo$VannaFrontend = require("./FieldInfo.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var ItemsTable$VannaFrontend = require("./ItemsTable.bs.js");
var MoneyField$VannaFrontend = require("./MoneyField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var NumberField$VannaFrontend = require("./NumberField.bs.js");
var ChargeHelpers$VannaFrontend = require("../types/ChargeHelpers.bs.js");
var SuspenseLoader$VannaFrontend = require("./SuspenseLoader.bs.js");
var CustomerUpsertForm$VannaFrontend = require("./CustomerUpsertForm.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var SearchableSelectField$VannaFrontend = require("./SearchableSelectField.bs.js");
var ItemTableUpsertProductForm$VannaFrontend = require("./ItemTableUpsertProductForm.bs.js");
var ItemTableUpsertServiceForm$VannaFrontend = require("./ItemTableUpsertServiceForm.bs.js");
var ChargeUpsertFormQuery_graphql$VannaFrontend = require("../__generated__/ChargeUpsertFormQuery_graphql.bs.js");
var ChargeUpsertForm_charge_graphql$VannaFrontend = require("../__generated__/ChargeUpsertForm_charge_graphql.bs.js");
var ChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend = require("../__generated__/ChargeUpsertCustomersRefetchQuery_graphql.bs.js");
var ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend = require("../__generated__/ChargeUpsertForm_ChargeCreateMutation_graphql.bs.js");
var ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend = require("../__generated__/ChargeUpsertForm_GetTransactionCostQuery_graphql.bs.js");
var ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend = require("../__generated__/ChargeUpsertFormCustomersConnection_query_graphql.bs.js");

var thirtyPercentWidth = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 33.333
          }),
      tl: /* [] */0
    });

var Styles = {
  thirtyPercentWidth: thirtyPercentWidth
};

function get(state, field) {
  switch (field) {
    case /* CustomerId */0 :
        return state.customerId;
    case /* CustomerHasCreditCard */1 :
        return state.customerHasCreditCard;
    case /* Description */2 :
        return state.description;
    case /* HasInstallments */3 :
        return state.hasInstallments;
    case /* MaxInstallments */4 :
        return state.maxInstallments;
    case /* PaymentMethod */5 :
        return state.paymentMethod;
    case /* AskForConfirmation */6 :
        return state.askForConfirmation;
    case /* Items */7 :
        return state.items;
    case /* DueDate */8 :
        return state.dueDate;
    case /* HasFine */9 :
        return state.hasFine;
    case /* FineInstruction */10 :
        return state.fineInstruction;
    case /* FineDate */11 :
        return state.fineDate;
    case /* FineAmountFixed */12 :
        return state.fineAmountFixed;
    case /* FineAmountPercentage */13 :
        return state.fineAmountPercentage;
    case /* HasDiscount */14 :
        return state.hasDiscount;
    case /* DiscountInstruction */15 :
        return state.discountInstruction;
    case /* DiscountDate */16 :
        return state.discountDate;
    case /* DiscountAmountFixed */17 :
        return state.discountAmountFixed;
    case /* DiscountAmountPercentage */18 :
        return state.discountAmountPercentage;
    case /* HasInterest */19 :
        return state.hasInterest;
    case /* InterestInstruction */20 :
        return state.interestInstruction;
    case /* InterestDate */21 :
        return state.interestDate;
    case /* InterestAmountFixed */22 :
        return state.interestAmountFixed;
    case /* InterestAmountPercentage */23 :
        return state.interestAmountPercentage;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* CustomerId */0 :
        var newrecord = Caml_obj.caml_obj_dup(state);
        newrecord.customerId = value;
        return newrecord;
    case /* CustomerHasCreditCard */1 :
        var newrecord$1 = Caml_obj.caml_obj_dup(state);
        newrecord$1.customerHasCreditCard = value;
        return newrecord$1;
    case /* Description */2 :
        var newrecord$2 = Caml_obj.caml_obj_dup(state);
        newrecord$2.description = value;
        return newrecord$2;
    case /* HasInstallments */3 :
        var newrecord$3 = Caml_obj.caml_obj_dup(state);
        newrecord$3.hasInstallments = value;
        return newrecord$3;
    case /* MaxInstallments */4 :
        var newrecord$4 = Caml_obj.caml_obj_dup(state);
        newrecord$4.maxInstallments = value;
        return newrecord$4;
    case /* PaymentMethod */5 :
        var newrecord$5 = Caml_obj.caml_obj_dup(state);
        newrecord$5.paymentMethod = value;
        return newrecord$5;
    case /* AskForConfirmation */6 :
        var newrecord$6 = Caml_obj.caml_obj_dup(state);
        newrecord$6.askForConfirmation = value;
        return newrecord$6;
    case /* Items */7 :
        var newrecord$7 = Caml_obj.caml_obj_dup(state);
        newrecord$7.items = value;
        return newrecord$7;
    case /* DueDate */8 :
        var newrecord$8 = Caml_obj.caml_obj_dup(state);
        newrecord$8.dueDate = value;
        return newrecord$8;
    case /* HasFine */9 :
        var newrecord$9 = Caml_obj.caml_obj_dup(state);
        newrecord$9.hasFine = value;
        return newrecord$9;
    case /* FineInstruction */10 :
        var newrecord$10 = Caml_obj.caml_obj_dup(state);
        newrecord$10.fineInstruction = value;
        return newrecord$10;
    case /* FineDate */11 :
        var newrecord$11 = Caml_obj.caml_obj_dup(state);
        newrecord$11.fineDate = value;
        return newrecord$11;
    case /* FineAmountFixed */12 :
        var newrecord$12 = Caml_obj.caml_obj_dup(state);
        newrecord$12.fineAmountFixed = value;
        return newrecord$12;
    case /* FineAmountPercentage */13 :
        var newrecord$13 = Caml_obj.caml_obj_dup(state);
        newrecord$13.fineAmountPercentage = value;
        return newrecord$13;
    case /* HasDiscount */14 :
        var newrecord$14 = Caml_obj.caml_obj_dup(state);
        newrecord$14.hasDiscount = value;
        return newrecord$14;
    case /* DiscountInstruction */15 :
        var newrecord$15 = Caml_obj.caml_obj_dup(state);
        newrecord$15.discountInstruction = value;
        return newrecord$15;
    case /* DiscountDate */16 :
        var newrecord$16 = Caml_obj.caml_obj_dup(state);
        newrecord$16.discountDate = value;
        return newrecord$16;
    case /* DiscountAmountFixed */17 :
        var newrecord$17 = Caml_obj.caml_obj_dup(state);
        newrecord$17.discountAmountFixed = value;
        return newrecord$17;
    case /* DiscountAmountPercentage */18 :
        var newrecord$18 = Caml_obj.caml_obj_dup(state);
        newrecord$18.discountAmountPercentage = value;
        return newrecord$18;
    case /* HasInterest */19 :
        var newrecord$19 = Caml_obj.caml_obj_dup(state);
        newrecord$19.hasInterest = value;
        return newrecord$19;
    case /* InterestInstruction */20 :
        var newrecord$20 = Caml_obj.caml_obj_dup(state);
        newrecord$20.interestInstruction = value;
        return newrecord$20;
    case /* InterestDate */21 :
        var newrecord$21 = Caml_obj.caml_obj_dup(state);
        newrecord$21.interestDate = value;
        return newrecord$21;
    case /* InterestAmountFixed */22 :
        var newrecord$22 = Caml_obj.caml_obj_dup(state);
        newrecord$22.interestAmountFixed = value;
        return newrecord$22;
    case /* InterestAmountPercentage */23 :
        var newrecord$23 = Caml_obj.caml_obj_dup(state);
        newrecord$23.interestAmountPercentage = value;
        return newrecord$23;
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var ChargeUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(ChargeUpsertForm_charge_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ChargeUpsertForm_charge_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ChargeUpsertForm_charge_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ChargeUpsertForm_charge_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var ChargeFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

var getConnectionNodes = ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Utils.getConnectionNodes;

var Internal$1 = {};

function internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = ReasonRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = ReasonRelay.mapRenderPolicy(renderPolicy);
  if (tmp$2 !== undefined) {
    tmp.UNSTABLE_renderPolicy = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$3 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var InternalRefetch = {
  internal_makeRefetchableFnOpts: internal_makeRefetchableFnOpts
};

function useRefetchable(fRef) {
  var match = Hooks.useRefetchableFragment(ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fRef);
  var refetchFn = match[1];
  var data = ReasonRelay_Internal.internal_useConvertedValue(ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, match[0]);
  return [
          data,
          (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return Curry._2(refetchFn, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        ];
}

function use$1(fRef) {
  var data = Hooks.useFragment(ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt$1(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function usePagination(fr) {
  var p = Hooks.usePaginationFragment(ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fr);
  var data = ReasonRelay_Internal.internal_useConvertedValue(ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

function useBlockingPagination(fRef) {
  var p = Hooks.useBlockingPaginationFragment(ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.node, fRef);
  var data = ReasonRelay_Internal.internal_useConvertedValue(ChargeUpsertFormCustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

var makeRefetchVariables = ChargeUpsertCustomersRefetchQuery_graphql$VannaFrontend.Types.makeRefetchVariables;

var CustomersConnectionFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Internal: Internal$1,
  InternalRefetch: InternalRefetch,
  useRefetchable: useRefetchable,
  use: use$1,
  useOpt: useOpt$1,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination,
  makeRefetchVariables: makeRefetchVariables
};

var Internal$2 = {};

function use$2(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(ChargeUpsertFormQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(ChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(ChargeUpsertFormQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, ChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, ChargeUpsertFormQuery_graphql$VannaFrontend.node, ChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: ChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, ChargeUpsertFormQuery_graphql$VannaFrontend.node, ChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return ChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(ChargeUpsertFormQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(ChargeUpsertFormQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var ChargeUpsertFormQuery = {
  Types: undefined,
  Internal: Internal$2,
  use: use$2,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var Internal$3 = {};

function use$3(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader$1(param) {
  var match = Hooks.useQueryLoader(ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch$1(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node, ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised$1(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node, ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded$1(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var GetTransactionCostQuery_make_chargeGetTransactionCostQueryInput = ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Utils.make_chargeGetTransactionCostQueryInput;

var GetTransactionCostQuery_makeVariables = ChargeUpsertForm_GetTransactionCostQuery_graphql$VannaFrontend.Utils.makeVariables;

var GetTransactionCostQuery = {
  make_chargeGetTransactionCostQueryInput: GetTransactionCostQuery_make_chargeGetTransactionCostQueryInput,
  makeVariables: GetTransactionCostQuery_makeVariables,
  Types: undefined,
  Internal: Internal$3,
  use: use$3,
  useLoader: useLoader$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  usePreloaded: usePreloaded$1
};

var Internal$4 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.node,
              variables: ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$4(param) {
  var match = RelayExperimental.useMutation(ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ChargeCreateMutation_make_chargeItem = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.make_chargeItem;

var ChargeCreateMutation_make_chargeCreateInput = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.make_chargeCreateInput;

var ChargeCreateMutation_makeVariables = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var ChargeCreateMutation_make_response_chargeCreate_charge_items = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_chargeCreate_charge_items;

var ChargeCreateMutation_make_response_chargeCreate_charge_customer = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_chargeCreate_charge_customer;

var ChargeCreateMutation_make_response_chargeCreate_charge_boleto = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_chargeCreate_charge_boleto;

var ChargeCreateMutation_make_response_chargeCreate_charge = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_chargeCreate_charge;

var ChargeCreateMutation_make_response_chargeCreate_error = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_chargeCreate_error;

var ChargeCreateMutation_make_response_chargeCreate = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.make_response_chargeCreate;

var ChargeCreateMutation_makeOptimisticResponse = ChargeUpsertForm_ChargeCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ChargeCreateMutation = {
  make_chargeItem: ChargeCreateMutation_make_chargeItem,
  make_chargeCreateInput: ChargeCreateMutation_make_chargeCreateInput,
  makeVariables: ChargeCreateMutation_makeVariables,
  make_response_chargeCreate_charge_items: ChargeCreateMutation_make_response_chargeCreate_charge_items,
  make_response_chargeCreate_charge_customer: ChargeCreateMutation_make_response_chargeCreate_charge_customer,
  make_response_chargeCreate_charge_boleto: ChargeCreateMutation_make_response_chargeCreate_charge_boleto,
  make_response_chargeCreate_charge: ChargeCreateMutation_make_response_chargeCreate_charge,
  make_response_chargeCreate_error: ChargeCreateMutation_make_response_chargeCreate_error,
  make_response_chargeCreate: ChargeCreateMutation_make_response_chargeCreate,
  makeOptimisticResponse: ChargeCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$4,
  commitMutation: commitMutation,
  use: use$4
};

var PaymentMethodSelect = SearchableSelectField$VannaFrontend.Make({});

var CustomersSelect = SearchableSelectField$VannaFrontend.Make({});

var BooleanSelectField = SearchableSelectField$VannaFrontend.Make({});

var FineAndInterestInstructionSelectField = SearchableSelectField$VannaFrontend.Make({});

var DiscountInstructionSelectField = SearchableSelectField$VannaFrontend.Make({});

function ChargeUpsertForm$1(Props) {
  var chargeOpt = Props.charge;
  var dispatchModal = Props.dispatchModal;
  var queryRef = Props.query;
  var charge = chargeOpt !== undefined ? Caml_option.valFromOption(chargeOpt) : undefined;
  var chargeFragment = useOpt(charge);
  var queryData = use$2(undefined, undefined, undefined, undefined, undefined, undefined);
  var match = use$4(undefined);
  var createCharge = match[0];
  var match$1 = usePagination(queryRef);
  var hasNext = match$1.hasNext;
  var loadNext = match$1.loadNext;
  var customers = Curry._1(getConnectionNodes, match$1.data.customers);
  var todayDate = new Date();
  var dueDate = AddDays(todayDate, 7).toISOString();
  var tmp;
  if (chargeFragment !== undefined) {
    var match$2 = chargeFragment.paymentMethod;
    tmp = {
      customerId: chargeFragment.customer.id,
      customerHasCreditCard: false,
      description: Belt_Option.getWithDefault(chargeFragment.description, ""),
      hasInstallments: false,
      maxInstallments: 0,
      paymentMethod: match$2 === "PIX" ? /* Pix */1 : (
          match$2 === "BOLETO" ? /* Boleto */2 : (
              match$2 === "CREDIT_CARD" ? /* CreditCard */0 : /* Pix */1
            )
        ),
      askForConfirmation: Belt_Option.getWithDefault(chargeFragment.askForConfirmation, false),
      items: Belt_Array.reduce(chargeFragment.items, [], (function (acc, item) {
              var match = item.type_;
              if (match === "PRODUCT") {
                return Belt_Array.concat(acc, [{
                              TAG: /* Product */0,
                              _0: {
                                name: item.name,
                                price: item.price,
                                quantity: item.quantity,
                                freightAmount: item.freightAmount,
                                insuranceAmount: item.insuranceAmount,
                                othersAmount: item.othersAmount,
                                discountAmount: item.discountAmount,
                                itemId: item.id,
                                temporaryId: Uuid.v4(),
                                maxQuantity: 0,
                                hasMaxQuantity: false,
                                chargeType: /* Single */0
                              }
                            }]);
              } else if (match === "SERVICE") {
                return Belt_Array.concat(acc, [{
                              TAG: /* Service */1,
                              _0: {
                                name: item.name,
                                price: item.price,
                                quantity: item.quantity,
                                freightAmount: item.freightAmount,
                                insuranceAmount: item.insuranceAmount,
                                othersAmount: item.othersAmount,
                                discountAmount: item.discountAmount,
                                itemId: item.id,
                                temporaryId: Uuid.v4(),
                                maxQuantity: 0,
                                hasMaxQuantity: false,
                                chargeType: /* Single */0
                              }
                            }]);
              } else {
                return acc;
              }
            })),
      dueDate: dueDate,
      hasFine: false,
      fineInstruction: /* FixedAmount */0,
      fineDate: dueDate,
      fineAmountFixed: 0.0,
      fineAmountPercentage: 0,
      hasDiscount: false,
      discountInstruction: /* PercentageUpToDate */0,
      discountDate: dueDate,
      discountAmountFixed: 0.0,
      discountAmountPercentage: 0,
      hasInterest: false,
      interestInstruction: /* FixedAmount */0,
      interestDate: dueDate,
      interestAmountFixed: 0.0,
      interestAmountPercentage: 0
    };
  } else {
    tmp = {
      customerId: "",
      customerHasCreditCard: false,
      description: "",
      hasInstallments: false,
      maxInstallments: 0,
      paymentMethod: /* Pix */1,
      askForConfirmation: false,
      items: [],
      dueDate: dueDate,
      hasFine: false,
      fineInstruction: /* FixedAmount */0,
      fineDate: dueDate,
      fineAmountFixed: 0.0,
      fineAmountPercentage: 0,
      hasDiscount: false,
      discountInstruction: /* AmountUntilDate */2,
      discountDate: dueDate,
      discountAmountFixed: 0.0,
      discountAmountPercentage: 0,
      hasInterest: false,
      interestInstruction: /* FixedAmount */0,
      interestDate: dueDate,
      interestAmountFixed: 0.0,
      interestAmountPercentage: 0
    };
  }
  var reform = Curry._7(ChargeUpsertForm.use, tmp, /* Schema */{
        _0: Curry._2(ChargeUpsertForm.ReSchema.Validation.$plus, Curry._3(ChargeUpsertForm.ReSchema.Validation.nonEmpty, "Por favor, informe o cliente", undefined, /* CustomerId */0), Curry._3(ChargeUpsertForm.ReSchema.Validation.custom, (function (state) {
                    if (state.items.length === 0) {
                      return {
                              TAG: /* Error */1,
                              _0: "Voc\xc3\xaa deve adicionar ao menos um produto ou servi\xc3\xa7o"
                            };
                    } else {
                      return /* Valid */0;
                    }
                  }), undefined, /* Items */7))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          var match = state.values.discountInstruction;
          var match$1 = state.values.discountInstruction;
          var tmp;
          switch (match$1) {
            case /* PercentageUpToDate */0 :
                tmp = "PERCENTAGE_UP_TO_DATE";
                break;
            case /* PercentageOverNominalValueAdvance */1 :
                tmp = "PERCENTAGE_OVER_NOMINAL_VALUE_ADVANCE";
                break;
            case /* AmountUntilDate */2 :
                tmp = "AMOUNT_UNTIL_DATE";
                break;
            case /* AmountAboveNominalValueAdvance */3 :
                tmp = "AMOUNT_ABOVE_NOMINAL_VALUE_ADVANCE";
                break;
            
          }
          var match$2 = state.values.fineInstruction;
          var match$3 = state.values.fineInstruction;
          var match$4 = state.values.interestInstruction;
          var match$5 = state.values.interestInstruction;
          var match$6 = state.values.paymentMethod;
          var tmp$1;
          if (match$6 !== 1) {
            throw {
                  RE_EXN_ID: "Match_failure",
                  _1: [
                    "ChargeUpsertForm.res",
                    379,
                    27
                  ],
                  Error: new Error()
                };
          }
          tmp$1 = "PIX";
          Curry.app(createCharge, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, errors) {
                    if (errors !== undefined && errors.length !== 0) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match = response.chargeCreate;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    } else if (match.charge !== undefined) {
                      Curry._1(dispatchModal, /* Close */1);
                      return Curry._1(send, {
                                  TAG: /* SetFormState */10,
                                  _0: /* Dirty */0
                                });
                    } else {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                  }),
                undefined,
                undefined,
                undefined,
                (function (store, response) {
                    var match = response.chargeCreate;
                    if (match === undefined) {
                      return ;
                    }
                    var charge = match.charge;
                    if (charge === undefined) {
                      return ;
                    }
                    var me = store.getRoot().getLinkedRecord("me", undefined);
                    if (me == null) {
                      return ;
                    }
                    var products = ReasonRelay.RecordProxy.getLinkedRecords(me, "charges", {
                          month: 10,
                          year: 2020,
                          status: null
                        }, undefined);
                    if (products === undefined) {
                      return ;
                    }
                    var productProxy = store.get(charge.id);
                    me.setLinkedRecords(Belt_Array.concat(products, [(productProxy == null) ? undefined : Caml_option.some(productProxy)]), "charges", {
                          month: 10,
                          year: 2020,
                          status: null
                        });
                    
                  }),
                {
                  input: {
                    affiliateId: undefined,
                    affiliatePercentage: undefined,
                    askForConfirmation: state.values.askForConfirmation,
                    customerId: state.values.customerId,
                    date: new Date().toISOString(),
                    description: state.values.description,
                    discountAmount: match >= 2 ? Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                                TAG: /* Decimal */0,
                                _0: state.values.discountAmountFixed
                              })) : state.values.discountAmountPercentage,
                    discountDate: state.values.discountDate,
                    discountInstruction: tmp,
                    dueDate: state.values.dueDate,
                    fineAmount: match$2 ? state.values.fineAmountPercentage : Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                                TAG: /* Decimal */0,
                                _0: state.values.fineAmountFixed
                              })),
                    fineDate: state.values.fineDate,
                    fineInstruction: match$3 ? "PERCENTAGE_AMOUNT" : "FIXED_AMOUNT",
                    hasDiscount: state.values.hasDiscount,
                    hasFine: state.values.hasFine,
                    hasInstallments: false,
                    hasInterest: state.values.hasInterest,
                    installments: undefined,
                    interestAmount: match$4 ? state.values.interestAmountPercentage : Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                                TAG: /* Decimal */0,
                                _0: state.values.interestAmountFixed
                              })),
                    interestDate: state.values.interestDate,
                    interestInstruction: match$5 ? "PERCENTAGE_AMOUNT" : "FIXED_AMOUNT",
                    items: Belt_Array.map(state.values.items, (function (item) {
                            var item$1 = item._0;
                            return {
                                    discountAmount: item$1.discountAmount,
                                    freightAmount: item$1.freightAmount,
                                    insuranceAmount: item$1.insuranceAmount,
                                    itemId: item$1.itemId,
                                    othersAmount: item$1.othersAmount,
                                    quantity: item$1.quantity
                                  };
                          })),
                    paymentMethod: tmp$1
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$3 = React.useState(function () {
        return {
                pix: 0,
                ccDefault: 0,
                ccVisaOrMastercard: 0,
                boleto: 0
              };
      });
  var setTransactionCosts = match$3[1];
  var transactionCosts = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setLoadingTransactionCost = match$4[1];
  var match$5 = React.useState(function () {
        
      });
  var setSelectedItem = match$5[1];
  var selectedItem = match$5[0];
  var chargeTotalAmount = ChargeHelpers$VannaFrontend.getTotalFromArray(Belt_Array.map(reform.state.values.items, (function (item) {
              if (item.TAG === /* Product */0) {
                var item$1 = item._0;
                return {
                        name: item$1.name,
                        price: item$1.price,
                        quantity: item$1.quantity,
                        freightAmount: item$1.freightAmount,
                        insuranceAmount: item$1.insuranceAmount,
                        othersAmount: item$1.othersAmount,
                        discountAmount: item$1.discountAmount,
                        itemId: "",
                        temporaryId: "",
                        maxQuantity: item$1.maxQuantity,
                        hasMaxQuantity: item$1.hasMaxQuantity,
                        chargeType: item$1.chargeType
                      };
              }
              var item$2 = item._0;
              return {
                      name: item$2.name,
                      price: item$2.price,
                      quantity: item$2.quantity,
                      freightAmount: item$2.freightAmount,
                      insuranceAmount: item$2.insuranceAmount,
                      othersAmount: item$2.othersAmount,
                      discountAmount: item$2.discountAmount,
                      itemId: "",
                      temporaryId: "",
                      maxQuantity: item$2.maxQuantity,
                      hasMaxQuantity: item$2.hasMaxQuantity,
                      chargeType: item$2.chargeType
                    };
            })));
  React.useEffect((function () {
          Curry._1(setLoadingTransactionCost, (function (param) {
                  return true;
                }));
          $$fetch$1(RelayEnv$VannaFrontend.environment, {
                input: {
                  amount: chargeTotalAmount
                }
              }, (function (result) {
                  if (result.TAG === /* Ok */0) {
                    var res = result._0.chargeGetTransactionCostQuery;
                    if (res !== undefined) {
                      Curry._1(setTransactionCosts, (function (param) {
                              return {
                                      pix: res.pix,
                                      ccDefault: res.ccDefault,
                                      ccVisaOrMastercard: res.ccVisaOrMastercard,
                                      boleto: res.boleto
                                    };
                            }));
                      return Curry._1(setLoadingTransactionCost, (function (param) {
                                    return false;
                                  }));
                    }
                    
                  }
                  return Curry._1(setLoadingTransactionCost, (function (param) {
                                return false;
                              }));
                }), undefined, undefined, undefined);
          
        }), [chargeTotalAmount]);
  var match$6 = queryData.me;
  if (match$6 === undefined) {
    return null;
  }
  var fragmentRefs = match$6.fragmentRefs;
  var match$7 = reform.state.values.paymentMethod;
  var match$8 = reform.state.values.paymentMethod;
  var tmp$1;
  if (match$8 >= 2) {
    var tmp$2;
    if (reform.state.values.hasFine) {
      var match$9 = reform.state.values.fineInstruction;
      tmp$2 = React.createElement(React.Fragment, undefined, reform.state.values.paymentMethod === /* Boleto */2 ? React.createElement(React.Fragment, undefined, React.createElement(ChargeUpsertForm.Field.make, {
                      field: /* FineDate */11,
                      render: (function (param) {
                          var value = param.value;
                          var date = IsValid(new Date(value)) ? new Date(value) : todayDate;
                          return React.createElement(DateField$VannaFrontend.make, {
                                      label: "Data da multa do título",
                                      value: Format(date, "yyyy-MM-dd"),
                                      onChange: param.handleChange,
                                      error: param.error,
                                      required: true
                                    });
                        })
                    }), React.createElement(ChargeUpsertForm.Field.make, {
                      field: /* FineInstruction */10,
                      render: (function (param) {
                          var handleChange = param.handleChange;
                          return React.createElement(FineAndInterestInstructionSelectField.make, {
                                      label: "Tipo da multa",
                                      error: param.error,
                                      required: true,
                                      value: param.value,
                                      onChange: (function (param) {
                                          Curry._1(handleChange, param.value);
                                          return $$Promise.resolved(undefined);
                                        }),
                                      options: [
                                        {
                                          label: "Valor fixo",
                                          value: /* FixedAmount */0
                                        },
                                        {
                                          label: "Percentual",
                                          value: /* PercentageAmount */1
                                        }
                                      ]
                                    });
                        })
                    })) : null, match$9 ? React.createElement(ChargeUpsertForm.Field.make, {
                  field: /* FineAmountPercentage */13,
                  render: (function (param) {
                      return React.createElement(NumberField$VannaFrontend.make, {
                                  label: "Valor da multa (%)",
                                  error: param.error,
                                  required: false,
                                  value: param.value,
                                  onChange: param.handleChange,
                                  min: "1",
                                  max: "100"
                                });
                    })
                }) : React.createElement(ChargeUpsertForm.Field.make, {
                  field: /* FineAmountFixed */12,
                  render: (function (param) {
                      return React.createElement(MoneyField$VannaFrontend.make, {
                                  label: "Valor da multa (R$)",
                                  error: param.error,
                                  required: false,
                                  value: param.value,
                                  onChange: param.handleChange
                                });
                    })
                }));
    } else {
      tmp$2 = null;
    }
    var tmp$3;
    if (reform.state.values.hasDiscount) {
      var match$10 = reform.state.values.discountInstruction;
      tmp$3 = React.createElement(React.Fragment, undefined, reform.state.values.paymentMethod === /* Boleto */2 ? React.createElement(React.Fragment, undefined, React.createElement(ChargeUpsertForm.Field.make, {
                      field: /* DiscountDate */16,
                      render: (function (param) {
                          var value = param.value;
                          var date = IsValid(new Date(value)) ? new Date(value) : todayDate;
                          return React.createElement(DateField$VannaFrontend.make, {
                                      label: "Data de desconto do título",
                                      value: Format(date, "yyyy-MM-dd"),
                                      onChange: param.handleChange,
                                      error: param.error,
                                      required: true
                                    });
                        })
                    }), React.createElement(ChargeUpsertForm.Field.make, {
                      field: /* DiscountInstruction */15,
                      render: (function (param) {
                          var handleChange = param.handleChange;
                          return React.createElement(DiscountInstructionSelectField.make, {
                                      label: "Tipo de desconto",
                                      error: param.error,
                                      required: true,
                                      value: param.value,
                                      onChange: (function (param) {
                                          Curry._1(handleChange, param.value);
                                          return $$Promise.resolved(undefined);
                                        }),
                                      options: [
                                        {
                                          label: "Porcentagem até a data relatada",
                                          value: /* PercentageUpToDate */0
                                        },
                                        {
                                          label: "Porcentagem sobre o valor nominal por antecipação (dias corridos)",
                                          value: /* PercentageOverNominalValueAdvance */1
                                        },
                                        {
                                          label: "Valor até a data informada",
                                          value: /* AmountUntilDate */2
                                        },
                                        {
                                          label: "Valor acima do valor nominal por antecipação (dias corridos)",
                                          value: /* AmountAboveNominalValueAdvance */3
                                        }
                                      ]
                                    });
                        })
                    })) : null, match$10 >= 2 ? React.createElement(ChargeUpsertForm.Field.make, {
                  field: /* DiscountAmountFixed */17,
                  render: (function (param) {
                      return React.createElement(MoneyField$VannaFrontend.make, {
                                  label: "Valor do desconto (R$)",
                                  error: param.error,
                                  required: false,
                                  value: param.value,
                                  onChange: param.handleChange
                                });
                    })
                }) : React.createElement(ChargeUpsertForm.Field.make, {
                  field: /* DiscountAmountPercentage */18,
                  render: (function (param) {
                      return React.createElement(NumberField$VannaFrontend.make, {
                                  label: "Valor do desconto (%)",
                                  error: param.error,
                                  required: false,
                                  value: param.value,
                                  onChange: param.handleChange,
                                  min: "1",
                                  max: "100"
                                });
                    })
                }));
    } else {
      tmp$3 = null;
    }
    var tmp$4;
    if (reform.state.values.hasInterest) {
      var match$11 = reform.state.values.interestInstruction;
      tmp$4 = React.createElement(React.Fragment, undefined, reform.state.values.paymentMethod === /* Boleto */2 ? React.createElement(React.Fragment, undefined, React.createElement(ChargeUpsertForm.Field.make, {
                      field: /* InterestDate */21,
                      render: (function (param) {
                          var value = param.value;
                          var date = IsValid(new Date(value)) ? new Date(value) : todayDate;
                          return React.createElement(DateField$VannaFrontend.make, {
                                      label: "Data de juros padrão",
                                      value: Format(date, "yyyy-MM-dd"),
                                      onChange: param.handleChange,
                                      error: param.error,
                                      required: true
                                    });
                        })
                    }), React.createElement(ChargeUpsertForm.Field.make, {
                      field: /* InterestInstruction */20,
                      render: (function (param) {
                          var handleChange = param.handleChange;
                          return React.createElement(FineAndInterestInstructionSelectField.make, {
                                      label: "Tipo dos juros",
                                      error: param.error,
                                      required: true,
                                      value: param.value,
                                      onChange: (function (param) {
                                          Curry._1(handleChange, param.value);
                                          return $$Promise.resolved(undefined);
                                        }),
                                      options: [
                                        {
                                          label: "Valor fixo",
                                          value: /* FixedAmount */0
                                        },
                                        {
                                          label: "Percentual",
                                          value: /* PercentageAmount */1
                                        }
                                      ]
                                    });
                        })
                    })) : null, match$11 ? React.createElement(ChargeUpsertForm.Field.make, {
                  field: /* InterestAmountPercentage */23,
                  render: (function (param) {
                      return React.createElement(NumberField$VannaFrontend.make, {
                                  label: "Valor dos juros (%)",
                                  error: param.error,
                                  required: false,
                                  value: param.value,
                                  onChange: param.handleChange,
                                  min: "1",
                                  max: "100"
                                });
                    })
                }) : React.createElement(ChargeUpsertForm.Field.make, {
                  field: /* InterestAmountFixed */22,
                  render: (function (param) {
                      return React.createElement(MoneyField$VannaFrontend.make, {
                                  label: "Valor dos juros (R$)",
                                  error: param.error,
                                  required: false,
                                  value: param.value,
                                  onChange: param.handleChange
                                });
                    })
                }));
    } else {
      tmp$4 = null;
    }
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(ChargeUpsertForm.Field.make, {
              field: /* HasFine */9,
              render: (function (param) {
                  var handleChange = param.handleChange;
                  return React.createElement(BooleanSelectField.make, {
                              label: "Definir multa?",
                              error: param.error,
                              required: true,
                              value: param.value,
                              onChange: (function (param) {
                                  Curry._1(handleChange, param.value);
                                  return $$Promise.resolved(undefined);
                                }),
                              options: [
                                {
                                  label: "Sim",
                                  value: true
                                },
                                {
                                  label: "Não",
                                  value: false
                                }
                              ]
                            });
                })
            }), tmp$2, React.createElement(ChargeUpsertForm.Field.make, {
              field: /* HasDiscount */14,
              render: (function (param) {
                  var handleChange = param.handleChange;
                  return React.createElement(BooleanSelectField.make, {
                              label: "Definir desconto?",
                              error: param.error,
                              required: true,
                              value: param.value,
                              onChange: (function (param) {
                                  Curry._1(handleChange, param.value);
                                  return $$Promise.resolved(undefined);
                                }),
                              options: [
                                {
                                  label: "Sim",
                                  value: true
                                },
                                {
                                  label: "Não",
                                  value: false
                                }
                              ]
                            });
                })
            }), tmp$3, React.createElement(ChargeUpsertForm.Field.make, {
              field: /* HasInterest */19,
              render: (function (param) {
                  var handleChange = param.handleChange;
                  return React.createElement(BooleanSelectField.make, {
                              label: "Definir juros?",
                              error: param.error,
                              required: true,
                              value: param.value,
                              onChange: (function (param) {
                                  Curry._1(handleChange, param.value);
                                  return $$Promise.resolved(undefined);
                                }),
                              options: [
                                {
                                  label: "Sim",
                                  value: true
                                },
                                {
                                  label: "Não",
                                  value: false
                                }
                              ]
                            });
                })
            }), tmp$4);
  } else {
    tmp$1 = null;
  }
  var match$12 = Belt_Array.getBy(customers, (function (customer) {
          return customer.id === reform.state.values.customerId;
        }));
  var match$13 = reform.state.values.paymentMethod;
  var tmp$5;
  if (match$4[0]) {
    tmp$5 = "Calculando custo da transação...";
  } else {
    var match$14 = reform.state.values.paymentMethod;
    switch (match$14) {
      case /* CreditCard */0 :
          tmp$5 = React.createElement(React.Fragment, undefined, React.createElement("h4", undefined, "Taxa com a bandeira Visa ou MasterCard"), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Taxa de transação: "), Money$VannaFrontend.formatInt(transactionCosts.ccVisaOrMastercard)), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Valor líquido: "), Money$VannaFrontend.format(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: chargeTotalAmount
                              }), Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: transactionCosts.ccVisaOrMastercard
                              })))), React.createElement("h4", undefined, "Taxa com a bandeira Elo ou outras"), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Taxa de transação: "), Money$VannaFrontend.formatInt(transactionCosts.ccDefault)), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Valor líquido: "), Money$VannaFrontend.format(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: chargeTotalAmount
                              }), Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: transactionCosts.ccDefault
                              })))));
          break;
      case /* Pix */1 :
          tmp$5 = React.createElement(React.Fragment, undefined, React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Taxa de transação: "), Money$VannaFrontend.format(Money$VannaFrontend.make({
                            TAG: /* Cents */1,
                            _0: transactionCosts.pix
                          }))), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Valor líquido: "), Money$VannaFrontend.formatInt(chargeTotalAmount)));
          break;
      case /* Boleto */2 :
          tmp$5 = React.createElement(React.Fragment, undefined, React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Taxa de transação: "), Money$VannaFrontend.format(Money$VannaFrontend.make({
                            TAG: /* Cents */1,
                            _0: transactionCosts.boleto
                          }))), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                    children: null
                  }, React.createElement("span", undefined, "Valor líquido: "), Money$VannaFrontend.format(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: chargeTotalAmount
                              }), Money$VannaFrontend.make({
                                TAG: /* Cents */1,
                                _0: transactionCosts.boleto
                              })))));
          break;
      
    }
  }
  var match$15 = reform.formState;
  var tmp$6;
  if (typeof match$15 === "number") {
    tmp$6 = null;
  } else {
    var error = match$15._0;
    tmp$6 = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(ChargeUpsertForm.Provider.make, Curry._3(ChargeUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(FieldGrid$VannaFrontend.make, {
                          children: null
                        }, React.createElement(ChargeUpsertForm.Field.make, {
                              field: /* CustomerId */0,
                              render: (function (param) {
                                  var handleChange = param.handleChange;
                                  return React.createElement(CustomersSelect.make, {
                                              label: "Cliente",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: (function (param) {
                                                  Curry._1(handleChange, param.value);
                                                  return $$Promise.resolved(undefined);
                                                }),
                                              disabled: Belt_Option.isSome(chargeFragment),
                                              options: Belt_Array.map(customers, (function (customer) {
                                                      return {
                                                              label: Belt_Option.getWithDefault(customer.name, ""),
                                                              value: customer.id
                                                            };
                                                    })),
                                              loadMore: (function (_search) {
                                                  return new Promise((function (resolve, param) {
                                                                Curry._3(loadNext, 20, (function (error) {
                                                                        if (error !== undefined) {
                                                                          return resolve({
                                                                                      options: [],
                                                                                      hasMore: false
                                                                                    });
                                                                        } else {
                                                                          return resolve({
                                                                                      options: Belt_Array.map(customers, (function (cur) {
                                                                                              return {
                                                                                                      label: Belt_Option.getWithDefault(cur.name, ""),
                                                                                                      value: cur.id
                                                                                                    };
                                                                                            })),
                                                                                      hasMore: hasNext
                                                                                    });
                                                                        }
                                                                      }), undefined);
                                                                
                                                              }));
                                                })
                                            });
                                })
                            }), React.createElement("div", {
                              className: thirtyPercentWidth
                            }, React.createElement(Modal$VannaFrontend.make, {
                                  title: "Adicionar cliente",
                                  trigger: (function (dispatch) {
                                      return React.createElement(Button$VannaFrontend.make, {
                                                  mode: /* Secondary */1,
                                                  onClick: (function (param) {
                                                      return Curry._1(dispatch, /* Open */2);
                                                    }),
                                                  children: null
                                                }, React.createElement(Fi.FiPlus, {}), "Adicionar cliente");
                                    }),
                                  content: (function (dispatch) {
                                      return React.createElement(CustomerUpsertForm$VannaFrontend.make, {
                                                  dispatchModal: dispatch,
                                                  onSuccess: (function (customerId) {
                                                      return Curry._4(reform.setFieldValue, /* CustomerId */0, customerId, undefined, undefined);
                                                    })
                                                });
                                    })
                                }))), React.createElement(ChargeUpsertForm.Field.make, {
                          field: /* Description */2,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Descrição",
                                          error: param.error,
                                          required: false,
                                          value: param.value,
                                          onChange: param.handleChange,
                                          disabled: Belt_Option.isSome(chargeFragment)
                                        });
                            })
                        }), React.createElement(ChargeUpsertForm.Field.make, {
                          field: /* PaymentMethod */5,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(PaymentMethodSelect.make, {
                                          label: "Método de pagamento",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          disabled: Belt_Option.isSome(chargeFragment),
                                          options: [{
                                              label: "PIX",
                                              value: /* Pix */1
                                            }]
                                        });
                            })
                        }), match$7 !== 0 ? React.createElement(ChargeUpsertForm.Field.make, {
                            field: /* DueDate */8,
                            render: (function (param) {
                                var value = param.value;
                                var date = IsValid(new Date(value)) ? new Date(value) : todayDate;
                                return React.createElement(DateField$VannaFrontend.make, {
                                            label: "Data de vencimento",
                                            value: Format(date, "yyyy-MM-dd"),
                                            onChange: param.handleChange,
                                            error: param.error,
                                            required: true
                                          });
                              })
                          }) : null, tmp$1, match$12 !== undefined && !(match$13 !== 0 || match$12.cards.length === 0) ? React.createElement(ChargeUpsertForm.Field.make, {
                            field: /* AskForConfirmation */6,
                            render: (function (param) {
                                var handleChange = param.handleChange;
                                return React.createElement(BooleanSelectField.make, {
                                            label: "Este cliente já possui um cartão cadastrado. Você deseja enviar uma solicitação de confirmação de pagamento?",
                                            error: param.error,
                                            required: true,
                                            value: param.value,
                                            onChange: (function (param) {
                                                Curry._1(handleChange, param.value);
                                                return $$Promise.resolved(undefined);
                                              }),
                                            disabled: Belt_Option.isSome(chargeFragment),
                                            options: [
                                              {
                                                label: "Sim",
                                                value: true
                                              },
                                              {
                                                label: "Não",
                                                value: false
                                              }
                                            ]
                                          });
                              })
                          }) : null, React.createElement(ChargeUpsertForm.Field.make, {
                          field: /* Items */7,
                          render: (function (param) {
                              var tmp;
                              tmp = selectedItem !== undefined && selectedItem.TAG === /* Product */0 ? true : false;
                              var tmp$1;
                              tmp$1 = selectedItem !== undefined && selectedItem.TAG !== /* Product */0 ? true : false;
                              return React.createElement(React.Fragment, undefined, React.createElement(ItemsTable$VannaFrontend.make, {
                                              title: "Produtos",
                                              data: Belt_Array.reduce(reform.state.values.items, [], (function (acc, item) {
                                                      if (item.TAG === /* Product */0) {
                                                        return Belt_Array.concat(acc, [item._0]);
                                                      } else {
                                                        return acc;
                                                      }
                                                    })),
                                              onRemoveItem: (function (itemTemporaryId) {
                                                  var rowIndex = Belt_Array.getIndexBy(reform.state.values.items, (function (item) {
                                                          if (item.TAG === /* Product */0) {
                                                            return item._0.temporaryId === itemTemporaryId;
                                                          } else {
                                                            return false;
                                                          }
                                                        }));
                                                  if (rowIndex !== undefined) {
                                                    return Curry._2(reform.arrayRemoveByIndex, /* Items */7, rowIndex);
                                                  }
                                                  
                                                }),
                                              onEditItem: (function (item) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                return {
                                                                        TAG: /* Product */0,
                                                                        _0: item
                                                                      };
                                                              }));
                                                }),
                                              readOnly: Belt_Option.isSome(chargeFragment),
                                              domain: /* Charge */0
                                            }), React.createElement(Modal$VannaFrontend.make, {
                                              title: "Atualizar produto",
                                              trigger: (function (param) {
                                                  return null;
                                                }),
                                              controlledOpen: tmp,
                                              content: (function (dispatch) {
                                                  return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                              children: React.createElement(ItemTableUpsertProductForm$VannaFrontend.make, {
                                                                    domain: /* Charge */0,
                                                                    item: selectedItem !== undefined ? selectedItem : undefined,
                                                                    dispatchModal: dispatch,
                                                                    onUpsert: (function (item) {
                                                                        Curry._1(setSelectedItem, (function (param) {
                                                                                
                                                                              }));
                                                                        if (item.TAG === /* Insert */0) {
                                                                          return ;
                                                                        }
                                                                        var item$1 = item._0;
                                                                        var index = Belt_Array.getIndexBy(reform.state.values.items, (function (cur) {
                                                                                if (cur.TAG === /* Product */0) {
                                                                                  return item$1.temporaryId === cur._0.temporaryId;
                                                                                } else {
                                                                                  return false;
                                                                                }
                                                                              }));
                                                                        if (index !== undefined) {
                                                                          return Curry._3(reform.arrayUpdateByIndex, /* Items */7, index, {
                                                                                      TAG: /* Product */0,
                                                                                      _0: item$1
                                                                                    });
                                                                        }
                                                                        
                                                                      }),
                                                                    query: fragmentRefs
                                                                  })
                                                            });
                                                }),
                                              onClose: (function (param) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                
                                                              }));
                                                })
                                            }), Belt_Option.isNone(chargeFragment) ? React.createElement(Modal$VannaFrontend.make, {
                                                title: "Adicionar produto",
                                                trigger: (function (dispatch) {
                                                    return React.createElement(Button$VannaFrontend.make, {
                                                                mode: /* Secondary */1,
                                                                onClick: (function (param) {
                                                                    return Curry._1(dispatch, /* Open */2);
                                                                  }),
                                                                children: null
                                                              }, React.createElement(Fi.FiPlus, {}), "Adicionar produto");
                                                  }),
                                                content: (function (dispatch) {
                                                    return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                                children: React.createElement(ItemTableUpsertProductForm$VannaFrontend.make, {
                                                                      domain: /* Charge */0,
                                                                      item: undefined,
                                                                      dispatchModal: dispatch,
                                                                      onUpsert: (function (item) {
                                                                          if (item.TAG === /* Insert */0) {
                                                                            return Curry._2(reform.arrayPush, /* Items */7, {
                                                                                        TAG: /* Product */0,
                                                                                        _0: item._0
                                                                                      });
                                                                          }
                                                                          
                                                                        }),
                                                                      query: fragmentRefs
                                                                    })
                                                              });
                                                  })
                                              }) : null, React.createElement(Modal$VannaFrontend.make, {
                                              title: "Atualizar serviço",
                                              trigger: (function (param) {
                                                  return null;
                                                }),
                                              controlledOpen: tmp$1,
                                              content: (function (dispatch) {
                                                  return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                              children: React.createElement(ItemTableUpsertServiceForm$VannaFrontend.make, {
                                                                    domain: /* Charge */0,
                                                                    item: selectedItem !== undefined ? selectedItem : undefined,
                                                                    dispatchModal: dispatch,
                                                                    onUpsert: (function (item) {
                                                                        Curry._1(setSelectedItem, (function (param) {
                                                                                
                                                                              }));
                                                                        if (item.TAG === /* Insert */0) {
                                                                          return ;
                                                                        }
                                                                        var item$1 = item._0;
                                                                        var index = Belt_Array.getIndexBy(reform.state.values.items, (function (cur) {
                                                                                if (cur.TAG === /* Product */0) {
                                                                                  return false;
                                                                                } else {
                                                                                  return item$1.temporaryId === cur._0.temporaryId;
                                                                                }
                                                                              }));
                                                                        if (index !== undefined) {
                                                                          return Curry._3(reform.arrayUpdateByIndex, /* Items */7, index, {
                                                                                      TAG: /* Service */1,
                                                                                      _0: item$1
                                                                                    });
                                                                        }
                                                                        
                                                                      }),
                                                                    query: fragmentRefs
                                                                  })
                                                            });
                                                }),
                                              onClose: (function (param) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                
                                                              }));
                                                })
                                            }), React.createElement(ItemsTable$VannaFrontend.make, {
                                              title: "Serviços",
                                              data: Belt_Array.reduce(reform.state.values.items, [], (function (acc, item) {
                                                      if (item.TAG === /* Product */0) {
                                                        return acc;
                                                      } else {
                                                        return Belt_Array.concat(acc, [item._0]);
                                                      }
                                                    })),
                                              onRemoveItem: (function (itemTemporaryId) {
                                                  var rowIndex = Belt_Array.getIndexBy(reform.state.values.items, (function (item) {
                                                          if (item.TAG === /* Product */0) {
                                                            return false;
                                                          } else {
                                                            return item._0.temporaryId === itemTemporaryId;
                                                          }
                                                        }));
                                                  if (rowIndex !== undefined) {
                                                    return Curry._2(reform.arrayRemoveByIndex, /* Items */7, rowIndex);
                                                  }
                                                  
                                                }),
                                              onEditItem: (function (item) {
                                                  return Curry._1(setSelectedItem, (function (param) {
                                                                return {
                                                                        TAG: /* Service */1,
                                                                        _0: item
                                                                      };
                                                              }));
                                                }),
                                              readOnly: Belt_Option.isSome(chargeFragment),
                                              domain: /* Charge */0
                                            }), Belt_Option.isNone(chargeFragment) ? React.createElement(Modal$VannaFrontend.make, {
                                                title: "Adicionar serviço",
                                                trigger: (function (dispatch) {
                                                    return React.createElement(Button$VannaFrontend.make, {
                                                                mode: /* Secondary */1,
                                                                onClick: (function (param) {
                                                                    return Curry._1(dispatch, /* Open */2);
                                                                  }),
                                                                children: null
                                                              }, React.createElement(Fi.FiPlus, {}), "Adicionar serviço");
                                                  }),
                                                content: (function (dispatch) {
                                                    return React.createElement(SuspenseLoader$VannaFrontend.make, {
                                                                children: React.createElement(ItemTableUpsertServiceForm$VannaFrontend.make, {
                                                                      domain: /* Charge */0,
                                                                      item: undefined,
                                                                      dispatchModal: dispatch,
                                                                      onUpsert: (function (item) {
                                                                          if (item.TAG === /* Insert */0) {
                                                                            return Curry._2(reform.arrayPush, /* Items */7, {
                                                                                        TAG: /* Service */1,
                                                                                        _0: item._0
                                                                                      });
                                                                          }
                                                                          
                                                                        }),
                                                                      query: fragmentRefs
                                                                    })
                                                              });
                                                  })
                                              }) : null);
                            })
                        }), React.createElement(FieldInfo$VannaFrontend.List.make, {
                          children: React.createElement(React.Fragment, undefined, React.createElement("h4", undefined, "Simulação da cobrança de acordo com a bandeira do cartão do cliente"), React.createElement(FieldInfo$VannaFrontend.Item.make, {
                                    children: null
                                  }, React.createElement("span", undefined, "Valor bruto: "), Money$VannaFrontend.formatInt(chargeTotalAmount)), tmp$5)
                        }), tmp$6, Belt_Option.isNone(chargeFragment) ? React.createElement(Button$VannaFrontend.make, {
                            isLoading: reform.isSubmitting,
                            type_: /* Submit */1,
                            children: Belt_Option.isSome(chargeFragment) ? "Atualizar" : "Adicionar"
                          }) : null, React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, Belt_Option.isNone(chargeFragment) ? /* CloseWithConfirmation */0 : /* Close */1);
                            }),
                          children: "Fechar"
                        })), undefined));
}

var pageSize = 20;

var make = ChargeUpsertForm$1;

exports.Styles = Styles;
exports.StateLenses = StateLenses;
exports.ChargeUpsertForm = ChargeUpsertForm;
exports.ChargeFragment = ChargeFragment;
exports.pageSize = pageSize;
exports.CustomersConnectionFragment = CustomersConnectionFragment;
exports.ChargeUpsertFormQuery = ChargeUpsertFormQuery;
exports.GetTransactionCostQuery = GetTransactionCostQuery;
exports.ChargeCreateMutation = ChargeCreateMutation;
exports.PaymentMethodSelect = PaymentMethodSelect;
exports.CustomersSelect = CustomersSelect;
exports.BooleanSelectField = BooleanSelectField;
exports.FineAndInterestInstructionSelectField = FineAndInterestInstructionSelectField;
exports.DiscountInstructionSelectField = DiscountInstructionSelectField;
exports.make = make;
/* thirtyPercentWidth Not a pure module */
