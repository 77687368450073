import React, { useEffect, useState } from 'react'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

const isClient = typeof window === 'object'

function getSize() {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

const ERROR_MESSAGES = {
  emptyCardNumber: 'O número do cartão não pode estar vazio',
  invalidCardNumber: 'Número do cartão inválido',
  emptyExpiryDate: 'A data de vencimento não pode estar vazio',
  monthOutOfRange: 'O mês de vencimento deve estar entre 01 e 12',
  yearOutOfRange: 'O ano de vencimento não pode estar no passado',
  dateOutOfRange: 'A data de vencimento não pode estar no passado',
  invalidExpiryDate: 'A data de vencimento é inválida',
  emptyCVC: 'O CVC não pode estar vazio',
  invalidCVC: 'CVC inválido',
}

function extractValueFromEvent(fn) {
  return (event) => fn(event.target.value)
}

export default ({
  handleChangeCardNumber,
  handleChangeExpiryDate,
  handleChangeCvc,
  setCardIsInvalid,
  values = {},
}) => {
  const size = useWindowSize()

  let defaults = {
    cardNumber: '',
    cardExpirationDate: '',
    cardCvc: '',
    ...values,
  }

  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
    wrapperProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
    onError: (error, erroredInputs) => {
      if (erroredInputs.cardNumber) {
        setCardIsInvalid(erroredInputs.cardNumber)
      } else if (erroredInputs.expiryDate) {
        setCardIsInvalid(erroredInputs.expiryDate)
      } else if (erroredInputs.cvc) {
        setCardIsInvalid(erroredInputs.cvc)
      } else {
        setCardIsInvalid(undefined)
      }
    },
  })

  let inputs = [
    <input
      {...getCardNumberProps({ onChange: extractValueFromEvent(handleChangeCardNumber) })}
      placeholder='4242 4242 4242 4242'
      defaultValue={defaults.cardNumber}
      key='cardNumber'
    />,
    <input
      {...getExpiryDateProps({ onChange: extractValueFromEvent(handleChangeExpiryDate) })}
      placeholder='MM/AA'
      defaultValue={defaults.cardExpirationDate}
      key='cardExpirationDate'
    />,
    <input
      {...getCVCProps({ onChange: extractValueFromEvent(handleChangeCvc) })}
      placeholder='CVC'
      defaultValue={defaults.cardCvc}
      key='cardCvc'
    />,
  ]

  return size.width <= 480 ? (
    <div style={{
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap"
    }}>{inputs}</div>
  ) : (
      <PaymentInputsWrapper
        {...wrapperProps}
        styles={{
          fieldWrapper: {
            base: {
              width: "100%",
              height: "2.5rem"
            },
          },
          inputWrapper: {
            base: {
              boxShadow: 'none',
            },
            errored: {
              boxShadow: 'none',
            },
            focused: {
              boxShadow: 'none',
            },
          },
          input: {
            base: {
              fontSize: "0.85rem"
            },
            cardNumber: {
              flexGrow: 4,
            },
            expiryDate: {
              flexGrow: 1.2,
            },
            cvc: {
              flexGrow: 1,
            },
          },
        }}
      >
        <svg {...getCardImageProps({ images })} />
        {inputs}
      </PaymentInputsWrapper>
    )
}