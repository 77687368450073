'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var ReactHelmet = require("react-helmet");
var Ai = require("react-icons/ai");
var Bs = require("react-icons/bs");
var Cg = require("react-icons/cg");
var Fa = require("react-icons/fa");
var Fi = require("react-icons/fi");
var Gi = require("react-icons/gi");
var Io = require("react-icons/io");
var Md = require("react-icons/md");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.bs.js");
var Hooks = require("react-relay/hooks");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Navbar$VannaFrontend = require("./Navbar.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var SideMenu$VannaFrontend = require("./SideMenu.bs.js");
var Authorization$VannaFrontend = require("../types/Authorization.bs.js");
var SuspenseLoader$VannaFrontend = require("./SuspenseLoader.bs.js");
var PrivateLayoutQuery_graphql$VannaFrontend = require("../__generated__/PrivateLayoutQuery_graphql.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

var privateLayoutWrapper = Curry._1(Css.style, {
      hd: Css.boxSizing("borderBox"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.overflow("hidden"),
          tl: {
            hd: Css.width({
                  NAME: "percent",
                  VAL: 100
                }),
            tl: {
              hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                    hd: Css.flexDirection("column"),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.media("(min-width: " + mediumBreakpoint + ")", {
                      hd: Css.maxHeight({
                            NAME: "vh",
                            VAL: 100
                          }),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var componentWrapper = Curry._1(Css.style, {
      hd: Css.boxSizing("borderBox"),
      tl: {
        hd: Css.minHeight({
              NAME: "vh",
              VAL: 100
            }),
        tl: {
          hd: Css.padding2({
                NAME: "pxFloat",
                VAL: 17.5
              }, {
                NAME: "rem",
                VAL: 1.5
              }),
          tl: {
            hd: Css.overflowY("auto"),
            tl: {
              hd: Css.width({
                    NAME: "percent",
                    VAL: 100
                  }),
              tl: {
                hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                      hd: Css.padding2({
                            NAME: "rem",
                            VAL: 2.0
                          }, {
                            NAME: "rem",
                            VAL: 1.0
                          }),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var navigationSplitter = Curry._1(Css.style, {
      hd: Css.margin2({
            NAME: "px",
            VAL: 10
          }, {
            NAME: "px",
            VAL: 5
          }),
      tl: {
        hd: Css.borderBottom({
              NAME: "px",
              VAL: 1
            }, "solid", Theme$VannaFrontend.Colors.border),
        tl: /* [] */0
      }
    });

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  privateLayoutWrapper: privateLayoutWrapper,
  componentWrapper: componentWrapper,
  navigationSplitter: navigationSplitter
};

var defaultItems_0 = {
  TAG: /* Route */0,
  _0: {
    label: "Dashboard",
    route: /* Dashboard */3,
    icon: React.createElement(Md.MdDashboard, {})
  }
};

var defaultItems_1 = {
  hd: {
    TAG: /* Route */0,
    _0: {
      label: "Conta PIX",
      route: /* Pix */4,
      icon: React.createElement(Bs.BsListNested, {})
    }
  },
  tl: {
    hd: {
      TAG: /* Route */0,
      _0: {
        label: "Assinaturas",
        route: /* RecurrentCharges */5,
        icon: React.createElement(Md.MdRepeat, {})
      }
    },
    tl: {
      hd: {
        TAG: /* Route */0,
        _0: {
          label: "Vendas",
          route: /* Charges */6,
          icon: React.createElement(Md.MdAttachMoney, {})
        }
      },
      tl: {
        hd: {
          TAG: /* Route */0,
          _0: {
            label: "Clientes",
            route: /* Customers */7,
            icon: React.createElement(Io.IoIosPeople, {})
          }
        },
        tl: {
          hd: {
            TAG: /* Route */0,
            _0: {
              label: "Produtos",
              route: /* Products */8,
              icon: React.createElement(Fi.FiShoppingBag, {})
            }
          },
          tl: {
            hd: {
              TAG: /* Route */0,
              _0: {
                label: "Serviços",
                route: /* Services */9,
                icon: React.createElement(Cg.CgWorkAlt, {})
              }
            },
            tl: {
              hd: {
                TAG: /* Route */0,
                _0: {
                  label: "Webhooks",
                  route: /* Webhooks */12,
                  icon: React.createElement(Gi.GiMeatHook, {})
                }
              },
              tl: {
                hd: {
                  TAG: /* Route */0,
                  _0: {
                    label: "API",
                    route: /* API */13,
                    icon: React.createElement(Ai.AiFillApi, {})
                  }
                },
                tl: {
                  hd: {
                    TAG: /* Route */0,
                    _0: {
                      label: "Vendedores",
                      route: {
                        TAG: /* SellersAndReferrals */7,
                        _0: "vendedores"
                      },
                      icon: React.createElement(Fa.FaUsers, {})
                    }
                  },
                  tl: {
                    hd: {
                      TAG: /* Route */0,
                      _0: {
                        label: "Configurações",
                        route: {
                          TAG: /* Settings */8,
                          _0: "dados-cadastrais"
                        },
                        icon: React.createElement(Fi.FiSettings, {})
                      }
                    },
                    tl: {
                      hd: /* Divider */0,
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var defaultItems = {
  hd: defaultItems_0,
  tl: defaultItems_1
};

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(PrivateLayoutQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(PrivateLayoutQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(PrivateLayoutQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(PrivateLayoutQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, PrivateLayoutQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, PrivateLayoutQuery_graphql$VannaFrontend.node, PrivateLayoutQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: PrivateLayoutQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, PrivateLayoutQuery_graphql$VannaFrontend.node, PrivateLayoutQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return PrivateLayoutQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(PrivateLayoutQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(PrivateLayoutQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var PrivateLayoutQuery = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

function PrivateLayout(Props) {
  var $$navigator = Props.navigator;
  var children = Props.children;
  var url = ReasonReactRouter.useUrl(undefined, undefined);
  var queryData = use(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var items = List.concat({
        hd: defaultItems,
        tl: {
          hd: {
            hd: {
              TAG: /* Action */1,
              _0: {
                label: "Sair",
                action: (function (param) {
                    Authorization$VannaFrontend.signOut(undefined);
                    return Curry._1($$navigator.replace, /* Login */0);
                  }),
                icon: React.createElement(Fi.FiLogOut, {})
              }
            },
            tl: /* [] */0
          },
          tl: /* [] */0
        }
      });
  var me = queryData.me;
  if (me === undefined) {
    return null;
  }
  var title = me.settings.whitelabelInfo.title;
  var iconSrc = me.settings.whitelabelInfo.iconSrc;
  return React.createElement("div", {
              className: privateLayoutWrapper
            }, React.createElement(ReactHelmet.Helmet, {
                  children: null
                }, title !== undefined ? (
                    title !== "" ? React.createElement("title", undefined, title) : React.createElement("title", undefined, "Vanna - A plataforma de cobranças feita para seu negócio")
                  ) : React.createElement("title", undefined, "Vanna - A plataforma de cobranças feita para seu negócio"), iconSrc !== undefined && iconSrc !== "" ? React.createElement("link", {
                        href: iconSrc,
                        rel: "shortcut icon"
                      }) : null), React.createElement(Navbar$VannaFrontend.make, {
                  query: me.settings.whitelabelInfo.fragmentRefs,
                  items: Belt_List.map(items, (function (item) {
                          if (typeof item === "number") {
                            return React.createElement("div", {
                                        className: navigationSplitter
                                      });
                          }
                          if (item.TAG === /* Route */0) {
                            var match = item._0;
                            var route = match.route;
                            var label = match.label;
                            return React.createElement(Navbar$VannaFrontend.NavbarItem.make, {
                                        label: label,
                                        route: route,
                                        onClick: (function (param) {
                                            return Curry._1($$navigator.push, route);
                                          }),
                                        url: url,
                                        key: "__navbar__item__" + label
                                      });
                          }
                          var match$1 = item._0;
                          var action = match$1.action;
                          return React.createElement(Navbar$VannaFrontend.NavbarItem.make, {
                                      label: match$1.label,
                                      onClick: (function (param) {
                                          return Curry._1(action, undefined);
                                        }),
                                      url: url
                                    });
                        }))
                }), React.createElement(SideMenu$VannaFrontend.make, {
                  query: me.settings.whitelabelInfo.fragmentRefs,
                  navigator: $$navigator,
                  items: Belt_List.mapWithIndex(items, (function (index, item) {
                          if (typeof item === "number") {
                            return React.createElement("div", {
                                        key: "__sidemenu__divider__" + String(index),
                                        className: navigationSplitter
                                      });
                          }
                          if (item.TAG === /* Route */0) {
                            var match = item._0;
                            var route = match.route;
                            var label = match.label;
                            return React.createElement(SideMenu$VannaFrontend.SideMenuItem.make, {
                                        onClick: (function (param) {
                                            return Curry._1($$navigator.push, route);
                                          }),
                                        route: route,
                                        url: url,
                                        icon: match.icon,
                                        label: label,
                                        key: "__sidemenu__item__" + label
                                      });
                          }
                          var match$1 = item._0;
                          var action = match$1.action;
                          var label$1 = match$1.label;
                          return React.createElement(SideMenu$VannaFrontend.SideMenuItem.make, {
                                      onClick: (function (param) {
                                          return Curry._1(action, undefined);
                                        }),
                                      url: url,
                                      icon: match$1.icon,
                                      label: label$1,
                                      key: "__sidemenu__item__" + label$1
                                    });
                        }))
                }), React.createElement("div", {
                  className: componentWrapper
                }, React.createElement(SuspenseLoader$VannaFrontend.make, {
                      children: children
                    })));
}

var make = PrivateLayout;

exports.Styles = Styles;
exports.defaultItems = defaultItems;
exports.PrivateLayoutQuery = PrivateLayoutQuery;
exports.make = make;
/* privateLayoutWrapper Not a pure module */
