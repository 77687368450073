'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fi = require("react-icons/fi");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var SuspenseLoader$VannaFrontend = require("./SuspenseLoader.bs.js");
var ChargeUpsertForm$VannaFrontend = require("./ChargeUpsertForm.bs.js");
var SelfCheckoutUpsert$VannaFrontend = require("./SelfCheckoutUpsert.bs.js");
var RecurrentChargeUpsertForm$VannaFrontend = require("./RecurrentChargeUpsertForm.bs.js");
var DashboardShortcutsFragment_user_graphql$VannaFrontend = require("../__generated__/DashboardShortcutsFragment_user_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.padding(Theme$VannaFrontend.Padding.content),
      tl: /* [] */0
    });

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.marginTop({
                NAME: "px",
                VAL: 10
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  panel: panel,
  buttons: buttons
};

function shortcuts(query) {
  return [
          {
            renderModal: (function (dispatch) {
                return React.createElement(SuspenseLoader$VannaFrontend.make, {
                            children: React.createElement(RecurrentChargeUpsertForm$VannaFrontend.make, {
                                  recurrentCharge: undefined,
                                  dispatchModal: dispatch,
                                  query: query
                                })
                          });
              }),
            displayName: "assinatura"
          },
          {
            renderModal: (function (dispatch) {
                return React.createElement(SuspenseLoader$VannaFrontend.make, {
                            children: React.createElement(ChargeUpsertForm$VannaFrontend.make, {
                                  charge: undefined,
                                  dispatchModal: dispatch,
                                  query: query
                                })
                          });
              }),
            displayName: "venda"
          },
          {
            renderModal: (function (dispatch) {
                return React.createElement(SuspenseLoader$VannaFrontend.make, {
                            children: React.createElement(SelfCheckoutUpsert$VannaFrontend.make, {
                                  selfCheckout: undefined,
                                  me: query,
                                  dispatchModal: dispatch
                                })
                          });
              }),
            displayName: "link de pagamento"
          }
        ];
}

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(DashboardShortcutsFragment_user_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(DashboardShortcutsFragment_user_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(DashboardShortcutsFragment_user_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return DashboardShortcutsFragment_user_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var DashboardShortcutsFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function DashboardShortcuts(Props) {
  var query = Props.query;
  var queryData = use(query);
  var match = queryData.settings.companyInfo.taxId;
  if (match !== undefined) {
    return React.createElement(Panel$VannaFrontend.make, {
                className: panel,
                children: null
              }, React.createElement(Panel$VannaFrontend.PanelTitle.make, {
                    children: "Atalhos"
                  }), React.createElement("div", {
                    className: buttons
                  }, Belt_Array.map(shortcuts(query), (function (param) {
                          var displayName = param.displayName;
                          return React.createElement(Modal$VannaFrontend.make, {
                                      title: "Adicionar " + displayName,
                                      trigger: (function (dispatch) {
                                          return React.createElement(Button$VannaFrontend.make, {
                                                      marginLeft: true,
                                                      fullWidth: false,
                                                      onClick: (function (param) {
                                                          return Curry._1(dispatch, /* Open */2);
                                                        }),
                                                      children: null
                                                    }, React.createElement(Fi.FiPlus, {}), React.createElement("span", undefined, "Adicionar " + displayName));
                                        }),
                                      content: param.renderModal,
                                      key: "__SHORTCUT__ITEM__" + displayName
                                    });
                        }))));
  } else {
    return null;
  }
}

var make = DashboardShortcuts;

exports.Styles = Styles;
exports.shortcuts = shortcuts;
exports.DashboardShortcutsFragment = DashboardShortcutsFragment;
exports.make = make;
/* panel Not a pure module */
