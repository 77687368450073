'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Hooks = require("react-relay/hooks");
var Tabs$VannaFrontend = require("../components/Tabs.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var UserSellersTable$VannaFrontend = require("../components/UserSellersTable.bs.js");
var PageQueryRenderer$VannaFrontend = require("../components/PageQueryRenderer.bs.js");
var UserReferralsTable$VannaFrontend = require("../components/UserReferralsTable.bs.js");
var UserReferralUpsertForm$VannaFrontend = require("../components/UserReferralUpsertForm.bs.js");
var SellersAndReferralsQuery_graphql$VannaFrontend = require("../__generated__/SellersAndReferralsQuery_graphql.bs.js");

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(SellersAndReferralsQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(SellersAndReferralsQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(SellersAndReferralsQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(SellersAndReferralsQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, SellersAndReferralsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, SellersAndReferralsQuery_graphql$VannaFrontend.node, SellersAndReferralsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: SellersAndReferralsQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, SellersAndReferralsQuery_graphql$VannaFrontend.node, SellersAndReferralsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return SellersAndReferralsQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(SellersAndReferralsQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(SellersAndReferralsQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var SellersAndReferralsQuery = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

function SellersAndReferrals$Inner(Props) {
  var $$navigator = Props.navigator;
  var queryRef = Props.query;
  var defaultTab = Props.defaultTab;
  return React.createElement(React.Fragment, undefined, React.createElement(PageHeader$VannaFrontend.make, {
                  title: "Vendedores e indicações",
                  withMarginBottom: false,
                  upsertModal: {
                    renderModal: (function (dispatch) {
                        return React.createElement(UserReferralUpsertForm$VannaFrontend.make, {
                                    dispatchModal: dispatch,
                                    onSuccess: (function (referral) {
                                        console.log(referral);
                                        return Curry._1($$navigator.push, {
                                                    TAG: /* SellersAndReferrals */7,
                                                    _0: "links-indicacoes"
                                                  });
                                      })
                                  });
                      }),
                    displayName: "link para indicação"
                  }
                }), React.createElement(Tabs$VannaFrontend.make, {
                  defaultTab: defaultTab,
                  list_: [
                    {
                      name: "vendedores",
                      title: "Vendedores",
                      disabled: false,
                      badge: null,
                      body: React.createElement(UserSellersTable$VannaFrontend.make, {
                            navigator: $$navigator,
                            query: queryRef
                          })
                    },
                    {
                      name: "links-indicacoes",
                      title: "Links para indicações",
                      disabled: false,
                      badge: null,
                      body: React.createElement(UserReferralsTable$VannaFrontend.make, {
                            navigator: $$navigator,
                            query: queryRef
                          })
                    }
                  ],
                  onChange: (function (tabName) {
                      return Curry._1($$navigator.push, {
                                  TAG: /* SellersAndReferrals */7,
                                  _0: tabName
                                });
                    }),
                  withPadding: false
                }));
}

var Inner = {
  make: SellersAndReferrals$Inner
};

function SellersAndReferrals(Props) {
  var $$navigator = Props.navigator;
  var defaultTab = Props.defaultTab;
  var queryData = use(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var me = queryData.me;
  if (me !== undefined) {
    return React.createElement(PageQueryRenderer$VannaFrontend.make, {
                children: React.createElement(PrivateLayout$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(SellersAndReferrals$Inner, {
                            navigator: $$navigator,
                            query: me.fragmentRefs,
                            defaultTab: defaultTab
                          })
                    })
              });
  } else {
    return null;
  }
}

var make = SellersAndReferrals;

exports.SellersAndReferralsQuery = SellersAndReferralsQuery;
exports.Inner = Inner;
exports.make = make;
/* react Not a pure module */
