'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Fi = require("react-icons/fi");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Title$VannaFrontend = require("./Title.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var Header$VannaFrontend = require("./Header.bs.js");

var header = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "px",
            VAL: 41
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  header: header
};

function PageHeader(Props) {
  var title = Props.title;
  var withMarginBottomOpt = Props.withMarginBottom;
  var upsertModal = Props.upsertModal;
  var withMarginBottom = withMarginBottomOpt !== undefined ? withMarginBottomOpt : true;
  var tmp;
  if (upsertModal !== undefined) {
    var displayName = upsertModal.displayName;
    tmp = React.createElement(Modal$VannaFrontend.make, {
          title: title,
          trigger: (function (dispatch) {
              return React.createElement(Button$VannaFrontend.make, {
                          marginLeft: true,
                          fullWidth: false,
                          onClick: (function (param) {
                              return Curry._1(dispatch, /* Open */2);
                            }),
                          children: null
                        }, React.createElement(Fi.FiPlus, {}), React.createElement("span", undefined, "Adicionar " + displayName));
            }),
          content: upsertModal.renderModal
        });
  } else {
    tmp = null;
  }
  return React.createElement(Header$VannaFrontend.make, {
              withMarginBottom: withMarginBottom,
              className: header,
              children: null
            }, React.createElement(Title$VannaFrontend.make, {
                  children: title
                }), tmp);
}

var make = PageHeader;

exports.Styles = Styles;
exports.make = make;
/* header Not a pure module */
