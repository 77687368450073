'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Fi = require("react-icons/fi");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("../components/Modal.bs.js");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var Table$VannaFrontend = require("../components/Table.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var IconButton$VannaFrontend = require("../components/IconButton.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var DeleteModal$VannaFrontend = require("../components/DeleteModal.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var WebhookUpsertForm$VannaFrontend = require("../components/WebhookUpsertForm.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var WebhooksQuery_graphql$VannaFrontend = require("../__generated__/WebhooksQuery_graphql.bs.js");
var WebhooksRefetchQuery_graphql$VannaFrontend = require("../__generated__/WebhooksRefetchQuery_graphql.bs.js");
var WebhooksConnection_query_graphql$VannaFrontend = require("../__generated__/WebhooksConnection_query_graphql.bs.js");
var Webhooks_DeleteWebhookMutation_graphql$VannaFrontend = require("../__generated__/Webhooks_DeleteWebhookMutation_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.width("auto"),
      tl: /* [] */0
    });

var Styles = {
  panel: panel
};

var getConnectionNodes = WebhooksConnection_query_graphql$VannaFrontend.Utils.getConnectionNodes;

var Internal = {};

function internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = ReasonRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = ReasonRelay.mapRenderPolicy(renderPolicy);
  if (tmp$2 !== undefined) {
    tmp.UNSTABLE_renderPolicy = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$3 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var InternalRefetch = {
  internal_makeRefetchableFnOpts: internal_makeRefetchableFnOpts
};

function useRefetchable(fRef) {
  var match = Hooks.useRefetchableFragment(WebhooksConnection_query_graphql$VannaFrontend.node, fRef);
  var refetchFn = match[1];
  var data = ReasonRelay_Internal.internal_useConvertedValue(WebhooksConnection_query_graphql$VannaFrontend.Internal.convertFragment, match[0]);
  return [
          data,
          (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return Curry._2(refetchFn, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(WebhooksRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        ];
}

function use(fRef) {
  var data = Hooks.useFragment(WebhooksConnection_query_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(WebhooksConnection_query_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(WebhooksConnection_query_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return WebhooksConnection_query_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function usePagination(fr) {
  var p = Hooks.usePaginationFragment(WebhooksConnection_query_graphql$VannaFrontend.node, fr);
  var data = ReasonRelay_Internal.internal_useConvertedValue(WebhooksConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(WebhooksRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

function useBlockingPagination(fRef) {
  var p = Hooks.useBlockingPaginationFragment(WebhooksConnection_query_graphql$VannaFrontend.node, fRef);
  var data = ReasonRelay_Internal.internal_useConvertedValue(WebhooksConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(WebhooksRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

var makeRefetchVariables = WebhooksRefetchQuery_graphql$VannaFrontend.Types.makeRefetchVariables;

var WebhooksConnectionFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Internal: Internal,
  InternalRefetch: InternalRefetch,
  useRefetchable: useRefetchable,
  use: use,
  useOpt: useOpt,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination,
  makeRefetchVariables: makeRefetchVariables
};

var Internal$1 = {};

function use$1(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(WebhooksQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(WebhooksQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(WebhooksQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(WebhooksQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, WebhooksQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, WebhooksQuery_graphql$VannaFrontend.node, WebhooksQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: WebhooksQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, WebhooksQuery_graphql$VannaFrontend.node, WebhooksQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return WebhooksQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(WebhooksQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(WebhooksQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var WebhooksQuery = {
  Types: undefined,
  Internal: Internal$1,
  use: use$1,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var Internal$2 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.node,
              variables: Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var DeleteWebhookMutation_make_webhookSubscriptionDeleteInput = Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Utils.make_webhookSubscriptionDeleteInput;

var DeleteWebhookMutation_makeVariables = Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Utils.makeVariables;

var DeleteWebhookMutation_make_response_webhookSubscriptionDelete_error = Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Utils.make_response_webhookSubscriptionDelete_error;

var DeleteWebhookMutation_make_response_webhookSubscriptionDelete = Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Utils.make_response_webhookSubscriptionDelete;

var DeleteWebhookMutation_makeOptimisticResponse = Webhooks_DeleteWebhookMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var DeleteWebhookMutation = {
  make_webhookSubscriptionDeleteInput: DeleteWebhookMutation_make_webhookSubscriptionDeleteInput,
  makeVariables: DeleteWebhookMutation_makeVariables,
  make_response_webhookSubscriptionDelete_error: DeleteWebhookMutation_make_response_webhookSubscriptionDelete_error,
  make_response_webhookSubscriptionDelete: DeleteWebhookMutation_make_response_webhookSubscriptionDelete,
  makeOptimisticResponse: DeleteWebhookMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation,
  use: use$2
};

var WebhooksTable = Table$VannaFrontend.Make({});

function Webhooks$WebhooksPage(Props) {
  var $$navigator = Props.navigator;
  var queryRef = Props.query;
  var match = React.useState(function () {
        
      });
  var setDeleteError = match[1];
  var deleteError = match[0];
  var match$1 = use$2(undefined);
  var isDelettingWebhook = match$1[1];
  var webhookDelete = match$1[0];
  var match$2 = usePagination(queryRef);
  var loadNext = match$2.loadNext;
  var webhooks = Curry._1(getConnectionNodes, match$2.data.webhookSubscriptions);
  return React.createElement(PrivateLayout$VannaFrontend.make, {
              navigator: $$navigator,
              children: null
            }, React.createElement(PageHeader$VannaFrontend.make, {
                  title: "Webhooks",
                  upsertModal: {
                    renderModal: (function (dispatch) {
                        return React.createElement(WebhookUpsertForm$VannaFrontend.make, {
                                    dispatchModal: dispatch
                                  });
                      }),
                    displayName: "webhook"
                  }
                }), React.createElement(Panel$VannaFrontend.make, {
                  className: panel,
                  children: React.createElement(WebhooksTable.make, {
                        data: webhooks,
                        columns: {
                          hd: Table$VannaFrontend.column("Descrição", undefined, (function (webhook, param) {
                                  return Belt_Option.getWithDefault(webhook.description, "-");
                                })),
                          tl: {
                            hd: Table$VannaFrontend.column("URL", undefined, (function (webhook, param) {
                                    return webhook.url;
                                  })),
                            tl: {
                              hd: Table$VannaFrontend.column("Eventos", undefined, (function (webhook, param) {
                                      return Belt_Array.map(webhook.events, (function ($$event) {
                                                      if ($$event === "CHARGE_CANCELED") {
                                                        return "Cobrança cancelada";
                                                      }
                                                      if ($$event === "RECURRENT_CHARGE_RESUMED") {
                                                        return "Cobrança recorrente reiniciada";
                                                      }
                                                      if ($$event === "CHARGE_REJECTED") {
                                                        return "Cobrança rejeitada";
                                                      }
                                                      if ($$event === "CHARGE_CONFIRMED") {
                                                        return "Cobrança confirmada";
                                                      }
                                                      if ($$event === "RECURRENT_CHARGE_CANCELED") {
                                                        return "Cobrança recorrente cancelada";
                                                      }
                                                      if ($$event === "CHARGE_SOURCE_ADDED") {
                                                        return "Cobrança cartão de crédito adicionado";
                                                      }
                                                      if ($$event === "CHARGE_CREATED") {
                                                        return "Cobrança criada";
                                                      }
                                                      if ($$event === "CHARGE_REFUNDED") {
                                                        return "Cobrança estornada";
                                                      }
                                                      if ($$event === "CHARGE_DELETED") {
                                                        return "Cobrança deletada";
                                                      }
                                                      if ($$event === "CHARGE_UPDATED") {
                                                        return "Cobrança atualizada";
                                                      }
                                                      if ($$event === "RECURRENT_CHARGE_PAUSED") {
                                                        return "Cobrança recorrente pausada";
                                                      }
                                                      if ($$event === "CHARGE_PAID") {
                                                        return "Cobrança paga";
                                                      }
                                                      if ($$event === "RECURRENT_CHARGE_PAYMENT_FAILED") {
                                                        return "Cobrança recorrente pagamento falhou";
                                                      }
                                                      throw {
                                                            RE_EXN_ID: "Match_failure",
                                                            _1: [
                                                              "Webhooks.res",
                                                              90,
                                                              16
                                                            ],
                                                            Error: new Error()
                                                          };
                                                    })).join(", ");
                                    })),
                              tl: {
                                hd: Table$VannaFrontend.column("", undefined, (function (webhook, param) {
                                        return React.createElement(Modal$VannaFrontend.make, {
                                                    title: "Atualizar webhook",
                                                    trigger: (function (dispatch) {
                                                        return React.createElement(IconButton$VannaFrontend.make, {
                                                                    onClick: (function (param, param$1, param$2) {
                                                                        return Curry._1(dispatch, /* Open */2);
                                                                      }),
                                                                    children: React.createElement(Fi.FiEdit3, {})
                                                                  });
                                                      }),
                                                    content: (function (dispatch) {
                                                        return React.createElement(WebhookUpsertForm$VannaFrontend.make, {
                                                                    webhook: Caml_option.some(webhook.fragmentRefs),
                                                                    dispatchModal: dispatch
                                                                  });
                                                      })
                                                  });
                                      })),
                                tl: {
                                  hd: Table$VannaFrontend.column("", undefined, (function (webhook, param) {
                                          return React.createElement(DeleteModal$VannaFrontend.make, {
                                                      displayName: "webhook",
                                                      onConfirm: (function (dispatch) {
                                                          Curry._1(setDeleteError, (function (param) {
                                                                  
                                                                }));
                                                          Curry.app(webhookDelete, [
                                                                (function (param) {
                                                                    return Curry._1(setDeleteError, (function (param) {
                                                                                  return "Algo deu errado, tente novamente";
                                                                                }));
                                                                  }),
                                                                (function (response, _errors) {
                                                                    var match = response.webhookSubscriptionDelete;
                                                                    if (match === undefined) {
                                                                      return Curry._1(setDeleteError, (function (param) {
                                                                                    return "Algo deu errado, tente novamente";
                                                                                  }));
                                                                    }
                                                                    var match$1 = match.error;
                                                                    if (match$1 === undefined) {
                                                                      return Curry._1(dispatch, /* Close */1);
                                                                    }
                                                                    var message = match$1.message;
                                                                    return Curry._1(setDeleteError, (function (param) {
                                                                                  return message;
                                                                                }));
                                                                  }),
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                (function (store, response) {
                                                                    var match = response.webhookSubscriptionDelete;
                                                                    if (match === undefined) {
                                                                      return ;
                                                                    }
                                                                    if (match.error !== undefined) {
                                                                      return ;
                                                                    }
                                                                    var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                                                                    if (meProxy == null) {
                                                                      return ;
                                                                    }
                                                                    var webhooksConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "Webhooks_webhookSubscriptions", undefined);
                                                                    if (!(webhooksConnection == null)) {
                                                                      RelayRuntime.ConnectionHandler.deleteNode(webhooksConnection, webhook.id);
                                                                      return ;
                                                                    }
                                                                    
                                                                  }),
                                                                {
                                                                  input: {
                                                                    webhookSubscriptionId: webhook.id
                                                                  }
                                                                },
                                                                undefined,
                                                                undefined
                                                              ]);
                                                          
                                                        }),
                                                      onClose: (function (param) {
                                                          return Curry._1(setDeleteError, (function (param) {
                                                                        
                                                                      }));
                                                        }),
                                                      isLoading: isDelettingWebhook,
                                                      error: deleteError
                                                    });
                                        })),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        },
                        getItemId: (function (webhook) {
                            return webhook.id;
                          }),
                        hasNextPage: match$2.hasNext,
                        loadMore: (function (param) {
                            Curry._3(loadNext, 20, undefined, undefined);
                            
                          })
                      })
                }));
}

var WebhooksPage = {
  make: Webhooks$WebhooksPage
};

function Webhooks(Props) {
  var $$navigator = Props.navigator;
  var queryData = use$1(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var me = queryData.me;
  if (me !== undefined) {
    return React.createElement(Webhooks$WebhooksPage, {
                navigator: $$navigator,
                query: me.fragmentRefs
              });
  } else {
    return null;
  }
}

var pageSize = 20;

var make = Webhooks;

exports.Styles = Styles;
exports.pageSize = pageSize;
exports.WebhooksConnectionFragment = WebhooksConnectionFragment;
exports.WebhooksQuery = WebhooksQuery;
exports.DeleteWebhookMutation = DeleteWebhookMutation;
exports.WebhooksTable = WebhooksTable;
exports.WebhooksPage = WebhooksPage;
exports.make = make;
/* panel Not a pure module */
