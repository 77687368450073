'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"customerCreate_customer_address_number":{"n":""},"customerCreate_customer_name":{"n":""},"customerCreate":{"n":""},"customerCreate_error":{"n":""},"customerCreate_customer_phoneAreaCode":{"n":""},"customerCreate_customer":{"n":""},"customerCreate_customer_address":{"n":""},"customerCreate_customer_phoneNumber":{"n":""},"customerCreate_customer_taxId":{"n":""},"customerCreate_customer_address_city":{"n":""},"customerCreate_customer_address_additionalInformation":{"n":""},"customerCreate_customer_address_street":{"n":""},"customerCreate_customer_tradeName":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"customerCreate_customer_address_number":{"n":""},"customerCreate_customer_name":{"n":""},"customerCreate":{"n":""},"customerCreate_error":{"n":""},"customerCreate_customer_phoneAreaCode":{"n":""},"customerCreate_customer":{"n":""},"customerCreate_customer_address":{"n":""},"customerCreate_customer_phoneNumber":{"n":""},"customerCreate_customer_taxId":{"n":""},"customerCreate_customer_address_city":{"n":""},"customerCreate_customer_address_additionalInformation":{"n":""},"customerCreate_customer_address_street":{"n":""},"customerCreate_customer_tradeName":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"CustomerCreateInput":{"name":{"n":""},"tradeName":{"n":""},"taxId":{"n":""},"phoneAreaCode":{"n":""},"address":{"n":"","r":"Address"},"phoneNumber":{"n":""}},"__root":{"input":{"r":"CustomerCreateInput"}},"Address":{"additionalInformation":{"n":""},"number":{"n":""},"city":{"r":"City"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_city(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_address(additionalInformation, city, district, number, postalCode, state, street, param) {
  return {
          additionalInformation: additionalInformation,
          city: city,
          district: district,
          number: number,
          postalCode: postalCode,
          state: state,
          street: street
        };
}

function make_customerCreateInput(address, email, name, phoneAreaCode, phoneNumber, taxId, tradeName, typeOfPeople, param) {
  return {
          address: address,
          email: email,
          name: name,
          phoneAreaCode: phoneAreaCode,
          phoneNumber: phoneNumber,
          taxId: taxId,
          tradeName: tradeName,
          typeOfPeople: typeOfPeople
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_customerCreate_customer_address_city(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_response_customerCreate_customer_address(additionalInformation, city, district, number, postalCode, state, street, param) {
  return {
          additionalInformation: additionalInformation,
          city: city,
          district: district,
          number: number,
          postalCode: postalCode,
          state: state,
          street: street
        };
}

function make_response_customerCreate_customer(id, name, tradeName, taxId, email, typeOfPeople, phoneAreaCode, phoneNumber, address, param) {
  return {
          id: id,
          name: name,
          tradeName: tradeName,
          taxId: taxId,
          email: email,
          typeOfPeople: typeOfPeople,
          phoneAreaCode: phoneAreaCode,
          phoneNumber: phoneNumber,
          address: address
        };
}

function make_response_customerCreate_error(message) {
  return {
          message: message
        };
}

function make_response_customerCreate(error, customer, param) {
  return {
          error: error,
          customer: customer
        };
}

function makeOptimisticResponse(customerCreate, param) {
  return {
          customerCreate: customerCreate
        };
}

var Utils = {
  make_city: make_city,
  make_address: make_address,
  make_customerCreateInput: make_customerCreateInput,
  makeVariables: makeVariables,
  make_response_customerCreate_customer_address_city: make_response_customerCreate_customer_address_city,
  make_response_customerCreate_customer_address: make_response_customerCreate_customer_address,
  make_response_customerCreate_customer: make_response_customerCreate_customer,
  make_response_customerCreate_error: make_response_customerCreate_error,
  make_response_customerCreate: make_response_customerCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CustomerCreatePayload",
    "kind": "LinkedField",
    "name": "customerCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "customer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tradeName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taxId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "typeOfPeople",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneAreaCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AddressType",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "additionalInformation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CityType",
                "kind": "LinkedField",
                "name": "city",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "district",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerUpsertForm_CustomerCreateMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerUpsertForm_CustomerCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c5fbea62d3185aa4644a90ab89c19a3a",
    "id": null,
    "metadata": {},
    "name": "CustomerUpsertForm_CustomerCreateMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerUpsertForm_CustomerCreateMutation(\n  $input: CustomerCreateInput!\n) {\n  customerCreate(input: $input) {\n    error {\n      message\n    }\n    customer {\n      id\n      name\n      tradeName\n      taxId\n      email\n      typeOfPeople\n      phoneAreaCode\n      phoneNumber\n      address {\n        additionalInformation\n        city {\n          code\n          name\n        }\n        district\n        number\n        postalCode\n        state\n        street\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
