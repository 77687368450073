'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Commonjs = require("@fnando/cpf/commonjs");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var Commonjs$1 = require("@fnando/cnpj/commonjs");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var FieldSection$VannaFrontend = require("./FieldSection.bs.js");
var ZipcodeField$VannaFrontend = require("./ZipcodeField.bs.js");
var StateSelectField$VannaFrontend = require("./StateSelectField.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var SearchableSelectField$VannaFrontend = require("./SearchableSelectField.bs.js");
var CustomerUpsertForm_customer_graphql$VannaFrontend = require("../__generated__/CustomerUpsertForm_customer_graphql.bs.js");
var CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend = require("../__generated__/CustomerUpsertForm_CustomerCreateMutation_graphql.bs.js");
var CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend = require("../__generated__/CustomerUpsertForm_CustomerUpdateMutation_graphql.bs.js");

function get(state, field) {
  switch (field) {
    case /* Name */0 :
        return state.name;
    case /* TradeName */1 :
        return state.tradeName;
    case /* TaxId */2 :
        return state.taxId;
    case /* Email */3 :
        return state.email;
    case /* TypeOfPeople */4 :
        return state.typeOfPeople;
    case /* PhoneAreaCode */5 :
        return state.phoneAreaCode;
    case /* PhoneNumber */6 :
        return state.phoneNumber;
    case /* Zipcode */7 :
        return state.zipcode;
    case /* Address */8 :
        return state.address;
    case /* Number */9 :
        return state.number;
    case /* Neighborhood */10 :
        return state.neighborhood;
    case /* CityName */11 :
        return state.cityName;
    case /* CityCode */12 :
        return state.cityCode;
    case /* State */13 :
        return state.state;
    case /* Complement */14 :
        return state.complement;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Name */0 :
        return {
                name: value,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* TradeName */1 :
        return {
                name: state.name,
                tradeName: value,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* TaxId */2 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: value,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* Email */3 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: value,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* TypeOfPeople */4 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: value,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* PhoneAreaCode */5 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: value,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* PhoneNumber */6 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: value,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* Zipcode */7 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: value,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* Address */8 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: value,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* Number */9 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: value,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* Neighborhood */10 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: value,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* CityName */11 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: value,
                cityCode: state.cityCode,
                state: state.state,
                complement: state.complement
              };
    case /* CityCode */12 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: value,
                state: state.state,
                complement: state.complement
              };
    case /* State */13 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: value,
                complement: state.complement
              };
    case /* Complement */14 :
        return {
                name: state.name,
                tradeName: state.tradeName,
                taxId: state.taxId,
                email: state.email,
                typeOfPeople: state.typeOfPeople,
                phoneAreaCode: state.phoneAreaCode,
                phoneNumber: state.phoneNumber,
                zipcode: state.zipcode,
                address: state.address,
                number: state.number,
                neighborhood: state.neighborhood,
                cityName: state.cityName,
                cityCode: state.cityCode,
                state: state.state,
                complement: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var CustomerUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var TypeOfPeopleSelect = SearchableSelectField$VannaFrontend.Make({});

var twentyPercentField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 20
          }),
      tl: /* [] */0
    });

var thirtyPercent = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 33.33
          }),
      tl: /* [] */0
    });

var Styles = {
  twentyPercentField: twentyPercentField,
  thirtyPercent: thirtyPercent
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(CustomerUpsertForm_customer_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(CustomerUpsertForm_customer_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(CustomerUpsertForm_customer_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return CustomerUpsertForm_customer_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var CustomerFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.node,
              variables: CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var CustomerCreateMutation_make_city = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.make_city;

var CustomerCreateMutation_make_address = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.make_address;

var CustomerCreateMutation_make_customerCreateInput = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.make_customerCreateInput;

var CustomerCreateMutation_makeVariables = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var CustomerCreateMutation_make_response_customerCreate_customer_address_city = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.make_response_customerCreate_customer_address_city;

var CustomerCreateMutation_make_response_customerCreate_customer_address = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.make_response_customerCreate_customer_address;

var CustomerCreateMutation_make_response_customerCreate_customer = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.make_response_customerCreate_customer;

var CustomerCreateMutation_make_response_customerCreate_error = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.make_response_customerCreate_error;

var CustomerCreateMutation_make_response_customerCreate = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.make_response_customerCreate;

var CustomerCreateMutation_makeOptimisticResponse = CustomerUpsertForm_CustomerCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var CustomerCreateMutation = {
  make_city: CustomerCreateMutation_make_city,
  make_address: CustomerCreateMutation_make_address,
  make_customerCreateInput: CustomerCreateMutation_make_customerCreateInput,
  makeVariables: CustomerCreateMutation_makeVariables,
  make_response_customerCreate_customer_address_city: CustomerCreateMutation_make_response_customerCreate_customer_address_city,
  make_response_customerCreate_customer_address: CustomerCreateMutation_make_response_customerCreate_customer_address,
  make_response_customerCreate_customer: CustomerCreateMutation_make_response_customerCreate_customer,
  make_response_customerCreate_error: CustomerCreateMutation_make_response_customerCreate_error,
  make_response_customerCreate: CustomerCreateMutation_make_response_customerCreate,
  makeOptimisticResponse: CustomerCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.node,
              variables: CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var CustomerUpdateMutation_make_city = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.make_city;

var CustomerUpdateMutation_make_address = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.make_address;

var CustomerUpdateMutation_make_customerUpdateInput = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.make_customerUpdateInput;

var CustomerUpdateMutation_makeVariables = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var CustomerUpdateMutation_make_response_customerUpdate_customer_address_city = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.make_response_customerUpdate_customer_address_city;

var CustomerUpdateMutation_make_response_customerUpdate_customer_address = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.make_response_customerUpdate_customer_address;

var CustomerUpdateMutation_make_response_customerUpdate_customer = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.make_response_customerUpdate_customer;

var CustomerUpdateMutation_make_response_customerUpdate_error = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.make_response_customerUpdate_error;

var CustomerUpdateMutation_make_response_customerUpdate = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.make_response_customerUpdate;

var CustomerUpdateMutation_makeOptimisticResponse = CustomerUpsertForm_CustomerUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var CustomerUpdateMutation = {
  make_city: CustomerUpdateMutation_make_city,
  make_address: CustomerUpdateMutation_make_address,
  make_customerUpdateInput: CustomerUpdateMutation_make_customerUpdateInput,
  makeVariables: CustomerUpdateMutation_makeVariables,
  make_response_customerUpdate_customer_address_city: CustomerUpdateMutation_make_response_customerUpdate_customer_address_city,
  make_response_customerUpdate_customer_address: CustomerUpdateMutation_make_response_customerUpdate_customer_address,
  make_response_customerUpdate_customer: CustomerUpdateMutation_make_response_customerUpdate_customer,
  make_response_customerUpdate_error: CustomerUpdateMutation_make_response_customerUpdate_error,
  make_response_customerUpdate: CustomerUpdateMutation_make_response_customerUpdate,
  makeOptimisticResponse: CustomerUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

function CustomerUpsertForm$1(Props) {
  var customerOpt = Props.customer;
  var dispatchModal = Props.dispatchModal;
  var onSuccessOpt = Props.onSuccess;
  var customer = customerOpt !== undefined ? Caml_option.valFromOption(customerOpt) : undefined;
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var customerFragment = useOpt(customer);
  var match = React.useState(function () {
        return false;
      });
  var setAddressLoaded = match[1];
  var isAddressLoaded = match[0];
  var match$1 = use$1(undefined);
  var createCustomer = match$1[0];
  var match$2 = use$2(undefined);
  var updateCustomer = match$2[0];
  var tmp;
  if (customerFragment !== undefined) {
    var match$3 = customerFragment.typeOfPeople;
    tmp = {
      name: Belt_Option.getWithDefault(customerFragment.name, ""),
      tradeName: Belt_Option.getWithDefault(customerFragment.tradeName, ""),
      taxId: Belt_Option.getWithDefault(customerFragment.taxId, ""),
      email: customerFragment.email,
      typeOfPeople: match$3 === "LEGAL" ? /* Legal */1 : /* Natural */0,
      phoneAreaCode: Belt_Option.getWithDefault(customerFragment.phoneAreaCode, ""),
      phoneNumber: Belt_Option.getWithDefault(customerFragment.phoneNumber, ""),
      zipcode: Belt_Option.mapWithDefault(customerFragment.address, "", (function (address) {
              return address.postalCode;
            })),
      address: Belt_Option.mapWithDefault(customerFragment.address, "", (function (address) {
              return Belt_Option.getWithDefault(address.street, "");
            })),
      number: Belt_Option.mapWithDefault(customerFragment.address, "", (function (address) {
              return Belt_Option.getWithDefault(address.number, "");
            })),
      neighborhood: Belt_Option.mapWithDefault(customerFragment.address, "", (function (address) {
              return address.district;
            })),
      cityName: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(customerFragment.address, (function (address) {
                      return address.city;
                    })), (function (city) {
                  return city.name;
                })), ""),
      cityCode: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(customerFragment.address, (function (address) {
                      return address.city;
                    })), (function (city) {
                  return city.code;
                })), ""),
      state: Belt_Option.mapWithDefault(customerFragment.address, "", (function (address) {
              return address.state;
            })),
      complement: Belt_Option.mapWithDefault(customerFragment.address, "", (function (address) {
              return Belt_Option.getWithDefault(address.additionalInformation, "");
            }))
    };
  } else {
    tmp = {
      name: "",
      tradeName: "",
      taxId: "",
      email: "",
      typeOfPeople: /* Natural */0,
      phoneAreaCode: "",
      phoneNumber: "",
      zipcode: "",
      address: "",
      number: "",
      neighborhood: "",
      cityName: "",
      cityCode: "",
      state: "",
      complement: ""
    };
  }
  var reform = Curry._7(CustomerUpsertForm.use, tmp, /* Schema */{
        _0: Curry._2(CustomerUpsertForm.ReSchema.Validation.$plus, Curry._2(CustomerUpsertForm.ReSchema.Validation.$plus, Curry._2(CustomerUpsertForm.ReSchema.Validation.$plus, Curry._2(CustomerUpsertForm.ReSchema.Validation.$plus, Curry._2(CustomerUpsertForm.ReSchema.Validation.$plus, Curry._2(CustomerUpsertForm.ReSchema.Validation.$plus, Curry._2(CustomerUpsertForm.ReSchema.Validation.$plus, Curry._2(CustomerUpsertForm.ReSchema.Validation.$plus, Curry._3(CustomerUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Name */0), Curry._3(CustomerUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* TradeName */1)), Curry._3(CustomerUpsertForm.ReSchema.Validation.custom, (function (param) {
                                            var taxId = param.taxId;
                                            if (param.typeOfPeople) {
                                              if (Commonjs$1.isValid(taxId)) {
                                                return /* Valid */0;
                                              } else {
                                                return {
                                                        TAG: /* Error */1,
                                                        _0: "O CNPJ informado é inválido"
                                                      };
                                              }
                                            } else if (Commonjs.isValid(taxId)) {
                                              return /* Valid */0;
                                            } else {
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: "O CPF informado é inválido"
                                                    };
                                            }
                                          }), undefined, /* TaxId */2)), Curry._3(CustomerUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Zipcode */7)), Curry._3(CustomerUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Address */8)), Curry._3(CustomerUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Number */9)), Curry._3(CustomerUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Neighborhood */10)), Curry._3(CustomerUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* State */13)), Curry._3(CustomerUpsertForm.ReSchema.Validation.email, "Email inválido", undefined, /* Email */3))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          if (customerFragment !== undefined) {
            var match = state.values.typeOfPeople;
            Curry.app(updateCustomer, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.customerUpdate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      }
                      var customer = match.customer;
                      if (customer !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        Curry._1(send, {
                              TAG: /* SetFormState */10,
                              _0: /* Dirty */0
                            });
                        return Curry._1(onSuccess, customer.id);
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    input: {
                      address: {
                        additionalInformation: state.values.complement,
                        city: {
                          code: state.values.cityCode,
                          name: state.values.cityName
                        },
                        district: state.values.neighborhood,
                        number: state.values.number,
                        postalCode: state.values.zipcode,
                        state: state.values.state,
                        street: state.values.address
                      },
                      customerId: customerFragment.id,
                      email: state.values.email,
                      name: state.values.name,
                      phoneAreaCode: state.values.phoneAreaCode,
                      phoneNumber: state.values.phoneNumber,
                      taxId: state.values.taxId,
                      tradeName: state.values.tradeName,
                      typeOfPeople: match ? "LEGAL" : "NATURAL"
                    }
                  },
                  undefined,
                  undefined
                ]);
          } else {
            var match$1 = state.values.typeOfPeople;
            Curry.app(createCustomer, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.customerCreate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      }
                      var customer = match.customer;
                      if (customer !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        Curry._1(send, {
                              TAG: /* SetFormState */10,
                              _0: /* Dirty */0
                            });
                        return Curry._1(onSuccess, customer.id);
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  (function (store, response) {
                      var match = response.customerCreate;
                      if (match === undefined) {
                        return ;
                      }
                      var customer = match.customer;
                      if (customer === undefined) {
                        return ;
                      }
                      var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                      var customerProxy = store.get(customer.id);
                      if (meProxy == null) {
                        return ;
                      }
                      if (customerProxy == null) {
                        return ;
                      }
                      var customersConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "Customers_customers", undefined);
                      if (customersConnection == null) {
                        return ;
                      }
                      var edge = RelayRuntime.ConnectionHandler.createEdge(store, customersConnection, customerProxy, "Customer");
                      RelayRuntime.ConnectionHandler.insertEdgeAfter(customersConnection, edge, undefined);
                      
                    }),
                  {
                    input: {
                      address: {
                        additionalInformation: state.values.complement,
                        city: {
                          code: state.values.cityCode,
                          name: state.values.cityName
                        },
                        district: state.values.neighborhood,
                        number: state.values.number,
                        postalCode: state.values.zipcode,
                        state: state.values.state,
                        street: state.values.address
                      },
                      email: state.values.email,
                      name: state.values.name,
                      phoneAreaCode: state.values.phoneAreaCode,
                      phoneNumber: state.values.phoneNumber,
                      taxId: state.values.taxId,
                      tradeName: state.values.tradeName,
                      typeOfPeople: match$1 ? "LEGAL" : "NATURAL"
                    }
                  },
                  undefined,
                  undefined
                ]);
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$4 = reform.formState;
  var tmp$1;
  if (typeof match$4 === "number") {
    tmp$1 = null;
  } else {
    var error = match$4._0;
    tmp$1 = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(CustomerUpsertForm.Provider.make, Curry._3(CustomerUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(CustomerUpsertForm.Field.make, {
                          field: /* TypeOfPeople */4,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(TypeOfPeopleSelect.make, {
                                          label: "Tipo",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          options: [
                                            {
                                              label: "Pessoa Física",
                                              value: /* Natural */0
                                            },
                                            {
                                              label: "Pessoa Jurídica",
                                              value: /* Legal */1
                                            }
                                          ]
                                        });
                            })
                        }), React.createElement(CustomerUpsertForm.Field.make, {
                          field: /* Name */0,
                          render: (function (param) {
                              var match = reform.state.values.typeOfPeople;
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: match ? "Razão social" : "Nome",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(CustomerUpsertForm.Field.make, {
                          field: /* TradeName */1,
                          render: (function (param) {
                              var match = reform.state.values.typeOfPeople;
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: match ? "Nome fantasia" : "Sobrenome",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(CustomerUpsertForm.Field.make, {
                          field: /* TaxId */2,
                          render: (function (param) {
                              var match = reform.state.values.typeOfPeople;
                              var match$1 = reform.state.values.typeOfPeople;
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: match ? "CNPJ" : "CPF",
                                          error: param.error,
                                          required: true,
                                          mask: match$1 ? "99.999.999/9999-99" : "999.999.999-99",
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(FieldSection$VannaFrontend.make, {
                          children: "Endereço"
                        }), React.createElement(CustomerUpsertForm.Field.make, {
                          field: /* Zipcode */7,
                          render: (function (param) {
                              return React.createElement(ZipcodeField$VannaFrontend.make, {
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange,
                                          onAddressInfoLoaded: (function (param) {
                                              Curry._1(setAddressLoaded, (function (param) {
                                                      return true;
                                                    }));
                                              var init = reform.values;
                                              return Curry._1(reform.setValues, {
                                                          name: init.name,
                                                          tradeName: init.tradeName,
                                                          taxId: init.taxId,
                                                          email: init.email,
                                                          typeOfPeople: init.typeOfPeople,
                                                          phoneAreaCode: init.phoneAreaCode,
                                                          phoneNumber: init.phoneNumber,
                                                          zipcode: param.zipcode,
                                                          address: param.address,
                                                          number: init.number,
                                                          neighborhood: param.neighborhood,
                                                          cityName: param.cityName,
                                                          cityCode: param.cityCode,
                                                          state: param.state,
                                                          complement: param.complement
                                                        });
                                            })
                                        });
                            })
                        }), React.createElement(FieldGrid$VannaFrontend.make, {
                          children: null
                        }, React.createElement(CustomerUpsertForm.Field.make, {
                              field: /* Address */8,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Rua",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), React.createElement(CustomerUpsertForm.Field.make, {
                              field: /* Number */9,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Número",
                                              error: param.error,
                                              type_: /* Tel */5,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: twentyPercentField
                                            });
                                })
                            })), React.createElement(FieldGrid$VannaFrontend.make, {
                          children: null
                        }, React.createElement(CustomerUpsertForm.Field.make, {
                              field: /* Neighborhood */10,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Bairro",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: thirtyPercent
                                            });
                                })
                            }), React.createElement(CustomerUpsertForm.Field.make, {
                              field: /* CityName */11,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Cidade",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: thirtyPercent,
                                              disabled: isAddressLoaded
                                            });
                                })
                            }), React.createElement(CustomerUpsertForm.Field.make, {
                              field: /* State */13,
                              render: (function (param) {
                                  return React.createElement(StateSelectField$VannaFrontend.make, {
                                              error: param.error,
                                              onChange: param.handleChange,
                                              value: param.value,
                                              disabled: isAddressLoaded,
                                              className: thirtyPercent
                                            });
                                })
                            })), React.createElement(CustomerUpsertForm.Field.make, {
                          field: /* Complement */14,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Informações adicionais",
                                          error: param.error,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(FieldSection$VannaFrontend.make, {
                          children: "Contato"
                        }), React.createElement(CustomerUpsertForm.Field.make, {
                          field: /* Email */3,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Email",
                                          error: param.error,
                                          type_: /* Email */0,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(FieldSection$VannaFrontend.make, {
                          children: "Telefone"
                        }), React.createElement(FieldGrid$VannaFrontend.make, {
                          children: null
                        }, React.createElement(CustomerUpsertForm.Field.make, {
                              field: /* PhoneAreaCode */5,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Código de área",
                                              error: param.error,
                                              mask: "99",
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: twentyPercentField
                                            });
                                })
                            }), React.createElement(CustomerUpsertForm.Field.make, {
                              field: /* PhoneNumber */6,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Número",
                                              error: param.error,
                                              mask: "9.9999-9999",
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            })), tmp$1, React.createElement(Button$VannaFrontend.make, {
                          isLoading: reform.isSubmitting,
                          type_: /* Submit */1,
                          children: Belt_Option.isSome(customerFragment) ? "Atualizar" : "Adicionar"
                        }), React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, /* CloseWithConfirmation */0);
                            }),
                          children: "Cancelar"
                        })), undefined));
}

var make = CustomerUpsertForm$1;

exports.StateLenses = StateLenses;
exports.CustomerUpsertForm = CustomerUpsertForm;
exports.TypeOfPeopleSelect = TypeOfPeopleSelect;
exports.Styles = Styles;
exports.CustomerFragment = CustomerFragment;
exports.CustomerCreateMutation = CustomerCreateMutation;
exports.CustomerUpdateMutation = CustomerUpdateMutation;
exports.make = make;
/* CustomerUpsertForm Not a pure module */
