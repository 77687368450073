'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Title$VannaFrontend = require("./Title.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var Header$VannaFrontend = require("./Header.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

var title = Curry._1(Css.style, {
      hd: Css.lineHeight({
            NAME: "rem",
            VAL: 1.3
          }),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                hd: Css.marginBottom({
                      NAME: "px",
                      VAL: 15
                    }),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var options = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.marginLeft({
                NAME: "rem",
                VAL: 1.0
              }),
          tl: {
            hd: Css.child("button")({
                  hd: Css.not__(":last-child", {
                        hd: Css.marginRight({
                              NAME: "rem",
                              VAL: 0.5
                            }),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  title: title,
  options: options
};

function ButtonToggleRow(Props) {
  var title$1 = Props.title;
  var options$1 = Props.options;
  var selected = Props.selected;
  var setSelected = Props.setSelected;
  var withMarginBottomOpt = Props.withMarginBottom;
  var withMarginBottom = withMarginBottomOpt !== undefined ? withMarginBottomOpt : false;
  return React.createElement(Header$VannaFrontend.make, {
              withMarginBottom: withMarginBottom,
              children: null
            }, React.createElement(Title$VannaFrontend.make, {
                  className: title,
                  children: title$1
                }), React.createElement("div", {
                  className: options
                }, $$Array.map((function (param) {
                        var label = param.label;
                        var value = param.value;
                        return React.createElement(Button$VannaFrontend.make, {
                                    small: true,
                                    fullWidth: false,
                                    mode: selected !== value ? /* Secondary */1 : /* Primary */0,
                                    onClick: (function (param) {
                                        return Curry._1(setSelected, value);
                                      }),
                                    children: label,
                                    key: "__toggle_row_button__" + label
                                  });
                      }), options$1)));
}

var make = ButtonToggleRow;

exports.Styles = Styles;
exports.make = make;
/* title Not a pure module */
