'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"serviceCreate_error":{"n":""},"serviceCreate_service":{"n":""},"serviceCreate_service_description":{"n":""},"serviceCreate":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"serviceCreate_error":{"n":""},"serviceCreate_service":{"n":""},"serviceCreate_service_description":{"n":""},"serviceCreate":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"ServiceCreateInput":{"description":{"n":""},"cnae":{"n":"","r":"Cnae"},"federalServiceCode":{"n":"","r":"FederalServiceCode"},"cityServiceCode":{"n":"","r":"CityServiceCode"}},"__root":{"input":{"r":"ServiceCreateInput"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_federalServiceCode(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_cnae(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_cityServiceCode(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_serviceCreateInput(cityServiceCode, cnae, description, federalServiceCode, name, price, param) {
  return {
          cityServiceCode: cityServiceCode,
          cnae: cnae,
          description: description,
          federalServiceCode: federalServiceCode,
          name: name,
          price: price
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_serviceCreate_service(id, name, description, price, param) {
  return {
          id: id,
          name: name,
          description: description,
          price: price
        };
}

function make_response_serviceCreate_error(message) {
  return {
          message: message
        };
}

function make_response_serviceCreate(error, service, param) {
  return {
          error: error,
          service: service
        };
}

function makeOptimisticResponse(serviceCreate, param) {
  return {
          serviceCreate: serviceCreate
        };
}

var Utils = {
  make_federalServiceCode: make_federalServiceCode,
  make_cnae: make_cnae,
  make_cityServiceCode: make_cityServiceCode,
  make_serviceCreateInput: make_serviceCreateInput,
  makeVariables: makeVariables,
  make_response_serviceCreate_service: make_response_serviceCreate_service,
  make_response_serviceCreate_error: make_response_serviceCreate_error,
  make_response_serviceCreate: make_response_serviceCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ServiceCreatePayload",
    "kind": "LinkedField",
    "name": "serviceCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Service",
        "kind": "LinkedField",
        "name": "service",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceUpsertForm_ServiceCreateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ServiceUpsertForm_ServiceCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1fa63766f879d58c1ac22e14d9dae66e",
    "id": null,
    "metadata": {},
    "name": "ServiceUpsertForm_ServiceCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ServiceUpsertForm_ServiceCreateMutation(\n  $input: ServiceCreateInput!\n) {\n  serviceCreate(input: $input) {\n    error {\n      message\n    }\n    service {\n      id\n      name\n      description\n      price\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
