'use strict';

var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var ExtractFiles = require("extract-files");
var RelayRuntime = require("relay-runtime");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var JsMap$VannaFrontend = require("./types/JsMap.bs.js");
var Authorization$VannaFrontend = require("./types/Authorization.bs.js");

var Graphql_error = /* @__PURE__ */Caml_exceptions.create("RelayEnv-VannaFrontend.Graphql_error");

function fetchQuery(operation, variables, _cacheConfig, _uploadables) {
  var match = ExtractFiles.extractFiles({
        query: operation.text,
        variables: variables
      });
  var files = match.files;
  var size = files.size;
  var tmp;
  if (size > 0) {
    var formData = new FormData();
    var pathMap = {};
    JsMap$VannaFrontend.forEachWithIndex(files, (function (paths, param, i) {
            pathMap[String(i)] = paths;
            
          }));
    JsMap$VannaFrontend.forEachWithIndex(files, (function (param, file, i) {
            formData.append(String(i), file, undefined);
            
          }));
    formData.append("operations", Js_json.serializeExn(match.clone));
    formData.append("map", Js_json.serializeExn(pathMap));
    tmp = Fetch.RequestInit.make(/* Post */2, {
            authorization: Authorization$VannaFrontend.getToken(undefined)
          }, Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  } else {
    tmp = Fetch.RequestInit.make(/* Post */2, {
            "content-type": "application/json",
            accept: "application/json",
            authorization: Authorization$VannaFrontend.getToken(undefined)
          }, Caml_option.some(JSON.stringify(Js_dict.fromList({
                        hd: [
                          "query",
                          operation.text
                        ],
                        tl: {
                          hd: [
                            "variables",
                            variables
                          ],
                          tl: /* [] */0
                        }
                      }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  }
  return fetch(process.env.REACT_APP_SERVER_URL, tmp).then(function (resp) {
                if (resp.ok) {
                  return resp.json();
                } else {
                  return Promise.reject({
                              RE_EXN_ID: Graphql_error,
                              _1: "Request failed: " + resp.statusText
                            });
                }
              }).catch(function (err) {
              console.log(err);
              return Promise.reject({
                          RE_EXN_ID: Graphql_error,
                          _1: "Request failed"
                        });
            });
}

var network = RelayRuntime.Network.create(fetchQuery, undefined);

var environment = ReasonRelay.Environment.make(network, ReasonRelay.Store.make(new RelayRuntime.RecordSource(undefined), 10, undefined, undefined), undefined, undefined, undefined, undefined);

exports.Graphql_error = Graphql_error;
exports.fetchQuery = fetchQuery;
exports.network = network;
exports.environment = environment;
/* network Not a pure module */
