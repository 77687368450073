'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var QueryString = require("query-string");

function routeToPath(route) {
  if (typeof route === "number") {
    switch (route) {
      case /* Login */0 :
          return "/login";
      case /* SignUp */1 :
          return "/criar-conta";
      case /* ForgotPassword */2 :
          return "/esqueci-minha-senha";
      case /* Dashboard */3 :
          return "/";
      case /* Pix */4 :
          return "/pix";
      case /* RecurrentCharges */5 :
          return "/assinaturas";
      case /* Charges */6 :
          return "/vendas";
      case /* Customers */7 :
          return "/clientes";
      case /* Products */8 :
          return "/produtos";
      case /* Services */9 :
          return "/servicos";
      case /* SelfCheckouts */10 :
          return "/links-de-pagamento";
      case /* Affiliates */11 :
          return "/afiliados";
      case /* Webhooks */12 :
          return "/webhooks";
      case /* API */13 :
          return "/api";
      case /* NotFound */14 :
          return "/404";
      
    }
  } else {
    switch (route.TAG | 0) {
      case /* SignUpIndicated */0 :
          return "/criar-conta/" + Belt_Option.getWithDefault(route._0, "");
      case /* ResetPassword */1 :
          return "/redefinir-senha/" + route._0;
      case /* RecurrentChargePayment */2 :
          return "/assinatura/" + route._0;
      case /* RecurrentChargePaymentConfirm */3 :
          return "/assinatura/" + (route._0 + "/confirmar");
      case /* ChargePayment */4 :
          return "/vendas/" + route._0;
      case /* ChargePaymentConfirm */5 :
          return "/vendas/" + (route._0 + "/confirmar");
      case /* ChargeDetails */6 :
          return "/vendas/detalhes/" + route._0;
      case /* SellersAndReferrals */7 :
          return "/vendedores?t=" + route._0;
      case /* Settings */8 :
          return "/configuracoes?t=" + route._0;
      
    }
  }
}

function pathToRoute(route) {
  var match = route.path;
  if (!match) {
    return /* Dashboard */3;
  }
  switch (match.hd) {
    case "afiliados" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* Affiliates */11;
        }
    case "api" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* API */13;
        }
    case "assinatura" :
        var match$1 = match.tl;
        if (!match$1) {
          return /* NotFound */14;
        }
        var match$2 = match$1.tl;
        var id = match$1.hd;
        if (match$2) {
          if (match$2.hd === "confirmar" && !match$2.tl) {
            return {
                    TAG: /* RecurrentChargePaymentConfirm */3,
                    _0: id
                  };
          } else {
            return /* NotFound */14;
          }
        } else {
          return {
                  TAG: /* RecurrentChargePayment */2,
                  _0: id
                };
        }
    case "assinaturas" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* RecurrentCharges */5;
        }
    case "clientes" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* Customers */7;
        }
    case "configuracoes" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return {
                  TAG: /* Settings */8,
                  _0: Belt_Option.getWithDefault(Js_dict.get(QueryString.parse(route.search), "t"), "dados-cadastrais")
                };
        }
    case "criar-conta" :
        var match$3 = match.tl;
        if (!match$3) {
          return /* SignUp */1;
        }
        if (match$3.tl) {
          return /* NotFound */14;
        }
        var code = match$3.hd;
        return {
                TAG: /* SignUpIndicated */0,
                _0: code.length !== 0 ? code : undefined
              };
    case "esqueci-minha-senha" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* ForgotPassword */2;
        }
    case "links-de-pagamento" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* SelfCheckouts */10;
        }
    case "login" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* Login */0;
        }
    case "pix" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* Pix */4;
        }
    case "produtos" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* Products */8;
        }
    case "redefinir-senha" :
        var match$4 = match.tl;
        if (match$4 && !match$4.tl) {
          return {
                  TAG: /* ResetPassword */1,
                  _0: match$4.hd
                };
        } else {
          return /* NotFound */14;
        }
    case "servicos" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* Services */9;
        }
    case "vendas" :
        var match$5 = match.tl;
        if (!match$5) {
          return /* Charges */6;
        }
        var chargeId = match$5.hd;
        var match$6 = match$5.tl;
        if (!match$6) {
          return {
                  TAG: /* ChargePayment */4,
                  _0: chargeId
                };
        }
        if (match$6.hd === "confirmar") {
          if (match$6.tl) {
            return /* NotFound */14;
          } else {
            return {
                    TAG: /* ChargePaymentConfirm */5,
                    _0: chargeId
                  };
          }
        }
        if (chargeId !== "detalhes") {
          return /* NotFound */14;
        }
        var match$7 = match$5.tl;
        if (match$7.tl) {
          return /* NotFound */14;
        } else {
          return {
                  TAG: /* ChargeDetails */6,
                  _0: match$7.hd
                };
        }
    case "vendedores" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return {
                  TAG: /* SellersAndReferrals */7,
                  _0: Belt_Option.getWithDefault(Js_dict.get(QueryString.parse(route.search), "t"), "vendedores")
                };
        }
    case "webhooks" :
        if (match.tl) {
          return /* NotFound */14;
        } else {
          return /* Webhooks */12;
        }
    default:
      return /* NotFound */14;
  }
}

exports.routeToPath = routeToPath;
exports.pathToRoute = pathToRoute;
/* query-string Not a pure module */
