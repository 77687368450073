'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var KeyValueField$VannaFrontend = require("./KeyValueField.bs.js");
var SimpleSelectField$VannaFrontend = require("./SimpleSelectField.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var WebhookUpsertForm_webhook_graphql$VannaFrontend = require("../__generated__/WebhookUpsertForm_webhook_graphql.bs.js");
var WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend = require("../__generated__/WebhookUpsertForm_WebhookCreateMutation_graphql.bs.js");
var WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend = require("../__generated__/WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql.bs.js");

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(WebhookUpsertForm_webhook_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(WebhookUpsertForm_webhook_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(WebhookUpsertForm_webhook_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return WebhookUpsertForm_webhook_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var WebhookFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.node,
              variables: WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var WebhookCreateMutation_make_webhookSubscriptionCreateInput = WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Utils.make_webhookSubscriptionCreateInput;

var WebhookCreateMutation_makeVariables = WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var WebhookCreateMutation_make_response_webhookSubscriptionCreate_webhookSubscription = WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Utils.make_response_webhookSubscriptionCreate_webhookSubscription;

var WebhookCreateMutation_make_response_webhookSubscriptionCreate_error = WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Utils.make_response_webhookSubscriptionCreate_error;

var WebhookCreateMutation_make_response_webhookSubscriptionCreate = WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Utils.make_response_webhookSubscriptionCreate;

var WebhookCreateMutation_makeOptimisticResponse = WebhookUpsertForm_WebhookCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var WebhookCreateMutation = {
  make_webhookSubscriptionCreateInput: WebhookCreateMutation_make_webhookSubscriptionCreateInput,
  makeVariables: WebhookCreateMutation_makeVariables,
  make_response_webhookSubscriptionCreate_webhookSubscription: WebhookCreateMutation_make_response_webhookSubscriptionCreate_webhookSubscription,
  make_response_webhookSubscriptionCreate_error: WebhookCreateMutation_make_response_webhookSubscriptionCreate_error,
  make_response_webhookSubscriptionCreate: WebhookCreateMutation_make_response_webhookSubscriptionCreate,
  makeOptimisticResponse: WebhookCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.node,
              variables: WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var WebhookUpdateMutation_make_webhookSubscriptionUpdateInput = WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Utils.make_webhookSubscriptionUpdateInput;

var WebhookUpdateMutation_makeVariables = WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var WebhookUpdateMutation_make_response_webhookSubscriptionUpdate_webhookSubscription = WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Utils.make_response_webhookSubscriptionUpdate_webhookSubscription;

var WebhookUpdateMutation_make_response_webhookSubscriptionUpdate_error = WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Utils.make_response_webhookSubscriptionUpdate_error;

var WebhookUpdateMutation_make_response_webhookSubscriptionUpdate = WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Utils.make_response_webhookSubscriptionUpdate;

var WebhookUpdateMutation_makeOptimisticResponse = WebhookUpsertForm_WebhookSubscriptionUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var WebhookUpdateMutation = {
  make_webhookSubscriptionUpdateInput: WebhookUpdateMutation_make_webhookSubscriptionUpdateInput,
  makeVariables: WebhookUpdateMutation_makeVariables,
  make_response_webhookSubscriptionUpdate_webhookSubscription: WebhookUpdateMutation_make_response_webhookSubscriptionUpdate_webhookSubscription,
  make_response_webhookSubscriptionUpdate_error: WebhookUpdateMutation_make_response_webhookSubscriptionUpdate_error,
  make_response_webhookSubscriptionUpdate: WebhookUpdateMutation_make_response_webhookSubscriptionUpdate,
  makeOptimisticResponse: WebhookUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

function get(state, field) {
  switch (field) {
    case /* Description */0 :
        return state.description;
    case /* Url */1 :
        return state.url;
    case /* AdditionalBody */2 :
        return state.additionalBody;
    case /* AdditionalHeaders */3 :
        return state.additionalHeaders;
    case /* Events */4 :
        return state.events;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Description */0 :
        return {
                description: value,
                url: state.url,
                additionalBody: state.additionalBody,
                additionalHeaders: state.additionalHeaders,
                events: state.events
              };
    case /* Url */1 :
        return {
                description: state.description,
                url: value,
                additionalBody: state.additionalBody,
                additionalHeaders: state.additionalHeaders,
                events: state.events
              };
    case /* AdditionalBody */2 :
        return {
                description: state.description,
                url: state.url,
                additionalBody: value,
                additionalHeaders: state.additionalHeaders,
                events: state.events
              };
    case /* AdditionalHeaders */3 :
        return {
                description: state.description,
                url: state.url,
                additionalBody: state.additionalBody,
                additionalHeaders: value,
                events: state.events
              };
    case /* Events */4 :
        return {
                description: state.description,
                url: state.url,
                additionalBody: state.additionalBody,
                additionalHeaders: state.additionalHeaders,
                events: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var WebhookUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var EventSelect = SimpleSelectField$VannaFrontend.Make({});

var eventOptions = [
  {
    label: "Cobrança cancelada",
    value: "CHARGE_CANCELED"
  },
  {
    label: "Cobrança confirmada",
    value: "CHARGE_CONFIRMED"
  },
  {
    label: "Cobrança criada",
    value: "CHARGE_CREATED"
  },
  {
    label: "Cobrança removida",
    value: "CHARGE_DELETED"
  },
  {
    label: "Cobrança paga",
    value: "CHARGE_PAID"
  },
  {
    label: "Cobrança estornada",
    value: "CHARGE_REFUNDED"
  },
  {
    label: "Cobrança rejeitada",
    value: "CHARGE_REJECTED"
  },
  {
    label: "Cobrança cartão de crédito adicionado",
    value: "CHARGE_SOURCE_ADDED"
  },
  {
    label: "Cobrança atualizada",
    value: "CHARGE_UPDATED"
  },
  {
    label: "Cobrança recorrente cancelada",
    value: "RECURRENT_CHARGE_CANCELED"
  },
  {
    label: "Cobrança recorrente pausada",
    value: "RECURRENT_CHARGE_PAUSED"
  },
  {
    label: "Cobrança recorrente reiniciada",
    value: "RECURRENT_CHARGE_RESUMED"
  },
  {
    label: "Cobrança recorrente pagamento falhou",
    value: "RECURRENT_CHARGE_PAYMENT_FAILED"
  }
];

function WebhookUpsertForm$1(Props) {
  var webhookOpt = Props.webhook;
  var dispatchModal = Props.dispatchModal;
  var webhook = webhookOpt !== undefined ? Caml_option.valFromOption(webhookOpt) : undefined;
  var webhookFragment = useOpt(webhook);
  var match = use$1(undefined);
  var createWebhook = match[0];
  var match$1 = use$2(undefined);
  var updateWebhook = match$1[0];
  var tmp;
  if (webhookFragment !== undefined) {
    var tmp$1;
    var exit = 0;
    var value;
    try {
      value = JSON.parse(Belt_Option.getWithDefault(webhookFragment.additionalBody, "{}"));
      exit = 1;
    }
    catch (exn){
      tmp$1 = [];
    }
    if (exit === 1) {
      tmp$1 = Belt_Option.mapWithDefault(Js_json.decodeObject(value), [], (function (json) {
              return Belt_Array.map(Js_dict.entries(json), (function (param) {
                            return {
                                    key: param[0],
                                    value: Belt_Option.getWithDefault(Js_json.decodeString(param[1]), "")
                                  };
                          }));
            }));
    }
    var tmp$2;
    var exit$1 = 0;
    var value$1;
    try {
      value$1 = JSON.parse(Belt_Option.getWithDefault(webhookFragment.additionalHeaders, "{}"));
      exit$1 = 1;
    }
    catch (exn$1){
      tmp$2 = [];
    }
    if (exit$1 === 1) {
      tmp$2 = Belt_Option.mapWithDefault(Js_json.decodeObject(value$1), [], (function (json) {
              return Belt_Array.map(Js_dict.entries(json), (function (param) {
                            return {
                                    key: param[0],
                                    value: Belt_Option.getWithDefault(Js_json.decodeString(param[1]), "")
                                  };
                          }));
            }));
    }
    tmp = {
      description: Belt_Option.getWithDefault(webhookFragment.description, ""),
      url: webhookFragment.url,
      additionalBody: tmp$1,
      additionalHeaders: tmp$2,
      events: webhookFragment.events
    };
  } else {
    tmp = {
      description: "",
      url: "",
      additionalBody: [],
      additionalHeaders: [],
      events: []
    };
  }
  var reform = Curry._7(WebhookUpsertForm.use, tmp, /* Schema */{
        _0: Curry._2(WebhookUpsertForm.ReSchema.Validation.$plus, Curry._2(WebhookUpsertForm.ReSchema.Validation.$plus, Curry._3(WebhookUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Description */0), Curry._3(WebhookUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Url */1)), Curry._3(WebhookUpsertForm.ReSchema.Validation.custom, (function (state) {
                    var match = state.events;
                    if (match.length !== 0) {
                      return /* Valid */0;
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: "Você precisa selecionar pelo menos 1 evento"
                            };
                    }
                  }), undefined, /* Events */4))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          if (webhookFragment !== undefined) {
            var tmp;
            var exit = 0;
            var json;
            try {
              json = Js_json.serializeExn(Belt_Array.reduce(state.values.additionalBody, {}, (function (acc, cur) {
                          acc[cur.key] = cur.value;
                          return acc;
                        })));
              exit = 1;
            }
            catch (exn){
              tmp = undefined;
            }
            if (exit === 1) {
              tmp = json;
            }
            var tmp$1;
            var exit$1 = 0;
            var json$1;
            try {
              json$1 = Js_json.serializeExn(Belt_Array.reduce(state.values.additionalHeaders, {}, (function (acc, cur) {
                          acc[cur.key] = cur.value;
                          return acc;
                        })));
              exit$1 = 1;
            }
            catch (exn$1){
              tmp$1 = undefined;
            }
            if (exit$1 === 1) {
              tmp$1 = json$1;
            }
            Curry.app(updateWebhook, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.webhookSubscriptionUpdate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      } else if (match.webhookSubscription !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        return Curry._1(send, {
                                    TAG: /* SetFormState */10,
                                    _0: /* Dirty */0
                                  });
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    input: {
                      additionalBody: tmp,
                      additionalHeaders: tmp$1,
                      description: state.values.description,
                      events: Belt_Array.map(state.values.events, (function ($$event) {
                              return $$event;
                            })),
                      observedObjectId: undefined,
                      url: state.values.url,
                      webhookSubscriptionId: webhookFragment.id
                    }
                  },
                  undefined,
                  undefined
                ]);
          } else {
            var tmp$2;
            var exit$2 = 0;
            var json$2;
            try {
              json$2 = Js_json.serializeExn(Belt_Array.reduce(state.values.additionalBody, {}, (function (acc, cur) {
                          acc[cur.key] = cur.value;
                          return acc;
                        })));
              exit$2 = 1;
            }
            catch (exn$2){
              tmp$2 = undefined;
            }
            if (exit$2 === 1) {
              tmp$2 = json$2;
            }
            var tmp$3;
            var exit$3 = 0;
            var json$3;
            try {
              json$3 = Js_json.serializeExn(Belt_Array.reduce(state.values.additionalHeaders, {}, (function (acc, cur) {
                          acc[cur.key] = cur.value;
                          return acc;
                        })));
              exit$3 = 1;
            }
            catch (exn$3){
              tmp$3 = undefined;
            }
            if (exit$3 === 1) {
              tmp$3 = json$3;
            }
            Curry.app(createWebhook, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.webhookSubscriptionCreate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      } else if (match.webhookSubscription !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        return Curry._1(send, {
                                    TAG: /* SetFormState */10,
                                    _0: /* Dirty */0
                                  });
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  (function (store, response) {
                      var match = response.webhookSubscriptionCreate;
                      if (match === undefined) {
                        return ;
                      }
                      var webhook = match.webhookSubscription;
                      if (webhook === undefined) {
                        return ;
                      }
                      var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                      var webhookProxy = store.get(webhook.id);
                      if (meProxy == null) {
                        return ;
                      }
                      if (webhookProxy == null) {
                        return ;
                      }
                      var webhooksConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "Webhooks_webhookSubscriptions", undefined);
                      if (webhooksConnection == null) {
                        return ;
                      }
                      var edge = RelayRuntime.ConnectionHandler.createEdge(store, webhooksConnection, webhookProxy, "WebhookSubscription");
                      RelayRuntime.ConnectionHandler.insertEdgeAfter(webhooksConnection, edge, undefined);
                      
                    }),
                  {
                    input: {
                      additionalBody: tmp$2,
                      additionalHeaders: tmp$3,
                      description: state.values.description,
                      events: Belt_Array.map(state.values.events, (function ($$event) {
                              return $$event;
                            })),
                      observedObjectId: undefined,
                      url: state.values.url
                    }
                  },
                  undefined,
                  undefined
                ]);
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$2 = reform.formState;
  var tmp$3;
  if (typeof match$2 === "number") {
    tmp$3 = null;
  } else {
    var error = match$2._0;
    tmp$3 = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(WebhookUpsertForm.Provider.make, Curry._3(WebhookUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(WebhookUpsertForm.Field.make, {
                          field: /* Description */0,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Descrição",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(WebhookUpsertForm.Field.make, {
                          field: /* Url */1,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "URL",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(WebhookUpsertForm.Field.make, {
                          field: /* AdditionalBody */2,
                          render: (function (param) {
                              return React.createElement(KeyValueField$VannaFrontend.make, {
                                          label: "Objeto a ser enviado juntamente ao corpo da requisição",
                                          error: param.error,
                                          required: false,
                                          tip: "Chave - Valor",
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(WebhookUpsertForm.Field.make, {
                          field: /* AdditionalHeaders */3,
                          render: (function (param) {
                              return React.createElement(KeyValueField$VannaFrontend.make, {
                                          label: "Objeto a ser enviado juntamente aos cabeçalhos da requisição",
                                          error: param.error,
                                          required: false,
                                          tip: "Chave - Valor",
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(WebhookUpsertForm.Field.make, {
                          field: /* Events */4,
                          render: (function (param) {
                              return React.createElement(EventSelect.make, {
                                          label: "Eventos",
                                          error: param.error,
                                          tip: "Lista dos eventos que serão enviados para a URL cadastrada",
                                          value: param.value,
                                          onChange: (function (values) {
                                              return Curry._4(reform.setFieldValue, /* Events */4, values, undefined, undefined);
                                            }),
                                          placeholder: "Selecione",
                                          options: eventOptions
                                        });
                            })
                        }), tmp$3, React.createElement(Button$VannaFrontend.make, {
                          isLoading: reform.isSubmitting,
                          type_: /* Submit */1,
                          children: Belt_Option.isSome(webhookFragment) ? "Atualizar" : "Adicionar"
                        }), React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, /* CloseWithConfirmation */0);
                            }),
                          children: "Cancelar"
                        })), undefined));
}

var make = WebhookUpsertForm$1;

exports.WebhookFragment = WebhookFragment;
exports.WebhookCreateMutation = WebhookCreateMutation;
exports.WebhookUpdateMutation = WebhookUpdateMutation;
exports.StateLenses = StateLenses;
exports.WebhookUpsertForm = WebhookUpsertForm;
exports.EventSelect = EventSelect;
exports.eventOptions = eventOptions;
exports.make = make;
/* WebhookUpsertForm Not a pure module */
