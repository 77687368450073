'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var QrcodeReact = require("qrcode.react");
var RelayRuntime = require("relay-runtime");
var Hr$VannaFrontend = require("./Hr.bs.js");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Text$VannaFrontend = require("./Text.bs.js");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var TwoFactorForm$VannaFrontend = require("./TwoFactorForm.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var SecurityFormFragment_graphql$VannaFrontend = require("../__generated__/SecurityFormFragment_graphql.bs.js");
var SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend = require("../__generated__/SecurityForm_UserChangePasswordMutation_graphql.bs.js");
var SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend = require("../__generated__/SecurityForm_SecurityFormEnableSMS2FAMutation_graphql.bs.js");
var SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend = require("../__generated__/SecurityForm_SecurityFormVerifySMS2FAMutation_graphql.bs.js");
var SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend = require("../__generated__/SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql.bs.js");
var SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend = require("../__generated__/SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 50
          }),
      tl: {
        hd: Css.padding(Theme$VannaFrontend.Padding.content),
        tl: /* [] */0
      }
    });

var text = Curry._1(Css.style, {
      hd: Css.fontWeight("bold"),
      tl: /* [] */0
    });

var subtext = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.8
          }),
      tl: {
        hd: Css.color(Theme$VannaFrontend.Colors.textSecondary),
        tl: /* [] */0
      }
    });

var itemWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: /* [] */0
          }
        }
      }
    });

var qrCodeWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.padding({
                    NAME: "px",
                    VAL: 7
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var field = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 80
          }),
      tl: /* [] */0
    });

var Styles = {
  panel: panel,
  text: text,
  subtext: subtext,
  itemWrapper: itemWrapper,
  qrCodeWrapper: qrCodeWrapper,
  field: field
};

function get(state, field) {
  return state.token;
}

function set(state, field, value) {
  return {
          token: value
        };
}

var StateLenses = {
  get: get,
  set: set
};

function get$1(state, field) {
  switch (field) {
    case /* OldPassword */0 :
        return state.oldPassword;
    case /* NewPassword */1 :
        return state.newPassword;
    case /* NewPasswordConfirm */2 :
        return state.newPasswordConfirm;
    case /* GoogleAuthCode */3 :
        return state.googleAuthCode;
    case /* SmsAuthCode */4 :
        return state.smsAuthCode;
    
  }
}

function set$1(state, field, value) {
  switch (field) {
    case /* OldPassword */0 :
        return {
                oldPassword: value,
                newPassword: state.newPassword,
                newPasswordConfirm: state.newPasswordConfirm,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* NewPassword */1 :
        return {
                oldPassword: state.oldPassword,
                newPassword: value,
                newPasswordConfirm: state.newPasswordConfirm,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* NewPasswordConfirm */2 :
        return {
                oldPassword: state.oldPassword,
                newPassword: state.newPassword,
                newPasswordConfirm: value,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* GoogleAuthCode */3 :
        return {
                oldPassword: state.oldPassword,
                newPassword: state.newPassword,
                newPasswordConfirm: state.newPasswordConfirm,
                googleAuthCode: value,
                smsAuthCode: state.smsAuthCode
              };
    case /* SmsAuthCode */4 :
        return {
                oldPassword: state.oldPassword,
                newPassword: state.newPassword,
                newPasswordConfirm: state.newPasswordConfirm,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: value
              };
    
  }
}

var ChangePasswordStateLenses = {
  get: get$1,
  set: set$1
};

var GoogleTwoFactorVerifyForm = ReForm.Make({
      set: set,
      get: get
    });

var SmsTwoFactorVerifyForm = ReForm.Make({
      set: set,
      get: get
    });

var ChangePasswordForm = ReForm.Make({
      set: set$1,
      get: get$1
    });

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(SecurityFormFragment_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(SecurityFormFragment_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(SecurityFormFragment_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return SecurityFormFragment_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var SecurityFormFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.node,
              variables: SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var SecurityFormEnableGAuth2FA_make_response_userSetupGauth_error = SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Utils.make_response_userSetupGauth_error;

var SecurityFormEnableGAuth2FA_make_response_userSetupGauth = SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Utils.make_response_userSetupGauth;

var SecurityFormEnableGAuth2FA_makeOptimisticResponse = SecurityForm_SecurityFormEnableGAuth2FAMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var SecurityFormEnableGAuth2FA = {
  make_response_userSetupGauth_error: SecurityFormEnableGAuth2FA_make_response_userSetupGauth_error,
  make_response_userSetupGauth: SecurityFormEnableGAuth2FA_make_response_userSetupGauth,
  makeOptimisticResponse: SecurityFormEnableGAuth2FA_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.node,
              variables: SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var SecurityFormVerifyGAuth2FA_make_userVerifyGauthInput = SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Utils.make_userVerifyGauthInput;

var SecurityFormVerifyGAuth2FA_makeVariables = SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Utils.makeVariables;

var SecurityFormVerifyGAuth2FA_make_response_userVerifyGauth_error = SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Utils.make_response_userVerifyGauth_error;

var SecurityFormVerifyGAuth2FA_make_response_userVerifyGauth = SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Utils.make_response_userVerifyGauth;

var SecurityFormVerifyGAuth2FA_makeOptimisticResponse = SecurityForm_SecurityFormVerifyGAuth2FAMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var SecurityFormVerifyGAuth2FA = {
  make_userVerifyGauthInput: SecurityFormVerifyGAuth2FA_make_userVerifyGauthInput,
  makeVariables: SecurityFormVerifyGAuth2FA_makeVariables,
  make_response_userVerifyGauth_error: SecurityFormVerifyGAuth2FA_make_response_userVerifyGauth_error,
  make_response_userVerifyGauth: SecurityFormVerifyGAuth2FA_make_response_userVerifyGauth,
  makeOptimisticResponse: SecurityFormVerifyGAuth2FA_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

var Internal$3 = {};

function commitMutation$2(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.node,
              variables: SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$3(param) {
  var match = RelayExperimental.useMutation(SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var SecurityFormEnableSMS2FAMutation_make_response_userSetupSms_error = SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Utils.make_response_userSetupSms_error;

var SecurityFormEnableSMS2FAMutation_make_response_userSetupSms = SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Utils.make_response_userSetupSms;

var SecurityFormEnableSMS2FAMutation_makeOptimisticResponse = SecurityForm_SecurityFormEnableSMS2FAMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var SecurityFormEnableSMS2FAMutation = {
  make_response_userSetupSms_error: SecurityFormEnableSMS2FAMutation_make_response_userSetupSms_error,
  make_response_userSetupSms: SecurityFormEnableSMS2FAMutation_make_response_userSetupSms,
  makeOptimisticResponse: SecurityFormEnableSMS2FAMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$3,
  commitMutation: commitMutation$2,
  use: use$3
};

var Internal$4 = {};

function commitMutation$3(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.node,
              variables: SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$4(param) {
  var match = RelayExperimental.useMutation(SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var SecurityFormVerifySMS2FAMutation_make_userVerifySmsInput = SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Utils.make_userVerifySmsInput;

var SecurityFormVerifySMS2FAMutation_makeVariables = SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Utils.makeVariables;

var SecurityFormVerifySMS2FAMutation_make_response_userVerifySms_error = SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Utils.make_response_userVerifySms_error;

var SecurityFormVerifySMS2FAMutation_make_response_userVerifySms = SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Utils.make_response_userVerifySms;

var SecurityFormVerifySMS2FAMutation_makeOptimisticResponse = SecurityForm_SecurityFormVerifySMS2FAMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var SecurityFormVerifySMS2FAMutation = {
  make_userVerifySmsInput: SecurityFormVerifySMS2FAMutation_make_userVerifySmsInput,
  makeVariables: SecurityFormVerifySMS2FAMutation_makeVariables,
  make_response_userVerifySms_error: SecurityFormVerifySMS2FAMutation_make_response_userVerifySms_error,
  make_response_userVerifySms: SecurityFormVerifySMS2FAMutation_make_response_userVerifySms,
  makeOptimisticResponse: SecurityFormVerifySMS2FAMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$4,
  commitMutation: commitMutation$3,
  use: use$4
};

var Internal$5 = {};

function commitMutation$4(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.node,
              variables: SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$5(param) {
  var match = RelayExperimental.useMutation(SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ChangePasswordMutation_make_twoFactor = SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Utils.make_twoFactor;

var ChangePasswordMutation_make_userChangePasswordInput = SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Utils.make_userChangePasswordInput;

var ChangePasswordMutation_makeVariables = SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Utils.makeVariables;

var ChangePasswordMutation_make_response_userChangePassword_error = SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Utils.make_response_userChangePassword_error;

var ChangePasswordMutation_make_response_userChangePassword = SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Utils.make_response_userChangePassword;

var ChangePasswordMutation_makeOptimisticResponse = SecurityForm_UserChangePasswordMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ChangePasswordMutation = {
  make_twoFactor: ChangePasswordMutation_make_twoFactor,
  make_userChangePasswordInput: ChangePasswordMutation_make_userChangePasswordInput,
  makeVariables: ChangePasswordMutation_makeVariables,
  make_response_userChangePassword_error: ChangePasswordMutation_make_response_userChangePassword_error,
  make_response_userChangePassword: ChangePasswordMutation_make_response_userChangePassword,
  makeOptimisticResponse: ChangePasswordMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$5,
  commitMutation: commitMutation$4,
  use: use$5
};

function SecurityForm(Props) {
  var fragment = Props.fragment;
  var queryData = use(fragment);
  var match = use$1(undefined);
  var isEnablingGAuth2FA = match[1];
  var enableGAuth2FA = match[0];
  var match$1 = use$2(undefined);
  var verifyGAuth2FA = match$1[0];
  var match$2 = use$3(undefined);
  var isEnablingSms2FA = match$2[1];
  var enableSms2FA = match$2[0];
  var match$3 = use$4(undefined);
  var verifySms2FA = match$3[0];
  var match$4 = use$5(undefined);
  var changePassword = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setError = match$5[1];
  var error = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setGAuthUrl = match$6[1];
  var gAuthUrl = match$6[0];
  var match$7 = React.useState(function () {
        return false;
      });
  var setOpenGoogleAuthModal = match$7[1];
  var match$8 = React.useState(function () {
        return false;
      });
  var setOpenSMSAuthModal = match$8[1];
  var match$9 = React.useState(function () {
        return false;
      });
  var setOpenChangePasswordModal = match$9[1];
  var match$10 = queryData.googleAuth;
  var isGoogleAuthEnabled = match$10 !== undefined ? match$10.isEnabled : false;
  var match$11 = queryData.smsAuth;
  var isSMSAuthEnabled = match$11 !== undefined ? match$11.isEnabled : false;
  var googleAuthReform = Curry._7(GoogleTwoFactorVerifyForm.use, {
        token: ""
      }, /* Schema */{
        _0: Curry._3(GoogleTwoFactorVerifyForm.ReSchema.Validation.custom, (function (state) {
                if (state.token.length !== 6) {
                  return {
                          TAG: /* Error */1,
                          _0: "O código de autenticação precisa ter 6 caracteres"
                        };
                } else {
                  return /* Valid */0;
                }
              }), undefined, /* Token */0)
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var send = param.send;
          Curry.app(verifyGAuth2FA, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, errors) {
                    if (errors !== undefined && errors.length !== 0) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match = response.userVerifyGauth;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    } else {
                      Curry._1(send, {
                            TAG: /* SetFormState */10,
                            _0: /* Dirty */0
                          });
                      Curry._1(setOpenGoogleAuthModal, (function (param) {
                              return false;
                            }));
                      return Curry._1(send, /* ResetForm */2);
                    }
                  }),
                undefined,
                undefined,
                undefined,
                (function (store, response) {
                    var match = response.userVerifyGauth;
                    if (match === undefined) {
                      return ;
                    }
                    if (match.error !== undefined) {
                      return ;
                    }
                    var me = store.getRoot().getLinkedRecord("me", undefined);
                    if (me == null) {
                      return ;
                    }
                    var googleAuth = me.getOrCreateLinkedRecord("googleAuth", "GoogleAuthType", undefined);
                    googleAuth.setValue(true, "isEnabled", undefined);
                    
                  }),
                {
                  input: {
                    token: param.state.values.token
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var smsAuthReform = Curry._7(SmsTwoFactorVerifyForm.use, {
        token: ""
      }, /* Schema */{
        _0: Curry._3(SmsTwoFactorVerifyForm.ReSchema.Validation.custom, (function (state) {
                if (state.token.length !== 6) {
                  return {
                          TAG: /* Error */1,
                          _0: "O código de autenticação precisa ter 6 caracteres"
                        };
                } else {
                  return /* Valid */0;
                }
              }), undefined, /* Token */0)
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var send = param.send;
          Curry.app(verifySms2FA, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, errors) {
                    if (errors !== undefined && errors.length !== 0) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match = response.userVerifySms;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    } else {
                      Curry._1(send, {
                            TAG: /* SetFormState */10,
                            _0: /* Dirty */0
                          });
                      Curry._1(setOpenSMSAuthModal, (function (param) {
                              return false;
                            }));
                      return Curry._1(send, /* ResetForm */2);
                    }
                  }),
                undefined,
                undefined,
                undefined,
                (function (store, response) {
                    var match = response.userVerifySms;
                    if (match === undefined) {
                      return ;
                    }
                    if (match.error !== undefined) {
                      return ;
                    }
                    var me = store.getRoot().getLinkedRecord("me", undefined);
                    if (me == null) {
                      return ;
                    }
                    var googleAuth = me.getOrCreateLinkedRecord("smsAuth", " SmsAuthType", undefined);
                    googleAuth.setValue(true, "isEnabled", undefined);
                    
                  }),
                {
                  input: {
                    token: param.state.values.token
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var changePasswordReform = Curry._7(ChangePasswordForm.use, {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        googleAuthCode: "",
        smsAuthCode: ""
      }, /* Schema */{
        _0: Curry._2(ChangePasswordForm.ReSchema.Validation.$plus, Curry._6(ChangePasswordForm.ReSchema.Validation.string, 8, "A senha precisa ter pelo menos 8 caracteres", undefined, undefined, undefined, /* NewPassword */1), Curry._3(ChangePasswordForm.ReSchema.Validation.custom, (function (param) {
                    if (param.newPassword !== param.newPasswordConfirm) {
                      return {
                              TAG: /* Error */1,
                              _0: "Suas senhas não conferem"
                            };
                    } else {
                      return /* Valid */0;
                    }
                  }), undefined, /* NewPasswordConfirm */2))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          Curry.app(changePassword, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, errors) {
                    if (errors !== undefined && errors.length !== 0) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match = response.userChangePassword;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    } else {
                      Curry._1(send, {
                            TAG: /* SetFormState */10,
                            _0: /* Dirty */0
                          });
                      Curry._1(setOpenChangePasswordModal, (function (param) {
                              return false;
                            }));
                      return Curry._1(send, /* ResetForm */2);
                    }
                  }),
                undefined,
                undefined,
                undefined,
                undefined,
                {
                  input: {
                    oldPassword: state.values.oldPassword,
                    password: state.values.newPassword,
                    twoFactor: {
                      googleAuthCode: state.values.googleAuthCode,
                      smsAuthCode: state.values.smsAuthCode
                    }
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  return React.createElement(Panel$VannaFrontend.make, {
              className: panel,
              children: null
            }, error !== undefined ? React.createElement(Box$VannaFrontend.make, {
                    type_: /* Error */2,
                    title: "Ops!",
                    children: error
                  }) : null, React.createElement("div", {
                  className: itemWrapper
                }, React.createElement("div", undefined, React.createElement(Text$VannaFrontend.make, {
                          className: text,
                          children: "Autenticação Google"
                        }), React.createElement(Text$VannaFrontend.make, {
                          className: subtext,
                          children: "Utilizado para login e saques"
                        })), React.createElement("div", undefined, React.createElement(Modal$VannaFrontend.make, {
                          title: "Habilitar Autenticação Google",
                          trigger: (function (param) {
                              return React.createElement(Button$VannaFrontend.make, {
                                          isLoading: isEnablingGAuth2FA,
                                          onClick: (function (param) {
                                              Curry.app(enableGAuth2FA, [
                                                    (function (param) {
                                                        return Curry._1(setError, (function (param) {
                                                                      return "Algo deu errado, tente novamente";
                                                                    }));
                                                      }),
                                                    (function (response, errors) {
                                                        if (errors !== undefined && errors.length !== 0) {
                                                          return Curry._1(setError, (function (param) {
                                                                        return "Algo deu errado, tente novamente";
                                                                      }));
                                                        }
                                                        var match = response.userSetupGauth;
                                                        if (match === undefined) {
                                                          return Curry._1(setError, (function (param) {
                                                                        return "Algo deu errado, tente novamente";
                                                                      }));
                                                        }
                                                        var match$1 = match.error;
                                                        if (match$1 !== undefined) {
                                                          var message = match$1.message;
                                                          return Curry._1(setError, (function (param) {
                                                                        return message;
                                                                      }));
                                                        }
                                                        var url = match.url;
                                                        Curry._1(setOpenGoogleAuthModal, (function (param) {
                                                                return true;
                                                              }));
                                                        return Curry._1(setGAuthUrl, (function (param) {
                                                                      return url;
                                                                    }));
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined
                                                  ]);
                                              
                                            }),
                                          disabled: isGoogleAuthEnabled,
                                          children: isGoogleAuthEnabled ? "Habilitado" : "Habilitar"
                                        });
                            }),
                          controlledOpen: match$7[0],
                          content: (function (param) {
                              var match = googleAuthReform.formState;
                              var tmp;
                              if (typeof match === "number") {
                                tmp = null;
                              } else {
                                var error = match._0;
                                tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
                                        type_: /* Error */2,
                                        title: "Ops!",
                                        children: error
                                      }) : null;
                              }
                              return React.createElement(GoogleTwoFactorVerifyForm.Provider.make, Curry._3(GoogleTwoFactorVerifyForm.Provider.makeProps, googleAuthReform, React.createElement("form", {
                                                  onSubmit: (function ($$event) {
                                                      $$event.preventDefault();
                                                      return Curry._1(googleAuthReform.submit, undefined);
                                                    })
                                                }, React.createElement("div", {
                                                      className: qrCodeWrapper
                                                    }, gAuthUrl !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(QrcodeReact, {
                                                                value: gAuthUrl,
                                                                size: 180
                                                              }), React.createElement(Text$VannaFrontend.make, {
                                                                children: "Escanear o código QR no aplicativo Google Authenticator"
                                                              })) : null, React.createElement(FieldGrid$VannaFrontend.make, {
                                                          children: null
                                                        }, React.createElement(GoogleTwoFactorVerifyForm.Field.make, {
                                                              field: /* Token */0,
                                                              render: (function (param) {
                                                                  return React.createElement(TextField$VannaFrontend.make, {
                                                                              label: "Código de Autenticação Google",
                                                                              error: param.error,
                                                                              required: true,
                                                                              mask: "999999",
                                                                              value: param.value,
                                                                              onChange: param.handleChange,
                                                                              wrapperClassName: field,
                                                                              placeholder: "000000"
                                                                            });
                                                                })
                                                            }), React.createElement(Button$VannaFrontend.make, {
                                                              fullWidth: false,
                                                              isLoading: googleAuthReform.isSubmitting,
                                                              type_: /* Submit */1,
                                                              children: "Enviar"
                                                            }))), tmp), undefined));
                            }),
                          onClose: (function (param) {
                              Curry._1(setGAuthUrl, (function (param) {
                                      
                                    }));
                              Curry._4(googleAuthReform.setFieldValue, /* Token */0, "", undefined, undefined);
                              return Curry._1(setOpenGoogleAuthModal, (function (param) {
                                            return false;
                                          }));
                            })
                        }))), React.createElement(Hr$VannaFrontend.make, {}), React.createElement("div", {
                  className: itemWrapper
                }, React.createElement("div", undefined, React.createElement(Text$VannaFrontend.make, {
                          className: text,
                          children: "Autenticação SMS"
                        }), React.createElement(Text$VannaFrontend.make, {
                          className: subtext,
                          children: "Utilizado para login e saques"
                        })), React.createElement("div", undefined, React.createElement(Modal$VannaFrontend.make, {
                          title: "Habilitar Autenticação SMS",
                          trigger: (function (param) {
                              return React.createElement(Button$VannaFrontend.make, {
                                          isLoading: isEnablingSms2FA,
                                          onClick: (function (param) {
                                              Curry.app(enableSms2FA, [
                                                    (function (param) {
                                                        return Curry._1(setError, (function (param) {
                                                                      return "Algo deu errado, tente novamente";
                                                                    }));
                                                      }),
                                                    (function (response, errors) {
                                                        if (errors !== undefined && errors.length !== 0) {
                                                          return Curry._1(setError, (function (param) {
                                                                        return "Algo deu errado, tente novamente";
                                                                      }));
                                                        }
                                                        var match = response.userSetupSms;
                                                        if (match === undefined) {
                                                          return Curry._1(setError, (function (param) {
                                                                        return "Algo deu errado, tente novamente";
                                                                      }));
                                                        }
                                                        var match$1 = match.error;
                                                        if (match$1 === undefined) {
                                                          return Curry._1(setOpenSMSAuthModal, (function (param) {
                                                                        return true;
                                                                      }));
                                                        }
                                                        var message = match$1.message;
                                                        return Curry._1(setError, (function (param) {
                                                                      return message;
                                                                    }));
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined
                                                  ]);
                                              
                                            }),
                                          disabled: isSMSAuthEnabled,
                                          children: isSMSAuthEnabled ? "Habilitado" : "Habilitar"
                                        });
                            }),
                          controlledOpen: match$8[0],
                          content: (function (param) {
                              var match = smsAuthReform.formState;
                              var tmp;
                              if (typeof match === "number") {
                                tmp = null;
                              } else {
                                var error = match._0;
                                tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
                                        type_: /* Error */2,
                                        title: "Ops!",
                                        children: error
                                      }) : null;
                              }
                              return React.createElement(SmsTwoFactorVerifyForm.Provider.make, Curry._3(SmsTwoFactorVerifyForm.Provider.makeProps, smsAuthReform, React.createElement("form", {
                                                  onSubmit: (function ($$event) {
                                                      $$event.preventDefault();
                                                      return Curry._1(smsAuthReform.submit, undefined);
                                                    })
                                                }, React.createElement("div", {
                                                      className: qrCodeWrapper
                                                    }, gAuthUrl !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(QrcodeReact, {
                                                                value: gAuthUrl,
                                                                size: 180
                                                              }), React.createElement(Text$VannaFrontend.make, {
                                                                children: "Informe o código recebido no telefone cadastrado"
                                                              })) : null, React.createElement(FieldGrid$VannaFrontend.make, {
                                                          children: null
                                                        }, React.createElement(SmsTwoFactorVerifyForm.Field.make, {
                                                              field: /* Token */0,
                                                              render: (function (param) {
                                                                  return React.createElement(TextField$VannaFrontend.make, {
                                                                              label: "Código de Autenticação SMS",
                                                                              error: param.error,
                                                                              required: true,
                                                                              mask: "999999",
                                                                              value: param.value,
                                                                              onChange: param.handleChange,
                                                                              wrapperClassName: field,
                                                                              placeholder: "000000"
                                                                            });
                                                                })
                                                            }), React.createElement(Button$VannaFrontend.make, {
                                                              fullWidth: false,
                                                              isLoading: smsAuthReform.isSubmitting,
                                                              type_: /* Submit */1,
                                                              children: "Enviar"
                                                            }))), tmp), undefined));
                            }),
                          onClose: (function (param) {
                              return Curry._1(setOpenSMSAuthModal, (function (param) {
                                            return false;
                                          }));
                            })
                        }))), React.createElement(Hr$VannaFrontend.make, {}), React.createElement("div", {
                  className: itemWrapper
                }, React.createElement("div", undefined, React.createElement(Modal$VannaFrontend.make, {
                          title: "Alterar senha",
                          trigger: (function (param) {
                              return React.createElement(Button$VannaFrontend.make, {
                                          isLoading: false,
                                          onClick: (function (param) {
                                              return Curry._1(setOpenChangePasswordModal, (function (param) {
                                                            return true;
                                                          }));
                                            }),
                                          children: "Alterar senha"
                                        });
                            }),
                          controlledOpen: match$9[0],
                          content: (function (param) {
                              var match = changePasswordReform.formState;
                              var tmp;
                              if (typeof match === "number") {
                                tmp = null;
                              } else {
                                var error = match._0;
                                tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
                                        type_: /* Error */2,
                                        title: "Ops!",
                                        children: error
                                      }) : null;
                              }
                              return React.createElement(ChangePasswordForm.Provider.make, Curry._3(ChangePasswordForm.Provider.makeProps, changePasswordReform, React.createElement("form", {
                                                  onSubmit: (function ($$event) {
                                                      $$event.preventDefault();
                                                      return Curry._1(changePasswordReform.submit, undefined);
                                                    })
                                                }, React.createElement(ChangePasswordForm.Field.make, {
                                                      field: /* OldPassword */0,
                                                      render: (function (param) {
                                                          return React.createElement(TextField$VannaFrontend.make, {
                                                                      label: "Sua senha atual",
                                                                      error: param.error,
                                                                      type_: /* Password */4,
                                                                      value: param.value,
                                                                      onChange: param.handleChange
                                                                    });
                                                        })
                                                    }), React.createElement(ChangePasswordForm.Field.make, {
                                                      field: /* NewPassword */1,
                                                      render: (function (param) {
                                                          return React.createElement(TextField$VannaFrontend.make, {
                                                                      label: "Sua nova senha",
                                                                      error: param.error,
                                                                      type_: /* Password */4,
                                                                      value: param.value,
                                                                      onChange: param.handleChange
                                                                    });
                                                        })
                                                    }), React.createElement(ChangePasswordForm.Field.make, {
                                                      field: /* NewPasswordConfirm */2,
                                                      render: (function (param) {
                                                          return React.createElement(TextField$VannaFrontend.make, {
                                                                      label: "Confirme sua nova senha",
                                                                      error: param.error,
                                                                      type_: /* Password */4,
                                                                      value: param.value,
                                                                      onChange: param.handleChange
                                                                    });
                                                        })
                                                    }), React.createElement(TwoFactorForm$VannaFrontend.make, {
                                                      isGoogleAuthEnabled: isGoogleAuthEnabled,
                                                      isSmsAuthEnabled: isSMSAuthEnabled,
                                                      onChange: (function (authMethod, value) {
                                                          return Curry._4(changePasswordReform.setFieldValue, authMethod === /* Google */0 ? /* GoogleAuthCode */3 : /* SmsAuthCode */4, value, undefined, undefined);
                                                        })
                                                    }), tmp, React.createElement(Button$VannaFrontend.make, {
                                                      isLoading: changePasswordReform.isSubmitting,
                                                      type_: /* Submit */1,
                                                      children: "Alterar senha"
                                                    })), undefined));
                            }),
                          onClose: (function (param) {
                              return Curry._1(setOpenChangePasswordModal, (function (param) {
                                            return false;
                                          }));
                            })
                        }))));
}

var make = SecurityForm;

exports.Styles = Styles;
exports.StateLenses = StateLenses;
exports.ChangePasswordStateLenses = ChangePasswordStateLenses;
exports.GoogleTwoFactorVerifyForm = GoogleTwoFactorVerifyForm;
exports.SmsTwoFactorVerifyForm = SmsTwoFactorVerifyForm;
exports.ChangePasswordForm = ChangePasswordForm;
exports.SecurityFormFragment = SecurityFormFragment;
exports.SecurityFormEnableGAuth2FA = SecurityFormEnableGAuth2FA;
exports.SecurityFormVerifyGAuth2FA = SecurityFormVerifyGAuth2FA;
exports.SecurityFormEnableSMS2FAMutation = SecurityFormEnableSMS2FAMutation;
exports.SecurityFormVerifySMS2FAMutation = SecurityFormVerifySMS2FAMutation;
exports.ChangePasswordMutation = ChangePasswordMutation;
exports.make = make;
/* panel Not a pure module */
