'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Fi = require("react-icons/fi");
var Format = require("date-fns/format");
var Badge$VannaFrontend = require("./Badge.bs.js");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Table$VannaFrontend = require("./Table.bs.js");
var Tooltip$VannaFrontend = require("./Tooltip.bs.js");
var IconButton$VannaFrontend = require("./IconButton.bs.js");
var ConfirmModal$VannaFrontend = require("./ConfirmModal.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var RecurrentChargeUpsertForm$VannaFrontend = require("./RecurrentChargeUpsertForm.bs.js");
var RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend = require("../__generated__/RecurrentChargesTable_RecurrentChargePauseMutation_graphql.bs.js");
var RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend = require("../__generated__/RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql.bs.js");
var RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend = require("../__generated__/RecurrentChargesTable_RecurrentChargeResumeMutation_graphql.bs.js");

var CustomTable = Table$VannaFrontend.Make({});

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.node,
              variables: RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var RecurrentChargePauseMutation_make_recurrentChargePauseInput = RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Utils.make_recurrentChargePauseInput;

var RecurrentChargePauseMutation_makeVariables = RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Utils.makeVariables;

var RecurrentChargePauseMutation_make_response_recurrentChargePause_recurrentCharge = RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargePause_recurrentCharge;

var RecurrentChargePauseMutation_make_response_recurrentChargePause_error = RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargePause_error;

var RecurrentChargePauseMutation_make_response_recurrentChargePause = RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargePause;

var RecurrentChargePauseMutation_makeOptimisticResponse = RecurrentChargesTable_RecurrentChargePauseMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var RecurrentChargePauseMutation = {
  make_recurrentChargePauseInput: RecurrentChargePauseMutation_make_recurrentChargePauseInput,
  makeVariables: RecurrentChargePauseMutation_makeVariables,
  make_response_recurrentChargePause_recurrentCharge: RecurrentChargePauseMutation_make_response_recurrentChargePause_recurrentCharge,
  make_response_recurrentChargePause_error: RecurrentChargePauseMutation_make_response_recurrentChargePause_error,
  make_response_recurrentChargePause: RecurrentChargePauseMutation_make_response_recurrentChargePause,
  makeOptimisticResponse: RecurrentChargePauseMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

var Internal$1 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.node,
              variables: RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var RecurrentChargeResumeMutation_make_recurrentChargeResumeInput = RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Utils.make_recurrentChargeResumeInput;

var RecurrentChargeResumeMutation_makeVariables = RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Utils.makeVariables;

var RecurrentChargeResumeMutation_make_response_recurrentChargeResume_recurrentCharge = RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeResume_recurrentCharge;

var RecurrentChargeResumeMutation_make_response_recurrentChargeResume_error = RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeResume_error;

var RecurrentChargeResumeMutation_make_response_recurrentChargeResume = RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeResume;

var RecurrentChargeResumeMutation_makeOptimisticResponse = RecurrentChargesTable_RecurrentChargeResumeMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var RecurrentChargeResumeMutation = {
  make_recurrentChargeResumeInput: RecurrentChargeResumeMutation_make_recurrentChargeResumeInput,
  makeVariables: RecurrentChargeResumeMutation_makeVariables,
  make_response_recurrentChargeResume_recurrentCharge: RecurrentChargeResumeMutation_make_response_recurrentChargeResume_recurrentCharge,
  make_response_recurrentChargeResume_error: RecurrentChargeResumeMutation_make_response_recurrentChargeResume_error,
  make_response_recurrentChargeResume: RecurrentChargeResumeMutation_make_response_recurrentChargeResume,
  makeOptimisticResponse: RecurrentChargeResumeMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation$1,
  use: use$1
};

var Internal$2 = {};

function commitMutation$2(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.node,
              variables: RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var RecurrentChargeDeleteMutation_make_recurrentChargeDeleteInput = RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Utils.make_recurrentChargeDeleteInput;

var RecurrentChargeDeleteMutation_makeVariables = RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Utils.makeVariables;

var RecurrentChargeDeleteMutation_make_response_recurrentChargeDelete_error = RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeDelete_error;

var RecurrentChargeDeleteMutation_make_response_recurrentChargeDelete = RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Utils.make_response_recurrentChargeDelete;

var RecurrentChargeDeleteMutation_makeOptimisticResponse = RecurrentChargesTable_RecurrentChargeDeleteMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var RecurrentChargeDeleteMutation = {
  make_recurrentChargeDeleteInput: RecurrentChargeDeleteMutation_make_recurrentChargeDeleteInput,
  makeVariables: RecurrentChargeDeleteMutation_makeVariables,
  make_response_recurrentChargeDelete_error: RecurrentChargeDeleteMutation_make_response_recurrentChargeDelete_error,
  make_response_recurrentChargeDelete: RecurrentChargeDeleteMutation_make_response_recurrentChargeDelete,
  makeOptimisticResponse: RecurrentChargeDeleteMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$2,
  use: use$2
};

function RecurrentChargesTable(Props) {
  var kind = Props.kind;
  var recurrentCharges = Props.recurrentCharges;
  var queryRef = Props.query;
  var match = React.useState(function () {
        
      });
  var setPauseError = match[1];
  var pauseError = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setResumeError = match$1[1];
  var startError = match$1[0];
  var match$2 = use(undefined);
  var isPausing = match$2[1];
  var recurrentChargePause = match$2[0];
  var match$3 = use$1(undefined);
  var isResuming = match$3[1];
  var recurrentChargeResume = match$3[0];
  var match$4 = use$2(undefined);
  var isDeletting = match$4[1];
  var recurrentChargeDelete = match$4[0];
  var deleteTableColumn = Table$VannaFrontend.column("", false, (function (recurrentCharge, param) {
          return React.createElement(ConfirmModal$VannaFrontend.make, {
                      trigger: (function (open_) {
                          return React.createElement(IconButton$VannaFrontend.make, {
                                      onClick: (function (param, param$1, param$2) {
                                          return Curry._1(open_, undefined);
                                        }),
                                      children: React.createElement(Tooltip$VannaFrontend.make, {
                                            message: "Remover assinatura",
                                            children: React.createElement(Fi.FiX, {})
                                          })
                                    });
                        }),
                      title: "Remover assinatura",
                      message: "Você tem certeza de que deseja remover esta assinatura?",
                      error: isDeletting ? undefined : pauseError,
                      isLoading: isDeletting,
                      onConfirm: (function (param) {
                          Curry.app(recurrentChargeDelete, [
                                (function (param) {
                                    return Curry._1(setPauseError, (function (param) {
                                                  return "Algo deu errado, tente novamente";
                                                }));
                                  }),
                                (function (response, errors) {
                                    var exit = 0;
                                    if (errors !== undefined) {
                                      if (errors.length !== 0) {
                                        return Curry._1(setPauseError, (function (param) {
                                                      return "Algo deu errado, tente novamente";
                                                    }));
                                      }
                                      exit = 1;
                                    } else {
                                      exit = 1;
                                    }
                                    if (exit === 1) {
                                      var match = response.recurrentChargeDelete;
                                      var exit$1 = 0;
                                      if (match !== undefined) {
                                        var match$1 = match.error;
                                        if (match$1 !== undefined) {
                                          var message = match$1.message;
                                          return Curry._1(setPauseError, (function (param) {
                                                        return message;
                                                      }));
                                        }
                                        exit$1 = 2;
                                      } else {
                                        exit$1 = 2;
                                      }
                                      if (exit$1 === 2) {
                                        return Curry._1(setPauseError, (function (param) {
                                                      
                                                    }));
                                      }
                                      
                                    }
                                    
                                  }),
                                undefined,
                                undefined,
                                undefined,
                                (function (store, response) {
                                    var match = response.recurrentChargeDelete;
                                    if (match === undefined) {
                                      return ;
                                    }
                                    if (match.error !== undefined) {
                                      return ;
                                    }
                                    var me = store.getRoot().getLinkedRecord("me", undefined);
                                    if (me == null) {
                                      return ;
                                    }
                                    var recurrentCharges = ReasonRelay.RecordProxy.getLinkedRecords(me, "recurrentCharges", undefined, undefined);
                                    if (recurrentCharges === undefined) {
                                      return ;
                                    }
                                    var deletedRecurrentCharge = store.get(recurrentCharge.id);
                                    var deletedRecurrentCharge$1 = (deletedRecurrentCharge == null) ? undefined : Caml_option.some(deletedRecurrentCharge);
                                    me.setLinkedRecords(Belt_Array.keep(recurrentCharges, (function (recurrentCharge) {
                                                return Caml_obj.caml_compare(recurrentCharge, deletedRecurrentCharge$1) !== 0;
                                              })), "recurrentCharges", undefined);
                                    
                                  }),
                                {
                                  input: {
                                    recurrentChargeId: recurrentCharge.id
                                  }
                                },
                                undefined,
                                undefined
                              ]);
                          
                        })
                    });
        }));
  var editTableColumn = Table$VannaFrontend.column("", false, (function (recurrentCharge, param) {
          return React.createElement(Modal$VannaFrontend.make, {
                      title: "Atualizar assinatura",
                      trigger: (function (dispatch) {
                          return React.createElement(IconButton$VannaFrontend.make, {
                                      onClick: (function (param, param$1, param$2) {
                                          return Curry._1(dispatch, /* Open */2);
                                        }),
                                      children: React.createElement(Fi.FiEye, {})
                                    });
                        }),
                      content: (function (dispatch) {
                          return React.createElement(RecurrentChargeUpsertForm$VannaFrontend.make, {
                                      recurrentCharge: Caml_option.some(recurrentCharge.fragmentRefs),
                                      dispatchModal: dispatch,
                                      query: queryRef
                                    });
                        })
                    });
        }));
  var tmp;
  switch (kind) {
    case /* Ongoing */0 :
        tmp = {
          hd: Table$VannaFrontend.column("Data da próxima cobrança", undefined, (function (recurrentCharge, param) {
                  var date = recurrentCharge.nextChargeDate;
                  if (date !== undefined) {
                    return Format(new Date(date), "dd/MM/yyyy kk:mm");
                  } else {
                    return "-";
                  }
                })),
          tl: {
            hd: editTableColumn,
            tl: {
              hd: Table$VannaFrontend.column("", false, (function (recurrentCharge, param) {
                      return React.createElement(Tooltip$VannaFrontend.make, {
                                  message: "Página de pagamento",
                                  children: React.createElement("a", {
                                        href: "/assinatura/" + recurrentCharge.id,
                                        target: "_blank"
                                      }, React.createElement(IconButton$VannaFrontend.make, {
                                            onClick: (function (param, _setLoading, param$1) {
                                                
                                              }),
                                            children: React.createElement(Fi.FiLink, {})
                                          }))
                                });
                    })),
              tl: {
                hd: Table$VannaFrontend.column("", false, (function (recurrentCharge, param) {
                        return React.createElement(ConfirmModal$VannaFrontend.make, {
                                    trigger: (function (open_) {
                                        return React.createElement(IconButton$VannaFrontend.make, {
                                                    onClick: (function (param, param$1, param$2) {
                                                        return Curry._1(open_, undefined);
                                                      }),
                                                    children: React.createElement(Tooltip$VannaFrontend.make, {
                                                          message: "Pausar assinatura",
                                                          children: React.createElement(Fi.FiPause, {})
                                                        })
                                                  });
                                      }),
                                    title: "Confirmar pausa?",
                                    message: "Você tem certeza de que deseja pausar esta assinatura?",
                                    error: isPausing ? undefined : pauseError,
                                    isLoading: isPausing,
                                    onConfirm: (function (param) {
                                        Curry.app(recurrentChargePause, [
                                              (function (param) {
                                                  return Curry._1(setPauseError, (function (param) {
                                                                return "Algo deu errado, tente novamente";
                                                              }));
                                                }),
                                              (function (response, errors) {
                                                  var exit = 0;
                                                  if (errors !== undefined) {
                                                    if (errors.length !== 0) {
                                                      return Curry._1(setPauseError, (function (param) {
                                                                    return "Algo deu errado, tente novamente";
                                                                  }));
                                                    }
                                                    exit = 1;
                                                  } else {
                                                    exit = 1;
                                                  }
                                                  if (exit === 1) {
                                                    var match = response.recurrentChargePause;
                                                    var exit$1 = 0;
                                                    if (match !== undefined) {
                                                      var match$1 = match.error;
                                                      if (match$1 !== undefined) {
                                                        var message = match$1.message;
                                                        return Curry._1(setPauseError, (function (param) {
                                                                      return message;
                                                                    }));
                                                      }
                                                      exit$1 = 2;
                                                    } else {
                                                      exit$1 = 2;
                                                    }
                                                    if (exit$1 === 2) {
                                                      return Curry._1(setPauseError, (function (param) {
                                                                    
                                                                  }));
                                                    }
                                                    
                                                  }
                                                  
                                                }),
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              {
                                                input: {
                                                  recurrentChargeId: recurrentCharge.id
                                                }
                                              },
                                              undefined,
                                              undefined
                                            ]);
                                        
                                      })
                                  });
                      })),
                tl: {
                  hd: deleteTableColumn,
                  tl: /* [] */0
                }
              }
            }
          }
        };
        break;
    case /* AwaitingSubscription */1 :
        tmp = {
          hd: Table$VannaFrontend.column("", false, (function (recurrentCharge, param) {
                  return React.createElement(Tooltip$VannaFrontend.make, {
                              message: "Página de pagamento",
                              children: React.createElement("a", {
                                    href: "/assinatura/" + recurrentCharge.id,
                                    target: "_blank"
                                  }, React.createElement(IconButton$VannaFrontend.make, {
                                        onClick: (function (param, _setLoading, param$1) {
                                            
                                          }),
                                        children: React.createElement(Fi.FiLink, {})
                                      }))
                            });
                })),
          tl: {
            hd: editTableColumn,
            tl: {
              hd: deleteTableColumn,
              tl: /* [] */0
            }
          }
        };
        break;
    case /* Stopped */2 :
        tmp = {
          hd: Table$VannaFrontend.column("", false, (function (recurrentCharge, param) {
                  return React.createElement(Tooltip$VannaFrontend.make, {
                              message: "Página de pagamento",
                              children: React.createElement("a", {
                                    href: "/assinatura/" + recurrentCharge.id,
                                    target: "_blank"
                                  }, React.createElement(IconButton$VannaFrontend.make, {
                                        onClick: (function (param, _setLoading, param$1) {
                                            
                                          }),
                                        children: React.createElement(Fi.FiLink, {})
                                      }))
                            });
                })),
          tl: {
            hd: Table$VannaFrontend.column("", false, (function (recurrentCharge, param) {
                    return React.createElement(ConfirmModal$VannaFrontend.make, {
                                trigger: (function (open_) {
                                    return React.createElement(Tooltip$VannaFrontend.make, {
                                                message: "Retomar assinatura",
                                                children: React.createElement(IconButton$VannaFrontend.make, {
                                                      onClick: (function (param, param$1, param$2) {
                                                          return Curry._1(open_, undefined);
                                                        }),
                                                      children: React.createElement(Fi.FiPlay, {})
                                                    })
                                              });
                                  }),
                                title: "Confirmar reinício da assinatura?",
                                message: "Você tem certeza de que deseja retomar esta assinatura?",
                                error: isResuming ? undefined : startError,
                                isLoading: isResuming,
                                onConfirm: (function (param) {
                                    Curry.app(recurrentChargeResume, [
                                          (function (param) {
                                              return Curry._1(setResumeError, (function (param) {
                                                            return "Algo deu errado, tente novamente";
                                                          }));
                                            }),
                                          (function (response, errors) {
                                              var exit = 0;
                                              if (errors !== undefined) {
                                                if (errors.length !== 0) {
                                                  return Curry._1(setResumeError, (function (param) {
                                                                return "Algo deu errado, tente novamente";
                                                              }));
                                                }
                                                exit = 1;
                                              } else {
                                                exit = 1;
                                              }
                                              if (exit === 1) {
                                                var match = response.recurrentChargeResume;
                                                var exit$1 = 0;
                                                if (match !== undefined) {
                                                  var match$1 = match.error;
                                                  if (match$1 !== undefined) {
                                                    var message = match$1.message;
                                                    return Curry._1(setResumeError, (function (param) {
                                                                  return message;
                                                                }));
                                                  }
                                                  exit$1 = 2;
                                                } else {
                                                  exit$1 = 2;
                                                }
                                                if (exit$1 === 2) {
                                                  return Curry._1(setResumeError, (function (param) {
                                                                
                                                              }));
                                                }
                                                
                                              }
                                              
                                            }),
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          {
                                            input: {
                                              recurrentChargeId: recurrentCharge.id
                                            }
                                          },
                                          undefined,
                                          undefined
                                        ]);
                                    
                                  })
                              });
                  })),
            tl: {
              hd: editTableColumn,
              tl: {
                hd: deleteTableColumn,
                tl: /* [] */0
              }
            }
          }
        };
        break;
    case /* PaymentFailure */3 :
        tmp = {
          hd: Table$VannaFrontend.column("Razão da falha", undefined, (function (recurrentCharge, param) {
                  return Belt_Option.getWithDefault(recurrentCharge.failureReason, "-");
                })),
          tl: {
            hd: Table$VannaFrontend.column("", false, (function (recurrentCharge, param) {
                    return React.createElement(Tooltip$VannaFrontend.make, {
                                message: "Página de pagamento",
                                children: React.createElement("a", {
                                      href: "/assinatura/" + recurrentCharge.id,
                                      target: "_blank"
                                    }, React.createElement(IconButton$VannaFrontend.make, {
                                          onClick: (function (param, _setLoading, param$1) {
                                              
                                            }),
                                          children: React.createElement(Fi.FiLink, {})
                                        }))
                              });
                  })),
            tl: {
              hd: editTableColumn,
              tl: {
                hd: deleteTableColumn,
                tl: /* [] */0
              }
            }
          }
        };
        break;
    case /* RejectedByCustomer */4 :
        tmp = {
          hd: Table$VannaFrontend.column("", false, (function (recurrentCharge, param) {
                  return React.createElement(Tooltip$VannaFrontend.make, {
                              message: "Página de pagamento",
                              children: React.createElement("a", {
                                    href: "/assinatura/" + recurrentCharge.id,
                                    target: "_blank"
                                  }, React.createElement(IconButton$VannaFrontend.make, {
                                        onClick: (function (param, _setLoading, param$1) {
                                            
                                          }),
                                        children: React.createElement(Fi.FiLink, {})
                                      }))
                            });
                })),
          tl: {
            hd: editTableColumn,
            tl: {
              hd: deleteTableColumn,
              tl: /* [] */0
            }
          }
        };
        break;
    
  }
  return React.createElement(CustomTable.make, {
              data: recurrentCharges,
              columns: {
                hd: Table$VannaFrontend.column("Cliente", undefined, (function (recurrentCharge, param) {
                        return Belt_Option.getWithDefault(recurrentCharge.customer.name, "");
                      })),
                tl: {
                  hd: Table$VannaFrontend.column("Descrição", undefined, (function (recurrentCharge, param) {
                          return Belt_Option.getWithDefault(recurrentCharge.description, "");
                        })),
                  tl: {
                    hd: Table$VannaFrontend.column("Valor", undefined, (function (recurrentCharge, param) {
                            return Money$VannaFrontend.formatInt(recurrentCharge.amount);
                          })),
                    tl: {
                      hd: Table$VannaFrontend.column("Situação", undefined, (function (_recurrentCharge, param) {
                              switch (kind) {
                                case /* Ongoing */0 :
                                    return React.createElement(Badge$VannaFrontend.make, {
                                                mode: /* Success */1,
                                                text: "Assinado"
                                              });
                                case /* AwaitingSubscription */1 :
                                    return React.createElement(Badge$VannaFrontend.make, {
                                                mode: /* Default */0,
                                                text: "Criado"
                                              });
                                case /* Stopped */2 :
                                    return React.createElement(Badge$VannaFrontend.make, {
                                                mode: /* Warn */4,
                                                text: "Pausada"
                                              });
                                case /* PaymentFailure */3 :
                                    return React.createElement(Badge$VannaFrontend.make, {
                                                mode: /* Error */2,
                                                text: "Falha"
                                              });
                                case /* RejectedByCustomer */4 :
                                    return React.createElement(Badge$VannaFrontend.make, {
                                                mode: /* Error */2,
                                                text: "Rejeitado"
                                              });
                                
                              }
                            })),
                      tl: tmp
                    }
                  }
                }
              },
              getItemId: (function (recurrentCharge) {
                  return recurrentCharge.id;
                }),
              withPadding: false
            });
}

var make = RecurrentChargesTable;

exports.CustomTable = CustomTable;
exports.RecurrentChargePauseMutation = RecurrentChargePauseMutation;
exports.RecurrentChargeResumeMutation = RecurrentChargeResumeMutation;
exports.RecurrentChargeDeleteMutation = RecurrentChargeDeleteMutation;
exports.make = make;
/* CustomTable Not a pure module */
