'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("../components/Modal.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var Table$VannaFrontend = require("../components/Table.bs.js");
var Button$VannaFrontend = require("../components/Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var RequireKYC$VannaFrontend = require("../components/RequireKYC.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var DashboardBalance$VannaFrontend = require("../components/DashboardBalance.bs.js");
var PixQuery_graphql$VannaFrontend = require("../__generated__/PixQuery_graphql.bs.js");
var PixWithdrawalModal$VannaFrontend = require("../components/PixWithdrawalModal.bs.js");
var PixRefetchQuery_graphql$VannaFrontend = require("../__generated__/PixRefetchQuery_graphql.bs.js");
var PixConnection_query_graphql$VannaFrontend = require("../__generated__/PixConnection_query_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.width("auto"),
      tl: /* [] */0
    });

var balancePanel = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 20
          }),
      tl: {
        hd: Css.padding({
              NAME: "px",
              VAL: 25
            }),
        tl: /* [] */0
      }
    });

var Styles = {
  panel: panel,
  balancePanel: balancePanel
};

var getConnectionNodes = PixConnection_query_graphql$VannaFrontend.Utils.getConnectionNodes;

var Internal = {};

function internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = ReasonRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = ReasonRelay.mapRenderPolicy(renderPolicy);
  if (tmp$2 !== undefined) {
    tmp.UNSTABLE_renderPolicy = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$3 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var InternalRefetch = {
  internal_makeRefetchableFnOpts: internal_makeRefetchableFnOpts
};

function useRefetchable(fRef) {
  var match = Hooks.useRefetchableFragment(PixConnection_query_graphql$VannaFrontend.node, fRef);
  var refetchFn = match[1];
  var data = ReasonRelay_Internal.internal_useConvertedValue(PixConnection_query_graphql$VannaFrontend.Internal.convertFragment, match[0]);
  return [
          data,
          (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return Curry._2(refetchFn, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(PixRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        ];
}

function use(fRef) {
  var data = Hooks.useFragment(PixConnection_query_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(PixConnection_query_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(PixConnection_query_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return PixConnection_query_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function usePagination(fr) {
  var p = Hooks.usePaginationFragment(PixConnection_query_graphql$VannaFrontend.node, fr);
  var data = ReasonRelay_Internal.internal_useConvertedValue(PixConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(PixRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

function useBlockingPagination(fRef) {
  var p = Hooks.useBlockingPaginationFragment(PixConnection_query_graphql$VannaFrontend.node, fRef);
  var data = ReasonRelay_Internal.internal_useConvertedValue(PixConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(PixRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

var makeRefetchVariables = PixRefetchQuery_graphql$VannaFrontend.Types.makeRefetchVariables;

var PixConnectionFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Internal: Internal,
  InternalRefetch: InternalRefetch,
  useRefetchable: useRefetchable,
  use: use,
  useOpt: useOpt,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination,
  makeRefetchVariables: makeRefetchVariables
};

var Internal$1 = {};

function use$1(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(PixQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(PixQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(PixQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(PixQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, PixQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, PixQuery_graphql$VannaFrontend.node, PixQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: PixQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, PixQuery_graphql$VannaFrontend.node, PixQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return PixQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(PixQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(PixQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var PixQuery = {
  Types: undefined,
  Internal: Internal$1,
  use: use$1,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var PixTable = Table$VannaFrontend.Make({});

function Pix$Inner(Props) {
  var $$navigator = Props.navigator;
  var queryRef = Props.query;
  var visualization = Props.visualization;
  var match = usePagination(queryRef);
  var hasNext = match.hasNext;
  var loadNext = match.loadNext;
  var transactions = Curry._1(getConnectionNodes, match.data.transactions);
  var balance = Belt_Option.mapWithDefault(visualization, 0, (function (visualization) {
          return visualization.pixAccountBalance;
        }));
  return React.createElement(RequireKYC$VannaFrontend.make, {
              navigator: $$navigator,
              fragment: queryRef,
              children: (function (param) {
                  return React.createElement(React.Fragment, undefined, React.createElement(PageHeader$VannaFrontend.make, {
                                  title: "Conta PIX"
                                }), React.createElement(Panel$VannaFrontend.make, {
                                  className: balancePanel,
                                  children: null
                                }, React.createElement(DashboardBalance$VannaFrontend.Balance.make, {
                                      amount: balance,
                                      label: "Saldo na conta PIX"
                                    }), React.createElement(Modal$VannaFrontend.make, {
                                      title: "Sacar",
                                      trigger: (function (dispatch) {
                                          return React.createElement(Button$VannaFrontend.make, {
                                                      onClick: (function (param) {
                                                          return Curry._1(dispatch, /* Open */2);
                                                        }),
                                                      children: "Sacar"
                                                    });
                                        }),
                                      content: (function (dispatch) {
                                          return React.createElement(PixWithdrawalModal$VannaFrontend.make, {
                                                      query: queryRef,
                                                      dispatchModal: dispatch,
                                                      balance: balance
                                                    });
                                        })
                                    })), React.createElement(Panel$VannaFrontend.make, {
                                  className: panel,
                                  children: React.createElement(PixTable.make, {
                                        data: transactions,
                                        columns: {
                                          hd: Table$VannaFrontend.column("Descrição", undefined, (function (transaction, param) {
                                                  var match = transaction.metadata;
                                                  if (typeof match === "string") {
                                                    return null;
                                                  }
                                                  var variant = match.NAME;
                                                  if (variant === "TransactionMetaPixSent" || variant === "TransactionMetaEvent" || variant === "TransactionMetaPixReceived") {
                                                    return Belt_Option.getWithDefault(match.VAL.description, "");
                                                  } else {
                                                    return null;
                                                  }
                                                })),
                                          tl: {
                                            hd: Table$VannaFrontend.column("Valor", undefined, (function (transaction, param) {
                                                    return Money$VannaFrontend.format(Money$VannaFrontend.make({
                                                                    TAG: /* Cents */1,
                                                                    _0: transaction.amount
                                                                  }));
                                                  })),
                                            tl: /* [] */0
                                          }
                                        },
                                        getItemId: (function (transaction) {
                                            return transaction.id;
                                          }),
                                        hasNextPage: hasNext,
                                        loadMore: (function (param) {
                                            Curry._3(loadNext, 20, undefined, undefined);
                                            
                                          })
                                      })
                                }));
                })
            });
}

var Inner = {
  make: Pix$Inner
};

function Pix(Props) {
  var $$navigator = Props.navigator;
  var queryData = use$1(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var me = queryData.me;
  if (me !== undefined) {
    return React.createElement(PrivateLayout$VannaFrontend.make, {
                navigator: $$navigator,
                children: React.createElement(Pix$Inner, {
                      navigator: $$navigator,
                      query: me.fragmentRefs,
                      visualization: me.visualization
                    })
              });
  } else {
    return null;
  }
}

var pageSize = 20;

var Balance;

var make = Pix;

exports.Styles = Styles;
exports.pageSize = pageSize;
exports.PixConnectionFragment = PixConnectionFragment;
exports.PixQuery = PixQuery;
exports.Balance = Balance;
exports.PixTable = PixTable;
exports.Inner = Inner;
exports.make = make;
/* panel Not a pure module */
