'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Theme$VannaFrontend = require("../Theme.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var RouterHelpers$VannaFrontend = require("../RouterHelpers.bs.js");
var SideMenu_whitelabelInfo_graphql$VannaFrontend = require("../__generated__/SideMenu_whitelabelInfo_graphql.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

var sideMenuWrapper = Curry._1(Css.style, {
      hd: Css.maxHeight({
            NAME: "vh",
            VAL: 100.0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.boxSizing("borderBox"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                    hd: Css.display("none"),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.overflowY("auto"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var brand = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("center"),
        tl: {
          hd: Css.margin2({
                NAME: "px",
                VAL: 5
              }, {
                NAME: "px",
                VAL: 0
              }),
          tl: {
            hd: Css.flexShrink(0.0),
            tl: /* [] */0
          }
        }
      }
    });

var brandImage = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "px",
            VAL: 40
          }),
      tl: {
        hd: Css.width("auto"),
        tl: {
          hd: Css.cursor("pointer"),
          tl: {
            hd: Css.padding2({
                  NAME: "px",
                  VAL: 15
                }, {
                  NAME: "px",
                  VAL: 0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var navigation = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: /* [] */0
    });

var navigationItemWrapper = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: /* [] */0
    });

function navigationItem(isCurrent) {
  return Curry._1(Css.style, {
              hd: Css.textAlign("left"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("flexStart"),
                    tl: {
                      hd: Css.cursor("pointer"),
                      tl: {
                        hd: Css.outlineStyle("none"),
                        tl: {
                          hd: Css.padding2({
                                NAME: "rem",
                                VAL: 0.6
                              }, {
                                NAME: "px",
                                VAL: 20
                              }),
                          tl: {
                            hd: Css.fontSize({
                                  NAME: "rem",
                                  VAL: 0.95
                                }),
                            tl: {
                              hd: Css.marginBottom({
                                    NAME: "px",
                                    VAL: 14
                                  }),
                              tl: {
                                hd: Css.marginTop({
                                      NAME: "px",
                                      VAL: 14
                                    }),
                                tl: {
                                  hd: Css.color(Theme$VannaFrontend.Colors.textSecondary),
                                  tl: {
                                    hd: Css.transitionDuration(1),
                                    tl: {
                                      hd: Css.backgroundColor("transparent"),
                                      tl: {
                                        hd: Css.width({
                                              NAME: "percent",
                                              VAL: 100.0
                                            }),
                                        tl: {
                                          hd: Css.borderStyle("none"),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.backgroundColor(Theme$VannaFrontend.Colors.secondary),
                                                  tl: /* [] */0
                                                }),
                                            tl: isCurrent ? ({
                                                  hd: Css.color(Theme$VannaFrontend.Colors.primary),
                                                  tl: {
                                                    hd: Css.before({
                                                          hd: Css.contentRule({
                                                                NAME: "text",
                                                                VAL: " "
                                                              }),
                                                          tl: {
                                                            hd: Css.width({
                                                                  NAME: "px",
                                                                  VAL: 4
                                                                }),
                                                            tl: {
                                                              hd: Css.height({
                                                                    NAME: "percent",
                                                                    VAL: 100.0
                                                                  }),
                                                              tl: {
                                                                hd: Css.position("absolute"),
                                                                tl: {
                                                                  hd: Css.borderTopRightRadius({
                                                                        NAME: "px",
                                                                        VAL: 4
                                                                      }),
                                                                  tl: {
                                                                    hd: Css.borderBottomRightRadius({
                                                                          NAME: "px",
                                                                          VAL: 4
                                                                        }),
                                                                    tl: {
                                                                      hd: Css.left({
                                                                            NAME: "px",
                                                                            VAL: 0
                                                                          }),
                                                                      tl: {
                                                                        hd: Css.top({
                                                                              NAME: "px",
                                                                              VAL: 0
                                                                            }),
                                                                        tl: {
                                                                          hd: Css.backgroundColor(Theme$VannaFrontend.Colors.primary),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }) : /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var navigationSplitter = Curry._1(Css.style, {
      hd: Css.margin2({
            NAME: "px",
            VAL: 10
          }, {
            NAME: "px",
            VAL: 5
          }),
      tl: {
        hd: Css.borderBottom({
              NAME: "px",
              VAL: 1
            }, "solid", Theme$VannaFrontend.Colors.border),
        tl: /* [] */0
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.margin2({
            NAME: "px",
            VAL: 0
          }, {
            NAME: "px",
            VAL: 14
          }),
      tl: /* [] */0
    });

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  sideMenuWrapper: sideMenuWrapper,
  brand: brand,
  brandImage: brandImage,
  navigation: navigation,
  navigationItemWrapper: navigationItemWrapper,
  navigationItem: navigationItem,
  navigationSplitter: navigationSplitter,
  label: label
};

function SideMenu$SideMenuItem(Props) {
  var onClick = Props.onClick;
  var route = Props.route;
  var url = Props.url;
  var icon = Props.icon;
  var label$1 = Props.label;
  return React.createElement("div", {
              className: navigationItemWrapper
            }, React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: navigationItem(route !== undefined ? Caml_obj.caml_equal(route, RouterHelpers$VannaFrontend.pathToRoute(url)) : false),
                        tl: {
                          hd: "active",
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      return Curry._1(onClick, undefined);
                    })
                }, icon, React.createElement("span", {
                      className: label
                    }, label$1)));
}

var SideMenuItem = {
  make: SideMenu$SideMenuItem
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(SideMenu_whitelabelInfo_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(SideMenu_whitelabelInfo_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(SideMenu_whitelabelInfo_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return SideMenu_whitelabelInfo_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var SideMenuFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function SideMenu(Props) {
  var queryRef = Props.query;
  var $$navigator = Props.navigator;
  var items = Props.items;
  var whitelabelInfo = use(queryRef);
  var iconSrc = whitelabelInfo.iconSrc;
  return React.createElement("div", {
              className: sideMenuWrapper
            }, React.createElement("div", {
                  className: brand
                }, React.createElement("img", {
                      className: brandImage,
                      src: iconSrc !== undefined && iconSrc !== "" ? iconSrc : "/icon.png",
                      onClick: (function (param) {
                          return Curry._1($$navigator.push, /* Dashboard */3);
                        })
                    })), React.createElement("div", {
                  className: navigationSplitter
                }), React.createElement("nav", {
                  className: navigation
                }, Belt_List.toArray(items)), React.createElement("div", {
                  className: navigationSplitter
                }));
}

var make = SideMenu;

exports.Styles = Styles;
exports.SideMenuItem = SideMenuItem;
exports.SideMenuFragment = SideMenuFragment;
exports.make = make;
/* sideMenuWrapper Not a pure module */
