'use strict';

var Numeral = require("numeral");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var BignumberJs = require("bignumber.js").default;

var CustomNumeral = {};

Numeral.register("locale", "brasil", {
      delimiters: {
        thousands: ".",
        decimal: ","
      },
      abbreviations: {
        thousand: "mil",
        million: "milhões",
        billion: "B",
        trillion: "T"
      },
      ordinal: (function (_number) {
          return "º";
        }),
      currency: {
        symbol: "R$"
      }
    });

Numeral.locale("brasil");

function make(input) {
  if (input.TAG === /* Decimal */0) {
    return /* Money */{
            _0: new BignumberJs(input._0).times(100).decimalPlaces(0).toNumber()
          };
  } else {
    return /* Money */{
            _0: input._0
          };
  }
}

function unwrap(a) {
  return a._0;
}

function $plus(a, b) {
  var left = a._0;
  var right = b._0;
  return /* Money */{
          _0: left + right | 0
        };
}

function $neg(a, b) {
  var left = a._0;
  var right = b._0;
  var leftBn = new BignumberJs(left);
  return /* Money */{
          _0: leftBn.minus(right).toNumber()
        };
}

function toDecimal(value) {
  var a = value._0;
  return new BignumberJs(a).dividedBy(100).toNumber();
}

function abs(value) {
  var a = value._0;
  return /* Money */{
          _0: Pervasives.abs(a)
        };
}

function format(value) {
  return Numeral(toDecimal(value)).format("$ 0,0.00");
}

function formatInt(value) {
  return format(make({
                  TAG: /* Cents */1,
                  _0: value
                }));
}

var toCents = unwrap;

var formatNumber = format;

var formatBRL = format;

exports.CustomNumeral = CustomNumeral;
exports.make = make;
exports.unwrap = unwrap;
exports.$plus = $plus;
exports.$neg = $neg;
exports.toCents = toCents;
exports.toDecimal = toDecimal;
exports.abs = abs;
exports.format = format;
exports.formatNumber = formatNumber;
exports.formatBRL = formatBRL;
exports.formatInt = formatInt;
/*  Not a pure module */
