'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

var wrapper = Curry._1(Css.style, {
      hd: Css.paddingBottom({
            NAME: "px",
            VAL: 15
          }),
      tl: {
        hd: Css.width({
              NAME: "percent",
              VAL: 100.0
            }),
        tl: {
          hd: Css.maxWidth({
                NAME: "px",
                VAL: 700
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  wrapper: wrapper
};

function FieldWrapper(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: wrapper,
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  })
            }, children);
}

var make = FieldWrapper;

exports.Styles = Styles;
exports.make = make;
/* wrapper Not a pure module */
