'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var FieldError$VannaFrontend = require("./FieldError.bs.js");
var FieldLabel$VannaFrontend = require("./FieldLabel.bs.js");
var FieldWrapper$VannaFrontend = require("./FieldWrapper.bs.js");

var textarea = Curry._1(Css.style, {
      hd: Css.padding2({
            NAME: "rem",
            VAL: 0.75
          }, {
            NAME: "rem",
            VAL: 0.9
          }),
      tl: {
        hd: Css.width({
              NAME: "percent",
              VAL: 100
            }),
        tl: {
          hd: Css.maxWidth({
                NAME: "px",
                VAL: 700
              }),
          tl: {
            hd: Css.boxSizing("borderBox"),
            tl: {
              hd: Css.fontSize({
                    NAME: "rem",
                    VAL: 0.85
                  }),
              tl: {
                hd: Css.fontFamily("inherit_"),
                tl: {
                  hd: Css.borderRadius(Theme$VannaFrontend.Border.radius),
                  tl: {
                    hd: Css.color(Theme$VannaFrontend.Colors.text),
                    tl: {
                      hd: Css.border({
                            NAME: "px",
                            VAL: 1
                          }, "solid", Theme$VannaFrontend.Colors.border),
                      tl: {
                        hd: Css.backgroundColor(Theme$VannaFrontend.Colors.secondary),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  textarea: textarea
};

function TextAreaField(Props) {
  var label = Props.label;
  var errorOpt = Props.error;
  var requiredOpt = Props.required;
  var tip = Props.tip;
  var floatRight = Props.floatRight;
  var value = Props.value;
  var onChange = Props.onChange;
  var wrapperClassNameOpt = Props.wrapperClassName;
  var placeholderOpt = Props.placeholder;
  var disabledOpt = Props.disabled;
  var rowsOpt = Props.rows;
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var wrapperClassName = wrapperClassNameOpt !== undefined ? wrapperClassNameOpt : Curry._1(Css.style, /* [] */0);
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var rows = rowsOpt !== undefined ? rowsOpt : 4;
  var hasError = error !== undefined ? error !== "" : false;
  var tmp;
  if (label !== undefined) {
    var tmp$1 = {
      hasError: hasError,
      required: required,
      children: label
    };
    if (tip !== undefined) {
      tmp$1.tip = Caml_option.valFromOption(tip);
    }
    if (floatRight !== undefined) {
      tmp$1.floatRight = Caml_option.valFromOption(floatRight);
    }
    tmp = React.createElement(FieldLabel$VannaFrontend.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(FieldWrapper$VannaFrontend.make, {
              className: wrapperClassName,
              children: null
            }, tmp, React.createElement("textarea", {
                  className: textarea,
                  disabled: disabled,
                  placeholder: placeholder,
                  rows: rows,
                  value: value,
                  onChange: (function (evt) {
                      return Curry._1(onChange, evt.target.value);
                    })
                }), hasError ? React.createElement(FieldError$VannaFrontend.make, {
                    message: Belt_Option.getWithDefault(error, "")
                  }) : null);
}

var make = TextAreaField;

exports.Styles = Styles;
exports.make = make;
/* textarea Not a pure module */
