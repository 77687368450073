'use strict';

var AsyncPaginateSelect = require("./AsyncPaginateSelect").default;

function Make(Config) {
  var make = AsyncPaginateSelect;
  return {
          make: make
        };
}

exports.Make = Make;
/* ./AsyncPaginateSelect Not a pure module */
