'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"companyInfoUpdate_settingsCompanyInfo_address":{"n":""},"companyInfoUpdate_settingsCompanyInfo_taxId":{"n":""},"companyInfoUpdate_settingsCompanyInfo":{"n":""},"companyInfoUpdate_settingsCompanyInfo_phoneAreaCode":{"n":""},"companyInfoUpdate_settingsCompanyInfo_address_additionalInformation":{"n":""},"companyInfoUpdate_settingsCompanyInfo_phoneNumber":{"n":""},"companyInfoUpdate_settingsCompanyInfo_name":{"n":""},"companyInfoUpdate_error":{"n":""},"companyInfoUpdate":{"n":""},"companyInfoUpdate_settingsCompanyInfo_address_city":{"n":""},"companyInfoUpdate_settingsCompanyInfo_address_number":{"n":""},"companyInfoUpdate_settingsCompanyInfo_tradeName":{"n":""},"companyInfoUpdate_settingsCompanyInfo_address_street":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"companyInfoUpdate_settingsCompanyInfo_address":{"n":""},"companyInfoUpdate_settingsCompanyInfo_taxId":{"n":""},"companyInfoUpdate_settingsCompanyInfo":{"n":""},"companyInfoUpdate_settingsCompanyInfo_phoneAreaCode":{"n":""},"companyInfoUpdate_settingsCompanyInfo_address_additionalInformation":{"n":""},"companyInfoUpdate_settingsCompanyInfo_phoneNumber":{"n":""},"companyInfoUpdate_settingsCompanyInfo_name":{"n":""},"companyInfoUpdate_error":{"n":""},"companyInfoUpdate":{"n":""},"companyInfoUpdate_settingsCompanyInfo_address_city":{"n":""},"companyInfoUpdate_settingsCompanyInfo_address_number":{"n":""},"companyInfoUpdate_settingsCompanyInfo_tradeName":{"n":""},"companyInfoUpdate_settingsCompanyInfo_address_street":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"CompanyInfoUpdateInput":{"twoFactor":{"r":"TwoFactor"},"address":{"r":"Address"}},"__root":{"input":{"r":"CompanyInfoUpdateInput"}},"Address":{"additionalInformation":{"n":""},"number":{"n":""},"city":{"r":"City"}},"TwoFactor":{"googleAuthCode":{"n":""},"smsAuthCode":{"n":""}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_twoFactor(googleAuthCode, smsAuthCode, param) {
  return {
          googleAuthCode: googleAuthCode,
          smsAuthCode: smsAuthCode
        };
}

function make_city(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_address(additionalInformation, city, district, number, postalCode, state, street, param) {
  return {
          additionalInformation: additionalInformation,
          city: city,
          district: district,
          number: number,
          postalCode: postalCode,
          state: state,
          street: street
        };
}

function make_companyInfoUpdateInput(address, municipalTaxId, name, openningDate, phoneAreaCode, phoneNumber, taxId, tradeName, twoFactor) {
  return {
          address: address,
          municipalTaxId: municipalTaxId,
          name: name,
          openningDate: openningDate,
          phoneAreaCode: phoneAreaCode,
          phoneNumber: phoneNumber,
          taxId: taxId,
          tradeName: tradeName,
          twoFactor: twoFactor
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_companyInfoUpdate_settingsCompanyInfo_address_city(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_response_companyInfoUpdate_settingsCompanyInfo_address(additionalInformation, city, district, number, postalCode, state, street, param) {
  return {
          additionalInformation: additionalInformation,
          city: city,
          district: district,
          number: number,
          postalCode: postalCode,
          state: state,
          street: street
        };
}

function make_response_companyInfoUpdate_settingsCompanyInfo(id, name, phoneAreaCode, phoneNumber, taxId, tradeName, address, param) {
  return {
          id: id,
          name: name,
          phoneAreaCode: phoneAreaCode,
          phoneNumber: phoneNumber,
          taxId: taxId,
          tradeName: tradeName,
          address: address
        };
}

function make_response_companyInfoUpdate_error(message) {
  return {
          message: message
        };
}

function make_response_companyInfoUpdate(error, settingsCompanyInfo, param) {
  return {
          error: error,
          settingsCompanyInfo: settingsCompanyInfo
        };
}

function makeOptimisticResponse(companyInfoUpdate, param) {
  return {
          companyInfoUpdate: companyInfoUpdate
        };
}

var Utils = {
  make_twoFactor: make_twoFactor,
  make_city: make_city,
  make_address: make_address,
  make_companyInfoUpdateInput: make_companyInfoUpdateInput,
  makeVariables: makeVariables,
  make_response_companyInfoUpdate_settingsCompanyInfo_address_city: make_response_companyInfoUpdate_settingsCompanyInfo_address_city,
  make_response_companyInfoUpdate_settingsCompanyInfo_address: make_response_companyInfoUpdate_settingsCompanyInfo_address,
  make_response_companyInfoUpdate_settingsCompanyInfo: make_response_companyInfoUpdate_settingsCompanyInfo,
  make_response_companyInfoUpdate_error: make_response_companyInfoUpdate_error,
  make_response_companyInfoUpdate: make_response_companyInfoUpdate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CompanyInfoUpdatePayload",
    "kind": "LinkedField",
    "name": "companyInfoUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SettingsCompanyInfo",
        "kind": "LinkedField",
        "name": "settingsCompanyInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneAreaCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taxId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tradeName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AddressType",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "additionalInformation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CityType",
                "kind": "LinkedField",
                "name": "city",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "district",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyInfoForm_CompanyInfoUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyInfoForm_CompanyInfoUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "79343db3f0be30fc3064ef99a1949a4a",
    "id": null,
    "metadata": {},
    "name": "CompanyInfoForm_CompanyInfoUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CompanyInfoForm_CompanyInfoUpdateMutation(\n  $input: CompanyInfoUpdateInput!\n) {\n  companyInfoUpdate(input: $input) {\n    error {\n      message\n    }\n    settingsCompanyInfo {\n      id\n      name\n      phoneAreaCode\n      phoneNumber\n      taxId\n      tradeName\n      address {\n        additionalInformation\n        city {\n          code\n          name\n        }\n        district\n        number\n        postalCode\n        state\n        street\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
