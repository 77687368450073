'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"recurrentChargeAddSource_recurrentCharge_card":{"n":""},"recurrentChargeAddSource_error":{"n":""},"recurrentChargeAddSource":{"n":""},"recurrentChargeAddSource_recurrentCharge":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"recurrentChargeAddSource_recurrentCharge_card":{"n":""},"recurrentChargeAddSource_error":{"n":""},"recurrentChargeAddSource":{"n":""},"recurrentChargeAddSource_recurrentCharge":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"RecurrentChargeAddSourceInput"}},"RecurrentChargeAddSourceInput":{"creditCard":{"r":"CreditCard"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_creditCard(cardNumber, cvc, expirationMonth, expirationYear, holderName) {
  return {
          cardNumber: cardNumber,
          cvc: cvc,
          expirationMonth: expirationMonth,
          expirationYear: expirationYear,
          holderName: holderName
        };
}

function make_recurrentChargeAddSourceInput(creditCard, recurrentChargeId, taxId) {
  return {
          creditCard: creditCard,
          recurrentChargeId: recurrentChargeId,
          taxId: taxId
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_recurrentChargeAddSource_recurrentCharge_card(id, brand, last4) {
  return {
          id: id,
          brand: brand,
          last4: last4
        };
}

function make_response_recurrentChargeAddSource_recurrentCharge(id, status, card, param) {
  return {
          id: id,
          status: status,
          card: card
        };
}

function make_response_recurrentChargeAddSource_error(message) {
  return {
          message: message
        };
}

function make_response_recurrentChargeAddSource(error, recurrentCharge, param) {
  return {
          error: error,
          recurrentCharge: recurrentCharge
        };
}

function makeOptimisticResponse(recurrentChargeAddSource, param) {
  return {
          recurrentChargeAddSource: recurrentChargeAddSource
        };
}

var Utils = {
  make_creditCard: make_creditCard,
  make_recurrentChargeAddSourceInput: make_recurrentChargeAddSourceInput,
  makeVariables: makeVariables,
  make_response_recurrentChargeAddSource_recurrentCharge_card: make_response_recurrentChargeAddSource_recurrentCharge_card,
  make_response_recurrentChargeAddSource_recurrentCharge: make_response_recurrentChargeAddSource_recurrentCharge,
  make_response_recurrentChargeAddSource_error: make_response_recurrentChargeAddSource_error,
  make_response_recurrentChargeAddSource: make_response_recurrentChargeAddSource,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RecurrentChargeAddSourcePayload",
    "kind": "LinkedField",
    "name": "recurrentChargeAddSource",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RecurrentCharge",
        "kind": "LinkedField",
        "name": "recurrentCharge",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerCard",
            "kind": "LinkedField",
            "name": "card",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brand",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last4",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecurrentChargePayment_AddSourceMutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecurrentChargePayment_AddSourceMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "dde13509d352bfadb1601f22d93251fd",
    "id": null,
    "metadata": {},
    "name": "RecurrentChargePayment_AddSourceMutation",
    "operationKind": "mutation",
    "text": "mutation RecurrentChargePayment_AddSourceMutation(\n  $input: RecurrentChargeAddSourceInput!\n) {\n  recurrentChargeAddSource(input: $input) {\n    error {\n      message\n    }\n    recurrentCharge {\n      id\n      status\n      card {\n        id\n        brand\n        last4\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
