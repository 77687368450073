import React from "react";
import ApexChart from "react-apexcharts";
import color from "color";
import merge from "lodash.merge";
import max from "lodash.max";

import { Colors } from "../Theme.bs";

const formatMoney = (amount = 0) =>
  amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

export default ({
  series,
  options,
  categories,
  type = "bar",
  hasNegativeValues = false,
}) => {
  const textStyles = {
    color: Colors.text,
    fontSize: 12,
  };

  const verticalMax = max(
    series.map((serie) => max(serie.data.map((x) => Math.abs(x))))
  );

  const yaxis = hasNegativeValues
    ? {
        enabled: true,
        labels: {
          style: textStyles,
          offsetX: -10,
          formatter: formatMoney,
        },
        min: -verticalMax,
        max: verticalMax,
        forceNiceScale: true,
        axisBorder: {
          show: true,
          color: color(Colors.toString(Colors.text)).darken(0.85).string(),
          offsetX: 0,
          offsetY: 0,
        },
      }
    : {
        enabled: true,
        labels: {
          style: textStyles,
          offsetX: -10,
          formatter: formatMoney,
        },
        forceNiceScale: true,
        axisBorder: {
          show: true,
          color: color(Colors.toString(Colors.text)).fade(0.85).string(),
          offsetX: 0,
          offsetY: 0,
        },
      };

  const defaultOptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    theme: {
      palette: "palette4",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      labels: {
        style: {
          ...textStyles,
          colors: textStyles.color,
        },
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: true,
        color: color(Colors.toString(Colors.text)).fade(0.85).string(),
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis,
    plotOptions: {
      bar: {
        columnWidth: "45%",
      },
    },
    grid: {
      show: true,
      borderColor: color(Colors.toString(Colors.text)).fade(0.95).string(),
      padding: {
        left: 0,
        right: 0,
      },
    },
    tooltip: {
      shared: true,
      y: {
        formatter: formatMoney,
      },
    },
    legend: {
      position: "bottom",
      offsetY: -15,
      labels: {
        colors: textStyles.color,
      },
      itemMargin: {
        vertical: 15,
        horizontal: 7.5,
      },
    },
  };

  return (
    <ApexChart
      options={merge({}, defaultOptions, options)}
      series={series}
      width="100%"
      height="320"
      type={type}
    />
  );
};
