'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Box$VannaFrontend = require("./Box.bs.js");
var Text$VannaFrontend = require("./Text.bs.js");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexEnd"),
        tl: {
          hd: Css.marginTop({
                NAME: "px",
                VAL: 30
              }),
          tl: /* [] */0
        }
      }
    });

var cancelButton = Curry._1(Css.style, {
      hd: Css.marginLeft({
            NAME: "px",
            VAL: 20
          }),
      tl: /* [] */0
    });

var Styles = {
  buttons: buttons,
  cancelButton: cancelButton
};

function ConfirmModal(Props) {
  var trigger = Props.trigger;
  var titleOpt = Props.title;
  var message = Props.message;
  var error = Props.error;
  var isLoading = Props.isLoading;
  var onConfirm = Props.onConfirm;
  var title = titleOpt !== undefined ? titleOpt : "Confirmação";
  return React.createElement(Modal$VannaFrontend.make, {
              title: title,
              trigger: (function (dispatch) {
                  return Curry._1(trigger, (function (param) {
                                return Curry._1(dispatch, /* Open */2);
                              }));
                }),
              content: (function (dispatch) {
                  var tmp;
                  tmp = error !== undefined && error !== "" ? React.createElement(Box$VannaFrontend.make, {
                          type_: /* Error */2,
                          title: "Erro",
                          children: error
                        }) : null;
                  return React.createElement(React.Fragment, undefined, React.createElement(Text$VannaFrontend.make, {
                                  children: message
                                }), tmp, React.createElement("div", {
                                  className: buttons
                                }, React.createElement(Button$VannaFrontend.make, {
                                      fullWidth: false,
                                      mode: /* Danger */2,
                                      onClick: (function (param) {
                                          return Curry._1(dispatch, /* Close */1);
                                        }),
                                      children: "Não"
                                    }), React.createElement(Button$VannaFrontend.make, {
                                      fullWidth: false,
                                      mode: /* Primary */0,
                                      isLoading: isLoading,
                                      onClick: (function (param) {
                                          return Curry._1(onConfirm, undefined);
                                        }),
                                      className: cancelButton,
                                      children: "Sim"
                                    })));
                }),
              confirmClose: false
            });
}

var make = ConfirmModal;

exports.Styles = Styles;
exports.make = make;
/* buttons Not a pure module */
