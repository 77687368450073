'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var API$VannaFrontend = require("./pages/API.bs.js");
var Pix$VannaFrontend = require("./pages/Pix.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.bs.js");
var Login$VannaFrontend = require("./pages/Login.bs.js");
var SignUp$VannaFrontend = require("./pages/SignUp.bs.js");
var Charges$VannaFrontend = require("./pages/Charges.bs.js");
var NotFound$VannaFrontend = require("./pages/NotFound.bs.js");
var Products$VannaFrontend = require("./pages/Products.bs.js");
var Services$VannaFrontend = require("./pages/Services.bs.js");
var Settings$VannaFrontend = require("./pages/Settings.bs.js");
var Webhooks$VannaFrontend = require("./pages/Webhooks.bs.js");
var Customers$VannaFrontend = require("./pages/Customers.bs.js");
var Dashboard$VannaFrontend = require("./pages/Dashboard.bs.js");
var Affiliates$VannaFrontend = require("./pages/Affiliates.bs.js");
var PublicRoute$VannaFrontend = require("./components/PublicRoute.bs.js");
var ChargeDetails$VannaFrontend = require("./pages/ChargeDetails.bs.js");
var ChargePayment$VannaFrontend = require("./pages/ChargePayment.bs.js");
var ResetPassword$VannaFrontend = require("./pages/ResetPassword.bs.js");
var RouterHelpers$VannaFrontend = require("./RouterHelpers.bs.js");
var SelfCheckouts$VannaFrontend = require("./pages/SelfCheckouts.bs.js");
var ForgotPassword$VannaFrontend = require("./pages/ForgotPassword.bs.js");
var ProtectedRoute$VannaFrontend = require("./components/ProtectedRoute.bs.js");
var RecurrentCharges$VannaFrontend = require("./pages/RecurrentCharges.bs.js");
var SellersAndReferrals$VannaFrontend = require("./pages/SellersAndReferrals.bs.js");
var ChargePaymentConfirm$VannaFrontend = require("./pages/ChargePaymentConfirm.bs.js");
var RecurrentChargePayment$VannaFrontend = require("./pages/RecurrentChargePayment.bs.js");
var RecurrentChargePaymentConfirm$VannaFrontend = require("./pages/RecurrentChargePaymentConfirm.bs.js");

function Router(Props) {
  var match = React.useReducer((function (state, action) {
          if (typeof action === "number") {
            var historyLen = state.history.length;
            if (historyLen <= 1) {
              return state;
            }
            var previousRoute = Caml_array.get(state.history, historyLen - 1 | 0);
            var history = $$Array.sub(state.history, 0, state.history.length - 1 | 0);
            return {
                    history: history,
                    currentRoute: previousRoute
                  };
          }
          if (action.TAG !== /* Push */0) {
            return {
                    history: state.history,
                    currentRoute: action._0
                  };
          }
          var history$1 = $$Array.append(state.history, [state.currentRoute]);
          return {
                  history: history$1,
                  currentRoute: action._0
                };
        }), {
        history: [RouterHelpers$VannaFrontend.pathToRoute(ReasonReactRouter.dangerouslyGetInitialUrl(undefined))],
        currentRoute: RouterHelpers$VannaFrontend.pathToRoute(ReasonReactRouter.dangerouslyGetInitialUrl(undefined))
      });
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          ReasonReactRouter.push(RouterHelpers$VannaFrontend.routeToPath(state.currentRoute));
          
        }), [state.currentRoute]);
  var navigator_push = function (route) {
    return Curry._1(dispatch, {
                TAG: /* Push */0,
                _0: route
              });
  };
  var navigator_pop = function (param) {
    return Curry._1(dispatch, /* Pop */0);
  };
  var navigator_replace = function (route) {
    return Curry._1(dispatch, {
                TAG: /* Replace */1,
                _0: route
              });
  };
  var navigator_externalRedirect = function (prim) {
    window.location.assign(prim);
    
  };
  var $$navigator = {
    push: navigator_push,
    pop: navigator_pop,
    replace: navigator_replace,
    externalRedirect: navigator_externalRedirect
  };
  var code = state.currentRoute;
  if (typeof code === "number") {
    switch (code) {
      case /* Login */0 :
          return React.createElement(PublicRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Login$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* SignUp */1 :
          return React.createElement(PublicRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(SignUp$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* ForgotPassword */2 :
          return React.createElement(PublicRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(ForgotPassword$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* Dashboard */3 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Dashboard$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* Pix */4 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Pix$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* RecurrentCharges */5 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(RecurrentCharges$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* Charges */6 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Charges$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* Customers */7 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Customers$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* Products */8 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Products$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* Services */9 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Services$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* SelfCheckouts */10 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(SelfCheckouts$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* Affiliates */11 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Affiliates$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* Webhooks */12 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Webhooks$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* API */13 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(API$VannaFrontend.make, {
                            navigator: $$navigator
                          })
                    });
      case /* NotFound */14 :
          return React.createElement(NotFound$VannaFrontend.make, {
                      navigator: $$navigator
                    });
      
    }
  } else {
    switch (code.TAG | 0) {
      case /* SignUpIndicated */0 :
          return React.createElement(PublicRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(SignUp$VannaFrontend.make, {
                            navigator: $$navigator,
                            code: code._0
                          })
                    });
      case /* ResetPassword */1 :
          return React.createElement(PublicRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(ResetPassword$VannaFrontend.make, {
                            token: code._0,
                            navigator: $$navigator
                          })
                    });
      case /* RecurrentChargePayment */2 :
          return React.createElement(RecurrentChargePayment$VannaFrontend.make, {
                      navigator: $$navigator,
                      recurrentChargeId: code._0
                    });
      case /* RecurrentChargePaymentConfirm */3 :
          return React.createElement(RecurrentChargePaymentConfirm$VannaFrontend.make, {
                      navigator: $$navigator,
                      recurrentChargeId: code._0
                    });
      case /* ChargePayment */4 :
          return React.createElement(ChargePayment$VannaFrontend.make, {
                      navigator: $$navigator,
                      chargeId: code._0
                    });
      case /* ChargePaymentConfirm */5 :
          return React.createElement(ChargePaymentConfirm$VannaFrontend.make, {
                      navigator: $$navigator,
                      chargeId: code._0
                    });
      case /* ChargeDetails */6 :
          return React.createElement(ChargeDetails$VannaFrontend.make, {
                      chargeId: code._0,
                      navigator: $$navigator
                    });
      case /* SellersAndReferrals */7 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(SellersAndReferrals$VannaFrontend.make, {
                            navigator: $$navigator,
                            defaultTab: code._0
                          })
                    });
      case /* Settings */8 :
          return React.createElement(ProtectedRoute$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(Settings$VannaFrontend.make, {
                            navigator: $$navigator,
                            defaultTab: code._0
                          })
                    });
      
    }
  }
}

var make = Router;

exports.make = make;
/* react Not a pure module */
