'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"allCharges_receivable":{"n":""},"allCharges_receivable_paidAt":{"n":""},"allCharges_receivable_status":{"n":""},"allCharges_receivable_expectedOn":{"n":""}}};

function convertFragment(v) {
  return ReasonRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "currentMonth"
    },
    {
      "kind": "RootArgument",
      "name": "currentYear"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReceivablesCalendarFragment_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "allCharges",
      "args": [
        {
          "kind": "Variable",
          "name": "month",
          "variableName": "currentMonth"
        },
        {
          "kind": "Variable",
          "name": "year",
          "variableName": "currentYear"
        }
      ],
      "concreteType": "Charge",
      "kind": "LinkedField",
      "name": "charges",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChargeReceivable",
          "kind": "LinkedField",
          "name": "receivable",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expectedOn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
