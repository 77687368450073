'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var CopyableContent$VannaFrontend = require("./CopyableContent.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var ApiKeyUpsertForm_apiKey_graphql$VannaFrontend = require("../__generated__/ApiKeyUpsertForm_apiKey_graphql.bs.js");
var ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend = require("../__generated__/ApiKeyUpsertForm_ApiKeyCreateMutation_graphql.bs.js");
var ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend = require("../__generated__/ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql.bs.js");

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(ApiKeyUpsertForm_apiKey_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ApiKeyUpsertForm_apiKey_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ApiKeyUpsertForm_apiKey_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ApiKeyUpsertForm_apiKey_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var ApiKeyFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function get(state, field) {
  return state.description;
}

function set(state, field, value) {
  return {
          description: value
        };
}

var StateLenses = {
  get: get,
  set: set
};

var ApiKeyUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.node,
              variables: ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ApiKeyCreateMutation_make_apiKeyCreateInput = ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Utils.make_apiKeyCreateInput;

var ApiKeyCreateMutation_makeVariables = ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var ApiKeyCreateMutation_make_response_apiKeyCreate_apiKey = ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Utils.make_response_apiKeyCreate_apiKey;

var ApiKeyCreateMutation_make_response_apiKeyCreate_error = ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Utils.make_response_apiKeyCreate_error;

var ApiKeyCreateMutation_make_response_apiKeyCreate = ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Utils.make_response_apiKeyCreate;

var ApiKeyCreateMutation_makeOptimisticResponse = ApiKeyUpsertForm_ApiKeyCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ApiKeyCreateMutation = {
  make_apiKeyCreateInput: ApiKeyCreateMutation_make_apiKeyCreateInput,
  makeVariables: ApiKeyCreateMutation_makeVariables,
  make_response_apiKeyCreate_apiKey: ApiKeyCreateMutation_make_response_apiKeyCreate_apiKey,
  make_response_apiKeyCreate_error: ApiKeyCreateMutation_make_response_apiKeyCreate_error,
  make_response_apiKeyCreate: ApiKeyCreateMutation_make_response_apiKeyCreate,
  makeOptimisticResponse: ApiKeyCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.node,
              variables: ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ApiKeyUpdateMutation_make_apiKeyUpdateInput = ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Utils.make_apiKeyUpdateInput;

var ApiKeyUpdateMutation_makeVariables = ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var ApiKeyUpdateMutation_make_response_apiKeyUpdate_apiKey = ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Utils.make_response_apiKeyUpdate_apiKey;

var ApiKeyUpdateMutation_make_response_apiKeyUpdate_error = ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Utils.make_response_apiKeyUpdate_error;

var ApiKeyUpdateMutation_make_response_apiKeyUpdate = ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Utils.make_response_apiKeyUpdate;

var ApiKeyUpdateMutation_makeOptimisticResponse = ApiKeyUpsertForm_ApiKeyUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ApiKeyUpdateMutation = {
  make_apiKeyUpdateInput: ApiKeyUpdateMutation_make_apiKeyUpdateInput,
  makeVariables: ApiKeyUpdateMutation_makeVariables,
  make_response_apiKeyUpdate_apiKey: ApiKeyUpdateMutation_make_response_apiKeyUpdate_apiKey,
  make_response_apiKeyUpdate_error: ApiKeyUpdateMutation_make_response_apiKeyUpdate_error,
  make_response_apiKeyUpdate: ApiKeyUpdateMutation_make_response_apiKeyUpdate,
  makeOptimisticResponse: ApiKeyUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

function ApiKeyUpsertForm$1(Props) {
  var apiKeyOpt = Props.apiKey;
  var dispatchModal = Props.dispatchModal;
  var apiKey = apiKeyOpt !== undefined ? Caml_option.valFromOption(apiKeyOpt) : undefined;
  var apiKeyFragment = useOpt(apiKey);
  var match = use$1(undefined);
  var createApiKey = match[0];
  var match$1 = use$2(undefined);
  var updateApiKey = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setApiKey = match$2[1];
  var apiKey$1 = match$2[0];
  var reform = Curry._7(ApiKeyUpsertForm.use, apiKeyFragment !== undefined ? ({
            description: Belt_Option.getWithDefault(apiKeyFragment.description, "")
          }) : ({
            description: ""
          }), /* Schema */{
        _0: Curry._3(ApiKeyUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Description */0)
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          if (apiKeyFragment !== undefined) {
            Curry.app(updateApiKey, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.apiKeyUpdate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      } else if (match.apiKey !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        return Curry._1(send, {
                                    TAG: /* SetFormState */10,
                                    _0: /* Dirty */0
                                  });
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    input: {
                      apiKeyId: apiKeyFragment.id,
                      description: state.values.description
                    }
                  },
                  undefined,
                  undefined
                ]);
          } else {
            Curry.app(createApiKey, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.apiKeyCreate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      }
                      var secretKey = match.secretKey;
                      if (secretKey === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var apiKey = match.apiKey;
                      if (apiKey !== undefined) {
                        Curry._1(send, {
                              TAG: /* SetFormState */10,
                              _0: /* Dirty */0
                            });
                        return Curry._1(setApiKey, (function (param) {
                                      return {
                                              keyId: apiKey.keyId,
                                              secretKey: secretKey,
                                              description: Belt_Option.getWithDefault(apiKey.description, "")
                                            };
                                    }));
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  (function (store, response) {
                      var match = response.apiKeyCreate;
                      if (match === undefined) {
                        return ;
                      }
                      var apiKey = match.apiKey;
                      if (apiKey === undefined) {
                        return ;
                      }
                      var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                      var apiKeyProxy = store.get(apiKey.id);
                      if (meProxy == null) {
                        return ;
                      }
                      if (apiKeyProxy == null) {
                        return ;
                      }
                      var apiKeysConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "ApiKeys_apiKeys", undefined);
                      if (apiKeysConnection == null) {
                        return ;
                      }
                      var edge = RelayRuntime.ConnectionHandler.createEdge(store, apiKeysConnection, apiKeyProxy, "ApiKey");
                      RelayRuntime.ConnectionHandler.insertEdgeAfter(apiKeysConnection, edge, undefined);
                      
                    }),
                  {
                    input: {
                      description: state.values.description
                    }
                  },
                  undefined,
                  undefined
                ]);
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$3 = reform.formState;
  var tmp;
  var exit = 0;
  if (typeof match$3 === "number") {
    exit = 1;
  } else {
    var error = match$3._0;
    if (error !== undefined) {
      tmp = React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          });
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = apiKey$1 !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Success */0,
            title: "Chave de API criada!",
            children: null
          }, "Salve a chave de API " + (apiKey$1.description + " em um lugar seguro:"), React.createElement("br", undefined), "ID da Chave: ", React.createElement(CopyableContent$VannaFrontend.make, {
                content: apiKey$1.keyId,
                children: React.createElement("strong", undefined, apiKey$1.keyId)
              }), React.createElement("br", undefined), "Chave da API: ", React.createElement(CopyableContent$VannaFrontend.make, {
                content: apiKey$1.secretKey,
                children: React.createElement("strong", undefined, apiKey$1.secretKey)
              })) : null;
  }
  return React.createElement(ApiKeyUpsertForm.Provider.make, Curry._3(ApiKeyUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(ApiKeyUpsertForm.Field.make, {
                          field: /* Description */0,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Descrição",
                                          error: param.error,
                                          required: false,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), tmp, apiKey$1 !== undefined ? null : React.createElement(Button$VannaFrontend.make, {
                            isLoading: reform.isSubmitting,
                            type_: /* Submit */1,
                            children: Belt_Option.isSome(apiKeyFragment) ? "Atualizar" : "Adicionar"
                          }), React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, /* Close */1);
                            }),
                          children: Belt_Option.isSome(apiKey$1) ? "Fechar" : "Cancelar"
                        })), undefined));
}

var make = ApiKeyUpsertForm$1;

exports.ApiKeyFragment = ApiKeyFragment;
exports.StateLenses = StateLenses;
exports.ApiKeyUpsertForm = ApiKeyUpsertForm;
exports.ApiKeyCreateMutation = ApiKeyCreateMutation;
exports.ApiKeyUpdateMutation = ApiKeyUpdateMutation;
exports.make = make;
/* ApiKeyUpsertForm Not a pure module */
