'use strict';


function getGreeting(param) {
  var hour = new Date().getHours() | 0;
  if (hour > 18) {
    return "Boa noite";
  } else if (hour > 12) {
    return "Boa tarde";
  } else {
    return "Bom dia";
  }
}

exports.getGreeting = getGreeting;
/* No side effect */
