'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$VannaFrontend = require("../Theme.bs.js");

var title = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 1.3
          }),
      tl: {
        hd: Css.padding({
              NAME: "px",
              VAL: 0
            }),
        tl: {
          hd: Css.margin({
                NAME: "px",
                VAL: 0
              }),
          tl: {
            hd: Css.color(Theme$VannaFrontend.Colors.text),
            tl: {
              hd: Css.fontWeight("medium"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Styles = {
  title: title
};

function Title(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement("h1", {
              className: Curry._1(Css.merge, {
                    hd: title,
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  })
            }, children);
}

var make = Title;

exports.Styles = Styles;
exports.make = make;
/* title Not a pure module */
