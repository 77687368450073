'use strict';

var Cookie$JustgageReasonCookie = require("@justgage/reason-cookie/src/Cookie.bs.js");

var tokenName = "TOKEN";

function signIn(token) {
  return Cookie$JustgageReasonCookie.setString(tokenName, token);
}

function signOut(param) {
  return Cookie$JustgageReasonCookie.remove(tokenName);
}

function getToken(param) {
  return Cookie$JustgageReasonCookie.getAsString(tokenName);
}

exports.tokenName = tokenName;
exports.signIn = signIn;
exports.signOut = signOut;
exports.getToken = getToken;
/* Cookie-JustgageReasonCookie Not a pure module */
