'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fi = require("react-icons/fi");
var Button$VannaFrontend = require("./Button.bs.js");
var Loading$VannaFrontend = require("./Loading.bs.js");
var FieldError$VannaFrontend = require("./FieldError.bs.js");
var FieldLabel$VannaFrontend = require("./FieldLabel.bs.js");
var FieldWrapper$VannaFrontend = require("./FieldWrapper.bs.js");

function FileField(Props) {
  var label = Props.label;
  var errorOpt = Props.error;
  var requiredOpt = Props.required;
  var tip = Props.tip;
  var floatRight = Props.floatRight;
  var value = Props.value;
  var onChange = Props.onChange;
  var wrapperClassNameOpt = Props.wrapperClassName;
  var disabledOpt = Props.disabled;
  var accept = Props.accept;
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var wrapperClassName = wrapperClassNameOpt !== undefined ? wrapperClassNameOpt : Curry._1(Css.style, /* [] */0);
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var hasError = error !== undefined ? error !== "" : false;
  var match = React.useState(function () {
        return false;
      });
  var setReadingFile = match[1];
  var fileInputRef = React.useRef(undefined);
  var handleInputRef = React.useCallback((function (input) {
          fileInputRef.current = (input == null) ? undefined : Caml_option.some(input);
          
        }), []);
  var tmp;
  if (label !== undefined) {
    var tmp$1 = {
      hasError: hasError,
      required: required,
      children: label
    };
    if (tip !== undefined) {
      tmp$1.tip = Caml_option.valFromOption(tip);
    }
    if (floatRight !== undefined) {
      tmp$1.floatRight = Caml_option.valFromOption(floatRight);
    }
    tmp = React.createElement(FieldLabel$VannaFrontend.make, tmp$1);
  } else {
    tmp = null;
  }
  var tmp$2 = {
    ref: handleInputRef,
    className: Curry._1(Css.style, {
          hd: Css.display("none"),
          tl: /* [] */0
        }),
    type: "file",
    onChange: (function (_evt) {
        var inputRef = fileInputRef.current;
        if (inputRef === undefined) {
          return ;
        }
        Curry._1(setReadingFile, (function (param) {
                return true;
              }));
        var file = Caml_array.get(Caml_option.valFromOption(inputRef).files, 0);
        Curry._1(onChange, (file == null) ? undefined : Caml_option.some(file));
        return Curry._1(setReadingFile, (function (param) {
                      return false;
                    }));
      })
  };
  if (accept !== undefined) {
    tmp$2.accept = Caml_option.valFromOption(accept);
  }
  return React.createElement(FieldWrapper$VannaFrontend.make, {
              className: wrapperClassName,
              children: null
            }, tmp, React.createElement(Button$VannaFrontend.make, {
                  mode: /* Secondary */1,
                  onClick: (function (param) {
                      var inputRef = fileInputRef.current;
                      if (inputRef !== undefined) {
                        return Caml_option.valFromOption(inputRef).click();
                      }
                      
                    }),
                  disabled: disabled,
                  children: match[0] ? React.createElement(Loading$VannaFrontend.make, {}) : React.createElement(React.Fragment, undefined, React.createElement(Fi.FiFile, {}), value !== undefined ? React.createElement(React.Fragment, undefined, Caml_option.valFromOption(value).name + " ", React.createElement("small", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginLeft({
                                                NAME: "px",
                                                VAL: 8
                                              }),
                                          tl: {
                                            hd: Css.fontWeight("normal"),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, "(clique para adicionar outro arquivo)")) : "Escolher arquivo")
                }), React.createElement("input", tmp$2), hasError ? React.createElement(FieldError$VannaFrontend.make, {
                    message: Belt_Option.getWithDefault(error, "")
                  }) : null);
}

var make = FileField;

exports.make = make;
/* Css Not a pure module */
