'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var ButtonToggleRow$VannaFrontend = require("./ButtonToggleRow.bs.js");

var startYear = new Date().getFullYear() - 10 | 0;

var years = $$Array.mapi((function (index, _item) {
        return {
                value: String(startYear + index | 0),
                label: String(startYear + index | 0)
              };
      }), Caml_array.caml_make_vect(12, 0));

function DateSelectors$YearSelector(Props) {
  var selected = Props.selected;
  var setSelected = Props.setSelected;
  var withMarginBottomOpt = Props.withMarginBottom;
  var withMarginBottom = withMarginBottomOpt !== undefined ? withMarginBottomOpt : false;
  return React.createElement(ButtonToggleRow$VannaFrontend.make, {
              title: "Ano contábil",
              options: years,
              selected: selected,
              setSelected: setSelected,
              withMarginBottom: withMarginBottom
            });
}

var YearSelector = {
  startYear: startYear,
  years: years,
  make: DateSelectors$YearSelector
};

var months = $$Array.mapi((function (index, month) {
        return {
                value: String(index),
                label: month
              };
      }), [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Maio",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez"
    ]);

function DateSelectors$MonthSelector(Props) {
  var selected = Props.selected;
  var setSelected = Props.setSelected;
  var withMarginBottomOpt = Props.withMarginBottom;
  var withMarginBottom = withMarginBottomOpt !== undefined ? withMarginBottomOpt : false;
  return React.createElement(ButtonToggleRow$VannaFrontend.make, {
              title: "Mês",
              options: months,
              selected: selected,
              setSelected: setSelected,
              withMarginBottom: withMarginBottom
            });
}

var MonthSelector = {
  months: months,
  make: DateSelectors$MonthSelector
};

exports.YearSelector = YearSelector;
exports.MonthSelector = MonthSelector;
/* startYear Not a pure module */
