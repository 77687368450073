'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"sellerReferralCreate_error":{"n":""},"sellerReferralCreate_sellerReferral":{"n":""},"sellerReferralCreate":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"sellerReferralCreate_error":{"n":""},"sellerReferralCreate_sellerReferral":{"n":""},"sellerReferralCreate":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"SellerReferralCreateInput"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_sellerReferralCreateInput(code) {
  return {
          code: code
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_sellerReferralCreate_sellerReferral(id, code) {
  return {
          id: id,
          code: code
        };
}

function make_response_sellerReferralCreate_error(message) {
  return {
          message: message
        };
}

function make_response_sellerReferralCreate(error, sellerReferral, param) {
  return {
          error: error,
          sellerReferral: sellerReferral
        };
}

function makeOptimisticResponse(sellerReferralCreate, param) {
  return {
          sellerReferralCreate: sellerReferralCreate
        };
}

var Utils = {
  make_sellerReferralCreateInput: make_sellerReferralCreateInput,
  makeVariables: makeVariables,
  make_response_sellerReferralCreate_sellerReferral: make_response_sellerReferralCreate_sellerReferral,
  make_response_sellerReferralCreate_error: make_response_sellerReferralCreate_error,
  make_response_sellerReferralCreate: make_response_sellerReferralCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SellerReferralCreatePayload",
    "kind": "LinkedField",
    "name": "sellerReferralCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserSellerReferral",
        "kind": "LinkedField",
        "name": "sellerReferral",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserReferralUpsertForm_UserReferralCreateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserReferralUpsertForm_UserReferralCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f43945513d8a96d8b4bcf9434555331f",
    "id": null,
    "metadata": {},
    "name": "UserReferralUpsertForm_UserReferralCreateMutation",
    "operationKind": "mutation",
    "text": "mutation UserReferralUpsertForm_UserReferralCreateMutation(\n  $input: SellerReferralCreateInput!\n) {\n  sellerReferralCreate(input: $input) {\n    error {\n      message\n    }\n    sellerReferral {\n      id\n      code\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
