'use strict';

var React = require("react");
var App$VannaFrontend = require("./App.bs.js");
var ReactExperimental = require("reason-relay/src/ReactExperimental.bs.js");
var ReasonReactErrorBoundary = require("reason-react/src/ReasonReactErrorBoundary.bs.js");

var StrictMode = {};

function Index$ReactApp(Props) {
  return React.createElement(React.StrictMode, {
              children: React.createElement(ReasonReactErrorBoundary.make, {
                    children: React.createElement(App$VannaFrontend.make, {}),
                    fallback: (function (param) {
                        return "Estamos atualizando a página, recarregue em alguns instantes";
                      })
                  })
            });
}

var ReactApp = {
  make: Index$ReactApp
};

ReactExperimental.renderConcurrentRootAtElementWithId(React.createElement(Index$ReactApp, {}), "root");

exports.StrictMode = StrictMode;
exports.ReactApp = ReactApp;
/*  Not a pure module */
