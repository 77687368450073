'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var Loading$VannaFrontend = require("./Loading.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

function tableWrapper(withPadding) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.overflowX("auto"),
                  tl: {
                    hd: Css.width({
                          NAME: "percent",
                          VAL: 100.0
                        }),
                    tl: {
                      hd: Css.boxSizing("borderBox"),
                      tl: {
                        hd: Css.padding2({
                              NAME: "px",
                              VAL: 0
                            }, withPadding ? Theme$VannaFrontend.Padding.content : ({
                                  NAME: "px",
                                  VAL: 0
                                })),
                        tl: {
                          hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                                hd: Css.padding({
                                      NAME: "px",
                                      VAL: 0
                                    }),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var table = Curry._1(Css.style, {
      hd: Css.borderCollapse("collapse"),
      tl: {
        hd: Css.tableLayout("auto"),
        tl: {
          hd: Css.width({
                NAME: "percent",
                VAL: 100.0
              }),
          tl: /* [] */0
        }
      }
    });

function tableHeaderCell(isButton) {
  return Curry._1(Css.style, {
              hd: Css.textAlign("left"),
              tl: {
                hd: Css.fontSize({
                      NAME: "rem",
                      VAL: 0.85
                    }),
                tl: {
                  hd: Css.padding({
                        NAME: "px",
                        VAL: 10
                      }),
                  tl: {
                    hd: Css.color(Theme$VannaFrontend.Colors.text),
                    tl: {
                      hd: Css.fontWeight("normal"),
                      tl: {
                        hd: Css.borderBottom({
                              NAME: "px",
                              VAL: 1
                            }, "solid", Theme$VannaFrontend.Colors.border),
                        tl: {
                          hd: Css.firstChild({
                                hd: Css.paddingLeft({
                                      NAME: "px",
                                      VAL: 10
                                    }),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.lastChild({
                                  hd: Css.paddingRight({
                                        NAME: "px",
                                        VAL: 10
                                      }),
                                  tl: /* [] */0
                                }),
                            tl: isButton ? ({
                                  hd: Css.width({
                                        NAME: "px",
                                        VAL: 34
                                      }),
                                  tl: /* [] */0
                                }) : /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var tableItemWrapper = Curry._1(Css.style, {
      hd: Css.borderRadius(Theme$VannaFrontend.Border.radius),
      tl: {
        hd: Css.opacity(1.0),
        tl: {
          hd: Css.hover({
                hd: Css.backgroundColor(Theme$VannaFrontend.Colors.background),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

function tableItemCell(clickable) {
  return Curry._1(Css.style, {
              hd: Css.overflow("hidden"),
              tl: {
                hd: Css.textOverflow("ellipsis"),
                tl: {
                  hd: Css.fontSize({
                        NAME: "rem",
                        VAL: 0.9
                      }),
                  tl: {
                    hd: Css.height({
                          NAME: "px",
                          VAL: 35
                        }),
                    tl: {
                      hd: Css.padding({
                            NAME: "px",
                            VAL: 10
                          }),
                      tl: {
                        hd: Css.minWidth("initial"),
                        tl: {
                          hd: Css.color(Theme$VannaFrontend.Colors.text),
                          tl: {
                            hd: Css.fontWeight("inherit_"),
                            tl: {
                              hd: Css.cursor("default"),
                              tl: {
                                hd: Css.visibility("initial"),
                                tl: {
                                  hd: Css.firstChild({
                                        hd: Css.borderTopLeftRadius(Theme$VannaFrontend.Border.radius),
                                        tl: {
                                          hd: Css.borderBottomLeftRadius(Theme$VannaFrontend.Border.radius),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: {
                                    hd: Css.lastChild({
                                          hd: Css.borderTopRightRadius(Theme$VannaFrontend.Border.radius),
                                          tl: {
                                            hd: Css.borderBottomRightRadius(Theme$VannaFrontend.Border.radius),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: clickable ? ({
                                          hd: Css.cursor("pointer"),
                                          tl: /* [] */0
                                        }) : /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var noResults = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.padding2({
                  NAME: "px",
                  VAL: 60
                }, {
                  NAME: "px",
                  VAL: 0
                }),
            tl: {
              hd: Css.fontSize({
                    NAME: "rem",
                    VAL: 0.9
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var paginationWrapper = Curry._1(Css.style, {
      hd: Css.textAlign("right"),
      tl: {
        hd: Css.padding3({
              NAME: "px",
              VAL: 25
            }, {
              NAME: "px",
              VAL: 0
            }, {
              NAME: "px",
              VAL: 0
            }),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var pagination = Curry._1(Css.style, {
      hd: Css.display("inlineBlock"),
      tl: {
        hd: Css.fontSize({
              NAME: "rem",
              VAL: 0.85
            }),
        tl: {
          hd: Css.color(Theme$VannaFrontend.Colors.text),
          tl: /* [] */0
        }
      }
    });

var PrimaryStyles = {
  mediumBreakpoint: mediumBreakpoint,
  tableWrapper: tableWrapper,
  table: table,
  tableHeaderCell: tableHeaderCell,
  tableItemWrapper: tableItemWrapper,
  tableItemCell: tableItemCell,
  noResults: noResults,
  paginationWrapper: paginationWrapper,
  pagination: pagination
};

function table$1(darkerBackground) {
  return Curry._1(Css.style, {
              hd: Css.marginBottom({
                    NAME: "px",
                    VAL: 20
                  }),
              tl: {
                hd: Css.borderCollapse("collapse"),
                tl: {
                  hd: Css.tableLayout("fixed"),
                  tl: {
                    hd: Css.width({
                          NAME: "percent",
                          VAL: 100
                        }),
                    tl: {
                      hd: Css.maxWidth({
                            NAME: "px",
                            VAL: 700
                          }),
                      tl: {
                        hd: Css.boxSizing("borderBox"),
                        tl: {
                          hd: Css.backgroundColor(darkerBackground ? Theme$VannaFrontend.Colors.background : Theme$VannaFrontend.Colors.backgroundLighter),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var tr = Curry._1(Css.style, {
      hd: Css.not__(":last-child", {
            hd: Css.child("td")({
                  hd: Css.borderBottom({
                        NAME: "px",
                        VAL: 1
                      }, "solid", Theme$VannaFrontend.Colors.borderLighter),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }),
      tl: /* [] */0
    });

var header = Curry._1(Css.style, {
      hd: Css.textAlign("center"),
      tl: {
        hd: Css.padding({
              NAME: "px",
              VAL: 10
            }),
        tl: {
          hd: Css.borderBottom({
                NAME: "px",
                VAL: 1
              }, "solid", Theme$VannaFrontend.Colors.border),
          tl: {
            hd: Css.fontSize({
                  NAME: "rem",
                  VAL: 0.8
                }),
            tl: /* [] */0
          }
        }
      }
    });

var cell = Curry._1(Css.style, {
      hd: Css.padding2({
            NAME: "px",
            VAL: 4
          }, {
            NAME: "px",
            VAL: 10
          }),
      tl: {
        hd: Css.height({
              NAME: "px",
              VAL: 35
            }),
        tl: {
          hd: Css.fontSize({
                NAME: "rem",
                VAL: 0.95
              }),
          tl: {
            hd: Css.color(Theme$VannaFrontend.Colors.textSecondary),
            tl: {
              hd: Css.width({
                    NAME: "percent",
                    VAL: 1
                  }),
              tl: {
                hd: Css.whiteSpace("nowrap"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var buttonsWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: /* [] */0
        }
      }
    });

var noResults$1 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.padding2({
                  NAME: "px",
                  VAL: 15
                }, {
                  NAME: "px",
                  VAL: 0
                }),
            tl: {
              hd: Css.fontSize({
                    NAME: "rem",
                    VAL: 0.9
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var SecondaryStyles = {
  table: table$1,
  tr: tr,
  header: header,
  cell: cell,
  buttonsWrapper: buttonsWrapper,
  noResults: noResults$1
};

function column(name, clickableOpt, render) {
  var clickable = clickableOpt !== undefined ? clickableOpt : true;
  return {
          name: name,
          render: render,
          clickable: clickable
        };
}

function Make(Config) {
  var Table$Make = function (Props) {
    var isLoadingOpt = Props.isLoading;
    var data = Props.data;
    var columns = Props.columns;
    var getItemId = Props.getItemId;
    var onRowClickOpt = Props.onRowClick;
    var withPaddingOpt = Props.withPadding;
    var hasNextPageOpt = Props.hasNextPage;
    var loadMoreOpt = Props.loadMore;
    var isLoading = isLoadingOpt !== undefined ? isLoadingOpt : false;
    var onRowClick = onRowClickOpt !== undefined ? Caml_option.valFromOption(onRowClickOpt) : undefined;
    var withPadding = withPaddingOpt !== undefined ? withPaddingOpt : true;
    var hasNextPage = hasNextPageOpt !== undefined ? hasNextPageOpt : false;
    var loadMore = loadMoreOpt !== undefined ? loadMoreOpt : (function (param) {
          
        });
    var colSpan = List.length(columns);
    return React.createElement("div", {
                className: tableWrapper(withPadding)
              }, React.createElement("table", {
                    className: table
                  }, React.createElement("thead", undefined, React.createElement("tr", undefined, Belt_List.toArray(Belt_List.mapWithIndex(columns, (function (index, header) {
                                      return React.createElement("th", {
                                                  key: "__table__header__" + (header.name + String(index)),
                                                  className: tableHeaderCell(false)
                                                }, header.name);
                                    }))))), React.createElement("tbody", undefined, isLoading ? React.createElement("tr", undefined, React.createElement("td", {
                                  colSpan: colSpan
                                }, React.createElement(Loading$VannaFrontend.make, {
                                      hasPadding: true,
                                      hasIcon: true
                                    }))) : (
                          data.length !== 0 ? $$Array.mapi((function (idx, element) {
                                    var columns$1 = List.mapi((function (index, el) {
                                            var renderedData = Curry._2(el.render, element, idx);
                                            return React.createElement("td", {
                                                        key: Curry._1(getItemId, element) + ("_" + (el.name + String(index))),
                                                        className: tableItemCell(el.clickable && Belt_Option.isSome(onRowClick)),
                                                        onClick: (function (param) {
                                                            var match = el.clickable;
                                                            if (onRowClick !== undefined && match) {
                                                              return Curry._1(onRowClick, Curry._1(getItemId, element));
                                                            }
                                                            
                                                          })
                                                      }, renderedData);
                                          }), columns);
                                    return React.createElement("tr", {
                                                key: "__table__row__" + (Curry._1(getItemId, element) + String(idx)),
                                                className: tableItemWrapper
                                              }, Belt_List.toArray(columns$1));
                                  }), data) : React.createElement("tr", undefined, React.createElement("td", {
                                      colSpan: colSpan
                                    }, React.createElement("div", {
                                          className: noResults
                                        }, "Nenhum registro")))
                        ))), React.createElement("div", {
                    className: paginationWrapper,
                    colSpan: colSpan
                  }, hasNextPage ? React.createElement(Button$VannaFrontend.make, {
                          fullWidth: false,
                          onClick: (function (param) {
                              return Curry._1(loadMore, undefined);
                            }),
                          children: "Carregar mais..."
                        }) : null));
  };
  return {
          make: Table$Make
        };
}

function MakeSecondary(Config) {
  var Table$MakeSecondary = function (Props) {
    var isLoadingOpt = Props.isLoading;
    var data = Props.data;
    var columns = Props.columns;
    var getItemId = Props.getItemId;
    var darkerBackgroundOpt = Props.darkerBackground;
    var withPaddingOpt = Props.withPadding;
    var isLoading = isLoadingOpt !== undefined ? isLoadingOpt : false;
    var darkerBackground = darkerBackgroundOpt !== undefined ? darkerBackgroundOpt : false;
    var withPadding = withPaddingOpt !== undefined ? withPaddingOpt : true;
    var colSpan = List.length(columns);
    return React.createElement("div", {
                className: tableWrapper(withPadding)
              }, React.createElement("table", {
                    className: table$1(darkerBackground)
                  }, React.createElement("thead", undefined, React.createElement("tr", undefined, Belt_List.toArray(Belt_List.mapWithIndex(columns, (function (index, header$1) {
                                      return React.createElement("th", {
                                                  key: "__table__header__" + (header$1.name + String(index)),
                                                  className: header
                                                }, header$1.name);
                                    }))))), React.createElement("tbody", undefined, isLoading ? React.createElement("tr", undefined, React.createElement("td", {
                                  colSpan: colSpan
                                }, React.createElement(Loading$VannaFrontend.make, {
                                      hasPadding: true,
                                      hasIcon: true
                                    }))) : (
                          data.length !== 0 ? $$Array.mapi((function (idx, element) {
                                    var columns$1 = List.mapi((function (index, el) {
                                            var renderedData = Curry._2(el.render, element, idx);
                                            return React.createElement("td", {
                                                        key: Curry._1(getItemId, element) + ("_" + (el.name + String(index))),
                                                        className: cell
                                                      }, renderedData);
                                          }), columns);
                                    return React.createElement("tr", {
                                                key: "__table__row__" + (Curry._1(getItemId, element) + String(idx)),
                                                className: tr
                                              }, Belt_List.toArray(columns$1));
                                  }), data) : React.createElement("tr", undefined, React.createElement("td", {
                                      colSpan: colSpan
                                    }, React.createElement("div", {
                                          className: noResults$1
                                        }, "Nenhum registro")))
                        ))));
  };
  return {
          make: Table$MakeSecondary
        };
}

exports.PrimaryStyles = PrimaryStyles;
exports.SecondaryStyles = SecondaryStyles;
exports.column = column;
exports.Make = Make;
exports.MakeSecondary = MakeSecondary;
/* table Not a pure module */
