'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Link$VannaFrontend = require("./Link.bs.js");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Table$VannaFrontend = require("./Table.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var DeleteModal$VannaFrontend = require("./DeleteModal.bs.js");
var CopyableContent$VannaFrontend = require("./CopyableContent.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var UserReferralsRefetchQuery_graphql$VannaFrontend = require("../__generated__/UserReferralsRefetchQuery_graphql.bs.js");
var UserReferralsTableConnection_query_graphql$VannaFrontend = require("../__generated__/UserReferralsTableConnection_query_graphql.bs.js");
var UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend = require("../__generated__/UserReferralsTable_UserReferralsTableDeleteMutation_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.width("auto"),
      tl: /* [] */0
    });

var Styles = {
  panel: panel
};

var getConnectionNodes = UserReferralsTableConnection_query_graphql$VannaFrontend.Utils.getConnectionNodes;

var Internal = {};

function internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = ReasonRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = ReasonRelay.mapRenderPolicy(renderPolicy);
  if (tmp$2 !== undefined) {
    tmp.UNSTABLE_renderPolicy = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$3 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var InternalRefetch = {
  internal_makeRefetchableFnOpts: internal_makeRefetchableFnOpts
};

function useRefetchable(fRef) {
  var match = Hooks.useRefetchableFragment(UserReferralsTableConnection_query_graphql$VannaFrontend.node, fRef);
  var refetchFn = match[1];
  var data = ReasonRelay_Internal.internal_useConvertedValue(UserReferralsTableConnection_query_graphql$VannaFrontend.Internal.convertFragment, match[0]);
  return [
          data,
          (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return Curry._2(refetchFn, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(UserReferralsRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        ];
}

function use(fRef) {
  var data = Hooks.useFragment(UserReferralsTableConnection_query_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(UserReferralsTableConnection_query_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(UserReferralsTableConnection_query_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return UserReferralsTableConnection_query_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function usePagination(fr) {
  var p = Hooks.usePaginationFragment(UserReferralsTableConnection_query_graphql$VannaFrontend.node, fr);
  var data = ReasonRelay_Internal.internal_useConvertedValue(UserReferralsTableConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(UserReferralsRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

function useBlockingPagination(fRef) {
  var p = Hooks.useBlockingPaginationFragment(UserReferralsTableConnection_query_graphql$VannaFrontend.node, fRef);
  var data = ReasonRelay_Internal.internal_useConvertedValue(UserReferralsTableConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(UserReferralsRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

var makeRefetchVariables = UserReferralsRefetchQuery_graphql$VannaFrontend.Types.makeRefetchVariables;

var UserReferralsTableConnectionFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Internal: Internal,
  InternalRefetch: InternalRefetch,
  useRefetchable: useRefetchable,
  use: use,
  useOpt: useOpt,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination,
  makeRefetchVariables: makeRefetchVariables
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.node,
              variables: UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var UserReferralsTableDeleteMutation_make_sellerReferralDeleteInput = UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Utils.make_sellerReferralDeleteInput;

var UserReferralsTableDeleteMutation_makeVariables = UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Utils.makeVariables;

var UserReferralsTableDeleteMutation_make_response_sellerReferralDelete_error = UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Utils.make_response_sellerReferralDelete_error;

var UserReferralsTableDeleteMutation_make_response_sellerReferralDelete = UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Utils.make_response_sellerReferralDelete;

var UserReferralsTableDeleteMutation_makeOptimisticResponse = UserReferralsTable_UserReferralsTableDeleteMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var UserReferralsTableDeleteMutation = {
  make_sellerReferralDeleteInput: UserReferralsTableDeleteMutation_make_sellerReferralDeleteInput,
  makeVariables: UserReferralsTableDeleteMutation_makeVariables,
  make_response_sellerReferralDelete_error: UserReferralsTableDeleteMutation_make_response_sellerReferralDelete_error,
  make_response_sellerReferralDelete: UserReferralsTableDeleteMutation_make_response_sellerReferralDelete,
  makeOptimisticResponse: UserReferralsTableDeleteMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var UserReferralsTable = Table$VannaFrontend.Make({});

function UserReferralsTable$1(Props) {
  var queryRef = Props.query;
  var match = React.useState(function () {
        
      });
  var setDeleteError = match[1];
  var deleteError = match[0];
  var match$1 = use$1(undefined);
  var isDeletting = match$1[1];
  var $$delete = match$1[0];
  var match$2 = usePagination(queryRef);
  var loadNext = match$2.loadNext;
  var data = Curry._1(getConnectionNodes, match$2.data.referrals);
  return React.createElement(React.Fragment, undefined, React.createElement(Panel$VannaFrontend.make, {
                  className: panel,
                  children: React.createElement(UserReferralsTable.make, {
                        data: data,
                        columns: {
                          hd: Table$VannaFrontend.column("Código", undefined, (function (referral, param) {
                                  return referral.code;
                                })),
                          tl: {
                            hd: Table$VannaFrontend.column("Link", undefined, (function (referral, param) {
                                    var url = process.env.REACT_APP_APP_URL + ("/criar-conta/" + referral.code);
                                    return React.createElement(CopyableContent$VannaFrontend.make, {
                                                content: url,
                                                children: React.createElement(Link$VannaFrontend.Hyperlink.make, {
                                                      href: url,
                                                      target: "_blank",
                                                      children: url
                                                    })
                                              });
                                  })),
                            tl: {
                              hd: Table$VannaFrontend.column("", undefined, (function (referral, param) {
                                      return React.createElement(DeleteModal$VannaFrontend.make, {
                                                  displayName: "indicação",
                                                  onConfirm: (function (dispatch) {
                                                      Curry._1(setDeleteError, (function (param) {
                                                              
                                                            }));
                                                      Curry.app($$delete, [
                                                            (function (param) {
                                                                return Curry._1(setDeleteError, (function (param) {
                                                                              return "Algo deu errado, tente novamente";
                                                                            }));
                                                              }),
                                                            (function (response, _errors) {
                                                                var match = response.sellerReferralDelete;
                                                                if (match === undefined) {
                                                                  return Curry._1(setDeleteError, (function (param) {
                                                                                return "Algo deu errado, tente novamente";
                                                                              }));
                                                                }
                                                                var match$1 = match.error;
                                                                if (match$1 === undefined) {
                                                                  return Curry._1(dispatch, /* Close */1);
                                                                }
                                                                var message = match$1.message;
                                                                return Curry._1(setDeleteError, (function (param) {
                                                                              return message;
                                                                            }));
                                                              }),
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            (function (store, response) {
                                                                var match = response.sellerReferralDelete;
                                                                if (match === undefined) {
                                                                  return ;
                                                                }
                                                                if (match.error !== undefined) {
                                                                  return ;
                                                                }
                                                                var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                                                                if (meProxy == null) {
                                                                  return ;
                                                                }
                                                                var referralsConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "UserReferrals_referrals", undefined);
                                                                if (!(referralsConnection == null)) {
                                                                  RelayRuntime.ConnectionHandler.deleteNode(referralsConnection, referral.id);
                                                                  return ;
                                                                }
                                                                
                                                              }),
                                                            {
                                                              input: {
                                                                referralId: referral.id
                                                              }
                                                            },
                                                            undefined,
                                                            undefined
                                                          ]);
                                                      
                                                    }),
                                                  onClose: (function (param) {
                                                      return Curry._1(setDeleteError, (function (param) {
                                                                    
                                                                  }));
                                                    }),
                                                  isLoading: isDeletting,
                                                  error: deleteError
                                                });
                                    })),
                              tl: /* [] */0
                            }
                          }
                        },
                        getItemId: (function (referral) {
                            return referral.id;
                          }),
                        hasNextPage: match$2.hasNext,
                        loadMore: (function (param) {
                            Curry._3(loadNext, 20, undefined, undefined);
                            
                          })
                      })
                }));
}

var pageSize = 20;

var make = UserReferralsTable$1;

exports.Styles = Styles;
exports.pageSize = pageSize;
exports.UserReferralsTableConnectionFragment = UserReferralsTableConnectionFragment;
exports.UserReferralsTableDeleteMutation = UserReferralsTableDeleteMutation;
exports.UserReferralsTable = UserReferralsTable;
exports.make = make;
/* panel Not a pure module */
