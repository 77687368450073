'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var FormatHelpers$VannaFrontend = require("../types/FormatHelpers.bs.js");
var DashboardBalanceFragment_user_graphql$VannaFrontend = require("../__generated__/DashboardBalanceFragment_user_graphql.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

var panel = Curry._1(Css.style, {
      hd: Css.padding(Theme$VannaFrontend.Padding.content),
      tl: /* [] */0
    });

var subtitles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.marginTop({
              NAME: "px",
              VAL: 20
            }),
        tl: {
          hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var subtitle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.fontSize({
                NAME: "rem",
                VAL: 0.9
              }),
          tl: {
            hd: Css.paddingRight({
                  NAME: "px",
                  VAL: 20
                }),
            tl: {
              hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                    hd: Css.flexDirection("column"),
                    tl: {
                      hd: Css.paddingRight({
                            NAME: "px",
                            VAL: 0
                          }),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.marginBottom({
                                NAME: "px",
                                VAL: 15
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var balanceLabel = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
              hd: Css.marginLeft({
                    NAME: "px",
                    VAL: 8
                  }),
              tl: {
                hd: Css.display("none"),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var balanceLabelPhone = Curry._1(Css.style, {
      hd: Css.display("none"),
      tl: {
        hd: Css.fontWeight("bold"),
        tl: {
          hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                hd: Css.display("block"),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

function balanceAmount(amount) {
  return Curry._1(Css.style, {
              hd: Css.color(amount < 0 ? Theme$VannaFrontend.Colors.error : Theme$VannaFrontend.Colors.success),
              tl: {
                hd: Css.fontSize({
                      NAME: "rem",
                      VAL: 1.2
                    }),
                tl: {
                  hd: Css.marginBottom({
                        NAME: "px",
                        VAL: 5
                      }),
                  tl: /* [] */0
                }
              }
            });
}

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  panel: panel,
  subtitles: subtitles,
  subtitle: subtitle,
  balanceLabel: balanceLabel,
  balanceLabelPhone: balanceLabelPhone,
  balanceAmount: balanceAmount
};

function DashboardBalance$Balance(Props) {
  var amount = Props.amount;
  var label = Props.label;
  return React.createElement("div", {
              className: subtitle
            }, React.createElement("span", {
                  className: balanceLabelPhone
                }, label), React.createElement("b", {
                  className: balanceAmount(amount)
                }, Money$VannaFrontend.formatInt(amount)), React.createElement("span", {
                  className: balanceLabel
                }, label));
}

var Balance = {
  make: DashboardBalance$Balance
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(DashboardBalanceFragment_user_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(DashboardBalanceFragment_user_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(DashboardBalanceFragment_user_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return DashboardBalanceFragment_user_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var DashboardBalanceFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function DashboardBalance(Props) {
  var visualization = Props.visualization;
  var query = use(visualization);
  var visualization$1 = query.visualization;
  if (visualization$1 !== undefined) {
    return React.createElement(Panel$VannaFrontend.make, {
                className: panel,
                children: null
              }, React.createElement(Panel$VannaFrontend.PanelTitle.make, {
                    children: FormatHelpers$VannaFrontend.getGreeting(undefined) + (", " + query.name)
                  }), React.createElement("div", {
                    className: subtitles
                  }, React.createElement(DashboardBalance$Balance, {
                        amount: visualization$1.balanceToReceive,
                        label: "A liberar"
                      }), React.createElement(DashboardBalance$Balance, {
                        amount: visualization$1.receivedThisMonth,
                        label: "Recebido neste mês"
                      }), React.createElement(DashboardBalance$Balance, {
                        amount: visualization$1.receivedLastMonth,
                        label: "Recebido no mês anterior"
                      })));
  } else {
    return null;
  }
}

var make = DashboardBalance;

exports.Styles = Styles;
exports.Balance = Balance;
exports.DashboardBalanceFragment = DashboardBalanceFragment;
exports.make = make;
/* panel Not a pure module */
