'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var SearchableSelectField$VannaFrontend = require("./SearchableSelectField.bs.js");

var StateSelect = SearchableSelectField$VannaFrontend.Make({});

var states = [
  {
    label: "Acre",
    value: "AC"
  },
  {
    label: "Alagoas",
    value: "AL"
  },
  {
    label: "Amapá",
    value: "AP"
  },
  {
    label: "Amazonas",
    value: "AM"
  },
  {
    label: "Bahia",
    value: "BA"
  },
  {
    label: "Ceará",
    value: "CE"
  },
  {
    label: "Distrito Federal",
    value: "DF"
  },
  {
    label: "Espírito Santo",
    value: "ES"
  },
  {
    label: "Goiás",
    value: "GO"
  },
  {
    label: "Maranhão",
    value: "MA"
  },
  {
    label: "Mato Grosso",
    value: "MT"
  },
  {
    label: "Mato Grosso do Sul",
    value: "MS"
  },
  {
    label: "Minas Gerais",
    value: "MG"
  },
  {
    label: "Pará",
    value: "PA"
  },
  {
    label: "Paraíba",
    value: "PB"
  },
  {
    label: "Paraná",
    value: "PR"
  },
  {
    label: "Pernambuco",
    value: "PE"
  },
  {
    label: "Piauí",
    value: "PI"
  },
  {
    label: "Rio de Janeiro",
    value: "RJ"
  },
  {
    label: "Rio Grande do Norte",
    value: "RN"
  },
  {
    label: "Rio Grande do Sul",
    value: "RS"
  },
  {
    label: "Rondônia",
    value: "RO"
  },
  {
    label: "Roraima",
    value: "RR"
  },
  {
    label: "Santa Catarina",
    value: "SC"
  },
  {
    label: "São Paulo",
    value: "SP"
  },
  {
    label: "Sergipe",
    value: "SE"
  },
  {
    label: "Tocantins",
    value: "TO"
  }
];

function StateSelectField(Props) {
  var error = Props.error;
  var onChange = Props.onChange;
  var value = Props.value;
  var disabledOpt = Props.disabled;
  var classNameOpt = Props.className;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement(StateSelect.make, {
              label: "Estado",
              error: error,
              required: true,
              value: value,
              onChange: (function (param) {
                  Curry._1(onChange, param.value);
                  return $$Promise.resolved(undefined);
                }),
              disabled: disabled,
              options: states,
              wrapperClassName: className
            });
}

var make = StateSelectField;

exports.StateSelect = StateSelect;
exports.states = states;
exports.make = make;
/* StateSelect Not a pure module */
