'use strict';


function i18n_false_(param) {
  return "Este valor deve ser falso";
}

function i18n_true_(param) {
  return "Este valor deve ser verdadeiro";
}

function i18n_intMin(param, min) {
  return "Este valor precisa ser maior ou igual a " + min.toString();
}

function i18n_intMax(param, max) {
  return "Este valor precisa ser menor ou igual a " + max.toString();
}

function i18n_floatMin(param, min) {
  return "Este valor precisa ser maior ou igual a " + min.toString();
}

function i18n_floatMax(param, max) {
  return "Este valor precisa ser menor ou igual a " + max.toString();
}

function i18n_email(value) {
  return "" + value + " não é um email válido";
}

function i18n_stringNonEmpty(param) {
  return "Este campo é obrigatório";
}

function i18n_stringRegExp(param, pattern) {
  return "Este campo deve seguir o seguinte padrão: \"" + pattern + "\"";
}

function i18n_stringMin(param, min) {
  return "Este campo deve ter no mínimo " + min + " caracteres";
}

function i18n_stringMax(param, max) {
  return "Este campo deve ter no máximo " + max + " caracteres";
}

var i18n = {
  false_: i18n_false_,
  true_: i18n_true_,
  intMin: i18n_intMin,
  intMax: i18n_intMax,
  floatMin: i18n_floatMin,
  floatMax: i18n_floatMax,
  email: i18n_email,
  stringNonEmpty: i18n_stringNonEmpty,
  stringRegExp: i18n_stringRegExp,
  stringMin: i18n_stringMin,
  stringMax: i18n_stringMax
};

exports.i18n = i18n;
/* No side effect */
