'use strict';


function groupBy(xs, keyFn) {
  return xs.reduce((function (rv, x) {
                var k = keyFn(x);
                if (rv.hasOwnProperty(k)) {
                  rv[k].push(x);
                } else {
                  rv[k] = [x];
                }
                return rv;
              }), {});
}

exports.groupBy = groupBy;
/* No side effect */
