'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Hooks = require("react-relay/hooks");
var Tabs$VannaFrontend = require("../components/Tabs.bs.js");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var RequireKYC$VannaFrontend = require("../components/RequireKYC.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var RecurrentChargesTable$VannaFrontend = require("../components/RecurrentChargesTable.bs.js");
var RecurrentChargeUpsertForm$VannaFrontend = require("../components/RecurrentChargeUpsertForm.bs.js");
var RecurrentChargesQuery_graphql$VannaFrontend = require("../__generated__/RecurrentChargesQuery_graphql.bs.js");

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(RecurrentChargesQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(RecurrentChargesQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargesQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(RecurrentChargesQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, RecurrentChargesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, RecurrentChargesQuery_graphql$VannaFrontend.node, RecurrentChargesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: RecurrentChargesQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, RecurrentChargesQuery_graphql$VannaFrontend.node, RecurrentChargesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return RecurrentChargesQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(RecurrentChargesQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(RecurrentChargesQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var RecurrentChargesQuery = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

function RecurrentCharges(Props) {
  var $$navigator = Props.navigator;
  var queryData = use(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var match = queryData.me;
  var recurrentCharges = match !== undefined ? match.recurrentCharges : [];
  var recurrentChargesByType = React.useMemo((function () {
          return Belt_Array.reduce(recurrentCharges, {
                      ongoing: 0,
                      awaitingSubscription: 0,
                      stopped: 0,
                      paymentFailure: 0,
                      rejectedByCustomer: 0
                    }, (function (acc, cur) {
                        var match = cur.status;
                        if (match === "STOPPED") {
                          return {
                                  ongoing: acc.ongoing,
                                  awaitingSubscription: acc.awaitingSubscription,
                                  stopped: acc.stopped + 1 | 0,
                                  paymentFailure: acc.paymentFailure,
                                  rejectedByCustomer: acc.rejectedByCustomer
                                };
                        } else if (match === "AWAITING_SUBSCRIPTION") {
                          return {
                                  ongoing: acc.ongoing,
                                  awaitingSubscription: acc.awaitingSubscription + 1 | 0,
                                  stopped: acc.stopped,
                                  paymentFailure: acc.paymentFailure,
                                  rejectedByCustomer: acc.rejectedByCustomer
                                };
                        } else if (match === "PAYMENT_FAILURE") {
                          return {
                                  ongoing: acc.ongoing,
                                  awaitingSubscription: acc.awaitingSubscription,
                                  stopped: acc.stopped,
                                  paymentFailure: acc.paymentFailure + 1 | 0,
                                  rejectedByCustomer: acc.rejectedByCustomer
                                };
                        } else if (match === "REJECTED_BY_CUSTOMER") {
                          return {
                                  ongoing: acc.ongoing,
                                  awaitingSubscription: acc.awaitingSubscription,
                                  stopped: acc.stopped,
                                  paymentFailure: acc.paymentFailure,
                                  rejectedByCustomer: acc.rejectedByCustomer + 1 | 0
                                };
                        } else if (match === "ONGOING") {
                          return {
                                  ongoing: acc.ongoing + 1 | 0,
                                  awaitingSubscription: acc.awaitingSubscription,
                                  stopped: acc.stopped,
                                  paymentFailure: acc.paymentFailure,
                                  rejectedByCustomer: acc.rejectedByCustomer
                                };
                        } else {
                          return acc;
                        }
                      }));
        }), [recurrentCharges]);
  var me = queryData.me;
  if (me !== undefined) {
    return React.createElement(PrivateLayout$VannaFrontend.make, {
                navigator: $$navigator,
                children: React.createElement(RequireKYC$VannaFrontend.make, {
                      navigator: $$navigator,
                      fragment: me.fragmentRefs,
                      children: (function (param) {
                          return React.createElement(React.Fragment, undefined, React.createElement(PageHeader$VannaFrontend.make, {
                                          title: "Assinaturas",
                                          upsertModal: {
                                            renderModal: (function (dispatch) {
                                                return React.createElement(RecurrentChargeUpsertForm$VannaFrontend.make, {
                                                            recurrentCharge: undefined,
                                                            dispatchModal: dispatch,
                                                            query: me.fragmentRefs
                                                          });
                                              }),
                                            displayName: "assinatura"
                                          }
                                        }), React.createElement(Panel$VannaFrontend.make, {
                                          children: React.createElement(Tabs$VannaFrontend.make, {
                                                list_: [
                                                  {
                                                    name: "ativas",
                                                    title: "Ativas (" + (String(recurrentChargesByType.ongoing) + ")"),
                                                    disabled: false,
                                                    badge: null,
                                                    body: React.createElement(RecurrentChargesTable$VannaFrontend.make, {
                                                          navigator: $$navigator,
                                                          kind: /* Ongoing */0,
                                                          recurrentCharges: Belt_Array.keep(recurrentCharges, (function (charge) {
                                                                  return charge.status === "ONGOING";
                                                                })),
                                                          query: me.fragmentRefs
                                                        })
                                                  },
                                                  {
                                                    name: "aguardando-assinatura",
                                                    title: "Aguardando assinatura (" + (String(recurrentChargesByType.awaitingSubscription) + ")"),
                                                    disabled: false,
                                                    badge: null,
                                                    body: React.createElement(RecurrentChargesTable$VannaFrontend.make, {
                                                          navigator: $$navigator,
                                                          kind: /* AwaitingSubscription */1,
                                                          recurrentCharges: Belt_Array.keep(recurrentCharges, (function (charge) {
                                                                  return charge.status === "AWAITING_SUBSCRIPTION";
                                                                })),
                                                          query: me.fragmentRefs
                                                        })
                                                  },
                                                  {
                                                    name: "pausadas",
                                                    title: "Pausadas (" + (String(recurrentChargesByType.stopped) + ")"),
                                                    disabled: false,
                                                    badge: null,
                                                    body: React.createElement(RecurrentChargesTable$VannaFrontend.make, {
                                                          navigator: $$navigator,
                                                          kind: /* Stopped */2,
                                                          recurrentCharges: Belt_Array.keep(recurrentCharges, (function (charge) {
                                                                  return charge.status === "STOPPED";
                                                                })),
                                                          query: me.fragmentRefs
                                                        })
                                                  },
                                                  {
                                                    name: "falha-no-pagamento",
                                                    title: "Falha no pagamento (" + (String(recurrentChargesByType.paymentFailure) + ")"),
                                                    disabled: false,
                                                    badge: null,
                                                    body: React.createElement(RecurrentChargesTable$VannaFrontend.make, {
                                                          navigator: $$navigator,
                                                          kind: /* PaymentFailure */3,
                                                          recurrentCharges: Belt_Array.keep(recurrentCharges, (function (charge) {
                                                                  return charge.status === "PAYMENT_FAILURE";
                                                                })),
                                                          query: me.fragmentRefs
                                                        })
                                                  },
                                                  {
                                                    name: "rejeitada-pelo-cliente",
                                                    title: "Rejeitadas pelo cliente (" + String(recurrentChargesByType.rejectedByCustomer) + ")",
                                                    disabled: false,
                                                    badge: null,
                                                    body: React.createElement(RecurrentChargesTable$VannaFrontend.make, {
                                                          navigator: $$navigator,
                                                          kind: /* RejectedByCustomer */4,
                                                          recurrentCharges: Belt_Array.keep(recurrentCharges, (function (charge) {
                                                                  return charge.status === "REJECTED_BY_CUSTOMER";
                                                                })),
                                                          query: me.fragmentRefs
                                                        })
                                                  }
                                                ],
                                                withPadding: true
                                              })
                                        }));
                        })
                    })
              });
  } else {
    return null;
  }
}

var make = RecurrentCharges;

exports.RecurrentChargesQuery = RecurrentChargesQuery;
exports.make = make;
/* react Not a pure module */
