'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var FieldError$VannaFrontend = require("./FieldError.bs.js");
var FieldLabel$VannaFrontend = require("./FieldLabel.bs.js");
var ReactSelect$VannaFrontend = require("../types/ReactSelect.bs.js");
var FieldWrapper$VannaFrontend = require("./FieldWrapper.bs.js");

function Make(Config) {
  var Select = ReactSelect$VannaFrontend.Make({});
  var SimpleSelectField$Make = function (Props) {
    var label = Props.label;
    var errorOpt = Props.error;
    var requiredOpt = Props.required;
    var tip = Props.tip;
    var floatRight = Props.floatRight;
    var value = Props.value;
    var onChange = Props.onChange;
    var placeholderOpt = Props.placeholder;
    var disabledOpt = Props.disabled;
    var options = Props.options;
    var wrapperClassNameOpt = Props.wrapperClassName;
    var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
    var required = requiredOpt !== undefined ? requiredOpt : false;
    var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var wrapperClassName = wrapperClassNameOpt !== undefined ? wrapperClassNameOpt : Curry._1(Css.style, /* [] */0);
    var hasError = error !== undefined ? error !== "" : false;
    var tmp;
    if (label !== undefined) {
      var tmp$1 = {
        hasError: hasError,
        required: required,
        children: label
      };
      if (tip !== undefined) {
        tmp$1.tip = Caml_option.valFromOption(tip);
      }
      if (floatRight !== undefined) {
        tmp$1.floatRight = Caml_option.valFromOption(floatRight);
      }
      tmp = React.createElement(FieldLabel$VannaFrontend.make, tmp$1);
    } else {
      tmp = null;
    }
    return React.createElement(FieldWrapper$VannaFrontend.make, {
                className: wrapperClassName,
                children: null
              }, tmp, React.createElement(Select.make, {
                    value: value,
                    options: options,
                    placeholder: placeholder,
                    onChange: onChange,
                    disabled: disabled
                  }), hasError ? React.createElement(FieldError$VannaFrontend.make, {
                      message: Belt_Option.getWithDefault(error, "")
                    }) : null);
  };
  return {
          Select: Select,
          make: SimpleSelectField$Make
        };
}

exports.Make = Make;
/* Css Not a pure module */
