'use strict';

var ReactSelectJs = require("./ReactSelect.js").default;

function Make(Config) {
  var make = ReactSelectJs;
  return {
          make: make
        };
}

exports.Make = Make;
/* ./ReactSelect.js Not a pure module */
