'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Color = require("color");
var Css_AtomicTypes = require("bs-css/src/Css_AtomicTypes.bs.js");

var primary = Css.hex("D8C800");

var link = Css.hex("42a5f5");

var previsionGreen = Css.hex("00B45C");

var $$default = Css.hex("888995");

var success = Css.hex("00ce69");

var error = Css.hex("ef5350");

var info = Css.hex("42a5f5");

var warn = Css.hex("ffc200");

var buttonText = Css.hex("48504C");

var buttonDisabled = Css.hex("d0d0d0");

var buttonDisabledText = Css.hex("888888");

var secondary = Css.hex("fff");

var border = Css.hex("d2d2d2");

var borderLighter = Css.hex("e4e4e4");

var text = Css.hex("04100A");

var textSecondary = Css.hex("04100A");

var background = Css.hex("f1f1f1");

var backgroundLighter = Css.hex("f7f7f7");

var domainCharges = Css.hex("adcf60");

var domainRecurrentCharges = Css.hex("5ab6b2");

var domainTransactionCosts = Css.hex("d65550");

function toString(color) {
  return new Color(Css_AtomicTypes.Color.toString(color)).string();
}

var Colors = {
  primary: primary,
  link: link,
  previsionGreen: previsionGreen,
  $$default: $$default,
  success: success,
  error: error,
  info: info,
  warn: warn,
  buttonText: buttonText,
  buttonDisabled: buttonDisabled,
  buttonDisabledText: buttonDisabledText,
  secondary: secondary,
  border: border,
  borderLighter: borderLighter,
  text: text,
  textSecondary: textSecondary,
  background: background,
  backgroundLighter: backgroundLighter,
  domainCharges: domainCharges,
  domainRecurrentCharges: domainRecurrentCharges,
  domainTransactionCosts: domainTransactionCosts,
  toString: toString
};

var content = Css.rem(2.0);

var Padding = {
  content: content
};

var content$1 = Css.rem(3.0);

var Margin = {
  content: content$1
};

var BoxShadow = {
  $$default: "0px 1px 3px 0px rgba(0,0,0,0.15), 0px 2px 1px -1px rgba(0,0,0,0.08)"
};

var Border = {
  radius: {
    NAME: "px",
    VAL: 4
  }
};

var Breakpoints = {
  small: "600px",
  medium: "780px",
  large: "1280px",
  extraLarge: "1920px"
};

exports.Colors = Colors;
exports.Padding = Padding;
exports.Margin = Margin;
exports.BoxShadow = BoxShadow;
exports.Border = Border;
exports.Breakpoints = Breakpoints;
/* primary Not a pure module */
