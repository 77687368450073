'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var MoneyField$VannaFrontend = require("./MoneyField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var ServiceUpsertForm_service_graphql$VannaFrontend = require("../__generated__/ServiceUpsertForm_service_graphql.bs.js");
var ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend = require("../__generated__/ServiceUpsertForm_ServiceCreateMutation_graphql.bs.js");
var ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend = require("../__generated__/ServiceUpsertForm_ServiceUpdateMutation_graphql.bs.js");

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(ServiceUpsertForm_service_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ServiceUpsertForm_service_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ServiceUpsertForm_service_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ServiceUpsertForm_service_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var ServiceFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function get(state, field) {
  switch (field) {
    case /* Name */0 :
        return state.name;
    case /* Description */1 :
        return state.description;
    case /* Price */2 :
        return state.price;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Name */0 :
        return {
                name: value,
                description: state.description,
                price: state.price
              };
    case /* Description */1 :
        return {
                name: state.name,
                description: value,
                price: state.price
              };
    case /* Price */2 :
        return {
                name: state.name,
                description: state.description,
                price: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var ServiceUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.node,
              variables: ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ServiceCreateMutation_make_federalServiceCode = ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Utils.make_federalServiceCode;

var ServiceCreateMutation_make_cnae = ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Utils.make_cnae;

var ServiceCreateMutation_make_cityServiceCode = ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Utils.make_cityServiceCode;

var ServiceCreateMutation_make_serviceCreateInput = ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Utils.make_serviceCreateInput;

var ServiceCreateMutation_makeVariables = ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var ServiceCreateMutation_make_response_serviceCreate_service = ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Utils.make_response_serviceCreate_service;

var ServiceCreateMutation_make_response_serviceCreate_error = ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Utils.make_response_serviceCreate_error;

var ServiceCreateMutation_make_response_serviceCreate = ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Utils.make_response_serviceCreate;

var ServiceCreateMutation_makeOptimisticResponse = ServiceUpsertForm_ServiceCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ServiceCreateMutation = {
  make_federalServiceCode: ServiceCreateMutation_make_federalServiceCode,
  make_cnae: ServiceCreateMutation_make_cnae,
  make_cityServiceCode: ServiceCreateMutation_make_cityServiceCode,
  make_serviceCreateInput: ServiceCreateMutation_make_serviceCreateInput,
  makeVariables: ServiceCreateMutation_makeVariables,
  make_response_serviceCreate_service: ServiceCreateMutation_make_response_serviceCreate_service,
  make_response_serviceCreate_error: ServiceCreateMutation_make_response_serviceCreate_error,
  make_response_serviceCreate: ServiceCreateMutation_make_response_serviceCreate,
  makeOptimisticResponse: ServiceCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.node,
              variables: ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ServiceUpdateMutation_make_federalServiceCode = ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Utils.make_federalServiceCode;

var ServiceUpdateMutation_make_cnae = ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Utils.make_cnae;

var ServiceUpdateMutation_make_cityServiceCode = ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Utils.make_cityServiceCode;

var ServiceUpdateMutation_make_serviceUpdateInput = ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Utils.make_serviceUpdateInput;

var ServiceUpdateMutation_makeVariables = ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var ServiceUpdateMutation_make_response_serviceUpdate_service = ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Utils.make_response_serviceUpdate_service;

var ServiceUpdateMutation_make_response_serviceUpdate_error = ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Utils.make_response_serviceUpdate_error;

var ServiceUpdateMutation_make_response_serviceUpdate = ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Utils.make_response_serviceUpdate;

var ServiceUpdateMutation_makeOptimisticResponse = ServiceUpsertForm_ServiceUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ServiceUpdateMutation = {
  make_federalServiceCode: ServiceUpdateMutation_make_federalServiceCode,
  make_cnae: ServiceUpdateMutation_make_cnae,
  make_cityServiceCode: ServiceUpdateMutation_make_cityServiceCode,
  make_serviceUpdateInput: ServiceUpdateMutation_make_serviceUpdateInput,
  makeVariables: ServiceUpdateMutation_makeVariables,
  make_response_serviceUpdate_service: ServiceUpdateMutation_make_response_serviceUpdate_service,
  make_response_serviceUpdate_error: ServiceUpdateMutation_make_response_serviceUpdate_error,
  make_response_serviceUpdate: ServiceUpdateMutation_make_response_serviceUpdate,
  makeOptimisticResponse: ServiceUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

function ServiceUpsertForm$1(Props) {
  var serviceOpt = Props.service;
  var dispatchModal = Props.dispatchModal;
  var onSuccessOpt = Props.onSuccess;
  var service = serviceOpt !== undefined ? Caml_option.valFromOption(serviceOpt) : undefined;
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var serviceFragment = useOpt(service);
  var match = use$1(undefined);
  var createService = match[0];
  var match$1 = use$2(undefined);
  var updateService = match$1[0];
  var reform = Curry._7(ServiceUpsertForm.use, serviceFragment !== undefined ? ({
            name: serviceFragment.name,
            description: Belt_Option.getWithDefault(serviceFragment.description, ""),
            price: Money$VannaFrontend.toDecimal(Money$VannaFrontend.make({
                      TAG: /* Cents */1,
                      _0: serviceFragment.price
                    }))
          }) : ({
            name: "",
            description: "",
            price: 0.0
          }), /* Schema */{
        _0: Curry._2(ServiceUpsertForm.ReSchema.Validation.$plus, Curry._3(ServiceUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Name */0), Curry._6(ServiceUpsertForm.ReSchema.Validation.$$float, 0.0, "", undefined, undefined, undefined, /* Price */2))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          if (serviceFragment !== undefined) {
            Curry.app(updateService, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.serviceUpdate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      }
                      var service = match.service;
                      if (service !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        Curry._1(send, {
                              TAG: /* SetFormState */10,
                              _0: /* Dirty */0
                            });
                        return Curry._1(onSuccess, service.id);
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    input: {
                      cityServiceCode: undefined,
                      cnae: undefined,
                      description: state.values.description,
                      federalServiceCode: undefined,
                      name: state.values.name,
                      price: Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                                TAG: /* Decimal */0,
                                _0: state.values.price
                              })),
                      serviceId: serviceFragment.id
                    }
                  },
                  undefined,
                  undefined
                ]);
          } else {
            Curry.app(createService, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.serviceCreate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      }
                      var service = match.service;
                      if (service !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        Curry._1(send, {
                              TAG: /* SetFormState */10,
                              _0: /* Dirty */0
                            });
                        return Curry._1(onSuccess, service.id);
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  (function (store, response) {
                      var match = response.serviceCreate;
                      if (match === undefined) {
                        return ;
                      }
                      var service = match.service;
                      if (service === undefined) {
                        return ;
                      }
                      var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                      var serviceProxy = store.get(service.id);
                      if (meProxy == null) {
                        return ;
                      }
                      if (serviceProxy == null) {
                        return ;
                      }
                      var servicesConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "Services_services", undefined);
                      if (servicesConnection == null) {
                        return ;
                      }
                      var edge = RelayRuntime.ConnectionHandler.createEdge(store, servicesConnection, serviceProxy, "Service");
                      RelayRuntime.ConnectionHandler.insertEdgeAfter(servicesConnection, edge, undefined);
                      
                    }),
                  {
                    input: {
                      cityServiceCode: undefined,
                      cnae: undefined,
                      description: state.values.description,
                      federalServiceCode: undefined,
                      name: state.values.name,
                      price: Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                                TAG: /* Decimal */0,
                                _0: state.values.price
                              }))
                    }
                  },
                  undefined,
                  undefined
                ]);
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$2 = reform.formState;
  var tmp;
  if (typeof match$2 === "number") {
    tmp = null;
  } else {
    var error = match$2._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(ServiceUpsertForm.Provider.make, Curry._3(ServiceUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(ServiceUpsertForm.Field.make, {
                          field: /* Name */0,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Nome",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(ServiceUpsertForm.Field.make, {
                          field: /* Description */1,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Descrição",
                                          error: param.error,
                                          required: false,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(ServiceUpsertForm.Field.make, {
                          field: /* Price */2,
                          render: (function (param) {
                              return React.createElement(MoneyField$VannaFrontend.make, {
                                          label: "Preço",
                                          error: param.error,
                                          required: false,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), tmp, React.createElement(Button$VannaFrontend.make, {
                          isLoading: reform.isSubmitting,
                          type_: /* Submit */1,
                          children: Belt_Option.isSome(serviceFragment) ? "Atualizar" : "Adicionar"
                        }), React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, /* CloseWithConfirmation */0);
                            }),
                          children: "Cancelar"
                        })), undefined));
}

var make = ServiceUpsertForm$1;

exports.ServiceFragment = ServiceFragment;
exports.StateLenses = StateLenses;
exports.ServiceUpsertForm = ServiceUpsertForm;
exports.ServiceCreateMutation = ServiceCreateMutation;
exports.ServiceUpdateMutation = ServiceUpdateMutation;
exports.make = make;
/* ServiceUpsertForm Not a pure module */
