'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Color = require("color");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Css_AtomicTypes = require("bs-css/src/Css_AtomicTypes.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

function tabsWrapper(withPadding) {
  return Curry._1(Css.style, {
              hd: withPadding ? Css.padding2({
                      NAME: "px",
                      VAL: 0
                    }, Theme$VannaFrontend.Padding.content) : Css.padding({
                      NAME: "px",
                      VAL: 0
                    }),
              tl: {
                hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                      hd: Css.padding({
                            NAME: "px",
                            VAL: 0
                          }),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            });
}

var tabsList = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.borderBottom({
              NAME: "px",
              VAL: 1
            }, "solid", Theme$VannaFrontend.Colors.border),
        tl: {
          hd: Css.overflow("scroll"),
          tl: {
            hd: Css.padding2({
                  NAME: "px",
                  VAL: 0
                }, {
                  NAME: "px",
                  VAL: 20
                }),
            tl: /* [] */0
          }
        }
      }
    });

var tabsBody = Curry._1(Css.style, {
      hd: Css.padding3(Theme$VannaFrontend.Padding.content, {
            NAME: "px",
            VAL: 0
          }, {
            NAME: "px",
            VAL: 0
          }),
      tl: /* [] */0
    });

function tab(disabled, active) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.padding3({
                        NAME: "px",
                        VAL: 0
                      }, {
                        NAME: "px",
                        VAL: 0
                      }, {
                        NAME: "rem",
                        VAL: 1.0
                      }),
                  tl: {
                    hd: Css.height({
                          NAME: "px",
                          VAL: 50
                        }),
                    tl: {
                      hd: Css.fontSize({
                            NAME: "rem",
                            VAL: 0.95
                          }),
                      tl: {
                        hd: Css.borderBottom({
                              NAME: "px",
                              VAL: 2
                            }, "solid", "transparent"),
                        tl: {
                          hd: Css.color(Theme$VannaFrontend.Colors.textSecondary),
                          tl: {
                            hd: Css.cursor("pointer"),
                            tl: {
                              hd: Css.not__(":last-child", {
                                    hd: Css.marginRight({
                                          NAME: "rem",
                                          VAL: 2.0
                                        }),
                                    tl: /* [] */0
                                  }),
                              tl: disabled ? ({
                                    hd: Css.unsafe("color", new Color(Css_AtomicTypes.Color.toString(Theme$VannaFrontend.Colors.text)).fade(0.85).string()),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: /* [] */0
                                    }
                                  }) : (
                                  active ? ({
                                        hd: Css.color(Theme$VannaFrontend.Colors.text),
                                        tl: {
                                          hd: Css.borderColor(Theme$VannaFrontend.Colors.primary),
                                          tl: /* [] */0
                                        }
                                      }) : ({
                                        hd: Css.hover({
                                              hd: Css.unsafe("color", new Color(Css_AtomicTypes.Color.toString(Theme$VannaFrontend.Colors.textSecondary)).lighten(0.4).string()),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      })
                                )
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  tabsWrapper: tabsWrapper,
  tabsList: tabsList,
  tabsBody: tabsBody,
  tab: tab
};

function Tabs(Props) {
  var defaultTab = Props.defaultTab;
  var list_ = Props.list_;
  var onChange = Props.onChange;
  var withPaddingOpt = Props.withPadding;
  var wrapperClassNameOpt = Props.wrapperClassName;
  var withPadding = withPaddingOpt !== undefined ? withPaddingOpt : false;
  var wrapperClassName = wrapperClassNameOpt !== undefined ? wrapperClassNameOpt : Curry._1(Css.style, /* [] */0);
  var match = React.useState(function () {
        if (defaultTab !== undefined) {
          return Belt_Option.map(Belt_Array.getBy(list_, (function (tab) {
                            return tab.name === defaultTab;
                          })), (function (tab) {
                        return tab.name;
                      }));
        }
        var item = Belt_Array.get(list_, 0);
        if (item !== undefined) {
          return item.name;
        }
        
      });
  var setCurrentTab = match[1];
  var currentTab = match[0];
  React.useEffect((function () {
          if (defaultTab !== undefined) {
            Curry._1(setCurrentTab, (function (param) {
                    return Belt_Option.map(Belt_Array.getBy(list_, (function (tab) {
                                      return tab.name === defaultTab;
                                    })), (function (tab) {
                                  return tab.name;
                                }));
                  }));
          }
          
        }), [defaultTab]);
  var tab$1 = Belt_Array.getBy(list_, (function (item) {
          if (currentTab !== undefined) {
            return item.name === currentTab;
          } else {
            return false;
          }
        }));
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: tabsWrapper(withPadding),
                    tl: {
                      hd: wrapperClassName,
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: tabsList
                }, $$Array.map((function (param) {
                        var disabled = param.disabled;
                        var title = param.title;
                        var name = param.name;
                        return React.createElement("div", {
                                    key: "__tab__item__" + title,
                                    className: tab(disabled, currentTab !== undefined ? name === currentTab : false),
                                    onClick: (function (param) {
                                        if (!disabled) {
                                          Curry._1(setCurrentTab, (function (param) {
                                                  return name;
                                                }));
                                          if (onChange !== undefined) {
                                            return Curry._1(onChange, name);
                                          } else {
                                            return ;
                                          }
                                        }
                                        
                                      })
                                  }, param.badge, title);
                      }), list_)), tab$1 !== undefined ? React.createElement("div", {
                    className: tabsBody
                  }, tab$1.body) : null);
}

var make = Tabs;

exports.Styles = Styles;
exports.make = make;
/* tabsList Not a pure module */
