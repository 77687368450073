'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Theme$VannaFrontend = require("./Theme.bs.js");
var Router$VannaFrontend = require("./Router.bs.js");
var Loading$VannaFrontend = require("./components/Loading.bs.js");
var RelayEnv$VannaFrontend = require("./RelayEnv.bs.js");

Curry._2(Css.$$global, "html, body, #root", {
      hd: Css.fontFamily({
            NAME: "custom",
            VAL: "'Source Sans Pro', sans-serif"
          }),
      tl: {
        hd: Css.color(Theme$VannaFrontend.Colors.text),
        tl: {
          hd: Css.backgroundColor(Theme$VannaFrontend.Colors.background),
          tl: /* [] */0
        }
      }
    });

Curry._2(Css.$$global, "a", {
      hd: Css.textDecoration("none"),
      tl: /* [] */0
    });

Curry._2(Css.$$global, "input::placeholder", {
      hd: Css.opacity(0.7),
      tl: {
        hd: Css.color(Theme$VannaFrontend.Colors.textSecondary),
        tl: /* [] */0
      }
    });

function App(Props) {
  return React.createElement(ReasonRelay.Context.Provider.make, {
              environment: RelayEnv$VannaFrontend.environment,
              children: React.createElement(React.Suspense, {
                    children: React.createElement(Router$VannaFrontend.make, {}),
                    fallback: React.createElement(Loading$VannaFrontend.make, {
                          hasPadding: true,
                          hasIcon: true
                        })
                  })
            });
}

var make = App;

exports.make = make;
/*  Not a pure module */
