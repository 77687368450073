'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Fi = require("react-icons/fi");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Link$VannaFrontend = require("../components/Link.bs.js");
var Modal$VannaFrontend = require("../components/Modal.bs.js");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var Table$VannaFrontend = require("../components/Table.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var IconButton$VannaFrontend = require("../components/IconButton.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var RequireKYC$VannaFrontend = require("../components/RequireKYC.bs.js");
var DeleteModal$VannaFrontend = require("../components/DeleteModal.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var CopyableContent$VannaFrontend = require("../components/CopyableContent.bs.js");
var PageQueryRenderer$VannaFrontend = require("../components/PageQueryRenderer.bs.js");
var SelfCheckoutUpsert$VannaFrontend = require("../components/SelfCheckoutUpsert.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var SelfCheckoutsQuery_graphql$VannaFrontend = require("../__generated__/SelfCheckoutsQuery_graphql.bs.js");
var SelfCheckoutsRefetchQuery_graphql$VannaFrontend = require("../__generated__/SelfCheckoutsRefetchQuery_graphql.bs.js");
var SelfCheckoutsConnection_query_graphql$VannaFrontend = require("../__generated__/SelfCheckoutsConnection_query_graphql.bs.js");
var SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend = require("../__generated__/SelfCheckouts_SelfCheckoutDeleteMutation_graphql.bs.js");

var vannaEndpoint = "http://localhost:3000";

var getConnectionNodes = SelfCheckoutsConnection_query_graphql$VannaFrontend.Utils.getConnectionNodes;

var Internal = {};

function internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = ReasonRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = ReasonRelay.mapRenderPolicy(renderPolicy);
  if (tmp$2 !== undefined) {
    tmp.UNSTABLE_renderPolicy = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$3 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var InternalRefetch = {
  internal_makeRefetchableFnOpts: internal_makeRefetchableFnOpts
};

function useRefetchable(fRef) {
  var match = Hooks.useRefetchableFragment(SelfCheckoutsConnection_query_graphql$VannaFrontend.node, fRef);
  var refetchFn = match[1];
  var data = ReasonRelay_Internal.internal_useConvertedValue(SelfCheckoutsConnection_query_graphql$VannaFrontend.Internal.convertFragment, match[0]);
  return [
          data,
          (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return Curry._2(refetchFn, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(SelfCheckoutsRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        ];
}

function use(fRef) {
  var data = Hooks.useFragment(SelfCheckoutsConnection_query_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(SelfCheckoutsConnection_query_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(SelfCheckoutsConnection_query_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return SelfCheckoutsConnection_query_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function usePagination(fr) {
  var p = Hooks.usePaginationFragment(SelfCheckoutsConnection_query_graphql$VannaFrontend.node, fr);
  var data = ReasonRelay_Internal.internal_useConvertedValue(SelfCheckoutsConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(SelfCheckoutsRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

function useBlockingPagination(fRef) {
  var p = Hooks.useBlockingPaginationFragment(SelfCheckoutsConnection_query_graphql$VannaFrontend.node, fRef);
  var data = ReasonRelay_Internal.internal_useConvertedValue(SelfCheckoutsConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(SelfCheckoutsRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

var makeRefetchVariables = SelfCheckoutsRefetchQuery_graphql$VannaFrontend.Types.makeRefetchVariables;

var SelfCheckoutsConnectionFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Internal: Internal,
  InternalRefetch: InternalRefetch,
  useRefetchable: useRefetchable,
  use: use,
  useOpt: useOpt,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination,
  makeRefetchVariables: makeRefetchVariables
};

var Internal$1 = {};

function use$1(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(SelfCheckoutsQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(SelfCheckoutsQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(SelfCheckoutsQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(SelfCheckoutsQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, SelfCheckoutsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, SelfCheckoutsQuery_graphql$VannaFrontend.node, SelfCheckoutsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: SelfCheckoutsQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, SelfCheckoutsQuery_graphql$VannaFrontend.node, SelfCheckoutsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return SelfCheckoutsQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(SelfCheckoutsQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(SelfCheckoutsQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var SelfCheckoutsQuery = {
  Types: undefined,
  Internal: Internal$1,
  use: use$1,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var Internal$2 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.node,
              variables: SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var DeleteSelfCheckoutMutation_make_selfCheckoutDeleteInput = SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Utils.make_selfCheckoutDeleteInput;

var DeleteSelfCheckoutMutation_makeVariables = SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Utils.makeVariables;

var DeleteSelfCheckoutMutation_make_response_selfCheckoutDelete_error = SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutDelete_error;

var DeleteSelfCheckoutMutation_make_response_selfCheckoutDelete = SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Utils.make_response_selfCheckoutDelete;

var DeleteSelfCheckoutMutation_makeOptimisticResponse = SelfCheckouts_SelfCheckoutDeleteMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var DeleteSelfCheckoutMutation = {
  make_selfCheckoutDeleteInput: DeleteSelfCheckoutMutation_make_selfCheckoutDeleteInput,
  makeVariables: DeleteSelfCheckoutMutation_makeVariables,
  make_response_selfCheckoutDelete_error: DeleteSelfCheckoutMutation_make_response_selfCheckoutDelete_error,
  make_response_selfCheckoutDelete: DeleteSelfCheckoutMutation_make_response_selfCheckoutDelete,
  makeOptimisticResponse: DeleteSelfCheckoutMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation,
  use: use$2
};

var SelfCheckoutsTable = Table$VannaFrontend.Make({});

function SelfCheckouts$Inner(Props) {
  var $$navigator = Props.navigator;
  var queryRef = Props.query;
  var match = use$2(undefined);
  var isDelettingSelfCheckout = match[1];
  var deleteSelfCheckout = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setDeleteError = match$1[1];
  var deleteError = match$1[0];
  var match$2 = usePagination(queryRef);
  var hasNext = match$2.hasNext;
  var loadNext = match$2.loadNext;
  var selfCheckouts = Curry._1(getConnectionNodes, match$2.data.selfCheckouts);
  return React.createElement(React.Fragment, undefined, React.createElement(RequireKYC$VannaFrontend.make, {
                  navigator: $$navigator,
                  fragment: queryRef,
                  children: (function (param) {
                      return React.createElement(React.Fragment, undefined, React.createElement(PageHeader$VannaFrontend.make, {
                                      title: "Links de Pagamento",
                                      upsertModal: {
                                        renderModal: (function (dispatch) {
                                            return React.createElement(SelfCheckoutUpsert$VannaFrontend.make, {
                                                        selfCheckout: undefined,
                                                        me: queryRef,
                                                        dispatchModal: dispatch
                                                      });
                                          }),
                                        displayName: "link de pagamento"
                                      }
                                    }), React.createElement(Panel$VannaFrontend.make, {
                                      children: React.createElement(SelfCheckoutsTable.make, {
                                            data: selfCheckouts,
                                            columns: {
                                              hd: Table$VannaFrontend.column("Nome", undefined, (function (selfCheckout, param) {
                                                      return Belt_Option.getWithDefault(selfCheckout.description, "-");
                                                    })),
                                              tl: {
                                                hd: Table$VannaFrontend.column("Link", undefined, (function (selfCheckout, param) {
                                                        var checkoutId = selfCheckout.id;
                                                        var link = "" + vannaEndpoint + "?checkoutId=" + checkoutId;
                                                        return React.createElement(CopyableContent$VannaFrontend.make, {
                                                                    content: link,
                                                                    children: React.createElement(Link$VannaFrontend.Hyperlink.make, {
                                                                          href: link,
                                                                          target: "_blank",
                                                                          children: link
                                                                        })
                                                                  });
                                                      })),
                                                tl: {
                                                  hd: Table$VannaFrontend.column("", undefined, (function (selfCheckout, param) {
                                                          return React.createElement(Modal$VannaFrontend.make, {
                                                                      title: "Atualizar link de pagamento",
                                                                      trigger: (function (dispatch) {
                                                                          return React.createElement(IconButton$VannaFrontend.make, {
                                                                                      onClick: (function (param, param$1, param$2) {
                                                                                          return Curry._1(dispatch, /* Open */2);
                                                                                        }),
                                                                                      children: React.createElement(Fi.FiEdit3, {})
                                                                                    });
                                                                        }),
                                                                      content: (function (dispatch) {
                                                                          return React.createElement(SelfCheckoutUpsert$VannaFrontend.make, {
                                                                                      selfCheckout: Caml_option.some(selfCheckout.fragmentRefs),
                                                                                      me: queryRef,
                                                                                      dispatchModal: dispatch
                                                                                    });
                                                                        })
                                                                    });
                                                        })),
                                                  tl: {
                                                    hd: Table$VannaFrontend.column("", undefined, (function (selfCheckout, param) {
                                                            return React.createElement(DeleteModal$VannaFrontend.make, {
                                                                        displayName: "link de pagamento",
                                                                        onConfirm: (function (dispatch) {
                                                                            Curry._1(setDeleteError, (function (param) {
                                                                                    
                                                                                  }));
                                                                            Curry.app(deleteSelfCheckout, [
                                                                                  (function (param) {
                                                                                      return Curry._1(setDeleteError, (function (param) {
                                                                                                    return "Algo deu errado, tente novamente";
                                                                                                  }));
                                                                                    }),
                                                                                  (function (response, _errors) {
                                                                                      var match = response.selfCheckoutDelete;
                                                                                      if (match === undefined) {
                                                                                        return Curry._1(setDeleteError, (function (param) {
                                                                                                      return "Algo deu errado, tente novamente";
                                                                                                    }));
                                                                                      }
                                                                                      var match$1 = match.error;
                                                                                      if (match$1 === undefined) {
                                                                                        return Curry._1(dispatch, /* Close */1);
                                                                                      }
                                                                                      var message = match$1.message;
                                                                                      return Curry._1(setDeleteError, (function (param) {
                                                                                                    return message;
                                                                                                  }));
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  (function (store, response) {
                                                                                      var match = response.selfCheckoutDelete;
                                                                                      if (match === undefined) {
                                                                                        return ;
                                                                                      }
                                                                                      if (match.error !== undefined) {
                                                                                        return ;
                                                                                      }
                                                                                      var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                                                                                      if (meProxy == null) {
                                                                                        return ;
                                                                                      }
                                                                                      var selfCheckoutsConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "SelfCheckouts_selfCheckouts", undefined);
                                                                                      if (!(selfCheckoutsConnection == null)) {
                                                                                        RelayRuntime.ConnectionHandler.deleteNode(selfCheckoutsConnection, selfCheckout.id);
                                                                                        return ;
                                                                                      }
                                                                                      
                                                                                    }),
                                                                                  {
                                                                                    input: {
                                                                                      selfCheckoutId: selfCheckout.id
                                                                                    }
                                                                                  },
                                                                                  undefined,
                                                                                  undefined
                                                                                ]);
                                                                            
                                                                          }),
                                                                        onClose: (function (param) {
                                                                            return Curry._1(setDeleteError, (function (param) {
                                                                                          
                                                                                        }));
                                                                          }),
                                                                        isLoading: isDelettingSelfCheckout,
                                                                        error: deleteError
                                                                      });
                                                          })),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            },
                                            getItemId: (function (selfCheckout) {
                                                return selfCheckout.id;
                                              }),
                                            hasNextPage: hasNext,
                                            loadMore: (function (param) {
                                                Curry._3(loadNext, 20, undefined, undefined);
                                                
                                              })
                                          })
                                    }));
                    })
                }));
}

var Inner = {
  make: SelfCheckouts$Inner
};

function SelfCheckouts(Props) {
  var $$navigator = Props.navigator;
  var queryData = use$1(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var me = queryData.me;
  if (me !== undefined) {
    return React.createElement(PageQueryRenderer$VannaFrontend.make, {
                children: React.createElement(PrivateLayout$VannaFrontend.make, {
                      navigator: $$navigator,
                      children: React.createElement(SelfCheckouts$Inner, {
                            navigator: $$navigator,
                            query: me.fragmentRefs
                          })
                    })
              });
  } else {
    return null;
  }
}

var pageSize = 20;

var make = SelfCheckouts;

exports.vannaEndpoint = vannaEndpoint;
exports.pageSize = pageSize;
exports.SelfCheckoutsConnectionFragment = SelfCheckoutsConnectionFragment;
exports.SelfCheckoutsQuery = SelfCheckoutsQuery;
exports.DeleteSelfCheckoutMutation = DeleteSelfCheckoutMutation;
exports.SelfCheckoutsTable = SelfCheckoutsTable;
exports.Inner = Inner;
exports.make = make;
/* SelfCheckoutsTable Not a pure module */
