'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$VannaFrontend = require("../Theme.bs.js");

var hr = Curry._1(Css.style, {
      hd: Css.marginTop({
            NAME: "rem",
            VAL: 1.0
          }),
      tl: {
        hd: Css.marginBottom({
              NAME: "rem",
              VAL: 1.0
            }),
        tl: {
          hd: Css.marginLeft({
                NAME: "rem",
                VAL: 0.0
              }),
          tl: {
            hd: Css.marginRight({
                  NAME: "rem",
                  VAL: 0.0
                }),
            tl: {
              hd: Css.borderBottomWidth({
                    NAME: "px",
                    VAL: 1
                  }),
              tl: {
                hd: Css.borderBottomStyle("solid"),
                tl: {
                  hd: Css.borderBottomColor(Theme$VannaFrontend.Colors.border),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  hr: hr
};

function Hr(Props) {
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: hr,
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  })
            });
}

var make = Hr;

exports.Styles = Styles;
exports.make = make;
/* hr Not a pure module */
