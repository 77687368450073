'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Hooks = require("react-relay/hooks");
var Tabs$VannaFrontend = require("../components/Tabs.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var SecurityForm$VannaFrontend = require("../components/SecurityForm.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var CompanyInfoForm$VannaFrontend = require("../components/CompanyInfoForm.bs.js");
var WhitelabelInfoForm$VannaFrontend = require("../components/WhitelabelInfoForm.bs.js");
var BankAccountInfoForm$VannaFrontend = require("../components/BankAccountInfoForm.bs.js");
var SettingsQuery_graphql$VannaFrontend = require("../__generated__/SettingsQuery_graphql.bs.js");

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(SettingsQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(SettingsQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(SettingsQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(SettingsQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, SettingsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, SettingsQuery_graphql$VannaFrontend.node, SettingsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: SettingsQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, SettingsQuery_graphql$VannaFrontend.node, SettingsQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return SettingsQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(SettingsQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(SettingsQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var SettingsQuery = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

function Settings(Props) {
  var $$navigator = Props.navigator;
  var defaultTab = Props.defaultTab;
  var queryData = use(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var me = queryData.me;
  return React.createElement(PrivateLayout$VannaFrontend.make, {
              navigator: $$navigator,
              children: null
            }, React.createElement(PageHeader$VannaFrontend.make, {
                  title: "Configurações",
                  withMarginBottom: true
                }), me !== undefined ? React.createElement(Tabs$VannaFrontend.make, {
                    defaultTab: defaultTab,
                    list_: [
                      {
                        name: "dados-cadastrais",
                        title: "Dados cadastrais",
                        disabled: false,
                        badge: null,
                        body: React.createElement(CompanyInfoForm$VannaFrontend.make, {
                              fragment: me.fragmentRefs
                            })
                      },
                      {
                        name: "metodo-de-recebimento",
                        title: "Método de recebimento",
                        disabled: me.settings.kycStatus !== "DONE",
                        badge: null,
                        body: React.createElement(BankAccountInfoForm$VannaFrontend.make, {
                              fragment: me.fragmentRefs
                            })
                      },
                      {
                        name: "customizacoes",
                        title: "Customizações",
                        disabled: false,
                        badge: null,
                        body: React.createElement(WhitelabelInfoForm$VannaFrontend.make, {
                              fragment: me.fragmentRefs
                            })
                      },
                      {
                        name: "seguranca",
                        title: "Segurança",
                        disabled: false,
                        badge: null,
                        body: React.createElement(SecurityForm$VannaFrontend.make, {
                              fragment: me.fragmentRefs
                            })
                      }
                    ],
                    onChange: (function (tabName) {
                        return Curry._1($$navigator.push, {
                                    TAG: /* Settings */8,
                                    _0: tabName
                                  });
                      })
                  }) : null);
}

var make = Settings;

exports.SettingsQuery = SettingsQuery;
exports.make = make;
/* react Not a pure module */
