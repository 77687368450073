'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"me_settings_companyInfo_taxId":{"n":""},"me_settings_companyInfo_tradeName":{"n":""},"me":{"f":"","n":""},"me_visualization":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"me_settings_companyInfo_taxId":{"n":""},"me_settings_companyInfo_tradeName":{"n":""},"me":{"f":"","n":""},"me_visualization":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tradeName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Visualization",
  "kind": "LinkedField",
  "name": "visualization",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pixAccountBalance",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isEnabled",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PixQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Settings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingsCompanyInfo",
                "kind": "LinkedField",
                "name": "companyInfo",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PixConnection_query"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PixWithdrawalModalFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RequireKYCFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PixQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Settings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingsCompanyInfo",
                "kind": "LinkedField",
                "name": "companyInfo",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneAreaCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kycStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "TransactionConnection",
            "kind": "LinkedField",
            "name": "transactions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TransactionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Transaction",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "metadata",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "TransactionMetaEvent",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "TransactionMetaPixSent",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "TransactionMetaPixReceived",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "transactions(first:20)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Pix_transactions",
            "kind": "LinkedHandle",
            "name": "transactions"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GoogleAuthType",
            "kind": "LinkedField",
            "name": "googleAuth",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SmsAuthType",
            "kind": "LinkedField",
            "name": "smsAuth",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "43fd4ceea4c6fb4d9039bd839ce072be",
    "id": null,
    "metadata": {},
    "name": "PixQuery",
    "operationKind": "query",
    "text": "query PixQuery {\n  me {\n    id\n    settings {\n      companyInfo {\n        tradeName\n        taxId\n        id\n      }\n      id\n    }\n    visualization {\n      pixAccountBalance\n    }\n    ...PixConnection_query\n    ...PixWithdrawalModalFragment\n    ...RequireKYCFragment\n  }\n}\n\nfragment PixConnection_query on User {\n  transactions(first: 20) {\n    edges {\n      node {\n        id\n        amount\n        metadata {\n          __typename\n          ... on TransactionMetaEvent {\n            kind\n            status\n            description\n          }\n          ... on TransactionMetaPixSent {\n            kind\n            status\n            description\n          }\n          ... on TransactionMetaPixReceived {\n            kind\n            status\n            description\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment PixWithdrawalModalFragment on User {\n  id\n  settings {\n    companyInfo {\n      tradeName\n      taxId\n      id\n    }\n    id\n  }\n  visualization {\n    pixAccountBalance\n  }\n  googleAuth {\n    isEnabled\n  }\n  smsAuth {\n    isEnabled\n  }\n}\n\nfragment RequireKYCFragment on User {\n  id\n  settings {\n    id\n    kycStatus\n    companyInfo {\n      taxId\n      name\n      phoneAreaCode\n      phoneNumber\n      tradeName\n      id\n    }\n  }\n}\n"
  }
};
})());

var include = ReasonRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
