'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Fi = require("react-icons/fi");

var keyframes = Curry._1(Css.keyframes, {
      hd: [
        0,
        {
          hd: Css.transform(Css.rotate({
                    NAME: "deg",
                    VAL: 0.0
                  })),
          tl: /* [] */0
        }
      ],
      tl: /* [] */0
    });

var style = Curry._1(Css.style, {
      hd: Css.animation(2, undefined, undefined, "linear", undefined, undefined, "infinite", keyframes),
      tl: /* [] */0
    });

var Styles = {
  keyframes: keyframes,
  style: style
};

function Spinner(Props) {
  return React.createElement(Fi.FiLoader, {
              className: style
            });
}

var make = Spinner;

exports.Styles = Styles;
exports.make = make;
/* keyframes Not a pure module */
