'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Fi = require("react-icons/fi");
var Box$VannaFrontend = require("./Box.bs.js");
var Text$VannaFrontend = require("./Text.bs.js");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var Loading$VannaFrontend = require("./Loading.bs.js");
var IconButton$VannaFrontend = require("./IconButton.bs.js");

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexEnd"),
        tl: {
          hd: Css.marginTop({
                NAME: "px",
                VAL: 30
              }),
          tl: /* [] */0
        }
      }
    });

var cancelButton = Curry._1(Css.style, {
      hd: Css.marginLeft({
            NAME: "px",
            VAL: 20
          }),
      tl: /* [] */0
    });

var Styles = {
  buttons: buttons,
  cancelButton: cancelButton
};

function DeleteModal(Props) {
  var displayName = Props.displayName;
  var onConfirm = Props.onConfirm;
  var onCloseOpt = Props.onClose;
  var isLoading = Props.isLoading;
  var error = Props.error;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  return React.createElement(Modal$VannaFrontend.make, {
              title: "Você tem certeza que deseja remover este cliente?",
              trigger: (function (dispatch) {
                  return React.createElement(IconButton$VannaFrontend.make, {
                              onClick: (function (param, param$1, param$2) {
                                  return Curry._1(dispatch, /* Open */2);
                                }),
                              children: React.createElement(Fi.FiTrash2, {})
                            });
                }),
              content: (function (dispatch) {
                  return React.createElement(React.Fragment, undefined, React.createElement(Text$VannaFrontend.make, {
                                  children: "Você tem certeza que deseja remover este(a) " + displayName + "?"
                                }), error !== undefined ? React.createElement(Box$VannaFrontend.make, {
                                    type_: /* Error */2,
                                    title: "Ops!",
                                    children: error
                                  }) : null, React.createElement("div", {
                                  className: buttons
                                }, React.createElement(Button$VannaFrontend.make, {
                                      fullWidth: false,
                                      mode: /* Secondary */1,
                                      onClick: (function (param) {
                                          return Curry._1(dispatch, /* Close */1);
                                        }),
                                      children: "Cancelar"
                                    }), React.createElement(Button$VannaFrontend.make, {
                                      fullWidth: false,
                                      mode: /* Danger */2,
                                      onClick: (function (param) {
                                          return Curry._1(onConfirm, dispatch);
                                        }),
                                      disabled: isLoading,
                                      className: cancelButton,
                                      children: isLoading ? React.createElement(Loading$VannaFrontend.make, {}) : "Deletar"
                                    })));
                }),
              confirmClose: false,
              onClose: onClose
            });
}

var make = DeleteModal;

exports.Styles = Styles;
exports.make = make;
/* buttons Not a pure module */
