'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ViaCep$VannaFrontend = require("../types/ViaCep.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");

function ZipcodeField(Props) {
  var error = Props.error;
  var requiredOpt = Props.required;
  var value = Props.value;
  var onChange = Props.onChange;
  var onAddressInfoLoaded = Props.onAddressInfoLoaded;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  return React.createElement(TextField$VannaFrontend.make, {
              label: "CEP",
              error: error,
              required: required,
              floatRight: match[0] ? "Carregando..." : null,
              mask: "99999-999",
              value: value,
              onChange: (function (value) {
                  Curry._1(onChange, value);
                  var cep = value.replace("-", "");
                  if (cep.length === 8 && (error === undefined || Caml_obj.caml_equal(error, ""))) {
                    Curry._1(setLoading, (function (param) {
                            return true;
                          }));
                    $$Promise.tapOk($$Promise.tapError(ViaCep$VannaFrontend.$$fetch(cep), (function (_error) {
                                return Curry._1(setLoading, (function (param) {
                                              return false;
                                            }));
                              })), (function (response) {
                            if (response.TAG === /* Ok */0) {
                              var response$1 = response._0;
                              Curry._1(onAddressInfoLoaded, {
                                    zipcode: response$1.cep,
                                    address: Belt_Option.getWithDefault(response$1.logradouro, ""),
                                    neighborhood: Belt_Option.getWithDefault(response$1.bairro, ""),
                                    cityCode: Belt_Option.getWithDefault(response$1.ibge, ""),
                                    cityName: Belt_Option.getWithDefault(response$1.localidade, ""),
                                    state: Belt_Option.getWithDefault(response$1.uf, ""),
                                    complement: Belt_Option.getWithDefault(response$1.complemento, "")
                                  });
                            }
                            return Curry._1(setLoading, (function (param) {
                                          return false;
                                        }));
                          }));
                    return ;
                  }
                  
                })
            });
}

var make = ZipcodeField;

exports.make = make;
/* react Not a pure module */
