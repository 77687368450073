'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("../components/Box.bs.js");
var Link$VannaFrontend = require("../components/Link.bs.js");
var Text$VannaFrontend = require("../components/Text.bs.js");
var Button$VannaFrontend = require("../components/Button.bs.js");
var TextField$VannaFrontend = require("../components/TextField.bs.js");
var BrandPanel$VannaFrontend = require("../components/BrandPanel.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend = require("../__generated__/ResetPassword_UserResetPasswordMutation_graphql.bs.js");

function get(state, field) {
  switch (field) {
    case /* Email */0 :
        return state.email;
    case /* Password */1 :
        return state.password;
    case /* ConfirmPassword */2 :
        return state.confirmPassword;
    case /* GoogleAuthCode */3 :
        return state.googleAuthCode;
    case /* SmsAuthCode */4 :
        return state.smsAuthCode;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Email */0 :
        return {
                email: value,
                password: state.password,
                confirmPassword: state.confirmPassword,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* Password */1 :
        return {
                email: state.email,
                password: value,
                confirmPassword: state.confirmPassword,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* ConfirmPassword */2 :
        return {
                email: state.email,
                password: state.password,
                confirmPassword: value,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* GoogleAuthCode */3 :
        return {
                email: state.email,
                password: state.password,
                confirmPassword: state.confirmPassword,
                googleAuthCode: value,
                smsAuthCode: state.smsAuthCode
              };
    case /* SmsAuthCode */4 :
        return {
                email: state.email,
                password: state.password,
                confirmPassword: state.confirmPassword,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var ResetPasswordForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.node,
              variables: ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ResetPasswordMutation_make_userResetPasswordInput = ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Utils.make_userResetPasswordInput;

var ResetPasswordMutation_makeVariables = ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Utils.makeVariables;

var ResetPasswordMutation_make_response_userResetPassword_error = ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Utils.make_response_userResetPassword_error;

var ResetPasswordMutation_make_response_userResetPassword = ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Utils.make_response_userResetPassword;

var ResetPasswordMutation_makeOptimisticResponse = ResetPassword_UserResetPasswordMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ResetPasswordMutation = {
  make_userResetPasswordInput: ResetPasswordMutation_make_userResetPasswordInput,
  makeVariables: ResetPasswordMutation_makeVariables,
  make_response_userResetPassword_error: ResetPasswordMutation_make_response_userResetPassword_error,
  make_response_userResetPassword: ResetPasswordMutation_make_response_userResetPassword,
  makeOptimisticResponse: ResetPasswordMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

var okWrapper = Curry._1(Css.style, {
      hd: Css.textAlign("center"),
      tl: /* [] */0
    });

var Styles = {
  okWrapper: okWrapper
};

function ResetPassword(Props) {
  var token = Props.token;
  var $$navigator = Props.navigator;
  var match = React.useState(function () {
        return false;
      });
  var setOk = match[1];
  var match$1 = use(undefined);
  var resetPassword = match$1[0];
  var reform = Curry._7(ResetPasswordForm.use, {
        email: "",
        password: "",
        confirmPassword: "",
        googleAuthCode: "",
        smsAuthCode: ""
      }, /* Schema */{
        _0: Curry._2(ResetPasswordForm.ReSchema.Validation.$plus, Curry._2(ResetPasswordForm.ReSchema.Validation.$plus, Curry._3(ResetPasswordForm.ReSchema.Validation.email, "Email inválido", undefined, /* Email */0), Curry._6(ResetPasswordForm.ReSchema.Validation.string, 8, "A senha precisa ter pelo menos 8 caracteres", undefined, undefined, undefined, /* Password */1)), Curry._3(ResetPasswordForm.ReSchema.Validation.custom, (function (param) {
                    if (param.password !== param.confirmPassword) {
                      return {
                              TAG: /* Error */1,
                              _0: "Suas senhas não conferem"
                            };
                    } else {
                      return /* Valid */0;
                    }
                  }), undefined, /* ConfirmPassword */2))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          Curry.app(resetPassword, [
                (function (param) {
                    Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    return Curry._1(send, {
                                TAG: /* SetFormState */10,
                                _0: /* Dirty */0
                              });
                  }),
                (function (response, _errors) {
                    var match = response.userResetPassword;
                    if (match !== undefined) {
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        Curry._1(raiseSubmitFailed, match$1.message);
                      } else {
                        Curry._1(setOk, (function (param) {
                                return true;
                              }));
                      }
                    } else {
                      Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    return Curry._1(send, {
                                TAG: /* SetFormState */10,
                                _0: /* Dirty */0
                              });
                  }),
                undefined,
                undefined,
                undefined,
                undefined,
                {
                  input: {
                    email: state.values.email,
                    newPassword: state.values.password,
                    token: token
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var tmp;
  if (match[0]) {
    tmp = React.createElement("div", {
          className: okWrapper
        }, React.createElement(Text$VannaFrontend.make, {
              children: "Sua senha foi redefinida com sucesso. Use o botão abaixo para acessar a página de login:"
            }), React.createElement(Link$VannaFrontend.make, {
              content: "Ir para o login",
              onClick: (function (param) {
                  return Curry._1($$navigator.push, /* Login */0);
                })
            }));
  } else {
    var match$2 = reform.formState;
    var tmp$1;
    if (typeof match$2 === "number") {
      tmp$1 = null;
    } else {
      var error = match$2._0;
      tmp$1 = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
              type_: /* Error */2,
              title: "Ops!",
              children: error
            }) : null;
    }
    tmp = React.createElement("form", {
          onSubmit: (function ($$event) {
              $$event.preventDefault();
              return Curry._1(reform.submit, undefined);
            })
        }, React.createElement(ResetPasswordForm.Field.make, {
              field: /* Email */0,
              render: (function (param) {
                  return React.createElement(TextField$VannaFrontend.make, {
                              label: "Confirme seu email",
                              error: param.error,
                              type_: /* Email */0,
                              value: param.value,
                              onChange: param.handleChange
                            });
                })
            }), React.createElement(ResetPasswordForm.Field.make, {
              field: /* Password */1,
              render: (function (param) {
                  return React.createElement(TextField$VannaFrontend.make, {
                              label: "Sua nova senha",
                              error: param.error,
                              type_: /* Password */4,
                              value: param.value,
                              onChange: param.handleChange
                            });
                })
            }), React.createElement(ResetPasswordForm.Field.make, {
              field: /* ConfirmPassword */2,
              render: (function (param) {
                  return React.createElement(TextField$VannaFrontend.make, {
                              label: "Confirme sua nova senha",
                              error: param.error,
                              type_: /* Password */4,
                              value: param.value,
                              onChange: param.handleChange
                            });
                })
            }), tmp$1, React.createElement(Button$VannaFrontend.make, {
              isLoading: reform.isSubmitting,
              type_: /* Submit */1,
              children: "Redefinir senha"
            }));
  }
  return React.createElement(BrandPanel$VannaFrontend.make, {
              title: "Redefina sua senha",
              children: React.createElement(ResetPasswordForm.Provider.make, Curry._3(ResetPasswordForm.Provider.makeProps, reform, tmp, undefined))
            });
}

var make = ResetPassword;

exports.StateLenses = StateLenses;
exports.ResetPasswordForm = ResetPasswordForm;
exports.ResetPasswordMutation = ResetPasswordMutation;
exports.Styles = Styles;
exports.make = make;
/* ResetPasswordForm Not a pure module */
