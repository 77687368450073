'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Uuid = require("uuid");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Fi = require("react-icons/fi");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");
var FieldInfo$VannaFrontend = require("./FieldInfo.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var MoneyField$VannaFrontend = require("./MoneyField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var NumberField$VannaFrontend = require("./NumberField.bs.js");
var ChargeHelpers$VannaFrontend = require("../types/ChargeHelpers.bs.js");
var ProductUpsertForm$VannaFrontend = require("./ProductUpsertForm.bs.js");
var SearchableSelectField$VannaFrontend = require("./SearchableSelectField.bs.js");
var ItemTableUpsertProductFormRefetchQuery_graphql$VannaFrontend = require("../__generated__/ItemTableUpsertProductFormRefetchQuery_graphql.bs.js");
var ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend = require("../__generated__/ItemTableUpsertProductFormConnection_products_graphql.bs.js");

var thirtyPercentWidth = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 33.333
          }),
      tl: /* [] */0
    });

var Styles = {
  thirtyPercentWidth: thirtyPercentWidth
};

function get(state, field) {
  switch (field) {
    case /* ProductId */0 :
        return state.productId;
    case /* Price */1 :
        return state.price;
    case /* Name */2 :
        return state.name;
    case /* Quantity */3 :
        return state.quantity;
    case /* DiscountAmount */4 :
        return state.discountAmount;
    case /* FreightAmount */5 :
        return state.freightAmount;
    case /* InsuranceAmount */6 :
        return state.insuranceAmount;
    case /* OthersAmount */7 :
        return state.othersAmount;
    case /* MaxQuantity */8 :
        return state.maxQuantity;
    case /* HasMaxQuantity */9 :
        return state.hasMaxQuantity;
    case /* ChargeType */10 :
        return state.chargeType;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* ProductId */0 :
        return {
                productId: value,
                price: state.price,
                name: state.name,
                quantity: state.quantity,
                discountAmount: state.discountAmount,
                freightAmount: state.freightAmount,
                insuranceAmount: state.insuranceAmount,
                othersAmount: state.othersAmount,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: state.chargeType
              };
    case /* Price */1 :
        return {
                productId: state.productId,
                price: value,
                name: state.name,
                quantity: state.quantity,
                discountAmount: state.discountAmount,
                freightAmount: state.freightAmount,
                insuranceAmount: state.insuranceAmount,
                othersAmount: state.othersAmount,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: state.chargeType
              };
    case /* Name */2 :
        return {
                productId: state.productId,
                price: state.price,
                name: value,
                quantity: state.quantity,
                discountAmount: state.discountAmount,
                freightAmount: state.freightAmount,
                insuranceAmount: state.insuranceAmount,
                othersAmount: state.othersAmount,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: state.chargeType
              };
    case /* Quantity */3 :
        return {
                productId: state.productId,
                price: state.price,
                name: state.name,
                quantity: value,
                discountAmount: state.discountAmount,
                freightAmount: state.freightAmount,
                insuranceAmount: state.insuranceAmount,
                othersAmount: state.othersAmount,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: state.chargeType
              };
    case /* DiscountAmount */4 :
        return {
                productId: state.productId,
                price: state.price,
                name: state.name,
                quantity: state.quantity,
                discountAmount: value,
                freightAmount: state.freightAmount,
                insuranceAmount: state.insuranceAmount,
                othersAmount: state.othersAmount,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: state.chargeType
              };
    case /* FreightAmount */5 :
        return {
                productId: state.productId,
                price: state.price,
                name: state.name,
                quantity: state.quantity,
                discountAmount: state.discountAmount,
                freightAmount: value,
                insuranceAmount: state.insuranceAmount,
                othersAmount: state.othersAmount,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: state.chargeType
              };
    case /* InsuranceAmount */6 :
        return {
                productId: state.productId,
                price: state.price,
                name: state.name,
                quantity: state.quantity,
                discountAmount: state.discountAmount,
                freightAmount: state.freightAmount,
                insuranceAmount: value,
                othersAmount: state.othersAmount,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: state.chargeType
              };
    case /* OthersAmount */7 :
        return {
                productId: state.productId,
                price: state.price,
                name: state.name,
                quantity: state.quantity,
                discountAmount: state.discountAmount,
                freightAmount: state.freightAmount,
                insuranceAmount: state.insuranceAmount,
                othersAmount: value,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: state.chargeType
              };
    case /* MaxQuantity */8 :
        return {
                productId: state.productId,
                price: state.price,
                name: state.name,
                quantity: state.quantity,
                discountAmount: state.discountAmount,
                freightAmount: state.freightAmount,
                insuranceAmount: state.insuranceAmount,
                othersAmount: state.othersAmount,
                maxQuantity: value,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: state.chargeType
              };
    case /* HasMaxQuantity */9 :
        return {
                productId: state.productId,
                price: state.price,
                name: state.name,
                quantity: state.quantity,
                discountAmount: state.discountAmount,
                freightAmount: state.freightAmount,
                insuranceAmount: state.insuranceAmount,
                othersAmount: state.othersAmount,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: value,
                chargeType: state.chargeType
              };
    case /* ChargeType */10 :
        return {
                productId: state.productId,
                price: state.price,
                name: state.name,
                quantity: state.quantity,
                discountAmount: state.discountAmount,
                freightAmount: state.freightAmount,
                insuranceAmount: state.insuranceAmount,
                othersAmount: state.othersAmount,
                maxQuantity: state.maxQuantity,
                hasMaxQuantity: state.hasMaxQuantity,
                chargeType: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var ItemTableUpsertProductForm = ReForm.Make({
      set: set,
      get: get
    });

var getConnectionNodes = ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.Utils.getConnectionNodes;

var Internal = {};

function internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = ReasonRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = ReasonRelay.mapRenderPolicy(renderPolicy);
  if (tmp$2 !== undefined) {
    tmp.UNSTABLE_renderPolicy = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$3 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var InternalRefetch = {
  internal_makeRefetchableFnOpts: internal_makeRefetchableFnOpts
};

function useRefetchable(fRef) {
  var match = Hooks.useRefetchableFragment(ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.node, fRef);
  var refetchFn = match[1];
  var data = ReasonRelay_Internal.internal_useConvertedValue(ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.Internal.convertFragment, match[0]);
  return [
          data,
          (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return Curry._2(refetchFn, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ItemTableUpsertProductFormRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        ];
}

function use(fRef) {
  var data = Hooks.useFragment(ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function usePagination(fr) {
  var p = Hooks.usePaginationFragment(ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.node, fr);
  var data = ReasonRelay_Internal.internal_useConvertedValue(ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ItemTableUpsertProductFormRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

function useBlockingPagination(fRef) {
  var p = Hooks.useBlockingPaginationFragment(ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.node, fRef);
  var data = ReasonRelay_Internal.internal_useConvertedValue(ItemTableUpsertProductFormConnection_products_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ItemTableUpsertProductFormRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

var makeRefetchVariables = ItemTableUpsertProductFormRefetchQuery_graphql$VannaFrontend.Types.makeRefetchVariables;

var ProductsConnectionFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Internal: Internal,
  InternalRefetch: InternalRefetch,
  useRefetchable: useRefetchable,
  use: use,
  useOpt: useOpt,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination,
  makeRefetchVariables: makeRefetchVariables
};

var ProductsSelect = SearchableSelectField$VannaFrontend.Make({});

var BooleanSelectField = SearchableSelectField$VannaFrontend.Make({});

var ChargeTypeSelectField = SearchableSelectField$VannaFrontend.Make({});

function ItemTableUpsertProductForm$1(Props) {
  var domain = Props.domain;
  var item = Props.item;
  var dispatchModal = Props.dispatchModal;
  var onUpsertOpt = Props.onUpsert;
  var queryRef = Props.query;
  var onUpsert = onUpsertOpt !== undefined ? onUpsertOpt : (function (param) {
        
      });
  var match = usePagination(queryRef);
  var hasNext = match.hasNext;
  var loadNext = match.loadNext;
  var products = Curry._1(getConnectionNodes, match.data.products);
  var tmp;
  if (item !== undefined) {
    if (item.TAG === /* Product */0) {
      var item$1 = item._0;
      tmp = {
        productId: item$1.itemId,
        price: item$1.price,
        name: item$1.name,
        quantity: item$1.quantity,
        discountAmount: Money$VannaFrontend.toDecimal(Money$VannaFrontend.make({
                  TAG: /* Cents */1,
                  _0: item$1.discountAmount
                })),
        freightAmount: Money$VannaFrontend.toDecimal(Money$VannaFrontend.make({
                  TAG: /* Cents */1,
                  _0: item$1.freightAmount
                })),
        insuranceAmount: Money$VannaFrontend.toDecimal(Money$VannaFrontend.make({
                  TAG: /* Cents */1,
                  _0: item$1.insuranceAmount
                })),
        othersAmount: Money$VannaFrontend.toDecimal(Money$VannaFrontend.make({
                  TAG: /* Cents */1,
                  _0: item$1.othersAmount
                })),
        maxQuantity: item$1.maxQuantity,
        hasMaxQuantity: item$1.hasMaxQuantity,
        chargeType: item$1.chargeType
      };
    } else {
      tmp = {
        productId: "",
        price: 0,
        name: "",
        quantity: 1,
        discountAmount: 0,
        freightAmount: 0,
        insuranceAmount: 0,
        othersAmount: 0,
        maxQuantity: 0,
        hasMaxQuantity: false,
        chargeType: /* Single */0
      };
    }
  } else {
    tmp = {
      productId: "",
      price: 0,
      name: "",
      quantity: 1,
      discountAmount: 0,
      freightAmount: 0,
      insuranceAmount: 0,
      othersAmount: 0,
      maxQuantity: 0,
      hasMaxQuantity: false,
      chargeType: /* Single */0
    };
  }
  var reform = Curry._7(ItemTableUpsertProductForm.use, tmp, /* Schema */{
        _0: Curry._2(ItemTableUpsertProductForm.ReSchema.Validation.$plus, Curry._2(ItemTableUpsertProductForm.ReSchema.Validation.$plus, Curry._3(ItemTableUpsertProductForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* ProductId */0), Curry._3(ItemTableUpsertProductForm.ReSchema.Validation.custom, (function (state) {
                        var match = state.quantity;
                        if (domain >= 2 || match > 0) {
                          return /* Valid */0;
                        } else {
                          return {
                                  TAG: /* Error */1,
                                  _0: "A quantidade precisa ser maior que 0"
                                };
                        }
                      }), undefined, /* Quantity */3)), Curry._3(ItemTableUpsertProductForm.ReSchema.Validation.custom, (function (state) {
                    var match = state.hasMaxQuantity;
                    var match$1 = state.maxQuantity;
                    if (domain >= 2 && match && match$1 <= 0) {
                      return {
                              TAG: /* Error */1,
                              _0: "A quantidade máxima precisa ser maior que 0"
                            };
                    } else {
                      return /* Valid */0;
                    }
                  }), undefined, /* MaxQuantity */8))
      }, (function (param) {
          var state = param.state;
          var tmp;
          tmp = item !== undefined ? item._0.temporaryId : Uuid.v4();
          var data_name = state.values.name;
          var data_price = state.values.price;
          var data_quantity = state.values.quantity;
          var data_freightAmount = Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                    TAG: /* Decimal */0,
                    _0: state.values.freightAmount
                  }));
          var data_insuranceAmount = Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                    TAG: /* Decimal */0,
                    _0: state.values.insuranceAmount
                  }));
          var data_othersAmount = Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                    TAG: /* Decimal */0,
                    _0: state.values.othersAmount
                  }));
          var data_discountAmount = Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                    TAG: /* Decimal */0,
                    _0: state.values.discountAmount
                  }));
          var data_itemId = state.values.productId;
          var data_maxQuantity = state.values.maxQuantity;
          var data_hasMaxQuantity = state.values.hasMaxQuantity;
          var data_chargeType = state.values.chargeType;
          var data = {
            name: data_name,
            price: data_price,
            quantity: data_quantity,
            freightAmount: data_freightAmount,
            insuranceAmount: data_insuranceAmount,
            othersAmount: data_othersAmount,
            discountAmount: data_discountAmount,
            itemId: data_itemId,
            temporaryId: tmp,
            maxQuantity: data_maxQuantity,
            hasMaxQuantity: data_hasMaxQuantity,
            chargeType: data_chargeType
          };
          if (item !== undefined) {
            Curry._1(onUpsert, {
                  TAG: /* Update */1,
                  _0: data
                });
          } else {
            Curry._1(onUpsert, {
                  TAG: /* Insert */0,
                  _0: data
                });
          }
          Curry._1(dispatchModal, /* Close */1);
          Curry._1(param.send, {
                TAG: /* SetFormState */10,
                _0: /* Dirty */0
              });
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var itemTotalAmount = ChargeHelpers$VannaFrontend.getTotalUniq({
        name: reform.state.values.name,
        price: reform.state.values.price,
        quantity: reform.state.values.quantity,
        freightAmount: Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                  TAG: /* Decimal */0,
                  _0: reform.state.values.freightAmount
                })),
        insuranceAmount: Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                  TAG: /* Decimal */0,
                  _0: reform.state.values.insuranceAmount
                })),
        othersAmount: Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                  TAG: /* Decimal */0,
                  _0: reform.state.values.othersAmount
                })),
        discountAmount: Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                  TAG: /* Decimal */0,
                  _0: reform.state.values.discountAmount
                })),
        itemId: reform.state.values.productId,
        temporaryId: "",
        maxQuantity: reform.state.values.maxQuantity,
        hasMaxQuantity: reform.state.values.hasMaxQuantity,
        chargeType: reform.state.values.chargeType
      });
  React.useEffect((function () {
          var product = Belt_Array.getBy(products, (function (product) {
                  return product.id === reform.state.values.productId;
                }));
          if (product !== undefined) {
            Curry._4(reform.setFieldValue, /* Price */1, product.price, undefined, undefined);
            Curry._4(reform.setFieldValue, /* Name */2, product.name, undefined, undefined);
          }
          
        }), [reform.state.values.productId]);
  var match$1 = Belt_Array.getBy(products, (function (product) {
          return product.id === reform.state.values.productId;
        }));
  return React.createElement(ItemTableUpsertProductForm.Provider.make, Curry._3(ItemTableUpsertProductForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          
                        })
                    }, React.createElement(FieldGrid$VannaFrontend.make, {
                          children: null
                        }, React.createElement(ItemTableUpsertProductForm.Field.make, {
                              field: /* ProductId */0,
                              render: (function (param) {
                                  var handleChange = param.handleChange;
                                  return React.createElement(ProductsSelect.make, {
                                              label: "Produto",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: (function (param) {
                                                  Curry._1(handleChange, param.value);
                                                  return $$Promise.resolved(undefined);
                                                }),
                                              options: Belt_Array.map(products, (function (product) {
                                                      return {
                                                              label: product.name,
                                                              value: product.id
                                                            };
                                                    })),
                                              loadMore: (function (_search) {
                                                  return new Promise((function (resolve, param) {
                                                                Curry._3(loadNext, 20, (function (error) {
                                                                        if (error !== undefined) {
                                                                          return resolve({
                                                                                      options: [],
                                                                                      hasMore: false
                                                                                    });
                                                                        } else {
                                                                          return resolve({
                                                                                      options: Belt_Array.map(products, (function (cur) {
                                                                                              return {
                                                                                                      label: cur.name,
                                                                                                      value: cur.id
                                                                                                    };
                                                                                            })),
                                                                                      hasMore: hasNext
                                                                                    });
                                                                        }
                                                                      }), undefined);
                                                                
                                                              }));
                                                })
                                            });
                                })
                            }), React.createElement("div", {
                              className: thirtyPercentWidth
                            }, React.createElement(Modal$VannaFrontend.make, {
                                  title: "Adicionar produto",
                                  trigger: (function (dispatch) {
                                      return React.createElement(Button$VannaFrontend.make, {
                                                  mode: /* Secondary */1,
                                                  onClick: (function (param) {
                                                      return Curry._1(dispatch, /* Open */2);
                                                    }),
                                                  children: null
                                                }, React.createElement(Fi.FiPlus, {}), "Adicionar produto");
                                    }),
                                  content: (function (dispatch) {
                                      return React.createElement(ProductUpsertForm$VannaFrontend.make, {
                                                  dispatchModal: dispatch,
                                                  onSuccess: (function (productId) {
                                                      return Curry._4(reform.setFieldValue, /* ProductId */0, productId, undefined, undefined);
                                                    })
                                                });
                                    })
                                }))), domain >= 2 ? React.createElement(ItemTableUpsertProductForm.Field.make, {
                            field: /* HasMaxQuantity */9,
                            render: (function (param) {
                                var handleChange = param.handleChange;
                                return React.createElement(BooleanSelectField.make, {
                                            label: "Definir quantidade máxima?",
                                            error: param.error,
                                            required: true,
                                            value: param.value,
                                            onChange: (function (param) {
                                                Curry._1(handleChange, param.value);
                                                return $$Promise.resolved(undefined);
                                              }),
                                            options: [
                                              {
                                                label: "Sim",
                                                value: true
                                              },
                                              {
                                                label: "Não",
                                                value: false
                                              }
                                            ]
                                          });
                              })
                          }) : null, domain >= 2 ? (
                        reform.state.values.hasMaxQuantity ? React.createElement(ItemTableUpsertProductForm.Field.make, {
                                field: /* MaxQuantity */8,
                                render: (function (param) {
                                    return React.createElement(NumberField$VannaFrontend.make, {
                                                label: "Quantidade máxima",
                                                error: param.error,
                                                required: true,
                                                value: param.value,
                                                onChange: param.handleChange
                                              });
                                  })
                              }) : null
                      ) : React.createElement(ItemTableUpsertProductForm.Field.make, {
                            field: /* Quantity */3,
                            render: (function (param) {
                                return React.createElement(NumberField$VannaFrontend.make, {
                                            label: "Quantidade",
                                            error: param.error,
                                            required: true,
                                            value: param.value,
                                            onChange: param.handleChange
                                          });
                              })
                          }), React.createElement(TextField$VannaFrontend.make, {
                          label: "Preço unitário",
                          required: true,
                          value: match$1 !== undefined ? Money$VannaFrontend.formatInt(match$1.price) : Money$VannaFrontend.formatInt(0),
                          onChange: (function (param) {
                              
                            }),
                          disabled: true
                        }), domain >= 2 ? React.createElement(ItemTableUpsertProductForm.Field.make, {
                            field: /* ChargeType */10,
                            render: (function (param) {
                                var handleChange = param.handleChange;
                                return React.createElement(ChargeTypeSelectField.make, {
                                            label: "Tipo de cobrança",
                                            error: param.error,
                                            required: false,
                                            value: param.value,
                                            onChange: (function (param) {
                                                Curry._1(handleChange, param.value);
                                                return $$Promise.resolved(undefined);
                                              }),
                                            options: [
                                              {
                                                label: "Cobrança simples",
                                                value: /* Single */0
                                              },
                                              {
                                                label: "Cobrança recorrente",
                                                value: /* Recurrent */1
                                              }
                                            ]
                                          });
                              })
                          }) : null, domain >= 2 ? null : React.createElement(React.Fragment, undefined, React.createElement(FieldGrid$VannaFrontend.make, {
                                children: null
                              }, React.createElement(ItemTableUpsertProductForm.Field.make, {
                                    field: /* FreightAmount */5,
                                    render: (function (param) {
                                        return React.createElement(MoneyField$VannaFrontend.make, {
                                                    label: "Valor do frete",
                                                    error: param.error,
                                                    required: false,
                                                    value: param.value,
                                                    onChange: param.handleChange,
                                                    wrapperClassName: thirtyPercentWidth
                                                  });
                                      })
                                  }), React.createElement(ItemTableUpsertProductForm.Field.make, {
                                    field: /* InsuranceAmount */6,
                                    render: (function (param) {
                                        return React.createElement(MoneyField$VannaFrontend.make, {
                                                    label: "Valor do seguro",
                                                    error: param.error,
                                                    required: false,
                                                    value: param.value,
                                                    onChange: param.handleChange,
                                                    wrapperClassName: thirtyPercentWidth
                                                  });
                                      })
                                  }), React.createElement(ItemTableUpsertProductForm.Field.make, {
                                    field: /* OthersAmount */7,
                                    render: (function (param) {
                                        return React.createElement(MoneyField$VannaFrontend.make, {
                                                    label: "Valor de outros custos",
                                                    error: param.error,
                                                    required: false,
                                                    value: param.value,
                                                    onChange: param.handleChange,
                                                    wrapperClassName: thirtyPercentWidth
                                                  });
                                      })
                                  })), React.createElement(ItemTableUpsertProductForm.Field.make, {
                                field: /* DiscountAmount */4,
                                render: (function (param) {
                                    return React.createElement(MoneyField$VannaFrontend.make, {
                                                label: "Valor do desconto",
                                                error: param.error,
                                                required: false,
                                                value: param.value,
                                                onChange: param.handleChange
                                              });
                                  })
                              }), React.createElement(FieldInfo$VannaFrontend.List.make, {
                                children: React.createElement(FieldInfo$VannaFrontend.Item.make, {
                                      children: null
                                    }, React.createElement("span", undefined, "Total: "), Money$VannaFrontend.formatInt(itemTotalAmount))
                              })), React.createElement(Button$VannaFrontend.make, {
                          isLoading: reform.isSubmitting,
                          onClick: (function (_evt) {
                              return Curry._1(reform.submit, undefined);
                            }),
                          children: Belt_Option.isSome(item) ? "Atualizar" : "Adicionar"
                        }), React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, /* CloseWithConfirmation */0);
                            }),
                          children: "Cancelar"
                        })), undefined));
}

var pageSize = 20;

var make = ItemTableUpsertProductForm$1;

exports.Styles = Styles;
exports.StateLenses = StateLenses;
exports.ItemTableUpsertProductForm = ItemTableUpsertProductForm;
exports.pageSize = pageSize;
exports.ProductsConnectionFragment = ProductsConnectionFragment;
exports.ProductsSelect = ProductsSelect;
exports.BooleanSelectField = BooleanSelectField;
exports.ChargeTypeSelectField = ChargeTypeSelectField;
exports.make = make;
/* thirtyPercentWidth Not a pure module */
