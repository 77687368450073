'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$VannaFrontend = require("../Theme.bs.js");

var paragraph = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.9
          }),
      tl: {
        hd: Css.lineHeight({
              NAME: "px",
              VAL: 20
            }),
        tl: {
          hd: Css.color(Theme$VannaFrontend.Colors.text),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  paragraph: paragraph
};

function $$Text(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement("p", {
              className: Curry._1(Css.merge, {
                    hd: paragraph,
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  })
            }, children);
}

var make = $$Text;

exports.Styles = Styles;
exports.make = make;
/* paragraph Not a pure module */
