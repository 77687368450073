'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactModal = require("react-modal");
var Fi = require("react-icons/fi");
var Css_Legacy_Core = require("bs-css/src/Css_Legacy_Core.bs.js");
var Text$VannaFrontend = require("./Text.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Title$VannaFrontend = require("./Title.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var IconButton$VannaFrontend = require("./IconButton.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

var backdrop = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top({
              NAME: "px",
              VAL: 0
            }),
        tl: {
          hd: Css.left({
                NAME: "px",
                VAL: 0
              }),
          tl: {
            hd: Css.right({
                  NAME: "px",
                  VAL: 0
                }),
            tl: {
              hd: Css.bottom({
                    NAME: "px",
                    VAL: 0
                  }),
              tl: {
                hd: Css.backgroundColor({
                      NAME: "rgba",
                      VAL: [
                        0,
                        0,
                        0,
                        {
                          NAME: "num",
                          VAL: 0.5
                        }
                      ]
                    }),
                tl: {
                  hd: Css.overflowY("scroll"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.width({
                NAME: "px",
                VAL: 740
              }),
          tl: {
            hd: Css.boxSizing("borderBox"),
            tl: {
              hd: Css.borderRadius(Theme$VannaFrontend.Border.radius),
              tl: {
                hd: Css.margin2(Theme$VannaFrontend.Margin.content, "auto"),
                tl: {
                  hd: Css.padding(Theme$VannaFrontend.Padding.content),
                  tl: {
                    hd: Css.backgroundColor(Theme$VannaFrontend.Colors.background),
                    tl: {
                      hd: Css.outlineStyle("none"),
                      tl: {
                        hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                              hd: Css.width(Css_Legacy_Core.Calc.$neg({
                                        NAME: "percent",
                                        VAL: 100.0
                                      }, {
                                        NAME: "rem",
                                        VAL: 2.0
                                      })),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var modalHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.marginBottom(Css_Legacy_Core.Calc.$neg(Theme$VannaFrontend.Margin.content, {
                      NAME: "rem",
                      VAL: 1.0
                    })),
            tl: /* [] */0
          }
        }
      }
    });

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexEnd"),
        tl: {
          hd: Css.marginTop({
                NAME: "px",
                VAL: 30
              }),
          tl: /* [] */0
        }
      }
    });

var cancelButton = Curry._1(Css.style, {
      hd: Css.marginLeft({
            NAME: "px",
            VAL: 20
          }),
      tl: /* [] */0
    });

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  backdrop: backdrop,
  content: content,
  modalHeader: modalHeader,
  buttons: buttons,
  cancelButton: cancelButton
};

ReactModal.setAppElement("#root");

function Modal$PureModal(Props) {
  var isOpen = Props.isOpen;
  var title = Props.title;
  var dispatch = Props.dispatch;
  var confirmClose = Props.confirmClose;
  var content$1 = Props.content;
  return React.createElement(ReactModal, {
              isOpen: isOpen,
              onRequestClose: (function (param) {
                  return Curry._1(dispatch, confirmClose ? /* CloseWithConfirmation */0 : /* Close */1);
                }),
              overlayClassName: backdrop,
              className: content,
              shouldCloseOnOverlayClick: false,
              shouldCloseOnEsc: false,
              children: null
            }, React.createElement("div", {
                  className: modalHeader
                }, React.createElement(Title$VannaFrontend.make, {
                      children: title
                    }), React.createElement(IconButton$VannaFrontend.make, {
                      onClick: (function (param, param$1, param$2) {
                          return Curry._1(dispatch, confirmClose ? /* CloseWithConfirmation */0 : /* Close */1);
                        }),
                      children: React.createElement(Fi.FiX, {})
                    })), Curry._1(content$1, dispatch));
}

var PureModal = {
  make: Modal$PureModal
};

function Modal(Props) {
  var title = Props.title;
  var trigger = Props.trigger;
  var controlledOpen = Props.controlledOpen;
  var content = Props.content;
  var confirmCloseOpt = Props.confirmClose;
  var onCloseOpt = Props.onClose;
  var confirmClose = confirmCloseOpt !== undefined ? confirmCloseOpt : true;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  var match = React.useReducer((function (state, action) {
          switch (action) {
            case /* CloseWithConfirmation */0 :
                return {
                        isOpen: state.isOpen,
                        showCloseModal: true
                      };
            case /* Close */1 :
                return {
                        isOpen: false,
                        showCloseModal: false
                      };
            case /* Open */2 :
                return {
                        isOpen: true,
                        showCloseModal: false
                      };
            case /* CloseConfirmModal */3 :
                return {
                        isOpen: state.isOpen,
                        showCloseModal: false
                      };
            
          }
        }), {
        isOpen: controlledOpen !== undefined ? controlledOpen : false,
        showCloseModal: false
      });
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (controlledOpen !== undefined) {
            if (controlledOpen) {
              Curry._1(dispatch, /* Open */2);
            } else {
              Curry._1(dispatch, /* Close */1);
            }
          }
          
        }), [controlledOpen]);
  return React.createElement(React.Fragment, undefined, Curry._1(trigger, dispatch), React.createElement(Modal$PureModal, {
                  isOpen: state.isOpen,
                  title: title,
                  dispatch: dispatch,
                  confirmClose: confirmClose,
                  content: content
                }), React.createElement(Modal$PureModal, {
                  isOpen: state.showCloseModal,
                  title: "Cancelar",
                  dispatch: dispatch,
                  confirmClose: false,
                  content: (function (dispatch) {
                      return React.createElement(React.Fragment, undefined, React.createElement(Text$VannaFrontend.make, {
                                      children: "Você tem certeza que deseja cancelar a operação?"
                                    }), React.createElement("div", {
                                      className: buttons
                                    }, React.createElement(Button$VannaFrontend.make, {
                                          fullWidth: false,
                                          onClick: (function (param) {
                                              return Curry._1(dispatch, /* CloseConfirmModal */3);
                                            }),
                                          children: "Não"
                                        }), React.createElement(Button$VannaFrontend.make, {
                                          fullWidth: false,
                                          mode: /* Danger */2,
                                          onClick: (function (param) {
                                              Curry._1(dispatch, /* Close */1);
                                              return Curry._1(onClose, undefined);
                                            }),
                                          className: cancelButton,
                                          children: "Sim"
                                        })));
                    })
                }));
}

var make = Modal;

exports.Styles = Styles;
exports.PureModal = PureModal;
exports.make = make;
/* backdrop Not a pure module */
