'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("../components/Box.bs.js");
var Text$VannaFrontend = require("../components/Text.bs.js");
var Button$VannaFrontend = require("../components/Button.bs.js");
var TextField$VannaFrontend = require("../components/TextField.bs.js");
var BrandPanel$VannaFrontend = require("../components/BrandPanel.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend = require("../__generated__/ForgotPassword_UserForgotPasswordMutation_graphql.bs.js");

function get(state, field) {
  return state.email;
}

function set(state, field, value) {
  return {
          email: value
        };
}

var StateLenses = {
  get: get,
  set: set
};

var ForgotPasswordForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.node,
              variables: ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ForgotPasswordMutation_make_userForgotPasswordInput = ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Utils.make_userForgotPasswordInput;

var ForgotPasswordMutation_makeVariables = ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Utils.makeVariables;

var ForgotPasswordMutation_make_response_userForgotPassword_error = ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Utils.make_response_userForgotPassword_error;

var ForgotPasswordMutation_make_response_userForgotPassword = ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Utils.make_response_userForgotPassword;

var ForgotPasswordMutation_makeOptimisticResponse = ForgotPassword_UserForgotPasswordMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ForgotPasswordMutation = {
  make_userForgotPasswordInput: ForgotPasswordMutation_make_userForgotPasswordInput,
  makeVariables: ForgotPasswordMutation_makeVariables,
  make_response_userForgotPassword_error: ForgotPasswordMutation_make_response_userForgotPassword_error,
  make_response_userForgotPassword: ForgotPasswordMutation_make_response_userForgotPassword,
  makeOptimisticResponse: ForgotPasswordMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

var text = Curry._1(Css.style, {
      hd: Css.textAlign("center"),
      tl: /* [] */0
    });

var Styles = {
  text: text
};

function ForgotPassword(Props) {
  var $$navigator = Props.navigator;
  var match = use(undefined);
  var forgotPassword = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setOk = match$1[1];
  var reform = Curry._7(ForgotPasswordForm.use, {
        email: ""
      }, /* Schema */{
        _0: Curry._3(ForgotPasswordForm.ReSchema.Validation.email, "Email inválido", undefined, /* Email */0)
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var send = param.send;
          Curry.app(forgotPassword, [
                (function (param) {
                    Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    return Curry._1(send, {
                                TAG: /* SetFormState */10,
                                _0: /* Dirty */0
                              });
                  }),
                (function (response, _errors) {
                    var match = response.userForgotPassword;
                    if (match !== undefined) {
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        Curry._1(raiseSubmitFailed, match$1.message);
                      } else {
                        Curry._1(setOk, (function (param) {
                                return true;
                              }));
                      }
                    } else {
                      Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    return Curry._1(send, {
                                TAG: /* SetFormState */10,
                                _0: /* Dirty */0
                              });
                  }),
                undefined,
                undefined,
                undefined,
                undefined,
                {
                  input: {
                    email: param.state.values.email
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var tmp;
  if (match$1[0]) {
    var email = reform.state.values.email;
    tmp = React.createElement(Text$VannaFrontend.make, {
          className: text,
          children: "Um email foi enviado para o endereço " + email + ". Por favor, verifique sua caixa de entrada."
        });
  } else {
    var match$2 = reform.formState;
    var tmp$1;
    if (typeof match$2 === "number") {
      tmp$1 = null;
    } else {
      var error = match$2._0;
      tmp$1 = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
              type_: /* Error */2,
              title: "Ops!",
              children: error
            }) : null;
    }
    tmp = React.createElement("form", {
          onSubmit: (function ($$event) {
              $$event.preventDefault();
              return Curry._1(reform.submit, undefined);
            })
        }, React.createElement(ForgotPasswordForm.Field.make, {
              field: /* Email */0,
              render: (function (param) {
                  return React.createElement(TextField$VannaFrontend.make, {
                              label: "Email",
                              error: param.error,
                              type_: /* Email */0,
                              value: param.value,
                              onChange: param.handleChange
                            });
                })
            }), tmp$1, React.createElement(Button$VannaFrontend.make, {
              isLoading: reform.isSubmitting,
              type_: /* Submit */1,
              children: "Próximo"
            }), React.createElement(Button$VannaFrontend.make, {
              marginTop: true,
              mode: /* Secondary */1,
              onClick: (function (param) {
                  return Curry._1($$navigator.push, /* Login */0);
                }),
              children: "Voltar"
            }));
  }
  return React.createElement(BrandPanel$VannaFrontend.make, {
              title: "Recupere sua conta",
              children: React.createElement(ForgotPasswordForm.Provider.make, Curry._3(ForgotPasswordForm.Provider.makeProps, reform, tmp, undefined))
            });
}

var make = ForgotPassword;

exports.StateLenses = StateLenses;
exports.ForgotPasswordForm = ForgotPasswordForm;
exports.ForgotPasswordMutation = ForgotPasswordMutation;
exports.Styles = Styles;
exports.make = make;
/* ForgotPasswordForm Not a pure module */
