'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"signIn_token":{"n":""},"signIn_error":{"n":""},"signIn":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"signIn_token":{"n":""},"signIn_error":{"n":""},"signIn":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"SignInInput":{"twoFactor":{"r":"TwoFactor"}},"__root":{"input":{"r":"SignInInput"}},"TwoFactor":{"googleAuthCode":{"n":""},"smsAuthCode":{"n":""}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_twoFactor(googleAuthCode, smsAuthCode, param) {
  return {
          googleAuthCode: googleAuthCode,
          smsAuthCode: smsAuthCode
        };
}

function make_signInInput(email, password, twoFactor) {
  return {
          email: email,
          password: password,
          twoFactor: twoFactor
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_signIn_error(message) {
  return {
          message: message
        };
}

function make_response_signIn(token, error, param) {
  return {
          token: token,
          error: error
        };
}

function makeOptimisticResponse(signIn, param) {
  return {
          signIn: signIn
        };
}

var Utils = {
  make_twoFactor: make_twoFactor,
  make_signInInput: make_signInInput,
  makeVariables: makeVariables,
  make_response_signIn_error: make_response_signIn_error,
  make_response_signIn: make_response_signIn,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignInPayload",
    "kind": "LinkedField",
    "name": "signIn",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8f1e19df1a34cc1e7f4efb76da166eee",
    "id": null,
    "metadata": {},
    "name": "LoginMutation",
    "operationKind": "mutation",
    "text": "mutation LoginMutation(\n  $input: SignInInput!\n) {\n  signIn(input: $input) {\n    token\n    error {\n      message\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
