'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Nanoid = require("nanoid");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend = require("../__generated__/UserReferralUpsertForm_UserReferralCreateMutation_graphql.bs.js");

function get(state, field) {
  return state.code;
}

function set(state, field, value) {
  return {
          code: value
        };
}

var StateLenses = {
  get: get,
  set: set
};

var UserReferralUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var twentyPercentField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 20
          }),
      tl: /* [] */0
    });

var thirtyPercent = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 33.33
          }),
      tl: /* [] */0
    });

var fiftyPercentField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 50
          }),
      tl: /* [] */0
    });

var Styles = {
  twentyPercentField: twentyPercentField,
  thirtyPercent: thirtyPercent,
  fiftyPercentField: fiftyPercentField
};

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.node,
              variables: UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var UserReferralCreateMutation_make_sellerReferralCreateInput = UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Utils.make_sellerReferralCreateInput;

var UserReferralCreateMutation_makeVariables = UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var UserReferralCreateMutation_make_response_sellerReferralCreate_sellerReferral = UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Utils.make_response_sellerReferralCreate_sellerReferral;

var UserReferralCreateMutation_make_response_sellerReferralCreate_error = UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Utils.make_response_sellerReferralCreate_error;

var UserReferralCreateMutation_make_response_sellerReferralCreate = UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Utils.make_response_sellerReferralCreate;

var UserReferralCreateMutation_makeOptimisticResponse = UserReferralUpsertForm_UserReferralCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var UserReferralCreateMutation = {
  make_sellerReferralCreateInput: UserReferralCreateMutation_make_sellerReferralCreateInput,
  makeVariables: UserReferralCreateMutation_makeVariables,
  make_response_sellerReferralCreate_sellerReferral: UserReferralCreateMutation_make_response_sellerReferralCreate_sellerReferral,
  make_response_sellerReferralCreate_error: UserReferralCreateMutation_make_response_sellerReferralCreate_error,
  make_response_sellerReferralCreate: UserReferralCreateMutation_make_response_sellerReferralCreate,
  makeOptimisticResponse: UserReferralCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

function UserReferralUpsertForm$1(Props) {
  var dispatchModal = Props.dispatchModal;
  var onSuccess = Props.onSuccess;
  var match = use(undefined);
  var createUserReferral = match[0];
  var reform = Curry._7(UserReferralUpsertForm.use, {
        code: ""
      }, /* Schema */{
        _0: Curry._3(UserReferralUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Code */0)
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var send = param.send;
          Curry.app(createUserReferral, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, errors) {
                    if (errors !== undefined && errors.length !== 0) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match = response.sellerReferralCreate;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    }
                    var referral = match.sellerReferral;
                    if (referral !== undefined) {
                      Curry._1(dispatchModal, /* Close */1);
                      Curry._1(send, {
                            TAG: /* SetFormState */10,
                            _0: /* Dirty */0
                          });
                      return Curry._1(onSuccess, referral);
                    } else {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                  }),
                undefined,
                undefined,
                undefined,
                (function (store, response) {
                    var match = response.sellerReferralCreate;
                    if (match === undefined) {
                      return ;
                    }
                    var sellerReferral = match.sellerReferral;
                    if (sellerReferral === undefined) {
                      return ;
                    }
                    var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                    var referralProxy = store.get(sellerReferral.id);
                    if (meProxy == null) {
                      return ;
                    }
                    if (referralProxy == null) {
                      return ;
                    }
                    var referralsConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "UserReferrals_referrals", undefined);
                    if (referralsConnection == null) {
                      return ;
                    }
                    var edge = RelayRuntime.ConnectionHandler.createEdge(store, referralsConnection, referralProxy, "UserSellerReferral");
                    RelayRuntime.ConnectionHandler.insertEdgeAfter(referralsConnection, edge, undefined);
                    
                  }),
                {
                  input: {
                    code: param.state.values.code
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$1 = reform.formState;
  var tmp;
  if (typeof match$1 === "number") {
    tmp = null;
  } else {
    var error = match$1._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(UserReferralUpsertForm.Provider.make, Curry._3(UserReferralUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(FieldGrid$VannaFrontend.make, {
                          children: React.createElement(UserReferralUpsertForm.Field.make, {
                                field: /* Code */0,
                                render: (function (param) {
                                    var handleChange = param.handleChange;
                                    return React.createElement(React.Fragment, undefined, React.createElement(TextField$VannaFrontend.make, {
                                                    label: "Código",
                                                    error: param.error,
                                                    required: true,
                                                    value: param.value,
                                                    onChange: handleChange
                                                  }), React.createElement(Button$VannaFrontend.make, {
                                                    marginLeft: true,
                                                    fullWidth: false,
                                                    onClick: (function (param) {
                                                        return Curry._1(handleChange, Nanoid.nanoid());
                                                      }),
                                                    children: React.createElement("span", undefined, "Gerar código")
                                                  }));
                                  })
                              })
                        }), tmp, React.createElement(Button$VannaFrontend.make, {
                          isLoading: reform.isSubmitting,
                          type_: /* Submit */1,
                          children: "Adicionar"
                        }), React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, /* CloseWithConfirmation */0);
                            }),
                          children: "Cancelar"
                        })), undefined));
}

var make = UserReferralUpsertForm$1;

exports.StateLenses = StateLenses;
exports.UserReferralUpsertForm = UserReferralUpsertForm;
exports.Styles = Styles;
exports.UserReferralCreateMutation = UserReferralCreateMutation;
exports.make = make;
/* UserReferralUpsertForm Not a pure module */
