'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$VannaFrontend = require("../Theme.bs.js");

var wrapper = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 100
          }),
      tl: {
        hd: Css.marginBottom({
              NAME: "px",
              VAL: 25
            }),
        tl: {
          hd: Css.fontSize({
                NAME: "rem",
                VAL: 0.85
              }),
          tl: {
            hd: Css.color(Theme$VannaFrontend.Colors.textSecondary),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  wrapper: wrapper
};

function FieldInfo$List(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: wrapper
            }, children);
}

var List = {
  Styles: Styles,
  make: FieldInfo$List
};

var wrapper$1 = Curry._1(Css.style, {
      hd: Css.not__(":last-child", {
            hd: Css.marginBottom({
                  NAME: "px",
                  VAL: 6
                }),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.child("span")({
              hd: Css.color(Theme$VannaFrontend.Colors.text),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Styles$1 = {
  wrapper: wrapper$1
};

function FieldInfo$Item(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: wrapper$1
            }, children);
}

var Item = {
  Styles: Styles$1,
  make: FieldInfo$Item
};

exports.List = List;
exports.Item = Item;
/* wrapper Not a pure module */
