'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Uuid = require("uuid");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var QrcodeReact = require("qrcode.react");
var Ai = require("react-icons/ai");
var Fi = require("react-icons/fi");
var ReactToPrint = require("react-to-print");
var Format = require("date-fns/format");
var Hr$VannaFrontend = require("./Hr.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Text$VannaFrontend = require("./Text.bs.js");
var Badge$VannaFrontend = require("./Badge.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var Receipt$VannaFrontend = require("./Receipt.bs.js");
var ItemsTable$VannaFrontend = require("./ItemsTable.bs.js");
var ReceiptCharge_charge_graphql$VannaFrontend = require("../__generated__/ReceiptCharge_charge_graphql.bs.js");

var amountWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: /* [] */0
        }
      }
    });

var sectionTitleRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginBottom({
                  NAME: "px",
                  VAL: 10
                }),
            tl: {
              hd: Css.marginTop({
                    NAME: "px",
                    VAL: 10
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var sectionTitle = Curry._1(Css.style, {
      hd: Css.marginBottom({
            NAME: "px",
            VAL: 5
          }),
      tl: /* [] */0
    });

var text = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var centeredWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.padding({
                  NAME: "px",
                  VAL: 7
                }),
            tl: /* [] */0
          }
        }
      }
    });

var row = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  amountWrapper: amountWrapper,
  sectionTitleRow: sectionTitleRow,
  sectionTitle: sectionTitle,
  text: text,
  centeredWrapper: centeredWrapper,
  row: row
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(ReceiptCharge_charge_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ReceiptCharge_charge_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ReceiptCharge_charge_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ReceiptCharge_charge_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var ReceiptChargeFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function ReceiptCharge(Props) {
  var queryRef = Props.query;
  var queryData = use(queryRef);
  var componentRef = React.useRef(undefined);
  var handlePrint = ReactToPrint.useReactToPrint({
        content: (function (param) {
            return componentRef.current;
          }),
        pageStyle: "\n        @media print {\n          @page { size: portrait; }\n        }     \n      "
      });
  return React.createElement(Receipt$VannaFrontend.Wrapper.make, {
              content: (function (param) {
                  var match = queryData.status;
                  var match$1 = queryData.paymentMethod;
                  var match$2 = queryData.pixDynamicQrcode;
                  var tmp;
                  if (match$2 !== undefined) {
                    var emvqrcps = match$2.emvqrcps;
                    tmp = emvqrcps !== undefined ? React.createElement("div", {
                            className: centeredWrapper
                          }, React.createElement(QrcodeReact, {
                                value: emvqrcps,
                                size: 125
                              })) : null;
                  } else {
                    tmp = null;
                  }
                  return React.createElement("div", undefined, React.createElement(Text$VannaFrontend.make, {
                                  className: text,
                                  children: null
                                }, React.createElement(Ai.AiOutlineCalendar, {}), "Data: ", React.createElement("strong", undefined, Format(new Date(queryData.date), "dd/MM/yyyy kk:mm"))), React.createElement(Text$VannaFrontend.make, {
                                  className: text,
                                  children: null
                                }, React.createElement(Fi.FiSettings, {}), "Identificador da cobrança: ", React.createElement("strong", undefined, queryData.friendlyId)), React.createElement(Hr$VannaFrontend.make, {}), React.createElement("div", {
                                  className: row
                                }, React.createElement("div", undefined, React.createElement(Text$VannaFrontend.make, {
                                          children: Belt_Option.getWithDefault(queryData.customer.name, "")
                                        }), React.createElement(Text$VannaFrontend.make, {
                                          children: Belt_Option.getWithDefault(queryData.customer.taxId, "")
                                        })), React.createElement("div", undefined, React.createElement(Text$VannaFrontend.make, {
                                          className: text,
                                          children: null
                                        }, "Situação: ", match === "CANCELED" ? React.createElement(Badge$VannaFrontend.make, {
                                                mode: /* Info */3,
                                                text: "Cancelada"
                                              }) : (
                                            match === "REJECTED_BY_CUSTOMER" ? React.createElement(Badge$VannaFrontend.make, {
                                                    mode: /* Warn */4,
                                                    text: "Rejeitada pelo cliente"
                                                  }) : (
                                                match === "AWAITING_PAYMENT" ? React.createElement(Badge$VannaFrontend.make, {
                                                        mode: /* Default */0,
                                                        text: "Aguardando pagamento"
                                                      }) : (
                                                    match === "REFUNDED" ? React.createElement(Badge$VannaFrontend.make, {
                                                            mode: /* Info */3,
                                                            text: "Estornado"
                                                          }) : (
                                                        match === "PAID" ? React.createElement(Badge$VannaFrontend.make, {
                                                                mode: /* Success */1,
                                                                text: "Pago"
                                                              }) : "-"
                                                      )
                                                  )
                                              )
                                          )), React.createElement(Text$VannaFrontend.make, {
                                          className: text,
                                          children: null
                                        }, "Método de pagamento: ", match$1 === "PIX" ? "PIX" : (
                                            match$1 === "BOLETO" ? "Boleto" : (
                                                match$1 === "CREDIT_CARD" ? "Cartão de crédito" : "-"
                                              )
                                          )))), React.createElement(ItemsTable$VannaFrontend.make, {
                                  title: "",
                                  data: Belt_Array.map(queryData.items, (function (item) {
                                          return {
                                                  name: item.name,
                                                  price: item.price,
                                                  quantity: item.quantity,
                                                  freightAmount: item.freightAmount,
                                                  insuranceAmount: item.insuranceAmount,
                                                  othersAmount: item.othersAmount,
                                                  discountAmount: item.discountAmount,
                                                  itemId: item.id,
                                                  temporaryId: Uuid.v4(),
                                                  maxQuantity: 0,
                                                  hasMaxQuantity: false,
                                                  chargeType: /* Single */0
                                                };
                                        })),
                                  mode: /* Small */1,
                                  readOnly: true,
                                  domain: /* Charge */0
                                }), React.createElement("div", {
                                  className: amountWrapper
                                }, React.createElement(Text$VannaFrontend.make, {
                                      children: null
                                    }, "Valor total: ", React.createElement("strong", undefined, Money$VannaFrontend.formatInt(queryData.amount)))), tmp, React.createElement("div", {
                                  className: centeredWrapper
                                }, React.createElement(Button$VannaFrontend.make, {
                                      fullWidth: false,
                                      onClick: (function (param) {
                                          return Curry._1(handlePrint, undefined);
                                        }),
                                      children: "Imprimir"
                                    })));
                }),
              forwardRef: (function (ref) {
                  componentRef.current = ref;
                  
                })
            });
}

var make = ReceiptCharge;

exports.Styles = Styles;
exports.ReceiptChargeFragment = ReceiptChargeFragment;
exports.make = make;
/* amountWrapper Not a pure module */
