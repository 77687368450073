'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Color = require("color");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Css_AtomicTypes = require("bs-css/src/Css_AtomicTypes.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var CollectionHelpers$VannaFrontend = require("../types/CollectionHelpers.bs.js");
var ReceivablesCalendarFragment_user_graphql$VannaFrontend = require("../__generated__/ReceivablesCalendarFragment_user_graphql.bs.js");

var heatmap = Curry._1(Css.style, {
      hd: Css.overflowX("auto"),
      tl: /* [] */0
    });

var heatmapBlocks = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: /* [] */0
    });

var heatmapNumber = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top({
              NAME: "px",
              VAL: 0
            }),
        tl: {
          hd: Css.left({
                NAME: "px",
                VAL: 0
              }),
          tl: {
            hd: Css.paddingLeft({
                  NAME: "px",
                  VAL: 5
                }),
            tl: {
              hd: Css.paddingTop({
                    NAME: "px",
                    VAL: 5
                  }),
              tl: {
                hd: Css.color(Theme$VannaFrontend.Colors.textSecondary),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var heatmapValue = Curry._1(Css.style, {
      hd: Css.alignSelf("center"),
      tl: {
        hd: Css.fontSize({
              NAME: "px",
              VAL: 16
            }),
        tl: {
          hd: Css.fontWeight("medium"),
          tl: /* [] */0
        }
      }
    });

function heatmapBlock(amount, max, min, status, disabled) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexBasis({
                        NAME: "px",
                        VAL: 0
                      }),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.flexGrow(1),
                      tl: {
                        hd: Css.height({
                              NAME: "px",
                              VAL: 70
                            }),
                        tl: {
                          hd: Css.marginBottom({
                                NAME: "px",
                                VAL: 2
                              }),
                          tl: {
                            hd: Css.fontSize({
                                  NAME: "rem",
                                  VAL: 0.8
                                }),
                            tl: {
                              hd: Css.transition(1, undefined, undefined, "all"),
                              tl: {
                                hd: Css.minWidth({
                                      NAME: "px",
                                      VAL: 110
                                    }),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: {
                                    hd: Css.whiteSpace("nowrap"),
                                    tl: {
                                      hd: Css.not__(":last-child", {
                                            hd: Css.marginRight({
                                                  NAME: "px",
                                                  VAL: 2
                                                }),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.unsafe("backgroundColor", status >= 2 ? new Color(Css_AtomicTypes.Color.toString(Theme$VannaFrontend.Colors.success)).string() : (
                                                amount === 0 ? new Color(Css_AtomicTypes.Color.toString(Theme$VannaFrontend.Colors.primary)).fade(0.8).string() : new Color(Css_AtomicTypes.Color.toString(Theme$VannaFrontend.Colors.primary)).fade(0.8 - 0.8 / (max - min) * (Money$VannaFrontend.toDecimal(Money$VannaFrontend.make({
                                                                    TAG: /* Cents */1,
                                                                    _0: amount
                                                                  })) - min)).string()
                                              )),
                                        tl: {
                                          hd: Css.hover(status >= 2 ? ({
                                                    hd: Css.backgroundColor(Theme$VannaFrontend.Colors.success),
                                                    tl: /* [] */0
                                                  }) : (
                                                  disabled ? /* [] */0 : ({
                                                        hd: Css.backgroundColor(Theme$VannaFrontend.Colors.primary),
                                                        tl: /* [] */0
                                                      })
                                                )),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var heatmapLabels = Curry._1(Css.style, {
      hd: Css.margin2({
            NAME: "px",
            VAL: 15
          }, {
            NAME: "px",
            VAL: 0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: /* [] */0
      }
    });

var heatmapLabel = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("center"),
        tl: {
          hd: Css.flexBasis({
                NAME: "px",
                VAL: 0
              }),
          tl: {
            hd: Css.flexGrow(1),
            tl: {
              hd: Css.fontSize({
                    NAME: "rem",
                    VAL: 0.75
                  }),
              tl: {
                hd: Css.minWidth({
                      NAME: "px",
                      VAL: 110
                    }),
                tl: {
                  hd: Css.color(Theme$VannaFrontend.Colors.text),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var hiddenItem = Curry._1(Css.style, {
      hd: Css.visibility("hidden"),
      tl: /* [] */0
    });

var Styles = {
  heatmap: heatmap,
  heatmapBlocks: heatmapBlocks,
  heatmapNumber: heatmapNumber,
  heatmapValue: heatmapValue,
  heatmapBlock: heatmapBlock,
  heatmapLabels: heatmapLabels,
  heatmapLabel: heatmapLabel,
  hiddenItem: hiddenItem
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(ReceivablesCalendarFragment_user_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ReceivablesCalendarFragment_user_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ReceivablesCalendarFragment_user_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ReceivablesCalendarFragment_user_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var ReceivableCalendarFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function ReceivablesCalendar(Props) {
  var year = Props.year;
  var month = Props.month;
  var fragment = Props.fragment;
  var queryData = use(fragment);
  var data = React.useMemo((function () {
          return Belt_Array.map(Belt_Array.keep(queryData.allCharges, (function (charge) {
                            return Belt_Option.mapWithDefault(charge.receivable, false, (function (receivable) {
                                          var expectedOn = receivable.expectedOn;
                                          if (expectedOn !== undefined) {
                                            return new Date(expectedOn).getMonth() === month;
                                          } else {
                                            return false;
                                          }
                                        }));
                          })), (function (charge) {
                        return {
                                day: Belt_Option.mapWithDefault(charge.receivable, -1, (function (receivable) {
                                        var expectedOn = receivable.expectedOn;
                                        if (expectedOn !== undefined) {
                                          return new Date(expectedOn).getDate() | 0;
                                        } else {
                                          return -1;
                                        }
                                      })),
                                status: Belt_Option.mapWithDefault(charge.receivable, /* Unknown */0, (function (receivable) {
                                        var match = receivable.status;
                                        if (match !== undefined) {
                                          if (match === "PENDING") {
                                            return /* Ongoing */1;
                                          } else if (match === "PAID") {
                                            return /* Success */2;
                                          } else {
                                            return /* Unknown */0;
                                          }
                                        } else {
                                          return /* Unknown */0;
                                        }
                                      })),
                                amount: charge.amount
                              };
                      }));
        }), [queryData]);
  var firstDayOfMonth = new Date(year, month, 1.0).getDay() | 0;
  var lastDateOfMonth = new Date(year, month + 1, 0.0).getDate() | 0;
  var lastDateOfPreviousMonth = new Date(year, month, 0.0).getDate() | 0;
  var groupedData = React.useMemo((function () {
          return Belt_Array.keep(Js_dict.entries(Js_dict.map((function (items) {
                                var item = items.find(function (param, idx) {
                                      return idx === 0;
                                    });
                                if (item !== undefined) {
                                  return {
                                          day: item.day,
                                          status: item.status,
                                          amount: items.reduce((function (acc, cur) {
                                                  return acc + cur.amount | 0;
                                                }), 0)
                                        };
                                } else {
                                  return {
                                          day: -1,
                                          status: /* Unknown */0,
                                          amount: -1
                                        };
                                }
                              }), CollectionHelpers$VannaFrontend.groupBy(data, (function (x) {
                                    return String(x.day);
                                  })))), (function (param) {
                        return param[1].status !== /* Unknown */0;
                      }));
        }), [
        data,
        year,
        month
      ]);
  var match = React.useMemo((function () {
          var aux = firstDayOfMonth + lastDateOfMonth | 0;
          var data = Belt_Array.reduce(groupedData, Belt_Array.makeBy(lastDateOfMonth, (function (param) {
                      return 0;
                    })), (function (acc, param) {
                  var cur = param[1];
                  var head = Belt_Array.slice(acc, 0, cur.day);
                  var middle = [cur.amount];
                  var tail = Belt_Array.sliceToEnd(acc, cur.day + 1 | 0);
                  return Belt_Array.concatMany([
                              head,
                              middle,
                              tail
                            ]);
                }));
          var rowSize = (aux + 7 | 0) - (aux % 7 | 0) | 0;
          var match = Belt_Array.reduce(Belt_Array.makeBy(rowSize, (function (idx) {
                      return idx + 1 | 0;
                    })), [
                [],
                []
              ], (function (param, i) {
                  var days = param[1];
                  var amounts = param[0];
                  var amounts$1;
                  if (i > firstDayOfMonth && i <= aux) {
                    var amount = Belt_Array.get(data, i - firstDayOfMonth | 0);
                    amounts$1 = Belt_Array.concat(amounts, [amount !== undefined ? amount : 0]);
                  } else {
                    amounts$1 = Belt_Array.concat(amounts, [-1]);
                  }
                  var days$1 = i <= firstDayOfMonth ? Belt_Array.concat(days, [(lastDateOfPreviousMonth - firstDayOfMonth | 0) + i | 0]) : (
                      i <= aux ? Belt_Array.concat(days, [i - firstDayOfMonth | 0]) : Belt_Array.concat(days, [i - aux | 0])
                    );
                  return [
                          amounts$1,
                          days$1
                        ];
                }));
          var days = match[1];
          var amounts = match[0];
          var chunkedSeries = Belt_Array.reduce(Belt_Array.makeBy(amounts.length / 7 | 0, (function (idx) {
                      return idx;
                    })), [], (function (acc, chunkedSeriesLength) {
                  return Belt_Array.concat(acc, [{
                                amounts: amounts.slice(Math.imul(chunkedSeriesLength, 7), Math.imul(chunkedSeriesLength + 1 | 0, 7)),
                                days: days.slice(Math.imul(chunkedSeriesLength, 7), Math.imul(chunkedSeriesLength + 1 | 0, 7))
                              }]);
                }));
          var max = Belt_Array.reduce(amounts, 0, (function (a, b) {
                  return Math.max(a, b);
                }));
          var min = Belt_Array.reduce(amounts, 0, (function (a, b) {
                  return Math.min(a, b);
                }));
          return [
                  chunkedSeries,
                  max,
                  min
                ];
        }), [groupedData]);
  var min = match[2];
  var max = match[1];
  var getStatus = React.useCallback((function (day) {
          var match = Belt_Array.getBy(groupedData, (function (param) {
                  return param[1].day === day;
                }));
          if (match !== undefined) {
            return match[1].status;
          } else {
            return /* Unknown */0;
          }
        }), [groupedData]);
  return React.createElement("div", {
              className: heatmap
            }, Belt_Array.mapWithIndex(match[0], (function (indexI, serie) {
                    return React.createElement("div", {
                                key: "__HEATMAP__BLOCKS__" + String(indexI),
                                className: heatmapBlocks
                              }, Belt_Array.mapWithIndex(serie.amounts, (function (indexJ, amount) {
                                      if (amount < 0) {
                                        return React.createElement("div", {
                                                    key: "__HEATMAP__BLOCK" + String(indexJ),
                                                    className: heatmapBlock(amount, 0, 0, /* Unknown */0, true)
                                                  });
                                      } else {
                                        return React.createElement("div", {
                                                    key: "__HEATMAP__BLOCK" + String(indexJ),
                                                    className: heatmapBlock(amount, max, min, Curry._1(getStatus, Caml_array.get(serie.days, indexJ)), false)
                                                  }, React.createElement("span", {
                                                        className: heatmapNumber
                                                      }, String(Caml_array.get(serie.days, indexJ))), amount > 0 ? React.createElement("span", {
                                                          className: heatmapValue
                                                        }, Money$VannaFrontend.formatInt(amount)) : null);
                                      }
                                    })));
                  })), React.createElement("div", {
                  className: heatmapLabels
                }, Belt_Array.map([
                      "Domingo",
                      "Segunda-feira",
                      "Terça-feira",
                      "Quarta-feira",
                      "Quinta-feira",
                      "Sexta-feira",
                      "Sábado"
                    ], (function (day) {
                        return React.createElement("div", {
                                    className: heatmapLabel
                                  }, day);
                      }))));
}

var make = ReceivablesCalendar;

exports.Styles = Styles;
exports.ReceivableCalendarFragment = ReceivableCalendarFragment;
exports.make = make;
/* heatmap Not a pure module */
