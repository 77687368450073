'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function forEachWithIndex(map, fn) {
  var i = {
    contents: 0
  };
  map.forEach(function (value, key) {
        Curry._3(fn, value, key, i.contents);
        i.contents = i.contents + 1 | 0;
        
      });
  
}

exports.forEachWithIndex = forEachWithIndex;
/* No side effect */
