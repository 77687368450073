'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"chargeCreate_charge_customer_tradeName":{"n":""},"chargeCreate_charge_rejectedAt":{"n":""},"chargeCreate_charge_paidAt":{"n":""},"chargeCreate_charge_boleto_url":{"n":""},"chargeCreate_charge_canceledAt":{"n":""},"chargeCreate_charge_description":{"n":""},"chargeCreate":{"n":""},"chargeCreate_charge_boleto":{"n":""},"chargeCreate_charge_refundedAt":{"n":""},"chargeCreate_charge_boleto_id":{"n":""},"chargeCreate_charge":{"n":""},"chargeCreate_error":{"n":""},"chargeCreate_charge_boleto_barcode":{"n":""},"chargeCreate_charge_expectedOn":{"n":""},"chargeCreate_charge_customer_name":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"chargeCreate_charge_customer_tradeName":{"n":""},"chargeCreate_charge_rejectedAt":{"n":""},"chargeCreate_charge_paidAt":{"n":""},"chargeCreate_charge_boleto_url":{"n":""},"chargeCreate_charge_canceledAt":{"n":""},"chargeCreate_charge_description":{"n":""},"chargeCreate":{"n":""},"chargeCreate_charge_boleto":{"n":""},"chargeCreate_charge_refundedAt":{"n":""},"chargeCreate_charge_boleto_id":{"n":""},"chargeCreate_charge":{"n":""},"chargeCreate_error":{"n":""},"chargeCreate_charge_boleto_barcode":{"n":""},"chargeCreate_charge_expectedOn":{"n":""},"chargeCreate_charge_customer_name":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"ChargeCreateInput"}},"ChargeCreateInput":{"installments":{"n":""},"items":{"r":"ChargeItem"},"fineDate":{"n":""},"discountInstruction":{"n":""},"hasFine":{"n":""},"interestInstruction":{"n":""},"interestDate":{"n":""},"interestAmount":{"n":""},"dueDate":{"n":""},"affiliateId":{"n":""},"hasInterest":{"n":""},"hasDiscount":{"n":""},"fineInstruction":{"n":""},"description":{"n":""},"affiliatePercentage":{"n":""},"askForConfirmation":{"n":""},"discountAmount":{"n":""},"fineAmount":{"n":""},"discountDate":{"n":""}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_chargeItem(discountAmount, freightAmount, insuranceAmount, itemId, othersAmount, quantity) {
  return {
          discountAmount: discountAmount,
          freightAmount: freightAmount,
          insuranceAmount: insuranceAmount,
          itemId: itemId,
          othersAmount: othersAmount,
          quantity: quantity
        };
}

function make_chargeCreateInput(affiliateId, affiliatePercentage, askForConfirmation, customerId, date, description, discountAmount, discountDate, discountInstruction, dueDate, fineAmount, fineDate, fineInstruction, hasDiscount, hasFine, hasInstallments, hasInterest, installments, interestAmount, interestDate, interestInstruction, items, paymentMethod, param) {
  return {
          affiliateId: affiliateId,
          affiliatePercentage: affiliatePercentage,
          askForConfirmation: askForConfirmation,
          customerId: customerId,
          date: date,
          description: description,
          discountAmount: discountAmount,
          discountDate: discountDate,
          discountInstruction: discountInstruction,
          dueDate: dueDate,
          fineAmount: fineAmount,
          fineDate: fineDate,
          fineInstruction: fineInstruction,
          hasDiscount: hasDiscount,
          hasFine: hasFine,
          hasInstallments: hasInstallments,
          hasInterest: hasInterest,
          installments: installments,
          interestAmount: interestAmount,
          interestDate: interestDate,
          interestInstruction: interestInstruction,
          items: items,
          paymentMethod: paymentMethod
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_chargeCreate_charge_items(id, name, price, quantity, type_, discountAmount, freightAmount, insuranceAmount, othersAmount) {
  return {
          id: id,
          name: name,
          price: price,
          quantity: quantity,
          type_: type_,
          discountAmount: discountAmount,
          freightAmount: freightAmount,
          insuranceAmount: insuranceAmount,
          othersAmount: othersAmount
        };
}

function make_response_chargeCreate_charge_customer(id, name, tradeName, param) {
  return {
          id: id,
          name: name,
          tradeName: tradeName
        };
}

function make_response_chargeCreate_charge_boleto(id, barcode, url, amount, param) {
  return {
          id: id,
          barcode: barcode,
          url: url,
          amount: amount
        };
}

function make_response_chargeCreate_charge(id, amount, date, description, friendlyId, paymentMethod, status, transactionCost, paidAt, refundedAt, rejectedAt, canceledAt, expectedOn, boleto, customer, items, param) {
  return {
          id: id,
          amount: amount,
          date: date,
          description: description,
          friendlyId: friendlyId,
          paymentMethod: paymentMethod,
          status: status,
          transactionCost: transactionCost,
          paidAt: paidAt,
          refundedAt: refundedAt,
          rejectedAt: rejectedAt,
          canceledAt: canceledAt,
          expectedOn: expectedOn,
          boleto: boleto,
          customer: customer,
          items: items
        };
}

function make_response_chargeCreate_error(message) {
  return {
          message: message
        };
}

function make_response_chargeCreate(error, charge, param) {
  return {
          error: error,
          charge: charge
        };
}

function makeOptimisticResponse(chargeCreate, param) {
  return {
          chargeCreate: chargeCreate
        };
}

var Utils = {
  make_chargeItem: make_chargeItem,
  make_chargeCreateInput: make_chargeCreateInput,
  makeVariables: makeVariables,
  make_response_chargeCreate_charge_items: make_response_chargeCreate_charge_items,
  make_response_chargeCreate_charge_customer: make_response_chargeCreate_charge_customer,
  make_response_chargeCreate_charge_boleto: make_response_chargeCreate_charge_boleto,
  make_response_chargeCreate_charge: make_response_chargeCreate_charge,
  make_response_chargeCreate_error: make_response_chargeCreate_error,
  make_response_chargeCreate: make_response_chargeCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChargeCreatePayload",
    "kind": "LinkedField",
    "name": "chargeCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Charge",
        "kind": "LinkedField",
        "name": "charge",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "friendlyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentMethod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transactionCost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refundedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rejectedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canceledAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expectedOn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoletoType",
            "kind": "LinkedField",
            "name": "boleto",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "barcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tradeName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChargeItemType",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": "type_",
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "discountAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freightAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insuranceAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "othersAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChargeUpsertForm_ChargeCreateMutation",
    "selections": (v4/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChargeUpsertForm_ChargeCreateMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "4ecd3b0382ed363b3be5d6e9d2224a4e",
    "id": null,
    "metadata": {},
    "name": "ChargeUpsertForm_ChargeCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ChargeUpsertForm_ChargeCreateMutation(\n  $input: ChargeCreateInput!\n) {\n  chargeCreate(input: $input) {\n    error {\n      message\n    }\n    charge {\n      id\n      amount\n      date\n      description\n      friendlyId\n      paymentMethod\n      status\n      transactionCost\n      paidAt\n      refundedAt\n      rejectedAt\n      canceledAt\n      expectedOn\n      boleto {\n        id\n        barcode\n        url\n        amount\n      }\n      customer {\n        id\n        name\n        tradeName\n      }\n      items {\n        id\n        name\n        price\n        quantity\n        type_: type\n        discountAmount\n        freightAmount\n        insuranceAmount\n        othersAmount\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
