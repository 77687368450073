'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fi = require("react-icons/fi");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Text$VannaFrontend = require("./Text.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var RequireKYCFragment_graphql$VannaFrontend = require("../__generated__/RequireKYCFragment_graphql.bs.js");

var wrapper = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 100
          }),
      tl: {
        hd: Css.height({
              NAME: "vh",
              VAL: 100
            }),
        tl: {
          hd: Css.boxSizing("borderBox"),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var clockIconWrapper = Curry._1(Css.style, {
      hd: Css.marginBottom({
            NAME: "rem",
            VAL: 0.5
          }),
      tl: {
        hd: Css.textAlign("center"),
        tl: {
          hd: Css.fontSize({
                NAME: "rem",
                VAL: 2.5
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  wrapper: wrapper,
  clockIconWrapper: clockIconWrapper
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(RequireKYCFragment_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(RequireKYCFragment_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(RequireKYCFragment_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return RequireKYCFragment_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var RequireKYCFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function RequireKYC(Props) {
  var $$navigator = Props.navigator;
  var fragment = Props.fragment;
  var children = Props.children;
  var queryData = use(fragment);
  var match = queryData.settings.kycStatus;
  return React.createElement(React.Fragment, undefined, match === "REJECTED" ? React.createElement("div", {
                    className: wrapper
                  }, React.createElement("div", {
                        className: clockIconWrapper
                      }, React.createElement(Fi.FiX, {})), React.createElement(Text$VannaFrontend.make, {
                        className: Curry._1(Css.style, {
                              hd: Css.textAlign("center"),
                              tl: /* [] */0
                            }),
                        children: "Analisamos seus dados e infelizmente não poderemos liberar o acesso a plataforma nesse momento. Você receberá mais detalhes por email."
                      })) : (
                match === "ONGOING" ? React.createElement("div", {
                        className: wrapper
                      }, React.createElement("div", {
                            className: clockIconWrapper
                          }, React.createElement(Fi.FiClock, {})), React.createElement(Text$VannaFrontend.make, {
                            className: Curry._1(Css.style, {
                                  hd: Css.textAlign("center"),
                                  tl: /* [] */0
                                }),
                            children: "Seus dados foram recebidos e serão analisados pela nossa equipe dentro do prazo de dois dias úteis. Você será notificado por email ao fim de nossa análise."
                          })) : (
                    match === "UNSTARTED" ? React.createElement("div", {
                            className: wrapper
                          }, React.createElement(Button$VannaFrontend.make, {
                                onClick: (function (param) {
                                    return Curry._1($$navigator.push, {
                                                TAG: /* Settings */8,
                                                _0: "dados-cadastrais"
                                              });
                                  }),
                                children: "Antes de realizar vendas precisamos de algumas informações. Clique aqui para adicionar as informações básicas das sua empresa."
                              })) : (
                        match === "DONE" ? Curry._1(children, undefined) : null
                      )
                  )
              ));
}

var make = RequireKYC;

exports.Styles = Styles;
exports.RequireKYCFragment = RequireKYCFragment;
exports.make = make;
/* wrapper Not a pure module */
