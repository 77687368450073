'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$VannaFrontend = require("../Theme.bs.js");

var wrapper = Curry._1(Css.style, {
      hd: Css.padding3({
            NAME: "px",
            VAL: 5
          }, {
            NAME: "px",
            VAL: 0
          }, {
            NAME: "px",
            VAL: 0
          }),
      tl: {
        hd: Css.margin({
              NAME: "px",
              VAL: 0
            }),
        tl: {
          hd: Css.display("block"),
          tl: {
            hd: Css.fontSize({
                  NAME: "rem",
                  VAL: 0.7
                }),
            tl: {
              hd: Css.color(Theme$VannaFrontend.Colors.error),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Styles = {
  wrapper: wrapper
};

function FieldError(Props) {
  var message = Props.message;
  return React.createElement("div", {
              className: wrapper
            }, message);
}

var make = FieldError;

exports.Styles = Styles;
exports.make = make;
/* wrapper Not a pure module */
