'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$VannaFrontend = require("../Theme.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

function panel(useDefaultMaxWidth, noMarginBottom) {
  return Curry._1(Css.style, {
              hd: Css.width(useDefaultMaxWidth ? ({
                        NAME: "px",
                        VAL: 700
                      }) : ({
                        NAME: "percent",
                        VAL: 100.0
                      })),
              tl: {
                hd: Css.paddingTop(Theme$VannaFrontend.Padding.content),
                tl: {
                  hd: Css.paddingBottom(Theme$VannaFrontend.Padding.content),
                  tl: {
                    hd: Css.backgroundColor(Theme$VannaFrontend.Colors.backgroundLighter),
                    tl: {
                      hd: Css.borderRadius({
                            NAME: "px",
                            VAL: 6
                          }),
                      tl: {
                        hd: Css.marginBottom(noMarginBottom ? ({
                                  NAME: "px",
                                  VAL: 0
                                }) : Theme$VannaFrontend.Margin.content),
                        tl: {
                          hd: Css.unsafe("boxShadow", Theme$VannaFrontend.BoxShadow.$$default),
                          tl: {
                            hd: Css.boxSizing("borderBox"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var title = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.9
          }),
      tl: {
        hd: Css.lineHeight({
              NAME: "rem",
              VAL: 1.3
            }),
        tl: {
          hd: Css.fontWeight("medium"),
          tl: {
            hd: Css.color(Theme$VannaFrontend.Colors.text),
            tl: {
              hd: Css.marginBottom({
                    NAME: "px",
                    VAL: 20
                  }),
              tl: {
                hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                      hd: Css.textAlign("center"),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  panel: panel,
  title: title
};

function Panel(Props) {
  var useDefaultMaxWidthOpt = Props.useDefaultMaxWidth;
  var noMarginBottomOpt = Props.noMarginBottom;
  var classNameOpt = Props.className;
  var children = Props.children;
  var useDefaultMaxWidth = useDefaultMaxWidthOpt !== undefined ? useDefaultMaxWidthOpt : false;
  var noMarginBottom = noMarginBottomOpt !== undefined ? noMarginBottomOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: panel(useDefaultMaxWidth, noMarginBottom),
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  })
            }, children);
}

function Panel$PanelTitle(Props) {
  var children = Props.children;
  return React.createElement("h4", {
              className: title
            }, children);
}

var PanelTitle = {
  make: Panel$PanelTitle
};

var make = Panel;

exports.Styles = Styles;
exports.make = make;
exports.PanelTitle = PanelTitle;
/* title Not a pure module */
