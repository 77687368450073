'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Theme$VannaFrontend = require("../Theme.bs.js");

var input = Curry._1(Css.style, {
      hd: Css.padding2({
            NAME: "rem",
            VAL: 0.75
          }, {
            NAME: "rem",
            VAL: 0.9
          }),
      tl: {
        hd: Css.width({
              NAME: "percent",
              VAL: 100.0
            }),
        tl: {
          hd: Css.maxWidth({
                NAME: "px",
                VAL: 700
              }),
          tl: {
            hd: Css.boxSizing("borderBox"),
            tl: {
              hd: Css.fontSize({
                    NAME: "rem",
                    VAL: 0.85
                  }),
              tl: {
                hd: Css.borderRadius(Theme$VannaFrontend.Border.radius),
                tl: {
                  hd: Css.color(Theme$VannaFrontend.Colors.text),
                  tl: {
                    hd: Css.borderStyle("solid"),
                    tl: {
                      hd: Css.borderWidth({
                            NAME: "px",
                            VAL: 1
                          }),
                      tl: {
                        hd: Css.borderColor(Theme$VannaFrontend.Colors.border),
                        tl: {
                          hd: Css.backgroundColor(Theme$VannaFrontend.Colors.secondary),
                          tl: {
                            hd: Css.outlineStyle("none"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  input: input
};

function stringFromVariant(type_) {
  switch (type_) {
    case /* Email */0 :
        return "email";
    case /* Text */1 :
        return "text";
    case /* Number */2 :
        return "number";
    case /* File */3 :
        return "file";
    case /* Password */4 :
        return "password";
    case /* Tel */5 :
        return "tel";
    case /* Date */6 :
        return "date";
    
  }
}

function InputBase(Props) {
  var type_Opt = Props.type_;
  var placeholder = Props.placeholder;
  var disabledOpt = Props.disabled;
  var value = Props.value;
  var onChange = Props.onChange;
  var onMouseDown = Props.onMouseDown;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var readOnly = Props.readOnly;
  var min = Props.min;
  var max = Props.max;
  var type_ = type_Opt !== undefined ? type_Opt : /* Text */1;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tmp = {
    className: input,
    disabled: disabled,
    type: stringFromVariant(type_)
  };
  if (max !== undefined) {
    tmp.max = Caml_option.valFromOption(max);
  }
  if (min !== undefined) {
    tmp.min = Caml_option.valFromOption(min);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (readOnly !== undefined) {
    tmp.readOnly = Caml_option.valFromOption(readOnly);
  }
  if (value !== undefined) {
    tmp.value = Caml_option.valFromOption(value);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (onChange !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange);
  }
  if (onMouseDown !== undefined) {
    tmp.onMouseDown = Caml_option.valFromOption(onMouseDown);
  }
  return React.createElement("input", tmp);
}

var make = InputBase;

exports.Styles = Styles;
exports.stringFromVariant = stringFromVariant;
exports.make = make;
/* input Not a pure module */
