'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Hr$VannaFrontend = require("./Hr.bs.js");
var Hooks = require("react-relay/hooks");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Title$VannaFrontend = require("./Title.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var BrandPanelQuery_graphql$VannaFrontend = require("../__generated__/BrandPanelQuery_graphql.bs.js");

var panelWrapper = Curry._1(Css.style, {
      hd: Css.maxWidth({
            NAME: "px",
            VAL: 600
          }),
      tl: {
        hd: Css.marginTop({
              NAME: "px",
              VAL: 30
            }),
        tl: {
          hd: Css.marginBottom({
                NAME: "px",
                VAL: 30
              }),
          tl: {
            hd: Css.marginLeft("auto"),
            tl: {
              hd: Css.marginRight("auto"),
              tl: {
                hd: Css.padding({
                      NAME: "rem",
                      VAL: 1.0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var title = Curry._1(Css.style, {
      hd: Css.textAlign("center"),
      tl: {
        hd: Css.marginBottom({
              NAME: "rem",
              VAL: 2.0
            }),
        tl: /* [] */0
      }
    });

var hr = Curry._1(Css.style, {
      hd: Css.marginBottom({
            NAME: "rem",
            VAL: 2.0
          }),
      tl: /* [] */0
    });

var brand = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingBottom({
                  NAME: "rem",
                  VAL: 1.0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var brandImage = Curry._1(Css.style, {
      hd: Css.height({
            NAME: "px",
            VAL: 75
          }),
      tl: /* [] */0
    });

var Styles = {
  panelWrapper: panelWrapper,
  title: title,
  hr: hr,
  brand: brand,
  brandImage: brandImage
};

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(BrandPanelQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(BrandPanelQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(BrandPanelQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(BrandPanelQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, BrandPanelQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, BrandPanelQuery_graphql$VannaFrontend.node, BrandPanelQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: BrandPanelQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, BrandPanelQuery_graphql$VannaFrontend.node, BrandPanelQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return BrandPanelQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(BrandPanelQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(BrandPanelQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var BrandPanelQuery = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

function BrandPanel(Props) {
  var hasBrandOpt = Props.hasBrand;
  var title$1 = Props.title;
  var children = Props.children;
  var hasBrand = hasBrandOpt !== undefined ? hasBrandOpt : true;
  var queryData = use(undefined, undefined, undefined, undefined, undefined, undefined);
  var tmp;
  if (hasBrand) {
    var match = queryData.me;
    var tmp$1;
    if (match !== undefined) {
      var logoSrc = match.settings.whitelabelInfo.logoSrc;
      tmp$1 = logoSrc !== undefined && logoSrc !== "" ? logoSrc : "/icon.png";
    } else {
      tmp$1 = "/icon.png";
    }
    tmp = React.createElement("div", {
          className: brand
        }, React.createElement("img", {
              className: brandImage,
              src: tmp$1
            }));
  } else {
    tmp = null;
  }
  return React.createElement(Panel$VannaFrontend.make, {
              className: panelWrapper,
              children: null
            }, tmp, React.createElement(Hr$VannaFrontend.make, {
                  className: hr
                }), title$1 !== undefined ? React.createElement(Title$VannaFrontend.make, {
                    className: title,
                    children: title$1
                  }) : null, children);
}

var make = BrandPanel;

exports.Styles = Styles;
exports.BrandPanelQuery = BrandPanelQuery;
exports.make = make;
/* panelWrapper Not a pure module */
