'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"affiliateCreate_affiliate_address_additionalInformation":{"n":""},"affiliateCreate_affiliate_address_street":{"n":""},"affiliateCreate_affiliate_address_city":{"n":""},"affiliateCreate":{"n":""},"affiliateCreate_affiliate_address":{"n":""},"affiliateCreate_affiliate_phoneNumber":{"n":""},"affiliateCreate_error":{"n":""},"affiliateCreate_affiliate_phoneAreaCode":{"n":""},"affiliateCreate_affiliate":{"n":""},"affiliateCreate_affiliate_address_number":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"affiliateCreate_affiliate_address_additionalInformation":{"n":""},"affiliateCreate_affiliate_address_street":{"n":""},"affiliateCreate_affiliate_address_city":{"n":""},"affiliateCreate":{"n":""},"affiliateCreate_affiliate_address":{"n":""},"affiliateCreate_affiliate_phoneNumber":{"n":""},"affiliateCreate_error":{"n":""},"affiliateCreate_affiliate_phoneAreaCode":{"n":""},"affiliateCreate_affiliate":{"n":""},"affiliateCreate_affiliate_address_number":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"AffiliateCreateInput"}},"AffiliateCreateInput":{"address":{"n":"","r":"Address"}},"Address":{"additionalInformation":{"n":""},"number":{"n":""},"city":{"r":"City"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_city(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_address(additionalInformation, city, district, number, postalCode, state, street, param) {
  return {
          additionalInformation: additionalInformation,
          city: city,
          district: district,
          number: number,
          postalCode: postalCode,
          state: state,
          street: street
        };
}

function make_affiliateCreateInput(accountNumber, address, bank, email, firstName, identification, lastName, phoneAreaCode, phoneNumber, residency, routingNumber, taxId, typeAccount, typeOfPeople, param) {
  return {
          accountNumber: accountNumber,
          address: address,
          bank: bank,
          email: email,
          firstName: firstName,
          identification: identification,
          lastName: lastName,
          phoneAreaCode: phoneAreaCode,
          phoneNumber: phoneNumber,
          residency: residency,
          routingNumber: routingNumber,
          taxId: taxId,
          typeAccount: typeAccount,
          typeOfPeople: typeOfPeople
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_affiliateCreate_affiliate_address_city(code, name) {
  return {
          code: code,
          name: name
        };
}

function make_response_affiliateCreate_affiliate_address(additionalInformation, city, district, number, postalCode, state, street, param) {
  return {
          additionalInformation: additionalInformation,
          city: city,
          district: district,
          number: number,
          postalCode: postalCode,
          state: state,
          street: street
        };
}

function make_response_affiliateCreate_affiliate(id, email, firstName, lastName, phoneAreaCode, phoneNumber, salesCount, salesTotal, taxId, typeOfPeople, address, param) {
  return {
          id: id,
          email: email,
          firstName: firstName,
          lastName: lastName,
          phoneAreaCode: phoneAreaCode,
          phoneNumber: phoneNumber,
          salesCount: salesCount,
          salesTotal: salesTotal,
          taxId: taxId,
          typeOfPeople: typeOfPeople,
          address: address
        };
}

function make_response_affiliateCreate_error(message) {
  return {
          message: message
        };
}

function make_response_affiliateCreate(error, affiliate, param) {
  return {
          error: error,
          affiliate: affiliate
        };
}

function makeOptimisticResponse(affiliateCreate, param) {
  return {
          affiliateCreate: affiliateCreate
        };
}

var Utils = {
  make_city: make_city,
  make_address: make_address,
  make_affiliateCreateInput: make_affiliateCreateInput,
  makeVariables: makeVariables,
  make_response_affiliateCreate_affiliate_address_city: make_response_affiliateCreate_affiliate_address_city,
  make_response_affiliateCreate_affiliate_address: make_response_affiliateCreate_affiliate_address,
  make_response_affiliateCreate_affiliate: make_response_affiliateCreate_affiliate,
  make_response_affiliateCreate_error: make_response_affiliateCreate_error,
  make_response_affiliateCreate: make_response_affiliateCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AffiliateCreatePayload",
    "kind": "LinkedField",
    "name": "affiliateCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Affiliate",
        "kind": "LinkedField",
        "name": "affiliate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneAreaCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "salesCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "salesTotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taxId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "typeOfPeople",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AddressType",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "additionalInformation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CityType",
                "kind": "LinkedField",
                "name": "city",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "district",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AffiliateUpsertForm_AffiliateCreateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AffiliateUpsertForm_AffiliateCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "39c28f2655337a7c5eb4596333be91fe",
    "id": null,
    "metadata": {},
    "name": "AffiliateUpsertForm_AffiliateCreateMutation",
    "operationKind": "mutation",
    "text": "mutation AffiliateUpsertForm_AffiliateCreateMutation(\n  $input: AffiliateCreateInput!\n) {\n  affiliateCreate(input: $input) {\n    error {\n      message\n    }\n    affiliate {\n      id\n      email\n      firstName\n      lastName\n      phoneAreaCode\n      phoneNumber\n      salesCount\n      salesTotal\n      taxId\n      typeOfPeople\n      address {\n        additionalInformation\n        city {\n          code\n          name\n        }\n        district\n        number\n        postalCode\n        state\n        street\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
