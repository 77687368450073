'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Text$VannaFrontend = require("./Text.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var MoneyField$VannaFrontend = require("./MoneyField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var TwoFactorForm$VannaFrontend = require("./TwoFactorForm.bs.js");
var BrazilianUtils = require("@brazilian-utils/brazilian-utils");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var PixWithdrawalModalFragment_graphql$VannaFrontend = require("../__generated__/PixWithdrawalModalFragment_graphql.bs.js");
var PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend = require("../__generated__/PixWithdrawalModal_PixWithdrawalMutation_graphql.bs.js");
var PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend = require("../__generated__/PixWithdrawalModal_UserRequestSmsAuthMutation_graphql.bs.js");

var textBold = Curry._1(Css.style, {
      hd: Css.fontWeight("bold"),
      tl: /* [] */0
    });

var Styles = {
  textBold: textBold
};

function get(state, field) {
  switch (field) {
    case /* Amount */0 :
        return state.amount;
    case /* GoogleAuthCode */1 :
        return state.googleAuthCode;
    case /* SmsAuthCode */2 :
        return state.smsAuthCode;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Amount */0 :
        return {
                amount: value,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: state.smsAuthCode
              };
    case /* GoogleAuthCode */1 :
        return {
                amount: state.amount,
                googleAuthCode: value,
                smsAuthCode: state.smsAuthCode
              };
    case /* SmsAuthCode */2 :
        return {
                amount: state.amount,
                googleAuthCode: state.googleAuthCode,
                smsAuthCode: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var PixWithdrawalUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.node,
              variables: PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var PixWithdrawalMutation_make_twoFactor = PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Utils.make_twoFactor;

var PixWithdrawalMutation_make_beneficiaryAccount = PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Utils.make_beneficiaryAccount;

var PixWithdrawalMutation_make_pixWithdrawalInput = PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Utils.make_pixWithdrawalInput;

var PixWithdrawalMutation_makeVariables = PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Utils.makeVariables;

var PixWithdrawalMutation_make_response_pixWithdrawal_transaction = PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Utils.make_response_pixWithdrawal_transaction;

var PixWithdrawalMutation_make_response_pixWithdrawal_error = PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Utils.make_response_pixWithdrawal_error;

var PixWithdrawalMutation_make_response_pixWithdrawal = PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Utils.make_response_pixWithdrawal;

var PixWithdrawalMutation_makeOptimisticResponse = PixWithdrawalModal_PixWithdrawalMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var PixWithdrawalMutation = {
  make_twoFactor: PixWithdrawalMutation_make_twoFactor,
  make_beneficiaryAccount: PixWithdrawalMutation_make_beneficiaryAccount,
  make_pixWithdrawalInput: PixWithdrawalMutation_make_pixWithdrawalInput,
  makeVariables: PixWithdrawalMutation_makeVariables,
  make_response_pixWithdrawal_transaction: PixWithdrawalMutation_make_response_pixWithdrawal_transaction,
  make_response_pixWithdrawal_error: PixWithdrawalMutation_make_response_pixWithdrawal_error,
  make_response_pixWithdrawal: PixWithdrawalMutation_make_response_pixWithdrawal,
  makeOptimisticResponse: PixWithdrawalMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

var Internal$1 = {};

function use$1(fRef) {
  var data = Hooks.useFragment(PixWithdrawalModalFragment_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(PixWithdrawalModalFragment_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(PixWithdrawalModalFragment_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return PixWithdrawalModalFragment_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var PixWithdrawalModalFragment = {
  Types: undefined,
  Internal: Internal$1,
  use: use$1,
  useOpt: useOpt
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.node,
              variables: PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var UserRequestSmsAuthMutation_make_response_userRequestSmsAuth_error = PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Utils.make_response_userRequestSmsAuth_error;

var UserRequestSmsAuthMutation_make_response_userRequestSmsAuth = PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Utils.make_response_userRequestSmsAuth;

var UserRequestSmsAuthMutation_makeOptimisticResponse = PixWithdrawalModal_UserRequestSmsAuthMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var UserRequestSmsAuthMutation = {
  make_response_userRequestSmsAuth_error: UserRequestSmsAuthMutation_make_response_userRequestSmsAuth_error,
  make_response_userRequestSmsAuth: UserRequestSmsAuthMutation_make_response_userRequestSmsAuth,
  makeOptimisticResponse: UserRequestSmsAuthMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

function PixWithdrawalModal(Props) {
  var query = Props.query;
  var dispatchModal = Props.dispatchModal;
  var balance = Props.balance;
  var onSuccessOpt = Props.onSuccess;
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var queryData = use$1(query);
  var match = React.useState(function () {
        return /* SetAmount */0;
      });
  var setCurrentStep = match[1];
  var currentStep = match[0];
  var match$1 = use(undefined);
  var pixWithdrawal = match$1[0];
  var isGoogleAuthEnabled = Belt_Option.mapWithDefault(queryData.googleAuth, false, (function (googleAuth) {
          return googleAuth.isEnabled;
        }));
  var isSmsAuthEnabled = Belt_Option.mapWithDefault(queryData.smsAuth, false, (function (smsAuth) {
          return smsAuth.isEnabled;
        }));
  var reform = Curry._7(PixWithdrawalUpsertForm.use, {
        amount: 0.0,
        googleAuthCode: "",
        smsAuthCode: ""
      }, /* Schema */{
        _0: Curry._6(PixWithdrawalUpsertForm.ReSchema.Validation.$$float, 5.0, "Valor mínimo para saque é de 5,00", Money$VannaFrontend.toDecimal(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                          TAG: /* Cents */1,
                          _0: balance
                        }), Money$VannaFrontend.make({
                          TAG: /* Cents */1,
                          _0: 50
                        }))), "Valor não pode exceder ao saldo em conta", undefined, /* Amount */0)
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          if (currentStep) {
            Curry.app(pixWithdrawal, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.pixWithdrawal;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      }
                      var transaction = match.transaction;
                      if (transaction !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        Curry._1(send, {
                              TAG: /* SetFormState */10,
                              _0: /* Dirty */0
                            });
                        return Curry._1(onSuccess, transaction.id);
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  (function (store, response) {
                      var match = response.pixWithdrawal;
                      if (match === undefined) {
                        return ;
                      }
                      var transaction = match.transaction;
                      if (transaction === undefined) {
                        return ;
                      }
                      var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                      var transactionProxy = store.get(transaction.id);
                      if (meProxy == null) {
                        return ;
                      }
                      if (transactionProxy == null) {
                        return ;
                      }
                      var pixTransactionsConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "Pix_transactions", undefined);
                      if (pixTransactionsConnection == null) {
                        return ;
                      }
                      var edge = RelayRuntime.ConnectionHandler.createEdge(store, pixTransactionsConnection, transactionProxy, "Transaction");
                      RelayRuntime.ConnectionHandler.insertEdgeAfter(pixTransactionsConnection, edge, undefined);
                      
                    }),
                  {
                    input: {
                      amount: Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                                TAG: /* Decimal */0,
                                _0: state.values.amount
                              })),
                      beneficiaryAccount: undefined,
                      beneficiaryPixKey: queryData.settings.companyInfo.taxId,
                      twoFactor: {
                        googleAuthCode: state.values.googleAuthCode,
                        smsAuthCode: state.values.smsAuthCode
                      }
                    }
                  },
                  undefined,
                  undefined
                ]);
          } else {
            Curry._1(setCurrentStep, (function (param) {
                    return /* Confirmation */1;
                  }));
            Curry._1(send, {
                  TAG: /* SetFormState */10,
                  _0: /* Dirty */0
                });
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$2 = reform.formState;
  var tmp;
  if (typeof match$2 === "number") {
    tmp = null;
  } else {
    var error = match$2._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(PixWithdrawalUpsertForm.Provider.make, Curry._3(PixWithdrawalUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, currentStep ? React.createElement(React.Fragment, undefined, React.createElement(Text$VannaFrontend.make, {
                                children: null
                              }, "Chave de destino: ", React.createElement("strong", undefined, BrazilianUtils.formatCNPJ(Belt_Option.getWithDefault(queryData.settings.companyInfo.taxId, "")))), React.createElement(Text$VannaFrontend.make, {
                                children: null
                              }, "Valor: ", React.createElement("strong", undefined, Money$VannaFrontend.formatBRL(Money$VannaFrontend.make({
                                            TAG: /* Decimal */0,
                                            _0: reform.state.values.amount
                                          })))), React.createElement(Text$VannaFrontend.make, {
                                children: null
                              }, "Taxa de saque: ", React.createElement("strong", undefined, Money$VannaFrontend.formatBRL(Money$VannaFrontend.make({
                                            TAG: /* Cents */1,
                                            _0: 50
                                          })))), React.createElement(Text$VannaFrontend.make, {
                                children: null
                              }, "Saldo após saque: ", React.createElement("strong", undefined, Money$VannaFrontend.formatBRL(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                                TAG: /* Cents */1,
                                                _0: balance
                                              }), Money$VannaFrontend.$plus(Money$VannaFrontend.make({
                                                    TAG: /* Cents */1,
                                                    _0: 50
                                                  }), Money$VannaFrontend.make({
                                                    TAG: /* Decimal */0,
                                                    _0: reform.state.values.amount
                                                  })))))), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginTop({
                                            NAME: "px",
                                            VAL: 20
                                          }),
                                      tl: /* [] */0
                                    })
                              }, React.createElement(TwoFactorForm$VannaFrontend.make, {
                                    isGoogleAuthEnabled: isGoogleAuthEnabled,
                                    isSmsAuthEnabled: isSmsAuthEnabled,
                                    onChange: (function (method, value) {
                                        if (method) {
                                          return Curry._4(reform.setFieldValue, /* SmsAuthCode */2, value, undefined, undefined);
                                        } else {
                                          return Curry._4(reform.setFieldValue, /* GoogleAuthCode */1, value, undefined, undefined);
                                        }
                                      })
                                  }))) : React.createElement(React.Fragment, undefined, React.createElement(Text$VannaFrontend.make, {
                                className: textBold,
                                children: "O seu saldo é de " + Money$VannaFrontend.formatBRL(/* Money */{
                                      _0: balance
                                    })
                              }), React.createElement(Text$VannaFrontend.make, {
                                className: textBold,
                                children: "O custo de saque é de " + "R$ 0,50"
                              }), React.createElement(Text$VannaFrontend.make, {
                                children: null
                              }, "O saque será feito para a conta PIX de sua empresa ", React.createElement("strong", undefined, Belt_Option.getWithDefault(queryData.settings.companyInfo.tradeName, "")), " por meio da chave CNPJ ", React.createElement("strong", undefined, BrazilianUtils.formatCNPJ(Belt_Option.getWithDefault(queryData.settings.companyInfo.taxId, "")))), React.createElement(PixWithdrawalUpsertForm.Field.make, {
                                field: /* Amount */0,
                                render: (function (param) {
                                    return React.createElement(MoneyField$VannaFrontend.make, {
                                                label: "Valor",
                                                error: param.error,
                                                required: false,
                                                value: param.value,
                                                onChange: param.handleChange
                                              });
                                  })
                              })), tmp, currentStep ? React.createElement(React.Fragment, undefined, React.createElement(Button$VannaFrontend.make, {
                                isLoading: reform.isSubmitting,
                                type_: /* Submit */1,
                                children: "Confirmar"
                              }), React.createElement(Button$VannaFrontend.make, {
                                mode: /* Secondary */1,
                                onClick: (function (param) {
                                    return Curry._1(setCurrentStep, (function (param) {
                                                  return /* SetAmount */0;
                                                }));
                                  }),
                                children: "Voltar"
                              })) : React.createElement(React.Fragment, undefined, React.createElement(Button$VannaFrontend.make, {
                                isLoading: reform.isSubmitting,
                                type_: /* Submit */1,
                                children: "Continuar"
                              }), React.createElement(Button$VannaFrontend.make, {
                                mode: /* Secondary */1,
                                onClick: (function (param) {
                                    return Curry._1(dispatchModal, /* CloseWithConfirmation */0);
                                  }),
                                children: "Cancelar"
                              }))), undefined));
}

var withdrawFeeCents = 50;

var make = PixWithdrawalModal;

exports.Styles = Styles;
exports.StateLenses = StateLenses;
exports.PixWithdrawalUpsertForm = PixWithdrawalUpsertForm;
exports.PixWithdrawalMutation = PixWithdrawalMutation;
exports.PixWithdrawalModalFragment = PixWithdrawalModalFragment;
exports.UserRequestSmsAuthMutation = UserRequestSmsAuthMutation;
exports.withdrawFeeCents = withdrawFeeCents;
exports.make = make;
/* textBold Not a pure module */
