'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"whitelabelInfoUpdate":{"n":""},"whitelabelInfoUpdate_error":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"whitelabelInfoUpdate":{"n":""},"whitelabelInfoUpdate_error":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"WhitelabelInfoUpdateInput"}},"WhitelabelInfoUpdateInput":{"productLink":{"n":""},"title":{"n":""},"logoSrc":{"n":""},"emailTeam":{"n":""},"productName":{"n":""},"emailLogo":{"n":""},"iconSrc":{"n":""}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_whitelabelInfoUpdateInput(emailLogo, emailTeam, iconSrc, logoSrc, productLink, productName, title, param) {
  return {
          emailLogo: emailLogo,
          emailTeam: emailTeam,
          iconSrc: iconSrc,
          logoSrc: logoSrc,
          productLink: productLink,
          productName: productName,
          title: title
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_whitelabelInfoUpdate_error(message) {
  return {
          message: message
        };
}

function make_response_whitelabelInfoUpdate(error, param) {
  return {
          error: error
        };
}

function makeOptimisticResponse(whitelabelInfoUpdate, param) {
  return {
          whitelabelInfoUpdate: whitelabelInfoUpdate
        };
}

var Utils = {
  make_whitelabelInfoUpdateInput: make_whitelabelInfoUpdateInput,
  makeVariables: makeVariables,
  make_response_whitelabelInfoUpdate_error: make_response_whitelabelInfoUpdate_error,
  make_response_whitelabelInfoUpdate: make_response_whitelabelInfoUpdate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "WhitelabelInfoUpdatePayload",
    "kind": "LinkedField",
    "name": "whitelabelInfoUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WhitelabelInfoForm_WhitelabelInfoUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WhitelabelInfoForm_WhitelabelInfoUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e2bd9be7a59a25be9ab441aa0d78b310",
    "id": null,
    "metadata": {},
    "name": "WhitelabelInfoForm_WhitelabelInfoUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation WhitelabelInfoForm_WhitelabelInfoUpdateMutation(\n  $input: WhitelabelInfoUpdateInput!\n) {\n  whitelabelInfoUpdate(input: $input) {\n    error {\n      message\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
