'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Commonjs = require("@fnando/cpf/commonjs");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var Commonjs$1 = require("@fnando/cnpj/commonjs");
var BankCodes$VannaFrontend = require("../types/BankCodes.bs.js");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");
var FileField$VannaFrontend = require("./FileField.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var FieldSection$VannaFrontend = require("./FieldSection.bs.js");
var ZipcodeField$VannaFrontend = require("./ZipcodeField.bs.js");
var StateSelectField$VannaFrontend = require("./StateSelectField.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var SearchableSelectField$VannaFrontend = require("./SearchableSelectField.bs.js");
var AffiliateUpsertForm_affiliate_graphql$VannaFrontend = require("../__generated__/AffiliateUpsertForm_affiliate_graphql.bs.js");
var AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend = require("../__generated__/AffiliateUpsertForm_AffiliateCreateMutation_graphql.bs.js");
var AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend = require("../__generated__/AffiliateUpsertForm_AffiliateUpdateMutation_graphql.bs.js");

function get(state, field) {
  switch (field) {
    case /* TypeOfPeople */0 :
        return state.typeOfPeople;
    case /* Name */1 :
        return state.name;
    case /* TradeName */2 :
        return state.tradeName;
    case /* TaxId */3 :
        return state.taxId;
    case /* Email */4 :
        return state.email;
    case /* PhoneAreaCode */5 :
        return state.phoneAreaCode;
    case /* PhoneNumber */6 :
        return state.phoneNumber;
    case /* Zipcode */7 :
        return state.zipcode;
    case /* Address */8 :
        return state.address;
    case /* Number */9 :
        return state.number;
    case /* Neighborhood */10 :
        return state.neighborhood;
    case /* CityName */11 :
        return state.cityName;
    case /* CityCode */12 :
        return state.cityCode;
    case /* State */13 :
        return state.state;
    case /* Complement */14 :
        return state.complement;
    case /* IdentificationFile */15 :
        return state.identificationFile;
    case /* ResidencyFile */16 :
        return state.residencyFile;
    case /* BankCode */17 :
        return state.bankCode;
    case /* BankName */18 :
        return state.bankName;
    case /* RoutingNumber */19 :
        return state.routingNumber;
    case /* AccountNumber */20 :
        return state.accountNumber;
    case /* AccountType */21 :
        return state.accountType;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* TypeOfPeople */0 :
        var newrecord = Caml_obj.caml_obj_dup(state);
        newrecord.typeOfPeople = value;
        return newrecord;
    case /* Name */1 :
        var newrecord$1 = Caml_obj.caml_obj_dup(state);
        newrecord$1.name = value;
        return newrecord$1;
    case /* TradeName */2 :
        var newrecord$2 = Caml_obj.caml_obj_dup(state);
        newrecord$2.tradeName = value;
        return newrecord$2;
    case /* TaxId */3 :
        var newrecord$3 = Caml_obj.caml_obj_dup(state);
        newrecord$3.taxId = value;
        return newrecord$3;
    case /* Email */4 :
        var newrecord$4 = Caml_obj.caml_obj_dup(state);
        newrecord$4.email = value;
        return newrecord$4;
    case /* PhoneAreaCode */5 :
        var newrecord$5 = Caml_obj.caml_obj_dup(state);
        newrecord$5.phoneAreaCode = value;
        return newrecord$5;
    case /* PhoneNumber */6 :
        var newrecord$6 = Caml_obj.caml_obj_dup(state);
        newrecord$6.phoneNumber = value;
        return newrecord$6;
    case /* Zipcode */7 :
        var newrecord$7 = Caml_obj.caml_obj_dup(state);
        newrecord$7.zipcode = value;
        return newrecord$7;
    case /* Address */8 :
        var newrecord$8 = Caml_obj.caml_obj_dup(state);
        newrecord$8.address = value;
        return newrecord$8;
    case /* Number */9 :
        var newrecord$9 = Caml_obj.caml_obj_dup(state);
        newrecord$9.number = value;
        return newrecord$9;
    case /* Neighborhood */10 :
        var newrecord$10 = Caml_obj.caml_obj_dup(state);
        newrecord$10.neighborhood = value;
        return newrecord$10;
    case /* CityName */11 :
        var newrecord$11 = Caml_obj.caml_obj_dup(state);
        newrecord$11.cityName = value;
        return newrecord$11;
    case /* CityCode */12 :
        var newrecord$12 = Caml_obj.caml_obj_dup(state);
        newrecord$12.cityCode = value;
        return newrecord$12;
    case /* State */13 :
        var newrecord$13 = Caml_obj.caml_obj_dup(state);
        newrecord$13.state = value;
        return newrecord$13;
    case /* Complement */14 :
        var newrecord$14 = Caml_obj.caml_obj_dup(state);
        newrecord$14.complement = value;
        return newrecord$14;
    case /* IdentificationFile */15 :
        var newrecord$15 = Caml_obj.caml_obj_dup(state);
        newrecord$15.identificationFile = value;
        return newrecord$15;
    case /* ResidencyFile */16 :
        var newrecord$16 = Caml_obj.caml_obj_dup(state);
        newrecord$16.residencyFile = value;
        return newrecord$16;
    case /* BankCode */17 :
        var newrecord$17 = Caml_obj.caml_obj_dup(state);
        newrecord$17.bankCode = value;
        return newrecord$17;
    case /* BankName */18 :
        var newrecord$18 = Caml_obj.caml_obj_dup(state);
        newrecord$18.bankName = value;
        return newrecord$18;
    case /* RoutingNumber */19 :
        var newrecord$19 = Caml_obj.caml_obj_dup(state);
        newrecord$19.routingNumber = value;
        return newrecord$19;
    case /* AccountNumber */20 :
        var newrecord$20 = Caml_obj.caml_obj_dup(state);
        newrecord$20.accountNumber = value;
        return newrecord$20;
    case /* AccountType */21 :
        var newrecord$21 = Caml_obj.caml_obj_dup(state);
        newrecord$21.accountType = value;
        return newrecord$21;
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var AffiliateUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var TypeOfPeopleSelect = SearchableSelectField$VannaFrontend.Make({});

var BanksSelect = SearchableSelectField$VannaFrontend.Make({});

var AccountTypeSelect = SearchableSelectField$VannaFrontend.Make({});

var twentyPercentField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 20
          }),
      tl: /* [] */0
    });

var thirtyPercent = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 33.33
          }),
      tl: /* [] */0
    });

var fiftyPercentField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 50
          }),
      tl: /* [] */0
    });

var Styles = {
  twentyPercentField: twentyPercentField,
  thirtyPercent: thirtyPercent,
  fiftyPercentField: fiftyPercentField
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(AffiliateUpsertForm_affiliate_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(AffiliateUpsertForm_affiliate_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(AffiliateUpsertForm_affiliate_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return AffiliateUpsertForm_affiliate_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var AffiliateFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.node,
              variables: AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var AffiliateCreateMutation_make_city = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.make_city;

var AffiliateCreateMutation_make_address = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.make_address;

var AffiliateCreateMutation_make_affiliateCreateInput = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.make_affiliateCreateInput;

var AffiliateCreateMutation_makeVariables = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var AffiliateCreateMutation_make_response_affiliateCreate_affiliate_address_city = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.make_response_affiliateCreate_affiliate_address_city;

var AffiliateCreateMutation_make_response_affiliateCreate_affiliate_address = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.make_response_affiliateCreate_affiliate_address;

var AffiliateCreateMutation_make_response_affiliateCreate_affiliate = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.make_response_affiliateCreate_affiliate;

var AffiliateCreateMutation_make_response_affiliateCreate_error = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.make_response_affiliateCreate_error;

var AffiliateCreateMutation_make_response_affiliateCreate = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.make_response_affiliateCreate;

var AffiliateCreateMutation_makeOptimisticResponse = AffiliateUpsertForm_AffiliateCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var AffiliateCreateMutation = {
  make_city: AffiliateCreateMutation_make_city,
  make_address: AffiliateCreateMutation_make_address,
  make_affiliateCreateInput: AffiliateCreateMutation_make_affiliateCreateInput,
  makeVariables: AffiliateCreateMutation_makeVariables,
  make_response_affiliateCreate_affiliate_address_city: AffiliateCreateMutation_make_response_affiliateCreate_affiliate_address_city,
  make_response_affiliateCreate_affiliate_address: AffiliateCreateMutation_make_response_affiliateCreate_affiliate_address,
  make_response_affiliateCreate_affiliate: AffiliateCreateMutation_make_response_affiliateCreate_affiliate,
  make_response_affiliateCreate_error: AffiliateCreateMutation_make_response_affiliateCreate_error,
  make_response_affiliateCreate: AffiliateCreateMutation_make_response_affiliateCreate,
  makeOptimisticResponse: AffiliateCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.node,
              variables: AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var AffiliateUpdateMutation_make_city = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.make_city;

var AffiliateUpdateMutation_make_address = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.make_address;

var AffiliateUpdateMutation_make_affiliateUpdateInput = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.make_affiliateUpdateInput;

var AffiliateUpdateMutation_makeVariables = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var AffiliateUpdateMutation_make_response_affiliateUpdate_affiliate_address_city = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.make_response_affiliateUpdate_affiliate_address_city;

var AffiliateUpdateMutation_make_response_affiliateUpdate_affiliate_address = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.make_response_affiliateUpdate_affiliate_address;

var AffiliateUpdateMutation_make_response_affiliateUpdate_affiliate = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.make_response_affiliateUpdate_affiliate;

var AffiliateUpdateMutation_make_response_affiliateUpdate_error = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.make_response_affiliateUpdate_error;

var AffiliateUpdateMutation_make_response_affiliateUpdate = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.make_response_affiliateUpdate;

var AffiliateUpdateMutation_makeOptimisticResponse = AffiliateUpsertForm_AffiliateUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var AffiliateUpdateMutation = {
  make_city: AffiliateUpdateMutation_make_city,
  make_address: AffiliateUpdateMutation_make_address,
  make_affiliateUpdateInput: AffiliateUpdateMutation_make_affiliateUpdateInput,
  makeVariables: AffiliateUpdateMutation_makeVariables,
  make_response_affiliateUpdate_affiliate_address_city: AffiliateUpdateMutation_make_response_affiliateUpdate_affiliate_address_city,
  make_response_affiliateUpdate_affiliate_address: AffiliateUpdateMutation_make_response_affiliateUpdate_affiliate_address,
  make_response_affiliateUpdate_affiliate: AffiliateUpdateMutation_make_response_affiliateUpdate_affiliate,
  make_response_affiliateUpdate_error: AffiliateUpdateMutation_make_response_affiliateUpdate_error,
  make_response_affiliateUpdate: AffiliateUpdateMutation_make_response_affiliateUpdate,
  makeOptimisticResponse: AffiliateUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

function AffiliateUpsertForm$1(Props) {
  var affiliateOpt = Props.affiliate;
  var dispatchModal = Props.dispatchModal;
  var affiliate = affiliateOpt !== undefined ? Caml_option.valFromOption(affiliateOpt) : undefined;
  var affiliateFragment = useOpt(affiliate);
  var match = React.useState(function () {
        return false;
      });
  var setAddressLoaded = match[1];
  var isAddressLoaded = match[0];
  var match$1 = use$1(undefined);
  var createAffiliate = match$1[0];
  var match$2 = use$2(undefined);
  var updateAffiliate = match$2[0];
  var tmp;
  if (affiliateFragment !== undefined) {
    var match$3 = affiliateFragment.typeOfPeople;
    var tmp$1;
    if (match$3 === "LEGAL") {
      tmp$1 = /* Legal */0;
    } else if (match$3 === "NATURAL") {
      tmp$1 = /* Natural */1;
    } else {
      throw {
            RE_EXN_ID: "Match_failure",
            _1: [
              "AffiliateUpsertForm.res",
              170,
              22
            ],
            Error: new Error()
          };
    }
    tmp = {
      typeOfPeople: tmp$1,
      name: affiliateFragment.firstName,
      tradeName: affiliateFragment.lastName,
      taxId: affiliateFragment.taxId,
      email: affiliateFragment.email,
      phoneAreaCode: Belt_Option.getWithDefault(affiliateFragment.phoneAreaCode, ""),
      phoneNumber: Belt_Option.getWithDefault(affiliateFragment.phoneNumber, ""),
      zipcode: Belt_Option.mapWithDefault(affiliateFragment.address, "", (function (address) {
              return address.postalCode;
            })),
      address: Belt_Option.getWithDefault(Belt_Option.flatMap(affiliateFragment.address, (function (address) {
                  return address.street;
                })), ""),
      number: Belt_Option.getWithDefault(Belt_Option.flatMap(affiliateFragment.address, (function (address) {
                  return address.number;
                })), ""),
      neighborhood: Belt_Option.mapWithDefault(affiliateFragment.address, "", (function (address) {
              return address.district;
            })),
      cityName: Belt_Option.mapWithDefault(Belt_Option.flatMap(affiliateFragment.address, (function (address) {
                  return address.city;
                })), "", (function (city) {
              return city.name;
            })),
      cityCode: Belt_Option.mapWithDefault(Belt_Option.flatMap(affiliateFragment.address, (function (address) {
                  return address.city;
                })), "", (function (city) {
              return city.code;
            })),
      state: Belt_Option.mapWithDefault(affiliateFragment.address, "", (function (address) {
              return address.state;
            })),
      complement: Belt_Option.getWithDefault(Belt_Option.flatMap(affiliateFragment.address, (function (address) {
                  return address.additionalInformation;
                })), ""),
      identificationFile: undefined,
      residencyFile: undefined,
      bankCode: "",
      bankName: "",
      routingNumber: "",
      accountNumber: "",
      accountType: /* Checking */0
    };
  } else {
    tmp = {
      typeOfPeople: /* Natural */1,
      name: "",
      tradeName: "",
      taxId: "",
      email: "",
      phoneAreaCode: "",
      phoneNumber: "",
      zipcode: "",
      address: "",
      number: "",
      neighborhood: "",
      cityName: "",
      cityCode: "",
      state: "",
      complement: "",
      identificationFile: undefined,
      residencyFile: undefined,
      bankCode: "",
      bankName: "",
      routingNumber: "",
      accountNumber: "",
      accountType: /* Checking */0
    };
  }
  var reform = Curry._7(AffiliateUpsertForm.use, tmp, /* Schema */{
        _0: Curry._2(AffiliateUpsertForm.ReSchema.Validation.$plus, Curry._2(AffiliateUpsertForm.ReSchema.Validation.$plus, Curry._2(AffiliateUpsertForm.ReSchema.Validation.$plus, Curry._2(AffiliateUpsertForm.ReSchema.Validation.$plus, Curry._2(AffiliateUpsertForm.ReSchema.Validation.$plus, Curry._2(AffiliateUpsertForm.ReSchema.Validation.$plus, Curry._2(AffiliateUpsertForm.ReSchema.Validation.$plus, Curry._2(AffiliateUpsertForm.ReSchema.Validation.$plus, Curry._3(AffiliateUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Name */1), Curry._3(AffiliateUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* TradeName */2)), Curry._3(AffiliateUpsertForm.ReSchema.Validation.custom, (function (param) {
                                            var taxId = param.taxId;
                                            if (param.typeOfPeople) {
                                              if (Commonjs.isValid(taxId)) {
                                                return /* Valid */0;
                                              } else {
                                                return {
                                                        TAG: /* Error */1,
                                                        _0: "O CPF informado é inválido"
                                                      };
                                              }
                                            } else if (Commonjs$1.isValid(taxId)) {
                                              return /* Valid */0;
                                            } else {
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: "O CNPJ informado é inválido"
                                                    };
                                            }
                                          }), undefined, /* TaxId */3)), Curry._3(AffiliateUpsertForm.ReSchema.Validation.email, undefined, undefined, /* Email */4)), Curry._3(AffiliateUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* PhoneAreaCode */5)), Curry._3(AffiliateUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* PhoneNumber */6)), Curry._3(AffiliateUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* BankCode */17)), Curry._3(AffiliateUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* RoutingNumber */19)), Curry._3(AffiliateUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* AccountNumber */20))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          if (affiliateFragment !== undefined) {
            var match = state.values.typeOfPeople;
            Curry.app(updateAffiliate, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.affiliateUpdate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      } else if (match.affiliate !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        return Curry._1(send, {
                                    TAG: /* SetFormState */10,
                                    _0: /* Dirty */0
                                  });
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    input: {
                      address: {
                        additionalInformation: state.values.complement,
                        city: {
                          code: state.values.cityCode,
                          name: state.values.cityName
                        },
                        district: state.values.neighborhood,
                        number: state.values.number,
                        postalCode: state.values.zipcode,
                        state: state.values.state,
                        street: state.values.address
                      },
                      affiliateId: affiliateFragment.id,
                      email: state.values.email,
                      firstName: state.values.name,
                      lastName: state.values.tradeName,
                      phoneAreaCode: state.values.phoneAreaCode,
                      phoneNumber: state.values.phoneNumber,
                      taxId: state.values.taxId,
                      typeOfPeople: match ? "NATURAL" : "LEGAL"
                    }
                  },
                  undefined,
                  undefined
                ]);
          } else {
            var match$1 = state.values.identificationFile;
            var match$2 = state.values.residencyFile;
            if (match$1 !== undefined && match$2 !== undefined) {
              var match$3 = state.values.accountType;
              var match$4 = state.values.typeOfPeople;
              Curry.app(createAffiliate, [
                    (function (param) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }),
                    (function (response, errors) {
                        if (errors !== undefined && errors.length !== 0) {
                          return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                        }
                        var match = response.affiliateCreate;
                        if (match === undefined) {
                          return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                        }
                        var match$1 = match.error;
                        if (match$1 !== undefined) {
                          return Curry._1(raiseSubmitFailed, match$1.message);
                        } else if (match.affiliate !== undefined) {
                          Curry._1(dispatchModal, /* Close */1);
                          return Curry._1(send, {
                                      TAG: /* SetFormState */10,
                                      _0: /* Dirty */0
                                    });
                        } else {
                          return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                        }
                      }),
                    undefined,
                    undefined,
                    undefined,
                    (function (store, response) {
                        var match = response.affiliateCreate;
                        if (match === undefined) {
                          return ;
                        }
                        var affiliate = match.affiliate;
                        if (affiliate === undefined) {
                          return ;
                        }
                        var me = store.getRoot().getLinkedRecord("me", undefined);
                        if (me == null) {
                          return ;
                        }
                        var affiliates = ReasonRelay.RecordProxy.getLinkedRecords(me, "affiliates", undefined, undefined);
                        if (affiliates === undefined) {
                          return ;
                        }
                        var affiliateProxy = store.get(affiliate.id);
                        me.setLinkedRecords(Belt_Array.concat(affiliates, [(affiliateProxy == null) ? undefined : Caml_option.some(affiliateProxy)]), "affiliates", undefined);
                        
                      }),
                    {
                      input: {
                        accountNumber: state.values.accountNumber,
                        address: {
                          additionalInformation: state.values.complement,
                          city: {
                            code: state.values.cityCode,
                            name: state.values.cityName
                          },
                          district: state.values.neighborhood,
                          number: state.values.number,
                          postalCode: state.values.zipcode,
                          state: state.values.state,
                          street: state.values.address
                        },
                        bank: state.values.bankCode,
                        email: state.values.email,
                        firstName: state.values.name,
                        identification: Caml_option.valFromOption(match$1),
                        lastName: state.values.tradeName,
                        phoneAreaCode: state.values.phoneAreaCode,
                        phoneNumber: state.values.phoneNumber,
                        residency: Caml_option.valFromOption(match$2),
                        routingNumber: state.values.routingNumber,
                        taxId: state.values.taxId,
                        typeAccount: match$3 ? "SAVING" : "CHECKING",
                        typeOfPeople: match$4 ? "NATURAL" : "LEGAL"
                      }
                    },
                    undefined,
                    undefined
                  ]);
            }
            
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$4 = reform.formState;
  var tmp$2;
  if (typeof match$4 === "number") {
    tmp$2 = null;
  } else {
    var error = match$4._0;
    tmp$2 = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(AffiliateUpsertForm.Provider.make, Curry._3(AffiliateUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* TypeOfPeople */0,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(TypeOfPeopleSelect.make, {
                                          label: "Tipo",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          options: [
                                            {
                                              label: "Pessoa Física",
                                              value: /* Natural */1
                                            },
                                            {
                                              label: "Pessoa Jurídica",
                                              value: /* Legal */0
                                            }
                                          ]
                                        });
                            })
                        }), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* Name */1,
                          render: (function (param) {
                              var match = reform.state.values.typeOfPeople;
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: match ? "Nome" : "Razão social",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* TradeName */2,
                          render: (function (param) {
                              var match = reform.state.values.typeOfPeople;
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: match ? "Sobrenome" : "Nome fantasia",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* TaxId */3,
                          render: (function (param) {
                              var match = reform.state.values.typeOfPeople;
                              var match$1 = reform.state.values.typeOfPeople;
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: match ? "CPF" : "CNPJ",
                                          error: param.error,
                                          required: true,
                                          mask: match$1 ? "999.999.999-99" : "99.999.999/9999-99",
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(FieldSection$VannaFrontend.make, {
                          children: "Endereço"
                        }), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* Zipcode */7,
                          render: (function (param) {
                              return React.createElement(ZipcodeField$VannaFrontend.make, {
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange,
                                          onAddressInfoLoaded: (function (param) {
                                              Curry._1(setAddressLoaded, (function (param) {
                                                      return true;
                                                    }));
                                              var newrecord = Caml_obj.caml_obj_dup(reform.values);
                                              return Curry._1(reform.setValues, (newrecord.complement = param.complement, newrecord.state = param.state, newrecord.cityCode = param.cityCode, newrecord.cityName = param.cityName, newrecord.neighborhood = param.neighborhood, newrecord.address = param.address, newrecord.zipcode = param.zipcode, newrecord));
                                            })
                                        });
                            })
                        }), React.createElement(FieldGrid$VannaFrontend.make, {
                          children: null
                        }, React.createElement(AffiliateUpsertForm.Field.make, {
                              field: /* Address */8,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Rua",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            }), React.createElement(AffiliateUpsertForm.Field.make, {
                              field: /* Number */9,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Número",
                                              error: param.error,
                                              type_: /* Tel */5,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: twentyPercentField
                                            });
                                })
                            })), React.createElement(FieldGrid$VannaFrontend.make, {
                          children: null
                        }, React.createElement(AffiliateUpsertForm.Field.make, {
                              field: /* Neighborhood */10,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Bairro",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: thirtyPercent
                                            });
                                })
                            }), React.createElement(AffiliateUpsertForm.Field.make, {
                              field: /* CityName */11,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Cidade",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: thirtyPercent,
                                              disabled: isAddressLoaded
                                            });
                                })
                            }), React.createElement(AffiliateUpsertForm.Field.make, {
                              field: /* State */13,
                              render: (function (param) {
                                  return React.createElement(StateSelectField$VannaFrontend.make, {
                                              error: param.error,
                                              onChange: param.handleChange,
                                              value: param.value,
                                              disabled: isAddressLoaded,
                                              className: thirtyPercent
                                            });
                                })
                            })), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* Complement */14,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Informações adicionais",
                                          error: param.error,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(FieldSection$VannaFrontend.make, {
                          children: "Contato"
                        }), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* Email */4,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Email",
                                          error: param.error,
                                          type_: /* Email */0,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(FieldSection$VannaFrontend.make, {
                          children: "Telefone"
                        }), React.createElement(FieldGrid$VannaFrontend.make, {
                          children: null
                        }, React.createElement(AffiliateUpsertForm.Field.make, {
                              field: /* PhoneAreaCode */5,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Área",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: twentyPercentField
                                            });
                                })
                            }), React.createElement(AffiliateUpsertForm.Field.make, {
                              field: /* PhoneNumber */6,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Número",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange
                                            });
                                })
                            })), React.createElement(FieldSection$VannaFrontend.make, {
                          children: "Conta bancária"
                        }), React.createElement(FieldGrid$VannaFrontend.make, {
                          children: React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width({
                                            NAME: "percent",
                                            VAL: 50
                                          }),
                                      tl: /* [] */0
                                    })
                              }, React.createElement(AffiliateUpsertForm.Field.make, {
                                    field: /* BankCode */17,
                                    render: (function (param) {
                                        var handleChange = param.handleChange;
                                        return React.createElement(BanksSelect.make, {
                                                    label: "Banco",
                                                    error: param.error,
                                                    required: true,
                                                    value: param.value,
                                                    onChange: (function (param) {
                                                        Curry._1(handleChange, param.value);
                                                        return $$Promise.resolved(undefined);
                                                      }),
                                                    options: BankCodes$VannaFrontend.list
                                                  });
                                      })
                                  }))
                        }), React.createElement(FieldGrid$VannaFrontend.make, {
                          children: null
                        }, React.createElement(AffiliateUpsertForm.Field.make, {
                              field: /* RoutingNumber */19,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Agência",
                                              error: param.error,
                                              required: true,
                                              tip: "Número da agência sem o dígito verificador",
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: fiftyPercentField
                                            });
                                })
                            }), React.createElement(AffiliateUpsertForm.Field.make, {
                              field: /* AccountNumber */20,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Número da conta",
                                              error: param.error,
                                              required: true,
                                              tip: "Número da conta com o dígito verificador e sem hífen",
                                              value: param.value,
                                              onChange: param.handleChange,
                                              wrapperClassName: fiftyPercentField
                                            });
                                })
                            })), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* AccountType */21,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(AccountTypeSelect.make, {
                                          label: "Tipo da conta",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          options: [
                                            {
                                              label: "Conta corrente",
                                              value: /* Checking */0
                                            },
                                            {
                                              label: "Conta poupança",
                                              value: /* Savings */1
                                            }
                                          ]
                                        });
                            })
                        }), React.createElement(FieldSection$VannaFrontend.make, {
                          children: "Documentos"
                        }), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* IdentificationFile */15,
                          render: (function (param) {
                              return React.createElement(FileField$VannaFrontend.make, {
                                          label: "Documento de identidade com foto (RG ou CNG)",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* ResidencyFile */16,
                          render: (function (param) {
                              return React.createElement(FileField$VannaFrontend.make, {
                                          label: "Comprovante de residência em nome do afiliado (ou familiares)",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(AffiliateUpsertForm.Field.make, {
                          field: /* AccountType */21,
                          render: (function (param) {
                              var handleChange = param.handleChange;
                              return React.createElement(AccountTypeSelect.make, {
                                          label: "Tipo da conta",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: (function (param) {
                                              Curry._1(handleChange, param.value);
                                              return $$Promise.resolved(undefined);
                                            }),
                                          options: [
                                            {
                                              label: "Conta corrente",
                                              value: /* Checking */0
                                            },
                                            {
                                              label: "Conta poupança",
                                              value: /* Savings */1
                                            }
                                          ]
                                        });
                            })
                        }), tmp$2, React.createElement(Button$VannaFrontend.make, {
                          isLoading: reform.isSubmitting,
                          type_: /* Submit */1,
                          children: Belt_Option.isSome(affiliateFragment) ? "Atualizar" : "Adicionar"
                        }), React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, /* CloseWithConfirmation */0);
                            }),
                          children: "Cancelar"
                        })), undefined));
}

var make = AffiliateUpsertForm$1;

exports.StateLenses = StateLenses;
exports.AffiliateUpsertForm = AffiliateUpsertForm;
exports.TypeOfPeopleSelect = TypeOfPeopleSelect;
exports.BanksSelect = BanksSelect;
exports.AccountTypeSelect = AccountTypeSelect;
exports.Styles = Styles;
exports.AffiliateFragment = AffiliateFragment;
exports.AffiliateCreateMutation = AffiliateCreateMutation;
exports.AffiliateUpdateMutation = AffiliateUpdateMutation;
exports.make = make;
/* AffiliateUpsertForm Not a pure module */
