'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Ai = require("react-icons/ai");
var Fi = require("react-icons/fi");
var Format = require("date-fns/format");
var Badge$VannaFrontend = require("./Badge.bs.js");
var Modal$VannaFrontend = require("./Modal.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Table$VannaFrontend = require("./Table.bs.js");
var IconButton$VannaFrontend = require("./IconButton.bs.js");
var ConfirmModal$VannaFrontend = require("./ConfirmModal.bs.js");
var ReceiptCharge$VannaFrontend = require("./ReceiptCharge.bs.js");
var ChargeUpsertForm$VannaFrontend = require("./ChargeUpsertForm.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var ChargesTable_ChargeCancelMutation_graphql$VannaFrontend = require("../__generated__/ChargesTable_ChargeCancelMutation_graphql.bs.js");

var CustomTable = Table$VannaFrontend.Make({});

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.node,
              variables: ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ChargeCancelMutation_make_chargeCancelInput = ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Utils.make_chargeCancelInput;

var ChargeCancelMutation_makeVariables = ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Utils.makeVariables;

var ChargeCancelMutation_make_response_chargeCancel_charge = ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Utils.make_response_chargeCancel_charge;

var ChargeCancelMutation_make_response_chargeCancel_error = ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Utils.make_response_chargeCancel_error;

var ChargeCancelMutation_make_response_chargeCancel = ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Utils.make_response_chargeCancel;

var ChargeCancelMutation_makeOptimisticResponse = ChargesTable_ChargeCancelMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ChargeCancelMutation = {
  make_chargeCancelInput: ChargeCancelMutation_make_chargeCancelInput,
  makeVariables: ChargeCancelMutation_makeVariables,
  make_response_chargeCancel_charge: ChargeCancelMutation_make_response_chargeCancel_charge,
  make_response_chargeCancel_error: ChargeCancelMutation_make_response_chargeCancel_error,
  make_response_chargeCancel: ChargeCancelMutation_make_response_chargeCancel,
  makeOptimisticResponse: ChargeCancelMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

function ChargesTable(Props) {
  var $$navigator = Props.navigator;
  var kind = Props.kind;
  var charges = Props.charges;
  var queryRef = Props.query;
  var match = use(undefined);
  var isCancelling = match[1];
  var chargeCancel = match[0];
  React.useState(function () {
        
      });
  var match$1 = React.useState(function () {
        
      });
  var setCancelError = match$1[1];
  var cancelError = match$1[0];
  var tmp;
  switch (kind) {
    case /* Paid */0 :
        tmp = {
          hd: Table$VannaFrontend.column("Situação", undefined, (function (param, param$1) {
                  var paidAt = param.paidAt;
                  if (paidAt === undefined) {
                    return React.createElement(Badge$VannaFrontend.make, {
                                mode: /* Success */1,
                                text: "Pago"
                              });
                  }
                  var formattedDate = Format(new Date(paidAt), "dd/MM/yyyy kk:mm");
                  return React.createElement(Badge$VannaFrontend.make, {
                              mode: /* Success */1,
                              text: "Pago",
                              label: "em " + formattedDate
                            });
                })),
          tl: {
            hd: Table$VannaFrontend.column("Liberação em", undefined, (function (param, param$1) {
                    var expectedOn = param.expectedOn;
                    if (expectedOn !== undefined) {
                      return Format(new Date(expectedOn), "dd/MM/yyyy kk:mm");
                    } else {
                      return "-";
                    }
                  })),
            tl: /* [] */0
          }
        };
        break;
    case /* AwaitingPayment */1 :
        tmp = {
          hd: Table$VannaFrontend.column("Situação", undefined, (function (param, param$1) {
                  var formattedDate = Format(new Date(param.date), "dd/MM/yyyy kk:mm");
                  return React.createElement(Badge$VannaFrontend.make, {
                              mode: /* Default */0,
                              text: "Aguardando pagamento",
                              label: "em " + formattedDate
                            });
                })),
          tl: {
            hd: Table$VannaFrontend.column("", false, (function (charge, param) {
                    return React.createElement("a", {
                                href: "/vendas/" + charge.id,
                                target: "_blank"
                              }, React.createElement(IconButton$VannaFrontend.make, {
                                    onClick: (function (param, _setLoading, param$1) {
                                        
                                      }),
                                    children: React.createElement(Fi.FiLink, {})
                                  }));
                  })),
            tl: {
              hd: Table$VannaFrontend.column("", false, (function (charge, param) {
                      return React.createElement(Modal$VannaFrontend.make, {
                                  title: "Atualizar cobrança",
                                  trigger: (function (dispatch) {
                                      return React.createElement(IconButton$VannaFrontend.make, {
                                                  onClick: (function (param, param$1, param$2) {
                                                      return Curry._1(dispatch, /* Open */2);
                                                    }),
                                                  children: React.createElement(Fi.FiEye, {})
                                                });
                                    }),
                                  content: (function (dispatch) {
                                      return React.createElement(ChargeUpsertForm$VannaFrontend.make, {
                                                  charge: Caml_option.some(charge.fragmentRefs),
                                                  dispatchModal: dispatch,
                                                  query: queryRef
                                                });
                                    })
                                });
                    })),
              tl: {
                hd: Table$VannaFrontend.column("", false, (function (charge, param) {
                        return React.createElement(ConfirmModal$VannaFrontend.make, {
                                    trigger: (function (open_) {
                                        return React.createElement(IconButton$VannaFrontend.make, {
                                                    onClick: (function (param, param$1, param$2) {
                                                        return Curry._1(open_, undefined);
                                                      }),
                                                    children: React.createElement(Fi.FiX, {})
                                                  });
                                      }),
                                    title: "Confirmar cancelamento?",
                                    message: "Você tem certeza que deseja cancelar essa venda?",
                                    error: cancelError,
                                    isLoading: isCancelling,
                                    onConfirm: (function (param) {
                                        Curry.app(chargeCancel, [
                                              (function (param) {
                                                  return Curry._1(setCancelError, (function (param) {
                                                                return "Algo deu errado, tente novamente";
                                                              }));
                                                }),
                                              (function (response, errors) {
                                                  var exit = 0;
                                                  if (errors !== undefined) {
                                                    if (errors.length !== 0) {
                                                      return Curry._1(setCancelError, (function (param) {
                                                                    return "Algo deu errado, tente novamente";
                                                                  }));
                                                    }
                                                    exit = 1;
                                                  } else {
                                                    exit = 1;
                                                  }
                                                  if (exit === 1) {
                                                    var match = response.chargeCancel;
                                                    var exit$1 = 0;
                                                    if (match !== undefined) {
                                                      var match$1 = match.error;
                                                      if (match$1 !== undefined) {
                                                        var message = match$1.message;
                                                        return Curry._1(setCancelError, (function (param) {
                                                                      return message;
                                                                    }));
                                                      }
                                                      exit$1 = 2;
                                                    } else {
                                                      exit$1 = 2;
                                                    }
                                                    if (exit$1 === 2) {
                                                      return Curry._1(setCancelError, (function (param) {
                                                                    
                                                                  }));
                                                    }
                                                    
                                                  }
                                                  
                                                }),
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              {
                                                input: {
                                                  chargeId: charge.id
                                                }
                                              },
                                              undefined,
                                              undefined
                                            ]);
                                        
                                      })
                                  });
                      })),
                tl: {
                  hd: Table$VannaFrontend.column("", false, (function (charge, param) {
                          return React.createElement(Modal$VannaFrontend.make, {
                                      title: "Imprimir recibo",
                                      trigger: (function (dispatch) {
                                          return React.createElement(IconButton$VannaFrontend.make, {
                                                      onClick: (function (param, param$1, param$2) {
                                                          return Curry._1(dispatch, /* Open */2);
                                                        }),
                                                      children: React.createElement(Ai.AiOutlineFilePdf, {})
                                                    });
                                        }),
                                      content: (function (param) {
                                          return React.createElement(ReceiptCharge$VannaFrontend.make, {
                                                      query: charge.fragmentRefs
                                                    });
                                        }),
                                      confirmClose: false
                                    });
                        })),
                  tl: /* [] */0
                }
              }
            }
          }
        };
        break;
    case /* Canceled */2 :
        tmp = {
          hd: Table$VannaFrontend.column("Situação", undefined, (function (param, param$1) {
                  var canceledAt = param.canceledAt;
                  if (canceledAt === undefined) {
                    return React.createElement(Badge$VannaFrontend.make, {
                                mode: /* Error */2,
                                text: "Cancelado"
                              });
                  }
                  var formattedDate = Format(new Date(canceledAt), "dd/MM/yyyy kk:mm");
                  return React.createElement(Badge$VannaFrontend.make, {
                              mode: /* Warn */4,
                              text: "Cancelado",
                              label: "em " + formattedDate
                            });
                })),
          tl: /* [] */0
        };
        break;
    case /* Refunded */3 :
        tmp = {
          hd: Table$VannaFrontend.column("Situação", undefined, (function (param, param$1) {
                  var refundedAt = param.refundedAt;
                  if (refundedAt === undefined) {
                    return React.createElement(Badge$VannaFrontend.make, {
                                mode: /* Info */3,
                                text: "Devolvido"
                              });
                  }
                  var formattedDate = Format(new Date(refundedAt), "dd/MM/yyyy kk:mm");
                  return React.createElement(Badge$VannaFrontend.make, {
                              mode: /* Info */3,
                              text: "Devolvido",
                              label: "em " + formattedDate
                            });
                })),
          tl: /* [] */0
        };
        break;
    case /* RejectedByCustomer */4 :
        tmp = {
          hd: Table$VannaFrontend.column("Situação", undefined, (function (param, param$1) {
                  var rejectedAt = param.rejectedAt;
                  if (rejectedAt === undefined) {
                    return React.createElement(Badge$VannaFrontend.make, {
                                mode: /* Error */2,
                                text: "Rejeitado"
                              });
                  }
                  var formattedDate = Format(new Date(rejectedAt), "dd/MM/yyyy kk:mm");
                  return React.createElement(Badge$VannaFrontend.make, {
                              mode: /* Error */2,
                              text: "Rejeitado",
                              label: "em " + formattedDate
                            });
                })),
          tl: /* [] */0
        };
        break;
    
  }
  return React.createElement(CustomTable.make, {
              data: charges,
              columns: {
                hd: Table$VannaFrontend.column("Cliente", undefined, (function (charge, param) {
                        return Belt_Option.getWithDefault(charge.customer.name, "");
                      })),
                tl: {
                  hd: Table$VannaFrontend.column("Descrição", undefined, (function (charge, param) {
                          return Belt_Option.getWithDefault(charge.description, "");
                        })),
                  tl: {
                    hd: Table$VannaFrontend.column("Valor bruto", undefined, (function (charge, param) {
                            return Money$VannaFrontend.formatInt(charge.amount);
                          })),
                    tl: {
                      hd: Table$VannaFrontend.column("Taxa de transação", undefined, (function (charge, param) {
                              return Money$VannaFrontend.formatInt(charge.transactionCost);
                            })),
                      tl: {
                        hd: Table$VannaFrontend.column("Valor líquido", undefined, (function (charge, param) {
                                return Money$VannaFrontend.format(Money$VannaFrontend.$neg(Money$VannaFrontend.make({
                                                    TAG: /* Cents */1,
                                                    _0: charge.amount
                                                  }), Money$VannaFrontend.make({
                                                    TAG: /* Cents */1,
                                                    _0: charge.transactionCost
                                                  })));
                              })),
                        tl: {
                          hd: Table$VannaFrontend.column("Método de pagamento", undefined, (function (charge, param) {
                                  var match = charge.paymentMethod;
                                  if (match === "PIX") {
                                    return "PIX";
                                  } else if (match === "BOLETO") {
                                    return "Boleto";
                                  } else if (match === "CREDIT_CARD") {
                                    return "Cartão de crédito";
                                  } else {
                                    return "";
                                  }
                                })),
                          tl: tmp
                        }
                      }
                    }
                  }
                }
              },
              getItemId: (function (charge) {
                  return charge.id;
                }),
              onRowClick: kind === /* Paid */0 ? (function (chargeId) {
                    return Curry._1($$navigator.push, {
                                TAG: /* ChargeDetails */6,
                                _0: chargeId
                              });
                  }) : undefined,
              withPadding: false
            });
}

var make = ChargesTable;

exports.CustomTable = CustomTable;
exports.ChargeCancelMutation = ChargeCancelMutation;
exports.make = make;
/* CustomTable Not a pure module */
