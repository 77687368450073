'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Uuid = require("uuid");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var $$String = require("bs-platform/lib/js/string.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var BoletoJs = require("boleto.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var QrcodeReact = require("qrcode.react");
var ReactHelmet = require("react-helmet");
var RelayRuntime = require("relay-runtime");
var Format = require("date-fns/format");
var Box$VannaFrontend = require("../components/Box.bs.js");
var Hooks = require("react-relay/hooks");
var Link$VannaFrontend = require("../components/Link.bs.js");
var Text$VannaFrontend = require("../components/Text.bs.js");
var Badge$VannaFrontend = require("../components/Badge.bs.js");
var Commonjs = require("@fnando/cpf/commonjs");
var Boleto$VannaFrontend = require("../types/Boleto.bs.js");
var Button$VannaFrontend = require("../components/Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var Commonjs$1 = require("@fnando/cnpj/commonjs");
var TextField$VannaFrontend = require("../components/TextField.bs.js");
var BrandPanel$VannaFrontend = require("../components/BrandPanel.bs.js");
var ItemsTable$VannaFrontend = require("../components/ItemsTable.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var FieldSection$VannaFrontend = require("../components/FieldSection.bs.js");
var CopyableContent$VannaFrontend = require("../components/CopyableContent.bs.js");
var PageQueryRenderer$VannaFrontend = require("../components/PageQueryRenderer.bs.js");
var CreditCardReadOnly$VannaFrontend = require("../components/CreditCardReadOnly.bs.js");
var ReactPaymentInputs$VannaFrontend = require("../types/ReactPaymentInputs.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var ChargePaymentQuery_graphql$VannaFrontend = require("../__generated__/ChargePaymentQuery_graphql.bs.js");
var ChargePayment_AddSourceMutation_graphql$VannaFrontend = require("../__generated__/ChargePayment_AddSourceMutation_graphql.bs.js");

function get(state, field) {
  switch (field) {
    case /* HasTaxId */0 :
        return state.hasTaxId;
    case /* TypeOfPeople */1 :
        return state.typeOfPeople;
    case /* TaxId */2 :
        return state.taxId;
    case /* CreditCardHolderName */3 :
        return state.creditCardHolderName;
    case /* CreditCardNumber */4 :
        return state.creditCardNumber;
    case /* CreditCardExpiryDate */5 :
        return state.creditCardExpiryDate;
    case /* CreditCardCvc */6 :
        return state.creditCardCvc;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* HasTaxId */0 :
        return {
                hasTaxId: value,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* TypeOfPeople */1 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: value,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* TaxId */2 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: value,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* CreditCardHolderName */3 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: value,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* CreditCardNumber */4 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: value,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: state.creditCardCvc
              };
    case /* CreditCardExpiryDate */5 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: value,
                creditCardCvc: state.creditCardCvc
              };
    case /* CreditCardCvc */6 :
        return {
                hasTaxId: state.hasTaxId,
                typeOfPeople: state.typeOfPeople,
                taxId: state.taxId,
                creditCardHolderName: state.creditCardHolderName,
                creditCardNumber: state.creditCardNumber,
                creditCardExpiryDate: state.creditCardExpiryDate,
                creditCardCvc: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var CreditCardForm = ReForm.Make({
      set: set,
      get: get
    });

var textSection = Curry._1(Css.style, {
      hd: Css.marginBottom({
            NAME: "px",
            VAL: 20
          }),
      tl: /* [] */0
    });

var text = Curry._1(Css.style, {
      hd: Css.lineHeight({
            NAME: "px",
            VAL: 6
          }),
      tl: /* [] */0
    });

var qrCodeWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.padding({
                  NAME: "px",
                  VAL: 7
                }),
            tl: /* [] */0
          }
        }
      }
    });

var copyPasteWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.padding({
                    NAME: "px",
                    VAL: 15
                  }),
              tl: {
                hd: Css.width({
                      NAME: "percent",
                      VAL: 100
                    }),
                tl: {
                  hd: Css.wordBreak("breakAll"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var copyPasteContent = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.8
          }),
      tl: /* [] */0
    });

var Styles = {
  textSection: textSection,
  text: text,
  qrCodeWrapper: qrCodeWrapper,
  copyPasteWrapper: copyPasteWrapper,
  copyPasteContent: copyPasteContent
};

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(ChargePaymentQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ChargePaymentQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(ChargePaymentQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(ChargePaymentQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, ChargePaymentQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, ChargePaymentQuery_graphql$VannaFrontend.node, ChargePaymentQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: ChargePaymentQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, ChargePaymentQuery_graphql$VannaFrontend.node, ChargePaymentQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return ChargePaymentQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(ChargePaymentQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(ChargePaymentQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var ChargePaymentQuery_makeVariables = ChargePaymentQuery_graphql$VannaFrontend.Utils.makeVariables;

var ChargePaymentQuery = {
  makeVariables: ChargePaymentQuery_makeVariables,
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ChargePayment_AddSourceMutation_graphql$VannaFrontend.node,
              variables: ChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(ChargePayment_AddSourceMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ChargePayment_AddSourceMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var AddSourceMutation_make_creditCard = ChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_creditCard;

var AddSourceMutation_make_chargeAddSourceInput = ChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_chargeAddSourceInput;

var AddSourceMutation_makeVariables = ChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.makeVariables;

var AddSourceMutation_make_response_chargeAddSource_charge_boleto = ChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_response_chargeAddSource_charge_boleto;

var AddSourceMutation_make_response_chargeAddSource_charge_card = ChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_response_chargeAddSource_charge_card;

var AddSourceMutation_make_response_chargeAddSource_charge = ChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_response_chargeAddSource_charge;

var AddSourceMutation_make_response_chargeAddSource_error = ChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_response_chargeAddSource_error;

var AddSourceMutation_make_response_chargeAddSource = ChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.make_response_chargeAddSource;

var AddSourceMutation_makeOptimisticResponse = ChargePayment_AddSourceMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var AddSourceMutation = {
  make_creditCard: AddSourceMutation_make_creditCard,
  make_chargeAddSourceInput: AddSourceMutation_make_chargeAddSourceInput,
  makeVariables: AddSourceMutation_makeVariables,
  make_response_chargeAddSource_charge_boleto: AddSourceMutation_make_response_chargeAddSource_charge_boleto,
  make_response_chargeAddSource_charge_card: AddSourceMutation_make_response_chargeAddSource_charge_card,
  make_response_chargeAddSource_charge: AddSourceMutation_make_response_chargeAddSource_charge,
  make_response_chargeAddSource_error: AddSourceMutation_make_response_chargeAddSource_error,
  make_response_chargeAddSource: AddSourceMutation_make_response_chargeAddSource,
  makeOptimisticResponse: AddSourceMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

function ChargePayment$Inner(Props) {
  var chargeId = Props.chargeId;
  var queryData = use({
        id: chargeId
      }, /* StoreAndNetwork */2, undefined, undefined, {
        force: undefined,
        poll: 2000,
        liveConfigId: undefined,
        transactionId: undefined
      }, undefined);
  var match = use$1(undefined);
  var addSource = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setCardError = match$1[1];
  var cardError = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setHasSuccess = match$2[1];
  var charge = queryData.node;
  var tmp;
  if (charge !== undefined) {
    var match$3 = charge.customer;
    tmp = {
      hasTaxId: match$3 !== undefined ? match$3.taxId !== undefined : false,
      typeOfPeople: Belt_Option.mapWithDefault(charge.customer, /* Natural */0, (function (param) {
              if (param.typeOfPeople === "LEGAL") {
                return /* Legal */1;
              } else {
                return /* Natural */0;
              }
            })),
      taxId: Belt_Option.mapWithDefault(charge.customer, "", (function (param) {
              return Belt_Option.getWithDefault(param.taxId, "");
            })),
      creditCardHolderName: "",
      creditCardNumber: "",
      creditCardExpiryDate: "",
      creditCardCvc: ""
    };
  } else {
    tmp = {
      hasTaxId: false,
      typeOfPeople: /* Natural */0,
      taxId: "",
      creditCardHolderName: "",
      creditCardNumber: "",
      creditCardExpiryDate: "",
      creditCardCvc: ""
    };
  }
  var reform = Curry._7(CreditCardForm.use, tmp, /* Schema */{
        _0: Curry._2(CreditCardForm.ReSchema.Validation.$plus, Curry._3(CreditCardForm.ReSchema.Validation.nonEmpty, "Por favor, informe o nome no cartão", undefined, /* CreditCardHolderName */3), Curry._3(CreditCardForm.ReSchema.Validation.custom, (function (param) {
                    var taxId = param.taxId;
                    if (param.typeOfPeople) {
                      if (Commonjs$1.isValid(taxId)) {
                        return /* Valid */0;
                      } else {
                        return {
                                TAG: /* Error */1,
                                _0: "O CPF informado é inválido"
                              };
                      }
                    } else if (Commonjs.isValid(taxId)) {
                      return /* Valid */0;
                    } else {
                      return {
                              TAG: /* Error */1,
                              _0: "O CPF informado é inválido"
                            };
                    }
                  }), undefined, /* TaxId */2))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          var match = $$Array.map($$String.trim, state.values.creditCardExpiryDate.split("/"));
          var exit = 0;
          if (match.length !== 2) {
            exit = 1;
          } else {
            var expiryMonth = match[0];
            var expiryYear = match[1];
            if (cardError !== undefined) {
              exit = 1;
            } else {
              Curry.app(addSource, [
                    (function (param) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }),
                    (function (response, errors) {
                        if (errors !== undefined && errors.length !== 0) {
                          return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                        }
                        var match = response.chargeAddSource;
                        if (match === undefined) {
                          return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                        }
                        var match$1 = match.error;
                        if (match$1 !== undefined) {
                          return Curry._1(raiseSubmitFailed, match$1.message);
                        } else {
                          Curry._1(setHasSuccess, (function (param) {
                                  return true;
                                }));
                          return Curry._1(send, {
                                      TAG: /* SetFormState */10,
                                      _0: /* Dirty */0
                                    });
                        }
                      }),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    {
                      input: {
                        chargeId: chargeId,
                        creditCard: {
                          cardNumber: state.values.creditCardNumber,
                          cvc: state.values.creditCardCvc,
                          expirationMonth: expiryMonth,
                          expirationYear: expiryYear,
                          holderName: state.values.creditCardHolderName
                        },
                        taxId: state.values.taxId
                      }
                    },
                    undefined,
                    undefined
                  ]);
            }
          }
          if (exit === 1) {
            if (cardError !== undefined) {
              Curry._1(raiseSubmitFailed, cardError);
            } else {
              Curry._1(send, {
                    TAG: /* SetFormState */10,
                    _0: /* Dirty */0
                  });
            }
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var charge$1 = queryData.node;
  if (charge$1 === undefined) {
    return React.createElement(BrandPanel$VannaFrontend.make, {
                children: React.createElement(Text$VannaFrontend.make, {
                      children: "Cobrança não encontrada"
                    })
              });
  }
  var match$4 = charge$1.pixDynamicQrcode;
  var tmp$1;
  if (match$4 !== undefined) {
    var emvqrcps = match$4.emvqrcps;
    tmp$1 = emvqrcps !== undefined ? React.createElement("meta", {
            content: "https://gerarqrcodepix.com.br/api/v1?brcode=" + emvqrcps,
            property: "og:image"
          }) : null;
  } else {
    tmp$1 = null;
  }
  var description = charge$1.description;
  var date = charge$1.date;
  var match$5 = charge$1.status;
  var match$6 = charge$1.paymentMethod;
  var match$7 = charge$1.status;
  var match$8 = charge$1.paidAt;
  var match$9 = charge$1.status;
  var match$10 = charge$1.canceledAt;
  var match$11 = charge$1.status;
  var match$12 = charge$1.refundedAt;
  var match$13 = charge$1.status;
  var match$14 = charge$1.rejectedAt;
  var match$15 = charge$1.status;
  var match$16 = charge$1.paymentMethod;
  var tmp$2;
  if (match$15 !== undefined) {
    var exit = 0;
    if (match$15 === "AWAITING_PAYMENT") {
      if (match$16 !== undefined) {
        if (match$16 === "CREDIT_CARD") {
          var match$17 = reform.formState;
          var tmp$3;
          if (typeof match$17 === "number") {
            tmp$3 = null;
          } else {
            var error = match$17._0;
            tmp$3 = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
                    type_: /* Error */2,
                    title: "Ops!",
                    children: error
                  }) : null;
          }
          tmp$2 = React.createElement("form", {
                onSubmit: (function ($$event) {
                    $$event.preventDefault();
                    return Curry._1(reform.submit, undefined);
                  })
              }, React.createElement(FieldSection$VannaFrontend.make, {
                    children: React.createElement("strong", undefined, "Pagamento")
                  }), reform.state.values.hasTaxId ? null : React.createElement(CreditCardForm.Field.make, {
                      field: /* TaxId */2,
                      render: (function (param) {
                          var match = reform.state.values.typeOfPeople;
                          var match$1 = reform.state.values.typeOfPeople;
                          return React.createElement(TextField$VannaFrontend.make, {
                                      label: match ? "CNPJ" : "CPF",
                                      error: param.error,
                                      required: true,
                                      mask: match$1 ? "99.999.999/9999-99" : "999.999.999-99",
                                      value: param.value,
                                      onChange: param.handleChange
                                    });
                        })
                    }), React.createElement(CreditCardForm.Field.make, {
                    field: /* CreditCardHolderName */3,
                    render: (function (param) {
                        return React.createElement(TextField$VannaFrontend.make, {
                                    label: "Cartão de crédito",
                                    error: param.error,
                                    required: true,
                                    value: param.value,
                                    onChange: param.handleChange,
                                    placeholder: "Nome do titular do cartão"
                                  });
                      })
                  }), React.createElement(ReactPaymentInputs$VannaFrontend.make, {
                    handleChangeCardNumber: (function (value) {
                        return Curry._4(reform.setFieldValue, /* CreditCardNumber */4, value, undefined, undefined);
                      }),
                    handleChangeExpiryDate: (function (value) {
                        return Curry._4(reform.setFieldValue, /* CreditCardExpiryDate */5, value, undefined, undefined);
                      }),
                    handleChangeCvc: (function (value) {
                        return Curry._4(reform.setFieldValue, /* CreditCardCvc */6, value, undefined, undefined);
                      }),
                    setCardIsInvalid: (function (error) {
                        return Curry._1(setCardError, (function (param) {
                                      return error;
                                    }));
                      }),
                    values: {
                      cardNumber: reform.values.creditCardNumber,
                      cardExpirationDate: reform.values.creditCardExpiryDate,
                      cardCvc: reform.values.creditCardCvc
                    }
                  }), tmp$3, React.createElement(Button$VannaFrontend.make, {
                    isLoading: reform.isSubmitting,
                    type_: /* Submit */1,
                    children: "Pagar"
                  }));
        } else {
          exit = 1;
        }
      } else {
        tmp$2 = null;
      }
    } else if (match$15 === "PAID" || match$15 === "REFUNDED") {
      if (match$16 !== undefined) {
        if (match$16 === "CREDIT_CARD") {
          var match$18 = charge$1.card;
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement(FieldSection$VannaFrontend.make, {
                    children: React.createElement("strong", undefined, "Cartão de crédito")
                  }), match$18 !== undefined ? React.createElement(CreditCardReadOnly$VannaFrontend.make, {
                      brand: match$18.brand,
                      last4: match$18.last4
                    }) : null, match$2[0] ? React.createElement(Box$VannaFrontend.make, {
                      type_: /* Success */0,
                      title: "Sucesso",
                      children: "Pagamento realizado com sucesso"
                    }) : null);
        } else {
          exit = 1;
        }
      } else {
        tmp$2 = null;
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      if (match$16 !== undefined) {
        if (match$16 === "PIX") {
          if (match$15 !== "PAID") {
            var match$19 = charge$1.pixDynamicQrcode;
            var tmp$4;
            if (match$19 !== undefined) {
              var emvqrcps$1 = match$19.emvqrcps;
              tmp$4 = emvqrcps$1 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Text$VannaFrontend.make, {
                          children: "Utilize o QRCode abaixo para realizar o pagamento da fatura"
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.width({
                                      NAME: "percent",
                                      VAL: 100
                                    }),
                                tl: {
                                  hd: Css.children({
                                        hd: Css.width({
                                              NAME: "percent",
                                              VAL: 100
                                            }),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              })
                        }, React.createElement("div", {
                              className: qrCodeWrapper
                            }, React.createElement(QrcodeReact, {
                                  value: emvqrcps$1,
                                  size: 180
                                })), React.createElement("div", {
                              className: copyPasteWrapper
                            }, React.createElement(CopyableContent$VannaFrontend.make, {
                                  content: emvqrcps$1,
                                  children: React.createElement(Text$VannaFrontend.make, {
                                        className: copyPasteContent,
                                        children: emvqrcps$1
                                      })
                                })))) : null;
            } else {
              tmp$4 = null;
            }
            tmp$2 = React.createElement(React.Fragment, undefined, tmp$4);
          } else {
            tmp$2 = null;
          }
        } else if (match$16 === "BOLETO" && match$15 !== "PAID") {
          var match$20 = charge$1.boleto;
          var tmp$5;
          if (match$20 !== undefined) {
            var barcode = match$20.barcode;
            if (barcode !== undefined) {
              var url = match$20.url;
              tmp$5 = url !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Text$VannaFrontend.make, {
                          children: null
                        }, "Utilize o código de barras abaixo para realizar o pagamento da fatura ou acesse o boleto ", React.createElement(Link$VannaFrontend.Hyperlink.make, {
                              href: url,
                              target: "_blank",
                              children: "aqui."
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.width({
                                      NAME: "percent",
                                      VAL: 100
                                    }),
                                tl: {
                                  hd: Css.children({
                                        hd: Css.width({
                                              NAME: "percent",
                                              VAL: 100
                                            }),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          dangerouslySetInnerHTML: {
                            __html: new BoletoJs(Boleto$VannaFrontend.barcodeToBoletoNumber(barcode)).toSVG()
                          }
                        })) : null;
            } else {
              tmp$5 = null;
            }
          } else {
            tmp$5 = null;
          }
          tmp$2 = React.createElement(React.Fragment, undefined, tmp$5);
        } else {
          tmp$2 = null;
        }
      } else {
        tmp$2 = null;
      }
    }
    
  } else {
    tmp$2 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmet.Helmet, {
                  children: null
                }, React.createElement("meta", {
                      content: "Cobrança de " + Belt_Option.getWithDefault(charge$1.createdByName, "Vanna"),
                      name: "description"
                    }), React.createElement("meta", {
                      content: "Vanna",
                      property: "og:title"
                    }), React.createElement("meta", {
                      content: "Cobrança de " + Belt_Option.getWithDefault(charge$1.createdByName, "Vanna"),
                      property: "og:description"
                    }), tmp$1), React.createElement(BrandPanel$VannaFrontend.make, {
                  title: "Cobrança de " + Belt_Option.getWithDefault(charge$1.createdByName, "Vanna"),
                  children: null
                }, React.createElement("section", {
                      className: textSection
                    }, React.createElement(Text$VannaFrontend.make, {
                          className: text,
                          children: null
                        }, React.createElement("strong", undefined, "Identificador: "), Belt_Option.getWithDefault(charge$1.friendlyId, "-")), description !== undefined ? React.createElement(Text$VannaFrontend.make, {
                            className: text,
                            children: null
                          }, React.createElement("strong", undefined, "Descrição: "), description) : null, date !== undefined ? React.createElement(Text$VannaFrontend.make, {
                            className: text,
                            children: null
                          }, React.createElement("strong", undefined, "Data: "), Format(new Date(date), "dd/MM/yyyy kk:mm")) : null, React.createElement(Text$VannaFrontend.make, {
                          className: text,
                          children: React.createElement("strong", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginRight({
                                            NAME: "px",
                                            VAL: 10
                                          }),
                                      tl: /* [] */0
                                    })
                              }, "Situação: ")
                        }), match$5 !== undefined ? (
                        match$5 === "CANCELED" ? React.createElement(Badge$VannaFrontend.make, {
                                mode: /* Info */3,
                                text: "Cancelada"
                              }) : (
                            match$5 === "REJECTED_BY_CUSTOMER" ? React.createElement(Badge$VannaFrontend.make, {
                                    mode: /* Warn */4,
                                    text: "Rejeitada pelo cliente"
                                  }) : (
                                match$5 === "AWAITING_PAYMENT" ? React.createElement(Badge$VannaFrontend.make, {
                                        mode: /* Default */0,
                                        text: "Aguardando pagamento"
                                      }) : (
                                    match$5 === "REFUNDED" ? React.createElement(Badge$VannaFrontend.make, {
                                            mode: /* Info */3,
                                            text: "Estornado"
                                          }) : (
                                        match$5 === "PAID" ? React.createElement(Badge$VannaFrontend.make, {
                                                mode: /* Success */1,
                                                text: "Pago"
                                              }) : "-"
                                      )
                                  )
                              )
                          )
                      ) : "-", React.createElement(Text$VannaFrontend.make, {
                          className: text,
                          children: null
                        }, React.createElement("strong", undefined, "Método de pagamento: "), match$6 !== undefined ? (
                            match$6 === "PIX" ? "PIX" : (
                                match$6 === "BOLETO" ? "Boleto" : (
                                    match$6 === "CREDIT_CARD" ? "Cartão de crédito" : "-"
                                  )
                              )
                          ) : "-"), match$7 !== undefined && match$7 === "PAID" && match$8 !== undefined ? React.createElement(Text$VannaFrontend.make, {
                            className: text,
                            children: null
                          }, React.createElement("strong", undefined, "Paga em "), Format(new Date(match$8), "dd/MM/yyyy kk:mm")) : null, match$9 !== undefined && match$9 === "CANCELED" && match$10 !== undefined ? React.createElement(Text$VannaFrontend.make, {
                            className: text,
                            children: React.createElement("strong", undefined, "Cancelada em ", Format(new Date(match$10), "dd/MM/yyyy kk:mm"))
                          }) : null, match$11 !== undefined && match$11 === "REFUNDED" && match$12 !== undefined ? React.createElement(Text$VannaFrontend.make, {
                            className: text,
                            children: null
                          }, React.createElement("strong", undefined, "Estornada em "), Format(new Date(match$12), "dd/MM/yyyy kk:mm")) : null, match$13 !== undefined && match$13 === "REJECTED_BY_CUSTOMER" && match$14 !== undefined ? React.createElement(Text$VannaFrontend.make, {
                            className: text,
                            children: null
                          }, React.createElement("strong", undefined, "Rejeitada em "), Format(new Date(match$14), "dd/MM/yyyy kk:mm")) : null), React.createElement(ItemsTable$VannaFrontend.make, {
                      title: "",
                      data: Belt_Array.map(Belt_Option.getWithDefault(charge$1.items, []), (function (item) {
                              return {
                                      name: item.name,
                                      price: item.price,
                                      quantity: item.quantity,
                                      freightAmount: item.freightAmount,
                                      insuranceAmount: item.insuranceAmount,
                                      othersAmount: item.othersAmount,
                                      discountAmount: item.discountAmount,
                                      itemId: item.id,
                                      temporaryId: Uuid.v4(),
                                      maxQuantity: 0,
                                      hasMaxQuantity: false,
                                      chargeType: /* Single */0
                                    };
                            })),
                      mode: /* Small */1,
                      readOnly: true,
                      domain: /* Charge */0
                    }), React.createElement(CreditCardForm.Provider.make, Curry._3(CreditCardForm.Provider.makeProps, reform, tmp$2, undefined))));
}

var Inner = {
  make: ChargePayment$Inner
};

function ChargePayment(Props) {
  var $$navigator = Props.navigator;
  var chargeId = Props.chargeId;
  return React.createElement(PageQueryRenderer$VannaFrontend.make, {
              children: React.createElement(ChargePayment$Inner, {
                    navigator: $$navigator,
                    chargeId: chargeId
                  })
            });
}

var make = ChargePayment;

exports.StateLenses = StateLenses;
exports.CreditCardForm = CreditCardForm;
exports.Styles = Styles;
exports.ChargePaymentQuery = ChargePaymentQuery;
exports.AddSourceMutation = AddSourceMutation;
exports.Inner = Inner;
exports.make = make;
/* CreditCardForm Not a pure module */
