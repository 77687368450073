'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Color = require("color");
var React = require("react");
var Css_AtomicTypes = require("bs-css/src/Css_AtomicTypes.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

var badgeWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                hd: Css.flexDirection("column"),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

function badgeComp(mode) {
  var tmp;
  switch (mode) {
    case /* Default */0 :
        tmp = Theme$VannaFrontend.Colors.$$default;
        break;
    case /* Success */1 :
        tmp = Theme$VannaFrontend.Colors.success;
        break;
    case /* Error */2 :
        tmp = Theme$VannaFrontend.Colors.error;
        break;
    case /* Info */3 :
        tmp = Theme$VannaFrontend.Colors.info;
        break;
    case /* Warn */4 :
        tmp = Theme$VannaFrontend.Colors.warn;
        break;
    
  }
  var tmp$1;
  switch (mode) {
    case /* Default */0 :
        tmp$1 = Theme$VannaFrontend.Colors.$$default;
        break;
    case /* Success */1 :
        tmp$1 = Theme$VannaFrontend.Colors.success;
        break;
    case /* Error */2 :
        tmp$1 = Theme$VannaFrontend.Colors.error;
        break;
    case /* Info */3 :
        tmp$1 = Theme$VannaFrontend.Colors.info;
        break;
    case /* Warn */4 :
        tmp$1 = Theme$VannaFrontend.Colors.warn;
        break;
    
  }
  return Curry._1(Css.style, {
              hd: Css.padding2({
                    NAME: "px",
                    VAL: 3
                  }, {
                    NAME: "px",
                    VAL: 10
                  }),
              tl: {
                hd: Css.borderRadius({
                      NAME: "px",
                      VAL: 4
                    }),
                tl: {
                  hd: Css.fontSize({
                        NAME: "rem",
                        VAL: 0.75
                      }),
                  tl: {
                    hd: Css.unsafe("backgroundColor", new Color(Css_AtomicTypes.Color.toString(tmp)).fade(0.7).string()),
                    tl: {
                      hd: Css.unsafe("color", new Color(Css_AtomicTypes.Color.toString(tmp$1)).desaturate(0.09).string()),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var badgeLabel = Curry._1(Css.style, {
      hd: Css.marginLeft({
            NAME: "px",
            VAL: 1
          }),
      tl: {
        hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
              hd: Css.textAlign("center"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  badgeWrapper: badgeWrapper,
  badgeComp: badgeComp,
  badgeLabel: badgeLabel
};

function Badge(Props) {
  var mode = Props.mode;
  var text = Props.text;
  var label = Props.label;
  return React.createElement("div", {
              className: badgeWrapper
            }, React.createElement("div", {
                  className: badgeComp(mode)
                }, text), label !== undefined ? React.createElement("div", {
                    className: badgeLabel
                  }, label) : null);
}

var make = Badge;

exports.Styles = Styles;
exports.make = make;
/* badgeWrapper Not a pure module */
