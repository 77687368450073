'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var TextField$VannaFrontend = require("./TextField.bs.js");

function DateField(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var error = Props.error;
  var required = Props.required;
  return React.createElement(TextField$VannaFrontend.make, {
              label: label,
              error: error,
              type_: /* Date */6,
              required: required,
              value: value,
              onChange: (function (value) {
                  var match = $$Array.map(Caml_format.caml_float_of_string, value.split("-"));
                  if (match.length !== 3) {
                    return ;
                  }
                  var year = match[0];
                  var month = match[1];
                  var day = match[2];
                  return Curry._1(onChange, new Date(year, month - 1, day).toISOString());
                })
            });
}

var make = DateField;

exports.make = make;
/* react Not a pure module */
