'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Fi = require("react-icons/fi");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Spinner$VannaFrontend = require("./Spinner.bs.js");

var confirmationIcon = Curry._1(Css.style, {
      hd: Css.color(Theme$VannaFrontend.Colors.success),
      tl: /* [] */0
    });

function iconButton(highlight) {
  return Curry._1(Css.style, {
              hd: Css.backgroundColor("transparent"),
              tl: {
                hd: Css.opacity(highlight ? 1.0 : 0.5),
                tl: {
                  hd: Css.color(Theme$VannaFrontend.Colors.text),
                  tl: {
                    hd: Css.fontSize("inherit_"),
                    tl: {
                      hd: Css.borderRadius({
                            NAME: "percent",
                            VAL: 50.0
                          }),
                      tl: {
                        hd: Css.padding({
                              NAME: "px",
                              VAL: 10
                            }),
                        tl: {
                          hd: Css.borderStyle("none"),
                          tl: {
                            hd: Css.display("inlineFlex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.outlineStyle("none"),
                                  tl: {
                                    hd: Css.not__(":disabled", {
                                          hd: Css.cursor("pointer"),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.opacity(1.0),
                                                  tl: {
                                                    hd: Css.backgroundColor(Theme$VannaFrontend.Colors.secondary),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Styles = {
  confirmationIcon: confirmationIcon,
  iconButton: iconButton
};

function getIcon(isLoading, isConfirming, children) {
  if (isLoading) {
    return React.createElement(Spinner$VannaFrontend.make, {});
  } else if (isConfirming) {
    return React.createElement(Fi.FiCheck, {
                className: confirmationIcon
              });
  } else {
    return children;
  }
}

function IconButton(Props) {
  var onClick = Props.onClick;
  var disabledOpt = Props.disabled;
  var classNameOpt = Props.className;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var isLoading = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setConfirming = match$1[1];
  var isConfirming = match$1[0];
  return React.createElement("button", {
              className: Curry._1(Css.merge, {
                    hd: iconButton(isLoading || isConfirming),
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  }),
              disabled: disabled,
              type: "button",
              onClick: (function ($$event) {
                  return Curry._3(onClick, $$event, setLoading, setConfirming);
                })
            }, getIcon(isLoading, isConfirming, children));
}

var make = IconButton;

exports.Styles = Styles;
exports.getIcon = getIcon;
exports.make = make;
/* confirmationIcon Not a pure module */
