'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

function unwrap_response_selfCheckoutCreate_selfCheckout_items_item(u) {
  var v = u.__typename;
  switch (v) {
    case "Product" :
        return {
                NAME: "Product",
                VAL: u
              };
    case "Service" :
        return {
                NAME: "Service",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_response_selfCheckoutCreate_selfCheckout_items_item(param) {
  if (param.NAME === "UnselectedUnionMember") {
    return {
            __typename: param.VAL
          };
  } else {
    return param.VAL;
  }
}

var wrapResponseConverter = {"__root":{"selfCheckoutCreate_selfCheckout_items_maxQuantity":{"n":""},"selfCheckoutCreate_selfCheckout_snippets":{"n":""},"selfCheckoutCreate_selfCheckout_memberkitWebhook":{"n":""},"selfCheckoutCreate_selfCheckout_bannerLink":{"n":""},"selfCheckoutCreate_selfCheckout_memberkitHottoken":{"n":""},"selfCheckoutCreate_error":{"n":""},"selfCheckoutCreate_selfCheckout_description":{"n":""},"selfCheckoutCreate":{"n":""},"selfCheckoutCreate_selfCheckout_items_item":{"u":"response_selfCheckoutCreate_selfCheckout_items_item"},"selfCheckoutCreate_selfCheckout":{"n":""}}};

var wrapResponseConverterMap = {
  response_selfCheckoutCreate_selfCheckout_items_item: wrap_response_selfCheckoutCreate_selfCheckout_items_item
};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"selfCheckoutCreate_selfCheckout_items_maxQuantity":{"n":""},"selfCheckoutCreate_selfCheckout_snippets":{"n":""},"selfCheckoutCreate_selfCheckout_memberkitWebhook":{"n":""},"selfCheckoutCreate_selfCheckout_bannerLink":{"n":""},"selfCheckoutCreate_selfCheckout_memberkitHottoken":{"n":""},"selfCheckoutCreate_error":{"n":""},"selfCheckoutCreate_selfCheckout_description":{"n":""},"selfCheckoutCreate":{"n":""},"selfCheckoutCreate_selfCheckout_items_item":{"u":"response_selfCheckoutCreate_selfCheckout_items_item"},"selfCheckoutCreate_selfCheckout":{"n":""}}};

var responseConverterMap = {
  response_selfCheckoutCreate_selfCheckout_items_item: unwrap_response_selfCheckoutCreate_selfCheckout_items_item
};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"SelfCheckoutCreateInput"}},"SelfCheckoutCreateInput":{"memberkitWebhook":{"n":""},"items":{"r":"SelfCheckoutItem"},"affiliateId":{"n":""},"snippets":{"n":""},"affiliatePercentage":{"n":""},"memberkitHottoken":{"n":""},"bannerLink":{"n":""}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_selfCheckoutItem(chargeType, hasMaxQuantity, itemId, maxQuantity, type_) {
  return {
          chargeType: chargeType,
          hasMaxQuantity: hasMaxQuantity,
          itemId: itemId,
          maxQuantity: maxQuantity,
          type: type_
        };
}

function make_selfCheckoutCreateInput(affiliateId, affiliatePercentage, bannerLink, description, hasMemberkitIntegration, items, memberkitHottoken, memberkitWebhook, snippets, param) {
  return {
          affiliateId: affiliateId,
          affiliatePercentage: affiliatePercentage,
          bannerLink: bannerLink,
          description: description,
          hasMemberkitIntegration: hasMemberkitIntegration,
          items: items,
          memberkitHottoken: memberkitHottoken,
          memberkitWebhook: memberkitWebhook,
          snippets: snippets
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_selfCheckoutCreate_selfCheckout_items(chargeType, hasMaxQuantity, maxQuantity, item, type_, param) {
  return {
          chargeType: chargeType,
          hasMaxQuantity: hasMaxQuantity,
          maxQuantity: maxQuantity,
          item: item,
          type_: type_
        };
}

function make_response_selfCheckoutCreate_selfCheckout(id, description, friendlyId, bannerLink, snippets, hasMemberkitIntegration, memberkitHottoken, memberkitWebhook, items, param) {
  return {
          id: id,
          description: description,
          friendlyId: friendlyId,
          bannerLink: bannerLink,
          snippets: snippets,
          hasMemberkitIntegration: hasMemberkitIntegration,
          memberkitHottoken: memberkitHottoken,
          memberkitWebhook: memberkitWebhook,
          items: items
        };
}

function make_response_selfCheckoutCreate_error(message) {
  return {
          message: message
        };
}

function make_response_selfCheckoutCreate(error, selfCheckout, param) {
  return {
          error: error,
          selfCheckout: selfCheckout
        };
}

function makeOptimisticResponse(selfCheckoutCreate, param) {
  return {
          selfCheckoutCreate: selfCheckoutCreate
        };
}

var Utils = {
  make_selfCheckoutItem: make_selfCheckoutItem,
  make_selfCheckoutCreateInput: make_selfCheckoutCreateInput,
  makeVariables: makeVariables,
  make_response_selfCheckoutCreate_selfCheckout_items: make_response_selfCheckoutCreate_selfCheckout_items,
  make_response_selfCheckoutCreate_selfCheckout: make_response_selfCheckoutCreate_selfCheckout,
  make_response_selfCheckoutCreate_error: make_response_selfCheckoutCreate_error,
  make_response_selfCheckoutCreate: make_response_selfCheckoutCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friendlyId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bannerLink",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "snippets",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasMemberkitIntegration",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberkitHottoken",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberkitWebhook",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chargeType",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasMaxQuantity",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxQuantity",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "price",
    "storageKey": null
  }
],
v16 = {
  "kind": "InlineFragment",
  "selections": (v15/*: any*/),
  "type": "Product",
  "abstractKey": null
},
v17 = {
  "kind": "InlineFragment",
  "selections": (v15/*: any*/),
  "type": "Service",
  "abstractKey": null
},
v18 = {
  "alias": "type_",
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfCheckoutUpsertForm_SelfCheckoutCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfCheckoutCreatePayload",
        "kind": "LinkedField",
        "name": "selfCheckoutCreate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfCheckout",
            "kind": "LinkedField",
            "name": "selfCheckout",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SelfCheckoutItemType",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfCheckoutUpsertForm_SelfCheckoutCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfCheckoutCreatePayload",
        "kind": "LinkedField",
        "name": "selfCheckoutCreate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfCheckout",
            "kind": "LinkedField",
            "name": "selfCheckout",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SelfCheckoutItemType",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16ce73505d04de4df388493b38f44958",
    "id": null,
    "metadata": {},
    "name": "SelfCheckoutUpsertForm_SelfCheckoutCreateMutation",
    "operationKind": "mutation",
    "text": "mutation SelfCheckoutUpsertForm_SelfCheckoutCreateMutation(\n  $input: SelfCheckoutCreateInput!\n) {\n  selfCheckoutCreate(input: $input) {\n    error {\n      message\n    }\n    selfCheckout {\n      id\n      description\n      friendlyId\n      bannerLink\n      snippets\n      hasMemberkitIntegration\n      memberkitHottoken\n      memberkitWebhook\n      items {\n        chargeType\n        hasMaxQuantity\n        maxQuantity\n        item {\n          ... on Product {\n            id\n            name\n            price\n          }\n          ... on Service {\n            id\n            name\n            price\n          }\n          __typename\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n        type_: type\n      }\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.unwrap_response_selfCheckoutCreate_selfCheckout_items_item = unwrap_response_selfCheckoutCreate_selfCheckout_items_item;
exports.wrap_response_selfCheckoutCreate_selfCheckout_items_item = wrap_response_selfCheckoutCreate_selfCheckout_items_item;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
