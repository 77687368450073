'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Fi = require("react-icons/fi");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("../components/Modal.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var Table$VannaFrontend = require("../components/Table.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var IconButton$VannaFrontend = require("../components/IconButton.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var DeleteModal$VannaFrontend = require("../components/DeleteModal.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var ServiceUpsertForm$VannaFrontend = require("../components/ServiceUpsertForm.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var ServicesQuery_graphql$VannaFrontend = require("../__generated__/ServicesQuery_graphql.bs.js");
var ServicesRefetchQuery_graphql$VannaFrontend = require("../__generated__/ServicesRefetchQuery_graphql.bs.js");
var ServicesConnection_query_graphql$VannaFrontend = require("../__generated__/ServicesConnection_query_graphql.bs.js");
var Services_ServiceDeleteMutation_graphql$VannaFrontend = require("../__generated__/Services_ServiceDeleteMutation_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.width("auto"),
      tl: /* [] */0
    });

var Styles = {
  panel: panel
};

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(ServicesQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ServicesQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(ServicesQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(ServicesQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, ServicesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, ServicesQuery_graphql$VannaFrontend.node, ServicesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: ServicesQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, ServicesQuery_graphql$VannaFrontend.node, ServicesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return ServicesQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(ServicesQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(ServicesQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var ServicesQuery = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var getConnectionNodes = ServicesConnection_query_graphql$VannaFrontend.Utils.getConnectionNodes;

var Internal$1 = {};

function internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = ReasonRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = ReasonRelay.mapRenderPolicy(renderPolicy);
  if (tmp$2 !== undefined) {
    tmp.UNSTABLE_renderPolicy = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$3 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var InternalRefetch = {
  internal_makeRefetchableFnOpts: internal_makeRefetchableFnOpts
};

function useRefetchable(fRef) {
  var match = Hooks.useRefetchableFragment(ServicesConnection_query_graphql$VannaFrontend.node, fRef);
  var refetchFn = match[1];
  var data = ReasonRelay_Internal.internal_useConvertedValue(ServicesConnection_query_graphql$VannaFrontend.Internal.convertFragment, match[0]);
  return [
          data,
          (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return Curry._2(refetchFn, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ServicesRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        ];
}

function use$1(fRef) {
  var data = Hooks.useFragment(ServicesConnection_query_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ServicesConnection_query_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ServicesConnection_query_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ServicesConnection_query_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function usePagination(fr) {
  var p = Hooks.usePaginationFragment(ServicesConnection_query_graphql$VannaFrontend.node, fr);
  var data = ReasonRelay_Internal.internal_useConvertedValue(ServicesConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ServicesRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

function useBlockingPagination(fRef) {
  var p = Hooks.useBlockingPaginationFragment(ServicesConnection_query_graphql$VannaFrontend.node, fRef);
  var data = ReasonRelay_Internal.internal_useConvertedValue(ServicesConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(ServicesRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

var makeRefetchVariables = ServicesRefetchQuery_graphql$VannaFrontend.Types.makeRefetchVariables;

var ServicesConnectionFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Internal: Internal$1,
  InternalRefetch: InternalRefetch,
  useRefetchable: useRefetchable,
  use: use$1,
  useOpt: useOpt,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination,
  makeRefetchVariables: makeRefetchVariables
};

var Internal$2 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: Services_ServiceDeleteMutation_graphql$VannaFrontend.node,
              variables: Services_ServiceDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, Services_ServiceDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? Services_ServiceDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, Services_ServiceDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(Services_ServiceDeleteMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, Services_ServiceDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? Services_ServiceDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, Services_ServiceDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: Services_ServiceDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var DeleteServiceMutation_make_serviceDeleteInput = Services_ServiceDeleteMutation_graphql$VannaFrontend.Utils.make_serviceDeleteInput;

var DeleteServiceMutation_makeVariables = Services_ServiceDeleteMutation_graphql$VannaFrontend.Utils.makeVariables;

var DeleteServiceMutation_make_response_serviceDelete_error = Services_ServiceDeleteMutation_graphql$VannaFrontend.Utils.make_response_serviceDelete_error;

var DeleteServiceMutation_make_response_serviceDelete = Services_ServiceDeleteMutation_graphql$VannaFrontend.Utils.make_response_serviceDelete;

var DeleteServiceMutation_makeOptimisticResponse = Services_ServiceDeleteMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var DeleteServiceMutation = {
  make_serviceDeleteInput: DeleteServiceMutation_make_serviceDeleteInput,
  makeVariables: DeleteServiceMutation_makeVariables,
  make_response_serviceDelete_error: DeleteServiceMutation_make_response_serviceDelete_error,
  make_response_serviceDelete: DeleteServiceMutation_make_response_serviceDelete,
  makeOptimisticResponse: DeleteServiceMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation,
  use: use$2
};

var ServicesTable = Table$VannaFrontend.Make({});

function Services$Inner(Props) {
  var queryRef = Props.query;
  var match = use$2(undefined);
  var isDelettingService = match[1];
  var deleteService = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setDeleteError = match$1[1];
  var deleteError = match$1[0];
  var match$2 = usePagination(queryRef);
  var loadNext = match$2.loadNext;
  var services = Curry._1(getConnectionNodes, match$2.data.services);
  return React.createElement(React.Fragment, undefined, React.createElement(PageHeader$VannaFrontend.make, {
                  title: "Serviços",
                  upsertModal: {
                    renderModal: (function (dispatch) {
                        return React.createElement(ServiceUpsertForm$VannaFrontend.make, {
                                    dispatchModal: dispatch
                                  });
                      }),
                    displayName: "serviço"
                  }
                }), React.createElement(Panel$VannaFrontend.make, {
                  className: panel,
                  children: React.createElement(ServicesTable.make, {
                        data: services,
                        columns: {
                          hd: Table$VannaFrontend.column("Nome", undefined, (function (service, param) {
                                  return service.name;
                                })),
                          tl: {
                            hd: Table$VannaFrontend.column("Descrição", undefined, (function (service, param) {
                                    return Belt_Option.getWithDefault(service.description, "");
                                  })),
                            tl: {
                              hd: Table$VannaFrontend.column("Preço", undefined, (function (service, param) {
                                      return Money$VannaFrontend.format(Money$VannaFrontend.make({
                                                      TAG: /* Cents */1,
                                                      _0: service.price
                                                    }));
                                    })),
                              tl: {
                                hd: Table$VannaFrontend.column("", undefined, (function (service, param) {
                                        return React.createElement(Modal$VannaFrontend.make, {
                                                    title: "Atualizar serviço",
                                                    trigger: (function (dispatch) {
                                                        return React.createElement(IconButton$VannaFrontend.make, {
                                                                    onClick: (function (param, param$1, param$2) {
                                                                        return Curry._1(dispatch, /* Open */2);
                                                                      }),
                                                                    children: React.createElement(Fi.FiEdit3, {})
                                                                  });
                                                      }),
                                                    content: (function (dispatch) {
                                                        return React.createElement(ServiceUpsertForm$VannaFrontend.make, {
                                                                    service: Caml_option.some(service.fragmentRefs),
                                                                    dispatchModal: dispatch
                                                                  });
                                                      })
                                                  });
                                      })),
                                tl: {
                                  hd: Table$VannaFrontend.column("", undefined, (function (service, param) {
                                          return React.createElement(DeleteModal$VannaFrontend.make, {
                                                      displayName: "serviço",
                                                      onConfirm: (function (dispatch) {
                                                          Curry._1(setDeleteError, (function (param) {
                                                                  
                                                                }));
                                                          Curry.app(deleteService, [
                                                                (function (param) {
                                                                    return Curry._1(setDeleteError, (function (param) {
                                                                                  return "Algo deu errado, tente novamente";
                                                                                }));
                                                                  }),
                                                                (function (response, _errors) {
                                                                    var match = response.serviceDelete;
                                                                    if (match === undefined) {
                                                                      return Curry._1(setDeleteError, (function (param) {
                                                                                    return "Algo deu errado, tente novamente";
                                                                                  }));
                                                                    }
                                                                    var match$1 = match.error;
                                                                    if (match$1 === undefined) {
                                                                      return Curry._1(dispatch, /* Close */1);
                                                                    }
                                                                    var message = match$1.message;
                                                                    return Curry._1(setDeleteError, (function (param) {
                                                                                  return message;
                                                                                }));
                                                                  }),
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                (function (store, response) {
                                                                    var match = response.serviceDelete;
                                                                    if (match === undefined) {
                                                                      return ;
                                                                    }
                                                                    if (match.error !== undefined) {
                                                                      return ;
                                                                    }
                                                                    var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                                                                    if (meProxy == null) {
                                                                      return ;
                                                                    }
                                                                    var servicesConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "Services_services", undefined);
                                                                    if (!(servicesConnection == null)) {
                                                                      RelayRuntime.ConnectionHandler.deleteNode(servicesConnection, service.id);
                                                                      return ;
                                                                    }
                                                                    
                                                                  }),
                                                                {
                                                                  input: {
                                                                    serviceId: service.id
                                                                  }
                                                                },
                                                                undefined,
                                                                undefined
                                                              ]);
                                                          
                                                        }),
                                                      onClose: (function (param) {
                                                          return Curry._1(setDeleteError, (function (param) {
                                                                        
                                                                      }));
                                                        }),
                                                      isLoading: isDelettingService,
                                                      error: deleteError
                                                    });
                                        })),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        },
                        getItemId: (function (service) {
                            return service.id;
                          }),
                        hasNextPage: match$2.hasNext,
                        loadMore: (function (param) {
                            Curry._3(loadNext, 20, undefined, undefined);
                            
                          })
                      })
                }));
}

var Inner = {
  make: Services$Inner
};

function Services(Props) {
  var $$navigator = Props.navigator;
  var queryData = use(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var me = queryData.me;
  if (me !== undefined) {
    return React.createElement(PrivateLayout$VannaFrontend.make, {
                navigator: $$navigator,
                children: React.createElement(Services$Inner, {
                      navigator: $$navigator,
                      query: me.fragmentRefs
                    })
              });
  } else {
    return null;
  }
}

var pageSize = 20;

var make = Services;

exports.Styles = Styles;
exports.ServicesQuery = ServicesQuery;
exports.pageSize = pageSize;
exports.ServicesConnectionFragment = ServicesConnectionFragment;
exports.DeleteServiceMutation = DeleteServiceMutation;
exports.ServicesTable = ServicesTable;
exports.Inner = Inner;
exports.make = make;
/* panel Not a pure module */
