'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var FieldSection$VannaFrontend = require("./FieldSection.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var WhitelabelInfoFormFragment_graphql$VannaFrontend = require("../__generated__/WhitelabelInfoFormFragment_graphql.bs.js");
var WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend = require("../__generated__/WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

var panel = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 50
          }),
      tl: {
        hd: Css.padding(Theme$VannaFrontend.Padding.content),
        tl: {
          hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                hd: Css.width({
                      NAME: "percent",
                      VAL: 100
                    }),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  panel: panel
};

function get(state, field) {
  switch (field) {
    case /* ProductName */0 :
        return state.productName;
    case /* ProductLink */1 :
        return state.productLink;
    case /* Title */2 :
        return state.title;
    case /* LogoSrc */3 :
        return state.logoSrc;
    case /* IconSrc */4 :
        return state.iconSrc;
    case /* EmailLogo */5 :
        return state.emailLogo;
    case /* EmailTeam */6 :
        return state.emailTeam;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* ProductName */0 :
        return {
                productName: value,
                productLink: state.productLink,
                title: state.title,
                logoSrc: state.logoSrc,
                iconSrc: state.iconSrc,
                emailLogo: state.emailLogo,
                emailTeam: state.emailTeam
              };
    case /* ProductLink */1 :
        return {
                productName: state.productName,
                productLink: value,
                title: state.title,
                logoSrc: state.logoSrc,
                iconSrc: state.iconSrc,
                emailLogo: state.emailLogo,
                emailTeam: state.emailTeam
              };
    case /* Title */2 :
        return {
                productName: state.productName,
                productLink: state.productLink,
                title: value,
                logoSrc: state.logoSrc,
                iconSrc: state.iconSrc,
                emailLogo: state.emailLogo,
                emailTeam: state.emailTeam
              };
    case /* LogoSrc */3 :
        return {
                productName: state.productName,
                productLink: state.productLink,
                title: state.title,
                logoSrc: value,
                iconSrc: state.iconSrc,
                emailLogo: state.emailLogo,
                emailTeam: state.emailTeam
              };
    case /* IconSrc */4 :
        return {
                productName: state.productName,
                productLink: state.productLink,
                title: state.title,
                logoSrc: state.logoSrc,
                iconSrc: value,
                emailLogo: state.emailLogo,
                emailTeam: state.emailTeam
              };
    case /* EmailLogo */5 :
        return {
                productName: state.productName,
                productLink: state.productLink,
                title: state.title,
                logoSrc: state.logoSrc,
                iconSrc: state.iconSrc,
                emailLogo: value,
                emailTeam: state.emailTeam
              };
    case /* EmailTeam */6 :
        return {
                productName: state.productName,
                productLink: state.productLink,
                title: state.title,
                logoSrc: state.logoSrc,
                iconSrc: state.iconSrc,
                emailLogo: state.emailLogo,
                emailTeam: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var CustomizationsUpdateForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.node,
              variables: WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = RelayExperimental.useMutation(WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var WhitelabelInfoUpdateMutation_make_whitelabelInfoUpdateInput = WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Utils.make_whitelabelInfoUpdateInput;

var WhitelabelInfoUpdateMutation_makeVariables = WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var WhitelabelInfoUpdateMutation_make_response_whitelabelInfoUpdate_error = WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Utils.make_response_whitelabelInfoUpdate_error;

var WhitelabelInfoUpdateMutation_make_response_whitelabelInfoUpdate = WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Utils.make_response_whitelabelInfoUpdate;

var WhitelabelInfoUpdateMutation_makeOptimisticResponse = WhitelabelInfoForm_WhitelabelInfoUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var WhitelabelInfoUpdateMutation = {
  make_whitelabelInfoUpdateInput: WhitelabelInfoUpdateMutation_make_whitelabelInfoUpdateInput,
  makeVariables: WhitelabelInfoUpdateMutation_makeVariables,
  make_response_whitelabelInfoUpdate_error: WhitelabelInfoUpdateMutation_make_response_whitelabelInfoUpdate_error,
  make_response_whitelabelInfoUpdate: WhitelabelInfoUpdateMutation_make_response_whitelabelInfoUpdate,
  makeOptimisticResponse: WhitelabelInfoUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal,
  commitMutation: commitMutation,
  use: use
};

var Internal$1 = {};

function use$1(fRef) {
  var data = Hooks.useFragment(WhitelabelInfoFormFragment_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(WhitelabelInfoFormFragment_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(WhitelabelInfoFormFragment_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return WhitelabelInfoFormFragment_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var WhitelabelInfoFormFragment = {
  Types: undefined,
  Internal: Internal$1,
  use: use$1,
  useOpt: useOpt
};

function WhitelabelInfoForm(Props) {
  var fragment = Props.fragment;
  var queryData = use$1(fragment);
  var match = use(undefined);
  var whitelabelInfoUpdate = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setSuccess = match$1[1];
  var reform = Curry._7(CustomizationsUpdateForm.use, {
        productName: Belt_Option.getWithDefault(queryData.settings.whitelabelInfo.productName, ""),
        productLink: Belt_Option.getWithDefault(queryData.settings.whitelabelInfo.productLink, ""),
        title: Belt_Option.getWithDefault(queryData.settings.whitelabelInfo.title, ""),
        logoSrc: Belt_Option.getWithDefault(queryData.settings.whitelabelInfo.logoSrc, ""),
        iconSrc: Belt_Option.getWithDefault(queryData.settings.whitelabelInfo.iconSrc, ""),
        emailLogo: Belt_Option.getWithDefault(queryData.settings.whitelabelInfo.emailLogo, ""),
        emailTeam: Belt_Option.getWithDefault(queryData.settings.whitelabelInfo.emailTeam, "")
      }, /* Schema */{
        _0: Curry._3(CustomizationsUpdateForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* ProductName */0)
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          Curry.app(whitelabelInfoUpdate, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, errors) {
                    if (errors !== undefined && errors.length !== 0) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match = response.whitelabelInfoUpdate;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    } else {
                      Curry._1(send, {
                            TAG: /* SetFormState */10,
                            _0: /* Dirty */0
                          });
                      return Curry._1(setSuccess, (function (param) {
                                    return true;
                                  }));
                    }
                  }),
                undefined,
                undefined,
                undefined,
                undefined,
                {
                  input: {
                    emailLogo: state.values.emailLogo,
                    emailTeam: state.values.emailTeam,
                    iconSrc: state.values.iconSrc,
                    logoSrc: state.values.logoSrc,
                    productLink: state.values.productLink,
                    productName: state.values.productName,
                    title: state.values.title
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$2 = reform.formState;
  var tmp;
  if (match$1[0]) {
    tmp = React.createElement(Box$VannaFrontend.make, {
          type_: /* Success */0,
          title: "Sucesso",
          children: "Informações atualizadas com sucesso"
        });
  } else if (typeof match$2 === "number") {
    tmp = null;
  } else {
    var error = match$2._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(Panel$VannaFrontend.make, {
              className: panel,
              children: null
            }, React.createElement(Panel$VannaFrontend.PanelTitle.make, {
                  children: "Modifique o layout e o conteúdo das interfaces que seus clientes utilizarão"
                }), React.createElement(CustomizationsUpdateForm.Provider.make, Curry._3(CustomizationsUpdateForm.Provider.makeProps, reform, React.createElement("form", {
                          onSubmit: (function ($$event) {
                              $$event.preventDefault();
                              return Curry._1(reform.submit, undefined);
                            })
                        }, React.createElement(CustomizationsUpdateForm.Field.make, {
                              field: /* ProductName */0,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Nome da sua empresa",
                                              error: param.error,
                                              required: true,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              placeholder: "MinhaEmpresa"
                                            });
                                })
                            }), React.createElement(CustomizationsUpdateForm.Field.make, {
                              field: /* Title */2,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Título da página aqui no Vanna",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              placeholder: "MinhaEmpresa - sistema de assinaturas"
                                            });
                                })
                            }), React.createElement(CustomizationsUpdateForm.Field.make, {
                              field: /* ProductLink */1,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Link para acessar seu site",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              placeholder: "https://minhaempresa.com.br"
                                            });
                                })
                            }), React.createElement(CustomizationsUpdateForm.Field.make, {
                              field: /* LogoSrc */3,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Link para a logo da sua empresa",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              placeholder: "https://minhaempresa.com.br/logo.png"
                                            });
                                })
                            }), React.createElement(CustomizationsUpdateForm.Field.make, {
                              field: /* IconSrc */4,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Link para o ícone da sua empresa",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              placeholder: "https://minhaempresa.com.br/icon.png"
                                            });
                                })
                            }), React.createElement(FieldSection$VannaFrontend.make, {
                              children: "Email"
                            }), React.createElement(CustomizationsUpdateForm.Field.make, {
                              field: /* EmailLogo */5,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Link para a logo da sua empresa",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              placeholder: "https://minhaempresa.com.br/logo.png"
                                            });
                                })
                            }), React.createElement(CustomizationsUpdateForm.Field.make, {
                              field: /* EmailTeam */6,
                              render: (function (param) {
                                  return React.createElement(TextField$VannaFrontend.make, {
                                              label: "Nome do time da sua empresa",
                                              error: param.error,
                                              value: param.value,
                                              onChange: param.handleChange,
                                              placeholder: "Time MinhaEmpresa"
                                            });
                                })
                            }), tmp, React.createElement(Button$VannaFrontend.make, {
                              fullWidth: false,
                              isLoading: reform.isSubmitting,
                              type_: /* Submit */1,
                              children: "Atualizar"
                            })), undefined)));
}

var make = WhitelabelInfoForm;

exports.Styles = Styles;
exports.StateLenses = StateLenses;
exports.CustomizationsUpdateForm = CustomizationsUpdateForm;
exports.WhitelabelInfoUpdateMutation = WhitelabelInfoUpdateMutation;
exports.WhitelabelInfoFormFragment = WhitelabelInfoFormFragment;
exports.make = make;
/* panel Not a pure module */
