'use strict';

var React = require("react");
var Loading$VannaFrontend = require("./Loading.bs.js");

function SuspenseLoader(Props) {
  var children = Props.children;
  return React.createElement(React.Suspense, {
              children: children,
              fallback: React.createElement(Loading$VannaFrontend.make, {
                    hasIcon: true
                  })
            });
}

var make = SuspenseLoader;

exports.make = make;
/* react Not a pure module */
