'use strict';

var Decco = require("decco/src/Decco.bs.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");

function response_encode(v) {
  return Js_dict.fromArray([
              [
                "cep",
                Decco.stringToJson(v.cep)
              ],
              [
                "logradouro",
                Decco.optionToJson(Decco.stringToJson, v.logradouro)
              ],
              [
                "complemento",
                Decco.optionToJson(Decco.stringToJson, v.complemento)
              ],
              [
                "bairro",
                Decco.optionToJson(Decco.stringToJson, v.bairro)
              ],
              [
                "localidade",
                Decco.optionToJson(Decco.stringToJson, v.localidade)
              ],
              [
                "uf",
                Decco.optionToJson(Decco.stringToJson, v.uf)
              ],
              [
                "ibge",
                Decco.optionToJson(Decco.stringToJson, v.ibge)
              ],
              [
                "gia",
                Decco.optionToJson(Decco.stringToJson, v.gia)
              ],
              [
                "ddd",
                Decco.optionToJson(Decco.stringToJson, v.ddd)
              ],
              [
                "siafi",
                Decco.optionToJson(Decco.stringToJson, v.siafi)
              ]
            ]);
}

function response_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var cep = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "cep"), null));
  if (cep.TAG === /* Ok */0) {
    var logradouro = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "logradouro"), null));
    if (logradouro.TAG === /* Ok */0) {
      var complemento = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "complemento"), null));
      if (complemento.TAG === /* Ok */0) {
        var bairro = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bairro"), null));
        if (bairro.TAG === /* Ok */0) {
          var localidade = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "localidade"), null));
          if (localidade.TAG === /* Ok */0) {
            var uf = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "uf"), null));
            if (uf.TAG === /* Ok */0) {
              var ibge = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "ibge"), null));
              if (ibge.TAG === /* Ok */0) {
                var gia = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "gia"), null));
                if (gia.TAG === /* Ok */0) {
                  var ddd = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "ddd"), null));
                  if (ddd.TAG === /* Ok */0) {
                    var siafi = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siafi"), null));
                    if (siafi.TAG === /* Ok */0) {
                      return {
                              TAG: /* Ok */0,
                              _0: {
                                cep: cep._0,
                                logradouro: logradouro._0,
                                complemento: complemento._0,
                                bairro: bairro._0,
                                localidade: localidade._0,
                                uf: uf._0,
                                ibge: ibge._0,
                                gia: gia._0,
                                ddd: ddd._0,
                                siafi: siafi._0
                              }
                            };
                    }
                    var e = siafi._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".siafi" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = ddd._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".ddd" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = gia._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".gia" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = ibge._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".ibge" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = uf._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".uf" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = localidade._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".localidade" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = bairro._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".bairro" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = complemento._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".complemento" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = logradouro._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".logradouro" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = cep._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".cep" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function $$fetch$1(zipcode) {
  var __x = fetch("https://viacep.com.br/ws/" + zipcode + "/json/", Fetch.RequestInit.make(/* Get */0, {
              "content-type": "application/json",
              accept: "application/json"
            }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
  return $$Promise.flatMapOk($$Promise.mapError($$Promise.Js.toResult($$Promise.Js.fromBsPromise(__x.then(function (prim) {
                              return prim.json();
                            }))), (function (error) {
                    return $$Promise.resolved({
                                TAG: /* Error */1,
                                _0: error
                              });
                  })), (function (json) {
                var response = response_decode(json);
                return $$Promise.resolved({
                            TAG: /* Ok */0,
                            _0: response
                          });
              }));
}

exports.response_encode = response_encode;
exports.response_decode = response_decode;
exports.$$fetch = $$fetch$1;
/* Promise Not a pure module */
