'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Text$VannaFrontend = require("./Text.bs.js");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var BankCodes$VannaFrontend = require("../types/BankCodes.bs.js");
var FieldGrid$VannaFrontend = require("./FieldGrid.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var SearchableSelectField$VannaFrontend = require("./SearchableSelectField.bs.js");
var BankAccountInfoFormFragment_graphql$VannaFrontend = require("../__generated__/BankAccountInfoFormFragment_graphql.bs.js");
var BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend = require("../__generated__/BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

var panel = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 50
          }),
      tl: {
        hd: Css.padding(Theme$VannaFrontend.Padding.content),
        tl: {
          hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                hd: Css.width({
                      NAME: "percent",
                      VAL: 100
                    }),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var fiftyPercentField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 50
          }),
      tl: /* [] */0
    });

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  panel: panel,
  fiftyPercentField: fiftyPercentField
};

function get(state, field) {
  switch (field) {
    case /* HolderName */0 :
        return state.holderName;
    case /* BankCode */1 :
        return state.bankCode;
    case /* RoutingNumber */2 :
        return state.routingNumber;
    case /* AccountNumber */3 :
        return state.accountNumber;
    case /* TaxId */4 :
        return state.taxId;
    case /* TypeAccount */5 :
        return state.typeAccount;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* HolderName */0 :
        return {
                holderName: value,
                bankCode: state.bankCode,
                routingNumber: state.routingNumber,
                accountNumber: state.accountNumber,
                taxId: state.taxId,
                typeAccount: state.typeAccount
              };
    case /* BankCode */1 :
        return {
                holderName: state.holderName,
                bankCode: value,
                routingNumber: state.routingNumber,
                accountNumber: state.accountNumber,
                taxId: state.taxId,
                typeAccount: state.typeAccount
              };
    case /* RoutingNumber */2 :
        return {
                holderName: state.holderName,
                bankCode: state.bankCode,
                routingNumber: value,
                accountNumber: state.accountNumber,
                taxId: state.taxId,
                typeAccount: state.typeAccount
              };
    case /* AccountNumber */3 :
        return {
                holderName: state.holderName,
                bankCode: state.bankCode,
                routingNumber: state.routingNumber,
                accountNumber: value,
                taxId: state.taxId,
                typeAccount: state.typeAccount
              };
    case /* TaxId */4 :
        return {
                holderName: state.holderName,
                bankCode: state.bankCode,
                routingNumber: state.routingNumber,
                accountNumber: state.accountNumber,
                taxId: value,
                typeAccount: state.typeAccount
              };
    case /* TypeAccount */5 :
        return {
                holderName: state.holderName,
                bankCode: state.bankCode,
                routingNumber: state.routingNumber,
                accountNumber: state.accountNumber,
                taxId: state.taxId,
                typeAccount: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var BankAccountInfoCreateForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(BankAccountInfoFormFragment_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(BankAccountInfoFormFragment_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(BankAccountInfoFormFragment_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return BankAccountInfoFormFragment_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var BankAccountInfoFormFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.node,
              variables: BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var BankAccountInfoCreateMutation_make_bankAccountCreateInput = BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Utils.make_bankAccountCreateInput;

var BankAccountInfoCreateMutation_makeVariables = BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var BankAccountInfoCreateMutation_make_response_bankAccountCreate_error = BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Utils.make_response_bankAccountCreate_error;

var BankAccountInfoCreateMutation_make_response_bankAccountCreate = BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Utils.make_response_bankAccountCreate;

var BankAccountInfoCreateMutation_makeOptimisticResponse = BankAccountInfoForm_BankAccountInfoUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var BankAccountInfoCreateMutation = {
  make_bankAccountCreateInput: BankAccountInfoCreateMutation_make_bankAccountCreateInput,
  makeVariables: BankAccountInfoCreateMutation_makeVariables,
  make_response_bankAccountCreate_error: BankAccountInfoCreateMutation_make_response_bankAccountCreate_error,
  make_response_bankAccountCreate: BankAccountInfoCreateMutation_make_response_bankAccountCreate,
  makeOptimisticResponse: BankAccountInfoCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var BanksSelect = SearchableSelectField$VannaFrontend.Make({});

var AccountTypeSelect = SearchableSelectField$VannaFrontend.Make({});

function BankAccountInfoForm(Props) {
  var fragment = Props.fragment;
  var queryData = use(fragment);
  var match = use$1(undefined);
  var companyInfoUpdate = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setSuccess = match$1[1];
  var reform = Curry._7(BankAccountInfoCreateForm.use, {
        holderName: "",
        bankCode: "",
        routingNumber: "",
        accountNumber: "",
        taxId: Belt_Option.getWithDefault(queryData.settings.companyInfo.taxId, ""),
        typeAccount: /* Checking */0
      }, /* Schema */{
        _0: Curry._2(BankAccountInfoCreateForm.ReSchema.Validation.$plus, Curry._2(BankAccountInfoCreateForm.ReSchema.Validation.$plus, Curry._2(BankAccountInfoCreateForm.ReSchema.Validation.$plus, Curry._2(BankAccountInfoCreateForm.ReSchema.Validation.$plus, Curry._3(BankAccountInfoCreateForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* HolderName */0), Curry._3(BankAccountInfoCreateForm.ReSchema.Validation.nonEmpty, "O banco é obrigatório", undefined, /* BankCode */1)), Curry._3(BankAccountInfoCreateForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* RoutingNumber */2)), Curry._3(BankAccountInfoCreateForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* AccountNumber */3)), Curry._3(BankAccountInfoCreateForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* TaxId */4))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          var match = state.values.typeAccount;
          var bank = Belt_Array.getBy(BankCodes$VannaFrontend.list, (function (bank) {
                  return bank.value === state.values.bankCode;
                }));
          Curry.app(companyInfoUpdate, [
                (function (param) {
                    return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                  }),
                (function (response, errors) {
                    if (errors !== undefined && errors.length !== 0) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match = response.bankAccountCreate;
                    if (match === undefined) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }
                    var match$1 = match.error;
                    if (match$1 !== undefined) {
                      return Curry._1(raiseSubmitFailed, match$1.message);
                    } else {
                      Curry._1(send, {
                            TAG: /* SetFormState */10,
                            _0: /* Dirty */0
                          });
                      return Curry._1(setSuccess, (function (param) {
                                    return true;
                                  }));
                    }
                  }),
                undefined,
                undefined,
                undefined,
                undefined,
                {
                  input: {
                    accountNumber: state.values.accountNumber,
                    accountType: match ? "SAVINGS" : "CHECKING",
                    bankCode: state.values.bankCode,
                    bankName: bank !== undefined ? bank.label : "",
                    holderName: state.values.holderName,
                    routingNumber: state.values.routingNumber,
                    taxId: state.values.taxId
                  }
                },
                undefined,
                undefined
              ]);
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var bankAccount = queryData.bankAccount;
  var match$2 = reform.formState;
  var tmp;
  if (match$1[0]) {
    tmp = React.createElement(Box$VannaFrontend.make, {
          type_: /* Success */0,
          title: "Sucesso",
          children: "Conta bancária adicionada com sucesso"
        });
  } else if (typeof match$2 === "number") {
    tmp = null;
  } else {
    var error = match$2._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(React.Fragment, undefined, bankAccount !== undefined ? React.createElement(Panel$VannaFrontend.make, {
                    className: panel,
                    children: null
                  }, React.createElement(Panel$VannaFrontend.PanelTitle.make, {
                        children: "Dados da conta bancária cadastrada"
                      }), React.createElement(FieldGrid$VannaFrontend.make, {
                        children: null
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.flexGrow(4),
                                  tl: /* [] */0
                                })
                          }, React.createElement(Text$VannaFrontend.make, {
                                children: "Banco: "
                              }), React.createElement(Text$VannaFrontend.make, {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight("bold"),
                                      tl: /* [] */0
                                    }),
                                children: bankAccount.bankName
                              })), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.flexGrow(1.2),
                                  tl: /* [] */0
                                })
                          }, React.createElement(Text$VannaFrontend.make, {
                                children: "Agência: "
                              }), React.createElement(Text$VannaFrontend.make, {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight("bold"),
                                      tl: /* [] */0
                                    }),
                                children: "*" + bankAccount.routingNumberLast4
                              })), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.flexGrow(1),
                                  tl: /* [] */0
                                })
                          }, React.createElement(Text$VannaFrontend.make, {
                                children: "Número: "
                              }), React.createElement(Text$VannaFrontend.make, {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontWeight("bold"),
                                      tl: /* [] */0
                                    }),
                                children: "*" + bankAccount.accountNumberLast4
                              })))) : null, React.createElement(Panel$VannaFrontend.make, {
                  className: panel,
                  children: null
                }, React.createElement(Panel$VannaFrontend.PanelTitle.make, {
                      children: "Dados da conta bancária para qual o dinheiro recebido através do Vanna será transferido"
                    }), React.createElement(BankAccountInfoCreateForm.Provider.make, Curry._3(BankAccountInfoCreateForm.Provider.makeProps, reform, React.createElement("form", {
                              onSubmit: (function ($$event) {
                                  $$event.preventDefault();
                                  return Curry._1(reform.submit, undefined);
                                })
                            }, React.createElement(BankAccountInfoCreateForm.Field.make, {
                                  field: /* HolderName */0,
                                  render: (function (param) {
                                      return React.createElement(TextField$VannaFrontend.make, {
                                                  label: "Nome do titular",
                                                  error: param.error,
                                                  required: true,
                                                  value: param.value,
                                                  onChange: param.handleChange
                                                });
                                    })
                                }), React.createElement(FieldGrid$VannaFrontend.make, {
                                  children: null
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.width({
                                                  NAME: "percent",
                                                  VAL: 50
                                                }),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement(BankAccountInfoCreateForm.Field.make, {
                                          field: /* BankCode */1,
                                          render: (function (param) {
                                              var handleChange = param.handleChange;
                                              return React.createElement(BanksSelect.make, {
                                                          label: "Banco",
                                                          error: param.error,
                                                          required: true,
                                                          value: param.value,
                                                          onChange: (function (param) {
                                                              Curry._1(handleChange, param.value);
                                                              return $$Promise.resolved(undefined);
                                                            }),
                                                          options: BankCodes$VannaFrontend.list
                                                        });
                                            })
                                        })), React.createElement(BankAccountInfoCreateForm.Field.make, {
                                      field: /* TaxId */4,
                                      render: (function (param) {
                                          return React.createElement(TextField$VannaFrontend.make, {
                                                      label: "CNPJ",
                                                      error: param.error,
                                                      required: true,
                                                      mask: "99.999.999/9999-99",
                                                      value: param.value,
                                                      onChange: param.handleChange,
                                                      wrapperClassName: fiftyPercentField,
                                                      disabled: Belt_Option.isSome(queryData.settings.companyInfo.taxId)
                                                    });
                                        })
                                    })), React.createElement(FieldGrid$VannaFrontend.make, {
                                  children: null
                                }, React.createElement(BankAccountInfoCreateForm.Field.make, {
                                      field: /* RoutingNumber */2,
                                      render: (function (param) {
                                          return React.createElement(TextField$VannaFrontend.make, {
                                                      label: "Agência",
                                                      error: param.error,
                                                      required: true,
                                                      tip: "Número da sua agência sem o dígito verificador",
                                                      value: param.value,
                                                      onChange: param.handleChange,
                                                      wrapperClassName: fiftyPercentField
                                                    });
                                        })
                                    }), React.createElement(BankAccountInfoCreateForm.Field.make, {
                                      field: /* AccountNumber */3,
                                      render: (function (param) {
                                          return React.createElement(TextField$VannaFrontend.make, {
                                                      label: "Número da conta",
                                                      error: param.error,
                                                      required: true,
                                                      tip: "Número da sua conta com o dígito verificador e sem hífen",
                                                      value: param.value,
                                                      onChange: param.handleChange,
                                                      wrapperClassName: fiftyPercentField
                                                    });
                                        })
                                    })), React.createElement(BankAccountInfoCreateForm.Field.make, {
                                  field: /* TypeAccount */5,
                                  render: (function (param) {
                                      var handleChange = param.handleChange;
                                      return React.createElement(AccountTypeSelect.make, {
                                                  label: "Tipo da conta",
                                                  error: param.error,
                                                  required: true,
                                                  value: param.value,
                                                  onChange: (function (param) {
                                                      Curry._1(handleChange, param.value);
                                                      return $$Promise.resolved(undefined);
                                                    }),
                                                  options: [
                                                    {
                                                      label: "Conta corrente",
                                                      value: /* Checking */0
                                                    },
                                                    {
                                                      label: "Conta poupança",
                                                      value: /* Savings */1
                                                    }
                                                  ]
                                                });
                                    })
                                }), tmp, React.createElement(Button$VannaFrontend.make, {
                                  fullWidth: false,
                                  isLoading: reform.isSubmitting,
                                  type_: /* Submit */1,
                                  children: Belt_Option.isSome(queryData.bankAccount) ? "Atualizar" : "Adicionar"
                                })), undefined))));
}

var make = BankAccountInfoForm;

exports.Styles = Styles;
exports.StateLenses = StateLenses;
exports.BankAccountInfoCreateForm = BankAccountInfoCreateForm;
exports.BankAccountInfoFormFragment = BankAccountInfoFormFragment;
exports.BankAccountInfoCreateMutation = BankAccountInfoCreateMutation;
exports.BanksSelect = BanksSelect;
exports.AccountTypeSelect = AccountTypeSelect;
exports.make = make;
/* panel Not a pure module */
