'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

function makeRefetchVariables(input, param) {
  return {
          input: input
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var wrapResponseConverter = {"__root":{"chargeGetTransactionCostQuery":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"chargeGetTransactionCostQuery":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"ChargeGetTransactionCostQueryInput"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_chargeGetTransactionCostQueryInput(amount) {
  return {
          amount: amount
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

var Utils = {
  make_chargeGetTransactionCostQueryInput: make_chargeGetTransactionCostQueryInput,
  makeVariables: makeVariables
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChargeGetTransactionCostQueryPayload",
    "kind": "LinkedField",
    "name": "chargeGetTransactionCostQuery",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ccDefault",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ccVisaOrMastercard",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pix",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boleto",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChargeUpsertForm_GetTransactionCostQuery",
    "selections": (v1/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChargeUpsertForm_GetTransactionCostQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "adc9fc832b381d09fd9eb4f83c8042a0",
    "id": null,
    "metadata": {},
    "name": "ChargeUpsertForm_GetTransactionCostQuery",
    "operationKind": "query",
    "text": "query ChargeUpsertForm_GetTransactionCostQuery(\n  $input: ChargeGetTransactionCostQueryInput!\n) {\n  chargeGetTransactionCostQuery(input: $input) {\n    ccDefault\n    ccVisaOrMastercard\n    pix\n    boleto\n  }\n}\n"
  }
};
})());

var include = ReasonRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
