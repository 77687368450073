'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"webhookSubscriptionCreate_error":{"n":""},"webhookSubscriptionCreate":{"n":""},"webhookSubscriptionCreate_webhookSubscription_additionalBody":{"n":""},"webhookSubscriptionCreate_webhookSubscription_description":{"n":""},"webhookSubscriptionCreate_webhookSubscription_observedObjectId":{"n":""},"webhookSubscriptionCreate_webhookSubscription_additionalHeaders":{"n":""},"webhookSubscriptionCreate_webhookSubscription":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"webhookSubscriptionCreate_error":{"n":""},"webhookSubscriptionCreate":{"n":""},"webhookSubscriptionCreate_webhookSubscription_additionalBody":{"n":""},"webhookSubscriptionCreate_webhookSubscription_description":{"n":""},"webhookSubscriptionCreate_webhookSubscription_observedObjectId":{"n":""},"webhookSubscriptionCreate_webhookSubscription_additionalHeaders":{"n":""},"webhookSubscriptionCreate_webhookSubscription":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"WebhookSubscriptionCreateInput":{"additionalBody":{"n":""},"observedObjectId":{"n":""},"description":{"n":""},"additionalHeaders":{"n":""}},"__root":{"input":{"r":"WebhookSubscriptionCreateInput"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_webhookSubscriptionCreateInput(additionalBody, additionalHeaders, description, events, observedObjectId, url, param) {
  return {
          additionalBody: additionalBody,
          additionalHeaders: additionalHeaders,
          description: description,
          events: events,
          observedObjectId: observedObjectId,
          url: url
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_webhookSubscriptionCreate_webhookSubscription(id, description, events, url, additionalBody, additionalHeaders, observedObjectId, param) {
  return {
          id: id,
          description: description,
          events: events,
          url: url,
          additionalBody: additionalBody,
          additionalHeaders: additionalHeaders,
          observedObjectId: observedObjectId
        };
}

function make_response_webhookSubscriptionCreate_error(message) {
  return {
          message: message
        };
}

function make_response_webhookSubscriptionCreate(error, webhookSubscription, param) {
  return {
          error: error,
          webhookSubscription: webhookSubscription
        };
}

function makeOptimisticResponse(webhookSubscriptionCreate, param) {
  return {
          webhookSubscriptionCreate: webhookSubscriptionCreate
        };
}

var Utils = {
  make_webhookSubscriptionCreateInput: make_webhookSubscriptionCreateInput,
  makeVariables: makeVariables,
  make_response_webhookSubscriptionCreate_webhookSubscription: make_response_webhookSubscriptionCreate_webhookSubscription,
  make_response_webhookSubscriptionCreate_error: make_response_webhookSubscriptionCreate_error,
  make_response_webhookSubscriptionCreate: make_response_webhookSubscriptionCreate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "WebhookSubscriptionCreatePayload",
    "kind": "LinkedField",
    "name": "webhookSubscriptionCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WebhookSubscription",
        "kind": "LinkedField",
        "name": "webhookSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "events",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionalBody",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionalHeaders",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "observedObjectId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WebhookUpsertForm_WebhookCreateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WebhookUpsertForm_WebhookCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "56b230d4658c98382f8794609205c1eb",
    "id": null,
    "metadata": {},
    "name": "WebhookUpsertForm_WebhookCreateMutation",
    "operationKind": "mutation",
    "text": "mutation WebhookUpsertForm_WebhookCreateMutation(\n  $input: WebhookSubscriptionCreateInput!\n) {\n  webhookSubscriptionCreate(input: $input) {\n    error {\n      message\n    }\n    webhookSubscription {\n      id\n      description\n      events\n      url\n      additionalBody\n      additionalHeaders\n      observedObjectId\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
