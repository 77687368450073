'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Fi = require("react-icons/fi");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("../components/Modal.bs.js");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var Table$VannaFrontend = require("../components/Table.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var IconButton$VannaFrontend = require("../components/IconButton.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var DeleteModal$VannaFrontend = require("../components/DeleteModal.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var PageQueryRenderer$VannaFrontend = require("../components/PageQueryRenderer.bs.js");
var CustomerUpsertForm$VannaFrontend = require("../components/CustomerUpsertForm.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var CustomersQuery_graphql$VannaFrontend = require("../__generated__/CustomersQuery_graphql.bs.js");
var CustomersRefetchQuery_graphql$VannaFrontend = require("../__generated__/CustomersRefetchQuery_graphql.bs.js");
var CustomersConnection_query_graphql$VannaFrontend = require("../__generated__/CustomersConnection_query_graphql.bs.js");
var Customers_CustomerDeleteMutation_graphql$VannaFrontend = require("../__generated__/Customers_CustomerDeleteMutation_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.width("auto"),
      tl: /* [] */0
    });

var Styles = {
  panel: panel
};

var getConnectionNodes = CustomersConnection_query_graphql$VannaFrontend.Utils.getConnectionNodes;

var Internal = {};

function internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = ReasonRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = ReasonRelay.mapRenderPolicy(renderPolicy);
  if (tmp$2 !== undefined) {
    tmp.UNSTABLE_renderPolicy = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$3 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$3);
  }
  return tmp;
}

var InternalRefetch = {
  internal_makeRefetchableFnOpts: internal_makeRefetchableFnOpts
};

function useRefetchable(fRef) {
  var match = Hooks.useRefetchableFragment(CustomersConnection_query_graphql$VannaFrontend.node, fRef);
  var refetchFn = match[1];
  var data = ReasonRelay_Internal.internal_useConvertedValue(CustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, match[0]);
  return [
          data,
          (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return Curry._2(refetchFn, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(CustomersRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        ];
}

function use(fRef) {
  var data = Hooks.useFragment(CustomersConnection_query_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(CustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(CustomersConnection_query_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return CustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function usePagination(fr) {
  var p = Hooks.usePaginationFragment(CustomersConnection_query_graphql$VannaFrontend.node, fr);
  var data = ReasonRelay_Internal.internal_useConvertedValue(CustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(CustomersRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

function useBlockingPagination(fRef) {
  var p = Hooks.useBlockingPaginationFragment(CustomersConnection_query_graphql$VannaFrontend.node, fRef);
  var data = ReasonRelay_Internal.internal_useConvertedValue(CustomersConnection_query_graphql$VannaFrontend.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: (function (count, onComplete, param) {
              return p.loadNext(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          loadPrevious: (function (count, onComplete, param) {
              return p.loadPrevious(count, {
                          onComplete: ReasonRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                        });
            }),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: (function (variables, fetchPolicy, renderPolicy, onComplete, param) {
              return p.refetch(ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(CustomersRefetchQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, renderPolicy, onComplete, undefined));
            })
        };
}

var makeRefetchVariables = CustomersRefetchQuery_graphql$VannaFrontend.Types.makeRefetchVariables;

var CustomersConnectionFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Internal: Internal,
  InternalRefetch: InternalRefetch,
  useRefetchable: useRefetchable,
  use: use,
  useOpt: useOpt,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination,
  makeRefetchVariables: makeRefetchVariables
};

var Internal$1 = {};

function use$1(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(CustomersQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(CustomersQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(CustomersQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(CustomersQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, CustomersQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, CustomersQuery_graphql$VannaFrontend.node, CustomersQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: CustomersQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, CustomersQuery_graphql$VannaFrontend.node, CustomersQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return CustomersQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(CustomersQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(CustomersQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var CustomersQuery = {
  Types: undefined,
  Internal: Internal$1,
  use: use$1,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var Internal$2 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: Customers_CustomerDeleteMutation_graphql$VannaFrontend.node,
              variables: Customers_CustomerDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, Customers_CustomerDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? Customers_CustomerDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, Customers_CustomerDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(Customers_CustomerDeleteMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, Customers_CustomerDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? Customers_CustomerDeleteMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, Customers_CustomerDeleteMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: Customers_CustomerDeleteMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var DeleteCustomerMutation_make_customerDeleteInput = Customers_CustomerDeleteMutation_graphql$VannaFrontend.Utils.make_customerDeleteInput;

var DeleteCustomerMutation_makeVariables = Customers_CustomerDeleteMutation_graphql$VannaFrontend.Utils.makeVariables;

var DeleteCustomerMutation_make_response_customerDelete_error = Customers_CustomerDeleteMutation_graphql$VannaFrontend.Utils.make_response_customerDelete_error;

var DeleteCustomerMutation_make_response_customerDelete = Customers_CustomerDeleteMutation_graphql$VannaFrontend.Utils.make_response_customerDelete;

var DeleteCustomerMutation_makeOptimisticResponse = Customers_CustomerDeleteMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var DeleteCustomerMutation = {
  make_customerDeleteInput: DeleteCustomerMutation_make_customerDeleteInput,
  makeVariables: DeleteCustomerMutation_makeVariables,
  make_response_customerDelete_error: DeleteCustomerMutation_make_response_customerDelete_error,
  make_response_customerDelete: DeleteCustomerMutation_make_response_customerDelete,
  makeOptimisticResponse: DeleteCustomerMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation,
  use: use$2
};

var CustomersTable = Table$VannaFrontend.Make({});

function Customers$CustomersPage(Props) {
  var queryRef = Props.query;
  var match = usePagination(queryRef);
  var loadNext = match.loadNext;
  var customers = Curry._1(getConnectionNodes, match.data.customers);
  var match$1 = use$2(undefined);
  var isDelettingCustomer = match$1[1];
  var deleteCustomer = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setDeleteError = match$2[1];
  var deleteError = match$2[0];
  return React.createElement(React.Fragment, undefined, React.createElement(PageHeader$VannaFrontend.make, {
                  title: "Clientes",
                  upsertModal: {
                    renderModal: (function (dispatch) {
                        return React.createElement(CustomerUpsertForm$VannaFrontend.make, {
                                    dispatchModal: dispatch
                                  });
                      }),
                    displayName: "cliente"
                  }
                }), React.createElement(Panel$VannaFrontend.make, {
                  className: panel,
                  children: React.createElement(CustomersTable.make, {
                        data: customers,
                        columns: {
                          hd: Table$VannaFrontend.column("Nome", undefined, (function (customer, param) {
                                  return Belt_Option.getWithDefault(customer.name, "");
                                })),
                          tl: {
                            hd: Table$VannaFrontend.column("CNPJ/CPF", undefined, (function (customer, param) {
                                    return Belt_Option.getWithDefault(customer.taxId, "");
                                  })),
                            tl: {
                              hd: Table$VannaFrontend.column("Email", undefined, (function (customer, param) {
                                      return customer.email;
                                    })),
                              tl: {
                                hd: Table$VannaFrontend.column("", undefined, (function (customer, param) {
                                        return React.createElement(Modal$VannaFrontend.make, {
                                                    title: "Atualizar cliente",
                                                    trigger: (function (dispatch) {
                                                        return React.createElement(IconButton$VannaFrontend.make, {
                                                                    onClick: (function (param, param$1, param$2) {
                                                                        return Curry._1(dispatch, /* Open */2);
                                                                      }),
                                                                    children: React.createElement(Fi.FiEdit3, {})
                                                                  });
                                                      }),
                                                    content: (function (dispatch) {
                                                        return React.createElement(CustomerUpsertForm$VannaFrontend.make, {
                                                                    customer: Caml_option.some(customer.fragmentRefs),
                                                                    dispatchModal: dispatch
                                                                  });
                                                      })
                                                  });
                                      })),
                                tl: {
                                  hd: Table$VannaFrontend.column("", undefined, (function (customer, param) {
                                          return React.createElement(DeleteModal$VannaFrontend.make, {
                                                      displayName: "cliente",
                                                      onConfirm: (function (dispatch) {
                                                          Curry._1(setDeleteError, (function (param) {
                                                                  
                                                                }));
                                                          Curry.app(deleteCustomer, [
                                                                (function (param) {
                                                                    return Curry._1(setDeleteError, (function (param) {
                                                                                  return "Algo deu errado, tente novamente";
                                                                                }));
                                                                  }),
                                                                (function (response, _errors) {
                                                                    var match = response.customerDelete;
                                                                    if (match === undefined) {
                                                                      return Curry._1(setDeleteError, (function (param) {
                                                                                    return "Algo deu errado, tente novamente";
                                                                                  }));
                                                                    }
                                                                    var match$1 = match.error;
                                                                    if (match$1 === undefined) {
                                                                      return Curry._1(dispatch, /* Close */1);
                                                                    }
                                                                    var message = match$1.message;
                                                                    return Curry._1(setDeleteError, (function (param) {
                                                                                  return message;
                                                                                }));
                                                                  }),
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                (function (store, response) {
                                                                    var match = response.customerDelete;
                                                                    if (match === undefined) {
                                                                      return ;
                                                                    }
                                                                    if (match.error !== undefined) {
                                                                      return ;
                                                                    }
                                                                    var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                                                                    if (meProxy == null) {
                                                                      return ;
                                                                    }
                                                                    var customersConnections = RelayRuntime.ConnectionHandler.getConnection(meProxy, "Customers_customers", undefined);
                                                                    if (!(customersConnections == null)) {
                                                                      RelayRuntime.ConnectionHandler.deleteNode(customersConnections, customer.id);
                                                                      return ;
                                                                    }
                                                                    
                                                                  }),
                                                                {
                                                                  input: {
                                                                    customerId: customer.id
                                                                  }
                                                                },
                                                                undefined,
                                                                undefined
                                                              ]);
                                                          
                                                        }),
                                                      onClose: (function (param) {
                                                          return Curry._1(setDeleteError, (function (param) {
                                                                        
                                                                      }));
                                                        }),
                                                      isLoading: isDelettingCustomer,
                                                      error: deleteError
                                                    });
                                        })),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        },
                        getItemId: (function (customer) {
                            return customer.id;
                          }),
                        hasNextPage: match.hasNext,
                        loadMore: (function (param) {
                            Curry._3(loadNext, 20, undefined, undefined);
                            
                          })
                      })
                }));
}

var CustomersPage = {
  make: Customers$CustomersPage
};

function Customers$Inner(Props) {
  var queryData = use$1(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var me = queryData.me;
  if (me !== undefined) {
    return React.createElement(Customers$CustomersPage, {
                query: me.fragmentRefs
              });
  } else {
    return null;
  }
}

var Inner = {
  make: Customers$Inner
};

function Customers(Props) {
  var $$navigator = Props.navigator;
  return React.createElement(PageQueryRenderer$VannaFrontend.make, {
              children: React.createElement(PrivateLayout$VannaFrontend.make, {
                    navigator: $$navigator,
                    children: React.createElement(Customers$Inner, {})
                  })
            });
}

var pageSize = 20;

var make = Customers;

exports.Styles = Styles;
exports.pageSize = pageSize;
exports.CustomersConnectionFragment = CustomersConnectionFragment;
exports.CustomersQuery = CustomersQuery;
exports.DeleteCustomerMutation = DeleteCustomerMutation;
exports.CustomersTable = CustomersTable;
exports.CustomersPage = CustomersPage;
exports.Inner = Inner;
exports.make = make;
/* panel Not a pure module */
