'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Fi = require("react-icons/fi");
var Hooks = require("react-relay/hooks");
var Modal$VannaFrontend = require("../components/Modal.bs.js");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var Table$VannaFrontend = require("../components/Table.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var IconButton$VannaFrontend = require("../components/IconButton.bs.js");
var PageHeader$VannaFrontend = require("../components/PageHeader.bs.js");
var DeleteModal$VannaFrontend = require("../components/DeleteModal.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var PageQueryRenderer$VannaFrontend = require("../components/PageQueryRenderer.bs.js");
var AffiliateUpsertForm$VannaFrontend = require("../components/AffiliateUpsertForm.bs.js");
var AffiliatesQuery_graphql$VannaFrontend = require("../__generated__/AffiliatesQuery_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.width("auto"),
      tl: /* [] */0
    });

var Styles = {
  panel: panel
};

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(AffiliatesQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(AffiliatesQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(AffiliatesQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(AffiliatesQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, AffiliatesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, AffiliatesQuery_graphql$VannaFrontend.node, AffiliatesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: AffiliatesQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, AffiliatesQuery_graphql$VannaFrontend.node, AffiliatesQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return AffiliatesQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(AffiliatesQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(AffiliatesQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var AffiliatesQuery = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

var AffiliatesTable = Table$VannaFrontend.Make({});

function Affiliates$Inner(Props) {
  var match = React.useState(function () {
        
      });
  var setDeleteError = match[1];
  var deleteError = match[0];
  var queryData = use(undefined, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var match$1 = queryData.me;
  var data = match$1 !== undefined ? Belt_Array.keep(Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.flatMap(match$1.affiliates, (function (affiliates) {
                        return affiliates.edges;
                      })), []), (function (affiliateEdge) {
                return Belt_Option.flatMap(affiliateEdge, (function (affiliateEdge) {
                              return affiliateEdge.node;
                            }));
              })), Belt_Option.isSome) : [];
  return React.createElement(React.Fragment, undefined, React.createElement(PageHeader$VannaFrontend.make, {
                  title: "Afiliados",
                  upsertModal: {
                    renderModal: (function (dispatch) {
                        return React.createElement(AffiliateUpsertForm$VannaFrontend.make, {
                                    dispatchModal: dispatch
                                  });
                      }),
                    displayName: "afiliado"
                  }
                }), React.createElement(Panel$VannaFrontend.make, {
                  className: panel,
                  children: React.createElement(AffiliatesTable.make, {
                        data: data,
                        columns: {
                          hd: Table$VannaFrontend.column("Nome", undefined, (function (affiliate, param) {
                                  return Belt_Option.mapWithDefault(affiliate, "", (function (affiliate) {
                                                return affiliate.firstName + (" " + affiliate.lastName);
                                              }));
                                })),
                          tl: {
                            hd: Table$VannaFrontend.column("Email", undefined, (function (affiliate, param) {
                                    return Belt_Option.mapWithDefault(affiliate, "", (function (affiliate) {
                                                  return affiliate.email;
                                                }));
                                  })),
                            tl: {
                              hd: Table$VannaFrontend.column("CPF/CNPJ", undefined, (function (affiliate, param) {
                                      return Belt_Option.mapWithDefault(affiliate, "", (function (affiliate) {
                                                    return affiliate.taxId;
                                                  }));
                                    })),
                              tl: {
                                hd: Table$VannaFrontend.column("Total vendas", undefined, (function (affiliate, param) {
                                        return Belt_Option.mapWithDefault(affiliate, "", (function (affiliate) {
                                                      return String(affiliate.salesCount);
                                                    }));
                                      })),
                                tl: {
                                  hd: Table$VannaFrontend.column("Valor total vendas", undefined, (function (affiliate, param) {
                                          return Belt_Option.mapWithDefault(affiliate, "", (function (affiliate) {
                                                        return Money$VannaFrontend.format(Money$VannaFrontend.make({
                                                                        TAG: /* Cents */1,
                                                                        _0: affiliate.salesTotal
                                                                      }));
                                                      }));
                                        })),
                                  tl: {
                                    hd: Table$VannaFrontend.column("", undefined, (function (affiliate, param) {
                                            return React.createElement(Modal$VannaFrontend.make, {
                                                        title: "Atualizar afiliado",
                                                        trigger: (function (dispatch) {
                                                            return React.createElement(IconButton$VannaFrontend.make, {
                                                                        onClick: (function (param, param$1, param$2) {
                                                                            return Curry._1(dispatch, /* Open */2);
                                                                          }),
                                                                        children: React.createElement(Fi.FiEdit3, {})
                                                                      });
                                                          }),
                                                        content: (function (dispatch) {
                                                            return React.createElement(AffiliateUpsertForm$VannaFrontend.make, {
                                                                        affiliate: Belt_Option.map(affiliate, (function (affiliate) {
                                                                                return affiliate.fragmentRefs;
                                                                              })),
                                                                        dispatchModal: dispatch
                                                                      });
                                                          })
                                                      });
                                          })),
                                    tl: {
                                      hd: Table$VannaFrontend.column("", undefined, (function (_affiliate, param) {
                                              return React.createElement(DeleteModal$VannaFrontend.make, {
                                                          displayName: "afiliado",
                                                          onConfirm: (function (param) {
                                                              Curry._1(setDeleteError, (function (param) {
                                                                      
                                                                    }));
                                                              
                                                            }),
                                                          onClose: (function (param) {
                                                              return Curry._1(setDeleteError, (function (param) {
                                                                            
                                                                          }));
                                                            }),
                                                          isLoading: false,
                                                          error: deleteError
                                                        });
                                            })),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        },
                        getItemId: (function (affiliate) {
                            return Belt_Option.mapWithDefault(affiliate, "", (function (affiliate) {
                                          return affiliate.id;
                                        }));
                          })
                      })
                }));
}

var Inner = {
  make: Affiliates$Inner
};

function Affiliates(Props) {
  var $$navigator = Props.navigator;
  return React.createElement(PageQueryRenderer$VannaFrontend.make, {
              children: React.createElement(PrivateLayout$VannaFrontend.make, {
                    navigator: $$navigator,
                    children: React.createElement(Affiliates$Inner, {
                          navigator: $$navigator
                        })
                  })
            });
}

var make = Affiliates;

exports.Styles = Styles;
exports.AffiliatesQuery = AffiliatesQuery;
exports.AffiliatesTable = AffiliatesTable;
exports.Inner = Inner;
exports.make = make;
/* panel Not a pure module */
