'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$Promise = require("reason-promise/src/js/promise.bs.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");
var Hooks = require("react-relay/hooks");
var Panel$VannaFrontend = require("../components/Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var DateSelectors$VannaFrontend = require("../components/DateSelectors.bs.js");
var PrivateLayout$VannaFrontend = require("../components/PrivateLayout.bs.js");
var OnboardingSteps$VannaFrontend = require("../components/OnboardingSteps.bs.js");
var DashboardBalance$VannaFrontend = require("../components/DashboardBalance.bs.js");
var PageQueryRenderer$VannaFrontend = require("../components/PageQueryRenderer.bs.js");
var DashboardShortcuts$VannaFrontend = require("../components/DashboardShortcuts.bs.js");
var ReceivablesCalendar$VannaFrontend = require("../components/ReceivablesCalendar.bs.js");
var DashboardQuery_graphql$VannaFrontend = require("../__generated__/DashboardQuery_graphql.bs.js");
var ChargesProjectionsChart$VannaFrontend = require("../components/ChargesProjectionsChart.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.padding(Theme$VannaFrontend.Padding.content),
      tl: /* [] */0
    });

var Styles = {
  panel: panel
};

var Internal = {};

function use(variables, fetchPolicy, renderPolicy, fetchKey, networkCacheConfig, param) {
  var data = Hooks.useLazyLoadQuery(DashboardQuery_graphql$VannaFrontend.node, ReasonRelay_Internal.internal_cleanObjectFromUndefinedRaw(DashboardQuery_graphql$VannaFrontend.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: ReasonRelay.mapFetchPolicy(fetchPolicy),
        UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return ReasonRelay_Internal.internal_useConvertedValue(DashboardQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = Hooks.useQueryLoader(DashboardQuery_graphql$VannaFrontend.node);
  var loadQueryFn = match[1];
  var loadQuery = function (variables, fetchPolicy, networkCacheConfig, param) {
    return Curry._2(loadQueryFn, DashboardQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                fetchPolicy: fetchPolicy,
                networkCacheConfig: networkCacheConfig
              });
  };
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  Hooks.fetchQuery(environment, DashboardQuery_graphql$VannaFrontend.node, DashboardQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            return Curry._1(onResult, {
                        TAG: /* Ok */0,
                        _0: DashboardQuery_graphql$VannaFrontend.Internal.convertResponse(res)
                      });
          }),
        error: (function (err) {
            return Curry._1(onResult, {
                        TAG: /* Error */1,
                        _0: err
                      });
          })
      });
  
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  return $$Promise.map(Hooks.fetchQuery(environment, DashboardQuery_graphql$VannaFrontend.node, DashboardQuery_graphql$VannaFrontend.Internal.convertVariables(variables), {
                    networkCacheConfig: networkCacheConfig,
                    fetchPolicy: ReasonRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                  }).toPromise(), (function (res) {
                return DashboardQuery_graphql$VannaFrontend.Internal.convertResponse(res);
              }));
}

function usePreloaded(queryRef, renderPolicy, param) {
  var data = Hooks.usePreloadedQuery(DashboardQuery_graphql$VannaFrontend.node, queryRef, renderPolicy !== undefined ? ({
            UNSTABLE_renderPolicy: ReasonRelay.mapRenderPolicy(renderPolicy)
          }) : undefined);
  return ReasonRelay_Internal.internal_useConvertedValue(DashboardQuery_graphql$VannaFrontend.Internal.convertResponse, data);
}

var DashboardQuery_makeVariables = DashboardQuery_graphql$VannaFrontend.Utils.makeVariables;

var DashboardQuery = {
  makeVariables: DashboardQuery_makeVariables,
  Types: undefined,
  Internal: Internal,
  use: use,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded
};

function Dashboard$Inner(Props) {
  var $$navigator = Props.navigator;
  var match = React.useState(function () {
        return String(new Date().getFullYear() | 0);
      });
  var setYear = match[1];
  var year = match[0];
  var match$1 = React.useState(function () {
        return String(new Date().getMonth() | 0);
      });
  var setMonth = match$1[1];
  var month = match$1[0];
  var queryData = use({
        currentMonth: Caml_format.caml_int_of_string(month) + 1 | 0,
        currentYear: Caml_format.caml_int_of_string(year),
        lastMonth: Caml_format.caml_int_of_string(month),
        lastYear: Caml_format.caml_int_of_string(year) - 1 | 0
      }, /* StoreAndNetwork */2, undefined, undefined, undefined, undefined);
  var me = queryData.me;
  return React.createElement(React.Fragment, undefined, me !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(DashboardBalance$VannaFrontend.make, {
                        visualization: me.fragmentRefs
                      }), React.createElement(OnboardingSteps$VannaFrontend.make, {
                        navigator: $$navigator,
                        query: me.fragmentRefs
                      }), React.createElement(DashboardShortcuts$VannaFrontend.make, {
                        query: me.fragmentRefs
                      }), React.createElement(DateSelectors$VannaFrontend.YearSelector.make, {
                        selected: year,
                        setSelected: (function (value) {
                            return Curry._1(setYear, (function (param) {
                                          return value;
                                        }));
                          })
                      }), React.createElement(ChargesProjectionsChart$VannaFrontend.make, {
                        data: me.fragmentRefs,
                        year: year,
                        month: month
                      }), React.createElement(DateSelectors$VannaFrontend.MonthSelector.make, {
                        selected: month,
                        setSelected: (function (value) {
                            return Curry._1(setMonth, (function (param) {
                                          return value;
                                        }));
                          })
                      }), React.createElement(Panel$VannaFrontend.make, {
                        className: panel,
                        children: null
                      }, React.createElement(Panel$VannaFrontend.PanelTitle.make, {
                            children: "Calendário de recebíveis de cobranças pelo Vanna"
                          }), React.createElement(ReceivablesCalendar$VannaFrontend.make, {
                            year: Caml_format.caml_float_of_string(year),
                            month: Caml_format.caml_float_of_string(month),
                            fragment: me.fragmentRefs
                          }))) : null);
}

var Inner = {
  make: Dashboard$Inner
};

function Dashboard(Props) {
  var $$navigator = Props.navigator;
  return React.createElement(PageQueryRenderer$VannaFrontend.make, {
              children: React.createElement(PrivateLayout$VannaFrontend.make, {
                    navigator: $$navigator,
                    children: React.createElement(Dashboard$Inner, {
                          navigator: $$navigator
                        })
                  })
            });
}

var make = Dashboard;

exports.Styles = Styles;
exports.DashboardQuery = DashboardQuery;
exports.Inner = Inner;
exports.make = make;
/* panel Not a pure module */
