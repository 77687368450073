'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactInputMask = require("react-input-mask");
var InputBase$VannaFrontend = require("./InputBase.bs.js");
var FieldError$VannaFrontend = require("./FieldError.bs.js");
var FieldLabel$VannaFrontend = require("./FieldLabel.bs.js");
var FieldWrapper$VannaFrontend = require("./FieldWrapper.bs.js");

function TextField(Props) {
  var label = Props.label;
  var errorOpt = Props.error;
  var type_Opt = Props.type_;
  var requiredOpt = Props.required;
  var tip = Props.tip;
  var floatRight = Props.floatRight;
  var mask = Props.mask;
  var value = Props.value;
  var onChange = Props.onChange;
  var wrapperClassNameOpt = Props.wrapperClassName;
  var placeholderOpt = Props.placeholder;
  var disabledOpt = Props.disabled;
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  var type_ = type_Opt !== undefined ? type_Opt : /* Text */1;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var wrapperClassName = wrapperClassNameOpt !== undefined ? wrapperClassNameOpt : Curry._1(Css.style, /* [] */0);
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var hasError = error !== undefined ? error !== "" : false;
  var tmp;
  if (label !== undefined) {
    var tmp$1 = {
      hasError: hasError,
      required: required,
      children: label
    };
    if (tip !== undefined) {
      tmp$1.tip = Caml_option.valFromOption(tip);
    }
    if (floatRight !== undefined) {
      tmp$1.floatRight = Caml_option.valFromOption(floatRight);
    }
    tmp = React.createElement(FieldLabel$VannaFrontend.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(FieldWrapper$VannaFrontend.make, {
              className: wrapperClassName,
              children: null
            }, tmp, mask !== undefined ? React.createElement(ReactInputMask, {
                    mask: mask,
                    onChange: (function (evt) {
                        return Curry._1(onChange, evt.target.value);
                      }),
                    value: value,
                    disabled: disabled,
                    children: React.createElement(InputBase$VannaFrontend.make, {
                          type_: type_,
                          placeholder: placeholder
                        })
                  }) : React.createElement(InputBase$VannaFrontend.make, {
                    type_: type_,
                    placeholder: placeholder,
                    disabled: disabled,
                    value: value,
                    onChange: (function (evt) {
                        return Curry._1(onChange, evt.target.value);
                      })
                  }), hasError ? React.createElement(FieldError$VannaFrontend.make, {
                    message: Belt_Option.getWithDefault(error, "")
                  }) : null);
}

var make = TextField;

exports.make = make;
/* Css Not a pure module */
