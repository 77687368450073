'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fi = require("react-icons/fi");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Table$VannaFrontend = require("./Table.bs.js");
var IconButton$VannaFrontend = require("./IconButton.bs.js");
var FieldWrapper$VannaFrontend = require("./FieldWrapper.bs.js");
var ChargeHelpers$VannaFrontend = require("../types/ChargeHelpers.bs.js");

var section = Curry._1(Css.style, {
      hd: Css.marginBottom({
            NAME: "px",
            VAL: 15
          }),
      tl: {
        hd: Css.fontWeight("medium"),
        tl: /* [] */0
      }
    });

var Styles = {
  section: section
};

var ItemsTable = Table$VannaFrontend.MakeSecondary({});

function ItemsTable$1(Props) {
  var title = Props.title;
  var data = Props.data;
  var modeOpt = Props.mode;
  var onRemoveItemOpt = Props.onRemoveItem;
  var onEditItemOpt = Props.onEditItem;
  var readOnlyOpt = Props.readOnly;
  var domain = Props.domain;
  var mode = modeOpt !== undefined ? modeOpt : /* Large */0;
  var onRemoveItem = onRemoveItemOpt !== undefined ? Caml_option.valFromOption(onRemoveItemOpt) : undefined;
  var onEditItem = onEditItemOpt !== undefined ? Caml_option.valFromOption(onEditItemOpt) : undefined;
  var readOnly = readOnlyOpt !== undefined ? readOnlyOpt : false;
  var readOnlyColumns = readOnly ? /* [] */0 : ({
        hd: Table$VannaFrontend.column("", undefined, (function (item, _rowIndex) {
                return React.createElement(React.Fragment, undefined, React.createElement(IconButton$VannaFrontend.make, {
                                onClick: (function (param, param$1, param$2) {
                                    if (onRemoveItem !== undefined) {
                                      return Curry._1(onRemoveItem, item.temporaryId);
                                    }
                                    
                                  }),
                                children: React.createElement(Fi.FiTrash2, {})
                              }), React.createElement(IconButton$VannaFrontend.make, {
                                onClick: (function (param, param$1, param$2) {
                                    if (onEditItem !== undefined) {
                                      return Curry._1(onEditItem, item);
                                    }
                                    
                                  }),
                                children: React.createElement(Fi.FiEdit3, {})
                              }));
              })),
        tl: /* [] */0
      });
  return React.createElement(FieldWrapper$VannaFrontend.make, {
              children: null
            }, React.createElement("h4", {
                  className: section
                }, title), React.createElement(ItemsTable.make, {
                  data: data,
                  columns: domain >= 2 ? ({
                        hd: Table$VannaFrontend.column("Descrição", undefined, (function (item, param) {
                                return item.name;
                              })),
                        tl: {
                          hd: Table$VannaFrontend.column("Quantidade Máxima", undefined, (function (item, param) {
                                  if (item.hasMaxQuantity) {
                                    return String(item.maxQuantity);
                                  } else {
                                    return "Sem restrições";
                                  }
                                })),
                          tl: readOnlyColumns
                        }
                      }) : (
                      mode ? ({
                            hd: Table$VannaFrontend.column("Descrição", undefined, (function (item, param) {
                                    return item.name;
                                  })),
                            tl: {
                              hd: Table$VannaFrontend.column("Quantidade", undefined, (function (item, param) {
                                      return String(item.quantity);
                                    })),
                              tl: {
                                hd: Table$VannaFrontend.column("Preço", undefined, (function (item, param) {
                                        return Money$VannaFrontend.formatInt(item.price);
                                      })),
                                tl: {
                                  hd: Table$VannaFrontend.column("Subtotal", undefined, (function (item, param) {
                                          return Money$VannaFrontend.formatInt(ChargeHelpers$VannaFrontend.getTotalUniq(item));
                                        })),
                                  tl: readOnlyColumns
                                }
                              }
                            }
                          }) : ({
                            hd: Table$VannaFrontend.column("Descrição", undefined, (function (item, param) {
                                    return item.name;
                                  })),
                            tl: {
                              hd: Table$VannaFrontend.column("Quantidade", undefined, (function (item, param) {
                                      return String(item.quantity);
                                    })),
                              tl: {
                                hd: Table$VannaFrontend.column("Preço", undefined, (function (item, param) {
                                        return Money$VannaFrontend.formatInt(item.price);
                                      })),
                                tl: {
                                  hd: Table$VannaFrontend.column("Frete", undefined, (function (item, param) {
                                          return Money$VannaFrontend.formatInt(item.freightAmount);
                                        })),
                                  tl: {
                                    hd: Table$VannaFrontend.column("Seguro", undefined, (function (item, param) {
                                            return Money$VannaFrontend.formatInt(item.insuranceAmount);
                                          })),
                                    tl: {
                                      hd: Table$VannaFrontend.column("Outros custos", undefined, (function (item, param) {
                                              return Money$VannaFrontend.formatInt(item.othersAmount);
                                            })),
                                      tl: {
                                        hd: Table$VannaFrontend.column("Desconto", undefined, (function (item, param) {
                                                return Money$VannaFrontend.formatInt(item.discountAmount);
                                              })),
                                        tl: {
                                          hd: Table$VannaFrontend.column("Subtotal", undefined, (function (item, param) {
                                                  return Money$VannaFrontend.formatInt(ChargeHelpers$VannaFrontend.getTotalUniq(item));
                                                })),
                                          tl: readOnlyColumns
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    ),
                  getItemId: (function (item) {
                      return "__charge__item__table__row" + item.name;
                    }),
                  withPadding: false
                }));
}

var make = ItemsTable$1;

exports.Styles = Styles;
exports.ItemsTable = ItemsTable;
exports.make = make;
/* section Not a pure module */
