'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fi = require("react-icons/fi");
var Button$VannaFrontend = require("./Button.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var FieldError$VannaFrontend = require("./FieldError.bs.js");
var FieldLabel$VannaFrontend = require("./FieldLabel.bs.js");
var FieldWrapper$VannaFrontend = require("./FieldWrapper.bs.js");

var row = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("start"),
          tl: /* [] */0
        }
      }
    });

var textField = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 50
          }),
      tl: /* [] */0
    });

var Styles = {
  row: row,
  textField: textField
};

function KeyValueField(Props) {
  var label = Props.label;
  var errorOpt = Props.error;
  var requiredOpt = Props.required;
  var tip = Props.tip;
  var floatRight = Props.floatRight;
  var value = Props.value;
  var onChange = Props.onChange;
  var wrapperClassNameOpt = Props.wrapperClassName;
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var wrapperClassName = wrapperClassNameOpt !== undefined ? wrapperClassNameOpt : Curry._1(Css.style, /* [] */0);
  var match = React.useState(function () {
        return {
                key: "",
                value: ""
              };
      });
  var setCurrentItem = match[1];
  var currentItem = match[0];
  var match$1 = React.useState(function () {
        return value;
      });
  var setItems = match$1[1];
  var items = match$1[0];
  var hasError = error !== undefined ? error !== "" : false;
  React.useEffect((function () {
          Curry._1(onChange, items);
          
        }), [items]);
  var tmp;
  if (label !== undefined) {
    var tmp$1 = {
      hasError: hasError,
      required: required,
      children: label
    };
    if (tip !== undefined) {
      tmp$1.tip = Caml_option.valFromOption(tip);
    }
    if (floatRight !== undefined) {
      tmp$1.floatRight = Caml_option.valFromOption(floatRight);
    }
    tmp = React.createElement(FieldLabel$VannaFrontend.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement(FieldWrapper$VannaFrontend.make, {
              className: wrapperClassName,
              children: null
            }, tmp, Belt_Array.mapWithIndex(items, (function (index, item) {
                    return React.createElement("div", {
                                key: "__KEY_VALUE_FIELD_FORM__" + (item.key + item.value),
                                className: row
                              }, React.createElement(TextField$VannaFrontend.make, {
                                    value: item.key,
                                    onChange: (function (newKey) {
                                        var items$1 = Belt_Array.reduceWithIndex(items, [], (function (acc, cur, idx) {
                                                if (idx !== index) {
                                                  return Belt_Array.concat(acc, [cur]);
                                                }
                                                var newItem_value = cur.value;
                                                var newItem = {
                                                  key: newKey,
                                                  value: newItem_value
                                                };
                                                return Belt_Array.concat(acc, [newItem]);
                                              }));
                                        return Curry._1(setItems, (function (param) {
                                                      return items$1;
                                                    }));
                                      }),
                                    wrapperClassName: textField
                                  }), React.createElement(TextField$VannaFrontend.make, {
                                    value: item.value,
                                    onChange: (function (newValue) {
                                        var items$1 = Belt_Array.reduceWithIndex(items, [], (function (acc, cur, idx) {
                                                if (idx !== index) {
                                                  return Belt_Array.concat(acc, [cur]);
                                                }
                                                var newItem_key = cur.key;
                                                var newItem = {
                                                  key: newItem_key,
                                                  value: newValue
                                                };
                                                return Belt_Array.concat(acc, [newItem]);
                                              }));
                                        return Curry._1(setItems, (function (param) {
                                                      return items$1;
                                                    }));
                                      }),
                                    wrapperClassName: Curry._1(Css.merge, {
                                          hd: textField,
                                          tl: {
                                            hd: Curry._1(Css.style, {
                                                  hd: Css.paddingLeft({
                                                        NAME: "px",
                                                        VAL: 15
                                                      }),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        })
                                  }), React.createElement(Button$VannaFrontend.make, {
                                    fullWidth: false,
                                    mode: /* Secondary */1,
                                    onClick: (function (param) {
                                        var items$1 = Belt_Array.keepWithIndex(items, (function (_item, idx) {
                                                return idx !== index;
                                              }));
                                        return Curry._1(setItems, (function (param) {
                                                      return items$1;
                                                    }));
                                      }),
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginTop({
                                                NAME: "px",
                                                VAL: 0
                                              }),
                                          tl: /* [] */0
                                        }),
                                    children: React.createElement(Fi.FiMinus, {})
                                  }));
                  })), React.createElement("div", {
                  className: row
                }, React.createElement(TextField$VannaFrontend.make, {
                      value: currentItem.key,
                      onChange: (function (key) {
                          return Curry._1(setCurrentItem, (function (param) {
                                        return {
                                                key: key,
                                                value: currentItem.value
                                              };
                                      }));
                        }),
                      wrapperClassName: textField
                    }), React.createElement(TextField$VannaFrontend.make, {
                      value: currentItem.value,
                      onChange: (function (value) {
                          return Curry._1(setCurrentItem, (function (param) {
                                        return {
                                                key: currentItem.key,
                                                value: value
                                              };
                                      }));
                        }),
                      wrapperClassName: Curry._1(Css.merge, {
                            hd: textField,
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.paddingLeft({
                                          NAME: "px",
                                          VAL: 15
                                        }),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          })
                    }), React.createElement(Button$VannaFrontend.make, {
                      fullWidth: false,
                      mode: /* Secondary */1,
                      onClick: (function (param) {
                          Curry._1(setItems, (function (param) {
                                  return Belt_Array.concat(items, [currentItem]);
                                }));
                          return Curry._1(setCurrentItem, (function (param) {
                                        return {
                                                key: "",
                                                value: ""
                                              };
                                      }));
                        }),
                      className: Curry._1(Css.style, {
                            hd: Css.marginTop({
                                  NAME: "px",
                                  VAL: 0
                                }),
                            tl: /* [] */0
                          }),
                      children: React.createElement(Fi.FiPlus, {})
                    })), hasError ? React.createElement(FieldError$VannaFrontend.make, {
                    message: Belt_Option.getWithDefault(error, "")
                  }) : null);
}

var make = KeyValueField;

exports.Styles = Styles;
exports.make = make;
/* row Not a pure module */
