'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Panel$VannaFrontend = require("./Panel.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var OnboardingStepsFragment_user_graphql$VannaFrontend = require("../__generated__/OnboardingStepsFragment_user_graphql.bs.js");

var panel = Curry._1(Css.style, {
      hd: Css.padding(Theme$VannaFrontend.Padding.content),
      tl: /* [] */0
    });

var title = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.9
          }),
      tl: {
        hd: Css.lineHeight({
              NAME: "rem",
              VAL: 1.3
            }),
        tl: {
          hd: Css.fontWeight("bold"),
          tl: {
            hd: Css.color(Theme$VannaFrontend.Colors.text),
            tl: {
              hd: Css.marginBottom({
                    NAME: "px",
                    VAL: 20
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

function missingSetting(disabled) {
  return Curry._1(Css.style, !disabled ? ({
                  hd: Css.cursor("pointer"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.textDecoration("underline"),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }) : /* [] */0);
}

var Styles = {
  panel: panel,
  title: title,
  missingSetting: missingSetting
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(OnboardingStepsFragment_user_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(OnboardingStepsFragment_user_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(OnboardingStepsFragment_user_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return OnboardingStepsFragment_user_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var OnboardingStepsFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function OnboardingSteps(Props) {
  var $$navigator = Props.navigator;
  var query = Props.query;
  var queryData = use(query);
  var hasCompanyInfo = Belt_Option.isSome(queryData.settings.companyInfo.taxId);
  if (hasCompanyInfo) {
    return null;
  }
  var match = queryData.settings.companyInfo.taxId;
  return React.createElement(Panel$VannaFrontend.make, {
              className: panel,
              children: null
            }, React.createElement("h4", {
                  className: title
                }, "Precisamos de mais informações para habilitar todas as funcionalidades em sua conta"), match !== undefined ? null : React.createElement("div", {
                    className: missingSetting(false)
                  }, React.createElement(Box$VannaFrontend.make, {
                        type_: /* Warn */1,
                        title: "Informações básicas sobre a empresa",
                        onClick: (function (param) {
                            return Curry._1($$navigator.push, {
                                        TAG: /* Settings */8,
                                        _0: "dados-cadastrais"
                                      });
                          }),
                        children: "Clique aqui para adicionar informações básicas sobre sua empresa para começar a realizar vendas."
                      })));
}

var make = OnboardingSteps;

exports.Styles = Styles;
exports.OnboardingStepsFragment = OnboardingStepsFragment;
exports.make = make;
/* panel Not a pure module */
