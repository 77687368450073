'use strict';

var BoletoJs = require("./Boleto.js");

function barcodeToBoletoNumber(prim) {
  return BoletoJs.codBarras2LinhaDigitavel(prim);
}

exports.barcodeToBoletoNumber = barcodeToBoletoNumber;
/* ./Boleto.js Not a pure module */
