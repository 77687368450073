'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReForm = require("bs-reform/src/ReForm.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RelayRuntime = require("relay-runtime");
var Box$VannaFrontend = require("./Box.bs.js");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Money$VannaFrontend = require("../types/Money.bs.js");
var Button$VannaFrontend = require("./Button.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var TextField$VannaFrontend = require("./TextField.bs.js");
var MoneyField$VannaFrontend = require("./MoneyField.bs.js");
var Reformi18n$VannaFrontend = require("../Reformi18n.bs.js");
var RelayExperimental = require("react-relay/lib/relay-experimental");
var ProductUpsertForm_product_graphql$VannaFrontend = require("../__generated__/ProductUpsertForm_product_graphql.bs.js");
var ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend = require("../__generated__/ProductUpsertForm_ProductCreateMutation_graphql.bs.js");
var ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend = require("../__generated__/ProductUpsertForm_ProductUpdateMutation_graphql.bs.js");

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(ProductUpsertForm_product_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(ProductUpsertForm_product_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(ProductUpsertForm_product_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return ProductUpsertForm_product_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var ProductFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function get(state, field) {
  switch (field) {
    case /* Name */0 :
        return state.name;
    case /* Description */1 :
        return state.description;
    case /* Ncm */2 :
        return state.ncm;
    case /* Cest */3 :
        return state.cest;
    case /* Cfop */4 :
        return state.cfop;
    case /* Price */5 :
        return state.price;
    case /* IcmsOrigin */6 :
        return state.icmsOrigin;
    case /* IcmsCst */7 :
        return state.icmsCst;
    case /* IcmsCsosn */8 :
        return state.icmsCsosn;
    case /* PisCst */9 :
        return state.pisCst;
    case /* CofinsCst */10 :
        return state.cofinsCst;
    
  }
}

function set(state, field, value) {
  switch (field) {
    case /* Name */0 :
        return {
                name: value,
                description: state.description,
                ncm: state.ncm,
                cest: state.cest,
                cfop: state.cfop,
                price: state.price,
                icmsOrigin: state.icmsOrigin,
                icmsCst: state.icmsCst,
                icmsCsosn: state.icmsCsosn,
                pisCst: state.pisCst,
                cofinsCst: state.cofinsCst
              };
    case /* Description */1 :
        return {
                name: state.name,
                description: value,
                ncm: state.ncm,
                cest: state.cest,
                cfop: state.cfop,
                price: state.price,
                icmsOrigin: state.icmsOrigin,
                icmsCst: state.icmsCst,
                icmsCsosn: state.icmsCsosn,
                pisCst: state.pisCst,
                cofinsCst: state.cofinsCst
              };
    case /* Ncm */2 :
        return {
                name: state.name,
                description: state.description,
                ncm: value,
                cest: state.cest,
                cfop: state.cfop,
                price: state.price,
                icmsOrigin: state.icmsOrigin,
                icmsCst: state.icmsCst,
                icmsCsosn: state.icmsCsosn,
                pisCst: state.pisCst,
                cofinsCst: state.cofinsCst
              };
    case /* Cest */3 :
        return {
                name: state.name,
                description: state.description,
                ncm: state.ncm,
                cest: value,
                cfop: state.cfop,
                price: state.price,
                icmsOrigin: state.icmsOrigin,
                icmsCst: state.icmsCst,
                icmsCsosn: state.icmsCsosn,
                pisCst: state.pisCst,
                cofinsCst: state.cofinsCst
              };
    case /* Cfop */4 :
        return {
                name: state.name,
                description: state.description,
                ncm: state.ncm,
                cest: state.cest,
                cfop: value,
                price: state.price,
                icmsOrigin: state.icmsOrigin,
                icmsCst: state.icmsCst,
                icmsCsosn: state.icmsCsosn,
                pisCst: state.pisCst,
                cofinsCst: state.cofinsCst
              };
    case /* Price */5 :
        return {
                name: state.name,
                description: state.description,
                ncm: state.ncm,
                cest: state.cest,
                cfop: state.cfop,
                price: value,
                icmsOrigin: state.icmsOrigin,
                icmsCst: state.icmsCst,
                icmsCsosn: state.icmsCsosn,
                pisCst: state.pisCst,
                cofinsCst: state.cofinsCst
              };
    case /* IcmsOrigin */6 :
        return {
                name: state.name,
                description: state.description,
                ncm: state.ncm,
                cest: state.cest,
                cfop: state.cfop,
                price: state.price,
                icmsOrigin: value,
                icmsCst: state.icmsCst,
                icmsCsosn: state.icmsCsosn,
                pisCst: state.pisCst,
                cofinsCst: state.cofinsCst
              };
    case /* IcmsCst */7 :
        return {
                name: state.name,
                description: state.description,
                ncm: state.ncm,
                cest: state.cest,
                cfop: state.cfop,
                price: state.price,
                icmsOrigin: state.icmsOrigin,
                icmsCst: value,
                icmsCsosn: state.icmsCsosn,
                pisCst: state.pisCst,
                cofinsCst: state.cofinsCst
              };
    case /* IcmsCsosn */8 :
        return {
                name: state.name,
                description: state.description,
                ncm: state.ncm,
                cest: state.cest,
                cfop: state.cfop,
                price: state.price,
                icmsOrigin: state.icmsOrigin,
                icmsCst: state.icmsCst,
                icmsCsosn: value,
                pisCst: state.pisCst,
                cofinsCst: state.cofinsCst
              };
    case /* PisCst */9 :
        return {
                name: state.name,
                description: state.description,
                ncm: state.ncm,
                cest: state.cest,
                cfop: state.cfop,
                price: state.price,
                icmsOrigin: state.icmsOrigin,
                icmsCst: state.icmsCst,
                icmsCsosn: state.icmsCsosn,
                pisCst: value,
                cofinsCst: state.cofinsCst
              };
    case /* CofinsCst */10 :
        return {
                name: state.name,
                description: state.description,
                ncm: state.ncm,
                cest: state.cest,
                cfop: state.cfop,
                price: state.price,
                icmsOrigin: state.icmsOrigin,
                icmsCst: state.icmsCst,
                icmsCsosn: state.icmsCsosn,
                pisCst: state.pisCst,
                cofinsCst: value
              };
    
  }
}

var StateLenses = {
  get: get,
  set: set
};

var ProductUpsertForm = ReForm.Make({
      set: set,
      get: get
    });

var Internal$1 = {};

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.node,
              variables: ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$1(param) {
  var match = RelayExperimental.useMutation(ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ProductCreateMutation_make_pis = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_pis;

var ProductCreateMutation_make_ncm = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_ncm;

var ProductCreateMutation_make_icms = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_icms;

var ProductCreateMutation_make_cofins = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_cofins;

var ProductCreateMutation_make_cfop = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_cfop;

var ProductCreateMutation_make_cest = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_cest;

var ProductCreateMutation_make_productCreateInput = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_productCreateInput;

var ProductCreateMutation_makeVariables = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.makeVariables;

var ProductCreateMutation_make_response_productCreate_product_pis = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_response_productCreate_product_pis;

var ProductCreateMutation_make_response_productCreate_product_ncm = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_response_productCreate_product_ncm;

var ProductCreateMutation_make_response_productCreate_product_icms = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_response_productCreate_product_icms;

var ProductCreateMutation_make_response_productCreate_product_cofins = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_response_productCreate_product_cofins;

var ProductCreateMutation_make_response_productCreate_product_cfop = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_response_productCreate_product_cfop;

var ProductCreateMutation_make_response_productCreate_product_cest = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_response_productCreate_product_cest;

var ProductCreateMutation_make_response_productCreate_product = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_response_productCreate_product;

var ProductCreateMutation_make_response_productCreate_error = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_response_productCreate_error;

var ProductCreateMutation_make_response_productCreate = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.make_response_productCreate;

var ProductCreateMutation_makeOptimisticResponse = ProductUpsertForm_ProductCreateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ProductCreateMutation = {
  make_pis: ProductCreateMutation_make_pis,
  make_ncm: ProductCreateMutation_make_ncm,
  make_icms: ProductCreateMutation_make_icms,
  make_cofins: ProductCreateMutation_make_cofins,
  make_cfop: ProductCreateMutation_make_cfop,
  make_cest: ProductCreateMutation_make_cest,
  make_productCreateInput: ProductCreateMutation_make_productCreateInput,
  makeVariables: ProductCreateMutation_makeVariables,
  make_response_productCreate_product_pis: ProductCreateMutation_make_response_productCreate_product_pis,
  make_response_productCreate_product_ncm: ProductCreateMutation_make_response_productCreate_product_ncm,
  make_response_productCreate_product_icms: ProductCreateMutation_make_response_productCreate_product_icms,
  make_response_productCreate_product_cofins: ProductCreateMutation_make_response_productCreate_product_cofins,
  make_response_productCreate_product_cfop: ProductCreateMutation_make_response_productCreate_product_cfop,
  make_response_productCreate_product_cest: ProductCreateMutation_make_response_productCreate_product_cest,
  make_response_productCreate_product: ProductCreateMutation_make_response_productCreate_product,
  make_response_productCreate_error: ProductCreateMutation_make_response_productCreate_error,
  make_response_productCreate: ProductCreateMutation_make_response_productCreate,
  makeOptimisticResponse: ProductCreateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$1,
  commitMutation: commitMutation,
  use: use$1
};

var Internal$2 = {};

function commitMutation$1(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.node,
              variables: ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    return Curry._2(updater, store, ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use$2(param) {
  var match = RelayExperimental.useMutation(ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.node);
  var mutate = match[0];
  return [
          (function (onError, onCompleted, onUnsubscribe, optimisticResponse, optimisticUpdater, updater, variables, uploadables, param) {
              return Curry._1(mutate, {
                          onError: onError,
                          onCompleted: onCompleted !== undefined ? (function (r, errors) {
                                return Curry._2(onCompleted, ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                              }) : undefined,
                          onUnsubscribe: onUnsubscribe,
                          optimisticResponse: optimisticResponse !== undefined ? ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
                          optimisticUpdater: optimisticUpdater,
                          updater: updater !== undefined ? (function (store, r) {
                                return Curry._2(updater, store, ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Internal.convertResponse(r));
                              }) : undefined,
                          variables: ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Internal.convertVariables(variables),
                          uploadables: uploadables
                        });
            }),
          match[1]
        ];
}

var ProductUpdateMutation_make_pis = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_pis;

var ProductUpdateMutation_make_ncm = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_ncm;

var ProductUpdateMutation_make_icms = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_icms;

var ProductUpdateMutation_make_cofins = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_cofins;

var ProductUpdateMutation_make_cfop = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_cfop;

var ProductUpdateMutation_make_cest = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_cest;

var ProductUpdateMutation_make_productUpdateInput = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_productUpdateInput;

var ProductUpdateMutation_makeVariables = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.makeVariables;

var ProductUpdateMutation_make_response_productUpdate_product_pis = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_response_productUpdate_product_pis;

var ProductUpdateMutation_make_response_productUpdate_product_ncm = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_response_productUpdate_product_ncm;

var ProductUpdateMutation_make_response_productUpdate_product_icms = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_response_productUpdate_product_icms;

var ProductUpdateMutation_make_response_productUpdate_product_cofins = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_response_productUpdate_product_cofins;

var ProductUpdateMutation_make_response_productUpdate_product_cfop = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_response_productUpdate_product_cfop;

var ProductUpdateMutation_make_response_productUpdate_product_cest = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_response_productUpdate_product_cest;

var ProductUpdateMutation_make_response_productUpdate_product = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_response_productUpdate_product;

var ProductUpdateMutation_make_response_productUpdate_error = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_response_productUpdate_error;

var ProductUpdateMutation_make_response_productUpdate = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.make_response_productUpdate;

var ProductUpdateMutation_makeOptimisticResponse = ProductUpsertForm_ProductUpdateMutation_graphql$VannaFrontend.Utils.makeOptimisticResponse;

var ProductUpdateMutation = {
  make_pis: ProductUpdateMutation_make_pis,
  make_ncm: ProductUpdateMutation_make_ncm,
  make_icms: ProductUpdateMutation_make_icms,
  make_cofins: ProductUpdateMutation_make_cofins,
  make_cfop: ProductUpdateMutation_make_cfop,
  make_cest: ProductUpdateMutation_make_cest,
  make_productUpdateInput: ProductUpdateMutation_make_productUpdateInput,
  makeVariables: ProductUpdateMutation_makeVariables,
  make_response_productUpdate_product_pis: ProductUpdateMutation_make_response_productUpdate_product_pis,
  make_response_productUpdate_product_ncm: ProductUpdateMutation_make_response_productUpdate_product_ncm,
  make_response_productUpdate_product_icms: ProductUpdateMutation_make_response_productUpdate_product_icms,
  make_response_productUpdate_product_cofins: ProductUpdateMutation_make_response_productUpdate_product_cofins,
  make_response_productUpdate_product_cfop: ProductUpdateMutation_make_response_productUpdate_product_cfop,
  make_response_productUpdate_product_cest: ProductUpdateMutation_make_response_productUpdate_product_cest,
  make_response_productUpdate_product: ProductUpdateMutation_make_response_productUpdate_product,
  make_response_productUpdate_error: ProductUpdateMutation_make_response_productUpdate_error,
  make_response_productUpdate: ProductUpdateMutation_make_response_productUpdate,
  makeOptimisticResponse: ProductUpdateMutation_makeOptimisticResponse,
  Types: undefined,
  Internal: Internal$2,
  commitMutation: commitMutation$1,
  use: use$2
};

function ProductUpsertForm$1(Props) {
  var productOpt = Props.product;
  var dispatchModal = Props.dispatchModal;
  var onSuccessOpt = Props.onSuccess;
  var product = productOpt !== undefined ? Caml_option.valFromOption(productOpt) : undefined;
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var productFragment = useOpt(product);
  var match = use$1(undefined);
  var createProduct = match[0];
  var match$1 = use$2(undefined);
  var updateProduct = match$1[0];
  var reform = Curry._7(ProductUpsertForm.use, productFragment !== undefined ? ({
            name: productFragment.name,
            description: Belt_Option.getWithDefault(productFragment.description, ""),
            ncm: Belt_Option.getWithDefault(Belt_Option.flatMap(productFragment.ncm, (function (ncm) {
                        return ncm.code;
                      })), ""),
            cest: Belt_Option.getWithDefault(Belt_Option.flatMap(productFragment.cest, (function (cest) {
                        return cest.code;
                      })), ""),
            cfop: Belt_Option.getWithDefault(Belt_Option.flatMap(productFragment.cfop, (function (cfop) {
                        return cfop.code;
                      })), ""),
            price: Money$VannaFrontend.toDecimal(Money$VannaFrontend.make({
                      TAG: /* Cents */1,
                      _0: productFragment.price
                    })),
            icmsOrigin: Belt_Option.getWithDefault(Belt_Option.flatMap(productFragment.icms, (function (icms) {
                        return icms.origin;
                      })), ""),
            icmsCst: Belt_Option.getWithDefault(Belt_Option.flatMap(productFragment.icms, (function (icms) {
                        return icms.cst;
                      })), ""),
            icmsCsosn: Belt_Option.getWithDefault(Belt_Option.flatMap(productFragment.icms, (function (icms) {
                        return icms.csosn;
                      })), ""),
            pisCst: Belt_Option.getWithDefault(Belt_Option.flatMap(productFragment.pis, (function (pis) {
                        return pis.cst;
                      })), ""),
            cofinsCst: Belt_Option.getWithDefault(Belt_Option.flatMap(productFragment.cofins, (function (cofins) {
                        return cofins.cst;
                      })), "")
          }) : ({
            name: "",
            description: "",
            ncm: "",
            cest: "",
            cfop: "",
            price: 0.0,
            icmsOrigin: "",
            icmsCst: "",
            icmsCsosn: "",
            pisCst: "",
            cofinsCst: ""
          }), /* Schema */{
        _0: Curry._2(ProductUpsertForm.ReSchema.Validation.$plus, Curry._3(ProductUpsertForm.ReSchema.Validation.nonEmpty, undefined, undefined, /* Name */0), Curry._6(ProductUpsertForm.ReSchema.Validation.$$float, 0.0, "", undefined, undefined, undefined, /* Price */5))
      }, (function (param) {
          var raiseSubmitFailed = param.raiseSubmitFailed;
          var state = param.state;
          var send = param.send;
          if (productFragment !== undefined) {
            Curry.app(updateProduct, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.productUpdate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      }
                      var product = match.product;
                      if (product !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        Curry._1(send, {
                              TAG: /* SetFormState */10,
                              _0: /* Dirty */0
                            });
                        return Curry._1(onSuccess, product.id);
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    input: {
                      cest: undefined,
                      cfop: undefined,
                      cofins: undefined,
                      description: state.values.description,
                      icms: undefined,
                      name: state.values.name,
                      ncm: undefined,
                      pis: undefined,
                      price: Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                                TAG: /* Decimal */0,
                                _0: state.values.price
                              })),
                      productId: productFragment.id
                    }
                  },
                  undefined,
                  undefined
                ]);
          } else {
            Curry.app(createProduct, [
                  (function (param) {
                      return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                    }),
                  (function (response, errors) {
                      if (errors !== undefined && errors.length !== 0) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match = response.productCreate;
                      if (match === undefined) {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                      var match$1 = match.error;
                      if (match$1 !== undefined) {
                        return Curry._1(raiseSubmitFailed, match$1.message);
                      }
                      var product = match.product;
                      if (product !== undefined) {
                        Curry._1(dispatchModal, /* Close */1);
                        Curry._1(send, {
                              TAG: /* SetFormState */10,
                              _0: /* Dirty */0
                            });
                        return Curry._1(onSuccess, product.id);
                      } else {
                        return Curry._1(raiseSubmitFailed, "Algo deu errado, tente novamente");
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  (function (store, response) {
                      var match = response.productCreate;
                      if (match === undefined) {
                        return ;
                      }
                      var product = match.product;
                      if (product === undefined) {
                        return ;
                      }
                      var meProxy = store.getRoot().getLinkedRecord("me", undefined);
                      var productProxy = store.get(product.id);
                      if (meProxy == null) {
                        return ;
                      }
                      if (productProxy == null) {
                        return ;
                      }
                      var productsConnection = RelayRuntime.ConnectionHandler.getConnection(meProxy, "Products_products", undefined);
                      if (productsConnection == null) {
                        return ;
                      }
                      var edge = RelayRuntime.ConnectionHandler.createEdge(store, productsConnection, productProxy, "Product");
                      RelayRuntime.ConnectionHandler.insertEdgeAfter(productsConnection, edge, undefined);
                      
                    }),
                  {
                    input: {
                      cest: undefined,
                      cfop: undefined,
                      cofins: undefined,
                      description: state.values.description,
                      icms: undefined,
                      name: state.values.name,
                      ncm: undefined,
                      pis: undefined,
                      price: Money$VannaFrontend.toCents(Money$VannaFrontend.make({
                                TAG: /* Decimal */0,
                                _0: state.values.price
                              }))
                    }
                  },
                  undefined,
                  undefined
                ]);
          }
          
        }), undefined, Reformi18n$VannaFrontend.i18n, undefined, undefined);
  var match$2 = reform.formState;
  var tmp;
  if (typeof match$2 === "number") {
    tmp = null;
  } else {
    var error = match$2._0;
    tmp = error !== undefined ? React.createElement(Box$VannaFrontend.make, {
            type_: /* Error */2,
            title: "Ops!",
            children: error
          }) : null;
  }
  return React.createElement(ProductUpsertForm.Provider.make, Curry._3(ProductUpsertForm.Provider.makeProps, reform, React.createElement("form", {
                      onSubmit: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(reform.submit, undefined);
                        })
                    }, React.createElement(ProductUpsertForm.Field.make, {
                          field: /* Name */0,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Nome",
                                          error: param.error,
                                          required: true,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(ProductUpsertForm.Field.make, {
                          field: /* Description */1,
                          render: (function (param) {
                              return React.createElement(TextField$VannaFrontend.make, {
                                          label: "Descrição",
                                          error: param.error,
                                          required: false,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), React.createElement(ProductUpsertForm.Field.make, {
                          field: /* Price */5,
                          render: (function (param) {
                              return React.createElement(MoneyField$VannaFrontend.make, {
                                          label: "Preço",
                                          error: param.error,
                                          required: false,
                                          value: param.value,
                                          onChange: param.handleChange
                                        });
                            })
                        }), tmp, React.createElement(Button$VannaFrontend.make, {
                          isLoading: reform.isSubmitting,
                          type_: /* Submit */1,
                          children: Belt_Option.isSome(productFragment) ? "Atualizar" : "Adicionar"
                        }), React.createElement(Button$VannaFrontend.make, {
                          mode: /* Secondary */1,
                          onClick: (function (param) {
                              return Curry._1(dispatchModal, /* CloseWithConfirmation */0);
                            }),
                          children: "Cancelar"
                        })), undefined));
}

var make = ProductUpsertForm$1;

exports.ProductFragment = ProductFragment;
exports.StateLenses = StateLenses;
exports.ProductUpsertForm = ProductUpsertForm;
exports.ProductCreateMutation = ProductCreateMutation;
exports.ProductUpdateMutation = ProductUpdateMutation;
exports.make = make;
/* ProductUpsertForm Not a pure module */
