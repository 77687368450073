'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

function unwrap_fragment_items_item(u) {
  var v = u.__typename;
  switch (v) {
    case "Product" :
        return {
                NAME: "Product",
                VAL: u
              };
    case "Service" :
        return {
                NAME: "Service",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_fragment_items_item(param) {
  if (param.NAME === "UnselectedUnionMember") {
    return {
            __typename: param.VAL
          };
  } else {
    return param.VAL;
  }
}

var fragmentConverter = {"__root":{"memberkitWebhook":{"n":""},"items_item":{"u":"fragment_items_item"},"description":{"n":""},"items_maxQuantity":{"n":""},"snippets":{"n":""},"memberkitHottoken":{"n":""},"bannerLink":{"n":""}}};

var fragmentConverterMap = {
  fragment_items_item: unwrap_fragment_items_item
};

function convertFragment(v) {
  return ReasonRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "price",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelfCheckoutUpsertForm_selfCheckout",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasMemberkitIntegration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberkitHottoken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberkitWebhook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "snippets",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SelfCheckoutItemType",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "chargeType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasMaxQuantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxQuantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "Product",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "Service",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "type_",
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SelfCheckout",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.unwrap_fragment_items_item = unwrap_fragment_items_item;
exports.wrap_fragment_items_item = wrap_fragment_items_item;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
