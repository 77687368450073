'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

var wrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.width({
                NAME: "percent",
                VAL: 100
              }),
          tl: {
            hd: Css.maxWidth({
                  NAME: "px",
                  VAL: 700
                }),
            tl: {
              hd: Css.children({
                    hd: Css.not__(":last-child", {
                          hd: Css.marginRight({
                                NAME: "px",
                                VAL: 20
                              }),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Styles = {
  wrapper: wrapper
};

function FieldGrid(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: wrapper
            }, children);
}

var make = FieldGrid;

exports.Styles = Styles;
exports.make = make;
/* wrapper Not a pure module */
