'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Theme$VannaFrontend = require("../Theme.bs.js");

var link = Curry._1(Css.style, {
      hd: Css.display("inlineFlex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.textDecoration("none"),
          tl: {
            hd: Css.color(Theme$VannaFrontend.Colors.link),
            tl: {
              hd: Css.cursor("pointer"),
              tl: {
                hd: Css.hover({
                      hd: Css.textDecoration("underline"),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Styles = {
  link: link
};

function Link(Props) {
  var content = Props.content;
  var onClick = Props.onClick;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement("a", {
              className: Curry._1(Css.merge, {
                    hd: link,
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  }),
              onClick: (function (evt) {
                  evt.preventDefault();
                  return Curry._1(onClick, undefined);
                })
            }, content);
}

function Link$Hyperlink(Props) {
  var href = Props.href;
  var target = Props.target;
  var children = Props.children;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement("a", {
              className: Curry._1(Css.merge, {
                    hd: link,
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  }),
              href: href,
              target: target
            }, children);
}

var Hyperlink = {
  make: Link$Hyperlink
};

var make = Link;

exports.Styles = Styles;
exports.make = make;
exports.Hyperlink = Hyperlink;
/* link Not a pure module */
