'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Color = require("color");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fi = require("react-icons/fi");
var Css_AtomicTypes = require("bs-css/src/Css_AtomicTypes.bs.js");
var Theme$VannaFrontend = require("../Theme.bs.js");

function getBgColor(type_) {
  switch (type_) {
    case /* Success */0 :
        return Theme$VannaFrontend.Colors.success;
    case /* Warn */1 :
        return Theme$VannaFrontend.Colors.warn;
    case /* Error */2 :
        return Theme$VannaFrontend.Colors.error;
    case /* Info */3 :
        return Theme$VannaFrontend.Colors.info;
    
  }
}

function boxWrapper(disabled) {
  return Curry._1(Css.style, {
              hd: Css.borderRadius({
                    NAME: "px",
                    VAL: 4
                  }),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.marginTop({
                        NAME: "rem",
                        VAL: 1.0
                      }),
                  tl: {
                    hd: Css.marginBottom({
                          NAME: "rem",
                          VAL: 1.0
                        }),
                    tl: {
                      hd: Css.overflow("hidden"),
                      tl: {
                        hd: Css.unsafe("color", disabled ? new Color(Css_AtomicTypes.Color.toString(Theme$VannaFrontend.Colors.success)).fade(0.5).string() : Css_AtomicTypes.Color.toString(Theme$VannaFrontend.Colors.text)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function icon(type_) {
  return Curry._1(Css.style, {
              hd: Css.flexShrink(0.0),
              tl: {
                hd: Css.padding2({
                      NAME: "px",
                      VAL: 0
                    }, {
                      NAME: "rem",
                      VAL: 1.25
                    }),
                tl: {
                  hd: Css.fontSize({
                        NAME: "rem",
                        VAL: 2.0
                      }),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("center"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.unsafe("backgroundColor", new Color(Css_AtomicTypes.Color.toString(getBgColor(type_))).fade(0.5).string()),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function message(type_) {
  return Curry._1(Css.style, {
              hd: Css.padding({
                    NAME: "rem",
                    VAL: 0.75
                  }),
              tl: {
                hd: Css.flexGrow(1.0),
                tl: {
                  hd: Css.unsafe("backgroundColor", new Color(Css_AtomicTypes.Color.toString(getBgColor(type_))).fade(0.4).string()),
                  tl: /* [] */0
                }
              }
            });
}

var title = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.9
          }),
      tl: {
        hd: Css.margin3({
              NAME: "px",
              VAL: 0
            }, {
              NAME: "px",
              VAL: 0
            }, {
              NAME: "rem",
              VAL: 0.5
            }),
        tl: /* [] */0
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 0.8
          }),
      tl: /* [] */0
    });

var Styles = {
  getBgColor: getBgColor,
  boxWrapper: boxWrapper,
  icon: icon,
  message: message,
  title: title,
  content: content
};

function getIcon(type_) {
  switch (type_) {
    case /* Success */0 :
        return React.createElement(Fi.FiCheck, {});
    case /* Warn */1 :
        return React.createElement(Fi.FiAlertTriangle, {});
    case /* Error */2 :
        return React.createElement(Fi.FiXCircle, {});
    case /* Info */3 :
        return React.createElement(Fi.FiInfo, {});
    
  }
}

function Box(Props) {
  var type_ = Props.type_;
  var title$1 = Props.title;
  var disabledOpt = Props.disabled;
  var onClick = Props.onClick;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tmp = {
    className: boxWrapper(disabled)
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("div", tmp, React.createElement("div", {
                  className: icon(type_)
                }, getIcon(type_)), React.createElement("div", {
                  className: message(type_)
                }, React.createElement("h4", {
                      className: title
                    }, title$1), React.createElement("div", {
                      className: content
                    }, children)));
}

var make = Box;

exports.Styles = Styles;
exports.getIcon = getIcon;
exports.make = make;
/* title Not a pure module */
