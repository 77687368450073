'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fi = require("react-icons/fi");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Hooks = require("react-relay/hooks");
var Theme$VannaFrontend = require("../Theme.bs.js");
var ReasonRelay_Internal = require("reason-relay/src/ReasonRelay_Internal.bs.js");
var IconButton$VannaFrontend = require("./IconButton.bs.js");
var RouterHelpers$VannaFrontend = require("../RouterHelpers.bs.js");
var Navbar_whitelabelInfo_graphql$VannaFrontend = require("../__generated__/Navbar_whitelabelInfo_graphql.bs.js");

var mediumBreakpoint = Theme$VannaFrontend.Breakpoints.medium;

var navbarWrapper = Curry._1(Css.style, {
      hd: Css.alignItems("center"),
      tl: {
        hd: Css.boxSizing("borderBox"),
        tl: {
          hd: Css.display("none"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.justifyContent("spaceBetween"),
              tl: {
                hd: Css.padding({
                      NAME: "rem",
                      VAL: 1.0
                    }),
                tl: {
                  hd: Css.position("sticky"),
                  tl: {
                    hd: Css.top({
                          NAME: "px",
                          VAL: 0
                        }),
                    tl: {
                      hd: Css.width({
                            NAME: "percent",
                            VAL: 100.0
                          }),
                      tl: {
                        hd: Css.zIndex(99),
                        tl: {
                          hd: Css.backgroundColor(Theme$VannaFrontend.Colors.backgroundLighter),
                          tl: {
                            hd: Css.media("(max-width: " + mediumBreakpoint + ")", {
                                  hd: Css.display("flex"),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var brand = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var brandImage = Curry._1(Css.style, {
      hd: Css.width("auto"),
      tl: {
        hd: Css.height({
              NAME: "vw",
              VAL: 9
            }),
        tl: /* [] */0
      }
    });

var brandText = Curry._1(Css.style, {
      hd: Css.color(Theme$VannaFrontend.Colors.text),
      tl: {
        hd: Css.fontSize({
              NAME: "rem",
              VAL: 1.2
            }),
        tl: {
          hd: Css.paddingLeft({
                NAME: "rem",
                VAL: 1.0
              }),
          tl: /* [] */0
        }
      }
    });

var menuDialog = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.top({
              NAME: "px",
              VAL: 0
            }),
        tl: {
          hd: Css.right({
                NAME: "px",
                VAL: 0
              }),
          tl: {
            hd: Css.width({
                  NAME: "percent",
                  VAL: 100.0
                }),
            tl: {
              hd: Css.padding2({
                    NAME: "rem",
                    VAL: 0.5
                  }, {
                    NAME: "px",
                    VAL: 0
                  }),
              tl: {
                hd: Css.unsafe("boxShadow", "0 5px 5px rgba(0, 0, 0, 0.1)"),
                tl: {
                  hd: Css.zIndex(2),
                  tl: {
                    hd: Css.backgroundColor(Theme$VannaFrontend.Colors.background),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var menuButton = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "rem",
            VAL: 1.2
          }),
      tl: /* [] */0
    });

function navbarItem(isCurrent) {
  return Curry._1(Css.style, {
              hd: Css.display("block"),
              tl: {
                hd: Css.width({
                      NAME: "percent",
                      VAL: 100.0
                    }),
                tl: {
                  hd: Css.fontSize({
                        NAME: "rem",
                        VAL: 1.0
                      }),
                  tl: {
                    hd: Css.padding2({
                          NAME: "rem",
                          VAL: 1.0
                        }, {
                          NAME: "px",
                          VAL: 0
                        }),
                    tl: {
                      hd: Css.textAlign("center"),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.color(isCurrent ? Theme$VannaFrontend.Colors.primary : Theme$VannaFrontend.Colors.textSecondary),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.backgroundColor(Theme$VannaFrontend.Colors.secondary),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Styles = {
  mediumBreakpoint: mediumBreakpoint,
  navbarWrapper: navbarWrapper,
  brand: brand,
  brandImage: brandImage,
  brandText: brandText,
  menuDialog: menuDialog,
  menuButton: menuButton,
  navbarItem: navbarItem
};

function Navbar$NavbarItem(Props) {
  var label = Props.label;
  var route = Props.route;
  var onClick = Props.onClick;
  var url = Props.url;
  return React.createElement("span", {
              key: "__navbar__item__" + label,
              className: navbarItem(route !== undefined ? Caml_obj.caml_equal(route, RouterHelpers$VannaFrontend.pathToRoute(url)) : false),
              onClick: (function (param) {
                  return Curry._1(onClick, route);
                })
            }, label);
}

var NavbarItem = {
  make: Navbar$NavbarItem
};

var Internal = {};

function use(fRef) {
  var data = Hooks.useFragment(Navbar_whitelabelInfo_graphql$VannaFrontend.node, fRef);
  return ReasonRelay_Internal.internal_useConvertedValue(Navbar_whitelabelInfo_graphql$VannaFrontend.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = Hooks.useFragment(Navbar_whitelabelInfo_graphql$VannaFrontend.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return ReasonRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return Navbar_whitelabelInfo_graphql$VannaFrontend.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var NavbarFragment = {
  Types: undefined,
  Internal: Internal,
  use: use,
  useOpt: useOpt
};

function Navbar(Props) {
  var queryRef = Props.query;
  var items = Props.items;
  var match = React.useState(function () {
        return false;
      });
  var setVisible = match[1];
  var isVisible = match[0];
  var whitelabelInfo = use(queryRef);
  var iconSrc = whitelabelInfo.iconSrc;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: navbarWrapper
                }, React.createElement("div", {
                      className: brand
                    }, React.createElement("img", {
                          className: brandImage,
                          src: iconSrc !== undefined && iconSrc !== "" ? iconSrc : "/icon.png"
                        })), React.createElement(IconButton$VannaFrontend.make, {
                      onClick: (function (param, param$1, param$2) {
                          return Curry._1(setVisible, (function (param) {
                                        return !isVisible;
                                      }));
                        }),
                      className: menuButton,
                      children: isVisible ? React.createElement(Fi.FiX, {}) : React.createElement(Fi.FiMenu, {})
                    })), isVisible ? React.createElement("div", undefined, Belt_List.toArray(items)) : React.createElement("div", undefined));
}

var make = Navbar;

exports.Styles = Styles;
exports.NavbarItem = NavbarItem;
exports.NavbarFragment = NavbarFragment;
exports.make = make;
/* navbarWrapper Not a pure module */
