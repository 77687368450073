'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var BignumberJs = require("bignumber.js").default;

function getTotalUniq(item) {
  var priceByQuantity = new BignumberJs(item.price).times(item.quantity);
  return priceByQuantity.plus(item.freightAmount).plus(item.insuranceAmount).plus(item.othersAmount).minus(item.discountAmount).toNumber();
}

function getTotalFromArray(items) {
  return Belt_Array.reduce(items, 0, (function (acc, item) {
                return new BignumberJs(acc).plus(getTotalUniq(item)).toNumber();
              }));
}

exports.getTotalUniq = getTotalUniq;
exports.getTotalFromArray = getTotalFromArray;
/* bignumber.js Not a pure module */
