'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"recurrentChargeResume":{"n":""},"recurrentChargeResume_error":{"n":""},"recurrentChargeResume_recurrentCharge":{"n":""},"recurrentChargeResume_recurrentCharge_stoppedAt":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"recurrentChargeResume":{"n":""},"recurrentChargeResume_error":{"n":""},"recurrentChargeResume_recurrentCharge":{"n":""},"recurrentChargeResume_recurrentCharge_stoppedAt":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"RecurrentChargeResumeInput"}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_recurrentChargeResumeInput(recurrentChargeId) {
  return {
          recurrentChargeId: recurrentChargeId
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_recurrentChargeResume_recurrentCharge(id, status, stoppedAt, param) {
  return {
          id: id,
          status: status,
          stoppedAt: stoppedAt
        };
}

function make_response_recurrentChargeResume_error(message) {
  return {
          message: message
        };
}

function make_response_recurrentChargeResume(error, recurrentCharge, param) {
  return {
          error: error,
          recurrentCharge: recurrentCharge
        };
}

function makeOptimisticResponse(recurrentChargeResume, param) {
  return {
          recurrentChargeResume: recurrentChargeResume
        };
}

var Utils = {
  make_recurrentChargeResumeInput: make_recurrentChargeResumeInput,
  makeVariables: makeVariables,
  make_response_recurrentChargeResume_recurrentCharge: make_response_recurrentChargeResume_recurrentCharge,
  make_response_recurrentChargeResume_error: make_response_recurrentChargeResume_error,
  make_response_recurrentChargeResume: make_response_recurrentChargeResume,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RecurrentChargeResumePayload",
    "kind": "LinkedField",
    "name": "recurrentChargeResume",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RecurrentCharge",
        "kind": "LinkedField",
        "name": "recurrentCharge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stoppedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecurrentChargesTable_RecurrentChargeResumeMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecurrentChargesTable_RecurrentChargeResumeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b6031898153bbed08430d29df9b3d305",
    "id": null,
    "metadata": {},
    "name": "RecurrentChargesTable_RecurrentChargeResumeMutation",
    "operationKind": "mutation",
    "text": "mutation RecurrentChargesTable_RecurrentChargeResumeMutation(\n  $input: RecurrentChargeResumeInput!\n) {\n  recurrentChargeResume(input: $input) {\n    error {\n      message\n    }\n    recurrentCharge {\n      id\n      status\n      stoppedAt\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
