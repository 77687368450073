'use strict';

var ReasonRelay = require("reason-relay/src/ReasonRelay.bs.js");

var Types = {};

var wrapResponseConverter = {"__root":{"apiKeyUpdate_error":{"n":""},"apiKeyUpdate_apiKey_description":{"n":""},"apiKeyUpdate":{"n":""},"apiKeyUpdate_apiKey":{"n":""}}};

function convertWrapResponse(v) {
  return ReasonRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"apiKeyUpdate_error":{"n":""},"apiKeyUpdate_apiKey_description":{"n":""},"apiKeyUpdate":{"n":""},"apiKeyUpdate_apiKey":{"n":""}}};

function convertResponse(v) {
  return ReasonRelay.convertObj(v, responseConverter, undefined, undefined);
}

var variablesConverter = {"__root":{"input":{"r":"ApiKeyUpdateInput"}},"ApiKeyUpdateInput":{"description":{"n":""}}};

function convertVariables(v) {
  return ReasonRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var Internal = {
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse,
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables
};

function make_apiKeyUpdateInput(apiKeyId, description, param) {
  return {
          apiKeyId: apiKeyId,
          description: description
        };
}

function makeVariables(input) {
  return {
          input: input
        };
}

function make_response_apiKeyUpdate_apiKey(id, keyId, description, param) {
  return {
          id: id,
          keyId: keyId,
          description: description
        };
}

function make_response_apiKeyUpdate_error(message) {
  return {
          message: message
        };
}

function make_response_apiKeyUpdate(error, apiKey, param) {
  return {
          error: error,
          apiKey: apiKey
        };
}

function makeOptimisticResponse(apiKeyUpdate, param) {
  return {
          apiKeyUpdate: apiKeyUpdate
        };
}

var Utils = {
  make_apiKeyUpdateInput: make_apiKeyUpdateInput,
  makeVariables: makeVariables,
  make_response_apiKeyUpdate_apiKey: make_response_apiKeyUpdate_apiKey,
  make_response_apiKeyUpdate_error: make_response_apiKeyUpdate_error,
  make_response_apiKeyUpdate: make_response_apiKeyUpdate,
  makeOptimisticResponse: makeOptimisticResponse
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ApiKeyUpdatePayload",
    "kind": "LinkedField",
    "name": "apiKeyUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApiKey",
        "kind": "LinkedField",
        "name": "apiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "keyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApiKeyUpsertForm_ApiKeyUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApiKeyUpsertForm_ApiKeyUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6b72f2ad2e764bfa30750e21a0615474",
    "id": null,
    "metadata": {},
    "name": "ApiKeyUpsertForm_ApiKeyUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ApiKeyUpsertForm_ApiKeyUpdateMutation(\n  $input: ApiKeyUpdateInput!\n) {\n  apiKeyUpdate(input: $input) {\n    error {\n      message\n    }\n    apiKey {\n      id\n      keyId\n      description\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
